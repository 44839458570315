import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

interface TransitionProps {
  className?: string;
  children?: React.ReactNode;
  component?: string;
  classNames: {
    appear?: string;
    appearActive?: string;
    enter?: string;
    enterActive?: string;
    exit?: string;
    exitActive?: string;
  };
  timeout: {
    appear?: number;
    enter?: number;
    exit?: number;
  };
  appear?: boolean;
  enter?: boolean;
  exit?: boolean;
  style?: object;
}

const Transition = ({
  children,
  className,
  component = 'div',

  classNames = {
    appear: 'appear',
    enter: 'enter',
    exit: 'exit'
  },

  timeout,
  appear = true,
  enter = true,
  exit = true,
  style
}: TransitionProps) => {
  let _children: React.ReactNode[] = [];

  const styleProps = {
    component,
    classNames,
    appear,
    enter,
    exit,
    timeout
  };

  if (Array.isArray(children)) {
    _children = children;
  } else if (children) {
    _children = [children];
  }

  return (
    <TransitionGroup style={style} className={className}>
      {_children.map((child, idx) => (
        <CSSTransition key={idx} {...styleProps}>
          {child}
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default React.memo(Transition);
