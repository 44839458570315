import React, { useCallback, useContext, useState } from 'react';

import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import T from 'ecto-common/lib/lang/Language';
import { useParams, generatePath } from 'react-router';
import IntegrationAPIGen from 'ecto-common/lib/API/IntegrationAPIGen';
import NavLinkFix from 'ecto-common/lib/NavLinkFix/NavLinkFix';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { ItemPageParams } from 'js/utils/routeConstants';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import AddButton from 'ecto-common/lib/Button/AddButton';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';

import { useAddOrEditIntegrationPointModal } from 'js/components/Integrations/IntegrationModels';
import IntegrationPointTable from 'js/components/Integrations/IntegrationPointTable';
import { IntegrationPointResponse } from 'ecto-common/lib/API/IntegrationAdminAPIGen';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import IntegrationAdminAPIGen from 'ecto-common/lib/API/IntegrationAdminAPIGen';
import { useQueryClient } from '@tanstack/react-query';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { IntegrationsRoute } from 'js/utils/routeConstants';

const IntegrationProxyPage = () => {
  const params = useParams<ItemPageParams>();
  const { itemId } = params;
  const { tenantId } = useContext(TenantContext);
  const [editPoint, setEditPoint] = useState<IntegrationPointResponse>(null);
  const [pointToDelete, setPointToDelete] =
    useState<IntegrationPointResponse>(null);

  const queryClient = useQueryClient();

  const {
    mutate: deleteIntegrationPoint,
    isLoading: isDeletingIntegrationPoint
  } =
    IntegrationAdminAPIGen.IntegrationPoints.deleteIntegrationPoint.useMutation(
      {
        pointId: pointToDelete?.id
      },
      {
        await: true
      },
      {
        onSuccess: () => {
          toastStore.addSuccessToastForDeletedItem(pointToDelete?.name);
          setPointToDelete(null);
          queryClient.invalidateQueries();
        },
        onError: () => {
          toastStore.addErrorToastForDeletedItem(pointToDelete?.name);
        }
      }
    );

  const { data: integrationProxy, isLoadingPaginatedData: isLoading } =
    IntegrationAPIGen.IntegrationProxies.getIntegrationProxy.useQuery({
      proxyId: itemId
    });

  const clearEditPoint = useCallback(() => {
    setEditPoint(null);
  }, []);

  const { onAddIntegrationPoint, addIntegrationModelComponent } =
    useAddOrEditIntegrationPointModal(
      integrationProxy,
      null,
      editPoint,
      clearEditPoint
    );

  const toolbarItems = (
    <>
      <ToolbarFlexibleSpace />
      <ToolbarItem>
        <AddButton onClick={onAddIntegrationPoint} disabled={isLoading}>
          {T.admin.integrations.addpoint}
        </AddButton>
      </ToolbarItem>
    </>
  );

  return (
    <ToolbarContentPage
      wrapContent={false}
      showLocationPicker={false}
      toolbarItems={toolbarItems}
      title={
        <>
          <NavLinkFix to={generatePath(IntegrationsRoute.path, { tenantId })}>
            {T.admin.tabs.integrations}
          </NavLinkFix>
          &nbsp;&gt;&nbsp;{integrationProxy && integrationProxy.name}
        </>
      }
    >
      <IntegrationPointTable
        integrationProxyId={itemId}
        isLoading={isLoading}
        onClickRow={setEditPoint}
        onDeleteRow={setPointToDelete}
      />
      {addIntegrationModelComponent}
      <ConfirmDeleteDialog
        isOpen={pointToDelete != null}
        itemName={pointToDelete?.name}
        onModalClose={() => setPointToDelete(null)}
        isLoading={isDeletingIntegrationPoint}
        onDelete={deleteIntegrationPoint}
      />
    </ToolbarContentPage>
  );
};

IntegrationProxyPage.propTypes = {};

export default React.memo(IntegrationProxyPage);
