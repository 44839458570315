import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import _ from 'lodash';
import { useLocation } from 'react-router';

// When we upgraded from React 15->16 and react-router-dom 4.1.1 => 4.3.1 our
// nav links failed to show the active state correctly. Somehow the nav links
// don't get the correct match URL. This class uses a simpler approach to detecting
// whether or not the link is active - should be removed if we can figure out why
// the bug occurs (or if a newer version of the libraries fixes the issue)
const NavLinkFix = (props: NavLinkProps) => {
  const { to, className, tabIndex, onClick } = props;
  const location = useLocation();
  const filteredProps = _.omit(
    props,
    'tabIndex',
    'staticContext',
    'match',
    'location',
    'history'
  );

  return (
    <NavLink
      onClick={(e) => {
        e.stopPropagation();
        onClick?.(e);
      }}
      className={className}
      tabIndex={tabIndex}
      isActive={() => {
        return location.pathname.startsWith(to as string);
      }}
      {...filteredProps}
    />
  );
};

export default React.memo(NavLinkFix);
