import React from 'react';
import classNames from 'classnames';
import '../styles/reset.css';

import '../styles/font/font.css';
import styles from './Layout.module.css';

interface LayoutProps {
  header?: React.ReactElement;
  contentArea: React.ReactElement;
  leftSidebar?: React.ReactElement;
  disabled?: boolean;
}

const Layout = ({
  header,
  contentArea,
  leftSidebar,
  disabled = false
}: LayoutProps) => {
  if (leftSidebar != null) {
    return (
      <div id="contentContainer" className={classNames(styles.layout)}>
        {header}
        <div
          className={classNames(
            styles.sidebarContent,
            disabled && styles.disabled
          )}
        >
          {leftSidebar}
          {contentArea}
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(styles.layout, disabled && styles.disabled)}>
      {header}

      {contentArea}
    </div>
  );
};

export default React.memo(Layout);
