import {
  compose,
  translate,
  applyToPoint,
  inverse,
  scale
} from 'transformation-matrix';
import { isPointOnLine } from 'ecto-common/lib/ProcessMaps/ProcessMapLineUtil';
import {
  keyboardEventIsRedo,
  keyboardEventIsUndo
} from 'ecto-common/lib/hooks/useUndoShortcuts';
import {
  ProcessMapLineConnection,
  ProcessMapDocument,
  ProcessMapLineModes,
  ProcessMapLineObject,
  ProcessMapObjectTypes,
  ProcessMapRect,
  ProcessMapRectHandle,
  ProcessMapRectResizeArea,
  ProcessMapRectResizeAreas,
  ProcessMapSignalTextObject,
  ProcessMapSymbolObject,
  ProcessMapTextObject,
  ProcessMapSymbolLineConnection,
  lineRectHeight,
  processMapLineSelectionMarginPixels,
  ProcessMapObject
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import { Draft } from 'immer';
import { SignalTypeResponseModel } from 'ecto-common/lib/API/APIGen';
import _ from 'lodash';
import md5 from 'md5';
import UUID from 'uuidjs';
import {
  MouseDragStates,
  ProcessMapState
} from 'ecto-common/lib/ProcessMaps/ProcessMapEditorTypes';
import { importOldSvg } from 'ecto-common/lib/ProcessMaps/ProcessMapEditorImportHelpers';
import { ProcessMapEditorActionUtils } from 'ecto-common/lib/ProcessMaps/ProcessMapEditorActionUtils';
import {
  distProcessMapRects,
  extendedLineRectIterator,
  overlapsProcessMapRectMouseCoord,
  overlapsProcessRectWithRectObject,
  ConnectionModelPoints,
  rectIterator,
  resizeTextNode,
  reverseRectIterator,
  setProcessMapRectCenterRounded
} from 'ecto-common/lib/ProcessMap/ProcessMapViewUtils';
import { processMapMinTextSize } from '../ProcessMap/ProcessMapViewConstants';
import { SymbolModel } from 'ecto-common/lib/API/PresentationAPIGen';

export const ProcessMapEditorStateHelpers = {
  undoAvailable: (state: ProcessMapState) => {
    return state.undoStackIndex > 0;
  },
  redoAvailable: (state: ProcessMapState) => {
    return state.undoStackIndex < state.undoStack.length - 1;
  }
};

/**
 * Operates a bit like a reducer, but without all the overhead of writing actions, action types
 * and having a giant switch block. Each function operates on an immer state and is free from side
 * effects. Intended to be used with the useImmer hook.
 */
export const ProcessMapEditorActions = {
  updateTransform: (state: Draft<ProcessMapState>, containerRect: DOMRect) => {
    const newTransform = translate(
      containerRect.width / 2 - state.processMap.width / 2,
      containerRect.height / 2 - state.processMap.height / 2
    );
    ProcessMapEditorActionUtils.setTransform(state, {
      current: newTransform,
      inverse: inverse(newTransform)
    });
  },
  addSvg: (
    state: Draft<ProcessMapState>,
    svgContent: string,
    svgMd5: string
  ) => {
    ProcessMapEditorActionUtils.addSvg(state, svgContent, svgMd5);
  },
  addNewLinePoint: (
    state: Draft<ProcessMapState>,
    objectIndex: number,
    index: number,
    centerX: number,
    centerY: number
  ) => {
    const object = state.processMap.objects[objectIndex];
    object.rects.splice(index, 0, {
      centerX,
      centerY,
      height: lineRectHeight,
      width: lineRectHeight,
      id: UUID.generate()
    });
    ProcessMapEditorActionUtils.fixHandles(state);
    state.selectedRectHandles = [
      {
        objectId: object.id,
        rectId: object.rects[index].id,
        objectIndex,
        rectIndex: index
      }
    ];
    ProcessMapEditorActionUtils.pushUndoStack(state);
  },
  importFromMap: (
    state: Draft<ProcessMapState>,
    processMap: ProcessMapDocument
  ) => {
    state.processMap.objects = state.processMap.objects.concat(
      processMap.objects
    );
    state.processMap.lineConnections = state.processMap.lineConnections.concat(
      processMap.lineConnections
    );
    state.processMap.symbolLineConnections =
      state.processMap.symbolLineConnections.concat(
        processMap.symbolLineConnections
      );
    state.processMap.svgImages = {
      ...state.processMap.svgImages,
      ...processMap.svgImages
    };
    ProcessMapEditorActionUtils.fixHandles(state);
    ProcessMapEditorActionUtils.pushUndoStack(state);
  },
  updateSymbols: (state: Draft<ProcessMapState>, library: SymbolModel[]) => {
    for (const object of state.processMap.objects) {
      if (object.type === ProcessMapObjectTypes.Symbol) {
        const typeId = object.typeId;
        if (typeId != null) {
          const libraryItem = _.find(library, (item) => item.id === typeId);
          if (libraryItem != null) {
            const svgMd5 = md5(libraryItem.data);
            object.svgMd5 = svgMd5;
            ProcessMapEditorActionUtils.addSvg(state, libraryItem.data, svgMd5);
            object.connections = _.cloneDeep(libraryItem.connections);
          }
        }
      }
    }

    for (const connection of state.processMap.symbolLineConnections) {
      const lineObject = state.processMap.objects[
        connection.lineObjectRectHandle.objectIndex
      ] as ProcessMapLineObject;
      const symbolObject = state.processMap.objects[
        connection.symbolObjectHandle.objectIndex
      ] as ProcessMapSymbolObject;

      const lineRect =
        lineObject.rects[connection.lineObjectRectHandle.rectIndex];
      const symbolRect = symbolObject.rects[0];

      const connectionPoint = symbolObject.connections.find(
        (x) => connection.connectionId === x.id
      );

      if (connectionPoint != null) {
        const connectionPointCoords = ConnectionModelPoints(
          symbolObject,
          symbolRect,
          connectionPoint
        );

        setProcessMapRectCenterRounded(
          lineRect,
          connectionPointCoords.x,
          connectionPointCoords.y
        );
      }
    }

    ProcessMapEditorActionUtils.trimImageDatabase(state);
  },
  onMouseDown: (
    state: Draft<ProcessMapState>,
    event: React.MouseEvent<SVGElement>,
    svgRect: DOMRect
  ) => {
    state.mouseState.buttonDown = event.button;
    state.mouseState.mouseDownX = event.clientX;
    state.mouseState.mouseDownY = event.clientY;
    state.mouseState.mouseDownTimeStamp = event.timeStamp;
    state.mouseState.didMove = false;
    state.mouseState.didMoveObject = false;
    state.isMouseDown = true;

    const zoomScale = state.transform.current.a;
    const mouseDocumentPosition = applyToPoint(state.transform.inverse, {
      x: event.clientX - svgRect.x,
      y: event.clientY - svgRect.y
    });

    if (
      ProcessMapEditorActionUtils.handleMouseDownInConnection(
        state,
        mouseDocumentPosition
      )
    ) {
      return;
    }

    if (event.altKey) {
      state.mouseState.cycleRectCounter++;
    } else {
      state.mouseState.cycleRectCounter = 0;
    }

    let existingRectRootIndex = state.selectedRectHandles.findIndex(
      (handle) => {
        const object = state.processMap.objects[handle.objectIndex];
        const rect = object.rects[handle.rectIndex];
        return (
          overlapsProcessMapRectMouseCoord(
            mouseDocumentPosition.x,
            mouseDocumentPosition.y,
            rect,
            object,
            zoomScale
          ) != null
        );
      }
    );

    if (existingRectRootIndex !== -1 && !event.altKey) {
      const firstHandle = state.selectedRectHandles[0];
      const singleLineSelected =
        state.processMap.objects[firstHandle.objectIndex].type ===
          ProcessMapObjectTypes.Line &&
        state.selectedRectHandles.every(
          (handle) => handle.objectId === firstHandle.objectId
        );

      // If all of the points belong to the same line then we likely want to just select the single point
      // that the user has clicked on, and drag that. To drag the whole line, you can drag the line segments.
      if (singleLineSelected) {
        state.selectedRectHandles = [
          state.selectedRectHandles[existingRectRootIndex]
        ];
        existingRectRootIndex = 0;
      }

      const existingRectRootHandle =
        state.selectedRectHandles[existingRectRootIndex];
      const existingRect =
        state.processMap.objects[existingRectRootHandle.objectIndex].rects[
          existingRectRootHandle.rectIndex
        ];
      state.mouseState.dragOffsetX =
        mouseDocumentPosition.x - existingRect.centerX;
      state.mouseState.dragOffsetY =
        mouseDocumentPosition.y - existingRect.centerY;

      state.mouseState.selectedRectRootIndex = existingRectRootIndex;

      return;
    }

    type MatchType =
      | {
          type: 'wholeLine';
          line: ProcessMapLineObject;
          lineIndex: number;
        }
      | {
          type: 'rect';
          handle: ProcessMapRectHandle;
        };

    const matchHandles: MatchType[] = [];

    /**
     * Iterate in reverse order so that the topmost object is selected. We can select both
     * individual rects, and an entire line object if the mouse is over the line. Store
     * all matches found, and then allow the user to cycle through the matches by pressing
     * the alt key.
     */
    for (
      let objectIndex = state.processMap.objects.length - 1;
      objectIndex >= 0;
      objectIndex--
    ) {
      const object = state.processMap.objects[objectIndex];
      for (let rectIndex = 0; rectIndex < object.rects.length; rectIndex++) {
        const rect = object.rects[rectIndex];
        if (
          overlapsProcessMapRectMouseCoord(
            mouseDocumentPosition.x,
            mouseDocumentPosition.y,
            rect,
            object,
            zoomScale
          ) != null
        ) {
          matchHandles.push({
            type: 'rect',
            handle: {
              objectIndex,
              rectIndex: rectIndex,
              objectId: object.id,
              rectId: rect.id
            }
          });
        }
      }

      if (object.type === ProcessMapObjectTypes.Line) {
        let prevRect = null;
        for (const rect of extendedLineRectIterator(object)) {
          if (prevRect != null) {
            if (
              isPointOnLine(
                mouseDocumentPosition.x,
                mouseDocumentPosition.y,
                prevRect,
                rect,
                processMapLineSelectionMarginPixels / zoomScale
              )
            ) {
              matchHandles.push({
                type: 'wholeLine',
                line: object,
                lineIndex: objectIndex
              });
              break;
            }
          }
          prevRect = rect;
        }
      }
    }

    let matchRect: ProcessMapRect = null;
    let matchRectHandle: ProcessMapRectHandle = null;

    if (matchHandles.length > 0) {
      const match =
        matchHandles[state.mouseState.cycleRectCounter % matchHandles.length];

      if (match.type === 'rect') {
        matchRectHandle = match.handle;
        matchRect =
          state.processMap.objects[matchRectHandle.objectIndex].rects[
            matchRectHandle.rectIndex
          ];
        state.mouseState.dragOffsetX =
          mouseDocumentPosition.x - matchRect.centerX;
        state.mouseState.dragOffsetY =
          mouseDocumentPosition.y - matchRect.centerY;

        // If the rect is not already among the selected rects, discard the other selected rects and select only it.
        // Unless the shift button is pressed, then add it to the list of already selected rects.
        // One exception: If the selection consists of rects all belonging to the same object (like a line),
        // treat it like the first case - i.e. start dragging only it, or add it to the list of already selected rects.
        const rectInSelection = state.selectedRectHandles.find(
          (handle) => handle.rectId === matchRectHandle.rectId
        );
        const otherObjectInSelection = state.selectedRectHandles.some(
          (handle) => handle.objectId !== matchRectHandle.objectId
        );
        if (!rectInSelection || !otherObjectInSelection) {
          state.mouseState.selectedRectRootIndex = 0;
          if (event.shiftKey) {
            state.selectedRectHandles = [
              matchRectHandle,
              ...state.selectedRectHandles
            ];
          } else {
            state.selectedRectHandles = [matchRectHandle];
          }
        } else {
          state.mouseState.selectedRectRootIndex = _.findIndex(
            state.selectedRectHandles,
            matchRectHandle
          );
        }
      } else if (match.type === 'wholeLine') {
        const overlappingLineObject = match.line;
        const overlappingLineIndex = match.lineIndex;

        const lineRectHandles = overlappingLineObject.rects.map((r, idx) => ({
          objectIndex: overlappingLineIndex,
          rectIndex: idx,
          objectId: overlappingLineObject.id,
          rectId: r.id
        }));

        state.mouseState.dragOffsetX =
          mouseDocumentPosition.x - overlappingLineObject.rects[0].centerX;
        state.mouseState.dragOffsetY =
          mouseDocumentPosition.y - overlappingLineObject.rects[0].centerY;

        state.mouseState.selectedRectRootIndex = 0;

        if (event.shiftKey) {
          state.selectedRectHandles = [
            ...lineRectHandles,
            ...state.selectedRectHandles
          ];
        } else {
          state.selectedRectHandles = lineRectHandles;
        }
      }
    } else {
      state.selectedRectHandles = [];
    }
  },
  onMouseMove: (
    state: Draft<ProcessMapState>,
    event: React.MouseEvent<SVGElement>,
    svgRect: DOMRect
  ) => {
    const minMoveDelta = 5;
    state.mouseState.didMove =
      state.mouseState.didMove ||
      Math.abs(event.clientX - state.mouseState.mouseDownX) > minMoveDelta ||
      Math.abs(event.clientY - state.mouseState.mouseDownY) > minMoveDelta;

    if (
      state.isMouseDown &&
      state.mouseState.dragState === MouseDragStates.Idle
    ) {
      if (
        (state.mouseState.buttonDown === 1 ||
          state.mouseState.buttonDown === 2) &&
        state.selectedRectHandles.length === 0
      ) {
        state.mouseState.dragState = MouseDragStates.Panning;
      } else if (
        state.mouseState.buttonDown === 0 &&
        state.selectedRectHandles.length > 0
      ) {
        // Prevent spurious object movements by ignoring mouse move events that occur too soon after a mouse down event.
        if (event.timeStamp - state.mouseState.mouseDownTimeStamp > 150) {
          state.mouseState.dragState = MouseDragStates.MovingObject;
        }
      } else if (
        state.mouseState.buttonDown === 0 &&
        state.selectedRectHandles.length === 0
      ) {
        state.mouseState.dragState = MouseDragStates.DefiningArea;
        state.mouseState.didMoveObject = true;
      }
    }

    const zoomScale = state.transform.current.a;
    const dx = event.movementX / zoomScale;
    const dy = event.movementY / zoomScale;

    const mouseDocumentPosition = applyToPoint(state.transform.inverse, {
      x: event.clientX - svgRect.x,
      y: event.clientY - svgRect.y
    });
    const newHoverRectHandles: ProcessMapRectHandle[] = [];

    for (const [rect, object, objectIndex, rectIndex] of reverseRectIterator(
      state.processMap
    )) {
      let didHitPoint = false;

      if (state.mouseState.dragState === MouseDragStates.DefiningArea) {
        didHitPoint =
          state.defineAreaState != null &&
          overlapsProcessRectWithRectObject(rect, object, zoomScale, {
            centerX:
              state.defineAreaState.startX +
              (state.defineAreaState.endX - state.defineAreaState.startX) / 2.0,
            centerY:
              state.defineAreaState.startY +
              (state.defineAreaState.endY - state.defineAreaState.startY) / 2.0,
            width: state.defineAreaState.endX - state.defineAreaState.startX,
            height: state.defineAreaState.endY - state.defineAreaState.startY,
            id: null
          });
      } else if (state.mouseState.dragState === MouseDragStates.MovingObject) {
        didHitPoint = state.selectedRectHandles.some((handle) =>
          overlapsProcessRectWithRectObject(
            state.processMap.objects[handle.objectIndex].rects[
              handle.rectIndex
            ],
            state.processMap.objects[handle.objectIndex],
            zoomScale,
            rect
          )
        );
      } else if (
        state.mouseState.dragState === MouseDragStates.Panning ||
        state.mouseState.dragState === MouseDragStates.Idle
      ) {
        didHitPoint =
          overlapsProcessMapRectMouseCoord(
            mouseDocumentPosition.x,
            mouseDocumentPosition.y,
            rect,
            object,
            zoomScale
          ) != null;
      }

      if (
        didHitPoint &&
        state.selectedRectHandles.find((handle) => handle.rectId === rect.id) ==
          null
      ) {
        newHoverRectHandles.push({
          objectIndex: objectIndex,
          rectIndex: rectIndex,
          objectId: object.id,
          rectId: rect.id
        });
      }
    }

    for (
      let objectIndex = state.processMap.objects.length - 1;
      objectIndex >= 0;
      objectIndex--
    ) {
      const object = state.processMap.objects[objectIndex];
      if (object.type === ProcessMapObjectTypes.Line) {
        let prevRect: ProcessMapRect = null;
        let rectIndex = 0;
        for (const rect of extendedLineRectIterator(object)) {
          if (
            prevRect &&
            isPointOnLine(
              mouseDocumentPosition.x,
              mouseDocumentPosition.y,
              prevRect,
              rect,
              processMapLineSelectionMarginPixels / zoomScale
            ) &&
            state.selectedRectHandles.find(
              (handle) => handle.rectId === rect.id
            ) == null
          ) {
            if (object.mode === ProcessMapLineModes.Path) {
              newHoverRectHandles.push({
                objectIndex: objectIndex,
                rectIndex,
                objectId: object.id,
                rectId: rect.id
              });
              newHoverRectHandles.push({
                objectIndex: objectIndex,
                rectIndex: rectIndex - 1,
                objectId: object.id,
                rectId: prevRect.id
              });
            } else {
              newHoverRectHandles.push({
                objectIndex: objectIndex,
                rectIndex: 0,
                objectId: object.id,
                rectId: object.rects[0].id
              });
              newHoverRectHandles.push({
                objectIndex: objectIndex,
                rectIndex: 1,
                objectId: object.id,
                rectId: object.rects[1].id
              });
            }
            break;
          }

          prevRect = rect;
          rectIndex++;
        }
      }
    }

    if (
      newHoverRectHandles.length !== state.hoverRectHandles.length ||
      _.some(
        newHoverRectHandles,
        (handle, idx) => handle.rectId !== state.hoverRectHandles[idx].rectId
      )
    ) {
      state.hoverRectHandles = newHoverRectHandles;
    }

    if (state.mouseState.dragState === MouseDragStates.Panning) {
      const currentTransform = compose(
        state.transform.current,
        translate(dx, dy)
      );
      ProcessMapEditorActionUtils.setTransform(state, {
        current: currentTransform,
        inverse: inverse(currentTransform)
      });
    } else if (state.mouseState.dragState === MouseDragStates.MovingObject) {
      const dragParentHandle =
        state.selectedRectHandles[state.mouseState.selectedRectRootIndex];
      const dragParent =
        state.processMap.objects[dragParentHandle.objectIndex].rects[
          dragParentHandle.rectIndex
        ];
      state.mouseState.didMoveObject = state.selectedRectHandles.length > 0;

      const alignPos = (pos: number) => {
        return event.metaKey || event.altKey ? pos : Math.round(pos);
      };

      for (const dragElementHandle of state.selectedRectHandles) {
        if (dragElementHandle === dragParentHandle) {
          continue;
        }

        const dragObject =
          state.processMap.objects[dragElementHandle.objectIndex];
        const dragElement = dragObject.rects[dragElementHandle.rectIndex];
        dragElement.centerX = alignPos(
          mouseDocumentPosition.x -
            state.mouseState.dragOffsetX +
            (dragElement.centerX - dragParent.centerX)
        );
        dragElement.centerY = alignPos(
          mouseDocumentPosition.y -
            state.mouseState.dragOffsetY +
            (dragElement.centerY - dragParent.centerY)
        );
      }

      dragParent.centerX = alignPos(
        mouseDocumentPosition.x - state.mouseState.dragOffsetX
      );
      dragParent.centerY = alignPos(
        mouseDocumentPosition.y - state.mouseState.dragOffsetY
      );

      state.pendingConnectionLine = null;
      state.pendingConnectionSymbol = null;

      ProcessMapEditorActionUtils.updateDraggedConnections(state);
      const firstHandle = state.selectedRectHandles[0];
      const firstDragObject = state.processMap.objects[firstHandle.objectIndex];

      if (
        state.selectedRectHandles.length === 1 &&
        firstDragObject.type === ProcessMapObjectTypes.Line
      ) {
        let prevLineDist = Number.MAX_SAFE_INTEGER;
        let prevSymbolDist = Number.MAX_SAFE_INTEGER;
        const scaleFactor = state.transform.current.a;

        // Since our connection UI elements keep the same pixel size even when zoomed,
        // we need to scale them back to the original size before checking for overlaps.
        const firstDragRect = firstDragObject.rects[firstHandle.rectIndex];

        const tmpRect: ProcessMapRect = {
          centerX: 0,
          centerY: 0,
          width: 0,
          height: 0,
          id: null
        };
        tmpRect.width = (state.connectionCircleRadius * 2) / scaleFactor;
        tmpRect.height = (state.connectionCircleRadius * 2) / scaleFactor;

        for (const [
          otherRect,
          otherObject,
          objectIndex,
          rectIndex
        ] of rectIterator(state.processMap)) {
          if (
            otherObject.type === ProcessMapObjectTypes.Line &&
            otherObject !== firstDragObject
          ) {
            tmpRect.centerX = otherRect.centerX;
            tmpRect.centerY = otherRect.centerY;

            const hitPoint = overlapsProcessRectWithRectObject(
              firstDragRect,
              firstDragObject,
              zoomScale,
              tmpRect
            );

            const dist = distProcessMapRects(firstDragRect, otherRect);
            if (hitPoint && dist < prevLineDist) {
              prevLineDist = dist;
              state.pendingConnectionLine = {
                lineRectHandle: {
                  objectIndex,
                  objectId: otherObject.id,
                  rectIndex,
                  rectId: otherRect.id
                }
              };
            }
          } else if (
            otherObject.type === ProcessMapObjectTypes.Symbol &&
            state.hoverRectHandles.some(
              (handle) => handle.objectId === otherObject.id
            )
          ) {
            const symbolObject = otherObject as ProcessMapSymbolObject;
            for (
              let connectionPointIndex = 0;
              connectionPointIndex < symbolObject.connections.length;
              connectionPointIndex++
            ) {
              const connectionPoint =
                symbolObject.connections[connectionPointIndex];
              const connectionPointCoords = ConnectionModelPoints(
                symbolObject,
                otherRect,
                connectionPoint
              );

              tmpRect.centerX = connectionPointCoords.x;
              tmpRect.centerY = connectionPointCoords.y;

              const hitPoint = overlapsProcessRectWithRectObject(
                firstDragRect,
                firstDragObject,
                zoomScale,
                tmpRect
              );
              const dist = distProcessMapRects(firstDragRect, tmpRect);

              if (hitPoint && dist < prevSymbolDist) {
                prevSymbolDist = dist;
                state.pendingConnectionSymbol = {
                  symbolHandle: {
                    objectIndex,
                    objectId: otherObject.id
                  },
                  connectionId: connectionPoint.id
                };
              }
            }
          }
        }
      }
    } else if (state.mouseState.dragState === MouseDragStates.DefiningArea) {
      const startX =
        Math.min(state.mouseState.mouseDownX, event.clientX) - svgRect.x;
      const startY =
        Math.min(state.mouseState.mouseDownY, event.clientY) - svgRect.y;
      const endX =
        Math.max(state.mouseState.mouseDownX, event.clientX) - svgRect.x;
      const endY =
        Math.max(state.mouseState.mouseDownY, event.clientY) - svgRect.y;

      const start = applyToPoint(state.transform.inverse, {
        x: startX,
        y: startY
      });
      const end = applyToPoint(state.transform.inverse, { x: endX, y: endY });

      state.defineAreaState = {
        startX: start.x,
        startY: start.y,
        endX: end.x,
        endY: end.y
      };
    } else if (
      state.mouseState.dragState === MouseDragStates.ResizingRect &&
      state.mouseState.rectSizeInfo != null
    ) {
      const rect =
        state.processMap.objects[state.mouseState.rectSizeInfo.objectIndex]
          .rects[state.mouseState.rectSizeInfo.rectIndex];
      const topLeftX = rect.centerX - rect.width / 2.0;
      const topLeftY = rect.centerY - rect.height / 2.0;
      const bottomRightX = rect.centerX + rect.width / 2.0;
      const bottomRightY = rect.centerY + rect.height / 2.0;

      switch (state.mouseState.rectSizeInfo.resizeArea) {
        case ProcessMapRectResizeAreas.TopLeft: {
          const newTopLeftX = topLeftX + dx;
          const newTopLeftY = topLeftY + dy;
          const newWidth = bottomRightX - newTopLeftX;
          const newHeight = bottomRightY - newTopLeftY;
          rect.centerX = newTopLeftX + newWidth / 2.0;
          rect.centerY = newTopLeftY + newHeight / 2.0;
          rect.width = newWidth;
          rect.height = newHeight;
          break;
        }
        case ProcessMapRectResizeAreas.BottomRight: {
          const newBottomRightX = bottomRightX + dx;
          const newBottomRightY = bottomRightY + dy;
          const newWidth = newBottomRightX - topLeftX;
          const newHeight = newBottomRightY - topLeftY;
          rect.centerX = topLeftX + newWidth / 2.0;
          rect.centerY = topLeftY + newHeight / 2.0;
          rect.width = newWidth;
          rect.height = newHeight;
          break;
        }
        default:
          break;
      }
    }
  },
  onMouseUp: (state: Draft<ProcessMapState>) => {
    state.isMouseDown = false;
    let needsUndoPush = state.mouseState.didMoveObject;
    state.mouseState.selectedRectRootIndex = 0;

    if (state.mouseState.dragState === MouseDragStates.DefiningArea) {
      state.selectedRectHandles = [...state.hoverRectHandles];
    }

    if (state.pendingConnectionLine != null) {
      const dragElementHandle = state.selectedRectHandles[0];
      const dragObject =
        state.processMap.objects[dragElementHandle.objectIndex];
      const dragElement = dragObject.rects[dragElementHandle.rectIndex];

      const pendingRect =
        state.processMap.objects[
          state.pendingConnectionLine.lineRectHandle.objectIndex
        ].rects[state.pendingConnectionLine.lineRectHandle.rectIndex];

      setProcessMapRectCenterRounded(
        dragElement,
        pendingRect.centerX,
        pendingRect.centerY
      );

      const newObject: ProcessMapLineConnection = {
        rectHandles: [
          {
            objectIndex: dragElementHandle.objectIndex,
            objectId: dragObject.id,
            rectIndex: dragElementHandle.rectIndex,
            rectId: dragElement.id
          },
          state.pendingConnectionLine.lineRectHandle
        ]
      };

      if (!_.find(state.processMap.lineConnections, newObject)) {
        state.processMap.lineConnections.push(newObject);
      }

      needsUndoPush = true;

      state.pendingConnectionLine = null;
    }

    if (state.pendingConnectionSymbol != null) {
      const dragElementHandle = state.selectedRectHandles[0];
      const dragObject =
        state.processMap.objects[dragElementHandle.objectIndex];
      const symbolObject = state.processMap.objects[
        state.pendingConnectionSymbol.symbolHandle.objectIndex
      ] as ProcessMapSymbolObject;
      const symbolRect = symbolObject.rects[0];
      const connectionPoint = symbolObject.connections.find(
        (c) => c.id === state.pendingConnectionSymbol.connectionId
      );
      const connectionPointCoords = ConnectionModelPoints(
        symbolObject,
        symbolRect,
        connectionPoint
      );
      const dragElement = dragObject.rects[dragElementHandle.rectIndex];

      setProcessMapRectCenterRounded(
        dragElement,
        connectionPointCoords.x,
        connectionPointCoords.y
      );

      const newObject: ProcessMapSymbolLineConnection = {
        symbolObjectHandle: state.pendingConnectionSymbol.symbolHandle,
        lineObjectRectHandle: {
          objectIndex: dragElementHandle.objectIndex,
          objectId: dragObject.id,
          rectIndex: dragElementHandle.rectIndex,
          rectId: dragElement.id
        },
        connectionId: state.pendingConnectionSymbol.connectionId
      };

      if (!_.find(state.processMap.symbolLineConnections, newObject)) {
        state.processMap.symbolLineConnections.push(newObject);
      }

      needsUndoPush = true;
      state.pendingConnectionSymbol = null;
    }

    state.mouseState.dragState = MouseDragStates.Idle;
    state.mouseState.rectSizeInfo = null;
    state.defineAreaState = null;
    state.pendingConnectionLine = null;
    state.pendingConnectionSymbol = null;
    if (needsUndoPush) {
      ProcessMapEditorActionUtils.pushUndoStack(state);
    }
  },
  onUndo: (state: Draft<ProcessMapState>) => {
    ProcessMapEditorActionUtils.setUndoIndex(
      state,
      Math.max(0, state.undoStackIndex - 1)
    );
  },
  onRedo: (state: Draft<ProcessMapState>) => {
    ProcessMapEditorActionUtils.setUndoIndex(
      state,
      Math.min(state.undoStack.length - 1, state.undoStackIndex + 1)
    );
  },
  nudge: (state: Draft<ProcessMapState>, diffX: number, diffY: number) => {
    if (state.selectedRectHandles.length > 0) {
      for (const handle of state.selectedRectHandles) {
        const rect =
          state.processMap.objects[handle.objectIndex].rects[handle.rectIndex];
        setProcessMapRectCenterRounded(
          rect,
          rect.centerX + diffX,
          rect.centerY + diffY
        );
      }
      ProcessMapEditorActionUtils.updateDraggedConnections(state);
      ProcessMapEditorActionUtils.pushUndoStack(state);
    } else {
      const currentTransform = compose(
        state.transform.current,
        translate(-diffX, -diffY)
      );
      ProcessMapEditorActionUtils.setTransform(state, {
        current: currentTransform,
        inverse: inverse(currentTransform)
      });
    }
  },
  onKeyDown: (state: Draft<ProcessMapState>, event: KeyboardEvent) => {
    if (
      event.target instanceof HTMLInputElement ||
      event.target instanceof HTMLTextAreaElement
    ) {
      return;
    }

    if (keyboardEventIsUndo(event)) {
      event.preventDefault();
      ProcessMapEditorActions.onUndo(state);
    } else if (keyboardEventIsRedo(event)) {
      event.preventDefault();
      ProcessMapEditorActions.onRedo(state);
    }

    const nudgeAmount = event.shiftKey ? 10 : 1;
    switch (event.code) {
      case 'ArrowUp':
        ProcessMapEditorActions.nudge(state, 0, -nudgeAmount);
        break;
      case 'ArrowDown':
        ProcessMapEditorActions.nudge(state, 0, nudgeAmount);
        break;
      case 'ArrowLeft':
        ProcessMapEditorActions.nudge(state, -nudgeAmount, 0);
        break;
      case 'ArrowRight':
        ProcessMapEditorActions.nudge(state, nudgeAmount, 0);
        break;
      case 'KeyA':
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          ProcessMapEditorActionUtils.selectAll(state);
        }
        break;
      case 'Delete':
      case 'Backspace':
        ProcessMapEditorActionUtils.clearSelectedItems(state);
        event.preventDefault();
        break;
      default:
        break;
    }
  },
  onKeyUp: (_state: Draft<ProcessMapState>, _event: KeyboardEvent) => {},
  onCut: (state: Draft<ProcessMapState>) => {
    ProcessMapEditorActionUtils.onCopy(state);
    ProcessMapEditorActionUtils.clearSelectedItems(state);
  },
  onCopy: (state: Draft<ProcessMapState>) => {
    ProcessMapEditorActionUtils.onCopy(state);
  },
  onPaste: (state: Draft<ProcessMapState>) => {
    const pasteState = _.cloneDeep(state.copyState);
    const idTranslation: Record<string, string> = {};
    const rectIdTranslation: Record<string, string> = {};

    for (const object of pasteState.objects) {
      const newId = UUID.generate();
      idTranslation[object.id] = newId;
      object.id = newId;

      for (const rect of object.rects) {
        rect.centerX += 30;
        rect.centerY += 30;
        const newRectId = UUID.generate();
        rectIdTranslation[rect.id] = newRectId;
        rect.id = newRectId;
      }
    }

    for (const lineConnection of pasteState.lineConnections) {
      lineConnection.rectHandles[0].objectId =
        idTranslation[lineConnection.rectHandles[0].objectId];
      lineConnection.rectHandles[1].objectId =
        idTranslation[lineConnection.rectHandles[1].objectId];
    }

    for (const symbolLineConnection of pasteState.symbolLineConnections) {
      symbolLineConnection.symbolObjectHandle.objectId =
        idTranslation[symbolLineConnection.symbolObjectHandle.objectId];
      symbolLineConnection.lineObjectRectHandle.objectId =
        idTranslation[symbolLineConnection.lineObjectRectHandle.objectId];
    }

    _.merge(state.processMap.svgImages, pasteState.svgImages);
    state.processMap.objects.push(...pasteState.objects);
    state.processMap.lineConnections.push(...pasteState.lineConnections);
    state.processMap.symbolLineConnections.push(
      ...pasteState.symbolLineConnections
    );
    state.hoverRectHandles = [];
    state.selectedRectHandles = _.flatMap(
      pasteState.objects,
      (object, objectIdx) =>
        object.rects.map((r, rectIndex) => ({
          objectIndex:
            state.processMap.objects.length -
            pasteState.objects.length +
            objectIdx,
          rectIndex: rectIndex,
          objectId: object.id,
          rectId: r.id
        }))
    );

    ProcessMapEditorActionUtils.fixHandles(state);
    ProcessMapEditorActionUtils.pushUndoStack(state);
  },
  setZoom: (
    state: Draft<ProcessMapState>,
    newScaleFactor: number,
    x: number,
    y: number
  ) => {
    const oldScaleFactor = state.transform.current.a;

    const deltaScale = newScaleFactor / oldScaleFactor;
    const currentTransform = compose(
      translate(x, y),
      scale(deltaScale, deltaScale),
      translate(-x, -y),
      state.transform.current
    );

    ProcessMapEditorActionUtils.setTransform(state, {
      current: currentTransform,
      inverse: inverse(currentTransform)
    });
  },
  onMouseWheel: (state: Draft<ProcessMapState>, event: WheelEvent) => {
    if (state.mouseState.dragState === MouseDragStates.Panning) {
      return;
    }

    if (event.metaKey || event.ctrlKey) {
      const oldScaleFactor = state.transform.current.a;
      let newScaleFactor = oldScaleFactor * 1.1;

      if (event.deltaY < 0) {
        newScaleFactor = oldScaleFactor * 0.9;
      }

      ProcessMapEditorActions.setZoom(
        state,
        newScaleFactor,
        event.offsetX,
        event.offsetY
      );
    } else {
      const dx = event.deltaX;
      const dy = event.deltaY;

      const currentTransform = compose(
        state.transform.current,
        translate(dx, dy)
      );
      ProcessMapEditorActionUtils.setTransform(state, {
        current: currentTransform,
        inverse: inverse(currentTransform)
      });
    }
  },
  addExternalSvgAsImage: (
    state: Draft<ProcessMapState>,
    svgImageBase64: string,
    centerX: number,
    centerY: number,
    width: number,
    height: number
  ) => {
    const itemMd5 = md5(svgImageBase64);
    ProcessMapEditorActions.addSvg(state, svgImageBase64, itemMd5);
    ProcessMapEditorActions.addObject(state, {
      rects: [
        {
          centerX,
          centerY,
          width: width,
          height: height,
          id: UUID.generate()
        }
      ],
      typeId: null,
      id: UUID.generate(),
      type: ProcessMapObjectTypes.Symbol,
      svgMd5: itemMd5,
      connections: [],
      symbolRules: [],
      states: [],
      originalWidth: width,
      originalHeight: height
    });
  },
  importOldSvg: (
    state: Draft<ProcessMapState>,
    signalTypesNameMap: Record<string, SignalTypeResponseModel>,
    libraryData: SymbolModel[]
  ) => {
    importOldSvg(state, signalTypesNameMap, libraryData);
  },
  trimDocumentSize: (state: Draft<ProcessMapState>) => {
    const padding = 20;

    let minX = Number.MAX_SAFE_INTEGER;
    let minY = Number.MAX_SAFE_INTEGER;
    let maxX = Number.MIN_SAFE_INTEGER;
    let maxY = Number.MIN_SAFE_INTEGER;
    for (const object of state.processMap.objects) {
      for (const rect of object.rects) {
        minX = Math.min(minX, rect.centerX - rect.width / 2.0);
        minY = Math.min(minY, rect.centerY - rect.height / 2.0);
        maxX = Math.max(maxX, rect.centerX + rect.width / 2.0);
        maxY = Math.max(maxY, rect.centerY + rect.height / 2.0);
      }
    }
    minX -= padding;
    minY -= padding;

    if (minX < 0) {
      for (const object of state.processMap.objects) {
        for (const rect of object.rects) {
          rect.centerX -= minX;
        }
      }
      maxX -= minX;
      minX = 0;
    }

    if (minY < 0) {
      for (const object of state.processMap.objects) {
        for (const rect of object.rects) {
          rect.centerY -= minY;
        }
      }
      maxY -= minY;
      minY = 0;
    }

    minX = Math.max(0, minX - padding);
    minY = Math.max(0, minY - padding);
    maxX += padding;
    maxY += padding;

    for (const object of state.processMap.objects) {
      for (const rect of object.rects) {
        rect.centerX -= minX;
        rect.centerY -= minY;
      }
    }

    state.processMap.width = maxX - minX;
    state.processMap.height = maxY - minY;
    ProcessMapEditorActionUtils.pushUndoStack(state);
  },
  addObject: (state: Draft<ProcessMapState>, object: ProcessMapObject) => {
    const objectIndex = state.processMap.objects.length;
    state.processMap.objects.push(object);
    state.selectedRectHandles = object.rects.map((rect, rectIndex) => ({
      objectId: object.id,
      objectIndex: objectIndex,
      rectId: rect.id,
      rectIndex: rectIndex
    }));
    ProcessMapEditorActionUtils.pushUndoStack(state);
  },
  deleteSelectedLineRects: (state: Draft<ProcessMapState>) => {
    for (const handle of state.selectedRectHandles) {
      const object = state.processMap.objects[handle.objectIndex];
      _.remove(object.rects, (r) => r.id === handle.rectId);
    }

    _.remove(state.processMap.lineConnections, (obj) =>
      state.selectedRectHandles.some((handle) => {
        return obj.rectHandles.some(
          (objectHandle) => objectHandle.rectId === handle.rectId
        );
      })
    );
    _.remove(state.processMap.symbolLineConnections, (obj) =>
      state.selectedRectHandles.some((handle) => {
        return obj.lineObjectRectHandle.rectId === handle.rectId;
      })
    );

    state.pendingConnectionLine = null;
    state.pendingConnectionSymbol = null;
    state.hoverRectHandles = [];
    state.selectedRectHandles = [];
    ProcessMapEditorActionUtils.fixHandles(state);
    ProcessMapEditorActionUtils.pushUndoStack(state);
  },
  setShowDeleteConnections: (state: Draft<ProcessMapState>) => {
    state.showDeleteConnections = true;
  },
  onShowContextMenu: (state: Draft<ProcessMapState>) => {
    state.isMouseDown = false;
  },
  sendSelectedToFront: (state: Draft<ProcessMapState>) => {
    ProcessMapEditorActionUtils.reorderSelected(state, 'tofront');
  },
  sendSelectedForward: (state: Draft<ProcessMapState>) => {
    ProcessMapEditorActionUtils.reorderSelected(state, 'forward');
  },
  sendSelectedToBack: (state: Draft<ProcessMapState>) => {
    ProcessMapEditorActionUtils.reorderSelected(state, 'toback');
  },
  sendSelectedBack: (state: Draft<ProcessMapState>) => {
    ProcessMapEditorActionUtils.reorderSelected(state, 'back');
  },
  clearSelectedItems: (state: Draft<ProcessMapState>) => {
    ProcessMapEditorActionUtils.clearSelectedItems(state);
  },

  updateObject: (
    state: Draft<ProcessMapState>,
    objectIndex: number,
    key: string[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    library: SymbolModel[]
  ) => {
    const object = state.processMap.objects[objectIndex];
    _.set(object, key, value);

    if (object.type === ProcessMapObjectTypes.Symbol) {
      ProcessMapEditorActionUtils.updateDraggedConnections(state);
      // When the user changes the image (referenced via svgMd5) we need
      // to update associated properties that are copied from the symbol object.
      if (key[0] === 'svgMd5') {
        const libraryItem = library.find((x) => md5(x.data) === value);
        ProcessMapEditorActionUtils.addSvg(state, libraryItem.data, value);
        object.rects[0].width = libraryItem.width;
        object.rects[0].height = libraryItem.height;
        object.states = libraryItem.states;
        object.connections = libraryItem.connections;
        object.typeId = libraryItem.id;
        object.originalWidth = libraryItem.width;
        object.originalHeight = libraryItem.height;
      } else if (key[0] === 'scale') {
        object.rects[0].width = object.originalWidth * object.scale;
        object.rects[0].height = object.originalHeight * object.scale;
      }
    } else if (object.type === ProcessMapObjectTypes.Line) {
      if (key[0] === 'mode') {
        if (value === ProcessMapLineModes.Straight) {
          const firstRect = object.rects[0];
          const lastRect = object.rects[object.rects.length - 1];
          object.rects = [firstRect, lastRect];
        }
      }
    }

    ProcessMapEditorActionUtils.pushUndoStack(state);
  },
  updateTextSize: (
    state: Draft<ProcessMapDocument>,
    objectIndex: number,
    rectIndex: number,
    width: number,
    height: number
  ) => {
    const object = state.objects[objectIndex] as
      | ProcessMapTextObject
      | ProcessMapSignalTextObject;
    if (object == null) {
      console.error(
        'ERROR! Null object in updateTextSize',
        objectIndex,
        rectIndex
      );
      return;
    }

    const rect = object.rects[rectIndex];
    resizeTextNode(
      object,
      rect,
      Math.max(processMapMinTextSize, width),
      Math.max(processMapMinTextSize, height)
    );
  },
  setRectResizeElement: (
    state: Draft<ProcessMapState>,
    objectIndex: number,
    rectIndex: number,
    resizeArea: ProcessMapRectResizeArea
  ) => {
    const object = state.processMap.objects[objectIndex];
    const rect = object.rects[rectIndex];
    state.mouseState.rectSizeInfo = {
      objectIndex,
      rectIndex,
      resizeArea,
      rectId: rect.id,
      objectId: object.id
    };
    state.mouseState.dragState = MouseDragStates.ResizingRect;
  },
  updateRect: (
    state: Draft<ProcessMapState>,
    objectIndex: number,
    rectIndex: number,
    update: Partial<ProcessMapRect>
  ) => {
    const object = state.processMap.objects[objectIndex];
    const rect = object.rects[rectIndex];
    _.merge(rect, update);
    ProcessMapEditorActionUtils.pushUndoStack(state);
  },
  updateDocument: (
    state: Draft<ProcessMapState>,
    key: string[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any
  ) => {
    if ((key[0] === 'width' || key[0] === 'height') && (value as number) < 0) {
      return;
    }

    _.set(state.processMap, key, value);
    ProcessMapEditorActionUtils.pushUndoStack(state);
  },
  clearHasChanges: (state: Draft<ProcessMapState>) => {
    state.hasChanges = false;
  }
};
