import React, { useContext, useEffect, useMemo } from 'react';
import { Switch, Route } from 'react-router-dom';
import BaseLocation from 'ecto-common/lib/BaseContainer/BaseLocation';
import EventHubService, {
  EventHubServiceContext,
  EVENT_HUB_SERVICE_DEFAULT_TIMEOUT_VALUE
} from 'ecto-common/lib/EventHubConnection/EventHubService';
import { AuthenticationContext } from 'ecto-common/lib/hooks/useAuthentication';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { storeCurrentNodeId } from 'ecto-common/lib/utils/cacheKeys';
import NoResourcesInfo from 'ecto-common/lib/BaseContainer/NoResourcesInfo';
import _ from 'lodash';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { ResourceType } from 'ecto-common/lib/constants';
import Page from 'ecto-common/lib/Page/Page';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { hasAccessToResource } from '../utils/accessAndRolesUtil';
import { getApiEnvironment } from 'ecto-common/lib/utils/apiEnvironment';

export type RouteItem = {
  component: React.FC;
  exact?: boolean;
  path: string;
  resourceTypes?: ResourceType[];
  hideIfHasResourceTypes?: ResourceType[];
};

interface BaseContainerProps {
  routes: RouteItem[];
  msalConfiguration: IPublicClientApplication;
  currentAccount: AccountInfo;
  allowNoLocation?: boolean;
}

const BaseContainer = ({
  routes,
  msalConfiguration,
  currentAccount,
  allowNoLocation = false
}: BaseContainerProps) => {
  const apiEnvironment = getApiEnvironment();
  const { contextSettings } = useContext(TenantContext);

  const connection = useMemo(
    () =>
      new EventHubService(
        contextSettings,
        EVENT_HUB_SERVICE_DEFAULT_TIMEOUT_VALUE,
        [apiEnvironment.scopes.gateway],
        msalConfiguration,
        currentAccount
      ),
    [currentAccount, msalConfiguration, apiEnvironment.scopes, contextSettings]
  );
  const contextParams = useMemo(
    () => ({
      msalConfiguration,
      scopes: apiEnvironment.scopes,
      currentAccount
    }),
    [msalConfiguration, currentAccount, apiEnvironment.scopes]
  );
  const nodeId = useCommonSelector((state) => state.general.nodeId);
  const { tenantResources, tenantId } = useContext(TenantContext);

  const filteredRoutes = useMemo(() => {
    return _.filter(
      routes,
      (route) =>
        (route.resourceTypes == null ||
          _.every(route.resourceTypes, (resourceType) =>
            hasAccessToResource(resourceType, tenantResources)
          )) &&
        (route.hideIfHasResourceTypes == null ||
          _.every(
            route.hideIfHasResourceTypes,
            (resourceType) =>
              !hasAccessToResource(resourceType, tenantResources)
          ))
    );
  }, [tenantResources, routes]);

  useEffect(() => {
    if (nodeId) {
      storeCurrentNodeId(tenantId, nodeId);
    }
  }, [nodeId, tenantId]);

  useEffect(() => {
    if (connection) {
      connection.connect();

      return () => {
        connection.disconnect();
      };
    }
  }, [connection]);

  return (
    <AuthenticationContext.Provider value={contextParams}>
      <EventHubServiceContext.Provider value={connection}>
        <Switch>
          {filteredRoutes.length === 0 && (
            <Page content={<NoResourcesInfo />} />
          )}
          {filteredRoutes.map((page, index) => (
            <Route key={index} {...page} />
          ))}
          <Route
            path="*"
            render={({ location }) => (
              <BaseLocation
                allowNoLocation={allowNoLocation}
                routes={filteredRoutes}
                location={location}
              />
            )}
          />
        </Switch>
      </EventHubServiceContext.Provider>
    </AuthenticationContext.Provider>
  );
};

export default BaseContainer;
