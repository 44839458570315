import { SignalProviderSignalResponseModel } from 'ecto-common/lib/API/APIGen';
import { LastSignalValuesResultWithMetadata } from '../Dashboard/panels/SignalListPanel';
import { Draft } from 'immer';
import {
  ProcessMapDocument,
  ProcessMapLineCapStyles,
  ProcessMapLineModes,
  ProcessMapLineObject,
  BaseProcessMapObject,
  ProcessMapObjectTypes,
  ProcessMapRect,
  ProcessMapRuleTypes,
  ProcessMapSignalTextObject,
  ProcessMapSymbolObject,
  ProcessMapTextAlignments,
  ProcessMapTextObject,
  processMapColorRules,
  processMapLineArrowLength
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import { ConnectionModel } from 'ecto-common/lib/API/PresentationAPIGen';

export const ConnectionModelPoints = (
  node: ProcessMapSymbolObject,
  rect: ProcessMapRect,
  connection: ConnectionModel
) => {
  let relConnectionX = connection.x;
  let relConnectionY = connection.y;

  if (node.flipHorizontal) {
    relConnectionX = 1 - relConnectionX;
  }

  if (node.flipVertical) {
    relConnectionY = 1 - relConnectionY;
  }

  // Convert relative coordinates to absolute
  let connectionX = relConnectionX * rect.width;
  let connectionY = relConnectionY * rect.height;

  // Translate point to the origin for rotation
  let translatedX = connectionX - rect.width / 2.0;
  let translatedY = connectionY - rect.height / 2.0;
  let rotation = node.rotation ?? 0;

  const radians = (Math.PI / 180) * rotation;

  // Calculate rotated coordinates and assign the rotated coordinates to connectionX and connectionY
  connectionX =
    translatedX * Math.cos(radians) - translatedY * Math.sin(radians);
  connectionY =
    translatedX * Math.sin(radians) + translatedY * Math.cos(radians);

  // Translate point back
  connectionX += rect.centerX;
  connectionY += rect.centerY;

  return {
    x: connectionX,
    y: connectionY
  };
};

/**
 * Each line object has two rects. But we rarely want to draw a line between the two rects - more often we want to
 * introduce a third "virtual" point so that all the lines are straight. This third point can be introduced in different
 * ways, ideally we want a very short line segment close to one of the points, then a long line to the other point. The
 * user can influence which point the short line segment is close to by the flipMidpoint setting.
 *
 * Another complexity is introduced by the end cap arrows. Sometimes the arrow doesn't fit in the short line segment.
 * If so, we try to place the mid point between the two points instead, making the two straight line equally long.
 * In this case we have to introduce two virtual midpoints.
 *
 * The shortenForArrows argument is used to shorten the line so that the arrow fits, which is required by the SVG
 * polyline element.
 *
 * @param line The line object to get extended points for
 * @param shortenForArrows Whether or not to reserve space for the arrows.
 * @param out The output object to write the points to. This is done to avoid allocating a new object every time.
 */
type LineCurveParams = {
  startDx: number;
  startDy: number;
  endDx: number;
  endDy: number;
  startLength: number;
  endLength: number;
};

export type LineObjectPointsReturnVal = {
  p0x: number;
  p0y: number;
  mid0x: number;
  mid0y: number;
  mid1x: number;
  mid1y: number;
  p1x: number;
  p1y: number;
};

const calculateLineCurveParams = (
  current: LineObjectPointsReturnVal,
  out: LineCurveParams
) => {
  out.startDx = current.mid0x - current.p0x;
  out.startDy = current.mid0y - current.p0y;
  out.startLength = Math.sqrt(
    out.startDx * out.startDx + out.startDy * out.startDy
  );
  out.endDx = current.p1x - current.mid1x;
  out.endDy = current.p1y - current.mid1y;
  out.endLength = Math.sqrt(out.endDx * out.endDx + out.endDy * out.endDy);
};

// Store this outside to keep from re-allocating all the time
const lineParams: LineCurveParams = {
  endDx: 0,
  endDy: 0,
  endLength: 0,
  startDx: 0,
  startDy: 0,
  startLength: 0
};

export const getLineObjectExtendedPoints = (
  line: ProcessMapLineObject,
  shortenForArrows: boolean,
  out: LineObjectPointsReturnVal
) => {
  out.p0x = line.rects[0].centerX;
  out.p0y = line.rects[0].centerY;
  out.p1x = line.rects[1].centerX;
  out.p1y = line.rects[1].centerY;

  let arrowLength = processMapLineArrowLength;

  const midX = (out.p0x + out.p1x) / 2.0;
  const midY = (out.p0y + out.p1y) / 2.0;
  out.mid0x = midX;
  out.mid0y = midY;
  out.mid1x = out.mid0x;
  out.mid1y = out.mid0y;
  let straightLine = out.p1x === out.p0x || out.p1y === out.p0y;
  calculateLineCurveParams(out, lineParams);

  if (!straightLine) {
    if (line.flipMidpoint) {
      out.mid0x = out.p1x;
      out.mid0y = out.p0y;
    } else {
      out.mid0x = out.p0x;
      out.mid0y = out.p1y;
    }

    out.mid1x = out.mid0x;
    out.mid1y = out.mid0y;
    calculateLineCurveParams(out, lineParams);

    // Arrow doesn't fit in our end or start segment, choose a new midpoint in the center of the line that fits
    if (
      (line.startLineEndcapStyle === ProcessMapLineCapStyles.Arrow &&
        lineParams.startLength <= arrowLength) ||
      (line.endLineCapStyle === ProcessMapLineCapStyles.Arrow &&
        lineParams.endLength <= arrowLength)
    ) {
      // Make the most space for the arrow by doing straight line out of the points and the
      // turn in the middle.
      if (Math.abs(out.p1x - out.p0x) > Math.abs(out.p1y - out.p0y)) {
        out.mid0x = midX;
        out.mid1x = midX;
        out.mid0y = out.p0y;
        out.mid1y = out.p1y;
      } else {
        out.mid0x = out.p0x;
        out.mid1x = out.p1x;
        out.mid0y = midY;
        out.mid1y = midY;
      }

      calculateLineCurveParams(out, lineParams);
    }
  }

  if (shortenForArrows) {
    if (line.startLineEndcapStyle === ProcessMapLineCapStyles.Arrow) {
      const normDx = lineParams.startDx / lineParams.startLength;
      const normDy = lineParams.startDy / lineParams.startLength;
      out.p0x += normDx * Math.min(arrowLength, lineParams.startLength);
      out.p0y += normDy * Math.min(arrowLength, lineParams.startLength);
    }

    if (line.endLineCapStyle === ProcessMapLineCapStyles.Arrow) {
      const normDx = lineParams.endDx / lineParams.endLength;
      const normDy = lineParams.endDy / lineParams.endLength;
      out.p1x -= normDx * Math.min(arrowLength, lineParams.endLength);
      out.p1y -= normDy * Math.min(arrowLength, lineParams.endLength);
    }
  }
};

// Store this outside to keep from re-allocating all the time
const lineObjectQuery: LineObjectPointsReturnVal = {
  p0x: 0,
  p0y: 0,
  mid0x: 0,
  mid0y: 0,
  mid1x: 0,
  mid1y: 0,
  p1x: 0,
  p1y: 0
};

export function* extendedLineRectIterator(
  line: ProcessMapLineObject
): Generator<ProcessMapRect> {
  if (line.mode === ProcessMapLineModes.Path) {
    for (let rectIndex = 0; rectIndex < line.rects.length; rectIndex++) {
      yield line.rects[rectIndex];
    }
  } else {
    yield line.rects[0];
    getLineObjectExtendedPoints(line, false, lineObjectQuery);
    yield {
      centerX: lineObjectQuery.mid0x,
      centerY: lineObjectQuery.mid0y,
      width: line.rects[0].width,
      height: line.rects[0].height,
      id: null
    };
    yield {
      centerX: lineObjectQuery.mid1x,
      centerY: lineObjectQuery.mid1y,
      width: line.rects[0].width,
      height: line.rects[0].height,
      id: null
    };
    yield line.rects[1];
  }
}

export function* symbolLineConnectionsIterator(
  document: ProcessMapDocument
): Generator<[ProcessMapRect, ProcessMapRect, number, number]> {
  for (let connection of document.symbolLineConnections) {
    const lineObject =
      document.objects[connection.lineObjectRectHandle.objectIndex];
    const symbolObject = document.objects[
      connection.symbolObjectHandle.objectIndex
    ] as ProcessMapSymbolObject;
    const lineRect =
      lineObject.rects[connection.lineObjectRectHandle.rectIndex];
    const symbolRect = symbolObject.rects[0];

    let connectionPoint = symbolObject.connections.find(
      (c) => c.id === connection.connectionId
    );
    // console.log(symbolRect, lineRect);

    if (connectionPoint != null) {
      let connectionPointCoords = ConnectionModelPoints(
        symbolObject,
        symbolRect,
        connectionPoint
      );
      yield [
        symbolRect,
        lineRect,
        connectionPointCoords.x,
        connectionPointCoords.y
      ];
    }
  }
}

export function* symbolConnectionsIterator(
  document: ProcessMapDocument,
  connectionCircleRadius: number
): Generator<[ProcessMapRect, ProcessMapSymbolObject, number, number, string]> {
  for (
    let symbolObjectIndex = 0;
    symbolObjectIndex < document.objects.length;
    symbolObjectIndex++
  ) {
    const symbolObject = document.objects[symbolObjectIndex];

    if (symbolObject.type !== ProcessMapObjectTypes.Symbol) {
      continue;
    }

    const symbolRect = symbolObject.rects[0];

    for (
      let connectionPointIndex = 0;
      connectionPointIndex < symbolObject.connections.length;
      connectionPointIndex++
    ) {
      const connectionPoint = symbolObject.connections[connectionPointIndex];
      let connectionPointCoords = ConnectionModelPoints(
        symbolObject,
        symbolRect,
        connectionPoint
      );

      const symbolConnectionRect: ProcessMapRect = {
        centerX: connectionPointCoords.x,
        centerY: connectionPointCoords.y,
        width: connectionCircleRadius * 2,
        height: connectionCircleRadius * 2,
        id: null
      };

      yield [
        symbolConnectionRect,
        symbolObject,
        symbolObjectIndex,
        connectionPointIndex,
        connectionPoint.id
      ];
    }
  }
}

export function* lineConnectionsIterator(
  document: ProcessMapDocument
): Generator<
  [
    ProcessMapRect,
    ProcessMapRect,
    number,
    BaseProcessMapObject,
    BaseProcessMapObject
  ]
> {
  for (
    let connectionIndex = 0;
    connectionIndex < document.lineConnections.length;
    connectionIndex++
  ) {
    let connection = document.lineConnections[connectionIndex];

    const lineObject1 = document.objects[
      connection.rectHandles[0].objectIndex
    ] as ProcessMapLineObject;
    const lineObject2 = document.objects[
      connection.rectHandles[1].objectIndex
    ] as ProcessMapLineObject;

    const lineRect1 = lineObject1.rects[connection.rectHandles[0].rectIndex];
    const lineRect2 = lineObject2.rects[connection.rectHandles[1].rectIndex];

    yield [lineRect1, lineRect2, connectionIndex, lineObject1, lineObject2];
  }
}

export function* rectIterator(
  document: ProcessMapDocument
): Generator<[ProcessMapRect, BaseProcessMapObject, number, number]> {
  for (
    let objectIndex = 0;
    objectIndex < document.objects.length;
    objectIndex++
  ) {
    let object = document.objects[objectIndex];
    for (let rectIndex = 0; rectIndex < object.rects.length; rectIndex++) {
      yield [object.rects[rectIndex], object, objectIndex, rectIndex];
    }
  }
}

export function* reverseRectIterator(
  document: ProcessMapDocument
): Generator<[ProcessMapRect, BaseProcessMapObject, number, number]> {
  for (
    let objectIndex = document.objects.length - 1;
    objectIndex >= 0;
    objectIndex--
  ) {
    let object = document.objects[objectIndex];
    for (let rectIndex = 0; rectIndex < object.rects.length; rectIndex++) {
      yield [object.rects[rectIndex], object, objectIndex, rectIndex];
    }
  }
}

export function prettifyXml(sourceXml: string) {
  const xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
  const xsltDoc = new DOMParser().parseFromString(
    [
      // describes how we want to modify the XML - indent everything
      '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
      '  <xsl:strip-space elements="*"/>',
      '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
      '    <xsl:value-of select="normalize-space(.)"/>',
      '  </xsl:template>',
      '  <xsl:template match="node()|@*">',
      '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
      '  </xsl:template>',
      '  <xsl:output indent="yes"/>',
      '</xsl:stylesheet>'
    ].join('\n'),
    'application/xml'
  );

  const xsltProcessor = new XSLTProcessor();
  xsltProcessor.importStylesheet(xsltDoc);
  const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
  const resultXml = new XMLSerializer().serializeToString(resultDoc);
  return resultXml;
}

export function setProcessMapRectCenterRounded(
  rect: ProcessMapRect,
  centerX: number,
  centerY: number
) {
  rect.centerX = Math.round(centerX);
  rect.centerY = Math.round(centerY);
}

const getSignalValue = (
  signalTypeId: string,
  allSignalsBySignalTypeId: Record<string, SignalProviderSignalResponseModel>,
  signalData: LastSignalValuesResultWithMetadata
) => {
  const signal = allSignalsBySignalTypeId[signalTypeId];
  return signalData[signal?.signalId]?.values?.[0]?.value;
};

export const evaluateProcessMapColorRule = (
  ruleName: string,
  signalTypeIds: string[],
  allSignalsBySignalTypeId: Record<string, SignalProviderSignalResponseModel>,
  signalData: LastSignalValuesResultWithMetadata
) => {
  const rule = processMapColorRules[ruleName];
  if (ruleName == null || signalTypeIds == null || rule == null) {
    return null;
  }

  if (rule.type === 'discrete') {
    const signalValue = getSignalValue(
      signalTypeIds[0],
      allSignalsBySignalTypeId,
      signalData
    );
    if (signalValue == null) {
      return null;
    }

    return signalValue === 1 ? rule.colors[0] : rule.colors[1];
  }
};

const defaultActiveStates = ['default'];

export const evaluateSymbolRules = (
  node: ProcessMapSymbolObject,
  allSignalsBySignalTypeId: Record<string, SignalProviderSignalResponseModel>,
  signalData: LastSignalValuesResultWithMetadata
) => {
  if (node.symbolRules == null || node.symbolRules.length === 0) {
    return defaultActiveStates;
  }

  let activeStates = ['default'];

  for (
    let ruleIndex = 0;
    ruleIndex < node.symbolRules?.length ?? 0;
    ruleIndex++
  ) {
    const rule = node.symbolRules[ruleIndex];
    const value = getSignalValue(
      rule.condition.signalTypeId,
      allSignalsBySignalTypeId,
      signalData
    );
    let evalResult = false;

    if (value != null) {
      switch (rule.condition.type) {
        case ProcessMapRuleTypes.Equal:
          evalResult = value === rule.condition.value;
          break;
        case ProcessMapRuleTypes.NotEqual:
          evalResult = value !== rule.condition.value;
          break;
        case ProcessMapRuleTypes.GreaterThan:
          evalResult = value > rule.condition.value;
          break;
        case ProcessMapRuleTypes.GreaterThanOrEqual:
          evalResult = value >= rule.condition.value;
          break;
        case ProcessMapRuleTypes.LessThan:
          evalResult = value < rule.condition.value;
          break;
        case ProcessMapRuleTypes.LessThanOrEqual:
          evalResult = value <= rule.condition.value;
          break;
        default:
          evalResult = false;
          break;
      }
    }

    if (evalResult) {
      activeStates.push(rule.state);
    }
  }

  return activeStates;
};
export function resizeTextNode(
  node: ProcessMapSignalTextObject | ProcessMapTextObject,
  rect: ProcessMapRect | Draft<ProcessMapRect>,
  newWidth: number,
  newHeight: number
) {
  if (
    node.textAlignment == null ||
    node.textAlignment === ProcessMapTextAlignments.Left
  ) {
    const leftX = rect.centerX - rect.width / 2.0;
    const topY = rect.centerY - rect.height / 2.0;
    rect.centerX = leftX + newWidth / 2.0;
    rect.centerY = topY + newHeight / 2.0;
  } else if (node.textAlignment === ProcessMapTextAlignments.Right) {
    const rightX = rect.centerX + rect.width / 2.0;
    const topY = rect.centerY - rect.height / 2.0;
    rect.centerX = rightX - newWidth / 2.0;
    rect.centerY = topY + newHeight / 2.0;
  }

  rect.width = newWidth;
  rect.height = newHeight;
}

export const distProcessMapRects = (
  rect1: ProcessMapRect,
  rect2: ProcessMapRect
) => {
  const dx = rect1.centerX - rect2.centerX;
  const dy = rect1.centerY - rect2.centerY;
  return Math.sqrt(dx * dx + dy * dy);
};

export const overlapsProcessMapRectWithRect = (
  rect: ProcessMapRect,
  otherRect: ProcessMapRect
): boolean => {
  const halfWidth = rect.width / 2.0;
  const halfHeight = rect.height / 2.0;

  const topLeftR1 = {
    x: rect.centerX - halfWidth,
    y: rect.centerY - halfHeight
  };
  const bottomRightR1 = {
    x: rect.centerX + halfWidth,
    y: rect.centerY + halfHeight
  };

  const halfWidthR2 = otherRect.width / 2.0;
  const halfHeightR2 = otherRect.height / 2.0;

  const topLeftR2 = {
    x: otherRect.centerX - halfWidthR2,
    y: otherRect.centerY - halfHeightR2
  };
  const bottomRightR2 = {
    x: otherRect.centerX + halfWidthR2,
    y: otherRect.centerY + halfHeightR2
  };

  return !(
    bottomRightR1.x < topLeftR2.x ||
    bottomRightR1.y < topLeftR2.y ||
    bottomRightR2.x < topLeftR1.x ||
    bottomRightR2.y < topLeftR1.y
  );
};

const tmpOverlapRect: ProcessMapRect = {
  centerX: 0,
  centerY: 0,
  width: 0,
  height: 0,
  id: null
};

export const overlapsProcessRectWithRectObject = (
  rect: ProcessMapRect,
  object: BaseProcessMapObject,
  zoomScale: number,
  otherRect: ProcessMapRect
): boolean => {
  tmpOverlapRect.centerX = rect.centerX;
  tmpOverlapRect.centerY = rect.centerY;

  // Line rects are scaled by the zoom scale, so we need to scale the overlap rect as well.
  // Ths is quite ugly, the width / height concept does not really work well with line points.
  if (object.type === ProcessMapObjectTypes.Line) {
    tmpOverlapRect.width = rect.width / zoomScale;
    tmpOverlapRect.height = rect.height / zoomScale;
  } else {
    tmpOverlapRect.width = rect.width;
    tmpOverlapRect.height = rect.height;
  }
  return overlapsProcessMapRectWithRect(tmpOverlapRect, otherRect);
};

export const overlapsProcessMapRectMouseCoord = (
  x: number,
  y: number,
  rect: ProcessMapRect,
  object: BaseProcessMapObject,
  zoomScale: number
) => {
  const padding = 5 / zoomScale;
  let halfWidth = rect.width / 2.0;
  let halfHeight = rect.height / 2.0;

  if (object.type === ProcessMapObjectTypes.Line) {
    halfWidth /= zoomScale;
    halfHeight /= zoomScale;
  }

  const topLeft = {
    x: rect.centerX - padding - halfWidth,
    y: rect.centerY - padding - halfHeight
  };
  const bottomRight = {
    x: rect.centerX + padding + halfWidth,
    y: rect.centerY + halfHeight + padding
  };

  const overlaps =
    x >= topLeft.x && x < bottomRight.x && y >= topLeft.y && y < bottomRight.y;

  if (overlaps) {
    return {
      x: x - rect.centerX,
      y: y - rect.centerY
    };
  }

  return null;
};
