import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import API from 'ecto-common/lib/API/API';

import rebootDevice from 'js/components/ManageEquipment/ProvisionDevice/rebootDevice';
import PingInput from 'js/components/EnergyManagers/HardwareActionInput/PingInput';
import RestartServiceInput from 'js/components/EnergyManagers/HardwareActionInput/RestartServiceInput';
import HeartbeatInput from 'js/components/EnergyManagers/HardwareActionInput/HeartbeatInput';
import RebootInput from 'js/components/EnergyManagers/HardwareActionInput/RebootInput';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { HardwareActionInputProps } from './HardwareActionInput/HardwareActionInputTypes';

export type HardwareActionEntry = {
  type: string;
  data: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    hasError: boolean;
    isLoading: boolean;
    cancelled: boolean;
    isSuccessful: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params: any;
  };
};

const deviceStatusEntry = (entry: HardwareActionEntry) =>
  _.get(entry, 'data.data.status', T.admin.iotdevicedetails.unknown);

type HardwareAction = {
  logFormats: {
    loadingRequest: (entry: HardwareActionEntry) => React.ReactNode;
    cancelledRequest: (entry: HardwareActionEntry) => React.ReactNode;
    failureRequest: (entry: HardwareActionEntry) => React.ReactNode;
    successEntry: (entry: HardwareActionEntry) => React.ReactNode;
    failureEntry: (entry: HardwareActionEntry) => React.ReactNode;
    type: string;
  };

  api: (
    contextSettings: ApiContextSettings,
    ...rest: unknown[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Promise<any>;
  responseProperty: [string, number] | number;
  component: React.FC<HardwareActionInputProps>;
  group: number;
};

const HardwareActions: Record<string, HardwareAction> = {
  pingRequest: {
    logFormats: {
      loadingRequest: (entry: HardwareActionEntry) =>
        T.format(
          T.admin.iotdevicedetails.performrequestping,
          entry.data.params
        ),
      cancelledRequest: () => T.admin.iotdevicedetails.cancelled,
      failureRequest: (entry: HardwareActionEntry) =>
        T.format(
          T.admin.iotdevicedetails.pingresponseerror,
          deviceStatusEntry(entry)
        ),
      successEntry: (entry: HardwareActionEntry) =>
        T.format(
          T.admin.iotdevicedetails.pingresponse,
          _.get(entry, 'data.data.responseTime', '-')
        ),
      failureEntry: (entry: HardwareActionEntry) =>
        T.format(
          T.admin.iotdevicedetails.pingresponseerror,
          deviceStatusEntry(entry)
        ),
      type: T.admin.iotdevicedetails.ping
    },
    api: API.Admin.IoTDevices.pingDevice,
    responseProperty: ['pingResponses', 0],
    component: PingInput,
    group: 0
  },
  restartServicesRequest: {
    logFormats: {
      loadingRequest: (entry: HardwareActionEntry) =>
        T.format(
          T.admin.iotdevicedetails.performrequestrestartservice,
          entry.data.params
        ),
      cancelledRequest: () => T.admin.iotdevicedetails.cancelled,
      failureRequest: () =>
        T.admin.iotdevicedetails.restartservicesresponseerror,
      successEntry: () => T.admin.iotdevicedetails.restartserviceresponse,
      failureEntry: (entry: HardwareActionEntry) =>
        T.format(
          T.admin.iotdevicedetails.restartservicesresponseerrorentry,
          deviceStatusEntry(entry)
        ),
      type: T.admin.iotdevicedetails.restartservice
    },
    api: API.Admin.IoTDevices.restartService,
    responseProperty: ['restartModuleResponses', 0],
    component: RestartServiceInput,
    group: 1
  },
  heartbeatRequest: {
    logFormats: {
      loadingRequest: () => T.admin.iotdevicedetails.performrequestheartbeat,
      cancelledRequest: () => T.admin.iotdevicedetails.cancelled,
      failureRequest: () => T.admin.iotdevicedetails.heartbeaterror,
      successEntry: () => T.admin.iotdevicedetails.heartbeatsuccess,
      failureEntry: (entry: HardwareActionEntry) =>
        T.format(
          T.admin.iotdevicedetails.heartbeatresponseerror,
          deviceStatusEntry(entry)
        ),
      type: T.admin.iotdevicedetails.heartbeattitle
    },
    api: API.Admin.IoTDevices.requestHeartbeat,
    responseProperty: 0,
    component: HeartbeatInput,
    group: 2
  },
  rebootRequest: {
    logFormats: {
      loadingRequest: () => T.admin.iotdevicedetails.performrequestreboot,
      cancelledRequest: () => T.admin.iotdevicedetails.cancelled,
      failureRequest: () => T.admin.iotdevicedetails.rebooterror,
      successEntry: () => T.admin.iotdevicedetails.rebootsuccess,
      failureEntry: (entry: HardwareActionEntry) => {
        // Special case for reboot until API has been updated
        if (_.get(entry, 'data.data.success') !== undefined) {
          return T.format(
            T.admin.iotdevicedetails.rebootresponseerror,
            deviceStatusEntry(entry)
          );
        }
        // Otherwise assume success
        return T.admin.iotdevicedetails.rebootsuccess;
      },
      type: T.admin.iotdevicedetails.reboot
    },
    api: rebootDevice,
    responseProperty: 0,
    component: RebootInput,
    group: 2
  }
};

export default HardwareActions;
