import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import Icons from 'ecto-common/lib/Icons/Icons';
import React from 'react';
import {
  AddOrUpdateDashboardCollectionRequestModel,
  DashboardCollectionResponseModel,
  DashboardResponseModel
} from 'ecto-common/lib/API/APIGen';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';

// This is so ugly, DashboardCollectionForm contains data both for the form and the table.
// TODO: Should be rewritten
export type DashboardFormAndTableEntry = Omit<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ModelDefinition<DashboardCollectionResponseModel, any>,
  'key'
> & {
  defaultValue: unknown;
  dataKey: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  key: (input: DashboardCollectionResponseModel) => any;
  linkColumn?: boolean;
};

export const DashboardCollectionForm: DashboardFormAndTableEntry[] = [
  {
    dataKey: 'dashboardCollectionId',
    key: (input) => input.dashboardCollectionId,
    defaultValue: null,
    modelType: ModelType.TEXT
  },
  {
    label: T.admin.dashboards.column.name,
    dataKey: 'name',
    key: (input) => input.name,
    defaultValue: '',
    hasError: isNullOrWhitespace,
    modelType: ModelType.TEXT,
    linkColumn: true
  },
  {
    label: T.admin.dashboards.column.description,
    key: (input) => input.description,
    dataKey: 'description',
    defaultValue: '',
    modelType: ModelType.TEXT
  },
  {
    dataKey: 'dashboards',
    key: (input) => input.dashboards,
    defaultValue: [] as string[],
    modelType: ModelType.TEXT
  },
  {
    dataKey: 'defaultDashboardId',
    key: (input) => input.defaultDashboardId,
    defaultValue: '',
    modelType: ModelType.TEXT
  }
];

const FormByKey = _.keyBy(DashboardCollectionForm, 'dataKey');

export const DashboardCollectionFormColumns = [
  {
    ...FormByKey.name,
    dataFormatter: (value: string) => (
      <>
        <Icons.Folder /> {value}{' '}
      </>
    ),
    width: 200
  },
  {
    ...FormByKey.description,
    width: 200
  }
];

/**
 * We use this type since it's easier to store the full selected dashboard objects on the form state itself,
 * and then convert to dashboardId:s once we save it.
 */
export type AddOrUpdateDashboardCollectionWithDashboardsRequestModel =
  AddOrUpdateDashboardCollectionRequestModel & {
    dashboards?: DashboardResponseModel[];
  };

export const initialFormState: AddOrUpdateDashboardCollectionWithDashboardsRequestModel =
  {
    dashboardCollectionId: null,
    name: '',
    description: '',
    dashboardIds: [],
    defaultDashboardId: ''
  };
