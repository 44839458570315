import React, { useState, useCallback, useMemo } from 'react';

import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import AddButton from 'ecto-common/lib/Button/AddButton';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import HelpPaths from 'ecto-common/help/tocKeys';

import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import ModelFormDialog from 'ecto-common/lib/ModelForm/ModelFormDialog';
import useReloadTrigger from 'ecto-common/lib/hooks/useReloadTrigger';
import NetmoreAccountsList from 'js/components/IntegrationAccounts/NetmoreIntegration/NetmoreAccountsList';
import NetmoreAPI from 'ecto-common/lib/utils/NetmoreAPI';
import { EXISTING_PASSWORD_PLACEHOLDER } from 'ecto-common/lib/utils/constants';
import { AccountResponse } from 'ecto-common/lib/API/NetmoreAPIGen';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';

type AccountResponseWithPassword = AccountResponse & {
  password: string;
};

const models: ModelDefinition<AccountResponseWithPassword>[] = [
  {
    key: (input) => input.username,
    modelType: ModelType.TEXT,
    label: T.admin.integration.netmore.form.username,
    hasError: isNullOrWhitespace
  },
  {
    key: (input) => input.description,
    modelType: ModelType.TEXT,
    label: T.admin.integration.netmore.form.description
  },
  {
    key: (input) => input.password,
    modelType: ModelType.TEXT,
    label: T.admin.integration.netmore.form.password,
    placeholder: EXISTING_PASSWORD_PLACEHOLDER,
    hasError: (value: string, object) => {
      return object?.id == null ? isNullOrWhitespace(value) : false;
    }
  }
];

interface NetmoreIntegrationAccountsProps {
  segmentControl?: React.ReactNode;
}

const NetmoreIntegrationAccounts = ({
  segmentControl
}: NetmoreIntegrationAccountsProps) => {
  const [currentAccount, setCurrentAccount] = useState<AccountResponse>(null);
  const [reloadAccounts, setReloadAccounts] = useReloadTrigger();

  const isEditing = currentAccount?.id != null;

  // ADD AND SAVE ACCOUNT
  const [addOrUpdateAccountIsLoading, addOrUpdateAccount] = usePromiseCall({
    promise: NetmoreAPI.addsOrUpdatesAccounts,
    onSuccess: () => {
      setReloadAccounts();
      setCurrentAccount(null);
      toastStore.addSuccessToastForUpdatedItem(
        T.admin.integration.netmore.account,
        !isEditing
      );
    },
    onError: () => {
      toastStore.addErrorToastForUpdatedItem(
        T.admin.integration.netmore.account,
        !isEditing
      );
    }
  });

  const onModalClose = useCallback(() => {
    setCurrentAccount(null);
  }, []);

  const onOpenCreateForm = useCallback(() => {
    setCurrentAccount({});
  }, []);

  const onOpenEditForm = useCallback((_account: AccountResponse) => {
    setCurrentAccount(() => ({
      ..._account
    }));
  }, []);

  const toolbarItems = useMemo(() => {
    return (
      <>
        {segmentControl}
        <ToolbarFlexibleSpace />
        <ToolbarItem>
          <AddButton onClick={onOpenCreateForm}>
            {T.admin.integration.netmore.add.account}
          </AddButton>
        </ToolbarItem>
      </>
    );
  }, [segmentControl, onOpenCreateForm]);

  return (
    <ToolbarContentPage
      title={T.admin.tabs.integrationaccounts}
      showLocationPicker={false}
      wrapContent={false}
      toolbarItems={toolbarItems}
      helpPath={HelpPaths.docs.admin.manage.integration_accounts.netmore}
    >
      <NetmoreAccountsList
        onSelectAccount={onOpenEditForm}
        reloadAccounts={reloadAccounts}
      />

      <ModelFormDialog
        models={models}
        input={currentAccount}
        addTitle={T.admin.integration.netmore.add.account}
        editTitle={T.admin.integration.netmore.edit.account}
        saveInput={addOrUpdateAccount}
        isSavingInput={addOrUpdateAccountIsLoading}
        onModalClose={onModalClose}
      />
    </ToolbarContentPage>
  );
};

export default React.memo(NetmoreIntegrationAccounts);
