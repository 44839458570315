import React, { useCallback, useEffect, useMemo, useState } from 'react';
import GoogleMapReact, { ChangeEventValue, Coords } from 'google-map-react';

import GeocodingInputField from 'ecto-common/lib/GeocodingInputField/GeocodingInputField';

import styles from 'ecto-common/lib/Map/Map.module.css';
import { darkModeStore } from 'ecto-common/lib/DarkMode/DarkMode';
import { useSyncExternalStore } from 'use-sync-external-store/shim';
import colors from 'ecto-common/lib/styles/variables/colors';

const googleMapsApiKey = 'AIzaSyAX6WMcSgrr8WkcqViU__ah91MIXC3sE7c';

const darkModeStyles = {
  backgroundColor: colors.darkBackgroundColor,
  styles: [
    { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
    { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
    { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
    {
      featureType: 'administrative.locality',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#d59563' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [{ color: '#38414e' }]
    },
    {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#212a37' }]
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#9ca5b3' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [{ color: '#746855' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#1f2835' }]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#f3d19c' }]
    },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [{ color: '#2f3948' }]
    },
    {
      featureType: 'transit.station',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#d59563' }]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#17263c' }]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [{ color: '#515c6d' }]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.stroke',
      stylers: [{ color: '#17263c' }]
    }
  ]
};

/*
 * MapTypeStyle interface: https://developers.google.com/maps/documentation/javascript/reference/map#MapTypeStyle
 * Style reference: https://developers.google.com/maps/documentation/javascript/style-reference#style-elements
 */
const initialOptions = {
  streetViewControl: false,
  keyboardShortcuts: false,
  mapTypeControl: false,
  fullscreenControl: false,
  styles: [
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'administrative',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off'
        }
      ]
    },
    {
      featureType: 'landscape',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'transit',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }]
    },
    {
      featureType: 'water',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }]
    }
  ]
};

export interface GoogleMapsApiPackage {
  LatLng?(latitude: number, longitude: number): void;
  Geocoder?(): void;
  places?: {
    PlacesService?(attributionRef: unknown): void;
    AutocompleteService?(): void;
    AutocompleteSessionToken?(): void;
  };
}

export type MapLocation = {
  street: string;
  lat: number;
  lng: number;
};

export type GoogleMapCoordinate = {
  lat: number;
  lng: number;
};

export type GoogleMapApi = {
  setZoom: (zoom: number) => void;
  panTo: (coordinate: GoogleMapCoordinate) => void;
};

export type MapsLoadedResult = {
  maps: GoogleMapsApiPackage;
  map: GoogleMapApi;
};

interface MapProps {
  children?: React.ReactNode;
  /**
   * The initial Map center.
   */
  center?: Coords;
  /**
   * The initial Map zoom level. Required. Valid values: Integers between zero, and up to the supported maximum zoom level.
   */
  zoom?: number;
  /**
   * To allow users to drag a shape to a different location on the map
   * */
  draggable?: boolean;
  /**
   * This event is fired when the user clicks on the map.
   * An ApiMouseEvent with properties for the clicked location is returned unless a place icon was clicked, in which case an IconMouseEvent with a placeId is returned.
   * IconMouseEvent and ApiMouseEvent are identical, except that IconMouseEvent has the placeId field.
   * The event can always be treated as an ApiMouseEvent when the placeId is not important.
   * The click event is not fired if a Marker or InfoWindow was clicked.
   */
  onClick?(): void;
  /**
   * This callback is called when the map instance has loaded. It is called with the map instance.
   */
  onMapsLoaded?(maps: MapsLoadedResult): void;
  /**
   * ({ center, zoom, bounds, marginBounds })
   */
  onChange?(event: ChangeEventValue): void;
  /**
   * A event listener callback that executes on all "drag" events
   */
  onDrag?(): void;
  /**
   * A event listener callback that executes when "drag" events end
   */
  onDragEnd?(): void;
  /**
   * A event listener callback that executes when a mouse or point events presses down
   */
  onChildMouseDown?(): void;
  /**
   * A event listener callback that executes when a mouse or point events releases the previous press down.
   */
  onChildMouseUp?(): void;
  /**
   * A event listener callback that executes when a mouse or point events moves the mouse or point event.
   */
  onChildMouseMove?(
    childkey: string,
    childProps: unknown,
    mouse: { lat: number; lng: number }
  ): void;
  /**
   * MapOptions object used to define the properties that can be set on a Map.
   */
  options?: object;
  /**
   * Activates the ability to search for addresses via google maps api
   */
  searchable?: boolean;
  /**
   * When "searchable" is active this callback method gets executed when a street address is selected from the search result and returns the street name.
   * Callback method for components that want to decide position of marker programmatically.
   * { street, lat, lng }
   */
  onSelectLocationFromSearch?(location: MapLocation, street: string): void;
  /**
   * Callback that changes the given map markers position if wanted.
   */
  setMarkerCenter?({ lat, lng }: { lat: number; lng: number }): void;
  /**
   * Sets the language of the map UI controls.
   */
  language?: string;
}

/*
 * Documentation for google-map-react:
 * https://github.com/google-map-react/google-map-react/blob/master/DOC.md
 */
const Map = ({
  children,
  center,
  zoom,
  draggable = true,
  onClick,
  onMapsLoaded,
  onChange,
  onDrag,
  onDragEnd,
  onChildMouseDown,
  onChildMouseUp,
  onChildMouseMove,
  options = {},
  searchable,
  setMarkerCenter,
  onSelectLocationFromSearch,
  language = 'en'
}: MapProps) => {
  const [maps, setMaps] = useState<GoogleMapsApiPackage>(null);
  const [streetSearch, setStreetSearch] = useState<string>(null);
  const [localCenter, setLocalCenter] = useState<Coords>(center);
  const darkModeEnabled = useSyncExternalStore(
    darkModeStore.subscribe,
    darkModeStore.getSnapshot
  );
  const onStreetSearch: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((event) => {
      setStreetSearch(event.target.value);
    }, []);

  const onGoogleApiLoaded = useCallback(
    (params: MapsLoadedResult) => {
      onMapsLoaded?.(params);

      if (searchable) {
        setMaps(params.maps);
      }
    },
    [onMapsLoaded, searchable]
  );

  const _options = useMemo(() => {
    return {
      ...initialOptions,
      ...options,
      ...(darkModeEnabled ? darkModeStyles : {})
    };
  }, [options, darkModeEnabled]);

  const bootstrapURLKeys = useMemo(
    () => ({ key: googleMapsApiKey, language, libraries: ['places'] }),
    [language]
  );

  const onSelectedLocation = useCallback(
    (location: MapLocation) => {
      const { lat, lng, street } = location;
      const _center = { lat, lng };

      setStreetSearch(null);
      onSelectLocationFromSearch?.(location, street);
      setLocalCenter(_center);
      setMarkerCenter?.(_center);
    },
    [onSelectLocationFromSearch, setMarkerCenter]
  );

  useEffect(() => {
    setLocalCenter(center);
  }, [center]);

  return (
    <div className={styles.container}>
      {searchable && (
        <div className={styles.searchField}>
          <GeocodingInputField
            maps={maps}
            value={streetSearch}
            onChange={onStreetSearch}
            onSelectedLocation={onSelectedLocation}
            latitude={center.lat}
            longitude={center.lng}
          />
        </div>
      )}

      <GoogleMapReact
        bootstrapURLKeys={bootstrapURLKeys}
        center={localCenter}
        zoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onClick={onClick}
        onChange={onChange}
        onGoogleApiLoaded={onGoogleApiLoaded}
        options={_options}
        onDrag={onDrag}
        onDragEnd={onDragEnd}
        draggable={draggable}
        onChildMouseDown={onChildMouseDown}
        onChildMouseUp={onChildMouseUp}
        onChildMouseMove={onChildMouseMove}
      >
        {children}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
