import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import T from 'ecto-common/lib/lang/Language';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import Icons from 'ecto-common/lib/Icons/Icons';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import API from 'ecto-common/lib/API/API';
import HelpPaths from 'ecto-common/help/tocKeys';

import AddAlarmSignalGroupTemplateDialog from 'js/components/ManageTemplates/AlarmSignalTemplates/AddAlarmSignalGroupTemplateDialog';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { AdminAlarmSignalGroupTemplateResponseModel } from 'ecto-common/lib/API/APIGen';

const _columns: DataTableColumnProps<AdminAlarmSignalGroupTemplateResponseModel>[] =
  [
    {
      dataKey: 'name',
      flexGrow: 1,
      width: 100,
      linkColumn: true,
      dataFormatter: (name: string) => (
        <>
          <Icons.File /> {name}{' '}
        </>
      )
    }
  ];

const AlarmSignalGroupTemplates = () => {
  const history = useHistory();
  const [addDialogVisible, showAddDialog, hideAddDialog] =
    useDialogState(false);
  const { tenantId } = useContext(TenantContext);

  const [isAddingGroupTemplate, createAlarmSignalGroupTemplate] =
    usePromiseCall({
      promise: API.Admin.Templates.updateAlarmTemplate,
      onSuccess: (_unused, args) => {
        hideAddDialog();
        toastStore.addSuccessToast(T.admin.requests.createtemplate.success);
        history.push(
          `/${tenantId}/templateManagement/alarms/${args.alarmSignalGroupTemplateId}`
        );
      },
      onError: () => {
        toastStore.addErrorToast(T.admin.requests.createtemplate.failure);
      }
    });

  const [templates, setTemplates] = useState<
    AdminAlarmSignalGroupTemplateResponseModel[]
  >([]);
  const [hasError, setHasError] = useState(false);
  const [isLoading, getAlarmTemplates] = usePromiseCall({
    promise: API.Admin.Templates.getAlarmTemplates,
    onSuccess: (result) => {
      setTemplates(result.sort((a, b) => a.name.localeCompare(b.name)));
    },
    onError: () => {
      setHasError(true);
    },
    initiallyLoading: true
  });

  useEffect(() => {
    getAlarmTemplates();
  }, [getAlarmTemplates]);

  const onClickRow = useCallback(
    (template: AdminAlarmSignalGroupTemplateResponseModel) => {
      history.push(
        `/${tenantId}/templateManagement/alarms/${template.alarmSignalGroupTemplateId}`
      );
    },
    [history, tenantId]
  );

  return (
    <ToolbarContentPage
      wrapContent={false}
      showLocationPicker={false}
      title={T.admin.alarmtemplates.header}
      addActionTitle={T.admin.alarmtemplates.addnew}
      addAction={showAddDialog}
      helpPath={HelpPaths.docs.admin.templates.alarms_types}
    >
      <AddAlarmSignalGroupTemplateDialog
        isLoading={isAddingGroupTemplate}
        isOpen={addDialogVisible}
        onAddAlarmTemplate={createAlarmSignalGroupTemplate}
        onModalClose={hideAddDialog}
      />
      <DataTable<AdminAlarmSignalGroupTemplateResponseModel>
        hasError={hasError}
        isLoading={isLoading}
        disableHeader
        onClickRow={onClickRow}
        data={templates}
        columns={_columns}
      />
    </ToolbarContentPage>
  );
};

export default AlarmSignalGroupTemplates;
