import React, { useEffect } from 'react';
import T from 'ecto-common/lib/lang/Language';
import { colors } from 'ecto-common/lib/styles/variables';
import HelpPaths from 'ecto-common/help/tocKeys';
import { CustomPanelProps } from 'ecto-common/lib/Dashboard/Panel';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import DataSourceTypes from 'ecto-common/lib/Dashboard/datasources/DataSourceTypes';

type NodeNamePanelProps = CustomPanelProps & {
  data: {
    node: SingleGridNode;
  };
};

const NodeNamePanel = ({ panelApi, data }: NodeNamePanelProps) => {
  const node = data?.node;
  useEffect(() => {
    if (node == null) {
      panelApi.setTitle(T.location.error.find);
    } else {
      panelApi.setTitle(node?.name);
    }
  }, [panelApi, node]);

  if (node == null) {
    return <div>{T.location.error.find}</div>;
  }

  return <div style={{ color: colors.surface3Color }}>{node?.street}</div>;
};

export const NodeNamePanelData = {
  version: 0,
  emptyTargets: {
    node: {
      sourceType: DataSourceTypes.NODE
    }
  },
  helpPath: HelpPaths.docs.dashboard.dashboards.location_panel
};

export default React.memo(NodeNamePanel);
