import React, { forwardRef, useCallback } from 'react';
import classNames from 'classnames';

import Icons from 'ecto-common/lib/Icons/Icons';
import FadeInFadeOutAnimation from 'ecto-common/lib/Animations/FadeInFadeOutAnimation';
import styles from './StyledMarkerPopup.module.css';
import { PointPropertiesType } from 'ecto-common/lib/Dashboard/panels/LocationMapPanel/DashboardMap';

type StyledMarkerPopupProps = {
  isVisible: boolean;
  properties: PointPropertiesType;
  onClick: () => void;
  isCurrentNode: boolean;
};

const StyledMarkerPopup = forwardRef<HTMLDivElement, StyledMarkerPopupProps>(
  ({ isVisible, properties, onClick, isCurrentNode }, ref) => {
    const { name, street } = properties;

    const _onClick = useCallback(() => {
      if (isCurrentNode) {
        return;
      }

      onClick?.();
    }, [isCurrentNode, onClick]);

    return (
      <FadeInFadeOutAnimation isVisible={isVisible}>
        <div
          className={classNames(styles.wrapper, isVisible && styles.isVisible)}
          ref={ref}
          onClick={_onClick}
        >
          <div
            className={classNames(
              styles.innerWrapper,
              isCurrentNode && styles.isCurrentNode
            )}
          >
            <div className={styles.container}>
              <div className={styles.information}>
                <div className={styles.name}>
                  <strong>{name}</strong>
                </div>

                <div>{street}</div>
              </div>

              {!isCurrentNode && (
                <div className={styles.button}>
                  <Icons.NavigationArrowRight />
                </div>
              )}
            </div>
          </div>

          <div className={styles.arrowContainer}>
            <div className={styles.arrow} />
          </div>
        </div>
      </FadeInFadeOutAnimation>
    );
  }
);

export default StyledMarkerPopup;
