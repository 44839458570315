import React, { useCallback, useEffect, useRef } from 'react';
import DateTime, { TimeConstraints } from 'react-datetime';
import classNames from 'classnames';
import { DEFAULT_TIMEZONE } from 'ecto-common/lib/constants';
import { dateRangeFromTimeRange } from 'ecto-common/lib/types/TimeRangeOptions';
import { getStartDateForTimeRange } from 'ecto-common/lib/types/TimeRangeOptions';
import { TimeRangeOptions } from 'ecto-common/lib/types/TimeRangeOptions';
import styles from './TimeRangeCalendar.module.css';
import { Moment } from 'moment/moment';
import { convertReactDatetimeToWrapAroundToNextUnit } from '../utils/dateUtils';

const timeRangeToViewMode = (timeRange: string) => {
  switch (timeRange) {
    case TimeRangeOptions.DAY:
      return 'days';
    case TimeRangeOptions.HOUR:
      return 'time';
    case TimeRangeOptions.WEEK:
      return 'days';
    case TimeRangeOptions.YEAR:
      return 'years';
    case TimeRangeOptions.MONTH:
      return 'months';
    case TimeRangeOptions.FIVE_YEARS_BACK:
      return 'years';
    default:
      return 'days';
  }
};

const NAVIGATION_DISABLED_OPTIONS: Record<string, string> = {
  [TimeRangeOptions.FIVE_YEARS_BACK]: 'years',
  [TimeRangeOptions.MONTH]: 'months',
  [TimeRangeOptions.YEAR]: 'years'
};

const TIME_CONSTRAINTS: TimeConstraints = {
  minutes: { min: 0, max: 0, step: 0 }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const renderWeekday = (dayProps: any, currentDate: any, selectedDate: any) => {
  const isInWeek =
    selectedDate !== false && currentDate.week() === selectedDate?.week();
  return (
    <td
      {...dayProps}
      className={classNames(dayProps.className, isInWeek && styles.weekDay)}
    >
      {currentDate.date()}
    </td>
  );
};

interface TimeRangeCalendarProps {
  referenceDate?: Date | string | Moment;
  onReferenceDateChanged(date: Moment): void;
  timeRangeOption?: string;
}

const TimeRangeCalendar = ({
  referenceDate,
  onReferenceDateChanged,
  timeRangeOption
}: TimeRangeCalendarProps) => {
  const ref = useRef(null);
  const viewMode = timeRangeToViewMode(timeRangeOption);

  useEffect(() => {
    ref.current?.navigate(viewMode);
  }, [viewMode]);

  useEffect(() => {
    if (timeRangeOption === TimeRangeOptions.HOUR && !referenceDate) {
      const range = dateRangeFromTimeRange(timeRangeOption, Date.now());
      onReferenceDateChanged(range.dateFrom);
    }
  }, [timeRangeOption, referenceDate, onReferenceDateChanged]);

  const prevVal = useRef<Moment>(null);
  const onChange = useCallback(
    (newDate: Moment) => {
      let _newDate = newDate;
      if (timeRangeOption === TimeRangeOptions.HOUR) {
        _newDate = convertReactDatetimeToWrapAroundToNextUnit(
          newDate,
          prevVal.current
        );
      }

      prevVal.current = _newDate;
      const newVal = getStartDateForTimeRange(timeRangeOption, _newDate);

      onReferenceDateChanged(newVal);

      if (timeRangeOption === TimeRangeOptions.HOUR) {
        ref.current?.navigate('hours');
      }
    },
    [timeRangeOption, onReferenceDateChanged]
  );

  const showTimePicker = timeRangeOption === TimeRangeOptions.HOUR;

  const onBeforeNavigate = useCallback(
    (_nextView: string, _currentView: string, viewDate: Moment) => {
      let forcedView = NAVIGATION_DISABLED_OPTIONS[timeRangeOption];
      if (forcedView != null && forcedView !== _nextView) {
        onReferenceDateChanged(
          getStartDateForTimeRange(timeRangeOption, viewDate)
        );
        return null;
      }

      return _nextView;
    },
    [timeRangeOption, onReferenceDateChanged]
  );

  return (
    <DateTime
      ref={ref}
      initialViewMode={viewMode}
      input={false}
      className={styles.calendar}
      timeFormat={showTimePicker}
      onBeforeNavigate={onBeforeNavigate}
      renderDay={
        timeRangeOption === TimeRangeOptions.WEEK ? renderWeekday : undefined
      }
      displayTimeZone={DEFAULT_TIMEZONE}
      timeConstraints={TIME_CONSTRAINTS}
      onChange={onChange}
      value={referenceDate}
    />
  );
};

export default React.memo(TimeRangeCalendar);
