import { createReducer } from 'ecto-common/lib/utils/reducerUtils';
import {
  initialReqState,
  REQ_STATE_SUCCESS,
  RequestStatusRawProp,
  updateReqState
} from 'ecto-common/lib/utils/requestStatus';
import API, {
  performAsyncRequestWithStateChanges
} from 'ecto-common/lib/API/API';
import { createAction } from 'ecto-common/lib/utils/actionUtils';
import {
  EquipmentTypes,
  getEnergyManagerEquipmentTypeId,
  isEquipmentTypeEnergyManager
} from 'ecto-common/lib/utils/equipmentTypeUtils';
import UUID from 'uuidjs';
import { updateNodeTreeIncrementally } from 'js/modules/provisioningCommon/provisioningCommon';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import { AlarmSignalGroupTemplateIds } from 'ecto-common/lib/utils/constants';
import { AdminDispatch, AdminGetState } from 'js/reducers/storeAdmin';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import {
  AddOrUpdateEnergyManagerByEquipmentTypeRequestModel,
  EquipmentResponseModel,
  EquipmentTypeResponseModel,
  NodeEquipmentResponseModel,
  ToolType
} from 'ecto-common/lib/API/APIGen';

const SET_ADD_EQUIPMENT_SHOW_DIALOG = 'SET_ADD_EQUIPMENT_SHOW_DIALOG';

const SET_ADD_EQUIPMENT_FORM_NAME = 'SET_ADD_EQUIPMENT_FORM_NAME';
const SET_ADD_EQUIPMENT_FORM_DESCRIPTION = 'SET_ADD_EQUIPMENT_FORM_DESCRIPTION';
const SET_ADD_EQUIPMENT_FORM_ADD_REQUEST_STATE =
  'SET_ADD_EQUIPMENT_FORM_ADD_REQUEST_STATE';

const SET_ADD_EQUIPMENT_SHOW_DEVICE_SELECTOR =
  'SET_ADD_EQUIPMENT_SHOW_DEVICE_SELECTOR';
const SET_ADD_EQUIPMENT_DEVICE_EQUIPMENT_ID =
  'SET_ADD_EQUIPMENT_DEVICE_EQUIPMENT_ID';
const SET_ADD_EQUIPMENT_EQUIPMENT_TYPE_ID =
  'SET_ADD_EQUIPMENT_EQUIPMENT_TYPE_ID';
const SET_ADD_EQUIPMENT_SELECTED_TOOL_TYPE_IDS =
  'SET_ADD_EQUIPMENT_SELECTED_TOOL_TYPE_IDS';
const SET_ADD_EQUIPMENT_ALARM_SIGNAL_GROUP_TEMPLATE_ID =
  'SET_ADD_EQUIPMENT_ALARM_SIGNAL_GROUP_TEMPLATE_ID';
const SET_ADD_EQUIPMENT_STATE = 'SET_ADD_EQUIPMENT_STATE';

export enum AddEquipmentState {
  IDLE = 'IDLE',
  ADDING = 'ADDING',
  ADDED = 'ADDED'
}

type AddEquipmentReducerProps = {
  dialogIsOpen: boolean;
  name: string;
  description: string;
  deviceEquipmentId: string;
  equipmentTypeId: string;
  selectedToolTypeIds: string[];
  alarmSignalGroupTemplateId: string;
  forceAddEnergyManager: boolean;
  showDeviceSelector: boolean;
  existingEnergyManager: EquipmentResponseModel;
  devices: NodeEquipmentResponseModel[];
  equipmentTypes: EquipmentTypeResponseModel[];
  addRequest: RequestStatusRawProp<void>;
  targetNodeId: string;
  isValid: boolean;
  addState: AddEquipmentState;
};

const initialState: AddEquipmentReducerProps = {
  dialogIsOpen: false,
  name: '',
  description: '',
  deviceEquipmentId: null,
  equipmentTypeId: null,
  selectedToolTypeIds: [],
  alarmSignalGroupTemplateId: null,
  forceAddEnergyManager: false,
  showDeviceSelector: false,
  existingEnergyManager: null,
  devices: [],
  equipmentTypes: [],
  addRequest: initialReqState,
  targetNodeId: null,
  isValid: false,
  addState: AddEquipmentState.IDLE
};

const _checkIsValid = (state: AddEquipmentReducerProps) => {
  if (state.equipmentTypeId == null || isNullOrWhitespace(state.name)) {
    return false;
  }

  if (
    state.equipmentTypeId ===
    getEnergyManagerEquipmentTypeId(state.equipmentTypes)
  ) {
    return true;
  }

  return state.deviceEquipmentId != null;
};

export default createReducer(initialState, {
  [SET_ADD_EQUIPMENT_FORM_NAME]: (state, { name }) => {
    const newState = { ...state, name };
    return { ...newState, isValid: _checkIsValid(newState) };
  },
  [SET_ADD_EQUIPMENT_FORM_DESCRIPTION]: (state, { description }) => {
    return { ...state, description };
  },
  [SET_ADD_EQUIPMENT_SHOW_DEVICE_SELECTOR]: (state, { showDeviceSelector }) => {
    return { ...state, showDeviceSelector };
  },
  [SET_ADD_EQUIPMENT_SHOW_DIALOG]: (
    state,
    {
      dialogIsOpen,
      forceAddEnergyManager,
      devices,
      equipmentTypes,
      targetNodeId
    }
  ) => {
    let stateToUse = state;

    if (dialogIsOpen && !state.dialogIsOpen) {
      stateToUse = initialState;

      if (forceAddEnergyManager) {
        stateToUse = {
          ...stateToUse,
          equipmentTypeId: EquipmentTypes.ENERGY_MANAGER,
          alarmSignalGroupTemplateId:
            AlarmSignalGroupTemplateIds.ENERGY_MANAGER_ALARMS
        };
      } else if (equipmentTypes.length > 0 && devices.length > 0) {
        stateToUse = {
          ...stateToUse,
          equipmentTypeId: equipmentTypes[0].equipmentTypeId,
          deviceEquipmentId: devices[0].equipmentId
        };
      }
    }

    return {
      ...stateToUse,
      forceAddEnergyManager,
      dialogIsOpen,
      devices,
      equipmentTypes,
      targetNodeId,
      isValid: _checkIsValid(stateToUse)
    };
  },
  [SET_ADD_EQUIPMENT_DEVICE_EQUIPMENT_ID]: (state, { deviceEquipmentId }) => {
    const newState = { ...state, deviceEquipmentId };
    return { ...newState, isValid: _checkIsValid(newState) };
  },
  [SET_ADD_EQUIPMENT_EQUIPMENT_TYPE_ID]: (state, { equipmentTypeId }) => {
    const newState = { ...state, equipmentTypeId };
    let { alarmSignalGroupTemplateId } = state;

    if (equipmentTypeId === EquipmentTypes.ENERGY_MANAGER) {
      alarmSignalGroupTemplateId =
        AlarmSignalGroupTemplateIds.ENERGY_MANAGER_ALARMS;
    } else if (
      alarmSignalGroupTemplateId ===
      AlarmSignalGroupTemplateIds.ENERGY_MANAGER_ALARMS
    ) {
      alarmSignalGroupTemplateId = null;
    }

    return {
      ...newState,
      alarmSignalGroupTemplateId,
      isValid: _checkIsValid(newState)
    };
  },
  [SET_ADD_EQUIPMENT_SELECTED_TOOL_TYPE_IDS]: (
    state,
    { selectedToolTypeIds }
  ) => {
    return { ...state, selectedToolTypeIds };
  },
  [SET_ADD_EQUIPMENT_ALARM_SIGNAL_GROUP_TEMPLATE_ID]: (
    state,
    { alarmSignalGroupTemplateId }
  ) => {
    return { ...state, alarmSignalGroupTemplateId };
  },
  [SET_ADD_EQUIPMENT_STATE]: (state, { addState }) => {
    return { ...state, addState };
  },
  [SET_ADD_EQUIPMENT_FORM_ADD_REQUEST_STATE]: (state, payload) => {
    return { ...state, addRequest: updateReqState(state.addRequest, payload) };
  }
});

const _setState = createAction(SET_ADD_EQUIPMENT_STATE, 'addState');

export const AddEquipmentFormActions = {
  setName: createAction(SET_ADD_EQUIPMENT_FORM_NAME, 'name'),
  setDescription: createAction(
    SET_ADD_EQUIPMENT_FORM_DESCRIPTION,
    'description'
  ),
  setShowDeviceSelector: createAction(
    SET_ADD_EQUIPMENT_SHOW_DEVICE_SELECTOR,
    'showDeviceSelector'
  ),
  setShowDialog: createAction(
    SET_ADD_EQUIPMENT_SHOW_DIALOG,
    'dialogIsOpen',
    'forceAddEnergyManager',
    'devices',
    'equipmentTypes',
    'targetNodeId'
  ),
  hideDialog: () =>
    AddEquipmentFormActions.setShowDialog(false, false, [], [], null, null),
  setDeviceEquipmentId: createAction(
    SET_ADD_EQUIPMENT_DEVICE_EQUIPMENT_ID,
    'deviceEquipmentId'
  ),
  setEquipmentTypeId: createAction(
    SET_ADD_EQUIPMENT_EQUIPMENT_TYPE_ID,
    'equipmentTypeId'
  ),
  setSelectedToolTypeIds: createAction(
    SET_ADD_EQUIPMENT_SELECTED_TOOL_TYPE_IDS,
    'selectedToolTypeIds'
  ),
  setAlarmSignalGroupTemplateId: createAction(
    SET_ADD_EQUIPMENT_ALARM_SIGNAL_GROUP_TEMPLATE_ID,
    'alarmSignalGroupTemplateId'
  ),
  createEquipment: (
    contextSettings: ApiContextSettings,
    existingEnergyManager: EquipmentResponseModel
  ) => {
    return async (dispatch: AdminDispatch, getState: AdminGetState) => {
      const { addEquipmentForm, general } = getState();
      const { connectionModbusConfigDefaults } = general.enums;

      const {
        isValid,
        equipmentTypes,
        name,
        description,
        targetNodeId,
        equipmentTypeId,
        deviceEquipmentId,
        selectedToolTypeIds,
        alarmSignalGroupTemplateId
      } = addEquipmentForm;

      if (!isValid) {
        return;
      }

      const isEnergyManager = isEquipmentTypeEnergyManager(
        equipmentTypeId,
        equipmentTypes
      );

      const sharedBody = {
        name,
        description,
        equipmentTypeId,
        equipmentId: UUID.generate(),
        nodeId: targetNodeId,
        alarmSignalGroupTemplateId
      };

      const performAdd = async () => {
        if (isEnergyManager) {
          let deviceId = UUID.generate();

          if (existingEnergyManager) {
            const deviceInfo = await API.Admin.Devices.getDeviceInfo(
              contextSettings,
              existingEnergyManager.equipmentId
            );
            deviceId = deviceInfo.deviceId;
          }

          const emBody: AddOrUpdateEnergyManagerByEquipmentTypeRequestModel = {
            ...sharedBody,
            deviceId,
            connectionModbusConfig: connectionModbusConfigDefaults,
            toolTypes: []
          };

          await API.Admin.Equipments.createEnergyManager(
            contextSettings,
            emBody
          );
        } else {
          const eqBody = {
            ...sharedBody,
            deviceEquipmentId: deviceEquipmentId,
            toolTypes: selectedToolTypeIds as ToolType[]
          };

          await API.Admin.Equipments.createEquipments(contextSettings, [
            eqBody
          ]);
        }

        await updateNodeTreeIncrementally(
          contextSettings,
          targetNodeId,
          dispatch
        );
      };

      dispatch(_setState(AddEquipmentState.ADDING));

      await performAsyncRequestWithStateChanges(
        dispatch,
        performAdd,
        SET_ADD_EQUIPMENT_FORM_ADD_REQUEST_STATE
      );

      const reqState = getState().addEquipmentForm.addRequest;

      if (reqState.state === REQ_STATE_SUCCESS) {
        dispatch(_setState(AddEquipmentState.ADDED));
        dispatch(AddEquipmentFormActions.hideDialog());
      } else {
        dispatch(_setState(AddEquipmentState.IDLE));
      }
    };
  },
  resetAddState: () => (dispatch: AdminDispatch) =>
    dispatch(_setState(AddEquipmentState.IDLE))
};
