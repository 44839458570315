import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import ProcessMapTable from 'ecto-common/lib/ProcessMaps/ProcessMapTable';
import T from 'ecto-common/lib/lang/Language';
import {
  calculateDataTableMinHeight,
  standardColumns
} from 'ecto-common/lib/utils/dataTableUtils';
import Icons from 'ecto-common/lib/Icons/Icons';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import API, {
  CancellablePromise,
  CancellablePromiseCallback
} from 'ecto-common/lib/API/API';
import DataTable from 'ecto-common/lib/DataTable/DataTable';
import {
  cancellablePromiseList,
  cancellablePromiseSequence
} from 'ecto-common/lib/API/API';
import _ from 'lodash';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import styles from './SelectProcessMapDialog.module.css';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import {
  EquipmentTypeProcessMapRelationshipResponseModel,
  NodeProcessMapRelationshipResponseModel,
  ProcessMapResponseModel
} from 'ecto-common/lib/API/APIGen';

const topColumns = [
  {
    label: T.admin.processmaps.name,
    dataKey: 'name',
    idKey: 'id',
    linkColumn: true,
    dataFormatter: (value: string) => (
      <>
        <Icons.File /> {value}{' '}
      </>
    )
  }
];

/**
 * Theoretically we could fetch only the process map ID in this promise and just
 * get the process map from our complete collection. However, I think we will need
 * to paginate the process map list in the future so I anticipate that and get the
 * specific process map in a separate call instead.
 */
const getRelationPromise = (
  contextSettings: ApiContextSettings,
  nodeId: string,
  equipmentTypeId: string
) => {
  return cancellablePromiseSequence<
    [
      processMaps: ProcessMapResponseModel[],
      eqProcessMaps: ProcessMapResponseModel[]
    ]
  >((withNextPromise: CancellablePromiseCallback) => {
    return withNextPromise(
      withNextPromise(
        cancellablePromiseList([
          nodeId
            ? API.Admin.ProcessMaps.getProcessMapByNodeId(
                contextSettings,
                nodeId
              )
            : Promise.resolve(),
          equipmentTypeId
            ? API.Admin.ProcessMaps.getProcessMapByEquipmentTypeId(
                contextSettings,
                equipmentTypeId
              )
            : Promise.resolve()
        ])
      )
    ).then(
      (
        relations: [
          NodeProcessMapRelationshipResponseModel[],
          EquipmentTypeProcessMapRelationshipResponseModel[]
        ]
      ) => {
        const nodeRelations: NodeProcessMapRelationshipResponseModel[] =
          relations[0];
        const relation = _.head(nodeRelations);
        const equipmentRelations: EquipmentTypeProcessMapRelationshipResponseModel[] =
          relations[1];
        const equipmentTypeRelation = _.head(equipmentRelations);

        return cancellablePromiseList([
          relation?.processMapId
            ? API.Admin.ProcessMaps.getProcessMapById(
                contextSettings,
                relation.processMapId
              )
            : Promise.resolve(),
          equipmentTypeRelation?.processMapId
            ? API.Admin.ProcessMaps.getProcessMapById(
                contextSettings,
                equipmentTypeRelation.processMapId
              )
            : Promise.resolve()
        ]);
      }
    );
  });
};

const updateRelationPromise = (
  contextSettings: ApiContextSettings,
  nodeId: string,
  equipmentTypeId: string,
  processMapId: string
): CancellablePromise<unknown> => {
  const idToUse = nodeId ?? equipmentTypeId;

  const updateApi =
    nodeId != null
      ? API.Admin.ProcessMaps.updateNodeProcessMapRelation
      : API.Admin.ProcessMaps.updateEquipmentTypeProcessMapRelation;

  const deleteApi =
    nodeId != null
      ? API.Admin.ProcessMaps.deleteNodeProcessMapRelation
      : API.Admin.ProcessMaps.deleteEquipmentTypeProcessMapRelation;

  if (processMapId == null) {
    return deleteApi(contextSettings, idToUse);
  }

  return updateApi(contextSettings, idToUse, processMapId);
};

interface SelectProcessMapDialogProps {
  isOpen?: boolean;
  onModalClose: () => void;
  nodeId?: string;
  equipmentTypeId?: string;
  onConfirm?: (processMap: ProcessMapResponseModel) => void;
  actionText?: React.ReactNode;
}

const SelectProcessMapDialog = ({
  isOpen,
  onModalClose,
  nodeId,
  equipmentTypeId,
  onConfirm = null,
  actionText = T.common.save
}: SelectProcessMapDialogProps) => {
  const [searchString, setSearchString] = useState('');
  const [selectedData, setSelectedData] = useState<ProcessMapResponseModel[]>(
    []
  );
  const [equipmentDefaultMap, setEquipmentDefaultMap] =
    useState<ProcessMapResponseModel>(null);
  const [hasError, setHasError] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [items, setItems] = useState<Record<string, ProcessMapResponseModel>>(
    {}
  );

  const onClickRow = useCallback((object: ProcessMapResponseModel) => {
    setHasChanges(true);
    setSelectedData((oldSelectedData) => {
      if (_.head(oldSelectedData)?.id === object.id) {
        return [];
      }

      return [object];
    });
  }, []);

  const [isLoadingRelation, loadRelation, cancelLoadRelation] = usePromiseCall({
    promise: getRelationPromise,
    onSuccess: (processMaps) => {
      if (nodeId == null) {
        // when we don't have a node id, we are in select process map for templates
        // So we switch the result here
        setSelectedData(processMaps[1]);
        setEquipmentDefaultMap(null);
      } else {
        setSelectedData(processMaps[0] ?? []);
        setEquipmentDefaultMap(_.head(processMaps[1]));
      }
    },
    onError: () => {
      setHasError(true);
    }
  });

  const [isSavingRelation, saveRelation] = usePromiseCall({
    promise: updateRelationPromise,
    onSuccess: () => {
      onModalClose();
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.processmaps.error.update);
    }
  });

  const [getProcessMapsIsLoading, getProcessMaps] = usePromiseCall({
    promise: API.Admin.ProcessMaps.getAllProcessMaps,
    onSuccess: (response) => {
      const _items = _.keyBy(response, 'id');
      setItems(_items);
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.processmaps.maps.error);
    }
  });

  useEffect(() => {
    if (isOpen) {
      setSelectedData([]);
      setHasChanges(false);
      setHasError(false);
      loadRelation(nodeId, equipmentTypeId);
      getProcessMaps();
    } else {
      cancelLoadRelation();
    }
  }, [
    isOpen,
    nodeId,
    equipmentTypeId,
    cancelLoadRelation,
    loadRelation,
    getProcessMaps
  ]);

  const onDelete = useCallback(() => {
    setSelectedData([]);
    setHasChanges(true);
  }, []);

  const allTopColumns = useMemo(() => {
    return [...topColumns, ...standardColumns({ onDelete })];
  }, [onDelete]);

  const onConfirmClick = useCallback(() => {
    if (onConfirm) {
      onConfirm(_.head(selectedData));
    } else {
      saveRelation(nodeId, equipmentTypeId, _.head(selectedData)?.id);
    }
  }, [onConfirm, selectedData, saveRelation, nodeId, equipmentTypeId]);

  const onChangeSearchString = useCallback(
    (changeEvent: React.ChangeEvent<HTMLInputElement>) =>
      setSearchString(changeEvent.target.value),
    []
  );

  const noDataText = useMemo(() => {
    if (
      nodeId == null ||
      equipmentTypeId == null ||
      equipmentDefaultMap == null
    ) {
      return T.admin.processmaps.nocurrentmap;
    }
    return _.join(
      T.format(
        T.admin.processmaps.nocurrentmapdefault,
        equipmentDefaultMap.name ?? ''
      ),
      ''
    );
  }, [equipmentDefaultMap, equipmentTypeId, nodeId]);

  return (
    <ActionModal
      isOpen={isOpen}
      onModalClose={onModalClose}
      title={T.admin.editlocation.editprocessmap}
      onConfirmClick={onConfirmClick}
      actionText={actionText}
      disableActionButton={!hasChanges}
      headerIcon={Icons.Settings}
      isLoading={isSavingRelation}
      className={styles.dialog}
    >
      <KeyValueLine>
        <KeyValueGeneric keyText={T.admin.processmaps.currentmap}>
          <DataTable
            isLoading={isLoadingRelation}
            columns={allTopColumns}
            data={selectedData}
            noDataText={noDataText}
            minHeight={calculateDataTableMinHeight({
              pageSize: 1,
              withButtons: true,
              showNoticeHeaders: false
            })}
            showNoticeHeaders={false}
            hasError={hasError}
          />
        </KeyValueGeneric>
      </KeyValueLine>

      <TextInput
        placeholder={T.common.search.placeholder}
        value={searchString}
        icon={<Icons.Search />}
        onChange={onChangeSearchString}
      />

      <ProcessMapTable
        searchString={searchString}
        onClickRow={onClickRow}
        showCheckboxes
        selectedId={_.head(selectedData)?.id}
        isLoading={isLoadingRelation || getProcessMapsIsLoading}
        items={items}
        defaultItemId={equipmentDefaultMap?.id}
      />
    </ActionModal>
  );
};

export default SelectProcessMapDialog;
