import { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import API from 'ecto-common/lib/API/API';
import usePromiseCall from './usePromiseCall';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import {
  PromiseCacheContext,
  batchedGetSignalsForNodesPromise
} from 'ecto-common/lib/Dashboard/datasources/signalUtils';
import { ApiContextSettings } from '../API/APIUtils';
import {
  EquipmentResponseModel,
  NodeEquipmentResponseModel,
  SignalProviderByNodeResponseModel,
  SignalResponseModel
} from 'ecto-common/lib/API/APIGen';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';

/**
 * Creates a mapping from signalId to signal and signalProviderId to signalProvider and signalId to signalProviderId
 *
 * @param response
 * @returns {signalProviders, signals, signalIdToProviderId, nodeIdToSignal}
 * @param equipmentMap Key mapped list over given equipments
 * @param grid specifies which grid that is currently active
 */

export type CreateSignalAndSignalProviderMappingResult = {
  signalProviders: Record<string, SignalProviderByNodeResponseModel>;
  signalIdToProviderId: Record<string, string>;
  nodeIdToSignal: Record<string, Record<string, SignalResponseModel>>;
  signals: Record<string, SignalResponseModel>;
};

export const createSignalAndSignalProviderMapping = (
  signalProvidersWithSignals: SignalProviderByNodeResponseModel[],
  equipmentMap: Record<string, EquipmentResponseModel>
): CreateSignalAndSignalProviderMappingResult => {
  return _.reduce(
    signalProvidersWithSignals,
    (mapping, signalProvider) => {
      mapping.signalProviders[signalProvider.signalProviderId] = signalProvider;
      for (let signal of signalProvider.signals) {
        mapping.signals[signal.signalId] = signal;
      }

      mapping.nodeIdToSignal = _.reduce(
        signalProvider.nodeIds,
        (dict, nodeId) => {
          const key = getNodeFromMap(equipmentMap, nodeId)?.nodeId ?? nodeId;

          if (dict[key] == null) {
            dict[key] = {};
          }

          let signalObj = dict[key];

          for (let signal of signalProvider.signals) {
            signalObj[signal.signalId] = signal;
          }

          return dict;
        },
        mapping.nodeIdToSignal ??
          ({} as Record<string, Record<string, SignalResponseModel>>)
      );

      mapping.signalIdToProviderId = _.reduce(
        signalProvider.signals,
        (signalIdToProviderId, { signalId }) => {
          signalIdToProviderId[signalId] = signalProvider.signalProviderId;
          return signalIdToProviderId;
        },
        mapping.signalIdToProviderId
      );

      return mapping;
    },
    {
      signalProviders: {},
      signals: {},
      signalIdToProviderId: {},
      nodeIdToSignal: {}
    } as CreateSignalAndSignalProviderMappingResult
  );
};

/**
 * List all node ids including equipment ids from a list of nodes.
 * It will NOT traverse child nodes.
 * @param nodes list of nodes
 * @returns [<guid>] node ids and equipments id of all the nodes
 */
export const nodeIdsWithEquipmentIds = (
  nodes: Partial<SingleGridNode & NodeEquipmentResponseModel>[]
) => {
  return _.flatMap(nodes, (node) => {
    const equipmentId = node?.equipmentId;
    const nodeId = node?.nodeId;
    if (!_.startsWith(nodeId, ROOT_NODE_ID) && (nodeId || equipmentId)) {
      if (equipmentId) {
        return [equipmentId];
      }
      return [nodeId, ..._.map(node.equipments, 'equipmentId')];
    }
    return [];
  });
};

const getSignalInfoForNodes = (
  contextSettings: ApiContextSettings,
  ids: string[],
  isAdmin: boolean,
  cacheContext: PromiseCacheContext
) => {
  return batchedGetSignalsForNodesPromise(
    contextSettings,
    isAdmin
      ? API.Admin.Signals.getSignalsForNodeIds
      : API.Signals.getSignalsForNodeIds,
    cacheContext
  )(ids);
};

/**
 * Returns all available signals and signal providers available to the node
 * @returns [isLoading: boolean, error: boolean, result: { signalProviders: {}, signals: {}, signalIdToProviderId: {} }, cancel: function]
 */
export const useAvailableSignalsForNodes = (
  nodes: SingleGridNode[] | EquipmentResponseModel[],
  cacheContext: PromiseCacheContext,
  isAdmin: boolean,
  equipmentMap: Record<string, EquipmentResponseModel>
): [
  isLoading: boolean,
  error: boolean,
  result: CreateSignalAndSignalProviderMappingResult,
  cancel: () => void
] => {
  const [result, setResult] =
    useState<CreateSignalAndSignalProviderMappingResult>(null);
  const [error, setError] = useState(false);

  const onSuccess = useCallback(
    (response: SignalProviderByNodeResponseModel[]) =>
      createSignalAndSignalProviderMapping(response, equipmentMap),
    [equipmentMap]
  );

  const [isLoading, getSignals, cancel] = usePromiseCall({
    promise: getSignalInfoForNodes,
    // eslint can't determine dependency for _.flow; disable dependency checking
    // eslint-disable-next-line react-hooks/exhaustive-deps
    onSuccess: _.flow(onSuccess, setResult),
    onError: () => setError(true)
  });

  useEffect(() => {
    const ids = nodeIdsWithEquipmentIds(nodes);

    setError(false);

    if (!_.isEmpty(ids)) {
      getSignals(ids, isAdmin, cacheContext);
    } else {
      cancel();
    }
  }, [nodes, getSignals, cancel, isAdmin, cacheContext]);

  return [isLoading, error, result, cancel];
};
