import React, { useCallback, useState, useEffect } from 'react';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import API from 'ecto-common/lib/API/API';
import _ from 'lodash';
import SignalSection, {
  DestOrSourceMappingSignal,
  SignalSectionModelDefinitionWithOnChange
} from 'js/components/SignalMapping/Components/SignalSection';
import { CustomModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import { FullSignalProviderResponseModel } from 'ecto-common/lib/API/APIGen';
import { SignalWithProvider } from 'ecto-common/lib/types/EctoCommonTypes';

const mapSignalMappingResult = (
  result: FullSignalProviderResponseModel[]
): Record<string, SignalWithProvider> => {
  // Map all signal arrays to dictionary of { <signal id>: <signal info> } and add signalProvider object to each signal
  // So final structure will be:
  // {
  //  [signalId1]: { ...<signal info 1>, signalProvider: <signal provider for signalId1> }
  //  [signalId2]: { ...<signal info 2>, signalProvider: <signal provider for signalId2> }
  //  ..
  // }
  return _.keyBy(
    _.flatMap(result, (signalProvider) => {
      // Set signalProvider on all signals
      return _.map(signalProvider.signals, (signal) => ({
        ...signal,
        provider: signalProvider
      }));
    }),
    'signalId'
  );
};

type SignalSectionModelEditorProps = Omit<
  CustomModelEditorProps,
  'rawValue'
> & {
  rawValue: DestOrSourceMappingSignal[];
  mappingId?: string;
  signalInputs?: SignalSectionModelDefinitionWithOnChange[];
};

const SignalSectionModelEditor = ({
  model,
  mappingId,
  signalInputs,
  updateItem,
  rawValue
}: SignalSectionModelEditorProps) => {
  const [signalInfo, setSignalInfo] = useState<
    Record<string, SignalWithProvider>
  >({});

  const [isLoading, getSignalMaps] = usePromiseCall({
    promise: API.Admin.Signals.getSignalsForMapId,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    onSuccess: _.flow(mapSignalMappingResult, setSignalInfo),
    onError: _.noop
  });
  const _updateItem = useCallback(
    (values: DestOrSourceMappingSignal[]) => {
      updateItem(values);
    },
    [updateItem]
  );
  useEffect(() => {
    if (mappingId != null) {
      getSignalMaps(mappingId);
    }
  }, [mappingId, getSignalMaps]);

  return (
    <SignalSection
      title={model.label}
      signalInputs={signalInputs}
      initialSignals={rawValue}
      onSignalsChanged={_updateItem}
      isLoading={isLoading}
      signalInfo={signalInfo}
    />
  );
};

export default React.memo(SignalSectionModelEditor);
