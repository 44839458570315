import React, { useCallback, useContext, useState } from 'react';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import EctotableAPIGen, {
  RoomRequest,
  RoomResponse,
  ScreenContentsRequest,
  ScreenContentsResponse
} from 'ecto-common/lib/API/EctotableAPIGen';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import AddButton from 'ecto-common/lib/Button/AddButton';
import ToolbarHeading from 'ecto-common/lib/Toolbar/ToolbarHeading';
import Toolbar from 'ecto-common/lib/Toolbar/Toolbar';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import EditEctotableScreenContentDialog from 'js/components/Ectotable/EditEctotableScreenContentDialog';
import ModelFormDialog from 'ecto-common/lib/ModelForm/ModelFormDialog';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import { useQueryClient } from '@tanstack/react-query';
import EctotableScreenContentList from './EctotableScreenContentList';
import T from 'ecto-common/lib/lang/Language';

const columns: DataTableColumnProps<RoomResponse>[] = [
  {
    label: T.admin.ectotable.name,
    dataKey: 'name',
    linkColumn: true
  }
];

const screenContentModels: ModelDefinition<ScreenContentsRequest>[] = [
  {
    label: T.admin.ectotable.name,
    key: (input) => input.name,
    modelType: ModelType.TEXT,
    hasError: (value) => isNullOrWhitespace(value),
    autoFocus: true
  }
];

const roomModels: ModelDefinition<Partial<RoomRequest>>[] = [
  {
    label: T.admin.ectotable.name,
    key: (input) => input.name,
    modelType: ModelType.TEXT,
    hasError: (value) => isNullOrWhitespace(value),
    autoFocus: true
  },
  {
    label: T.admin.ectotable.company,
    key: (input) => input.company,
    modelType: ModelType.TEXT,
    hasError: (value) => isNullOrWhitespace(value)
  },
  {
    label: T.admin.ectotable.location,
    key: (input) => input.location,
    modelType: ModelType.TEXT,
    hasError: (value) => isNullOrWhitespace(value)
  },
  {
    label: T.admin.ectotable.contactperson,
    key: (input) => input.contactPerson,
    modelType: ModelType.TEXT,
    hasError: (value) => isNullOrWhitespace(value)
  },
  {
    label: T.admin.ectotable.screencontent,
    key: (input) => input.screenContentsIds,
    modelType: ModelType.ODATA_LIST_OPTIONS,
    isMultiOption: true,
    oDataPromise: EctotableAPIGen.ScreenContentAdmin.listScreenContent.promise
  }
];

const ManageEctotablePage = () => {
  const { data: rooms, isLoading } =
    EctotableAPIGen.RoomAdmin.listRooms.useQuery({
      $orderby: 'name'
    });

  const [editScreenContent, setEditScreenContent] =
    useState<ScreenContentsResponse>(null);
  const [addScreenContent, setAddScreenContent] =
    useState<ScreenContentsRequest | null>(null);
  const [editRoom, setEditRoom] = useState<Partial<RoomRequest> | null>(null);

  const { tenantId, contextSettings } = useContext(TenantContext);
  const queryClient = useQueryClient();

  const { mutate: performEditRoom, isLoading: isEditingRoom } =
    EctotableAPIGen.RoomAdmin.adminRoomPartialUpdate.useMutation(
      { id: editRoom?.id },
      {
        onSuccess: () => {
          setEditRoom(null);
          queryClient.invalidateQueries(
            EctotableAPIGen.RoomAdmin.listRooms.path(contextSettings)
          );
        }
      }
    );

  const { mutate: addRoom, isLoading: isAddingRoom } =
    EctotableAPIGen.RoomAdmin.adminRoomCreate.useMutation({
      onSuccess: () => {
        setEditRoom(null);
        queryClient.invalidateQueries(
          EctotableAPIGen.RoomAdmin.listRooms.path(contextSettings)
        );
      }
    });

  const { mutate: performAddScreenContent, isLoading: isAddingScreenContent } =
    EctotableAPIGen.ScreenContentAdmin.adminScreencontentCreate.useMutation({
      onSuccess: () => {
        queryClient.invalidateQueries(
          EctotableAPIGen.ScreenContentAdmin.listScreenContent.path(
            contextSettings
          )
        );
        setAddScreenContent(null);
      }
    });

  const onClickRow = useCallback((row: RoomResponse) => {
    setEditRoom(row);
  }, []);

  const onAddRoom = () => {
    setEditRoom({
      tenantId: tenantId
    });
  };

  const onAddScreenContent = () => {
    setAddScreenContent({
      tenantId: tenantId,
      name: '',
      id: null
    });
  };

  const onClickScreenContent = (content: ScreenContentsResponse) => {
    setEditScreenContent(content);
  };

  const _performEditRoom = (content: Partial<RoomRequest>) => {
    performEditRoom(content);
  };

  const performAddRoom = (content: Partial<RoomRequest>) => {
    // Use name as new ID since that is what the client use to look up metadata
    addRoom({
      ...content,
      id: content.name ?? ''
    } as RoomRequest);
  };

  return (
    <ToolbarContentPage
      title={T.admin.ectotable.title}
      showLocationPicker={false}
      wrapContent={false}
    >
      <Toolbar>
        <ToolbarHeading isPageHeading title={T.admin.ectotable.rooms} />
        <ToolbarFlexibleSpace />
        <ToolbarItem>
          <AddButton onClick={onAddRoom}>{T.admin.ectotable.addroom}</AddButton>
        </ToolbarItem>
      </Toolbar>
      <DataTable<RoomResponse>
        onClickRow={onClickRow}
        isLoading={isLoading}
        columns={columns}
        data={rooms?.items}
      />
      <Toolbar withTopMargin>
        <ToolbarHeading
          isPageHeading
          title={T.admin.ectotable.screencontents}
        />
        <ToolbarFlexibleSpace />
        <ToolbarItem>
          <AddButton onClick={onAddScreenContent}>
            {T.admin.ectotable.addscreencontent}
          </AddButton>
        </ToolbarItem>
      </Toolbar>
      <EctotableScreenContentList onClickRow={onClickScreenContent} />
      <EditEctotableScreenContentDialog
        onScreenContentsUpdated={(content) => setEditScreenContent(content)}
        screenContents={editScreenContent}
        onModalClose={() => {
          setEditScreenContent(null);
        }}
      />
      <ModelFormDialog
        onModalClose={() => {
          setEditRoom(null);
        }}
        saveInput={editRoom?.id == null ? performAddRoom : _performEditRoom}
        input={editRoom}
        models={roomModels}
        addTitle={T.admin.ectotable.addroomtitle}
        editTitle={T.admin.ectotable.editroomtitle}
        isLoading={isAddingRoom || isEditingRoom}
        saveAsArray={false}
      />
      <ModelFormDialog
        input={addScreenContent}
        onModalClose={() => setAddScreenContent(null)}
        models={screenContentModels}
        saveInput={performAddScreenContent}
        addTitle={T.admin.ectotable.addscreencontentdialog}
        isLoading={isAddingScreenContent}
        saveAsArray={false}
      />
    </ToolbarContentPage>
  );
};

export default React.memo(ManageEctotablePage);
