import React, { memo } from 'react';

import AdminPage from 'js/components/AdminPage';
import BuildingsList from 'js/components/Buildings/BuildingsList';

const Buildings = () => {
  return <AdminPage content={<BuildingsList />} />;
};

export default memo(Buildings);
