import React from 'react';
import { matchPath } from 'react-router';

import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import T from 'ecto-common/lib/lang/Language';
import HelpPaths from 'ecto-common/help/tocKeys';

import { LocationRoute } from 'js/utils/routeConstants';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';

const urlBuilder = (
  tenantId: string,
  newNodeId: string,
  equipmentId: string
) => {
  const params = matchPath<NodeParams>(
    window.location.pathname,
    LocationRoute
  ).params;

  if (equipmentId != null) {
    return `/${tenantId}/home/${newNodeId}/equipment/${equipmentId}/${params.subPage ?? 'details'}`;
  }

  return `/${tenantId}/home/${newNodeId}/equipment/`;
};

interface EquipmentsToolbarPageProps {
  toolbarItems?: React.ReactNode;
  children?: React.ReactNode;
}

const EquipmentsToolbarPage = ({
  children,
  toolbarItems
}: EquipmentsToolbarPageProps) => (
  <ToolbarContentPage
    title={T.admin.tabs.equipment}
    selectEquipment
    urlBuilder={urlBuilder}
    toolbarItems={toolbarItems}
    helpPath={HelpPaths.docs.admin.manage.equipment.equipment}
  >
    {children}
  </ToolbarContentPage>
);

EquipmentsToolbarPage.defaultProps = {
  toolbarItems: []
};

export default EquipmentsToolbarPage;
