import Highcharts from 'highcharts/highstock';

/* eslint-disable @typescript-eslint/no-var-requires */
require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/draggable-points')(Highcharts);
require('highcharts/modules/annotations')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/offline-exporting')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);
require('highcharts/modules/boost')(Highcharts);

// Add center line at 'crossing' value
(function (H) {
  H.wrap(H.Axis.prototype, 'render', function (proceed) {
    if (this.options.crossing != null) {
      this.offset = this.chart[this.isXAxis ? 'yAxis' : 'xAxis'][0]?.toPixels(
        this.options.crossing,
        true
      );
    }
    proceed.call(this);
  });
})(Highcharts);

export { Highcharts };
