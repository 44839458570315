import React, { useContext } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import EquipmentGroupTemplates from 'js/components/ManageTemplates/EquipmentGroupTemplates/EquipmentGroupTemplates';
import EditEquipmentSignalTemplate from 'js/components/ManageTemplates/EquipmentSignalTemplates/EditEquipmentSignalTemplate';
import EditEquipmentGroupTemplate from 'js/components/ManageTemplates/EquipmentGroupTemplates/EditEquipmentGroupTemplate';
import EquipmentTypes from 'js/components/ManageTemplates/EquipmentSignalTemplates/EquipmentTypes';
import AlarmSignalGroupTemplates from 'js/components/ManageTemplates/AlarmSignalTemplates/AlarmSignalGroupTemplates';
import EditAlarmSignalGroupTemplate from 'js/components/ManageTemplates/AlarmSignalTemplates/EditAlarmSignalGroupTemplate';
import AdminPage from 'js/components/AdminPage';
import ProcessMaps from 'js/components/ProcessMaps/ProcessMaps';
import Dashboards from 'js/components/Dashboards/Dashboards';
import SignalTypes from 'js/components/SignalTypes/SignalTypes';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import DeployTemplates from 'js/components/TemplateManagement/DeployTemplates';

/**
 * TODO: this now acts a a wrapped pseudo-router. We probably should just have proper routes for each of these.
 */
const pages: Record<string, (item: string) => React.ReactNode> = {
  deploy: () => <DeployTemplates />,
  equipments: (item: string) =>
    item ? <EditEquipmentSignalTemplate /> : <EquipmentTypes />,
  alarms: (item: string) =>
    item ? <EditAlarmSignalGroupTemplate /> : <AlarmSignalGroupTemplates />,
  buildings: (item: string) =>
    item ? <EditEquipmentGroupTemplate /> : <EquipmentGroupTemplates />,
  processmaps: () => <ProcessMaps />,
  dashboards: () => <Dashboards />,
  signaltypes: () => <SignalTypes />
};

const TemplateManagement = () => {
  const params = useParams<NodeParams>();
  const { tenantId } = useContext(TenantContext);

  if (!params.page || pages[params.page] == null) {
    return <Redirect to={`/${tenantId}/templateManagement/dashboards`} />;
  }

  const currentPageContent = pages[params.page](params.itemId);

  return <AdminPage content={currentPageContent} />;
};

export default React.memo(TemplateManagement);
