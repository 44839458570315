import React from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from 'ecto-common/lib/Animations/FadeInFadeOutAnimation.module.css';

interface FadeInFadeOutAnimationProps {
  children: React.ReactNode;
  isVisible: boolean;
}

const FadeInFadeOutAnimation = ({
  children,
  isVisible = false
}: FadeInFadeOutAnimationProps) => (
  <CSSTransition
    in={isVisible}
    timeout={200}
    classNames={{
      enter: styles.enter,
      enterActive: styles.enterActive,
      enterDone: styles.enterDone,
      exit: styles.exit,
      exitActive: styles.exitActive
    }}
  >
    {children}
  </CSSTransition>
);

export default FadeInFadeOutAnimation;
