import React, { useEffect } from 'react';
import _ from 'lodash';

import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import T from 'ecto-common/lib/lang/Language';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import {
  relativeDateString,
  uptimeString
} from 'js/components/EnergyManagers/Models/formatting';

import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import { EditIoTDeviceDataType } from 'js/components/EnergyManagers/EditIoTDevice';
import DeviceAPIGen, {
  ListDeviceModulesResponse,
  ModuleModel
} from 'ecto-common/lib/API/DeviceAPIGen';
import {
  ModelDefinition,
  ModelTableData
} from 'ecto-common/lib/ModelForm/ModelPropType';

const initialData: ListDeviceModulesResponse = { modules: [] };

const modulesTableData = (deviceId: string): ModelTableData<ModuleModel> => {
  const modulesQuery = DeviceAPIGen.Devices.getModulesByDeviceId.useQuery({
    deviceId
  });
  const modules = (modulesQuery.data || initialData).modules;

  const columns = [
    {
      label: T.admin.iotdevicedetails.modulesname,
      dataKey: 'name',
      flexGrow: 1.2
    },
    {
      label: T.admin.iotdevicedetails.modulesdetail.version,
      dataKey: 'version',
      flexGrow: 0.8
    },
    {
      label: T.admin.iotdevicedetails.modulesdetail.runtimeStatus,
      dataKey: 'runtimeStatus',
      dataFormatter: (
        runtimeStatus: keyof typeof T.admin.iotdevicedetails.modulesdetail.status
      ) => {
        return T.admin.iotdevicedetails.modulesdetail.status[runtimeStatus];
      },
      flexGrow: 0.8
    },
    {
      label: T.admin.iotdevicedetails.modulesdetail.lastStartTime,
      dataKey: 'lastStartTimeUtc',
      dataFormatter: (value: string) => relativeDateString(value),
      flexGrow: 1.5
    },
    {
      label: T.admin.iotdevicedetails.modulesdetail.lastRestartTime,
      dataKey: 'lastRestartTimeUtc',
      dataFormatter: (value: string) => relativeDateString(value),
      flexGrow: 1.5
    },
    {
      label: T.admin.iotdevicedetails.modulesdetail.lastExitTime,
      dataKey: 'lastExitTimeUtc',
      dataFormatter: (value: string) => relativeDateString(value),
      flexGrow: 1.5
    },
    {
      label: T.admin.iotdevicedetails.modulesdetail.exitCode,
      dataKey: 'exitCode',
      flexGrow: 0.5
    }
  ];

  return { data: modules, columns, isLoading: modulesQuery.isLoading };
};

const models: ModelDefinition<EditIoTDeviceDataType, object, unknown>[] = [
  {
    key: (input) => input.heartbeatTimestamp,
    modelType: ModelType.LABEL,
    label: T.admin.iotdevicedetails.heartbeat,
    dataFormatter: (value: string) =>
      value ? relativeDateString(value) : T.admin.iotdevicedetails.noheartbeat
  },
  {
    key: (input) => input.reportedSystem.uptime,
    modelType: ModelType.LABEL,
    label: T.admin.iotdevicedetails.uptime,
    dataFormatter: (value: number) => uptimeString(value)
  },
  {
    key: (input) => input.reportedSystem.signalStrength,
    modelType: ModelType.LABEL,
    label: T.admin.iotdevicedetails.signalstrength,
    dataFormatter: (value: number) => _.defaultTo(value, '-') + ' dBm'
  },
  {
    key: (input) => input.ioTDevice.deviceName,
    label: T.admin.iotdevicedetails.modules,
    modelType: ModelType.TABLE,
    tableData: (deviceName: string) => modulesTableData(deviceName)
  }
];

interface IoTDeviceInfoProps {
  isLoading?: boolean;
  data?: EditIoTDeviceDataType;
  getData(): void;
}

const IoTDeviceInfo = ({ isLoading, data, getData }: IoTDeviceInfoProps) => {
  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <LoadingContainer isLoading={isLoading}>
      <ModelForm
        models={isLoading ? [] : models}
        onUpdateInput={_.noop}
        input={data}
      />
    </LoadingContainer>
  );
};

export default React.memo(IoTDeviceInfo);
