import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';

import T from 'ecto-common/lib/lang/Language';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import HelpPaths from 'ecto-common/help/tocKeys';

import DeviceList, {
  parseLocationSearch
} from 'js/components/EnergyManagers/DeviceList';
import { DeviceListFilterParams } from 'js/components/EnergyManagers/DeviceListFilter';

interface EnergyManagersPageProps {
  useAsSelector?: boolean;
  onDeviceSelected?(): void;
  pageSize?: number;
}

const EnergyManagersPage = ({
  onDeviceSelected,
  useAsSelector,
  pageSize
}: EnergyManagersPageProps) => {
  const history = useHistory();
  const location = useLocation();

  const onSearch = useCallback(
    (newSearch: Partial<DeviceListFilterParams>) => {
      const newLocation = {
        ...location,
        search: queryString.stringify({
          ...queryString.parse(location.search),
          ...newSearch
        })
      };
      history.push(newLocation);
    },
    [history, location]
  );

  const filterParams = parseLocationSearch(location);

  return (
    <ToolbarContentPage
      wrapContent={false}
      showLocationPicker={false}
      title={T.admin.tabs.devices}
      helpPath={HelpPaths.docs.admin.installation.energy_managers}
    >
      <DeviceList
        onSearch={onSearch}
        onDeviceSelected={onDeviceSelected}
        useAsSelector={useAsSelector}
        pageSize={pageSize}
        filterParams={filterParams}
      />
    </ToolbarContentPage>
  );
};

export default React.memo(EnergyManagersPage);
