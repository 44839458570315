import styles from 'ecto-common/lib/KeyValueInput/KeyValueInput.module.css';
import React from 'react';
import classNames from 'classnames';

interface KeyValueLineProps {
  /**
   * The key value components to be displayed on a line.
   */
  children: React.ReactNode;
  /**
   * Whether or not the line should have margin to the next element
   */
  shouldHaveBottomMargin?: boolean;

  /**
   * Whether or not the items on the line should be centered vertically.
   */
  centerVertically?: boolean;
}

/**
 * This component can be used to lay out multiple key value inputs horizontally on a row.
 */
export const KeyValueLine = ({
  children,
  shouldHaveBottomMargin,
  centerVertically
}: KeyValueLineProps) => {
  return (
    <div
      className={classNames(
        styles.keyValueLine,
        shouldHaveBottomMargin && styles.bottomMargin,
        centerVertically && styles.centerVertically
      )}
    >
      {children}
    </div>
  );
};

KeyValueLine.defaultProps = {
  shouldHaveBottomMargin: true
};
