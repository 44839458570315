import _ from 'lodash';

import API from 'ecto-common/lib/API/API';
import {
  ToolSignalProviders,
  ToolSignalProviderTranslations
} from 'js/components/ManageEquipment/EditEquipment/toolTypes';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import T from 'ecto-common/lib/lang/Language';
import { number } from 'ecto-common/lib/ModelForm/formUtils';
import HelpPaths from 'ecto-common/help/tocKeys';
import {
  ModelDefinition,
  ModelDynamicBoolProperty
} from 'ecto-common/lib/ModelForm/ModelPropType';
import { ResourceType } from 'ecto-common/lib/constants/index';
import { AddOrUpdateLoBaBatteryChargingThresholdProviderRequestModel } from 'ecto-common/lib/API/APIGen';
import { AdminToolImplementationType } from '../useEquipmentTools';

// Create a select a single signal model
const signal = (
  key: (
    input: AddOrUpdateLoBaBatteryChargingThresholdProviderRequestModel
  ) => string,
  label: string,
  hasError: ModelDynamicBoolProperty<
    AddOrUpdateLoBaBatteryChargingThresholdProviderRequestModel,
    object,
    string
  >
): ModelDefinition<AddOrUpdateLoBaBatteryChargingThresholdProviderRequestModel> => {
  return {
    key,
    label,
    placeholder: label,
    modelType: ModelType.SIGNAL,
    hasError,
    selectFromCurrentNodeOnly: true
  };
};

const lobaNumber =
  number<AddOrUpdateLoBaBatteryChargingThresholdProviderRequestModel>;

const model: ModelDefinition<AddOrUpdateLoBaBatteryChargingThresholdProviderRequestModel>[] =
  [
    signal(
      (input) => input.powerElectricityGridSignalId,
      T.admin.batterychargingthreshold.powerelectricitygridsignal,
      _.isNull
    ),
    lobaNumber(
      (input) => input.stateOfChargeMin,
      T.admin.batterychargingthreshold.stateofchargemin,
      (value: number) => value < 0 || value > 100
    ),
    lobaNumber(
      (input) => input.stateOfChargeMax,
      T.admin.batterychargingthreshold.stateofchargemax,
      (value: number) => value < 0 || value > 100
    ),
    lobaNumber(
      (input) => input.timeConstant,
      T.admin.batterychargingthreshold.timeconstant,
      (value: number) => _.isNull(value) || value <= 0
    ),
    lobaNumber(
      (input) => input.maxChargeRate,
      T.admin.batterychargingthreshold.maxchargerate,
      (value: number) => _.isNull(value) || value < 0
    ),
    lobaNumber(
      (input) => input.maxDischargeRate,
      T.admin.batterychargingthreshold.maxdischargerate,
      (value: number) => _.isNull(value) || value < 0
    )
  ];

const EMPTY_INPUT: Partial<AddOrUpdateLoBaBatteryChargingThresholdProviderRequestModel> =
  {
    powerElectricityGridSignalId: null,
    stateOfChargeMin: 20,
    stateOfChargeMax: 100,
    timeConstant: 300,
    maxChargeRate: 0,
    maxDischargeRate: 0
  };

const BatteryChargingThresholdTool: AdminToolImplementationType<AddOrUpdateLoBaBatteryChargingThresholdProviderRequestModel> =
  {
    type: ToolSignalProviders.BATTERY_CHARGING_THRESHOLD,
    name: ToolSignalProviderTranslations[
      ToolSignalProviders.BATTERY_CHARGING_THRESHOLD
    ],
    accessRights: [ResourceType.LOBA],
    allowDelete: true,
    allowDeploy: false,
    model,
    emptyInput: EMPTY_INPUT,
    save: API.Admin.ElectricityPeakShaving.addOrUpdateProviders,
    delete: API.Admin.ElectricityPeakShaving.removeProviders,
    load: API.Admin.ElectricityPeakShaving.getProvidersByNodeIds,
    helpPath:
      HelpPaths.docs.admin.manage.equipment.tools.battery_charging_threshold
  };

export default BatteryChargingThresholdTool;
