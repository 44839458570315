import React, {
  memo,
  useState,
  useCallback,
  useContext,
  useMemo,
  useRef
} from 'react';
import _ from 'lodash';

import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import T from 'ecto-common/lib/lang/Language';
import HelpPaths from 'ecto-common/help/tocKeys';

import ProcessMapsEditModal from 'ecto-common/lib/ProcessMaps/ProcessMapsEditModal';
import ProcessMapTable from 'ecto-common/lib/ProcessMaps/ProcessMapTable';
import APIGen, { ProcessMapResponseModel } from 'ecto-common/lib/API/APIGen';
import ProcessMapEditor from 'ecto-common/lib/ProcessMaps/ProcessMapEditor';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  TemplateManagementParams,
  TemplateManagementRoute,
  getTemplateManagementRoute
} from 'js/utils/routeConstants';
import {
  useHistory,
  useLocation,
  useParams,
  Prompt,
  matchPath
} from 'react-router';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import NavLinkFix from 'ecto-common/lib/NavLinkFix/NavLinkFix';
import queryString from 'query-string';
import { NestedTenantContainer } from 'ecto-common/lib/Application/TenantContainer';
import { EventHubServiceContainer } from 'ecto-common/lib/EventHubConnection/EventHubService';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import { useImmer } from 'use-immer';
import {
  ProcessMapState,
  initProcessMapState
} from 'ecto-common/lib/ProcessMaps/ProcessMapEditorTypes';
import { ProcessMapEditorActions } from 'ecto-common/lib/ProcessMaps/ProcessMapEditorActions';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import EditButton from 'ecto-common/lib/Button/EditButton';
import ProcessMapEditLibraryModal from 'ecto-common/lib/ProcessMaps/ProcessMapEditLibraryModal';
import { useProcessMapLibrary } from 'ecto-common/lib/ProcessMaps/ProcessMapEditorHooks';

const ProcessMapWrapper = ({ itemId }: { itemId: string }) => {
  const { tenantId, contextSettings } = useContext(TenantContext);
  const mapsRequest = APIGen.AdminProcessMaps.getProcessMapsById.useQuery(
    { ids: [itemId] },
    { refetchOnWindowFocus: false, refetchOnReconnect: false }
  );

  const hasDataForIdRef = useRef({
    [itemId]: false
  });

  const revisionRequest = useQuery(
    ['adminProcessMaps', itemId],
    ({ signal }) => {
      return APIGen.AdminProcessMaps.getProcessMapRevisionsByProcessMapId
        .promise(contextSettings, { processMapIds: [itemId] }, signal)
        .then((results) => {
          const lastRevision = _.head(results);
          return APIGen.AdminProcessMaps.getProcessMapRevisionsById.promise(
            contextSettings,
            { ids: [lastRevision.id] },
            signal
          );
        });
    },
    {
      cacheTime: 0,
      enabled: !hasDataForIdRef.current[itemId]
    }
  );

  const [processMapState, setProcessMapState] = useImmer<ProcessMapState>(null);

  // Workaround to make sure the process map is only fetched once.
  // When going to process map list, component will be unmounted, and
  // when going back to process map, the request will be re-triggered.
  if (revisionRequest.data != null && !hasDataForIdRef.current[itemId]) {
    hasDataForIdRef.current[itemId] = true;
    let initialData = revisionRequest.data?.[0]?.map;
    setProcessMapState(initProcessMapState(initialData));
  }

  const queryClient = useQueryClient();

  const saveRequest =
    APIGen.AdminProcessMaps.addOrUpdateProcessMapRevisions.useMutation({
      onSuccess: () => {
        queryClient.invalidateQueries(['adminProcessMaps']);
        queryClient.invalidateQueries(
          APIGen.AdminProcessMaps.getProcessMapRevisionsByProcessMapId.path(
            contextSettings
          )
        );
        queryClient.invalidateQueries(
          APIGen.AdminProcessMaps.getProcessMapRevisionsById.path(
            contextSettings
          )
        );
        setProcessMapState((draft) =>
          ProcessMapEditorActions.clearHasChanges(draft)
        );
      }
    });

  const { libraryRequest, libraryItems } = useProcessMapLibrary();

  const history = useHistory();
  const location = useLocation();

  const search = queryString.parse(location.search);
  const previewTenantId = search.tenantId as string;
  const previewNodeId = search.nodeId as string;

  const setPreviewTenantId = useCallback(
    (newTenantId: string) => {
      history.replace({
        ...location,
        search: queryString.stringify({ tenantId: newTenantId, nodeId: null })
      });
    },
    [history, location]
  );

  const setPreviewNodeId = useCallback(
    (newNodeId: string) => {
      history.replace({
        ...location,
        search: queryString.stringify({
          tenantId: previewTenantId,
          nodeId: newNodeId
        })
      });
    },
    [history, location, previewTenantId]
  );

  const titleElement = mapsRequest.isLoading ? (
    <Spinner size={SpinnerSize.SMALL} />
  ) : (
    <>{mapsRequest.data?.[0].name ?? T.common.unknownerror}</>
  );
  const title = (
    <>
      <NavLinkFix to={getTemplateManagementRoute(tenantId, 'processmaps')}>
        {T.admin.processmaps.title}
      </NavLinkFix>
      &nbsp;&gt;&nbsp;{titleElement}
    </>
  );

  const onSave = useCallback(
    (dataBase64: string, comment: string) => {
      saveRequest.mutate([
        {
          comment,
          processMapId: itemId,
          map: dataBase64
        }
      ]);
    },
    [itemId, saveRequest]
  );

  return (
    <>
      <Prompt
        when={processMapState?.hasChanges}
        message={(info) => {
          const newParams = matchPath<TemplateManagementParams>(
            info.pathname,
            TemplateManagementRoute
          )?.params;
          return newParams?.itemId !== itemId && processMapState?.hasChanges
            ? T.admin.form.unsavedstate
            : true;
        }}
      />
      <ToolbarContentPage
        title={title}
        showLocationPicker={false}
        wrapContent={false}
        removeTopMargin
        removeSideMargin
        removeBottomMargin
      >
        <LoadingContainer
          isLoading={
            revisionRequest.isLoading ||
            saveRequest.isLoading ||
            libraryRequest.isLoading
          }
        >
          {(revisionRequest.error != null || libraryRequest.error != null) && (
            <div>{T.admin.processmaps.failedtoload}</div>
          )}
          {processMapState != null && libraryRequest.data != null && (
            <NestedTenantContainer tenantId={previewTenantId}>
              <EventHubServiceContainer>
                <ProcessMapEditor
                  onSave={onSave}
                  processMapState={processMapState}
                  setProcessMapState={setProcessMapState}
                  library={libraryItems}
                  previewTenantId={previewTenantId}
                  setPreviewTenantId={setPreviewTenantId}
                  previewNodeId={previewNodeId}
                  setPreviewNodeId={setPreviewNodeId}
                  name={mapsRequest.data?.[0].name}
                />
              </EventHubServiceContainer>
            </NestedTenantContainer>
          )}
        </LoadingContainer>
      </ToolbarContentPage>
    </>
  );
};

const ProcessMapsList = () => {
  const [searchString, setSearchString] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedProcessMapId, setSelectedProcessMapId] =
    useState<string>(null);

  const search = queryString.parse(location.search);
  const isShowingDialog = search.showDialog != null;
  const history = useHistory();

  const showDialog = useCallback(() => {
    history.replace({
      ...location,
      search: queryString.stringify({ showDialog: true })
    });
  }, [history]);

  const hideDialog = useCallback(() => {
    history.replace({ ...location, search: null });
  }, [history]);

  const listQuery = APIGen.AdminProcessMaps.getAllProcessMaps.useQuery({
    initialData: []
  });
  const items = useMemo(() => {
    return _.keyBy(listQuery.data, 'id');
  }, [listQuery.data]);

  const queryClient = useQueryClient();

  const triggerReload = (newProcessMapId?: string) => {
    queryClient.invalidateQueries();
    if (newProcessMapId != null) {
      history.push(
        getTemplateManagementRoute(tenantId, 'processmaps', newProcessMapId)
      );
    }
  };

  const { tenantId } = useContext(TenantContext);

  const onEditRow = useCallback((processMap: ProcessMapResponseModel) => {
    setSelectedProcessMapId(processMap.id);
    setIsEditing(true);
    setIsOpen(true);
  }, []);

  const onClickRow = useCallback(
    ({ id }: ProcessMapResponseModel) => {
      history.push(getTemplateManagementRoute(tenantId, 'processmaps', id));
    },
    [history, tenantId]
  );

  const onAddClickHandler = useCallback(() => {
    setIsOpen(true);
    setIsEditing(false);
  }, []);

  const toolbarItems = (
    <>
      <ToolbarItem>
        <EditButton onClick={showDialog}>
          {T.admin.processmaps.editsymbollibrary}...
        </EditButton>
      </ToolbarItem>
    </>
  );

  return (
    <ToolbarContentPage
      title={T.admin.processmaps.title}
      addAction={onAddClickHandler}
      addActionTitle={T.admin.processmaps.addmap}
      showLocationPicker={false}
      searchString={searchString}
      onSearchInput={setSearchString}
      wrapContent={false}
      toolbarItems={toolbarItems}
      helpPath={HelpPaths.docs.admin.manage.process_maps.process_maps}
    >
      {listQuery.error && (
        <ErrorNotice> {T.admin.processmaps.maps.error} </ErrorNotice>
      )}
      <ProcessMapTable
        onClickRow={onClickRow}
        searchString={searchString}
        isLoading={listQuery.isFetching}
        items={items}
        hasError={listQuery.error != null}
        onEdit={onEditRow}
      />

      <ProcessMapsEditModal
        isOpen={isOpen}
        onModalClose={() => setIsOpen(false)}
        isEditing={isEditing}
        selectedProcessMapId={selectedProcessMapId}
        items={items}
        isLoading={listQuery.isLoading}
        setSelectedProcessMapId={setSelectedProcessMapId}
        reloadProcessMaps={triggerReload}
      />
      <ProcessMapEditLibraryModal
        isOpen={isShowingDialog}
        onModalClose={hideDialog}
      />
    </ToolbarContentPage>
  );
};

const ProcessMaps = () => {
  const params = useParams<TemplateManagementParams>();

  if (params.itemId != null) {
    return <ProcessMapWrapper itemId={params.itemId} key={params.itemId} />;
  }

  return <ProcessMapsList />;
};

export default memo(ProcessMaps);
