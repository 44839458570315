import {
  modbusConfigTypeToMode,
  translateBitmaskTypes,
  PossibleSignalSettingsType
} from 'js/components/ModbusLayout/ModbusEditUtils';
import _ from 'lodash';
import { SignalModbusConfigMode } from 'js/components/ModbusLayout/ModbusTypes';
import {
  AdminEquipmentSignalResponseModel,
  ConnectionResponseModel
} from 'ecto-common/lib/API/APIGen';

type SignalWithSignalSettings = {
  signalSettings?: PossibleSignalSettingsType;
  configType: SignalModbusConfigMode;
};

export const signalIsModbus = (signal: SignalWithSignalSettings) =>
  signal &&
  signal.signalSettings &&
  signal.signalSettings[_.get(signal, 'configType')];
export const getSignalType = (s: AdminEquipmentSignalWithConfigType) =>
  _.get(s, ['signalSettings', s.configType, 'type'], '');
export const signalIsEnabled = (s: AdminEquipmentSignalWithConfigType) =>
  !_.get(s, ['signalSettings', s.configType, 'disabled'], false);
export const getAddressValue = (s: AdminEquipmentSignalWithConfigType) =>
  _.get(s, ['signalSettings', 'modbusAddress'], '');

export const cleanSignalList = (
  signals: AdminEquipmentSignalWithConfigType[]
): Omit<AdminEquipmentSignalWithConfigType, 'configType'>[] => {
  return signals.map((signal) => _.omit(signal, 'configType'));
};

export const parseNumericValue = (value: string) => {
  const convertedNumber = parseInt(value, 10);
  return isNaN(convertedNumber) ? null : convertedNumber;
};

export const getSignalConfigType = (
  selectedSignal: AdminEquipmentSignalResponseModel,
  connections: Record<string, ConnectionResponseModel>
) => {
  const connectionId = _.get(selectedSignal, 'connectionId');
  const modbusMode = _.get(connections, [
    connectionId,
    'connectionModbusConfig',
    'modbusMode'
  ]);
  if (modbusMode) {
    return modbusConfigTypeToMode(modbusMode);
  }

  return SignalModbusConfigMode.SLAVE;
};

export type AdminEquipmentSignalWithConfigType =
  AdminEquipmentSignalResponseModel & {
    configType: SignalModbusConfigMode;
  };

export const getSignalList = (
  signals: AdminEquipmentSignalResponseModel[],
  connections: Record<string, ConnectionResponseModel>
): AdminEquipmentSignalWithConfigType[] => {
  let expandedSignals: AdminEquipmentSignalWithConfigType[] = signals.map(
    (signal) => {
      return {
        ...signal,
        configType: getSignalConfigType(signal, connections)
      };
    }
  );

  const modbusSignals = translateBitmaskTypes(
    expandedSignals.filter(signalIsModbus)
  );
  const nonModbusSignals = expandedSignals
    .filter((s) => !signalIsModbus(s))
    .sort((s1, s2) => s1.name.localeCompare(s2.name));
  return modbusSignals.concat(nonModbusSignals);
};
