import React, { useCallback, useEffect, useMemo, useState } from 'react';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import API from 'ecto-common/lib/API/API';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import _ from 'lodash';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import T from 'ecto-common/lib/lang/Language';
import { SignalCategoryResponseModel } from 'ecto-common/lib/API/APIGen';

const categoriesToOptions = (categories: SignalCategoryResponseModel[]) => {
  return _.map(categories, (category) => ({
    label: category.name,
    value: category.id
  }));
};

interface SignalCategoriesPickerProps {
  selectedCategoryIds: string[];
  onChange(categoryIds: string[]): void;
}

const SignalCategoriesPicker = ({
  selectedCategoryIds,
  onChange
}: SignalCategoriesPickerProps) => {
  const [categories, setCategories] = useState<GenericSelectOption<string>[]>(
    []
  );
  const [error, setError] = useState<unknown>(null);

  const [categoriesIsLoading, loadCategories] = usePromiseCall({
    promise: API.Admin.SignalCategories.getSignalCategories,
    onSuccess: (val) => setCategories(categoriesToOptions(val)),
    onError: setError
  });

  useEffect(() => {
    setError(null);
    loadCategories();
  }, [loadCategories]);

  const selectedOptions = useMemo(() => {
    return _.filter(categories, (category) =>
      selectedCategoryIds.includes(category.value)
    );
  }, [categories, selectedCategoryIds]);

  const _onChange = useCallback(
    (newVal: GenericSelectOption<string>[]) => {
      onChange(_.map(newVal, 'value'));
    },
    [onChange]
  );

  if (error != null) {
    return <ErrorNotice> {T.admin.signalcategories.loaderror}</ErrorNotice>;
  }

  return (
    <Select<GenericSelectOption<string>, true>
      options={categories}
      value={selectedOptions}
      onChange={_onChange}
      isMulti
      disabled={categoriesIsLoading}
      isLoading={categoriesIsLoading}
    />
  );
};

export default React.memo(SignalCategoriesPicker);
