import React, { useContext, useMemo } from 'react';
import { mapReqStateToProp } from 'ecto-common/lib/utils/requestStatus';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { ProvisionDeviceActions } from 'js/modules/provisionDevice/provisionDevice';
import T from 'ecto-common/lib/lang/Language';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import { IoTDeviceWithDeviceResponseModel } from 'ecto-common/lib/API/APIGen';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

interface ConfirmUnlinkDialogProps {
  iotDevice?: IoTDeviceWithDeviceResponseModel;
}

/**
 * Dialog to confirm removal/unlink of the current iotdevice from the current energy manager equipment
 */
const ConfirmUnlinkDialog = ({ iotDevice }: ConfirmUnlinkDialogProps) => {
  const dispatch = useAdminDispatch();
  const unpairRequestReq = useAdminSelector(
    (state) => state.provisionDevice.unpairRequest
  );
  const shouldConfirmUnlink = useAdminSelector(
    (state) => state.provisionDevice.shouldConfirmUnlink
  );
  const { contextSettings } = useContext(TenantContext);

  const unpairRequest = useMemo(() => {
    return mapReqStateToProp(unpairRequestReq);
  }, [unpairRequestReq]);

  return (
    <ActionModal
      isOpen={shouldConfirmUnlink}
      onModalClose={() =>
        dispatch(ProvisionDeviceActions.setShouldConfirmUnlink(false))
      }
      title={T.admin.energymanager.confirmunlink}
      isLoading={unpairRequest.isLoading}
      onConfirmClick={() =>
        dispatch(ProvisionDeviceActions.confirmUnlink(contextSettings))
      }
    >
      {(iotDevice &&
        T.format(
          T.admin.energymanager.confirmunlinktextformat,
          iotDevice.ioTDevice.deviceName
        )) || <span />}
    </ActionModal>
  );
};

export default React.memo(ConfirmUnlinkDialog);
