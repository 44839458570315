import React, { useContext } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';

import T from 'ecto-common/lib/lang/Language';

import AdminPage from 'js/components/AdminPage';

import SignalMappingPage from 'js/components/SignalMapping/SignalMappingPage';
import Equipments from 'js/components/Equipments/Equipments';
import EditLocation from 'js/components/EditLocation/EditLocation';
import ProcessMaps from 'js/components/ProcessMaps/ProcessMaps';

import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

const contentRenderFunctions: Record<
  string,
  (location: SingleGridNode) => React.ReactNode
> = {
  dashboard: (selectedLocation: SingleGridNode) => (
    <EditLocation selectedLocation={selectedLocation} />
  ),
  equipment: (selectedLocation: SingleGridNode) => (
    <Equipments
      selectedLocation={selectedLocation}
      equipments={(selectedLocation && selectedLocation.equipments) || []}
    />
  ),
  signalmapping: (selectedLocation: SingleGridNode) => (
    <SignalMappingPage selectedLocation={selectedLocation} />
  ),
  processmaps: () => <ProcessMaps />
};

const Location = () => {
  const { nodeId, page: pageFromParams } = useParams<NodeParams>();
  const { tenantId } = useContext(TenantContext);
  const nodeMap = useAdminSelector((state) => state.general.nodeMap);
  const nodeList = useAdminSelector((state) => state.general.nodeList);
  const selectedNode = nodeId && getNodeFromMap(nodeMap, nodeId);
  const nodeListEmpty = nodeList?.length === 0;
  let page = nodeId === ROOT_NODE_ID ? 'dashboard' : pageFromParams;

  if (!selectedNode && nodeId !== ROOT_NODE_ID) {
    if (nodeListEmpty) {
      return (
        <Redirect
          to={{
            pathname: `/${tenantId}/home/${ROOT_NODE_ID}/dashboard`
          }}
        />
      );
    }

    const content = (
      <ToolbarContentPage title={T.location.error.find} wrapContent>
        {T.location.error.find}
      </ToolbarContentPage>
    );

    return <AdminPage content={content} />;
  }

  const contentFunc = contentRenderFunctions[page];

  return <AdminPage content={contentFunc && contentFunc(selectedNode)} />;
};

export default Location;
