import React, { useMemo, useRef } from 'react';
import styles from './ProcessMapEditor.module.css';
import classNames from 'classnames';
import { Base64 } from 'js-base64';
import {
  ProcessMapObjectType,
  ProcessMapObjectTypes
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import { SymbolModel } from 'ecto-common/lib/API/PresentationAPIGen';

type SymbolModelViewProps = {
  title: string;
  src: string;
  onHover: (yPosition: number, imageWidth: number, imageHeight: number) => void;
  onClick: (
    imageWidth: number,
    imageHeight: number,
    mouseX: number,
    mouseY: number
  ) => void;
  selected: boolean;
  onDragStart?: React.DragEventHandler<HTMLImageElement>;
  onDragEnd?: (
    event: React.DragEvent<HTMLImageElement>,
    width: number,
    height: number
  ) => void;
  width: number;
  height: number;
};

const SymbolModelView = ({
  title,
  onClick,
  onHover,
  selected,
  src,
  onDragEnd,
  onDragStart,
  width,
  height
}: SymbolModelViewProps) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={ref}
      onMouseOver={() => {
        if (ref.current != null) {
          const scrollOffset =
            ref.current.parentElement?.parentElement?.scrollTop ?? 0;
          let yPosition = ref.current.offsetTop - scrollOffset;
          onHover(yPosition, width, height);
        }
      }}
      onClick={(event) => {
        onClick(width, height, event.clientX, event.clientY);
      }}
      className={classNames(selected && styles.selected)}
    >
      <img
        src={src}
        title={title}
        draggable
        onDragEnd={(e) => onDragEnd(e, width, height)}
        onDragStart={onDragStart}
      />
    </div>
  );
};

export type ProcessMapLibrarySystemItem = {
  type: Exclude<ProcessMapObjectType, typeof ProcessMapObjectTypes.Symbol>;
};

export type ProcessMapLibrarySymbolItemWithSize = {
  type: typeof ProcessMapObjectTypes.Symbol;
  item: SymbolModel;
  width: number;
  height: number;
};

export type SymbolModelOrSystemItem =
  | ProcessMapLibrarySymbolItemWithSize
  | ProcessMapLibrarySystemItem;

type ProcessMapLibraryListProps = {
  onClickLibraryItem: (
    item: ProcessMapLibrarySymbolItemWithSize,
    mouseX: number,
    mouseY: number
  ) => void;
  selectedIndex: number;
  footer?: React.ReactNode;
  library: SymbolModel[];
  onDragItemEnd?: (
    item: ProcessMapLibrarySymbolItemWithSize,
    event: React.DragEvent<HTMLImageElement>,
    offsetX: number,
    offsetY: number
  ) => void;
  onDragItemStart?: (event: React.DragEvent<HTMLImageElement>) => void;
  onHoverItem?: (
    item: ProcessMapLibrarySymbolItemWithSize,
    href: string,
    yPosition: number
  ) => void;
  withBorder?: boolean;
};

const ProcessMapLibraryList = ({
  onClickLibraryItem,
  selectedIndex,
  footer,
  library,
  onDragItemStart,
  onDragItemEnd,
  onHoverItem,
  withBorder
}: ProcessMapLibraryListProps) => {
  const dragOffset = useRef({ x: 0, y: 0 });
  const onDragStart = (dragEvent: React.DragEvent<HTMLImageElement>) => {
    onDragItemStart?.(dragEvent);
    dragEvent.dataTransfer.effectAllowed = 'move';
    dragEvent.dataTransfer.dropEffect = 'none';
    const rect = dragEvent.currentTarget.getBoundingClientRect();
    dragOffset.current.x = dragEvent.clientX - rect.x - rect.width / 2.0;
    dragOffset.current.y = dragEvent.clientY - rect.y - rect.height / 2.0;
  };

  const libraryDomObjectUrls = useMemo(() => {
    return (library ?? []).map((item) =>
      URL.createObjectURL(
        new Blob([Base64.decode(item.data)], { type: 'image/svg+xml' })
      )
    );
  }, [library]);

  return (
    <div className={styles.library}>
      <div
        className={classNames(styles.libraryList, withBorder && styles.border)}
      >
        {library &&
          library.map((item, idx) => (
            <SymbolModelView
              key={idx}
              title={item.name}
              width={item.width}
              height={item.height}
              src={libraryDomObjectUrls[idx]}
              selected={selectedIndex === idx}
              onClick={(width, height, mouseX, mouseY) =>
                onClickLibraryItem(
                  { type: ProcessMapObjectTypes.Symbol, item, width, height },
                  mouseX,
                  mouseY
                )
              }
              onHover={(yPosition, width, height) =>
                onHoverItem?.(
                  { type: ProcessMapObjectTypes.Symbol, item, width, height },
                  libraryDomObjectUrls[idx],
                  yPosition
                )
              }
              onDragStart={onDragStart}
              onDragEnd={(e, width, height) =>
                onDragItemEnd?.(
                  { type: ProcessMapObjectTypes.Symbol, item, width, height },
                  e,
                  dragOffset.current.x,
                  dragOffset.current.y
                )
              }
            />
          ))}
      </div>
      {footer}
    </div>
  );
};

export default React.memo(ProcessMapLibraryList);
