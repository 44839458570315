import React, { useCallback, useState } from 'react';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import DeviceAPIGen, {
  DeprovisionReason
} from 'ecto-common/lib/API/DeviceAPIGen';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import useFormInputWithValidation from 'ecto-common/lib/ModelForm/useFormInputWithValidation';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';

interface RemoveDeviceModalProps {
  deviceId: string;
  isOpen?: boolean;
  onModalClose: () => void;
  onDelete: () => void;
}

const DeprevisionResonOptions = [
  { label: T.admin.deprovisionreason.faulty, value: DeprovisionReason.Faulty },
  { label: T.admin.deprovisionreason.other, value: DeprovisionReason.Other }
];

type RemoveDeviceInput = {
  reason: DeprovisionReason;
  notes: string;
};

const models: ModelDefinition<RemoveDeviceInput>[] = [
  {
    key: (input) => input.reason,
    modelType: ModelType.OPTIONS,
    label: T.admin.iotdevicedetails.deprovisionreason,
    options: DeprevisionResonOptions,
    hasError: isNullOrWhitespace
  },
  {
    key: (input) => input.notes,
    modelType: ModelType.TEXT,
    label: T.admin.iotdevicedetails.notes,
    hasError: isNullOrWhitespace
  }
];

const RemoveDeviceModal = ({
  deviceId,
  isOpen,
  onModalClose,
  onDelete
}: RemoveDeviceModalProps) => {
  const removeDeviceMutation =
    DeviceAPIGen.Devices.deprovisionDevice.useMutation(
      { deviceId },
      {
        onError: (_unused) => {
          toastStore.addErrorToastForDeletedItem(deviceId);
        },
        onSuccess: (_unused) => {
          toastStore.addSuccessToastForDeletedItem(deviceId);
          onModalClose?.();
          onDelete?.();
        }
      }
    );

  const [editedInput, setEditedInput] = useState<RemoveDeviceInput>(null);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  const onDeprovisionDevice = useCallback(() => {
    setIsConfirmingDelete(false);
    removeDeviceMutation.mutate(editedInput);
  }, [editedInput, removeDeviceMutation]);

  const [isValidForm, setIsValidForm] = useState(false);
  const onUpdateInput = useFormInputWithValidation(
    editedInput,
    setEditedInput,
    setIsValidForm,
    models
  );

  return (
    <>
      <ActionModal
        isOpen={isOpen && deviceId != null}
        onModalClose={onModalClose}
        onConfirmClick={() => setIsConfirmingDelete(true)}
        actionText={T.admin.iotdevicedetails.deprovisiondevice}
        title={T.admin.iotdevicedetails.deprovisiondevice}
        headerIcon={Icons.Delete}
        disableActionButton={!isValidForm}
        isLoading={removeDeviceMutation.isLoading}
      >
        <ModelForm
          models={models}
          input={editedInput}
          onUpdateInput={onUpdateInput}
        />
      </ActionModal>
      <ConfirmDeleteDialog
        isOpen={isConfirmingDelete}
        onModalClose={() => setIsConfirmingDelete(false)}
        onDelete={onDeprovisionDevice}
        itemName={deviceId}
      />
    </>
  );
};

export default RemoveDeviceModal;
