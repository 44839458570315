import React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';
import styles from './CheckMark.module.css';

const CheckMark = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 12 12" {...props}>
    <polyline
      className={styles.checkMark}
      points="1.9778477549552917, 6.550377428531647 4.4978461265563965, 9.060375273227692 10.257846117019653, 3.3003774285316467"
    />
  </SvgIcon>
);

CheckMark.defaultProps = {
  height: 12,
  width: 12
};

export default CheckMark;
