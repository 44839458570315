import React, { useMemo, useState } from 'react';
import DataSourceTypes from 'ecto-common/lib/Dashboard/datasources/DataSourceTypes';
import SignalCurveEditor from 'ecto-common/lib/SignalCurveEditor/SignalCurveEditor';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import SignalNamesModelEditor from 'ecto-common/lib/Dashboard/modelforms/SignalNamesModelEditor';
import SectionListPriority from 'ecto-common/lib/Dashboard/SectionListPriority';
import LastSignalValuesDataSource, {
  SignalInputType
} from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';
import HelpPaths from '../../../help/tocKeys';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';
import { colors } from 'ecto-common/lib/styles/variables';
import { yAxisFormatter } from 'ecto-common/lib/SignalSelector/ChartUtils';
import { getSignalNameWithSignalType } from 'ecto-common/lib/SignalSelector/SignalUtils';
import {
  AdjustableChartCurve,
  AdjustableChartSeries,
  HighchartsOptionsWithAdjustableSettings
} from 'ecto-common/lib/AdjustableChart/AdjustableChart';
import { CustomPanelProps } from 'ecto-common/lib/Dashboard/Panel';
import { SignalTimeSeriesDataSourceResult } from 'ecto-common/lib/Dashboard/datasources/SignalTimeSeriesDataSource';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import {
  ModelDefinition,
  ModelFormSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';
import { ReducerFunction } from 'ecto-common/lib/utils/typescriptUtils';

type SignalCurveEditorPanelConfig = {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
  xaxissignals: SignalInputType[];
};

const DEFAULT_MIN_VALUE_X = -30;
const DEFAULT_MAX_VALUE_X = 30;
const DEFAULT_MIN_VALUE_Y = -30;
const DEFAULT_MAX_VALUE_Y = 30;

type Point = {
  x: number;
  y: number;
};

const invalidPoint = (point: Point): boolean =>
  point?.x == null || point?.y == null;

/**
 * Get line intersection between line (p0, p1) and line (p2, p3)
 * @param p0
 * @param p1
 * @param p2
 * @param p3
 * @returns {[]}
 */
const getLineIntersection = (
  p0: Point,
  p1: Point,
  p2: Point,
  p3: Point
): [number, number] | null => {
  if (
    invalidPoint(p0) ||
    invalidPoint(p1) ||
    invalidPoint(p2) ||
    invalidPoint(p3)
  ) {
    return null;
  }

  const s1_x = p1.x - p0.x;
  const s1_y = p1.y - p0.y;
  const s2_x = p3.x - p2.x;
  const s2_y = p3.y - p2.y;

  const s =
    (-s1_y * (p0.x - p2.x) + s1_x * (p0.y - p2.y)) /
    (-s2_x * s1_y + s1_x * s2_y);
  const t =
    (s2_x * (p0.y - p2.y) - s2_y * (p0.x - p2.x)) /
    (-s2_x * s1_y + s1_x * s2_y);

  if (s >= 0 && s <= 1 && t >= 0 && t <= 1) {
    return [p0.x + t * s1_x, p0.y + t * s1_y];
  }

  return null;
};

/**
 * Convert signal values to point (x,y)
 * @param signalX
 * @param signalY
 * @returns {{x, y}}
 */
const signalToPoint = ({ signalX, signalY }: AdjustableChartSeries): Point => ({
  x: signalX.value,
  y: signalY.value
});

type IntersectionResult = {
  point: [number, number];
  units: [string, string];
};

/**
 * Creates a function that adds intersection that crosses p0->p1 to a result
 * @param p0
 * @param p1
 * @returns {function(*, *, *, *): *}
 */
const addIntersections = (
  p0: Point,
  p1: Point
): ReducerFunction<AdjustableChartSeries[], IntersectionResult[]> => {
  return (
    result: IntersectionResult[],
    signal: AdjustableChartSeries,
    index: number,
    collection: AdjustableChartSeries[]
  ) => {
    if (index > 0) {
      const prevPoint = collection[index - 1];
      const intersection = getLineIntersection(
        p0,
        p1,
        signalToPoint(prevPoint),
        signalToPoint(signal)
      );
      if (intersection) {
        // Determine unit, if axis signals have same unit, use it else null
        const unitX =
          prevPoint.signalX?.unit === signal.signalX?.unit
            ? signal.signalX?.unit
            : null;
        const unitY =
          prevPoint.signalY?.unit === signal.signalY?.unit
            ? signal.signalY?.unit
            : null;

        result.push({
          point: intersection,
          units: [unitX, unitY]
        });
      }
    }
    return result;
  };
};

/**
 * Create HighCharts settings object
 * @param {array} curves Curves with data points
 * @param {number=} minX
 * @param {number=} minY
 * @param {number=} maxX
 * @param {number=} maxY
 * @param {Signal[]=} xaxissignals
 * @returns {*}
 */
const createSettings = (
  curves: AdjustableChartCurve[],
  minX: number,
  minY: number,
  maxX: number,
  maxY: number,
  xaxissignals: XAxisSignal[]
): HighchartsOptionsWithAdjustableSettings => {
  const _minX = minX ?? DEFAULT_MIN_VALUE_X;
  const _maxX = maxX ?? DEFAULT_MAX_VALUE_X;
  const _minY = minY ?? DEFAULT_MIN_VALUE_Y;
  const _maxY = maxY ?? DEFAULT_MAX_VALUE_Y;
  const crossingSeries = _.map(xaxissignals, (xaxis) => {
    // X axis line from p0 to p1
    const p0: Point = { x: xaxis.value, y: _minY };
    const p1: Point = { x: xaxis.value, y: _maxY };
    const intersections: IntersectionResult[] = _.flatMap(
      curves,
      ({ series }) =>
        _.reduce(series, addIntersections(p0, p1), [] as IntersectionResult[])
    );

    return {
      data: _.map(intersections, 'point'),
      type: 'scatter',
      color: xaxis.color,
      name: xaxis.name,

      // Only show tooltip when directly above point else it will show
      // it directly when mouse is inside the chart for this scatter plot
      stickyTracking: false,

      tooltip: {
        headerFormat: '',
        pointFormatter: function () {
          const data = intersections[this.index];
          return (
            formatNumberUnit(this.x, data.units[0]) +
            ', ' +
            formatNumberUnit(this.y, data.units[1])
          );
        }
      }
    };
  });

  // When minX is larger than a certain number, the Y axis labels are obscured by the chart area.
  // In this case, flip the labels over to the other side.
  let shouldFlipYAxisLabels = _minX >= -1;

  let firstXAxisSignalUnit = _.get(curves, '[0].series[0].signalX.unit');
  const firstYAxisSignalUnit = _.get(curves, '[0].series[0].signalY.unit');

  // Fallback to first x axis vertical signal for unit
  if (_.isEmpty(firstXAxisSignalUnit)) {
    firstXAxisSignalUnit = _.head(xaxissignals)?.unit;
  }

  return {
    additionalSeries: crossingSeries,
    xAxis: {
      min: _minX,
      max: _maxX,
      title: {
        text: firstXAxisSignalUnit,
        rotation: 0,
        offset: 0,
        align: 'low',
        y: 15,
        x: 5
      },
      crossing: Math.max(0, _minY),
      plotLines: _.map(xaxissignals, (xaxis) => ({
        value: xaxis.value,
        color: xaxis.color,
        width: 2,
        dashStyle: 'Dash'
      }))
    },
    yAxis: {
      startOnTick: false,
      min: _minY,
      max: _maxY,
      crossing: Math.max(0, _minX),
      labels: {
        formatter: yAxisFormatter,
        x: shouldFlipYAxisLabels ? 10 : -8,
        align: shouldFlipYAxisLabels ? 'left' : 'right'
      },
      title: {
        text: firstYAxisSignalUnit,
        align: 'high',
        y: 10,
        x: 10,
        offset: shouldFlipYAxisLabels ? 15 : -10,
        rotation: 0
      }
    },
    exporting: {
      enabled: false
    },
    dragDrop: {
      dragMinY: _minY,
      dragMaxY: _maxY,
      dragMinX: _minX,
      dragMaxX: _maxX
    },
    legend: {
      enabled: true,
      symbolHeight: 12,
      symbolWidth: 12,
      symbolRadius: 0,
      squareSymbol: false
    }
  };
};

const MAX_XAXIS = 1;

/**
 * @typedef {Object} SignalCurveEditorPanelData
 * @property {Signal[]} xaxissignals
 * @property {number=} minX
 * @property {number=} maxX
 * @property {number=} minY
 * @property {number=} maxY
 * @property {Curve[]} curves
 */

type XAxisSignal = {
  value: number;
  color: string;
  name: string;
  unit: string;
};

type SignalCurveEditorPanelProps = CustomPanelProps & {
  data: {
    curves: SignalTimeSeriesDataSourceResult;
  } & SignalCurveEditorPanelConfig;
};

/**
 *
 * @param {SignalCurveEditorPanelData} data
 * @param {Object} panelApi
 * @returns {JSX.Element}
 * @constructor
 */
const SignalCurveEditorPanel = ({
  data,
  panelApi
}: SignalCurveEditorPanelProps) => {
  const xAxisValues = LastSignalValuesDataSource({
    signals: data.xaxissignals,
    cacheContext: panelApi.cacheContext
  });
  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );
  const signalUnitTypesMap = useCommonSelector(
    (state) => state.general.signalUnitTypesMap
  );

  const xaxisSignals: XAxisSignal[] = useMemo(() => {
    if (xAxisValues.signalInfo.matchingSignals) {
      const items =
        MAX_XAXIS != null
          ? _.take(xAxisValues.signalInfo.matchingSignals, MAX_XAXIS)
          : xAxisValues.signalInfo.matchingSignals;
      return _.map(items, (match) => {
        const signalType = signalTypesMap[match.signal.signalTypeId];
        const unit = signalUnitTypesMap[signalType?.unitId]?.unit;
        return {
          value: _.find(xAxisValues.signalValues, {
            signalId: match.signal.signalId
          })?.value,
          color: match.signalInfo.color ?? colors.surface3Color,
          name: getSignalNameWithSignalType(
            match.signal,
            false,
            signalTypesMap,
            signalUnitTypesMap
          ),
          unit
        };
      });
    }

    return [];
  }, [
    signalTypesMap,
    signalUnitTypesMap,
    xAxisValues.signalInfo.matchingSignals,
    xAxisValues.signalValues
  ]);

  const [modifiedCurves, setModifiedCurve] = useState(data.curves.curves);

  const settings = useMemo(
    () =>
      createSettings(
        modifiedCurves,
        data.minX,
        data.minY,
        data.maxX,
        data.maxY,
        xaxisSignals
      ),
    [modifiedCurves, data.minX, data.minY, data.maxX, data.maxY, xaxisSignals]
  );

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <SignalCurveEditor
        isLoading={xAxisValues.isLoading}
        hasError={xAxisValues.hasError}
        curves={data.curves.curves}
        onCurvesChanged={setModifiedCurve}
        settings={settings}
        size={panelApi.size}
      />
    </div>
  );
};

const sections: ModelFormSectionType<SignalCurveEditorPanelConfig>[] = [
  {
    label: T.admin.dashboards.sections.curverange,
    initiallyCollapsed: false,
    lines: [
      {
        models: [
          {
            key: (input) => input.minX,
            modelType: ModelType.NUMBER,
            label: T.common.range.minx,
            placeholder: _.toString(DEFAULT_MIN_VALUE_X)
          },
          {
            key: (input) => input.maxX,
            modelType: ModelType.NUMBER,
            label: T.common.range.maxx,
            placeholder: _.toString(DEFAULT_MAX_VALUE_X)
          }
        ]
      },
      {
        models: [
          {
            key: (input) => input.minY,
            modelType: ModelType.NUMBER,
            label: T.common.range.miny,
            placeholder: _.toString(DEFAULT_MIN_VALUE_Y)
          },
          {
            key: (input) => input.maxY,
            modelType: ModelType.NUMBER,
            label: T.common.range.maxy,
            placeholder: _.toString(DEFAULT_MAX_VALUE_Y)
          }
        ]
      }
    ]
  },
  {
    label: T.admin.dashboards.sections.curvexaxis,
    lines: [
      {
        models: [
          {
            key: (input) => input.xaxissignals,
            modelType: ModelType.CUSTOM,
            render: (props) => (
              <SignalNamesModelEditor
                {...props}
                minItems={1}
                maxItems={MAX_XAXIS}
              />
            ),
            label: ''
          }
        ]
      }
    ],
    listPriority: SectionListPriority.Signals // Should always be last since it looks odd otherwise
  }
];

export const SignalCurveEditorPanelData = {
  dataSourceSectionsConfig: {
    [DataSourceTypes.SIGNAL_TIME_SERIES]: {
      useConstantValues: true,
      optionalSignalModels: [] as ModelDefinition<SignalInputType, object>[]
    }
  },
  emptyTargets: {
    curves: {
      sourceType: DataSourceTypes.SIGNAL_TIME_SERIES
    }
  },
  sections,
  helpPath: HelpPaths.docs.dashboard.dashboards.signal_curve_editor
};

export default React.memo(SignalCurveEditorPanel);
