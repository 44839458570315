import {
  GetEnumsAndFixedConfigurationsResponseModel,
  SignalTypeResponseModel
} from 'ecto-common/lib/API/APIGen';
import { DashboardPanel } from 'ecto-common/lib/Dashboard/Panel';

export type PanelMigration = {
  version: number;
  migration: (
    panel: DashboardPanel,
    enums: GetEnumsAndFixedConfigurationsResponseModel,
    signalTypesMap: Record<string, SignalTypeResponseModel>
  ) => void;
};

export enum PanelType {
  LOCATION_MAP = 'locationMap',
  ALARM_LIST = 'alarmList',
  SIGNAL_LIST = 'signalsList',
  ALARM_COUNT = 'alarmCount',
  LINE_CHART = 'lineChart',
  BAR_CHART = 'barChart',
  SCATTER_CHART = 'scatterChart',
  BAR_CHART_COMPARE = 'barChartCompare',
  NODE_NAME = 'nodeName',
  PROCESS_MAP = 'processMap',
  GAUGE = 'gauge',
  TEXT = 'text',
  ALARM_STATUS_LIST = 'alarmStatusList',
  SIGNAL_CURVE_SIGNAL_EDITOR = 'signalCurveEditor'
}
