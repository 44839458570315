import { useCallback, useContext } from 'react';
import useInterval from 'ecto-common/lib/hooks/useInterval';
import API from 'ecto-common/lib/API/API';
import _ from 'lodash';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

/**
 * Enable live signal updates from specified equipment ids
 * @param equipmentIds
 */
export const useLiveEquipmentSignals = (equipmentIds: string[]) => {
  const { contextSettings } = useContext(TenantContext);

  useInterval(
    useCallback(() => {
      // Set every 5 minutes to keep live telemetry going
      _.forEach(equipmentIds, (equipmentId) =>
        API.Devices.setLiveTelemetry(contextSettings, equipmentId).catch(_.noop)
      );
    }, [equipmentIds, contextSettings]),
    5 * 60 * 1000,
    true
  );
};
