import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  MouseEventHandler
} from 'react';
import { DEFAULT_TIMEZONE } from 'ecto-common/lib/constants';
import styles from './DateInput.module.css';
import textInputStyles from 'ecto-common/lib/TextInput/TextInput.module.css';
import DateTime from 'react-datetime';
import DateTextInput from './DateTextInput';
const DATE_FORMAT = 'YYYY-MM-DD';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import './DateTime.global.css';
import {
  convertReactDatetimeToWrapAroundToNextUnit,
  isDateStringValid,
  getDefaultDateTimeFormat,
  TimeFormats,
  getLocaleSpecificTimeFormatShort
} from 'ecto-common/lib/utils/dateUtils';

const dateInputWithLabel =
  (
    label: string,
    setFocus: (
      target:
        | React.MouseEvent<HTMLDivElement>
        | React.FocusEvent<HTMLInputElement>
    ) => void,
    expandingWidth: boolean,
    hasFocus: boolean,
    setPendingInput: React.Dispatch<React.SetStateAction<string>>,
    compact: boolean,
    enabled: boolean,
    placeholder: string,
    clearButton: boolean,
    onClear: MouseEventHandler<SVGSVGElement>,
    fieldClearIndex: number,
    textFieldClassName: string
    // eslint-disable-next-line react/prop-types
  ) =>
  (
    { value }: { value: string },
    openCalendar: () => void,
    closeCalendar: () => void
  ) => (
    <DateTextInput
      key={'DateTextInput-' + fieldClearIndex}
      enabled={enabled}
      textFieldClassName={textFieldClassName}
      className={styles.dateInput}
      openCalendar={openCalendar}
      closeCalendar={closeCalendar}
      label={label}
      value={value}
      onFocus={setFocus}
      expandingWidth={expandingWidth}
      hasFocus={hasFocus}
      setPendingInput={setPendingInput}
      compact={compact}
      placeholder={placeholder}
      clearButton={clearButton}
      onClear={onClear}
    />
  );

interface DateInputProps {
  /**
   * Whether or not the date input is available for inputting values.
   */
  enabled?: boolean;
  /**
   * The current value of the date input.
   */
  value?: Moment | string;
  /**
   * Triggered whenever the value changes.
   */
  onChange: (newDate: Moment) => void;
  /**
   * Custom function that determines whether or not dates should be shown in the calendar picker.
   */
  isValidDate?: (currentDate: Moment, selectedDate: Moment) => boolean;
  /**
   * Whether or not the value that the user entered is invalid based on the context.
   */
  hasError?: boolean;
  /**
   * Label that is shown to the left of the date in the text field.
   */
  label?: string;
  /**
   * Custom class name for the text field wrapper.
   */
  className?: string;

  /**
   * Custom class name for the text field itself.
   */
  textFieldClassName?: string;

  /**
   * Whether or not the text input field should be slightly smaller.
   */
  compact?: boolean;
  /**
   * Whether or not the control should expand to take all available width.
   */
  expandingWidth?: boolean;
  /**
   * Whether or not the input field should have white background and borders.
   */
  wrapContent?: boolean;
  /**
   * The time format part of the date format. If set then time is editable.
   */
  timeFormat?: boolean;
  /**
   * Placeholder string for the input field
   */
  placeholder?: string;
  /**
   * Whether or not a clear button should be shown.
   */
  clearButton?: boolean;
  /**
   * Whether or not to allow empty values
   */
  allowEmptyValue?: boolean;
}

/**
 *  DateInput is our standard component for entering date values. It can be used to select dates with
 *  or without a time component and supports lots of customization options.
 */
const DateInput = ({
  value,
  onChange,
  isValidDate,
  label,
  className,
  placeholder,
  hasError = false,
  enabled = true,
  compact = true,
  timeFormat = null,
  wrapContent = false,
  expandingWidth = false,
  clearButton = false,
  allowEmptyValue = false,
  textFieldClassName = null
}: DateInputProps) => {
  const [pendingInput, setPendingInput] = useState<string>(null);
  const [hasFocus, setHasFocus] = useState(false);

  // Ugly workaround since there is a bug in react-datetime where passing null
  // after a value has been set is being ignored. Recreate component when clearing.
  const [clearIndex, setClearIndex] = useState(0);

  const prevVal = useRef(value);

  // Text field work around, same problem
  const [fieldClearIndex, setFieldClearIndex] = useState(0);

  useEffect(() => {
    if (value == null) {
      setClearIndex((oldClearIndex) => oldClearIndex + 1);
      prevVal.current = null;
    }
  }, [value]);

  useEffect(() => {
    if (pendingInput === null) {
      setFieldClearIndex((oldFieldClearIndex) => oldFieldClearIndex + 1);
    }
  }, [pendingInput]);

  // Unfortunately have to use any as DateTime does not expose types for the
  // ref imperative API class
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const ref = useRef<any>(null);

  const setFocus = useCallback(() => {
    // Make sure the "placeholder" / "Current calendar selection date" is synce with current time
    if (ref.current != null && ref.current.props.value == null) {
      ref.current.setViewDate(new Date());
    }

    setHasFocus(true);
  }, []);

  const fullFormat = getDefaultDateTimeFormat(
    timeFormat ? TimeFormats.LONG_TIME : TimeFormats.NONE
  );
  const pendingInputIsValid = isDateStringValid(
    pendingInput,
    fullFormat,
    allowEmptyValue
  );

  const removeFocus = useCallback(() => {
    if (pendingInput != null && pendingInputIsValid) {
      if (isNullOrWhitespace(pendingInput) && allowEmptyValue) {
        onChange(null);
      } else {
        onChange(
          moment.tz(pendingInput, fullFormat, true, DEFAULT_TIMEZONE).utc()
        );
      }

      setPendingInput(null);
    }
    setHasFocus(false);
  }, [
    pendingInput,
    onChange,
    fullFormat,
    pendingInputIsValid,
    allowEmptyValue
  ]);

  const _onChange = useCallback(
    (val: Moment) => {
      let _val = val;

      if (ref.current && ref.current.state.currentView === 'time') {
        _val = convertReactDatetimeToWrapAroundToNextUnit(
          val,
          moment(prevVal.current)
        );
      }

      prevVal.current = _val;

      setPendingInput(null);
      onChange(_val);
    },
    [setPendingInput, onChange]
  );

  let _hasError = enabled && (hasError || !pendingInputIsValid);

  const onClear: React.MouseEventHandler<SVGSVGElement> = useCallback(
    (e) => {
      (e.target as HTMLElement).blur?.();
      _onChange(null);
    },
    [_onChange]
  );

  return (
    <DateTime
      className={classNames(
        !wrapContent && styles.dateTimeWrapper,
        wrapContent && styles.wrap,
        hasFocus && textInputStyles.focus,
        _hasError && textInputStyles.error,
        className
      )}
      ref={ref}
      initialViewDate={new Date()}
      timeFormat={timeFormat ? getLocaleSpecificTimeFormatShort() : false}
      value={value}
      key={'DateTime' + clearIndex}
      onChange={_onChange}
      dateFormat={DATE_FORMAT}
      displayTimeZone={DEFAULT_TIMEZONE}
      isValidDate={isValidDate}
      renderInput={dateInputWithLabel(
        label,
        setFocus,
        expandingWidth,
        hasFocus,
        setPendingInput,
        compact,
        enabled,
        placeholder,
        clearButton,
        onClear,
        fieldClearIndex,
        textFieldClassName
      )}
      onClose={removeFocus}
      onOpen={setFocus}
    />
  );
};

export default React.memo(DateInput);
