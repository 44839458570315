import React from 'react';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';
import { numDecimalsForUnit } from 'ecto-common/lib/Charts/UnitUtil';
import {
  SignalTypeResponseModel,
  UnitResponseModel
} from 'ecto-common/lib/API/APIGen';
import _ from 'lodash';
import {
  ProcessMapSignalTextObject,
  defaultSignalSubtitleSettings,
  defaultSignalTitleSettings
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import { ProcessMapViewSignal } from 'ecto-common/lib/ProcessMap/ProcessMapView';
import T from 'ecto-common/lib/lang/Language';
import { ProcessMapTextView } from 'ecto-common/lib/ProcessMap/View/ProcessMapTextView';

export type ProcessMapSignalViewValue = {
  values?: { value: number }[];
  isWritable?: boolean;
};

type ProcessMapSignalViewProps = {
  signal: ProcessMapViewSignal;
  signalType: SignalTypeResponseModel;
  unit: UnitResponseModel;
  node: ProcessMapSignalTextObject;
  isDragging: boolean;
  isLoading: boolean;
  updateTextSize: (
    objectIndex: number,
    rectIndex: number,
    width: number,
    height: number
  ) => void;
  objectIndex: number;
  isHovering: boolean;
  value: ProcessMapSignalViewValue;
  showSignalLabelsWhenNotFound: boolean;
  onMouseOver?: (event: MouseEvent) => void;
  onMouseOut?: (event: MouseEvent) => void;
  onClick?: (event: MouseEvent) => void;
};

export const ProcessMapSignalView = React.memo(
  ({
    node,
    isDragging,
    updateTextSize,
    signal,
    signalType,
    value,
    unit,
    isLoading,
    objectIndex,
    isHovering,
    showSignalLabelsWhenNotFound,
    onClick,
    onMouseOver,
    onMouseOut
  }: ProcessMapSignalViewProps) => {
    const valueNumber = _.head(value?.values)?.value;
    let subtitle: string = null;

    if (node.hideValue) {
      subtitle = null;
    } else if (node.signalTypeId == null) {
      if (showSignalLabelsWhenNotFound) {
        subtitle = T.admin.processmaps.nosignaltypeselected;
      } else {
        subtitle = '';
      }
    } else if (isLoading) {
      subtitle = '';
    } else if (signal != null || showSignalLabelsWhenNotFound) {
      subtitle = formatNumberUnit(
        valueNumber,
        unit?.unit ?? '',
        numDecimalsForUnit(unit?.unit ?? '')
      );
    } else {
      subtitle = '';
    }
    let title = '';

    if (!node.hideLabel && (signal != null || showSignalLabelsWhenNotFound)) {
      title = signal?.name ?? signalType?.name ?? '';
    }

    return (
      <ProcessMapTextView
        node={node}
        objectIndex={objectIndex}
        isDragging={isDragging}
        updateTextSize={updateTextSize}
        title={title}
        titleTextSettings={{
          ...defaultSignalTitleSettings,
          ...node.labelTextSettings
        }}
        subtitle={subtitle}
        subtitleTextSettings={{
          ...defaultSignalSubtitleSettings,
          ...node.valueTextSettings
        }}
        isHovering={isHovering}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        shouldHide={false}
      />
    );
  }
);
