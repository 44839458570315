import React, { useCallback, useContext, useState } from 'react';

import AddButton from 'ecto-common/lib/Button/AddButton';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import T from 'ecto-common/lib/lang/Language';
import DashboardsPageList from 'js/components/Dashboards/DashboardsPageList';

import _ from 'lodash';

import { useHistory, useLocation } from 'react-router';
import { getTemplateManagementRoute } from 'js/utils/routeConstants';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import DashboardDeleteModal from 'ecto-common/lib/DashboardEditor/DashboardDeleteModal';
import UUID from 'uuidjs';
import { InitialDashboard } from 'ecto-common/lib/DashboardEditor/DashboardConstants';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import EditDashboardPropertiesModal from 'ecto-common/lib/DashboardEditor/EditDashboardPropertiesModal';
import HelpPaths from 'ecto-common/help/tocKeys';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import APIGen, { DashboardResponseModel } from 'ecto-common/lib/API/APIGen';
import queryString from 'query-string';
import { useQueryClient } from '@tanstack/react-query';

interface DashboardsPageProps {
  segmentControl?: React.ReactNode;
}

const DashboardsPage = ({ segmentControl }: DashboardsPageProps) => {
  const queryClient = useQueryClient();

  const { contextSettings } = useContext(TenantContext);
  const triggerReload = useCallback(() => {
    queryClient.invalidateQueries(
      APIGen.AdminDashboard.getPagedDashboards.path(contextSettings)
    );
  }, [contextSettings, queryClient]);

  const history = useHistory();
  const [deleteItem, setDeleteItem] = useState<DashboardResponseModel>(null);
  const [showingAddModal, showAddModal, hideAddModal] = useDialogState(false);
  const [dashboard] = useState({
    ...InitialDashboard,
    dashboardId: UUID.generate()
  });
  const { tenantId } = useContext(TenantContext);

  const onClickRow = useCallback(
    (row: DashboardResponseModel) => {
      history.push(
        getTemplateManagementRoute(tenantId, 'dashboards', row.dashboardId)
      );
    },
    [history, tenantId]
  );

  const onCreateDashboard = useCallback(() => {
    _.defer(() => {
      history.push(
        getTemplateManagementRoute(
          tenantId,
          'dashboards',
          dashboard.dashboardId
        )
      );
    });
  }, [history, dashboard, tenantId]);

  const toolbarItems = (
    <>
      {segmentControl}
      <ToolbarFlexibleSpace />

      <ToolbarItem>
        <AddButton onClick={showAddModal}>{T.admin.dashboards.add}</AddButton>
      </ToolbarItem>
    </>
  );
  const location = useLocation();
  const onPageChange = useCallback(
    (page: number) => {
      history.push({
        ...location,
        search: queryString.stringify({ page: page + 1 })
      });
    },
    [history, location]
  );

  const search = queryString.parse(location.search);
  const parsedPageNumber = _.parseInt((search.page as string) ?? '0');
  const page = parsedPageNumber > 1 ? parsedPageNumber - 1 : 0;

  return (
    <ToolbarContentPage
      wrapContent={false}
      title={T.admin.dashboards.title}
      showLocationPicker={false}
      toolbarItems={toolbarItems}
      helpPath={HelpPaths.docs.admin.manage.dashboards}
    >
      <DashboardsPageList
        onClickRow={onClickRow}
        initialPage={page}
        onPageChange={onPageChange}
        onDelete={setDeleteItem}
      />

      <DashboardDeleteModal
        deleteItem={deleteItem}
        onSuccess={triggerReload}
        onModalClose={() => setDeleteItem(null)}
      />

      <EditDashboardPropertiesModal
        isOpen={showingAddModal}
        onCloseModal={hideAddModal}
        dashboard={dashboard}
        onDashboardUpdated={onCreateDashboard}
        isNew
      />
    </ToolbarContentPage>
  );
};

export default DashboardsPage;
