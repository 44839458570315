import styles from 'ecto-common/lib/KeyValueInput/KeyValueInput.module.css';
import React from 'react';
import classNames from 'classnames';

interface KeyValueColumnProps {
  /**
   * The key value components to be displayed on a column.
   */
  children: React.ReactNode;
  /**
   * Whether or not the column should have margin to the next element
   */
  shouldHaveBottomMargin?: boolean;
}

/**
 * This component can be used to lay out multiple key value inputs vertically in a column.
 */
export const KeyValueColumn = ({
  children,
  shouldHaveBottomMargin
}: KeyValueColumnProps) => {
  return (
    <div
      className={classNames(
        styles.keyValueColumn,
        shouldHaveBottomMargin && styles.bottomMargin
      )}
    >
      {children}
    </div>
  );
};

KeyValueColumn.defaultProps = {
  shouldHaveBottomMargin: true
};
