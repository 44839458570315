import { useCallback, useEffect, useMemo, useState } from 'react';
import API from 'ecto-common/lib/API/API';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import {
  IoTDeviceViewResponseModel,
  IoTDeviceWithDeviceResponseModel
} from 'ecto-common/lib/API/APIGen';

export type IoTDeviceWithDeviceAndTags = IoTDeviceViewResponseModel & {
  availableTags?: string[];
};

export type UseEditIoTDeviceLoadingInfo = {
  isLoadingAvailableTags: boolean;
  isSaving: boolean;
  isLoadingDeviceInfo: boolean;
};

export type UseEditIoTDeviceResult = [
  editDevice: IoTDeviceViewResponseModel,
  hasChanges: boolean,
  loadingInfo: UseEditIoTDeviceLoadingInfo,
  saveChanges: () => void,
  clearDevice: () => void,
  onDeviceDataChanged: (name: string[], value: unknown) => void
];

const useEditIoTDevice = (
  initialDevice: IoTDeviceViewResponseModel | IoTDeviceWithDeviceResponseModel
): UseEditIoTDeviceResult => {
  const [editDevice, setEditDevice] = useState(initialDevice);
  const [hasChanges, setHasChanges] = useState(false);

  const [isLoadingDeviceInfo, updateDeviceInfo] = usePromiseCall({
    promise: API.Admin.IoTDevices.getIoTDeviceByDeviceIds,
    onSuccess: (result) => {
      setEditDevice((originalDevice) => ({ ...originalDevice, ...result[0] }));
    },
    onError: () => {
      toastStore.addErrorToast(
        T.admin.iotdevicedetails.error.failedtofetchdeviceinfo
      );
    }
  });

  const [availableTags, setAvailableTags] = useState<string[]>([]);

  const [isLoadingAvailableTags, getAvailableTags] = usePromiseCall({
    promise: API.Admin.IoTDevices.getDeviceTags,
    onSuccess: setAvailableTags,
    onError: () => {
      toastStore.addErrorToast(
        T.admin.iotdevicedetails.error.failedtofetchtags
      );
    },
    initiallyLoading: true
  });

  useEffect(() => {
    getAvailableTags();
  }, [getAvailableTags]);

  const clearDevice = useCallback(() => {
    setEditDevice(null);
  }, []);

  const deviceId =
    (initialDevice as IoTDeviceWithDeviceResponseModel)?.device?.deviceId ??
    initialDevice?.ioTDevice?.deviceId;
  useEffect(() => {
    if (deviceId) {
      updateDeviceInfo([deviceId]);
    }
  }, [deviceId, updateDeviceInfo]);

  useEffect(() => {
    setEditDevice(initialDevice);
  }, [initialDevice]);

  const onDeviceDataChanged = useCallback((name: string[], value: unknown) => {
    setEditDevice((originalDevice) => {
      const newDevice = { ...originalDevice };
      _.set(newDevice, name, value);
      setHasChanges(true);
      return newDevice;
    });
  }, []);

  const [isSaving, _saveDevice] = usePromiseCall({
    promise: API.Admin.IoTDevices.updateDevice,
    onSuccess: () => {
      setHasChanges(false);
      getAvailableTags();
      toastStore.addSuccessToast(T.admin.iotdevicedetails.savesuccess);
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.iotdevicedetails.savefailed);
    }
  });

  const saveChanges = useCallback(() => {
    _saveDevice(editDevice?.ioTDevice);
  }, [_saveDevice, editDevice?.ioTDevice]);

  // Append available tags to device information so it can be used by tag selector
  const _editDevice: IoTDeviceWithDeviceAndTags = useMemo(() => {
    if (editDevice) {
      return { ...editDevice, availableTags };
    }
    return null;
  }, [editDevice, availableTags]);

  return [
    _editDevice,
    hasChanges,
    { isLoadingAvailableTags, isSaving, isLoadingDeviceInfo },
    saveChanges,
    clearDevice,
    onDeviceDataChanged
  ];
};

export default useEditIoTDevice;
