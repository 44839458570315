import React, { ChangeEventHandler, memo, useCallback, useState } from 'react';
import _ from 'lodash';

import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalSpace from 'ecto-common/lib/Modal/ModalSpace';
import DeleteButton from 'ecto-common/lib/Button/DeleteButton';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import SaveButton from 'ecto-common/lib/Button/SaveButton';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import T from 'ecto-common/lib/lang/Language';
import API, { CancellablePromiseCallback } from 'ecto-common/lib/API/API';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import { cancellablePromiseSequence } from 'ecto-common/lib/API/API';

import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { Base64 } from 'js-base64';
import { emptyProcessMapDocument } from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import {
  AddOrUpdateProcessMapRequestModel,
  ProcessMapResponseModel,
  ProcessMapRevisionResponseModel
} from 'ecto-common/lib/API/APIGen';

const updateProcessMap = (
  contextSettings: ApiContextSettings,
  { id, name, description }: AddOrUpdateProcessMapRequestModel
) => {
  return API.Admin.ProcessMaps.updateProcessMap(contextSettings, {
    id,
    name,
    description
  });
};

const createProcessMap = (
  contextSettings: ApiContextSettings,
  { name, description }: AddOrUpdateProcessMapRequestModel
) => {
  return cancellablePromiseSequence(
    (withNextPromise: CancellablePromiseCallback) => {
      return withNextPromise(
        API.Admin.ProcessMaps.createProcessMap(contextSettings, {
          name,
          description
        })
      ).then((data: ProcessMapResponseModel[]) => {
        return withNextPromise(
          API.Admin.ProcessMaps.createProcessMapRevision(contextSettings, {
            processMapId: data[0].id,
            map: Base64.encode(JSON.stringify(emptyProcessMapDocument)),
            comment: 'Initial version'
          })
        );
      });
    }
  );
};

interface ProcessMapsEditModalContentProps {
  onModalClose: () => void;
  isEditing: boolean;
  selectedProcessMapId?: string;
  items: object;
  reloadProcessMaps(newProcessMapId?: string): void;
}

const ProcessMapsEditModalContent = ({
  onModalClose,
  isEditing,
  selectedProcessMapId,
  items,
  reloadProcessMaps
}: ProcessMapsEditModalContentProps) => {
  const processMap = _.get(items, selectedProcessMapId);
  const [name, setName] = useState(_.get(processMap, 'name', ''));
  const [description, setDescription] = useState(
    _.get(processMap, 'description', '')
  );
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const successToastAndClose = useCallback(
    (message: string) => {
      toastStore.addSuccessToast(message);
      setDeleteModalIsOpen(false);
      onModalClose();
    },
    [onModalClose]
  );

  const [updateIsLoading, onUpdate] = usePromiseCall({
    promise: updateProcessMap,
    onSuccess: () => {
      successToastAndClose(T.admin.processmaps.updated);
      reloadProcessMaps?.(null);
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.processmaps.error.update);
    }
  });

  const [createIsLoading, onCreate] = usePromiseCall({
    promise: createProcessMap,
    onSuccess: (result: ProcessMapRevisionResponseModel[]) => {
      successToastAndClose(T.admin.processmaps.created);
      reloadProcessMaps?.(result[0].processMapId);
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.processmaps.error.create);
    }
  });

  const [deleteIsLoading, onDelete] = usePromiseCall({
    promise: API.Admin.ProcessMaps.deleteProcessMaps,
    onSuccess: () => {
      successToastAndClose(T.admin.processmaps.removed);
      reloadProcessMaps?.(null);
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.processmaps.error.delete);
    }
  });

  const isLoading = updateIsLoading || createIsLoading || deleteIsLoading;

  const onSaveHandler = useCallback(() => {
    if (name.length <= 0) {
      toastStore.addErrorToast(T.admin.processmaps.error.missingname);
      return;
    }

    if (isEditing) {
      onUpdate({ id: selectedProcessMapId, name, description });
    } else {
      onCreate({ name, description });
    }
  }, [isEditing, onUpdate, selectedProcessMapId, name, description, onCreate]);

  const onDeleteHandler = useCallback(() => {
    onDelete([selectedProcessMapId]);
  }, [onDelete, selectedProcessMapId]);

  const onChangeName: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => setName(event.target.value),
    []
  );

  const onChangeDescription: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => setDescription(event.target.value),
    []
  );

  const onDeleteButtonClick = useCallback(() => {
    setDeleteModalIsOpen(true);
  }, []);

  return (
    <>
      <ModalBody loading={isLoading}>
        <KeyValueLine>
          <KeyValueInput
            disabled={isLoading}
            keyText={T.admin.processmaps.name}
            value={name}
            onChange={onChangeName}
          />
        </KeyValueLine>

        <KeyValueLine>
          <KeyValueInput
            disabled={isLoading}
            keyText={T.admin.processmaps.description}
            value={description}
            onChange={onChangeDescription}
          />
        </KeyValueLine>
      </ModalBody>

      <ModalFooter>
        {isEditing && (
          <DeleteButton disabled={isLoading} onClick={onDeleteButtonClick}>
            {T.admin.processmaps.deletemap}
          </DeleteButton>
        )}

        <ModalSpace />

        <SaveButton disabled={isLoading} onClick={onSaveHandler}>
          {isEditing ? T.common.save : T.admin.processmaps.add}
        </SaveButton>

        <LocalizedButtons.Cancel disabled={isLoading} onClick={onModalClose} />
      </ModalFooter>

      <ConfirmDeleteDialog
        isOpen={deleteModalIsOpen}
        isLoading={deleteIsLoading}
        onDelete={onDeleteHandler}
        onModalClose={() => setDeleteModalIsOpen(false)}
        itemName={name}
      />
    </>
  );
};

export default memo(ProcessMapsEditModalContent);
