import React, { useCallback, useEffect, useState } from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import { modelFormIsValid } from 'ecto-common/lib/ModelForm/validateForm';
import { text, updateInputItem } from 'ecto-common/lib/ModelForm/formUtils';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import T from 'ecto-common/lib/lang/Language';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import Icons from 'ecto-common/lib/Icons/Icons';
import _ from 'lodash';
import API from 'ecto-common/lib/API/API';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import {
  AddOrUpdateDashboardRequestModel,
  DashboardResponseModel
} from 'ecto-common/lib/API/APIGen';
import IdentityServiceAPIGenV2 from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';

export const DashboardModels = [
  text<DashboardResponseModel>(
    (input) => input.name,
    T.admin.dashboards.column.name,
    isNullOrWhitespace
  ),
  text<DashboardResponseModel>(
    (input) => input.description,
    T.admin.dashboards.column.description,
    null
  )
];

const saveUserDashboardPromise = (
  contextSettings: ApiContextSettings,
  { dashboardId, name, description }: AddOrUpdateDashboardRequestModel
) => {
  return IdentityServiceAPIGenV2.User.createOrUpdateUserDashboards.promise(
    contextSettings,
    { dashboards: [{ id: dashboardId, name, description }] },
    null
  );
};

interface EditDashboardPropertiesModalProps {
  isOpen?: boolean;
  onCloseModal(): void;
  dashboard?: DashboardResponseModel;
  onDashboardUpdated(
    editedDashboard: DashboardResponseModel,
    isNew: boolean
  ): void;
  isNew?: boolean;
}

const EditDashboardPropertiesModal = ({
  isOpen,
  onCloseModal,
  dashboard,
  onDashboardUpdated,
  isNew
}: EditDashboardPropertiesModalProps) => {
  const isAdmin = useCommonSelector((state) => state.general.isAdmin);

  const [hasChanges, setHasChanges] = useState(false);
  const [editDashboard, setEditDashboard] =
    useState<DashboardResponseModel>(null);

  const [saveDashboardIsLoading, saveDashboard] = usePromiseCall({
    promise: isAdmin
      ? API.Admin.Dashboard.addsOrUpdatesDashboard
      : saveUserDashboardPromise,
    onSuccess: () => {
      setHasChanges(false);
      onCloseModal();
      onDashboardUpdated(editDashboard, isNew);
    },
    onError: () => {
      toastStore.addErrorToast(
        isNew
          ? T.admin.dashboards.save.failure
          : T.admin.dashboards.change.failure
      );
    }
  });

  useEffect(() => {
    setEditDashboard(_.cloneDeep(dashboard));
  }, [isOpen, dashboard]);

  const isValid = modelFormIsValid(DashboardModels, editDashboard);
  const isLoading = false;

  const onUpdateDashboard = useCallback((key: string[], value: unknown) => {
    setEditDashboard((oldDashboard) => {
      setHasChanges(true);
      return updateInputItem(oldDashboard, key, value);
    });
  }, []);

  const onSaveDashboard = useCallback(() => {
    if (isNew) {
      saveDashboard(editDashboard as AddOrUpdateDashboardRequestModel);
    } else {
      onCloseModal();
      onDashboardUpdated(editDashboard, isNew);
    }
  }, [editDashboard, isNew, onCloseModal, onDashboardUpdated, saveDashboard]);

  return (
    <ActionModal
      isOpen={isOpen}
      onModalClose={onCloseModal}
      disableActionButton={!isValid || (!isNew && !hasChanges)}
      isLoading={saveDashboardIsLoading}
      onConfirmClick={onSaveDashboard}
      headerIcon={isNew ? Icons.Add : Icons.Edit}
      title={isNew ? T.admin.dashboards.add : T.admin.dashboards.edit}
    >
      <ModelForm
        input={editDashboard}
        models={DashboardModels}
        onUpdateInput={onUpdateDashboard}
        isLoading={isLoading}
      />
    </ActionModal>
  );
};

export default EditDashboardPropertiesModal;
