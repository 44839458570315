import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';

// Keep this in order for calendar localizations to work
import 'moment/locale/sv';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import { IPublicClientApplication } from '@azure/msal-browser';
import { Store } from '@reduxjs/toolkit';

interface RootProps {
  store?: Store;
  configuration: IPublicClientApplication;
  children?: React.ReactNode;
}

const Root = ({ store, configuration, children }: RootProps) => {
  return (
    <MsalProvider instance={configuration}>
      <Provider store={store}>
        <Router>{children}</Router>
      </Provider>
    </MsalProvider>
  );
};

export default Root;
