import React from 'react';
import classnames from 'classnames';

import HelpTooltipModal from 'ecto-common/lib/HelpTooltip/HelpTooltipModal';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import Icons from 'ecto-common/lib/Icons/Icons';

import styles from 'ecto-common/lib/HelpTooltip/HelpTooltip.module.css';

interface HelpTooltipProps {
  helpPath: string;
  light?: boolean;
  small?: boolean;
}

const HelpTooltip = ({ helpPath, light, small }: HelpTooltipProps) => {
  const [dialogIsOpen, showDialog, hideDialog] = useDialogState(false);

  return (
    <>
      <div
        className={classnames(
          styles.helpButton,
          small && styles.small,
          light && styles.light
        )}
        onClick={showDialog}
      >
        <Icons.Question size={'xs'} />
      </div>

      {helpPath && (
        <HelpTooltipModal
          helpPath={helpPath}
          dialogIsOpen={dialogIsOpen}
          onConfirmClick={hideDialog}
        />
      )}
    </>
  );
};

export default HelpTooltip;
