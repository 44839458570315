import React, { useMemo } from 'react';
import LayoutDirection from 'ecto-common/lib/types/LayoutDirection';
import dimensions from 'ecto-common/lib/styles/dimensions';
import _ from 'lodash';

const DEFAULT_SPACING = dimensions.standardMargin;

/**
 * @readonly
 * @enum {string}
 */
export enum JustifyContent {
  FLEX_START = 'flex-start',
  FLEX_END = 'flex-end',
  START = 'start',
  END = 'end',
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  SPACE_BETWEEN = 'space-between',
  SPACE_AROUND = 'space-around',
  SPACE_EVENLY = 'space-evenly'
}

/**
 * @readonly
 * @enum {string}
 */
export enum FlexWrap {
  NOWRAP = 'nowrap',
  WRAP = 'wrap',
  WRAP_REVERSE = 'wrap-reverse'
}

/**
 * @readonly
 * @enum {string}
 */
export enum AlignItems {
  NORMAL = 'normal',
  STRETCH = 'stretch',
  FLEX_START = 'flex-start',
  FLEX_END = 'flex-end',
  CENTER = 'center',
  BASELINE = 'baseline',
  FIRST_BASELINE = 'first baseline',
  LAST_BASELINE = 'last baseline',
  START = 'start',
  END = 'end',
  SELF_START = 'self-start',
  SELF_END = 'self-end',

  SAFE_FLEX_START = 'safe flex-start',
  SAFE_FLEX_END = 'safe flex-end',
  SAFE_CENTER = 'safe center',
  SAFE_START = 'safe start',
  SAFE_END = 'safe end',
  SAFE_SELF_START = 'safe self-start',
  SAFE_SELF_END = 'safe self-end',

  UNSAFE_FLEX_START = 'unsafe flex-start',
  UNSAFE_FLEX_END = 'unsafe flex-end',
  UNSAFE_CENTER = 'unsafe center',
  UNSAFE_START = 'unsafe start',
  UNSAFE_END = 'unsafe end',
  UNSAFE_SELF_START = 'unsafe self-start',
  UNSAFE_SELF_END = 'unsafe self-end'
}

interface FlexProps {
  children?: React.ReactNode;
  direction?: LayoutDirection;
  className?: string;
  alignItems?: AlignItems;
  wrap?: FlexWrap;
  gap?: number;
  justifyContent?: JustifyContent;
}

const Flex = ({
  children,
  direction,
  className,
  alignItems,
  wrap,
  gap = DEFAULT_SPACING,
  justifyContent = JustifyContent.FLEX_START
}: FlexProps) => {
  return (
    <div
      style={{
        display: 'flex',
        gap,
        flexDirection:
          direction === LayoutDirection.VERTICAL ? 'column' : 'row',
        ...(wrap ? { flexWrap: wrap } : {}),
        alignItems,
        justifyContent
      }}
      className={className}
    >
      {children}
    </div>
  );
};

export default React.memo(Flex);

interface FlexItemProps {
  children?: React.ReactNode;
  style?: object;
  grow?: number;
  shrink?: number;
  align?: AlignItems;
  basis?: string;
}

/**
 * Placeholder for flex items, for future use of individual spacing etc
 * @param {JSX.Element} children
 * @param {object} style
 * @param {number} grow
 * @param {number} shrink
 * @param {JustifyContent} align
 * @param {string} basis
 * @returns {*}
 * @constructor
 */
export const FlexItem = ({
  children,
  style,
  grow,
  shrink,
  align,
  basis
}: FlexItemProps) => {
  const newStyle = useMemo(() => {
    let _newStyle = {
      minWidth: 0, // This is so the items do not grow outside current flex
      ...style
    };
    if (grow != null) {
      _.set(_newStyle, 'flexGrow', grow);
    }
    if (shrink != null) {
      _.set(_newStyle, 'flexShrink', shrink);
    }
    if (align != null) {
      _.set(_newStyle, 'alignSelf', align);
    }
    if (basis != null) {
      _.set(_newStyle, 'flexBasis', basis);
    }
    return _newStyle;
  }, [style, grow, shrink, align, basis]);

  return <div style={newStyle}>{children}</div>;
};
