import { useEffect, useMemo, useState } from 'react';
import DOMPurify from 'dompurify';
import _ from 'lodash';

import API from 'ecto-common/lib/API/API';
import T from 'ecto-common/lib/lang/Language';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import useLatestSignalValues from 'ecto-common/lib/hooks/useLatestSignalValues';
import {
  SignalInfoResponseModel,
  SignalTypeResponseModel
} from 'ecto-common/lib/API/APIGen';
import { FullSignalProviderResponseModel } from '../API/APIGen';
import { Moment } from 'moment';
import { LastSignalValuesResultWithMetadata } from '../Dashboard/panels/SignalListPanel';
import { Base64 } from 'js-base64';
import {
  ProcessMapDocument,
  emptyProcessMapDocument
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';

export const extractSignalStateNames = (svg: string) => {
  const regex = /symbol-state="(.*?)"/gm;
  let m;

  let signalStateNames = [];
  while ((m = regex.exec(svg)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    if (m.length === 2) {
      signalStateNames.push(m[1]);
    }
  }

  return _.uniq(signalStateNames);
};

const extractSignalTypeNames = (svg: string) => {
  const regex = /signal[iI]d="(.*?)"/gm;
  let m;

  let signalTypeNames = [];
  while ((m = regex.exec(svg)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === regex.lastIndex) {
      regex.lastIndex++;
    }

    if (m.length === 2) {
      signalTypeNames.push(m[1]);
    }
  }

  return _.uniq(signalTypeNames);
};

export const useProcessMapSignals = (
  nodeId: string,
  signalProviders: FullSignalProviderResponseModel[],
  onlyMappedSignals: boolean,
  signalTypesNameMap: Record<string, SignalTypeResponseModel>,
  fromDate: Moment = null,
  useOldProcessMaps = false,
  externalProcessMapImageBase64: string = null
) => {
  const [mappedSignalTypeIds, setMappedSignalTypeIds] = useState<string[]>([]);

  const [image, setImage] = useState<string>(null);
  const [signals, setSignals] = useState<SignalInfoResponseModel[]>([]);
  const [error, setError] = useState<string>(null);

  const [isLoading, getProcessMapSignals] = usePromiseCall({
    promise: API.SignalViews.getNodeSignalViews,
    initiallyLoading: true,
    onSuccess: (res) => {
      const view = res[0];
      if (externalProcessMapImageBase64 && view) {
        setSignals(view.equipmentSignals);
        const decoded = Base64.decode(externalProcessMapImageBase64);
        let document: ProcessMapDocument = null;
        try {
          document = JSON.parse(decoded);
        } catch (e) {
          console.error(e);
          document = _.cloneDeep(emptyProcessMapDocument);
        }

        let newSignalTypeIds: string[] = [];
        for (let object of document.objects) {
          if (object.type === 'signal') {
            newSignalTypeIds.push(object.signalTypeId);
          }
        }

        setMappedSignalTypeIds(_.uniq(newSignalTypeIds));
        setImage(decoded);
      } else if (view) {
        setSignals(view.equipmentSignals);
        if (view.map) {
          const decoded = Base64.decode(view.map);
          let document: ProcessMapDocument = null;
          if (decoded.startsWith('{')) {
            try {
              document = JSON.parse(decoded);
            } catch (e) {
              console.error(e);
              document = _.cloneDeep(emptyProcessMapDocument);
            }
          }

          if (document != null && !useOldProcessMaps) {
            let newSignalTypeIds: string[] = [];
            for (let object of document.objects) {
              if (object.type === 'signal') {
                newSignalTypeIds.push(object.signalTypeId);
              }
            }

            setMappedSignalTypeIds(_.uniq(newSignalTypeIds));
            setImage(decoded);
          } else {
            let rawImage: string = null;
            if (useOldProcessMaps && document && document.oldSvgData) {
              rawImage = Base64.decode(document.oldSvgData);
            } else {
              rawImage = decoded;
            }

            if (rawImage != null) {
              const img = DOMPurify.sanitize(rawImage);
              const signalTypeNames = extractSignalTypeNames(img);
              setMappedSignalTypeIds(
                signalTypeNames.map((name) => signalTypesNameMap[name]?.id)
              );
              setImage(img);
            }
          }
        }
      }
    },
    onError: () => {
      setError(T.equipment.errorfetchingprocessmap);
    }
  });

  const { signalIds, signalProvidersIds, allSignalIds } = useMemo(() => {
    if (onlyMappedSignals) {
      const mappedSignalIds = _(signalProviders)
        .flatMap('signals')
        .filter((signal) => mappedSignalTypeIds.includes(signal.signalTypeId))
        .map('signalId')
        .value();

      return {
        signalIds: mappedSignalIds,
        signalProvidersIds: null,
        allSignalIds: mappedSignalIds
      };
    }

    return {
      signalIds: null,
      signalProvidersIds: _.uniq(_.map(signalProviders, 'signalProviderId')),
      allSignalIds: _.uniq(
        _.flatMap(signalProviders, (provider) =>
          _.map(provider.signals, 'signalId')
        )
      )
    };
  }, [onlyMappedSignals, signalProviders, mappedSignalTypeIds]);

  useEffect(() => {
    setError(null);
    setImage(null);
    setSignals([]);
    setMappedSignalTypeIds([]);
    getProcessMapSignals(_.compact([nodeId]));
  }, [
    nodeId,
    getProcessMapSignals,
    useOldProcessMaps,
    externalProcessMapImageBase64
  ]);

  const _signalData = useLatestSignalValues(
    signalProvidersIds,
    signalIds,
    allSignalIds,
    fromDate
  );

  const signalData: LastSignalValuesResultWithMetadata = useMemo(() => {
    return _.merge({}, _signalData, _.keyBy(signals, 'signalId'));
  }, [signals, _signalData]);

  return {
    isLoading,
    error,
    image,
    signalData,
    mappedSignalTypeIds
  };
};
