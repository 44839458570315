import React, { useMemo, useCallback } from 'react';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import styles from './ColorSelector.module.css';
import ColorPickerDialog from '../ColorPickerDialog/ColorPickerDialog';
import classNames from 'classnames';
import { ColorResult } from 'react-color';

interface ColorSelectorProps {
  color?: string;
  onColorChange(color: ColorResult, isCustomColor: boolean): void;
  className?: string;
}

const ColorSelector = ({
  color,
  onColorChange,
  className
}: ColorSelectorProps) => {
  const [colorPickerVisible, showColorPicker, hideColorPicker] =
    useDialogState(false);

  const _onColorChange = useCallback(
    (newColor: ColorResult, isCustomColor: boolean) => {
      onColorChange?.(newColor, isCustomColor);
    },
    [onColorChange]
  );

  const refElementStyle = useMemo(() => ({ background: color }), [color]);
  return (
    <>
      <div
        onClick={showColorPicker}
        className={classNames(className, styles.colorBar)}
        style={refElementStyle}
      />

      <ColorPickerDialog
        isOpen={colorPickerVisible}
        onClose={hideColorPicker}
        onChange={_onColorChange}
        color={color}
      />
    </>
  );
};

export default React.memo(ColorSelector);
