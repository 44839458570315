import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  children: React.ReactNode;
  isOpen?: boolean;
  closeTimeout?: number;
}

// The only reason for using this instead of ReactDOM.createPortal directly is that it
// allows you to specify a timeout during which the portal content will still be visible
// after isOpen is set to false. Useful for modal transitions.
const Portal = ({ isOpen, closeTimeout, children }: PortalProps) => {
  const [isVisible, setIsVisible] = useState(isOpen);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> = null;
    if (isOpen) {
      setIsVisible(true);
    } else {
      timeout = setTimeout(() => {
        setIsVisible(isOpen);
      }, closeTimeout);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen, closeTimeout]);

  return isVisible && ReactDOM.createPortal(children, document.body);
};

Portal.defaultProps = {
  isOpen: true,
  closeTimeout: 0
};

export default Portal;
