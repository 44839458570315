import { NodeResponseModel } from 'ecto-common/lib/API/APIGen';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';

export const getNodePage = (
  tenantId: string,
  node: SingleGridNode | NodeResponseModel
) => {
  return `/${tenantId}/home/${node.nodeId}/dashboard`;
};

export const getSignalProvidersPage = (
  tenantId: string,
  nodeId: string,
  equipmentTypeId: string
) => {
  const path = `/${tenantId}/home/${nodeId}/signalproviders`;

  if (equipmentTypeId) {
    return `${path}/${equipmentTypeId}`;
  }

  return path;
};

export const getAlarmsPage = (tenantId: string, nodeId: string) =>
  `/${tenantId}/home/${nodeId}/alarms/signalClusters`;
