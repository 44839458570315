const dimensions = require('../dimensions');

let cssdimensions = {};

for (const [key, value] of Object.entries(dimensions)) {
  if (typeof value === 'number') {
    cssdimensions[key] = value + 'px';
  } else {
    cssdimensions[key] = value;
  }
}

module.exports = cssdimensions;
