import React, { useCallback } from 'react';
import styles from './PagingFooter.module.css';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import Link from 'ecto-common/lib/Link/Link';
import { getLocationWithPageParameter } from 'ecto-common/lib/PagingFooter/pagingUtil';
import linkStyles from 'ecto-common/lib/Link/Link.module.css';

interface PagingFooterLinkProps {
  disabled?: boolean;
  page?: number;
  active: boolean;
  pageLinkQueryParameter?: string;
  onClick?(newPage: number): void;
  children?: React.ReactNode;
}

const PagingFooterLink = ({
  page,
  active,
  pageLinkQueryParameter,
  onClick,
  children,
  disabled = false
}: PagingFooterLinkProps) => {
  const location = useLocation();
  const targetLocation = getLocationWithPageParameter(
    location,
    pageLinkQueryParameter,
    page
  );

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick(page);
    }
  }, [page, onClick]);

  const useLink = pageLinkQueryParameter != null && !disabled;

  return (
    <div className={classNames(active && styles.active)} onClick={handleClick}>
      {useLink && (
        <Link to={targetLocation} noTheme>
          {children}
        </Link>
      )}
      {!useLink && <span className={linkStyles.link}>{children} </span>}
    </div>
  );
};

export default PagingFooterLink;
