import React, { useEffect, useCallback, useState } from 'react';
import _ from 'lodash';
import { evaluateModelFormProperty } from 'ecto-common/lib/ModelForm/ModelEditor';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';

type UseFormOnChangeType = {
  event?:
    | React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLTextAreaElement>;
  dataKey: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
};
type UseFormStateResult<FormType> = [
  formState: FormType,
  onChange: (arg: UseFormOnChangeType) => void,
  resetForm: () => void
];

const useFormState = <FormType extends object = object>(
  initialItem: FormType,
  initialFormState: FormType
): UseFormStateResult<FormType> => {
  const [formState, setFormState] = useState<FormType>(
    _.cloneDeep(initialFormState)
  );

  const onChange = useCallback(
    ({ event, dataKey, value }: UseFormOnChangeType) => {
      setFormState((oldFormState) => {
        let newFormState = { ...oldFormState };

        if (event) {
          _.set<FormType>(newFormState, dataKey, event.target.value);
        } else {
          _.set<FormType>(newFormState, dataKey, value);
        }

        return newFormState;
      });
    },
    [setFormState]
  );

  const resetForm = useCallback(() => {
    setFormState(() =>
      initialItem ? _.cloneDeep(initialItem) : _.cloneDeep(initialFormState)
    );
  }, [initialFormState, initialItem]);

  useEffect(() => {
    resetForm();
  }, [initialFormState, initialItem, resetForm]);

  return [formState, onChange, resetForm];
};

export function hasValidFormValues<
  ObjectType extends object,
  EnvironmentType extends object = object
>(
  input: ObjectType,
  models: ModelDefinition<ObjectType, EnvironmentType>[],
  environment = {}
) {
  for (const model of models) {
    if (
      evaluateModelFormProperty(
        model.hasError,
        model.key(input),
        input,
        environment,
        false,
        model
      )
    ) {
      return false;
    }
  }
  return true;
}

export default useFormState;
