import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import _ from 'lodash';
import { ChangeEventValue, fitBounds, Size } from 'google-map-react';

import { EmptySignalType, NodeTypes } from 'ecto-common/lib/utils/constants';
import Map, { GoogleMapApi, MapsLoadedResult } from 'ecto-common/lib/Map/Map';
import DashboardMapMarker from 'ecto-common/lib/Dashboard/panels/LocationMapPanel/DashboardMapMarker';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import TimeRangeContext, {
  TimeRangeContextType
} from 'ecto-common/lib/Dashboard/context/TimeRangeContext';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import Button from 'ecto-common/lib/Button/Button';
import Icons from 'ecto-common/lib/Icons/Icons';
import styles from './DashboardMap.module.css';
import T from 'ecto-common/lib/lang/Language';
import UUID from 'uuidjs';
import Supercluster, { PointFeature } from 'supercluster';
import {
  NodeResponseModel,
  SignalTypeResponseModel
} from 'ecto-common/lib/API/APIGen';
import {
  LastSignalValuesDataSourceResult,
  SignalInputType
} from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { MatchedSignal } from 'ecto-common/lib/Dashboard/datasources/SignalValuesDataSource';

type MapNode = {
  nodeId: string;
  latitude: number;
  longitude: number;
};

export type PointPropertiesType = Partial<SingleGridNode> & {
  cluster: boolean;
  nodeCount: number;
  nodes?: MapNode[];
};

export type PointFeatureType =
  | PointFeature<PointPropertiesType>
  | Supercluster.ClusterFeature<PointPropertiesType>;

const useSuperClusterSimple = (
  points: PointFeatureType[],
  bounds: MapOptions['bounds'],
  zoom: MapOptions['zoom'],
  options: Supercluster.Options<PointPropertiesType, PointPropertiesType>
): {
  clusters: PointFeatureType[];
  supercluster: Supercluster<PointPropertiesType, PointPropertiesType>;
} => {
  const zoomInt = Math.round(zoom);

  const supercluster: Supercluster<PointPropertiesType, PointPropertiesType> =
    useMemo(() => {
      const _supercluster = new Supercluster<
        PointPropertiesType,
        PointPropertiesType
      >(options);
      _supercluster.load(points);
      return _supercluster;
    }, [options, points]);

  const clusters = useMemo(() => {
    if (bounds != null) {
      return supercluster.getClusters(bounds, zoomInt);
    }

    return points;
  }, [points, bounds, supercluster, zoomInt]);

  return { clusters, supercluster };
};

const STOCKHOLM_COORDS = {
  lat: 59.33,
  lng: 18.06
};

/**
 * https://geojson.org/
 */

const mapGeoJSON = (node: SingleGridNode): PointFeatureType => ({
  type: 'Feature',
  properties: { cluster: false, ...node, nodeCount: 1 },
  geometry: {
    type: 'Point',
    coordinates: [node.longitude, node.latitude]
  }
});

type MapCoordinate = { lat: number; lng: number };

type MapOptions = {
  center: MapCoordinate;
  zoom: number;
  bounds?: [nwLng: number, seLat: number, seLng: number, nwLat: number];
};

const getMapOptionsForNodes = (
  nodes: NodeResponseModel[],
  currentNode: NodeResponseModel,
  size: { width?: number; height?: number }
): MapOptions => {
  if (_.isEmpty(nodes)) {
    return {
      center: STOCKHOLM_COORDS,
      zoom: 17,
      bounds: null
    };
  } else if (
    currentNode != null &&
    currentNode.nodeType === NodeTypes.BUILDING
  ) {
    return {
      center: { lat: currentNode.latitude, lng: currentNode.longitude },
      zoom: 17,
      bounds: null
    };
  } else if (nodes.length === 1) {
    return {
      center: { lat: nodes[0].latitude, lng: nodes[0].longitude },
      zoom: 17,
      bounds: null
    };
  } else if (size.width == null || size.height == null) {
    return {
      center: STOCKHOLM_COORDS,
      zoom: 17,
      bounds: null
    };
  }

  const minLatitude = _.minBy(nodes, 'latitude').latitude;
  const maxLatitude = _.maxBy(nodes, 'latitude').latitude;
  const minLongitude = _.minBy(nodes, 'longitude').longitude;
  const maxLongitude = _.maxBy(nodes, 'longitude').longitude;

  const bounds = {
    ne: {
      lat: maxLatitude,
      lng: maxLongitude
    },
    sw: {
      lat: minLatitude,
      lng: minLongitude
    }
  };

  // Cast is OK, we have verified both width and height is not null
  const calculatedBounds = fitBounds(bounds, size as Size);

  return {
    center: calculatedBounds.center,
    bounds: [
      calculatedBounds.newBounds.nw.lng,
      calculatedBounds.newBounds.se.lat,
      calculatedBounds.newBounds.se.lng,
      calculatedBounds.newBounds.nw.lat
    ],
    zoom: calculatedBounds.zoom
  };
};

const getKey = (cluster: PointFeatureType) =>
  `cluster-${cluster.id ?? cluster?.properties?.nodeId}${cluster.geometry.coordinates[0]}${cluster.geometry.coordinates[1]}`;

const labelForSignalRule = (
  isLoading: boolean,
  matchingSignals: MatchedSignal[],
  signalRule: SignalInputType,
  signalTypesMap: Record<string, SignalTypeResponseModel>
) => {
  let matchingSignal = _.find(matchingSignals, [
    'signal.signalTypeId',
    signalRule.signalTypeId
  ])?.signal;

  if (isLoading) {
    return T.common.loading;
  }

  const signalType = signalTypesMap[signalRule.signalTypeId] ?? EmptySignalType;

  return signalRule.displayName ?? matchingSignal?.name ?? signalType.name;
};

const clusterOptions: Supercluster.Options<
  PointPropertiesType,
  PointPropertiesType
> = {
  radius: 75,
  maxZoom: 18,
  map: ({ nodeId, nodeCount, latitude, longitude }) => ({
    nodeCount,
    cluster: true,
    nodeId: UUID.generate(), // Give clusters a fake node id so that they can be focused.
    nodes: [{ nodeId, latitude, longitude }]
  }),
  reduce: (acc, properties) => {
    acc.nodeCount += properties.nodeCount;
    acc.nodes = acc.nodes.concat(properties.nodes);
    return acc;
  }
};

interface DashboardMapProps {
  /**
   * An array of nodes that will be illustrated as cluster groups in a map
   */
  nodeList: NodeResponseModel[];
  /**
   * The available size for the map to render in. Used to calculate bounds.
   */
  size?: {
    width?: number;
    height?: number;
  };
  /**
   * A object with collected signal information from the selected signals in the panel settings.
   */
  signals: LastSignalValuesDataSourceResult;
  /**
   * If set, do not show any map controls
   */
  hideControls?: boolean;

  /**
   * If set, automatically select first signal in dropdown list. Otherwise, the user explicitly
   * has to select a value before it is shown.
   */
  autoSelectFirstSignal?: boolean;
}

export type DashboardOption = GenericSelectOption<string>;

const DashboardMap = ({
  nodeList,
  size,
  signals,
  hideControls,
  autoSelectFirstSignal
}: DashboardMapProps) => {
  const mapRef = useRef<GoogleMapApi>();
  const timeRange = useContext<TimeRangeContextType>(TimeRangeContext);
  const timeRangeOption = timeRange?.timeRangeOption;

  const {
    nodeId: currentNodeId,
    nodeMap,
    signalTypesMap
  } = useContext(DashboardDataContext);
  const currentNode = getNodeFromMap(nodeMap, currentNodeId);

  const currentNodeIsSite = currentNode?.nodeType === NodeTypes.SITE;
  const points = useMemo(() => _.map(nodeList, mapGeoJSON), [nodeList]);

  const mappedMapOptions = useMemo(() => {
    return getMapOptionsForNodes(nodeList, currentNode, size);
  }, [currentNode, nodeList, size]);

  const [mapOptions, setMapOptions] = useState(mappedMapOptions);

  useEffect(() => {
    setMapOptions(mappedMapOptions);
  }, [mappedMapOptions]);

  const onChange = useCallback((params: ChangeEventValue) => {
    setMapOptions({
      center: params.center,
      zoom: params.zoom,
      bounds: [
        params.bounds.nw.lng,
        params.bounds.se.lat,
        params.bounds.se.lng,
        params.bounds.nw.lat
      ]
    });
  }, []);

  const onMapsLoaded = useCallback(({ map }: MapsLoadedResult) => {
    mapRef.current = map;
  }, []);

  const { clusters, supercluster } = useSuperClusterSimple(
    points,
    mapOptions.bounds,
    mapOptions.zoom,
    clusterOptions
  );

  const onClick = useCallback(
    (
      cluster: Supercluster.ClusterFeature<PointPropertiesType>,
      lat: number,
      lng: number,
      onClickCallback: () => void
    ) => {
      const expansionZoom = supercluster.getClusterExpansionZoom(
        cluster.id as number
      );

      const clusterNodes: MapNode[] = cluster.properties.nodes;
      const numUniqueCoordinates = _.uniqWith(
        clusterNodes,
        (node, otherNode) =>
          node.latitude === otherNode.latitude &&
          node.longitude === otherNode.longitude
      ).length;

      if (numUniqueCoordinates === 1) {
        // Attempted to zoom further into cluster, but already at minimum zoom level.
        // Pop up a dialog with a list of nodes within the cluster with the option to navigate
        // to one of them. List of node id:s available in cluster.properties.nodeIds.
        onClickCallback?.();
      } else {
        mapRef.current.setZoom(expansionZoom);
      }

      mapRef.current.panTo({ lat, lng });
    },
    [supercluster]
  );

  const panToLocation = useCallback((latitude: number, longitude: number) => {
    mapRef.current?.panTo({ lat: latitude, lng: longitude });
  }, []);

  const [isDragging, setIsDragging] = useState(false);

  // To prevent marker popup from closing if the user moves around in the map while popup is open.
  const onDrag = useCallback(() => {
    if (!isDragging) {
      setIsDragging(true);
    }
  }, [isDragging]);

  const onDragEnd = useCallback(() => {
    if (isDragging) {
      setIsDragging(false);
    }
  }, [isDragging]);

  const options: DashboardOption[] = useMemo(() => {
    const inputsFilteredByTimeOption = _.filter(
      signals?.signalInfo?.signalInputs,
      (input) => {
        if (input?.timeRange) {
          return input.timeRange === timeRangeOption;
        }

        return input != null;
      }
    );

    const _signals = _.map(inputsFilteredByTimeOption, (signal) => ({
      value: signal.signalTypeId,
      label: labelForSignalRule(
        signals?.isLoading,
        signals?.signalInfo?.matchingSignals,
        signal,
        signalTypesMap
      )
    }));

    return sortByLocaleCompare(_signals, 'label');
  }, [
    signals?.isLoading,
    signals?.signalInfo?.signalInputs,
    signals?.signalInfo?.matchingSignals,
    timeRangeOption,
    signalTypesMap
  ]);

  const [selectedSignalValue, setSelectedSignalValue] = useState<string>(null);

  const onSelectSignal = useCallback((data: DashboardOption) => {
    setSelectedSignalValue(data?.value);
  }, []);

  const selectedSignal = useMemo(() => {
    if (selectedSignalValue == null) {
      if (autoSelectFirstSignal) {
        return _.head(options);
      }

      return null;
    }
    return _.find(options, { value: selectedSignalValue }) ?? null;
  }, [selectedSignalValue, options, autoSelectFirstSignal]);

  const [focusId, setHasFocusId] = useState<string>(null);

  const focusedNodeId = _.find(
    clusters,
    (cluster) =>
      !cluster.properties.cluster && cluster.properties.nodeId === focusId
  );

  useEffect(() => {
    if (focusedNodeId == null) {
      setHasFocusId(null);
    }
  }, [focusedNodeId]);

  useEffect(() => {
    if (!currentNodeIsSite) {
      setHasFocusId(currentNode?.nodeId);
    }
  }, [currentNode?.nodeId, currentNodeIsSite]);

  const signalInfo = signals?.signalInfo;

  const onCenterToTarget = useCallback(() => {
    if (!currentNodeIsSite) {
      setHasFocusId(currentNode?.nodeId);
    }

    setMapOptions(mappedMapOptions);
  }, [currentNode?.nodeId, currentNodeIsSite, mappedMapOptions]);

  const googleMapOptions = useMemo(() => {
    return {
      disableDefaultUI: hideControls
    };
  }, [hideControls]);

  return (
    <div className={styles.container}>
      {signals?.signalInfo?.signalInputs?.length > 0 && (
        <div className={styles.selectContainer}>
          {!hideControls && !_.isEmpty(options) && (
            <Select
              options={options}
              value={selectedSignal}
              onChange={onSelectSignal}
              className={styles.select}
              isLoading={signals?.isLoading}
              isDisabled={signals?.isLoading}
              placeholder={
                signals?.isLoading ? T.common.loading : T.common.selectsignal
              }
              isClearable
            />
          )}
        </div>
      )}

      <Map
        zoom={mapOptions.zoom}
        center={mapOptions.center}
        onChange={onChange}
        onMapsLoaded={onMapsLoaded}
        onDrag={onDrag}
        onDragEnd={onDragEnd}
        options={googleMapOptions}
      >
        {_.map(clusters, (cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates;

          /**
           * "lat" and "lng" needs to be set on this level. Otherwise, "google-map-react" won't
           * render them on the correct position.
           */
          return (
            <DashboardMapMarker
              key={getKey(cluster)}
              lat={latitude}
              lng={longitude}
              cluster={cluster}
              onClick={onClick}
              isDragging={isDragging}
              signalValues={signals?.signalValues}
              focusId={focusId}
              onChangeFocusId={setHasFocusId}
              signalInfo={signalInfo}
              isLoading={signals?.isLoading}
              selectedSignal={selectedSignal}
              currentNodeId={currentNode?.nodeId}
              panToLocation={panToLocation}
            />
          );
        })}
      </Map>

      {!hideControls && (
        <Button
          className={styles.centerToTargetButton}
          isIconButton
          onClick={onCenterToTarget}
        >
          <Icons.Bullseye />
        </Button>
      )}
    </div>
  );
};

export default React.memo(DashboardMap);
