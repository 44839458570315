import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { ButtonProps } from 'ecto-common/lib/Button/Button';
import classNames from 'classnames';
import dropdownStyles from './DropdownButton.module.css';
import { usePopper } from 'react-popper';
import GreyButton from 'ecto-common/lib/Button/GreyButton';
import Icons from 'ecto-common/lib/Icons/Icons';
import dimensions from 'ecto-common/lib/styles/dimensions';
import DropdownMenu from 'ecto-common/lib/DropdownButton/DropdownMenu';
import { DropdownMenuRef } from './DropdownMenu';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';

// DOM nodes can set this data attribute to prevent dropdown menus from closing when clicking on it.
// Useful internally for rows that have buttons etc
const DISABLE_CLOSE_ATTRIBUTE = 'data-disableclosedropdownmenuonclick';

const targetHasDisableCloseMenuAttribute = (target: EventTarget) => {
  if (!(target instanceof HTMLElement)) {
    return false;
  }

  let curTarget = target;

  while (curTarget && !curTarget.hasAttribute(DISABLE_CLOSE_ATTRIBUTE)) {
    curTarget = curTarget.parentElement;
  }

  return curTarget?.getAttribute(DISABLE_CLOSE_ATTRIBUTE) !== undefined;
};

export enum DropdownButtonMenuPosition {
  BOTTOM_LEFT = 'bottom-start',
  BOTTOM_CENTER = 'bottom',
  BOTTOM_RIGHT = 'bottom-end'
}

export type DropdownButtonOptionType = {
  icon?: React.ReactNode;
  rightSideIcon?: React.ReactNode;
  label: React.ReactNode;
  subtitle?: React.ReactNode;
  action?: MouseEventHandler<HTMLElement>;
  disableCloseOnClick?: boolean;
  isEnabled?: boolean;
  nestedOptions?: DropdownButtonOptionType[];
  nestedFooter?: React.ReactNode;
  nestedHeader?: React.ReactNode;
};

export type DropdownButtonProps = ButtonProps & {
  /**
   * The options to show in the menu.
   */
  options?: DropdownButtonOptionType[];

  /**
   * Used to override the appearance of the options menu. Should be a valid CSS class name.
   */
  optionsMenuClassName?: string;
  /**
   * If set, show an arrow icon indicating dropdown state. Will appear more like our standard select control (applies some styles to this effect).
   */
  withArrow?: boolean;
  /**
   * If set shows a footer at the end of the options. When clicking inside this footer the menu will not close automatically.
   */
  footer?: React.ReactNode;
  /**
   * If set shows a header at the end of the options. When clicking inside this header the menu will not close automatically.
   */
  header?: React.ReactNode;
  /**
   * Set this to add a close button at the end of the dropdown menu.
   */
  closeButtonText?: string;
  /**
   * Set this if you want to change the menu position. Default position is bottom left of the button.
   */
  menuPosition?:
    | DropdownButtonMenuPosition.BOTTOM_LEFT
    | DropdownButtonMenuPosition.BOTTOM_CENTER
    | DropdownButtonMenuPosition.BOTTOM_RIGHT;

  children?: React.ReactNode;

  /**
   * Set this to a positive number to force the menu to close. Useful for when you want to have automatic close behavior when clicking outside
   * the menu but also want to force close it in certain situations.
   */
  forceClose?: number;

  tooltipText?: string;
};

/**
 * A button that when pressed shows a drop down menu. Note that you do not need to specify onClick etc unless you want to the menu will show automatically.
 */
const DropdownButton = ({
  options,
  optionsMenuClassName = null,
  withArrow = false,
  footer = null,
  header = null,
  menuPosition = DropdownButtonMenuPosition.BOTTOM_LEFT,
  closeButtonText = null,
  forceClose = -1,
  tooltipText = null,
  ...buttonProps
}: DropdownButtonProps) => {
  const [referenceElement, setReferenceElement] =
    React.useState<HTMLElement>(null);
  const [childRef, setChildRef] = useState<DropdownMenuRef>(null);

  const { styles, attributes } = usePopper(
    referenceElement,
    childRef?.outerContainer,
    {
      placement: menuPosition,
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            mainAxis: false
          }
        },
        {
          name: 'offset',
          options: {
            offset: [0, dimensions.smallMargin]
          }
        }
      ]
    }
  );

  const [isShowing, setIsShowing] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (forceClose >= 0) {
      setIsShowing(false);
    }
  }, [forceClose]);

  useEffect(() => {
    if (!isShowing && containerRef.current) {
      containerRef.current.childNodes.forEach((node) =>
        (node as HTMLElement).blur?.()
      );
    }
  }, [isShowing]);

  useEffect(() => {
    if (
      (options?.length === 0 || options == null) &&
      footer == null &&
      header == null
    ) {
      setIsShowing(false);
    }
  }, [footer, header, options]);

  // If clicking anywhere inside the div (button + menu), toggle
  // visibility of menu. If clicking outside the div, always
  // close it.
  useEffect(() => {
    const listener = (e: MouseEvent) => {
      // isConnected check is fix for react-datetime calendar which removes elements from DOM before
      // click has been processed. Since it's no longer part of the DOM our disableclosedropdownmenuonclick
      // fix in the wrapper won't work. Check for innerRef is there because sometimes clicks occur on the
      // containing menu.
      const disableCloseOnClick =
        targetHasDisableCloseMenuAttribute(e.target) ||
        !(e.target as HTMLElement).isConnected ||
        e.target === childRef?.innerContainer;

      if (!disableCloseOnClick && !buttonProps.disabled) {
        if (
          e.target instanceof HTMLElement &&
          containerRef.current.contains(e.target)
        ) {
          setIsShowing((wasShowing) => !wasShowing);

          // For some reason we need to trigger this in order for the menu to snap in place. Something
          // weird with popper coordinate generation.
          window.dispatchEvent(new Event('resize'));
        } else {
          setIsShowing(false);
        }
      }
    };

    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [childRef?.innerContainer, buttonProps.disabled]);

  const {
    children: buttonChildren,
    className: buttonClassName,
    ...otherButtonProps
  } = buttonProps;
  let content: React.ReactNode = (
    <GreyButton
      {...otherButtonProps}
      ref={setReferenceElement}
      className={classNames(
        buttonClassName,
        withArrow && dropdownStyles.button
      )}
    >
      {buttonChildren}
      {withArrow && (
        <Icons.NavigationArrowDown
          className={classNames(
            dropdownStyles.rightSideIcon,
            isShowing && dropdownStyles.open
          )}
        />
      )}
    </GreyButton>
  );

  if (tooltipText != null) {
    content = <Tooltip text={tooltipText}>{content}</Tooltip>;
  }

  return (
    <div ref={containerRef}>
      {content}
      <DropdownMenu
        ref={setChildRef}
        header={header}
        footer={footer}
        isShowing={isShowing}
        options={options}
        outerStyle={styles.popper}
        outerAttributes={attributes.popper}
        outerContainerClassName={optionsMenuClassName}
        closeButtonText={closeButtonText}
      />
    </div>
  );
};
export default React.memo(DropdownButton);
