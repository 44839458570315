export default function cssDurationToMilliseconds(duration: string) {
  const num = parseFloat(duration);
  let unitMatches = duration.match(/m?s/);
  let unit: string = null;

  if (unitMatches) {
    unit = unitMatches[0];
  }

  switch (unit) {
    // Seconds
    case 's':
      return num * 1000;
    // Milliseconds
    case 'ms':
      return num;
    default:
      throw new Error(`Unknown unit '${unit}'`);
  }
}
