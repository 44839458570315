import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef
} from 'react';
import styles from './ProcessMapView.module.css';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';
import _ from 'lodash';
import classNames from 'classnames';
import { numDecimalsForUnit } from 'ecto-common/lib/Charts/UnitUtil';
import { colors } from 'ecto-common/lib/styles/variables';
import {
  FullSignalProviderResponseModel,
  SignalProviderSignalResponseModel,
  SignalResponseModel,
  SignalTypeResponseModel,
  UnitResponseModel
} from 'ecto-common/lib/API/APIGen';
import { SignalProviderSignalWithProviderResponseModel } from 'ecto-common/lib/types/EctoCommonTypes';
import { LastSignalValuesResultWithMetadata } from '../Dashboard/panels/SignalListPanel';

export type ProcessMapViewSignal =
  SignalProviderSignalWithProviderResponseModel & {
    signalType: SignalTypeResponseModel;
    unit: UnitResponseModel;
    rawSignal: SignalResponseModel;
  };

const forEachLabel = (
  root: Element,
  attributeName: string,
  allSignals: Record<string, ProcessMapViewSignal>,
  processLabel: (
    textElement: Element,
    signal: ProcessMapViewSignal,
    rootElement: Element,
    signalTypeName: string
  ) => void
) => {
  const labels = root.querySelectorAll('[' + attributeName + ']');
  for (const rootElement of labels) {
    const signalTypeName = rootElement.getAttribute(attributeName);
    if (rootElement.tagName === 'text') {
      // Root element is already text
      processLabel(
        rootElement,
        allSignals[signalTypeName],
        // When text element are part of the root element they should not send their parent as root
        // because it will be styled and take all events
        rootElement.parentElement.tagName === 'svg'
          ? rootElement
          : rootElement.parentElement,
        signalTypeName
      );
    } else {
      const textElements = rootElement.getElementsByTagName('text');
      for (const textElement of textElements) {
        processLabel(
          textElement,
          allSignals[signalTypeName],
          rootElement,
          signalTypeName
        );
      }
    }
  }
};

const forEachElement = (
  root: Element,
  attributeName: string,
  allSignals: Record<string, ProcessMapViewSignal>,
  signalData: LastSignalValuesResultWithMetadata,
  processElement: (
    signal: ProcessMapViewSignal,
    rootElement: Element,
    signalTypeName: string
  ) => void
) => {
  const elements = root.querySelectorAll('[' + attributeName + ']');

  for (let rootElement of elements) {
    const signalTypeName = rootElement.getAttribute(attributeName);
    const signal = allSignals[signalTypeName];

    if (signal && signal.signalId in signalData) {
      processElement(signal, rootElement, signalTypeName);
    }
  }
};

const discreteSignals = [
  {
    name: 'data-alarm-signalid',
    colors: [colors.failureColor, colors.transparent]
  },
  {
    name: 'data-status-signalid',
    colors: [colors.successColor, colors.surface3Color]
  }
];

const updateText = (label: SVGElement, value: string, className: string) => {
  const span = label.getElementsByTagName('tspan');

  if (span.length === 1) {
    span[0].textContent = value;
  } else {
    label.textContent = value;
  }

  label.className.baseVal = className;
};

export interface ProcessMapViewProps {
  image: string;
  signalProviders: FullSignalProviderResponseModel[];
  signalData: LastSignalValuesResultWithMetadata;
  currentSignal: SignalProviderSignalResponseModel;
  setCurrentSignal?: Dispatch<SetStateAction<ProcessMapViewSignal>>;
  setCurrentEditSignal?: (signal: ProcessMapViewSignal) => void;
  toggleSignalSelection?: (signal: ProcessMapViewSignal) => void;
  signalTypesMap: Record<string, SignalTypeResponseModel>;
  signalUnitTypesMap: Record<string, UnitResponseModel>;
}

const ProcessMapView = ({
  image,
  signalProviders,
  signalData,
  currentSignal,
  setCurrentSignal = null,
  setCurrentEditSignal = null,
  toggleSignalSelection = null,
  signalTypesMap,
  signalUnitTypesMap
}: ProcessMapViewProps) => {
  const ref = useRef();

  const onElementHover = useCallback(
    (
      rootElem: SVGElement,
      _signalTypeName: string,
      signal: ProcessMapViewSignal
    ) => {
      rootElem.onmouseover = () => {
        setCurrentSignal(signal);
      };

      rootElem.onmouseleave = () => {
        setCurrentSignal(null);
      };
    },
    [setCurrentSignal]
  );

  const createContent = useCallback(() => {
    return { __html: image };
  }, [image]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const allSignals: ProcessMapViewSignal[] = _.flatMap(
      signalProviders,
      (signalProvider) =>
        _.map(signalProvider.signals, (signal) => ({
          ...signal,
          signalProvider,
          signalType: signalTypesMap[signal.signalTypeId],
          unit: signalUnitTypesMap[signalTypesMap[signal.signalTypeId]?.unitId],
          rawSignal: signal
        }))
    );

    const allSignalsKeyed = _.keyBy(allSignals, 'signalType.name');

    forEachLabel(
      ref.current,
      'data-label-signalid',
      allSignalsKeyed,
      (elem, signal, rootElem, signalTypeName) => {
        if (signal == null) {
          updateText(elem as SVGElement, '', styles.labelText);
          (elem as SVGElement).className.baseVal = styles.labelText;
        } else {
          updateText(
            elem as SVGElement,
            signal.name,
            classNames(styles.labelTextClickable)
          );
          (rootElem as SVGElement).onclick = (e) => {
            e.stopPropagation();
            toggleSignalSelection?.(signal);
          };

          onElementHover(rootElem as SVGElement, signalTypeName, signal);
          // When we couldn't find a valid root element the root is equal to the selected and we do not
          // want to style it.
          if (rootElem !== elem) {
            (rootElem as SVGElement).className.baseVal =
              toggleSignalSelection && styles.clickableRootElement;
          }
        }
      }
    );

    forEachLabel(
      ref.current,
      'data-value-signalid',
      allSignalsKeyed,
      (elem, signal, rootElem, signalTypeName) => {
        if (signal && signal.signalId in signalData) {
          const signalDataInstance = signalData[signal.signalId];
          const value = _.head(signalDataInstance.values)?.value;

          const formattedNumber = formatNumberUnit(
            value,
            signal.unit?.unit ?? '',
            numDecimalsForUnit(signal.unit?.unit ?? '')
          );

          updateText(
            elem as SVGElement,
            formattedNumber,
            classNames(
              styles.valueText,
              signalDataInstance.isWritable && styles.writableSignalText
            )
          );

          (rootElem as SVGElement).onclick = (e) => {
            e.stopPropagation();
            if (signalDataInstance.isWritable) {
              setCurrentEditSignal?.(signal);
            } else {
              toggleSignalSelection?.(signal);
            }
          };

          onElementHover(rootElem as SVGElement, signalTypeName, signal);

          (rootElem as SVGElement).className.baseVal =
            toggleSignalSelection && classNames(styles.clickableRootElement);
        } else {
          updateText(elem as SVGElement, '', null);
        }
      }
    );

    discreteSignals.map((discreteSignal) =>
      forEachElement(
        ref.current,
        discreteSignal.name,
        allSignalsKeyed,
        signalData,
        (signal, rootElem, signalTypeName) => {
          const signalDataInstance = signalData[signal.signalId];
          const value = _.head(signalDataInstance.values)?.value;
          const color =
            value === 1 ? discreteSignal.colors[0] : discreteSignal.colors[1];
          rootElem.setAttribute('fill', color);
          (rootElem as SVGElement).onclick = (e) => {
            e.stopPropagation();
            if (signalDataInstance.isWritable) {
              setCurrentEditSignal?.(signal);
            } else {
              toggleSignalSelection?.(signal);
            }
          };
          onElementHover(rootElem as SVGElement, signalTypeName, signal);
        }
      )
    );
  }, [
    signalProviders,
    image,
    ref,
    signalData,
    setCurrentEditSignal,
    setCurrentSignal,
    currentSignal,
    onElementHover,
    toggleSignalSelection,
    signalTypesMap,
    signalUnitTypesMap
  ]);

  if (!image) {
    return <div />;
  }

  return (
    <>
      <span>
        <div
          ref={ref}
          className={styles.processMap}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={createContent()}
        />
      </span>
    </>
  );
};

export default React.memo(ProcessMapView);
