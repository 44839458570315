import React, { useState, useCallback, useEffect } from 'react';

import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import APIGen from 'ecto-common/lib/API/APIGen';

interface AddLogEntryDialogProps {
  onModalClose: () => void;
  isOpen: boolean;
  location: SingleGridNode;
}

const AddLogEntryDialog = ({
  onModalClose,
  isOpen,
  location
}: AddLogEntryDialogProps) => {
  const [message, setMessage] = useState('');

  const createLogMutation = APIGen.ActivityLog.create.useMutation({
    onSuccess: () => {
      toastStore.addSuccessToast(
        T.format(T.admin.editlocation.addlogdialog.success, location.name)
      );
      onModalClose();
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.editlocation.addlogdialog.error);
    }
  });

  const onConfirmClick = useCallback(() => {
    createLogMutation.mutate({ locationId: location.nodeId, message });
  }, [createLogMutation, location.nodeId, message]);

  const onSetNotes = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setMessage(event.target.value),
    []
  );

  const validInput = message.length > 0;

  const keyText = T.format(
    T.admin.editlocation.addlogdialog.description,
    <strong key="1">{location.name}</strong>
  );

  useEffect(() => {
    setMessage('');
  }, [isOpen]);

  return (
    <ActionModal
      isOpen={isOpen}
      onModalClose={onModalClose}
      title={T.admin.editlocation.addlogentry}
      isLoading={createLogMutation.isLoading}
      actionText={T.common.add}
      disableActionButton={!validInput}
      onConfirmClick={onConfirmClick}
      headerIcon={Icons.Add}
    >
      <KeyValueInput
        disabled={createLogMutation.isLoading}
        onChange={onSetNotes}
        value={message}
        keyText={keyText}
        autoFocus
        placeholder={T.admin.editlocation.addlogdialog.placeholder}
      />
    </ActionModal>
  );
};

export default AddLogEntryDialog;
