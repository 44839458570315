import React from 'react';
import classNames from 'classnames';
import { Link as RRLink } from 'react-router-dom';
import styles from './Link.module.css';
import NavLinkFix from '../NavLinkFix/NavLinkFix';

interface LinkProps {
  to: string | object;
  children: React.ReactNode;
  className?: string;
  noTheme?: boolean;
  activeClassName?: string;
}

const Link = ({
  to = null,
  children,
  noTheme = false,
  className = null,
  activeClassName = null,
  ...otherProps
}: LinkProps) => {
  const classesToApply = classNames(
    styles.link,
    !noTheme && styles.theme,
    className
  );

  if (activeClassName) {
    return (
      <NavLinkFix
        to={to}
        className={classesToApply}
        activeClassName={activeClassName}
        {...otherProps}
      >
        {children}
      </NavLinkFix>
    );
  }

  return (
    <RRLink to={to} className={classesToApply} {...otherProps}>
      {children}
    </RRLink>
  );
};

export default React.memo(Link);
