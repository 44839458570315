import { getApiEnvironment } from 'ecto-common/lib/utils/apiEnvironment';
import { JSONPut, JSONPost, JSONDeleteBody } from 'ecto-common/lib/API/API';
import { ApiContextSettings } from '../API/APIUtils';
import {
  AccountResponse,
  AccountSensorResponse,
  AddOrUpdateAccountRequest,
  AddOrUpdateProviderRequest,
  PagedResultResponseOfAccountResponse,
  ProviderResponse
} from 'ecto-common/lib/API/NetmoreAPIGen';

const fetchOptions = {
  domain: () => getApiEnvironment().urls.netmoreUrl,
  scopes: () => [getApiEnvironment().scopes.smartcomfort]
};

const NetmoreAPI = {
  addsOrUpdatesAccounts: (
    contextSettings: ApiContextSettings,
    accounts: AddOrUpdateAccountRequest[]
  ) => JSONPut<void>(contextSettings, '/accounts', accounts, {}, fetchOptions),
  getAccountsByIds: (
    contextSettings: ApiContextSettings,
    accountIds: string[]
  ) =>
    JSONPost<AccountResponse[]>(
      contextSettings,
      '/accounts',
      { accountIds },
      {},
      fetchOptions
    ),
  getAccountsAll: (
    contextSettings: ApiContextSettings,
    {
      sortColumn,
      sortOrder,
      page,
      pageSize
    }: { sortColumn: string; sortOrder: string; page: number; pageSize: number }
  ) =>
    JSONPost<PagedResultResponseOfAccountResponse>(
      contextSettings,
      '/accounts/all',
      { sortColumn, sortOrder, pageNumber: page, pageSize },
      {},
      fetchOptions
    ),
  addsOrUpdatesProviders: (
    contextSettings: ApiContextSettings,
    providers: AddOrUpdateProviderRequest[]
  ) =>
    JSONPut<void>(contextSettings, '/providers', providers, {}, fetchOptions),
  getProvidersByNodeIds: (
    contextSettings: ApiContextSettings,
    nodeIds: string[]
  ) =>
    JSONPost<ProviderResponse[]>(
      contextSettings,
      '/providers/byNodeId',
      { nodeIds },
      {},
      fetchOptions
    ),
  removeProviders: (
    contextSettings: ApiContextSettings,
    providerIds: string[]
  ) =>
    JSONDeleteBody<void>(
      contextSettings,
      '/providers',
      { providerIds },
      {},
      fetchOptions
    ),
  getSensors: (
    contextSettings: ApiContextSettings,
    accountId: string,
    devEUISearch: string
  ) =>
    JSONPost<AccountSensorResponse[]>(
      contextSettings,
      `/sensors/${accountId}`,
      { devEUIS: [devEUISearch] },
      {},
      fetchOptions
    )
};

export default NetmoreAPI;
