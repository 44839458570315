import React from 'react';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import { DashboardResponseModel } from 'ecto-common/lib/API/APIGen';

const NameColumn = {
  label: T.admin.dashboards.column.name,
  dataKey: 'name',
  flexGrow: 2
};

const DescriptionColumn = {
  label: T.admin.dashboards.column.description,
  dataKey: 'description',
  flexGrow: 1
};

export const DashboardFormColumns = [NameColumn, DescriptionColumn];

export const DashboardFormColumnsWithLinks: DataTableColumnProps<DashboardResponseModel>[] =
  [
    {
      ...NameColumn,
      linkColumn: true,
      dataFormatter: (value) => (
        <>
          {' '}
          <Icons.Dashboard /> {value}{' '}
        </>
      )
    },
    DescriptionColumn
  ];
