import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import AdjustableChart, {
  AdjustableChartCurve,
  AdjustableChartSignal,
  HighchartsOptionsWithAdjustableSettings
} from 'ecto-common/lib/AdjustableChart/AdjustableChart';
import useSignalSetter, {
  SignalResponseModelWithValue
} from 'ecto-common/lib/hooks/useSignalSetter';
import _ from 'lodash';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import styles from './SignalCurveEditor.module.css';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import Flex, { FlexItem } from 'ecto-common/lib/Layout/Flex';
import { showUpdateSignalError } from 'ecto-common/lib/SignalsTable/signalsTableUtils';
import { isConstantSignal } from 'ecto-common/lib/utils/constants';
import {
  GraphicalRepresentation,
  SignalDirection
} from 'ecto-common/lib/API/APIGen';
import MessageDialog from 'ecto-common/lib/MessageDialog/MessageDialog';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';

/**
 * @callback ShouldAcceptValueCallback
 * @param {*} waitingForStateValues
 * @param {*} state
 */

type ShouldAcceptFunction<ValueType, WaitingValueType> = (
  waitingValue: WaitingValueType,
  value: ValueType
) => boolean;
/**
 * A hook that will have a waiting state and only accept a new value if shouldAcceptValue returns true
 * @param {*} initialValue
 * @param {*} value
 * @param shouldAcceptValue Whether the new values are accepted
 * @returns {[boolean, any, function]}
 */
const useDelayedValue = <ValueType, WaitingValueType>(
  initialValue: ValueType,
  value: ValueType,
  shouldAcceptValue: ShouldAcceptFunction<ValueType, WaitingValueType>
): [
  waitingForUpdate: boolean,
  value: ValueType,
  setWaitingValues: Dispatch<SetStateAction<WaitingValueType>>
] => {
  const [newValue, setNewValue] = useState<ValueType>(initialValue);
  const [waitingValues, setWaitingValues] = useState<WaitingValueType>(null);
  useEffect(() => {
    if (waitingValues == null || shouldAcceptValue(waitingValues, value)) {
      setWaitingValues(null);
      setNewValue(value);
    }
  }, [shouldAcceptValue, value, waitingValues]);

  return [waitingValues != null, newValue, setWaitingValues];
};

const allSignalsInCurves = (
  curves: AdjustableChartCurve[]
): AdjustableChartSignal[] =>
  _.flatMap(curves, (curve) => {
    return [
      ..._.map(curve.series, 'signalX'),
      ..._.map(curve.series, 'signalY')
    ];
  });

interface SignalCurveEditorProps {
  curves?: AdjustableChartCurve[];
  onCurvesChanged?(curves: AdjustableChartCurve[]): void;
  settings?: HighchartsOptionsWithAdjustableSettings;
  size?: {
    width?: number;
    height?: number;
  };
  isLoading?: boolean;
  hasError?: boolean;
}

const SignalCurveEditor = ({
  curves,
  onCurvesChanged,
  settings,
  size,
  isLoading,
  hasError
}: SignalCurveEditorProps) => {
  const [changedSignalValues, setChangedSignalValues] = useState<
    Record<string, AdjustableChartSignal>
  >({});
  const [curveCopy, setCurveCopy] = useState<AdjustableChartCurve[]>([]);

  const [isSaving, _setSignalValues] = useSignalSetter({
    onSignalUpdated: useCallback(() => {
      toastStore.addSuccessToast(T.equipment.setvaluesuccess);
      setChangedSignalValues({});
    }, []),
    onSignalUpdateFailed: useCallback((err) => {
      showUpdateSignalError(err);
    }, [])
  });

  // Used when the curve changes
  // and to prevent the effect below to trigger when we sent onCurvesChanged callback
  const curveRef = useRef<AdjustableChartCurve[]>(null);

  useEffect(() => {
    // Update curve when value changes
    if (
      _.isEmpty(changedSignalValues) ||
      curveRef.current == null ||
      onCurvesChanged == null
    ) {
      return;
    }

    const curResult = curveRef.current;
    // Create a new modified curve copy
    const newCurves: AdjustableChartCurve[] = _.map(curResult, (curve) => {
      const newSeries = _.map(curve.series, (serie) => {
        const newSerie = _.cloneDeep(serie);
        if (serie.signalX?.signalId in changedSignalValues) {
          newSerie.signalX.value =
            changedSignalValues[serie.signalX.signalId].value;
        }
        if (serie.signalY?.signalId in changedSignalValues) {
          newSerie.signalY.value =
            changedSignalValues[serie.signalY.signalId].value;
        }
        return newSerie;
      });
      return { ...curve, series: newSeries };
    });
    setCurveCopy(newCurves);
    onCurvesChanged(newCurves);
  }, [changedSignalValues, onCurvesChanged]);

  // Holds all current signals and their value to help compare with new changes
  const signals = useRef([]);

  const shouldAcceptValue = useCallback(
    (
      waitingForValues: AdjustableChartSignal[],
      newCurves: AdjustableChartCurve[]
    ) => {
      const newSignalState = allSignalsInCurves(newCurves);

      const possibleWaitingSignals = _.filter(newSignalState, (signal) => {
        return _.some(waitingForValues, { signalId: signal.signalId });
      });

      const sortSignals = (_signals: AdjustableChartSignal[]) =>
        _.sortBy(_signals, 'signalId');
      const diff = _.differenceBy(
        sortSignals(possibleWaitingSignals),
        sortSignals(waitingForValues),
        'time'
      );

      // If all signals that we are waiting for are updated by time then we accept the new value
      return diff.length === waitingForValues.length;
    },
    []
  );

  const [waitingForUpdate, delayedCurve, setWaitingValues] = useDelayedValue(
    [],
    curves,
    shouldAcceptValue
  );

  const setSignalValues = useCallback(
    (signalValues: AdjustableChartSignal[], message?: string) => {
      setWaitingValues([...signalValues]);
      _setSignalValues(
        signalValues.map(
          (value: AdjustableChartSignal): SignalResponseModelWithValue => ({
            dataFormat: null,
            signalDirection: SignalDirection.Input,
            graphicalRepresentation: GraphicalRepresentation.Step,
            ...value,
            value: value.value,
            signalId: value.signalId,
            isWritable: value.isWritable,
            signalProvider: value.signalProvider
          })
        ),
        message
      );
    },
    [_setSignalValues, setWaitingValues]
  );

  useEffect(() => {
    signals.current = allSignalsInCurves(delayedCurve);
    setCurveCopy([...delayedCurve]);
    curveRef.current = delayedCurve;
    onCurvesChanged?.(delayedCurve);
  }, [delayedCurve, onCurvesChanged]);

  const onChartDrop = useCallback((data: AdjustableChartSignal[]) => {
    // Only update signal values that changed
    const modifiedData: AdjustableChartSignal[] = _.reject(
      data,
      isConstantSignal
    );
    const newChangedCurveSignals = _.reject(modifiedData, (signal) => {
      const possibleChangedSignal = _.find(signals.current, {
        signalId: signal.signalId
      });
      return possibleChangedSignal?.value === signal.value;
    });
    // Add the new changed signal values to changed signal values set
    setChangedSignalValues((prevModifiedValues) =>
      _.reduce(
        newChangedCurveSignals,
        (dict, changedSignal) => {
          dict[changedSignal.signalId] = changedSignal;
          return dict;
        },
        { ...prevModifiedValues }
      )
    );
  }, []);

  const [messageDialogOpen, showMessageDialog, hideMessageDialog] =
    useDialogState(false);

  const onConfirmSignalMessage = useCallback(
    (message: string) => {
      hideMessageDialog();
      setSignalValues(_.values(changedSignalValues), message);
    },
    [hideMessageDialog, setSignalValues, changedSignalValues]
  );

  const onCancel = useCallback(() => {
    setChangedSignalValues({});
    setCurveCopy([...curves]);
    curveRef.current = curves;
    onCurvesChanged([...curves]);
  }, [curves, onCurvesChanged]);

  const saveDisabled = _.isEmpty(changedSignalValues) || isSaving;
  const anyCurveIsWritable =
    _.some(curves, 'isWritableX') || _.some(curves, 'isWritableY');

  return (
    <>
      <MessageDialog
        isOpen={messageDialogOpen}
        title={T.editsignalvalue.dialogtitle}
        messageTitle={T.common.reason}
        onModalClose={hideMessageDialog}
        onConfirmMessage={onConfirmSignalMessage}
        isRequired
      />
      <LoadingContainer isLoading={isSaving || waitingForUpdate}>
        <div className={styles.content}>
          <div className={styles.chart}>
            <AdjustableChart
              isLoading={isLoading}
              hasError={hasError}
              series={curveCopy}
              onChartDrop={onChartDrop}
              settings={settings}
              size={size}
            />
          </div>
          {anyCurveIsWritable && (
            <Flex>
              <FlexItem>
                <LocalizedButtons.Save
                  onClick={showMessageDialog}
                  disabled={saveDisabled}
                />
              </FlexItem>
              <FlexItem>
                <LocalizedButtons.Cancel
                  onClick={onCancel}
                  disabled={saveDisabled}
                />
              </FlexItem>
            </Flex>
          )}
        </div>
      </LoadingContainer>
    </>
  );
};

export default React.memo(SignalCurveEditor);
