import React, { useState, useCallback } from 'react';
import SegmentControl from 'ecto-common/lib/SegmentControl/SegmentControl';
import { SegmentControlItem } from 'ecto-common/lib/SegmentControl/SegmentControlItem';
import { useHistory } from 'react-router';

interface SegmentedContentViewProps {
  /**
   * Makes the control buttons align to left instead of covering the full width.
   */
  expanding?: boolean;
  /**
   * If you wish to fully control the component, set this index. In that case you
   * should supply URL:s for all sections so that you can determine the current
   * section.
   */
  index?: number;
  /**
   * The sections to be rendered.
   */
  sections?: {
    disabled?: boolean;
    title?: React.ReactNode;
    icon?: React.ReactNode;
    view?: React.ReactNode;
    url?: string;
    onClick?(): void;
  }[];
  /**
   * Used to override the appearance of the button. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * Whether or not to render a separator between the control and the content view
   */
  renderSeparator?: boolean;
}

/**
 * This is a convenience component that is used when you have a top level segment control that changes the content view beneath it when the section changes. You supply a list of sections with icons, title and the content view to be rendered when the section is active.
 */
const SegmentedContentView = ({
  sections,
  expanding,
  index,
  className,
  renderSeparator = true
}: SegmentedContentViewProps) => {
  const [curSectionIndex, setCurSectionIndex] = useState(0);
  const history = useHistory();

  const activeIndex = index == null ? curSectionIndex : index;
  const curSection = sections[activeIndex];

  const handleSectionClick: React.MouseEventHandler<HTMLDivElement> =
    useCallback(
      (e) => {
        const newIndex = parseInt((e.target as HTMLElement).dataset.index, 10);

        if (sections[newIndex].url != null) {
          history.push(sections[newIndex].url);
        }

        sections[newIndex]?.onClick?.();
        setCurSectionIndex(newIndex);
      },
      [history, sections]
    );

  return (
    <>
      <SegmentControl expanding={expanding} className={className}>
        {sections.map((item, idx) => (
          <SegmentControlItem
            compact
            expanding={expanding}
            data-index={idx}
            key={idx}
            disabled={item.disabled}
            active={activeIndex === idx}
            onClick={handleSectionClick}
          >
            {item.icon}
            {item.title}
          </SegmentControlItem>
        ))}
      </SegmentControl>
      {renderSeparator && <hr />}
      {curSection.view}
    </>
  );
};

export default SegmentedContentView;
