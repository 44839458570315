import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import _ from 'lodash';

import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { standardColumns } from 'ecto-common/lib/utils/dataTableUtils';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';

import EditNotificationDialog from './EditNotificationDialog';
import { NotificationModelsWithNodePicker } from './NotificationModels';
import ModelDisplay from 'ecto-common/lib/ModelForm/ModelDisplay';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import API from 'ecto-common/lib/API/API';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import { AlarmNotificationResponseModel } from 'ecto-common/lib/API/APIGen';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { getPathStringFromModelKeyFunc } from 'ecto-common/lib/ModelForm/formUtils';

const getNotifications = (
  contextSettings: ApiContextSettings,
  nodeId: string
) => {
  if (nodeId) {
    return API.Admin.Notifications.notificationForNodeIds(contextSettings, [
      nodeId
    ]);
  }
  return API.Admin.Notifications.allNotifications(contextSettings);
};

const deleteNotification = (
  contextSettings: ApiContextSettings,
  item: AlarmNotificationResponseModel
) => API.Admin.Notifications.deleteNotifications(contextSettings, [item.id]);

interface NotificationTableProps {
  nodeId?: string;
  searchString?: string;
  onShowAddNotification(show: boolean): void;
  shouldShowAddNotification?: boolean;
}

const NotificationTable = ({
  nodeId,
  searchString,
  onShowAddNotification,
  shouldShowAddNotification
}: NotificationTableProps) => {
  const nodeMap = useAdminSelector((state) => state.general.nodeMap);

  const [confirmDeleteItem, setConfirmDeleteItem] =
    useState<AlarmNotificationResponseModel>(null);
  const [editItem, setEditItem] =
    useState<AlarmNotificationResponseModel>(null);
  const [notifications, setNotifications] = useState<
    AlarmNotificationResponseModel[]
  >([]);
  const [hasError, setError] = useState(false);

  useEffect(() => {
    if (editItem) {
      onShowAddNotification(true);
    }
  }, [editItem, onShowAddNotification]);

  useEffect(() => {
    if (!shouldShowAddNotification) {
      setEditItem(null);
    }
  }, [shouldShowAddNotification]);

  const [isLoadingItems, _getItems] = usePromiseCall({
    promise: getNotifications,
    onSuccess: (result) => setNotifications(result.alarmNotifications),
    onError: () => {
      setNotifications([]);
      setError(true);
    },
    initiallyLoading: true
  });

  const getItems = useCallback(() => {
    setError(false);
    _getItems(nodeId);
  }, [nodeId, _getItems]);

  useEffect(() => {
    getItems();
  }, [getItems]);

  const [isCreatingItem, createItem] = usePromiseCall({
    promise: API.Admin.Notifications.addNotification,
    onSuccess: (unused, item) => {
      toastStore.addSuccessToastForUpdatedItem(item.name, item.id == null);
      getItems();
      onShowAddNotification(false);
    },
    onError: (unused, item) => {
      toastStore.addErrorToastForUpdatedItem(item.name, item.id == null);
    }
  });

  const [isDeletingItem, deleteItem] = usePromiseCall({
    promise: deleteNotification,
    onSuccess: (unused, item) => {
      toastStore.addSuccessToastForDeletedItem(item.name);
      onShowAddNotification(false);
      getItems();
      setConfirmDeleteItem(null);
    },
    onError: (unused, item) => {
      toastStore.addErrorToastForDeletedItem(item.name);
    }
  });

  const modelColumns: DataTableColumnProps<AlarmNotificationResponseModel>[] =
    useMemo(() => {
      const selectedColumns = nodeId ? ['name'] : ['name', 'nodeIds'];

      return _.reduce(
        NotificationModelsWithNodePicker,
        (columns, model) => {
          const keyString = getPathStringFromModelKeyFunc(model.key);

          if (!selectedColumns.includes(keyString)) {
            return columns;
          }

          return [
            ...columns,
            {
              label: model.label,
              dataKey: getPathStringFromModelKeyFunc(model.key),
              minWidth: 100,
              dataFormatter: (unused: unknown, item) => (
                <ModelDisplay model={model} rawValue={_.get(item, keyString)} />
              )
            }
          ];
        },
        [] as DataTableColumnProps<AlarmNotificationResponseModel>[]
      );
    }, [nodeId]);

  const columns = [
    ...modelColumns,
    ...standardColumns<AlarmNotificationResponseModel>({
      onDelete: setConfirmDeleteItem,
      onEdit: setEditItem
    })
  ];

  const data = useMemo(() => {
    const _searchString = _.toLower(searchString);

    return _.isEmpty(_searchString)
      ? notifications
      : notifications.filter((notification) => {
          return (
            _.defaultTo(_.toLower(notification.name), '').search(
              _searchString
            ) !== -1 ||
            _.get(
              getNodeFromMap(nodeMap, _.head(notification.nodeIds)),
              'name',
              ''
            )
              .toLowerCase()
              .search(_searchString) !== -1
          );
        });
  }, [nodeMap, notifications, searchString]);

  return (
    <Fragment>
      <ConfirmDeleteDialog
        isOpen={confirmDeleteItem != null}
        onModalClose={() => setConfirmDeleteItem(null)}
        isLoading={isDeletingItem}
        itemName={confirmDeleteItem?.name}
        onDelete={() => deleteItem(confirmDeleteItem)}
      />
      <EditNotificationDialog
        nodeId={nodeId}
        isOpen={shouldShowAddNotification}
        notification={editItem}
        isLoading={isCreatingItem}
        onModalClose={() => onShowAddNotification(false)}
        onAddInput={createItem}
        onDelete={setConfirmDeleteItem}
      />

      <DataTable<AlarmNotificationResponseModel>
        hasError={hasError}
        isLoading={isLoadingItems}
        data={data}
        columns={columns}
      />
    </Fragment>
  );
};

export default React.memo(NotificationTable);
