import React from 'react';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import DataTable from 'ecto-common/lib/DataTable/DataTable';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import {
  ModelDefinitionInternal,
  ModelTableData
} from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';

export type TableModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object,
  ValueType = unknown
> = ModelDefinitionInternal<ObjectType, EnvironmentType, unknown> & {
  modelType: typeof ModelType.TABLE;
  tableData: (
    value: ValueType,
    input: ObjectType,
    environment: EnvironmentType,
    model: TableModelDefinition<ObjectType, EnvironmentType, ValueType>
  ) => ModelTableData<object>;
};

type ModelEditorTableProps = ModelEditorProps & {
  model: TableModelDefinition<object, object, unknown>;
  tableData: ModelTableData<object>;
};

const renderStaticTable = (
  formattedValue: ModelTableData<object>,
  isLoading: boolean
) => {
  try {
    return (
      <DataTable
        isLoading={isLoading || formattedValue.isLoading}
        data={formattedValue.data}
        columns={formattedValue.columns}
      />
    );
  } catch (e) {
    console.error(e);
    return <div />;
  }
};

const ModelEditorTable = ({
  model,
  isLoading,
  tableData
}: ModelEditorTableProps) => {
  return (
    <KeyValueGeneric keyText={model.label}>
      {renderStaticTable(tableData, isLoading)}
    </KeyValueGeneric>
  );
};

export default React.memo(ModelEditorTable);
