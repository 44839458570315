import React, { useState, useCallback, useEffect } from 'react';

import Map, { MapLocation } from 'ecto-common/lib/Map/Map';
import MapMarker from 'ecto-common/lib/Map/MapMarker';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { ChangeEventValue } from 'google-map-react';

const initialMapZoom = 14;

interface DraggableMarkerMapProps {
  onCoordinateChanged(lat: number, lng: number): void;
  initialLatitude: number;
  initialLongitude: number;
  initialZoom?: number;
  disableMarker?: boolean;
  onMapsLoaded?(): void;
  searchable?: boolean;
  onSelectLocationFromSearch?(location: MapLocation, street: string): void;
}

const DraggableMarkerMap = ({
  onCoordinateChanged,
  initialLatitude,
  initialLongitude,
  onMapsLoaded = null,
  disableMarker = false,
  initialZoom = initialMapZoom,
  searchable,
  onSelectLocationFromSearch
}: DraggableMarkerMapProps) => {
  const language = useCommonSelector((state) => state.general.language);
  const [center, setCenter] = useState({
    lat: initialLatitude,
    lng: initialLongitude
  });
  const [markerCenter, setMarkerCenter] = useState({
    lat: initialLatitude,
    lng: initialLongitude
  });
  const [zoom, setZoom] = useState(initialZoom);
  const [draggable, setDraggable] = useState(true);

  const onChange = useCallback((map: ChangeEventValue) => {
    setZoom(map.zoom);
  }, []);

  useEffect(() => {
    setCenter({ lat: initialLatitude, lng: initialLongitude });
    setMarkerCenter({ lat: initialLatitude, lng: initialLongitude });
  }, [initialLatitude, initialLongitude]);

  const onChildMouseMove = useCallback(
    (
      _childKey: string,
      _childProps: unknown,
      mouse: { lat: number; lng: number }
    ) => {
      setDraggable(false);
      setMarkerCenter({
        lat: mouse.lat,
        lng: mouse.lng
      });
    },
    []
  );

  const onChildMouseUp = useCallback(() => {
    setDraggable(true);
    onCoordinateChanged(markerCenter.lat, markerCenter.lng);
  }, [markerCenter.lat, markerCenter.lng, onCoordinateChanged]);

  return (
    <Map
      zoom={zoom}
      center={center}
      onChange={onChange}
      onChildMouseMove={onChildMouseMove}
      onChildMouseUp={onChildMouseUp}
      draggable={draggable}
      onMapsLoaded={onMapsLoaded}
      searchable={searchable}
      setMarkerCenter={setMarkerCenter}
      onSelectLocationFromSearch={onSelectLocationFromSearch}
      language={language}
    >
      {!disableMarker && (
        <MapMarker lat={markerCenter.lat} lng={markerCenter.lng} />
      )}
    </Map>
  );
};

export default DraggableMarkerMap;
