/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AtomicEvent {
  eventId?: string | null;
  eventName?: string | null;
  /** @format int32 */
  sequence?: number;
  /** @format date-time */
  occuredUtc?: string;
}

export interface ByteReadOnlyMemory {
  /** @format int32 */
  length?: number;
  isEmpty?: boolean;
  span?: ByteReadOnlySpan;
}

export interface ByteReadOnlySpan {
  /** @format int32 */
  length?: number;
  isEmpty?: boolean;
}

export enum ConfigurationFormat {
  String = 'String',
  Integer = 'Integer',
  Float = 'Float'
}

export interface ConfigurationValue {
  stringValue?: string | null;
  /** @format int64 */
  integerValue?: number | null;
  /** @format double */
  floatValue?: number | null;
}

export interface DomainEvent {
  eventId?: string | null;
  eventName?: string | null;
  /** @format int32 */
  sequence?: number;
  /** @format date-time */
  occuredUtc?: string;
}

export interface ErrorMessage {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number;
  traceId?: string | null;
  errors?: Record<string, string[] | null>;
}

export enum GraphicalRepresentation {
  Unknown = 'Unknown',
  Event = 'Event',
  Step = 'Step',
  Linear = 'Linear',
  Spline = 'Spline'
}

export interface IntegrationPointAggregate {
  newEvents?: DomainEvent[] | null;
  events?: DomainEvent[] | null;
  atomicEvent?: AtomicEvent;
  id?: string | null;
  name?: string | null;
  integrationProxyId?: string | null;
  nodeIds?: string[] | null;
  tenantId?: string | null;
  configuration?: Record<string, ConfigurationValue>;
  initialDataLoaded?: boolean;
  deleted?: boolean;
}

export interface IntegrationPointCreateRequest {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  integrationProxyId: string;
  /** @minLength 1 */
  name: string;
  nodeIds?: string[] | null;
  /** @minLength 1 */
  configuration?: Record<string, ConfigurationValue>;
}

export interface IntegrationPointResponse {
  /**
   * Integration Point Identifier
   * @minLength 1
   */
  id: string;
  /** @minLength 1 */
  integrationProxyId: string;
  /** @minLength 1 */
  name: string;
  /**
   * Tenant identifier
   * @minLength 1
   */
  tenantId: string;
  nodeIds?: string[] | null;
  configuration?: Record<string, ConfigurationValue>;
  dataPoints?: Record<string, IntegrationPointResponseDataPoint>;
  /** Some proxies load some intital data at first execution. This can be like historical data. If this is true, initial data has been loaded. */
  initialDataLoaded?: boolean;
}

export interface IntegrationPointResponseDataPoint {
  /** @format uuid */
  signalId?: string;
  name?: string | null;
  description?: string | null;
  /** @format uuid */
  signalTypeId?: string;
  graphicalRepresentation?: GraphicalRepresentation;
}

export interface IntegrationPointResponseListResponse {
  items?: IntegrationPointResponse[] | null;
  continuationToken?: string | null;
  links?: Record<string, string>;
  /** @format int32 */
  total?: number | null;
}

export interface IntegrationPointUpdateRequest {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  configuration?: Record<string, ConfigurationValue>;
}

export interface IntegrationProxyAggregate {
  newEvents?: DomainEvent[] | null;
  events?: DomainEvent[] | null;
  atomicEvent?: AtomicEvent;
  id?: string | null;
  name?: string | null;
  signalProviderType?: string | null;
  dataPoints?: IntegrationProxyCreateEventDataPoint[] | null;
  configuration?: IntegrationProxyCreateEventConfiguration[] | null;
}

export interface IntegrationProxyCreateEventConfiguration {
  code?: string | null;
  /** @minLength 1 */
  name: string;
  format?: ConfigurationFormat;
  required?: boolean;
  /** @minLength 1 */
  regexFormat: string;
}

export interface IntegrationProxyCreateEventDataPoint {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  defaultName: string;
  /** @format uuid */
  signalTypeId?: string;
  required?: boolean;
  /** @minLength 1 */
  defaultDescription: string;
  graphicalRepresentation?: GraphicalRepresentation;
}

export interface IntegrationProxyCreateRequest {
  /** @minLength 1 */
  id: string;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  singalProviderType: string;
  /** @minLength 1 */
  dataPoints?: Record<string, IntegrationProxyCreateRequestDataPoint>;
  /** @minLength 1 */
  configuration?: Record<string, IntegrationProxyCreateRequestConfiguration>;
}

export interface IntegrationProxyCreateRequestConfiguration {
  /** @minLength 1 */
  name: string;
  format?: ConfigurationFormat;
  required?: boolean;
  /** @minLength 1 */
  regexFormat: string;
}

export interface IntegrationProxyCreateRequestDataPoint {
  /** @minLength 1 */
  defaultName: string;
  /** @minLength 1 */
  defaultDescription: string;
  /** @format uuid */
  signalTypeId?: string;
  required?: boolean;
  graphicalRepresentation?: GraphicalRepresentation;
}

export interface IntegrationProxyResponse {
  id?: string | null;
  name?: string | null;
  configuration?: IntegrationProxyResponseConfiguration[] | null;
  dataPoints?: IntegrationProxyResponseDataPoint[] | null;
}

export interface IntegrationProxyResponseConfiguration {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  name: string;
  format?: ConfigurationFormat;
  required?: boolean;
  /** @minLength 1 */
  regexFormat: string;
}

export interface IntegrationProxyResponseDataPoint {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  defaultName: string;
  /** @format uuid */
  signalTypeId?: string;
  required?: boolean;
  /** @minLength 1 */
  defaultDescription: string;
  graphicalRepresentation?: GraphicalRepresentation;
}

export interface IntegrationProxyResponseListResponse {
  items?: IntegrationProxyResponse[] | null;
  continuationToken?: string | null;
  links?: Record<string, string>;
  /** @format int32 */
  total?: number | null;
}

export interface InternalServiceEctocloudEvent {
  eventId?: string | null;
  eventName?: string | null;
  /** @format int32 */
  sequence?: number;
  /** @format date-time */
  occuredUtc?: string;
  id?: string | null;
  tenantId?: string | null;
  generatedBy?: string | null;
  operationId?: string | null;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface VersionResponse {
  netVersion?: string | null;
  application?: string | null;
  commonWebLib?: string | null;
  /** @format date-time */
  applicationStarted?: string | null;
}

export interface AddIntegrationPointParams {
  /** If request should be awaited or not, if awaited (201 Created) or (204 NoContent) will be returned otherwise (202 Accepted). */
  await?: boolean;
}

export interface ListIntegrationPointsParams {
  /** Continuation Token for pagination, leave empty for inital request. */
  continuationToken?: string;
  /**  Odata query filter */
  $filter?: string;
  /** @example 50 */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface PutIntegrationPointParams {
  /** If request should be awaited or not, if awaited (201 Created) or (204 NoContent) will be returned otherwise (202 Accepted). */
  await?: boolean;
  pointId: string;
}

export interface DeleteIntegrationPointParams {
  /** If request should be awaited or not, if awaited (201 Created) or (204 NoContent) will be returned otherwise (202 Accepted). */
  await?: boolean;
  pointId: string;
}

export interface AddNodeToIntegrationPointParams {
  /** If request should be awaited or not, if awaited (201 Created) or (204 NoContent) will be returned otherwise (202 Accepted). */
  await?: boolean;
  pointId: string;
  nodeId: string;
}

export interface DeleteNodeFromIntegrationPointParams {
  /** If request should be awaited or not, if awaited (201 Created) or (204 NoContent) will be returned otherwise (202 Accepted). */
  await?: boolean;
  pointId: string;
  nodeId: string;
}

export interface AddIntegrationProxyParams {
  /** If request should be awaited or not, if awaited (201 Created) or (204 NoContent) will be returned otherwise (202 Accepted). */
  await?: boolean;
}

export interface ListIntegrationProxyiesParams {
  /** Continuation Token for pagination, leave empty for inital request. */
  continuationToken?: string;
  /**  Odata query filter */
  $filter?: string;
  /** @example 50 */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface ListIntegrationPointsForIntegrationProxyParams {
  /** Continuation Token for pagination, leave empty for inital request. */
  continuationToken?: string;
  /**  Odata query filter */
  $filter?: string;
  /** @example 50 */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
  proxyId: string;
}

/**
 * @title Administration Integration API
 * @version admin
 */

import { APIGenType } from './APIGenType';
import {
  ContentType,
  Method,
  apiEndpointEmpty,
  apiEndpointEmptyUsingQueryParams,
  apiEndpointEmptyWithPath,
  apiEndpointEmptyWithPathUsingQueryParams,
  apiEndpointUsingQueryParams,
  apiEndpointWithPathUsingQueryParams
} from './APIUtils';

const apiTitle = APIGenType.IntegrationAdminAPIGen;

export interface PutIntegrationPointPath {
  pointId: string;
}

export interface DeleteIntegrationPointPath {
  pointId: string;
}

export interface GetIntegrationPointPath {
  pointId: string;
}

export interface AddNodeToIntegrationPointPath {
  pointId: string;
  nodeId: string;
}

export interface DeleteNodeFromIntegrationPointPath {
  pointId: string;
  nodeId: string;
}

export interface GetIntegrationPointAggregatePath {
  pointId: string;
}

export interface GetIntegrationPointHistoryPath {
  pointId: string;
}

export interface GetIntegrationProxyAggregatePath {
  proxyId: string;
}

export interface GetIntegrationProxyHistoryPath {
  proxyId: string;
}

export interface GetIntegrationProxyPath {
  proxyId: string;
}

export interface ListIntegrationPointsForIntegrationProxyPath {
  proxyId: string;
}

const IntegrationAdminAPIGen = {
  IntegrationPoints: {
    addIntegrationPoint: apiEndpointUsingQueryParams<
      IntegrationPointCreateRequest,
      AddIntegrationPointParams,
      void,
      ErrorMessage | ProblemDetails
    >(
      Method.POST,
      `/IntegrationPoints`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    listIntegrationPoints: apiEndpointEmptyUsingQueryParams<
      ListIntegrationPointsParams,
      IntegrationPointResponseListResponse,
      ProblemDetails
    >(
      Method.GET,
      `/IntegrationPoints`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    putIntegrationPoint: apiEndpointWithPathUsingQueryParams<
      IntegrationPointUpdateRequest,
      Omit<PutIntegrationPointParams, keyof PutIntegrationPointPath>,
      void,
      ErrorMessage | ProblemDetails,
      PutIntegrationPointPath
    >(
      Method.PUT,
      ({ pointId }: PutIntegrationPointPath) => `/IntegrationPoints/${pointId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    deleteIntegrationPoint: apiEndpointEmptyWithPathUsingQueryParams<
      Omit<DeleteIntegrationPointParams, keyof DeleteIntegrationPointPath>,
      void,
      ProblemDetails,
      DeleteIntegrationPointPath
    >(
      Method.DELETE,
      ({ pointId }: DeleteIntegrationPointPath) =>
        `/IntegrationPoints/${pointId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    getIntegrationPoint: apiEndpointEmptyWithPath<
      IntegrationPointResponse,
      ProblemDetails,
      GetIntegrationPointPath
    >(
      Method.GET,
      ({ pointId }: GetIntegrationPointPath) => `/IntegrationPoints/${pointId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    addNodeToIntegrationPoint: apiEndpointEmptyWithPathUsingQueryParams<
      Omit<
        AddNodeToIntegrationPointParams,
        keyof AddNodeToIntegrationPointPath
      >,
      void,
      ProblemDetails,
      AddNodeToIntegrationPointPath
    >(
      Method.POST,
      ({ pointId, nodeId }: AddNodeToIntegrationPointPath) =>
        `/IntegrationPoints/${pointId}/nodes/${nodeId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    deleteNodeFromIntegrationPoint: apiEndpointEmptyWithPathUsingQueryParams<
      Omit<
        DeleteNodeFromIntegrationPointParams,
        keyof DeleteNodeFromIntegrationPointPath
      >,
      void,
      ProblemDetails,
      DeleteNodeFromIntegrationPointPath
    >(
      Method.DELETE,
      ({ pointId, nodeId }: DeleteNodeFromIntegrationPointPath) =>
        `/IntegrationPoints/${pointId}/nodes/${nodeId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    getIntegrationPointAggregate: apiEndpointEmptyWithPath<
      IntegrationPointAggregate,
      ProblemDetails,
      GetIntegrationPointAggregatePath
    >(
      Method.GET,
      ({ pointId }: GetIntegrationPointAggregatePath) =>
        `/IntegrationPoints/${pointId}/aggregate`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getIntegrationPointHistory: apiEndpointEmptyWithPath<
      InternalServiceEctocloudEvent[],
      ProblemDetails,
      GetIntegrationPointHistoryPath
    >(
      Method.GET,
      ({ pointId }: GetIntegrationPointHistoryPath) =>
        `/IntegrationPoints/${pointId}/history`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  IntegrationProxies: {
    addIntegrationProxy: apiEndpointUsingQueryParams<
      IntegrationProxyCreateRequest,
      AddIntegrationProxyParams,
      void,
      ErrorMessage | ProblemDetails
    >(
      Method.POST,
      `/IntegrationProxies`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    listIntegrationProxyies: apiEndpointEmptyUsingQueryParams<
      ListIntegrationProxyiesParams,
      IntegrationProxyResponseListResponse,
      ProblemDetails
    >(
      Method.GET,
      `/IntegrationProxies`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getIntegrationProxyAggregate: apiEndpointEmptyWithPath<
      IntegrationProxyAggregate,
      ProblemDetails,
      GetIntegrationProxyAggregatePath
    >(
      Method.GET,
      ({ proxyId }: GetIntegrationProxyAggregatePath) =>
        `/IntegrationProxies/${proxyId}/aggregate`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getIntegrationProxyHistory: apiEndpointEmptyWithPath<
      InternalServiceEctocloudEvent[],
      ProblemDetails,
      GetIntegrationProxyHistoryPath
    >(
      Method.GET,
      ({ proxyId }: GetIntegrationProxyHistoryPath) =>
        `/IntegrationProxies/${proxyId}/history`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getIntegrationProxy: apiEndpointEmptyWithPath<
      IntegrationProxyResponse,
      ProblemDetails,
      GetIntegrationProxyPath
    >(
      Method.GET,
      ({ proxyId }: GetIntegrationProxyPath) =>
        `/IntegrationProxies/${proxyId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    listIntegrationPointsForIntegrationProxy:
      apiEndpointEmptyWithPathUsingQueryParams<
        Omit<
          ListIntegrationPointsForIntegrationProxyParams,
          keyof ListIntegrationPointsForIntegrationProxyPath
        >,
        IntegrationPointResponseListResponse,
        ProblemDetails,
        ListIntegrationPointsForIntegrationProxyPath
      >(
        Method.GET,
        ({ proxyId }: ListIntegrationPointsForIntegrationProxyPath) =>
          `/IntegrationProxies/${proxyId}/integrationpoints`,
        apiTitle,
        ContentType.Json,
        ContentType.Json,
        ContentType.Json
      )
  },
  Version: {
    version: apiEndpointEmpty<VersionResponse, any>(
      Method.GET,
      `/Version`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  }
};

export default IntegrationAdminAPIGen;
