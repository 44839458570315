import React from 'react';
import styles from './Toolbar.module.css';

import SearchInput, {
  SearchInputProps
} from 'ecto-common/lib/SearchInput/SearchInput';
import classNames from 'classnames';

type ToolbarSearchProps = SearchInputProps & {
  value?: string;
  /**
   * Used to override the appearance of the outer container. Should be a valid CSS class name.
   */
  wrapperClassName?: string;
  onChange?(searchPhrase: string): void;
};

const ToolbarSearch = ({
  value,
  onChange = null,
  wrapperClassName = null,
  ...props
}: ToolbarSearchProps) => {
  return (
    <SearchInput
      {...props}
      onChange={onChange}
      wrapperClassName={classNames(wrapperClassName, styles.textInput)}
      initialValue={value}
    />
  );
};

ToolbarSearch.defaultProps = {
  value: ''
};

export default React.memo(ToolbarSearch);
