import React, { useMemo } from 'react';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { CommonRootState } from 'ecto-common/lib/reducers/storeCommon';
import { useSelector } from 'react-redux';
import IntegrationAPIGen, {
  IntegrationPointResponse
} from 'ecto-common/lib/API/IntegrationAPIGen';
import _ from 'lodash';
import DataTableLoadMoreFooter from 'ecto-common/lib/DataTable/DataTableLoadMoreFooter';
import { getLocationRoute } from 'js/utils/routeConstants';
import NavLinkFix from 'ecto-common/lib/NavLinkFix/NavLinkFix';
import { useContext } from 'react';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import T from 'ecto-common/lib/lang/Language';
import { standardColumns } from 'ecto-common/lib/utils/dataTableUtils';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';

type IntegrationPointTableProps = {
  integrationProxyId: string;
  onAdd?: (point: IntegrationPointResponse) => void;
  shouldDisableAdd?: (point: IntegrationPointResponse) => boolean;
  onClickRow?: (
    point: IntegrationPointResponse,
    row: number,
    column: number
  ) => void;
  isLoading?: boolean;
  onDeleteRow?: (point: IntegrationPointResponse, row: number) => void;
};

const IntegrationPointTable = ({
  integrationProxyId,
  onAdd = undefined,
  shouldDisableAdd = undefined,
  isLoading = false,
  onClickRow = null,
  onDeleteRow = null
}: IntegrationPointTableProps) => {
  const nodeMap = useSelector(
    (state: CommonRootState) => state.general.nodeMap
  );
  const { tenantId } = useContext(TenantContext);

  const {
    data: integrationPointData,
    isLoading: isLoadingIntegrationPoints,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage
  } = IntegrationAPIGen.IntegrationProxies.listIntegrationPointsForIntegrationProxy.useInfiniteQuery(
    { proxyId: integrationProxyId },
    {
      $orderby: 'name asc'
    },
    {
      enabled: integrationProxyId != null
    }
  );

  const columns: DataTableColumnProps<IntegrationPointResponse>[] = useMemo(
    () => [
      {
        dataKey: 'name',
        label: T.admin.integrations.integrationpointname,
        linkColumn: true
      },
      {
        dataKey: 'nodeIds',
        label: T.admin.integrations.proxy.form.nodeids,
        dataFormatter: (nodeIds: string[]) => {
          return nodeIds.map((nodeId, idx) => {
            const isLast = idx === nodeIds.length - 1;
            const node = getNodeFromMap(nodeMap, nodeId);
            const suffix = isLast ? '' : ', ';

            return (
              node && (
                <NavLinkFix
                  to={getLocationRoute(tenantId, node.nodeId)}
                  key={'nodelink_' + node.nodeId + '_' + idx}
                >
                  {node.name}
                  {suffix}
                </NavLinkFix>
              )
            );
          });
        }
      },
      ...standardColumns({
        onAdd,
        shouldDisableAdd,
        onDelete: onDeleteRow
      })
    ],
    [nodeMap, onAdd, onDeleteRow, shouldDisableAdd, tenantId]
  );

  const allItems = useMemo(() => {
    return _.flatMap(integrationPointData?.pages, (page) => page.items);
  }, [integrationPointData]);

  return (
    <>
      <DataTable<IntegrationPointResponse>
        data={allItems}
        isLoading={
          isLoading ||
          (isLoadingIntegrationPoints && integrationProxyId != null)
        }
        columns={columns}
        onClickRow={onClickRow}
      />

      <DataTableLoadMoreFooter
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    </>
  );
};

export default React.memo(IntegrationPointTable);
