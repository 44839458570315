import moment, { Moment } from 'moment';
import { DEFAULT_TIMEZONE } from '../constants';
import { isWhitespace } from './stringUtils';
import { ObjectValues } from './typescriptUtils';

export const valueOfDate = (date: Moment) => date.startOf('day').valueOf();

export type DateRangeType = {
  dateFrom: number;
  dateTo: number;
};

export type DateRangeFormattedType = {
  dateFrom: string;
  dateTo: string;
};

export const DEFAULT_DATE_RANGE: DateRangeType = {
  dateFrom: valueOfDate(moment.utc(undefined)),
  dateTo: valueOfDate(moment.utc(undefined).add(1, 'days'))
};

export const defaultTimeRange = (): DateRangeType => {
  const now = new Date();
  const localStartOfDay = moment(now).startOf('day');
  const localEndOfDay = moment(now).add(1, 'days').startOf('day');

  return {
    dateFrom: moment.utc(localStartOfDay).valueOf(),
    dateTo: moment.utc(localEndOfDay).valueOf()
  };
};

const timeFormat = 'YYYY-MM-DD HH:mm:ss';

export const lastSevenDays = (): DateRangeFormattedType => ({
  dateFrom: moment().startOf('day').add(-7, 'days').format(timeFormat),
  dateTo: moment().endOf('day').format(timeFormat)
});

export const lastThirtyDays = (): DateRangeFormattedType => ({
  dateFrom: moment().startOf('day').add(-30, 'days').format(timeFormat),
  dateTo: moment().endOf('day').format(timeFormat)
});

export const isSameDay = (a: Moment | Date, b: Moment | Date) =>
  moment(a).isSame(moment(b), 'day');
export const hourDiff = (a: Moment, b: Moment | Date | string) =>
  moment.duration(a.diff(moment(b))).asHours();
export const minuteDiff = (a: Moment, b: Moment | Date) =>
  moment.duration(a.diff(moment(b))).asMinutes();
export const startOfDay = (date: Date | Moment) => moment(date).startOf('day');

export const DATE_FORMAT = 'YYYY-MM-DD';

const isUserClockPreference12Hour = () => {
  const format = new Intl.DateTimeFormat([], {
    hour: 'numeric',
    hour12: undefined
  });

  const options = format.resolvedOptions();

  return options.hour12;
};

export const TimeFormats = {
  NONE: 'none',
  LONG_TIME: 'long_time',
  SECONDS: 'seconds'
} as const;

export type TimeFormat = ObjectValues<typeof TimeFormats>;

const getLocaleSpecificTimeFormatSeconds = () => {
  if (isUserClockPreference12Hour()) {
    return 'h:mm:ss A';
  }
  return 'HH:mm:ss';
};

export const getLocaleSpecificTimeFormatShort = () => {
  if (isUserClockPreference12Hour()) {
    return 'h:mm A';
  }
  return 'HH:mm';
};

export const getDefaultDateTimeFormat = (format: TimeFormat) => {
  if (format === TimeFormats.SECONDS) {
    return DATE_FORMAT + ' ' + getLocaleSpecificTimeFormatSeconds();
  } else if (format === TimeFormats.LONG_TIME) {
    return DATE_FORMAT + ' ' + getLocaleSpecificTimeFormatShort();
  }

  return DATE_FORMAT;
};

export const getDefaultDateTimeFormatWithMilliseconds = () => {
  if (isUserClockPreference12Hour()) {
    return DATE_FORMAT + ' ' + 'h:mm:ss.SSS A';
  }
  return DATE_FORMAT + ' ' + 'HH:mm:ss.SSS';
};

export const getDefaultTimeFormat = (format: TimeFormat) => {
  if (format === TimeFormats.SECONDS) {
    return getLocaleSpecificTimeFormatSeconds();
  } else if (format === TimeFormats.LONG_TIME) {
    return getLocaleSpecificTimeFormatShort();
  }

  return '';
};

export const isDateStringValid = (
  date: string,
  format: string,
  allowEmptyValue: boolean
) => {
  if (date != null && (!allowEmptyValue || !isWhitespace(date))) {
    let pendingInputValue = moment
      .tz(date, format, true, DEFAULT_TIMEZONE)
      .utc();
    return pendingInputValue.isValid();
  }

  return true;
};

/**
 * In the time picker of react-datetime the control stays within the same unit when
 * going past the max value. For instance, if the minute is 59, and you increase the minute,
 * it will reset back to 0 at the current hour. However, we want it to increase to the
 * next hour instead of just going back to the same hour. Same goes if you are hour 23 and
 * increase it, we want to move to the next day. Also, the same thing goes for negative values:
 * If at hour 0 and decrease, go to hour 23 of the past day.
 *
 * @param newValue The suggested new value that react-datetime provides after increasing/decreasing time controls
 * @param prevValue The previous value before the change
 * @returns A value that overflows/underflows into the next/prev day/minute
 */
export const convertReactDatetimeToWrapAroundToNextUnit = (
  newValue?: Moment,
  prevValue?: Moment
) => {
  if (newValue == null || prevValue == null) {
    return newValue;
  }

  // Detect wraparounds by looking at the delta betweeen current and new value, if we jump 23/-23 or 59/-59
  // we assume that react-datetime has wrapped around the values to stay within the same unit.
  if (hourDiff(newValue, prevValue) === -23) {
    return moment(prevValue).add(1, 'hours');
  } else if (hourDiff(newValue, prevValue) === 23) {
    return moment(prevValue).add(-1, 'hours');
  } else if (minuteDiff(newValue, prevValue) === -59) {
    return moment(prevValue).add(1, 'minutes');
  } else if (minuteDiff(newValue, prevValue) === 59) {
    return moment(prevValue).add(-1, 'minutes');
  }

  return newValue;
};
