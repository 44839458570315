import React, { useCallback } from 'react';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import API from 'ecto-common/lib/API/API';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import DeleteModal from 'ecto-common/lib/ConfirmDeleteDialog/DeleteModal';
import {
  DashboardResponseModel,
  DeleteDashboardsRequestModel
} from 'ecto-common/lib/API/APIGen';
import IdentityServiceAPIGenV2 from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';

const removePersonalDashboardPromise = (
  contextSettings: ApiContextSettings,
  request: DeleteDashboardsRequestModel
) => {
  return IdentityServiceAPIGenV2.User.deleteUserDashboard.promise(
    contextSettings,
    { dashboardId: request.dashboardIds[0] },
    null
  );
};

interface DashboardDeleteModalProps {
  deleteItem?: DashboardResponseModel;
  onModalClose(): void;
  onSuccess?(deleteItem: DashboardResponseModel): void;
  handlePersonalDashboards?: boolean;
}

const DashboardDeleteModal = ({
  deleteItem,
  onModalClose,
  onSuccess,
  handlePersonalDashboards
}: DashboardDeleteModalProps) => {
  const [isLoading, removeDashboard] = usePromiseCall({
    promise: handlePersonalDashboards
      ? removePersonalDashboardPromise
      : API.Admin.Dashboard.removeDashboards,
    onSuccess: () => {
      toastStore.addSuccessToast(T.admin.dashboards.remove.success);
      onModalClose();
      onSuccess?.(deleteItem);
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.dashboards.remove.failure);
    }
  });

  const _removeDashboard = useCallback(
    () => removeDashboard({ dashboardIds: [deleteItem.dashboardId] }),
    [removeDashboard, deleteItem]
  );

  return (
    <DeleteModal
      isLoading={isLoading}
      deleteItem={deleteItem}
      onConfirmDelete={_removeDashboard}
      onModalClose={onModalClose}
      itemName={deleteItem?.name}
    />
  );
};

export default React.memo(DashboardDeleteModal);
