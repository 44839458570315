import svgPathParser from 'svg-path-parser';
import { Base64 } from 'js-base64';
import {
  MigrationData,
  ProcessMapState
} from 'ecto-common/lib/ProcessMaps/ProcessMapEditorTypes';
import { Draft } from 'immer';
import DOMPurify from 'dompurify';
import {
  ProcessMapTextObject,
  ProcessMapObjectTypes,
  ProcessMapLineObject,
  lineRectHeight,
  ProcessMapSymbolLineConnection,
  ProcessMapRect,
  ProcessMapRuleTypes,
  ProcessMapRectObject,
  ProcessMapSymbolObject,
  ProcessMapLineModes,
  ProcessMapLineMode
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import { SignalTypeResponseModel } from 'ecto-common/lib/API/APIGen';
import UUID from 'uuidjs';
import md5 from 'md5';
import { ProcessMapEditorActions } from 'ecto-common/lib/ProcessMaps/ProcessMapEditorActions';
import _ from 'lodash';
import colors from 'ecto-common/lib/styles/variables/colors';
import { ProcessMapObjectFactory } from 'ecto-common/lib/ProcessMaps/ProcessMapObjectFactory';
import { ProcessMapEditorActionUtils } from 'ecto-common/lib/ProcessMaps/ProcessMapEditorActionUtils';
import {
  overlapsProcessMapRectWithRect,
  rectIterator,
  symbolConnectionsIterator
} from 'ecto-common/lib/ProcessMap/ProcessMapViewUtils';
import { SymbolModel } from 'ecto-common/lib/API/PresentationAPIGen';

/**
 * This whole file is a temporary helper to give us a way to import old process maps. The result
 * is far from perfect, but vastly better than doing it manually. This code is not pretty and should
 * be removed once we have imported all of the old process maps. As such, don't focus too much on
 * refactoring or making this code pretty.
 */
type Pos = {
  x: number;
  y: number;
};

const getTextNode = (root: ChildNode): ChildNode => {
  if (root.nodeName === 'text') {
    return root;
  }

  for (const child of root.childNodes) {
    const textNode = getTextNode(child);
    if (textNode != null) {
      return textNode;
    }
  }

  return null;
};

const getTextPosition = (root: ChildNode, offsetY: number) => {
  const textNode = getTextNode(root);

  const attribs = (textNode as Element)?.attributes;
  const x = parseInt(attribs?.getNamedItem('x')?.value ?? '-1', 10);
  const y = parseInt(attribs?.getNamedItem('y')?.value ?? '-1', 10);
  return { x, y: y + offsetY };
};

const pathLength = (path: Pos[]) => {
  let length = 0;
  for (let i = 1; i < path.length; i++) {
    const dx = path[i].x - path[i - 1].x;
    const dy = path[i].y - path[i - 1].y;
    length += Math.sqrt(dx * dx + dy * dy);
  }

  return length;
};

type PathAndParentId = {
  path: string;
  parentId: string;
  strokeColor?: string;
  strokeWidth?: string;
  dashed?: boolean;
  siblingPaths: string[];
};

const findAllPathStrings = (rootString: string): PathAndParentId[] => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(Base64.decode(rootString), 'text/xml');
  const paths: PathAndParentId[] = [];

  const visit = (
    node: ChildNode,
    parentId: string,
    index: number,
    curStrokeColor: string,
    curStrokeWidth: string,
    curSiblingPaths: string[]
  ) => {
    if (node.nodeName === 'g') {
      const attribs = (node as Element)?.attributes;
      curStrokeColor = attribs?.getNamedItem('stroke')?.value ?? curStrokeColor;
      curStrokeWidth =
        attribs?.getNamedItem('stroke-width')?.value ?? curStrokeWidth;

      const newSiblingPaths: string[] = [];
      for (const childNode of node.childNodes) {
        if (childNode.nodeName === 'path') {
          newSiblingPaths.push(
            (childNode as Element)?.attributes?.getNamedItem('d').value
          );
        }
      }

      curSiblingPaths = newSiblingPaths;
    }

    if (node.nodeName === 'path') {
      const attribs = (node as Element)?.attributes;

      if (
        attribs?.getNamedItem('data-status-signalid') ||
        attribs?.getNamedItem('data-alarm-signalid')
      ) {
        return;
      }

      paths.push({
        path: attribs?.getNamedItem('d').value,
        parentId,
        strokeColor: attribs?.getNamedItem('stroke')?.value ?? curStrokeColor,
        strokeWidth:
          attribs?.getNamedItem('stroke-width')?.value ?? curStrokeWidth,
        dashed: attribs?.getNamedItem('stroke-dasharray')?.value != null,
        siblingPaths: curSiblingPaths
      });
    } else {
      for (
        let childIndex = 0;
        childIndex < node.childNodes.length;
        childIndex++
      ) {
        visit(
          node.childNodes[childIndex],
          parentId + '-' + index,
          childIndex,
          curStrokeColor,
          curStrokeWidth,
          curSiblingPaths
        );
      }
    }
  };

  for (
    let childIndex = 0;
    childIndex < xmlDoc.childNodes.length;
    childIndex++
  ) {
    visit(xmlDoc.childNodes[childIndex], 'svg', childIndex, null, null, []);
  }

  return paths;
};

type NormalizedPath = {
  parentId: string;
  points: Pos[];
  originalPoints: Pos[];
  origin: Pos;
  isVerticalLine: boolean;
  isHorizontalLine: boolean;
  isClosedPath: boolean;
  isPipeLine: boolean;
  hasVerticalPathCloseBy: boolean;
  isSupportVerticalPath: boolean;
  svgPathString: string;
  strokeWidth?: string;
  strokeColor?: string;
  dashed?: boolean;
};

const convertToNormalizedCoordinates = (
  pathString: PathAndParentId
): NormalizedPath => {
  const commands = svgPathParser.makeAbsolute(
    svgPathParser.parseSVG(pathString.path)
  );

  let positions: Pos[] = commands.map((x) => ({ x: x.x, y: x.y }));
  const firstPoint = {
    x: positions[0].x,
    y: positions[0].y
  };

  const isClosedPath =
    positions[positions.length - 1].x === firstPoint.x &&
    positions[positions.length - 1].y === firstPoint.y;

  let isHorizontalLine = true;
  let isVerticalLine = true;
  let isPipeLine = true;

  for (let i = 1; i < positions.length; i++) {
    if (Math.abs(positions[i].x - firstPoint.x) > 0.01) {
      isVerticalLine = false;
    }

    if (Math.abs(positions[i].y - firstPoint.y) > 0.01) {
      isHorizontalLine = false;
    }

    if (!isHorizontalLine && !isVerticalLine) {
      isPipeLine = false;
    }
  }

  let minX = Number.MAX_SAFE_INTEGER;
  let minY = Number.MAX_SAFE_INTEGER;
  let maxX = Number.MIN_SAFE_INTEGER;
  let maxY = Number.MIN_SAFE_INTEGER;

  if (pathString.siblingPaths != null) {
    for (let siblingPath of pathString.siblingPaths) {
      const siblingCommands = svgPathParser.makeAbsolute(
        svgPathParser.parseSVG(siblingPath)
      );
      for (let i = 0; i < siblingCommands.length; i++) {
        minX = Math.min(minX, siblingCommands[i].x);
        minY = Math.min(minY, siblingCommands[i].y);
        maxX = Math.max(maxX, siblingCommands[i].x);
        maxY = Math.max(maxY, siblingCommands[i].y);
      }
    }
  }

  for (let i = 0; i < positions.length; i++) {
    minX = Math.min(minX, positions[i].x);
    minY = Math.min(minY, positions[i].y);
    maxX = Math.max(maxX, positions[i].x);
    maxY = Math.max(maxY, positions[i].y);
  }

  if (positions.length > 1) {
    positions = positions.map((pos) => ({
      x: pos.x - firstPoint.x,
      y: pos.y - firstPoint.y
    }));
    let length = pathLength(positions);
    positions = positions.map((pos) => ({
      x: pos.x / length,
      y: pos.y / length
    }));
  }

  return {
    svgPathString: pathString.path,
    parentId: pathString.parentId,
    originalPoints: commands.map((x) => ({ x: x.x, y: x.y })),
    points: positions,
    origin: {
      x: minX + (maxX - minX) / 2.0,
      y: minY + (maxY - minY) / 2.0
    },
    isHorizontalLine,
    isVerticalLine,
    isPipeLine,
    isClosedPath,
    hasVerticalPathCloseBy: false,
    isSupportVerticalPath: false,
    strokeWidth: pathString.strokeWidth,
    strokeColor: pathString.strokeColor,
    dashed: pathString.dashed
  };
};

const findCloseVerticalPaths = (paths: NormalizedPath[]) => {
  for (const path of paths) {
    for (const otherPath of paths) {
      if (
        path !== otherPath &&
        !path.hasVerticalPathCloseBy &&
        !path.isVerticalLine &&
        otherPath.isVerticalLine
      ) {
        const dx = path.origin.x - otherPath.origin.x;
        const dy = path.origin.y - otherPath.origin.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < 21) {
          path.hasVerticalPathCloseBy = true;
          otherPath.isSupportVerticalPath = true;
        }
      }
    }
  }
};

const comparePath = (path1: Pos[], path2: Pos[]) => {
  if (path1.length !== path2.length) {
    return false;
  }

  for (let i = 0; i < path1.length; i++) {
    if (
      Math.abs(path1[i].x - path2[i].x) > 0.0001 ||
      Math.abs(path1[i].y - path2[i].y) > 0.0001
    ) {
      return false;
    }
  }

  return true;
};

type OrientationInfo = {
  svgString: string;
  flipHorizontal?: boolean;
  flipVertical?: boolean;
  rotate?: 0 | 90 | 180 | 270;
};

let extraDatabase: Record<string, OrientationInfo[]> = {
  TwoWayValve: [
    {
      svgString:
        'M180.5 184.1v-10.25m0-.18c3.22 0 5.83-2.65 5.83-5.92s-2.61-5.92-5.83-5.92-5.83 2.65-5.83 5.92 2.61 5.92 5.83 5.92zm0 10.4l14.08 7.76v-15.51l-14.08 7.75zm0 0l-14.08-7.75v15.51l14.08-7.76z'
    },
    {
      svgString:
        'M173.5 103.5c3.22 0 5.83-2.65 5.83-5.92s-2.61-5.92-5.83-5.92-5.83 2.65-5.83 5.92 2.61 5.92 5.83 5.92zm0 10.4l14.08 7.76v-15.51l-14.08 7.75zm0 0l-14.08-7.75v15.51l14.08-7.76z'
    },
    {
      svgString:
        'M379.84 156.5c0-3.22-2.65-5.83-5.92-5.83s-5.92 2.61-5.92 5.83 2.65 5.83 5.92 5.83 5.92-2.61 5.92-5.83zm10.4 0l7.76-14.08h-15.51l7.75 14.08zm0 0l-7.75 14.08H398l-7.76-14.08z',
      rotate: 270
    },
    {
      svgString:
        'M188.19 588.67c0 3.76 3.09 6.81 6.91 6.81 3.81 0 6.9-3.05 6.9-6.81s-3.09-6.81-6.9-6.81c-3.82 0-6.91 3.05-6.91 6.81zm-12.14 0L167 605.1h18.1l-9.05-16.43zm0 0l9.05-16.43H167l9.05 16.43z',
      rotate: 90
    }
  ],
  Radiator: [
    {
      svgString:
        'M476.06 223.48c5.6 0 10.13-4.32 10.13-9.65v-86.18c0-5.33-4.53-9.65-10.13-9.65-5.59 0-10.12 4.32-10.12 9.65v86.18c0 5.33 4.53 9.65 10.12 9.65zm-7.01 11.27c0 4.01 3.14 7.25 7.01 7.25 3.88 0 7.02-3.24 7.02-7.25 0-4-3.14-7.24-7.02-7.24-3.87 0-7.01 3.24-7.01 7.24z'
    }
  ],
  PumpRight: [
    { svgString: 'M188.06 148v-36l15.59 8.99 15.58 9.01-15.58 8.99z' },
    {
      svgString: 'M425 169.87v27l-8.66-6.75-8.66-6.75 8.66-6.75z',
      flipHorizontal: true
    },
    { svgString: 'M722 150h-24l6-8.66 6-8.66 6 8.66z', rotate: 270 }
  ],
  ThreeWayValve: [
    {
      svgString:
        'M449 374.51v-11.28m0-.2c3.55 0 6.42-2.91 6.42-6.51 0-3.6-2.87-6.52-6.42-6.52s-6.42 2.92-6.42 6.52c0 3.6 2.87 6.51 6.42 6.51zm0 11.46l15.51 8.54v-17.08L449 374.49zm0 0L440.46 390h17.08L449 374.49zm0 0l-15.51-8.54v17.08l15.51-8.54z'
    },
    {
      svgString:
        'M380 138.36c3.44 0 6.23-2.83 6.23-6.31 0-3.49-2.79-6.32-6.23-6.32-3.44 0-6.23 2.83-6.23 6.32 0 3.48 2.79 6.31 6.23 6.31zm0 11.11l15.03 8.27v-16.55L380 149.47zm0 0l-8.27 15.03h16.55L380 149.47zm0 0l-15.03-8.28v16.55l15.03-8.27z'
    },
    {
      svgString:
        'M186.97 205.84c0 3.55 2.91 6.42 6.51 6.42 3.6 0 6.52-2.87 6.52-6.42s-2.92-6.42-6.52-6.42c-3.6 0-6.51 2.87-6.51 6.42zm-11.46 0l-8.54 15.51h17.08l-8.54-15.51zm0 0L160 197.3v17.08l15.51-8.54zm0 0l8.54-15.51h-17.08l8.54 15.51z',
      rotate: 270
    }
  ],
  RadiatorUnknownItem: [
    { svgString: 'M131 152l-20 40h20l-20-40h20m-10 60v-20m0-40v-21' }
  ],
  HeatExchanger: [
    { svgString: 'M310 125.96h39.3l-20.28-26.07 20.28-26.07H310' }
  ],
  SensorAlarm: [
    {
      svgString: 'M271 181.58a19.916 19.916 0 0120 0L281 199z'
    }
  ],
  SensorAlarm2: [
    {
      svgString: 'M271 181.58a19.916 19.916 0 0120 0L281 199z'
    },
    {
      svgString: 'M515 190.08a19.916 19.916 0 0120 0l-10 17.42z'
    }
  ]
};

let dynamicSymbolDatabase: Record<string, OrientationInfo[]> = {
  PumpRight: [
    { svgString: 'M188.06 148v-36l15.59 8.99 15.58 9.01-15.58 8.99z' },
    { svgString: 'M175.5 144.87v-27l8.66 6.75 8.66 6.75-8.66 6.75z' },
    {
      svgString: 'M425 169.87v27l-8.66-6.75-8.66-6.75 8.66-6.75z',
      flipHorizontal: true
    },
    { svgString: 'M376 407h27l-6.75 8.66-6.75 8.66-6.75-8.66z', rotate: 90 },
    { svgString: 'M599 189h-24l6-8.66 6-8.66 6 8.66z', rotate: 270 },
    {
      svgString: 'M312.5 137.4v24l-8.66-6-8.66-6 8.66-6z',
      flipHorizontal: true
    },
    {
      svgString: 'M444.86 170v31l-10.77-7.75-10.77-7.75 10.77-7.75z',
      flipHorizontal: true
    },
    {
      svgString: 'M138 595h-27l6.75-8.66 6.75-8.66 6.75 8.66z',
      rotate: 270
    }
  ],
  SensorAlarm2: [
    {
      svgString: 'M515 190.08a19.916 19.916 0 0120 0l-10 17.42z'
    }
  ],
  HeatPumpStatus: [
    {
      svgString:
        'M669.47 196.65a19.516 19.516 0 000-19.5l-36.18 2.99a19.498 19.498 0 000 13.51l36.18 3'
    },
    {
      svgString:
        'M407.45 99.25a14.5 14.5 0 000 14.5l26.9-2.22c1.2-3.24 1.2-6.81 0-10.05l-26.9-2.23',
      flipHorizontal: true
    },
    {
      svgString:
        'M207.91 150.58c-4.25 8.74-4.25 20.17 0 28.9 14.34-1.4 28.68-2.83 43.02-4.26 2.07-6.47 2.07-13.87.05-20.38-14.37-1.43-28.7-2.83-43.07-4.26z',
      flipHorizontal: true
    }
  ]
};

type ProcessMapSymbolImportResult = {
  item: SymbolModel;
  x: number;
  y: number;
  orientation?: OrientationInfo;
};

type ProcessMapLineImportResult = {
  points: Pos[];
  strokeWidth?: string;
  strokeColor?: string;
  dashed?: boolean;
  mode: ProcessMapLineMode;
};

type ItemAndOrientation = { item: SymbolModel; orientation?: OrientationInfo };

export const importProcessMapSymbols = (
  libraryData: SymbolModel[],
  documentSvg: string
): [ProcessMapSymbolImportResult[], ProcessMapLineImportResult[]] => {
  const pathsFromSvg = findAllPathStrings(documentSvg).map(
    convertToNormalizedCoordinates
  );
  findCloseVerticalPaths(pathsFromSvg);

  const lineResults: ProcessMapLineImportResult[] = [];
  const results: ProcessMapSymbolImportResult[] = [];

  const libraryItemsAndPathStrings = libraryData.map((item) => ({
    item,
    paths: findAllPathStrings(item.data).map(convertToNormalizedCoordinates)
  }));

  const handledPathsByParentId: Record<string, boolean> = {};
  const handledPaths = new Set<NormalizedPath>();
  for (let svgPath of pathsFromSvg) {
    let candidates: ItemAndOrientation[] = [];
    if (
      svgPath.parentId !== 'svg-0' &&
      handledPathsByParentId[svgPath.parentId] === true
    ) {
      continue;
    }

    for (const item of libraryItemsAndPathStrings) {
      for (let path of item.paths) {
        if (
          !svgPath.isVerticalLine &&
          !svgPath.isHorizontalLine &&
          comparePath(path.points, svgPath.points)
        ) {
          candidates.push({ item: item.item });
        }
      }
      for (let extraPathString of extraDatabase[item.item.name] ?? []) {
        const extraPath = convertToNormalizedCoordinates({
          path: extraPathString.svgString,
          siblingPaths: [],
          parentId: ''
        });
        if (
          !svgPath.isVerticalLine &&
          comparePath(extraPath.points, svgPath.points)
        ) {
          candidates.push({ item: item.item, orientation: extraPathString });
        }
      }
    }

    candidates = _.uniqBy(candidates, (x) => x.item.id);

    if (candidates.length > 0) {
      let resolvedCandidate: ItemAndOrientation = null;

      if (
        candidates.find((candidate) => candidate.item.name === 'SensorAlarm') !=
        null
      ) {
        if (svgPath.hasVerticalPathCloseBy) {
          resolvedCandidate = {
            item: libraryData.find(
              (candidate) => candidate.name === 'SensorAlarm2'
            )
          };
        } else {
          resolvedCandidate = candidates.find(
            (candidate) => candidate.item.name === 'SensorAlarm'
          );
        }
      } else if (candidates.length === 1) {
        resolvedCandidate = candidates[0];
      } else {
        resolvedCandidate = candidates[0];
        console.error('Multiple candidates, unable to choose');
      }

      handledPathsByParentId[svgPath.parentId] = true;
      handledPaths.add(svgPath);
      results.push({
        item: resolvedCandidate.item,
        x: svgPath.origin.x,
        y: svgPath.origin.y,
        orientation: resolvedCandidate.orientation
      });
    }
  }

  for (let svgPath of pathsFromSvg) {
    if (
      (svgPath.parentId !== 'svg-0' &&
        handledPathsByParentId[svgPath.parentId] === true) ||
      handledPaths.has(svgPath)
    ) {
      continue;
    }

    if (!svgPath.isClosedPath && !svgPath.isSupportVerticalPath) {
      const commands = svgPathParser.makeAbsolute(
        svgPathParser.parseSVG(svgPath.svgPathString)
      );
      let curPoints: Pos[] = [];

      for (let command of commands) {
        if (command.command === 'moveto') {
          if (curPoints.length > 0) {
            lineResults.push({
              points: curPoints,
              strokeWidth: svgPath.strokeWidth,
              strokeColor: svgPath.strokeColor,
              dashed: svgPath.dashed,
              mode: ProcessMapLineModes.Straight
            });
          }
          curPoints = [];
        }
        curPoints.push({ x: command.x, y: command.y });
      }

      if (curPoints.length > 0) {
        lineResults.push({
          points: curPoints,
          strokeWidth: svgPath.strokeWidth,
          strokeColor: svgPath.strokeColor,
          dashed: svgPath.dashed,
          mode: ProcessMapLineModes.Straight
        });
      }
    }
  }

  // console.log(results);

  return [results, lineResults];
};

const getNodeBbox = (node: ChildNode): ProcessMapRect => {
  const attribs = (node as Element)?.attributes;
  if (node.nodeName === 'circle') {
    const cx = parseFloat(attribs?.getNamedItem('cx')?.value ?? '-1');
    const cy = parseFloat(attribs?.getNamedItem('cy')?.value ?? '-1');
    const r = parseFloat(attribs?.getNamedItem('r')?.value ?? '-1');
    return {
      centerX: cx,
      centerY: cy,
      width: r * 2,
      height: r * 2,
      id: null
    };
  } else if (node.nodeName === 'path') {
    const path = attribs?.getNamedItem('d')?.value ?? '';
    const commands = svgPathParser.makeAbsolute(svgPathParser.parseSVG(path));
    let minX = Number.MAX_SAFE_INTEGER;
    let minY = Number.MAX_SAFE_INTEGER;
    let maxX = Number.MIN_SAFE_INTEGER;
    let maxY = Number.MIN_SAFE_INTEGER;

    for (let command of commands) {
      minX = Math.min(minX, command.x);
      minY = Math.min(minY, command.y);
      maxX = Math.max(maxX, command.x);
      maxY = Math.max(maxY, command.y);
    }

    return {
      centerX: minX + (maxX - minX) / 2.0,
      centerY: minY + (maxY - minY) / 2.0,
      width: maxX - minX,
      height: maxY - minY,
      id: null
    };
  }

  throw new Error('Unknown node type');
};

export const importOldSvg = (
  state: Draft<ProcessMapState>,
  signalTypesNameMap: Record<string, SignalTypeResponseModel>,
  libraryData: SymbolModel[]
) => {
  const parser = new DOMParser();
  const sanitized = DOMPurify.sanitize(
    Base64.decode(state.processMap.oldSvgData)
  );

  const xmlDoc = parser.parseFromString(sanitized, 'text/xml');
  const dynamicSymbols: [ProcessMapRect, ChildNode][] = [];

  const migrationData: MigrationData = {
    labelLocations: {},
    valueLocations: {},
    lineObjects: [],
    textLocations: []
  };

  const visitNode = (node: ChildNode) => {
    // console.log(node.nodeName)
    const attribs = (node as Element)?.attributes;
    const signalIdAttribute = attribs?.getNamedItem('data-value-signalid');

    if (node.nodeName === 'image') {
      const width = parseFloat(attribs?.getNamedItem('width')?.value ?? '-1');
      const height = parseFloat(attribs?.getNamedItem('height')?.value ?? '-1');
      const x = parseFloat(attribs?.getNamedItem('x')?.value ?? '-1');
      const y = parseFloat(attribs?.getNamedItem('y')?.value ?? '-1');
      const href = attribs?.getNamedItem('xlink:href')?.value ?? '';

      const base64Image = href.split('data:image/svg+xml;base64,')[1];
      if (base64Image != null) {
        const imageMd5 = md5(base64Image);
        ProcessMapEditorActionUtils.addSvg(state, base64Image, imageMd5);

        const symbol: ProcessMapSymbolObject = {
          type: ProcessMapObjectTypes.Symbol,
          id: UUID.generate(),
          rects: [
            {
              centerX: x + width / 2.0,
              centerY: y + height / 2.0,
              width,
              height,
              id: UUID.generate()
            }
          ],
          svgMd5: imageMd5,
          connections: [],
          typeId: null,
          states: [],
          symbolRules: [],
          originalWidth: width,
          originalHeight: height
        };

        state.processMap.objects.push(symbol);
      }
    }

    if (signalIdAttribute != null) {
      migrationData.valueLocations[signalIdAttribute.value as string] =
        getTextPosition(node, 0);
    }

    const labelSignalIdAttribute = attribs?.getNamedItem('data-label-signalid');
    if (labelSignalIdAttribute != null) {
      migrationData.labelLocations[labelSignalIdAttribute.value as string] =
        getTextPosition(node, 0);
    }

    const alarmSignalIdAttribute = attribs?.getNamedItem('data-alarm-signalid');

    let pushedDynamic = false;
    if (alarmSignalIdAttribute != null) {
      pushedDynamic = true;
      dynamicSymbols.push([getNodeBbox(node), node]);
    }

    const alarmStatusSignalIdAttribute = attribs?.getNamedItem(
      'data-status-signalid'
    );

    if (!pushedDynamic && alarmStatusSignalIdAttribute != null) {
      dynamicSymbols.push([getNodeBbox(node), node]);
    }

    if (
      signalIdAttribute == null &&
      labelSignalIdAttribute == null &&
      alarmSignalIdAttribute == null &&
      alarmStatusSignalIdAttribute == null
    ) {
      if (node.nodeName === 'text') {
        const fontSizeStr = (node as Element).getAttribute('font-size');

        migrationData.textLocations.push({
          x: parseFloat((node as Element).getAttribute('x')),
          y: parseFloat((node as Element).getAttribute('y')),
          text: node.textContent,
          fontSize: fontSizeStr ? parseFloat(fontSizeStr) : null,
          fontStyle: (node as Element).getAttribute('font-style')
        });
      } else {
        for (let childNode of node.childNodes) {
          visitNode(childNode);
        }
      }
    }
  };

  for (let node of xmlDoc.childNodes) {
    visitNode(node);
  }

  const overlappingDynamicSymbols: [ProcessMapRect, ChildNode][][] = [];

  for (let i = 0; i < dynamicSymbols.length; i++) {
    const symbol = dynamicSymbols[i];
    const overlappingSymbols: [ProcessMapRect, ChildNode][] = [];
    overlappingSymbols.push(symbol);

    for (let j = 0; j < dynamicSymbols.length; j++) {
      if (
        j !== i &&
        overlapsProcessMapRectWithRect(symbol[0], dynamicSymbols[j][0])
      ) {
        overlappingSymbols.push(dynamicSymbols[j]);
      }
    }

    overlappingDynamicSymbols.push(overlappingSymbols);
  }

  const libraryItemsAndPathStrings = libraryData.map((item) => ({
    item,
    paths: findAllPathStrings(item.data).map(convertToNormalizedCoordinates)
  }));

  const handledRects: ProcessMapRect[] = [];

  for (let overlappingDynamicSymbol of overlappingDynamicSymbols) {
    if (
      handledRects.some((rect) =>
        overlappingDynamicSymbol.some(([childSymbolRect]) =>
          overlapsProcessMapRectWithRect(rect, childSymbolRect)
        )
      )
    ) {
      continue;
    }

    let minX = Number.MAX_SAFE_INTEGER;
    let minY = Number.MAX_SAFE_INTEGER;
    let maxX = Number.MIN_SAFE_INTEGER;
    let maxY = Number.MIN_SAFE_INTEGER;

    for (let [childSymbolRect] of overlappingDynamicSymbol) {
      minX = Math.min(
        minX,
        childSymbolRect.centerX - childSymbolRect.width / 2.0
      );
      minY = Math.min(
        minY,
        childSymbolRect.centerY - childSymbolRect.height / 2.0
      );
      maxX = Math.max(
        maxX,
        childSymbolRect.centerX + childSymbolRect.width / 2.0
      );
      maxY = Math.max(
        maxY,
        childSymbolRect.centerY + childSymbolRect.height / 2.0
      );
    }

    const centerX = minX + (maxX - minX) / 2.0;
    const centerY = minY + (maxY - minY) / 2.0;

    const dbCandidates: ItemAndOrientation[] = [];
    const candidates: ItemAndOrientation[] = [];

    if (
      overlappingDynamicSymbol.length === 1 &&
      overlappingDynamicSymbol[0][1].nodeName === 'circle'
    ) {
      let attribs = (overlappingDynamicSymbol[0][1] as Element)?.attributes;
      const alarmAttribSignalName = attribs?.getNamedItem(
        'data-alarm-signalid'
      )?.value;
      const statusAttribSignalName = attribs?.getNamedItem(
        'data-status-signalid'
      )?.value;

      const size = Math.round(maxX - minX);
      const rectObject: ProcessMapRectObject = {
        type: ProcessMapObjectTypes.Rect,
        id: UUID.generate(),
        cornerRadius: size / 2.0,
        fillColor: '#dadada',
        strokeColor: colors.accent1Color,
        rects: [
          { centerX, centerY, width: size, height: size, id: UUID.generate() }
        ]
      };

      if (alarmAttribSignalName != null) {
        rectObject.fillColorRuleName = 'Alarm';
        rectObject.fillColorRuleSignalTypeIds = [
          signalTypesNameMap[alarmAttribSignalName].id
        ];
      } else if (statusAttribSignalName != null) {
        rectObject.fillColorRuleName = 'Status';
        rectObject.fillColorRuleSignalTypeIds = [
          signalTypesNameMap[statusAttribSignalName].id
        ];
      }

      state.processMap.objects.push(rectObject);
    } else {
      let pathStrings: PathAndParentId[] = overlappingDynamicSymbol
        .map(([_childSymbolRect, childSymbol]) => {
          let attribs = (childSymbol as Element)?.attributes;
          const pathString = attribs?.getNamedItem('d')?.value ?? '';
          if (pathString === '') {
            return null;
          }

          return {
            path: pathString,
            parentId: null,
            siblingPaths: []
          };
        })
        .filter((x) => x !== null);
      let normalizedPathStrings = pathStrings.map(
        convertToNormalizedCoordinates
      );

      for (let pathString of normalizedPathStrings) {
        for (const item of libraryItemsAndPathStrings) {
          let foundInSymbolDatabase = false;

          for (let extraPathString of dynamicSymbolDatabase[item.item.name] ??
            []) {
            const extraPath = convertToNormalizedCoordinates({
              path: extraPathString.svgString,
              siblingPaths: [],
              parentId: ''
            });
            if (comparePath(extraPath.points, pathString.points)) {
              dbCandidates.push({
                item: item.item,
                orientation: extraPathString
              });
              foundInSymbolDatabase = true;
            }
          }

          if (!foundInSymbolDatabase) {
            for (let path of item.paths) {
              if (comparePath(path.points, pathString.points)) {
                candidates.push({ item: item.item });
                break;
              }
            }
          }
        }
      }

      if (dbCandidates.length !== 1 && candidates.length !== 1) {
        throw new Error('Invalid amount of candidates: ' + candidates.length);
      }

      const candidate = dbCandidates[0] ?? candidates[0];
      let alarmAttribSignalName: string = null;
      let statusAttribSignalName: string = null;
      overlappingDynamicSymbol.forEach(([_childSymbolRect, childSymbol]) => {
        let attribs = (childSymbol as Element)?.attributes;
        alarmAttribSignalName =
          alarmAttribSignalName ??
          attribs?.getNamedItem('data-alarm-signalid')?.value;
        statusAttribSignalName =
          statusAttribSignalName ??
          attribs?.getNamedItem('data-status-signalid')?.value;
      });

      const itemMd5 = md5(candidate.item.data);
      ProcessMapEditorActionUtils.addSvg(state, candidate.item.data, itemMd5);
      const symbolObject = ProcessMapObjectFactory.createSymbolObject(
        centerX,
        centerY,
        candidate.item.width,
        candidate.item.height,
        candidate.item.id,
        itemMd5,
        candidate.item.connections,
        candidate.item.states ?? []
      );
      symbolObject.flipHorizontal = candidate.orientation?.flipHorizontal;
      symbolObject.flipVertical = candidate.orientation?.flipVertical;
      symbolObject.rotation = candidate.orientation?.rotate;
      state.processMap.objects.push(symbolObject);

      if (alarmAttribSignalName != null) {
        const alarmAttribSignalTypeId =
          signalTypesNameMap[alarmAttribSignalName].id;

        if (!candidate.item.states?.includes('alarm')) {
          throw new Error('Missing alarm state in symbol object');
        }

        symbolObject.symbolRules.push({
          state: 'alarm',
          condition: {
            signalTypeId: alarmAttribSignalTypeId,
            value: 1,
            type: ProcessMapRuleTypes.Equal
          }
        });
      }

      if (statusAttribSignalName != null) {
        const statusAttribSignalTypeId =
          signalTypesNameMap[statusAttribSignalName].id;

        if (
          !candidate.item.states?.includes('status-active') ||
          !candidate.item.states?.includes('status-inactive')
        ) {
          throw new Error('Missing status state in symbol object');
        }

        symbolObject.symbolRules.push({
          state: 'status-active',
          condition: {
            signalTypeId: statusAttribSignalTypeId,
            value: 1,
            type: ProcessMapRuleTypes.Equal
          }
        });

        symbolObject.symbolRules.push({
          state: 'status-inactive',
          condition: {
            signalTypeId: statusAttribSignalTypeId,
            value: 0,
            type: ProcessMapRuleTypes.Equal
          }
        });
      }
    }

    for (let [childSymbolRect] of overlappingDynamicSymbol) {
      handledRects.push(childSymbolRect);
    }
  }

  for (let textLocation of migrationData.textLocations) {
    const textObject: ProcessMapTextObject = {
      type: ProcessMapObjectTypes.Text,
      id: UUID.generate(),
      rects: [
        {
          centerX: textLocation.x + 90,
          centerY: textLocation.y + 20,
          width: 180,
          height: 40,
          id: UUID.generate()
        }
      ],
      text: textLocation.text,
      textSettings: {
        italic: textLocation.fontStyle === 'italic',
        fontSize: textLocation.fontSize
      }
    };
    state.processMap.objects.push(textObject);
  }

  const [importedSymbols, importedLines] = importProcessMapSymbols(
    libraryData,
    Base64.encode(sanitized)
  );

  for (let symbol of importedSymbols) {
    const itemMd5 = md5(symbol.item.data);
    ProcessMapEditorActionUtils.addSvg(state, symbol.item.data, itemMd5);
    const symbolObject = ProcessMapObjectFactory.createSymbolObject(
      symbol.x,
      symbol.y,
      symbol.item.width,
      symbol.item.height,
      symbol.item.id,
      itemMd5,
      symbol.item.connections,
      symbol.item.states ?? []
    );
    symbolObject.flipHorizontal = symbol.orientation?.flipHorizontal;
    symbolObject.flipVertical = symbol.orientation?.flipVertical;
    symbolObject.rotation = symbol.orientation?.rotate;
    state.processMap.objects.push(symbolObject);
  }

  for (const line of importedLines) {
    const lineObject: ProcessMapLineObject = {
      type: ProcessMapObjectTypes.Line,
      rects: line.points.map((p) => ({
        centerX: p.x,
        centerY: p.y,
        width: lineRectHeight,
        height: lineRectHeight,
        id: UUID.generate()
      })),
      id: UUID.generate(),
      color: line.strokeColor,
      dashed: line.dashed,
      mode: line.mode,
      lineWidth:
        line.strokeWidth != null
          ? Math.ceil(parseFloat(line.strokeWidth))
          : null
    };
    state.processMap.objects.push(lineObject);
  }

  state.processMap.objects.push(...migrationData.lineObjects);

  let allSignalNames = _.uniq([
    ..._.keys(migrationData.labelLocations),
    ..._.keys(migrationData.valueLocations)
  ]);
  for (let signalName of allSignalNames) {
    let pos =
      migrationData.labelLocations[signalName] ??
      migrationData.valueLocations[signalName];
    let signalTypeId = signalTypesNameMap[signalName].id;

    state.processMap.objects.push({
      id: UUID.generate(),
      type: ProcessMapObjectTypes.Signal,
      rects: [
        {
          centerX: pos.x + 75,
          centerY: pos.y + 25,
          width: 150,
          height: 50,
          id: UUID.generate()
        }
      ],
      labelTextSettings: {},
      valueTextSettings: {},
      signalTypeId,
      hideLabel: migrationData.labelLocations[signalName] == null
    });
  }

  for (const [rect] of rectIterator(state.processMap)) {
    rect.centerX = Math.round(rect.centerX);
    rect.centerY = Math.round(rect.centerY);
  }
  type ConnectionAndDistance = {
    distance: number;
    connection: ProcessMapSymbolLineConnection;
  };

  const connectionCandidates: ConnectionAndDistance[] = [];

  for (const [
    lineRect,
    lineObject,
    lineObjectIndex,
    lineRectIndex
  ] of rectIterator(state.processMap)) {
    if (lineObject.type === ProcessMapObjectTypes.Line) {
      for (let [
        symbolConnectionRect,
        symbolObject,
        symbolObjectIndex,
        ,
        connectionId
      ] of symbolConnectionsIterator(
        state.processMap,
        state.connectionCircleRadius
      )) {
        if (overlapsProcessMapRectWithRect(lineRect, symbolConnectionRect)) {
          const newObject: ProcessMapSymbolLineConnection = {
            symbolObjectHandle: {
              objectIndex: symbolObjectIndex,
              objectId: symbolObject.id
            },
            lineObjectRectHandle: {
              objectIndex: lineObjectIndex,
              objectId: lineObject.id,
              rectIndex: lineRectIndex,
              rectId: lineRect.id
            },
            connectionId: connectionId
          };

          let dx = lineRect.centerX - symbolConnectionRect.centerX;
          let dy = lineRect.centerY - symbolConnectionRect.centerY;
          let distance = Math.sqrt(dx * dx + dy * dy);
          connectionCandidates.push({
            distance,
            connection: newObject
          });
        }
      }
    }
  }

  const groupedConnectionCandidates = _.groupBy(
    connectionCandidates,
    (collection) =>
      collection.connection.symbolObjectHandle.objectId +
      '-' +
      collection.connection.connectionId
  );

  for (let candidatesForConnectionId of Object.values(
    groupedConnectionCandidates
  )) {
    candidatesForConnectionId.sort((a, b) => a.distance - b.distance);

    if (candidatesForConnectionId.length > 0) {
      state.processMap.symbolLineConnections.push(
        candidatesForConnectionId[0].connection
      );
    }
  }

  ProcessMapEditorActionUtils.snapSymbolLineConnections(state);
  ProcessMapEditorActionUtils.pushUndoStack(state);
  ProcessMapEditorActions.trimDocumentSize(state);
};
