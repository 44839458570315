import { ResourceType } from 'ecto-common/lib/constants';
import { EquipmentTypes } from 'ecto-common/lib/utils/equipmentTypeUtils';
import API from 'ecto-common/lib/API/API';
import {
  ToolSignalProviders,
  ToolSignalProviderTranslations
} from 'js/components/ManageEquipment/EditEquipment/toolTypes';

import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import T from 'ecto-common/lib/lang/Language';
import { number, option } from 'ecto-common/lib/ModelForm/formUtils';
import UUID from 'uuidjs';
import {
  ModelDefinition,
  ModelDynamicBoolProperty
} from 'ecto-common/lib/ModelForm/ModelPropType';
import {
  AddOrUpdatePowerDeltaControlProviderRequestModel,
  PowerControlType
} from 'ecto-common/lib/API/APIGen';
import { AdminToolImplementationType } from 'js/components/ManageEquipment/EditEquipment/useEquipmentTools';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';

// Create a select a single signal model
const signal = (
  key: (input: AddOrUpdatePowerDeltaControlProviderRequestModel) => string,
  label: string,
  hasError: ModelDynamicBoolProperty<
    AddOrUpdatePowerDeltaControlProviderRequestModel,
    object,
    string
  >
): ModelDefinition<AddOrUpdatePowerDeltaControlProviderRequestModel> => {
  return {
    key,
    label,
    placeholder: label,
    modelType: ModelType.SIGNAL,
    hasError,
    selectFromCurrentNodeOnly: true
  };
};

const PowerDeltaTypeOptions: GenericSelectOption<PowerControlType>[] = [
  { value: PowerControlType.Heating, label: T.admin.powerdeltacontrol.heating },
  { value: PowerControlType.Cooling, label: T.admin.powerdeltacontrol.cooling }
];

const errorIfNotSet = <ValueType>(value: ValueType) => value == null;

const powerDeltaNumber =
  number<AddOrUpdatePowerDeltaControlProviderRequestModel>;

const model: ModelDefinition<AddOrUpdatePowerDeltaControlProviderRequestModel>[] =
  [
    signal(
      (input) => input.returnTempSignalId,
      T.admin.powerdeltacontrol.returntemperaturesignal,
      errorIfNotSet
    ),
    option(
      (input) => input.powerDeltaControlType,
      T.admin.powerdeltacontrol.type,
      PowerDeltaTypeOptions,
      errorIfNotSet
    ),
    powerDeltaNumber(
      (input) => input.timeConstant,
      T.admin.powerdeltacontrol.timeconstant,
      (value: number) => errorIfNotSet(value) || value <= 0
    )
  ];

const EMPTY_INPUT = Object.freeze({
  returnTempSignalId: null,
  powerDeltaControlType: null,
  timeConstant: 300
});

const PowerDeltaTool: AdminToolImplementationType<AddOrUpdatePowerDeltaControlProviderRequestModel> =
  {
    type: ToolSignalProviders.POWER_DELTA_CONTROL,
    name: ToolSignalProviderTranslations[
      ToolSignalProviders.POWER_DELTA_CONTROL
    ],
    accessRights: [ResourceType.CORE],
    allowDelete: true,
    allowDeploy: false,
    model,
    emptyInput: (equipment) => {
      // Create good default values specific for equipment type id
      if (equipment?.equipmentTypeId) {
        if (
          equipment.equipmentTypeId ===
          EquipmentTypes.ECTOGRID_HEATING_SUBSYSTEM
        ) {
          return {
            ...EMPTY_INPUT,
            providerId: UUID.generate(),
            powerDeltaControlType: PowerControlType.Heating
          };
        } else if (
          equipment.equipmentTypeId ===
          EquipmentTypes.ECTOGRID_COOLING_SUBSYSTEM
        ) {
          return {
            ...EMPTY_INPUT,
            providerId: UUID.generate(),
            powerDeltaControlType: PowerControlType.Cooling
          };
        }
      }
      return { ...EMPTY_INPUT, providerId: UUID.generate() };
    },
    save: API.Admin.PowerDeltaControl.addOrUpdateProviders,
    delete: API.Admin.PowerDeltaControl.removeProviders,
    load: API.Admin.PowerDeltaControl.getProvidersByNodeIds
  };

export default PowerDeltaTool;
