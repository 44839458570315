import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import { useCommonDispatch } from 'ecto-common/lib/reducers/storeCommon';
import { CancellablePromise } from 'ecto-common/lib/API/API';
import { Dispatch, SetStateAction } from 'react';

/**
 * Run promise like a normal promise, but dispatch the result
 * @param promise
 * @param setter
 * @param setHasError
 * @param cacheKey
 * @returns {[boolean, (function(...[*]): void)|*, (function(): void)|*]}
 */
export function useDispatchResultRequest<
  PromiseType extends (...args: unknown[]) => CancellablePromise<unknown>
>(
  promise: PromiseType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setter: any,
  setHasError: Dispatch<SetStateAction<boolean>>,
  cacheKey: string = null,
  errorHandler?: (error: unknown) => void
) {
  const dispatch = useCommonDispatch();

  return usePromiseCall({
    promise,
    onSuccess: (result) => {
      dispatch(setter(result));
    },
    onError: (error) => {
      errorHandler?.(error);
      setHasError?.(true);
    },
    initiallyLoading: true,
    cacheKey
  });
}
