import React, { MouseEventHandler } from 'react';
import classNames from 'classnames';
import styles from 'ecto-common/lib/Map/MapMarker.module.css';

// TODO: This is a really weird pattern - it just returns the children?
const Marker = ({
  className: _className,
  onClick: _onClick,
  children
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: any) => children;

interface MapMarkerProps {
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  isCluster?: boolean;
  focusActive?: boolean;
  nodeIsFocused?: boolean;
  lat?: number;
  lng?: number;
}

const MapMarker = ({
  lat: _lat,
  lng: _lng,
  children = null,
  onClick = null,
  isCluster = false,
  focusActive = false,
  nodeIsFocused = false
}: MapMarkerProps) => {
  return (
    <Marker className={styles.marker} onClick={onClick}>
      <div
        className={classNames(
          isCluster ? styles.cluster : styles.location,
          focusActive && !nodeIsFocused && styles.fade
        )}
        onClick={onClick}
      >
        {children}
      </div>
    </Marker>
  );
};

export default MapMarker;
