export enum APIGenType {
  EctotableClientAPIGen,
  EctotableAPIGen,
  APIGen,
  IntegrationAPIGen,
  IntegrationAdminAPIGen,
  IdentityServiceAPIGen,
  IdentityServiceAPIGenV2,
  NetmoreAPIGen,
  EctoplannerAPIGen,
  JobsAPIGenV2,
  DeviceAPIGen,
  AlarmsAPIGen,
  PresentationAPIGen,
  ManualAlarmsAPIGen
}
