let listeners: (() => void)[] = [];
let darkModeEnabled = false;

function syncEffects() {
  if (darkModeEnabled) {
    document.body.classList.add('dark');
  } else {
    document.body.classList.remove('dark');
  }
}

syncEffects();

const emitChange = () => {
  for (let listener of listeners) {
    listener();
  }
};

export const darkModeStore = {
  getSnapshot(): boolean {
    return darkModeEnabled;
  },

  subscribe(listener: () => void) {
    listeners = [...listeners, listener];
    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  },

  toggle() {
    this.setEnabled(!darkModeEnabled);
  },

  setEnabled(enabled: boolean) {
    darkModeEnabled = enabled;
    syncEffects();
    emitChange();
  }
};
