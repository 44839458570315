import React, { useCallback } from 'react';
import { modelFormattedNodeValue } from 'ecto-common/lib/ModelForm/modelValueUtil';
import { KeyValueFixedSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueFixedSelectableInput';
import SelectNodeDialog from 'ecto-common/lib/SelectNodeDialog/SelectNodeDialog';
import _ from 'lodash';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import { CustomNodeTreeSet } from 'ecto-common/lib/SelectNodeDialog/SelectNode';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { ModelDefinitionInternal } from 'ecto-common/lib/ModelForm/ModelPropType';

export type NodeModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object
> = {
  modelType: typeof ModelType.NODE;
  customNodeTreeSet?: CustomNodeTreeSet;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, string>;

type ModelEditorNodeProps<ObjectType extends object> = ModelEditorProps & {
  model: NodeModelDefinition<ObjectType>;
};

const ModelEditorNode = <ObjectType extends object>({
  model,
  updateItem,
  disabled,
  rawValue,
  hasError
}: ModelEditorNodeProps<ObjectType>) => {
  const nodeMap = useCommonSelector((state) => state.general.nodeMap);
  const [dialogIsOpen, showDialog, hideDialog] = useDialogState(false);

  const updateDialogItemTransformToSingleNode = useCallback(
    (value: string[]) => {
      if (value.length > 1) {
        throw new Error(
          'Invalid list with more than one element used for ModelType.NODE'
        );
      }

      updateItem(_.head(value) ?? null);
      hideDialog();
    },
    [updateItem, hideDialog]
  );

  let formattedValue = modelFormattedNodeValue(rawValue, nodeMap);

  return (
    <>
      <KeyValueFixedSelectableInput
        disabled={disabled}
        keyText={model.label}
        value={formattedValue}
        placeholder={model.placeholder}
        onClick={showDialog}
        hasError={hasError}
      />
      <SelectNodeDialog
        isOpen={dialogIsOpen}
        onModalClose={hideDialog}
        nodeIds={rawValue == null ? [] : [rawValue]}
        onNodesSelected={updateDialogItemTransformToSingleNode}
        multiSelect={false}
        customNodeTreeSet={model.customNodeTreeSet}
      />
    </>
  );
};

export default React.memo(ModelEditorNode);
