import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import * as directions from './directions';
import styles from 'ecto-common/lib/Tooltip/Tooltip.module.css';
import { DirectionType } from './directions';

// How far around the edge we can be
const POSITION_PADDING = 5;

interface TooltipContentProps {
  direction?: DirectionType;
  text?: React.ReactNode;
  multiline?: boolean;
  directionCallback?(direction: DirectionType): void;
}

const TooltipContent = ({
  directionCallback,
  direction,
  text,
  multiline
}: TooltipContentProps) => {
  const _tooltipRef = useRef(null);

  useEffect(() => {
    const _getDirection = (): DirectionType => {
      let calculatedDirection = direction;

      if (_tooltipRef.current) {
        const { top, left, bottom, right } =
          _tooltipRef.current.getBoundingClientRect();

        const innerWidth = window.innerWidth - POSITION_PADDING;
        const innerHeight = window.innerHeight - POSITION_PADDING;

        // If the tooltip is too large in either direction just use the default
        if (
          (right > innerWidth && left < POSITION_PADDING) ||
          (top < POSITION_PADDING && bottom > innerHeight)
        ) {
          return direction;
        }

        if (right > innerWidth) {
          switch (calculatedDirection) {
            case directions.N:
            case directions.NE:
              calculatedDirection = directions.NW;
              break;
            case directions.S:
            case directions.SE:
              calculatedDirection = directions.SW;
              break;
            case directions.E:
              calculatedDirection = directions.W;
              break;
            default:
              break;
          }
        }

        if (left < POSITION_PADDING) {
          switch (calculatedDirection) {
            case directions.N:
            case directions.NW:
              calculatedDirection = directions.NE;
              break;
            case directions.S:
            case directions.SW:
              calculatedDirection = directions.SE;
              break;
            case directions.W:
              calculatedDirection = directions.E;
              break;
            default:
              break;
          }
        }

        if (top < POSITION_PADDING) {
          switch (calculatedDirection) {
            case directions.N:
              calculatedDirection = directions.S;
              break;
            case directions.NW:
              calculatedDirection = directions.SW;
              break;
            case directions.NE:
              calculatedDirection = directions.SE;
              break;
            default:
              break;
          }
        }

        if (bottom > innerHeight) {
          switch (calculatedDirection) {
            case directions.S:
              calculatedDirection = directions.N;
              break;
            case directions.SW:
              calculatedDirection = directions.NW;
              break;
            case directions.SE:
              calculatedDirection = directions.NE;
              break;
            default:
              break;
          }
        }
      }

      return calculatedDirection;
    };

    if (directionCallback) {
      directionCallback(_getDirection());
    }
  }, [directionCallback, direction]);

  const cssStyles = classNames(
    styles.tooltip,
    multiline && styles.multiline,
    styles[`tooltip-${direction}`]
  );

  return (
    <div className={cssStyles}>
      <span ref={_tooltipRef}>{text}</span>
    </div>
  );
};

export default React.memo(TooltipContent);
