import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext
} from 'react';
import styles from './PageTreeView.module.css';
import LocationTreeView from 'ecto-common/lib/LocationTreeView/LocationTreeView';
import { useParams, useHistory, matchPath } from 'react-router';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import T from 'ecto-common/lib/lang/Language';
import classNames from 'classnames';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import _ from 'lodash';
import { SET_CURRENT_NODE } from 'ecto-common/lib/actions/actionTypes';
import useReloadTrigger from 'ecto-common/lib/hooks/useReloadTrigger';
import SearchInput from 'ecto-common/lib/SearchInput/SearchInput';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import {
  useCommonSelector,
  useCommonDispatch
} from 'ecto-common/lib/reducers/storeCommon';
import UrlContext from 'ecto-common/lib/hooks/UrlContext';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';

interface PageTreeViewProps {
  isOpen?: boolean;
  urlBuilder?(tenantId: string, nodeId: string, equipmentId?: string): string;
  selectEquipment?: boolean;
  disablePageChange?: boolean;
}

const PageTreeView = ({
  isOpen,
  selectEquipment,
  urlBuilder,
  disablePageChange
}: PageTreeViewProps) => {
  const { locationRoute } = useContext(UrlContext);
  const defaultUrlBuilder = useCallback(
    (newTenantId: string, newNodeId: string, _equipmentId: string) => {
      const params = matchPath<NodeParams>(
        window.location.pathname,
        locationRoute
      )?.params;
      return `/${newTenantId}/home/${newNodeId}/${params?.page ?? ''}`;
    },
    [locationRoute]
  );

  const _urlBuilder = urlBuilder ?? defaultUrlBuilder;

  const nodeTree = useCommonSelector((state) => state.general.nodeTree);

  const equipmentMap = useCommonSelector((state) => state.general.equipmentMap);
  const nodeMap = useCommonSelector((state) => state.general.nodeMap);
  const nodeTags = useCommonSelector((state) => state.general.nodeTags);
  const nodeId = useCommonSelector((state) => state.general.nodeId);
  const equipmentId = useCommonSelector((state) => state.general.equipmentId);
  const params = useParams<NodeParams>();
  const [reloadTreeView, triggerReloadTreeView] = useReloadTrigger();
  const { tenantId } = useContext(TenantContext);

  // There is a 1-frame delay before Redux has the new values when the location changes.
  // During that frame LocationTreeView can get its initial values wrong, and from then on
  // will handle its state internally and ignore new values from outside. To prevent this
  // we reconstruct the location tree whenever this happens (will only happen when moving
  // between top level pages and changing the node ID while doing so).
  //
  // TODO: move state out of LocationTreeView?
  let { nodeId: paramsNodeId } = params;

  useEffect(() => {
    if (paramsNodeId != null && nodeId !== paramsNodeId) {
      triggerReloadTreeView();
    }
  }, [nodeId, paramsNodeId, triggerReloadTreeView]);

  const [searchTerm, setSearchTerm] = useState('');
  const [tags, setTags] = useState<string[]>([]);

  const selectedIds = useMemo(() => {
    let selectedId = nodeId;

    if (selectEquipment) {
      selectedId = equipmentId || nodeId;
    }

    return [selectedId];
  }, [selectEquipment, equipmentId, nodeId]);

  const createOption = (tag: string) => ({
    value: tag,
    label: tag
  });
  const tagOptions = nodeTags && nodeTags.map(createOption);

  const history = useHistory();
  const dispatch = useCommonDispatch();

  const updateLocation = useCallback(
    (newNodeId: string) => {
      const equipment = getNodeFromMap(equipmentMap, newNodeId);
      const startingUrl = window.location.href;

      if (!disablePageChange) {
        if (selectEquipment) {
          if (equipment) {
            history.push(
              _urlBuilder(tenantId, equipment.nodeId, equipment.equipmentId)
            );
          } else {
            history.push(_urlBuilder(tenantId, newNodeId));
          }
        } else {
          history.push(_urlBuilder(tenantId, newNodeId));
        }
      }

      if (window.location.href !== startingUrl || disablePageChange) {
        dispatch({
          type: SET_CURRENT_NODE,
          payload: {
            nodeId: equipment ? equipment.nodeId : newNodeId,
            equipmentId: equipment ? newNodeId : null
          }
        });
      }
    },
    [
      tenantId,
      equipmentMap,
      disablePageChange,
      dispatch,
      history,
      selectEquipment,
      _urlBuilder
    ]
  );

  const tagValue = tags.map(createOption);
  const searchFilter = useMemo(
    () => ({ searchTerm: searchTerm ?? '', tags }),
    [searchTerm, tags]
  );

  return (
    <div className={classNames(styles.locationTree, isOpen && styles.open)}>
      <SearchInput
        autoFocus={isOpen}
        onChange={setSearchTerm}
        tabIndex={isOpen ? undefined : -1}
        wrapperClassName={styles.searchFieldContainer}
        placeholder={T.sidebar.location.search.placeholder}
      />

      <div className={styles.tagSearchContainer}>
        <Select
          className={styles.tagSearchSelect}
          isMulti
          placeholder={T.sidebar.location.tags.placeholder}
          value={tagValue}
          options={tagOptions}
          autoFocus={false}
          menuShouldScrollIntoView={false}
          menuShouldBlockScroll={false}
          closeMenuOnScroll={false}
          captureMenuScroll={false}
          onChange={(newTags: GenericSelectOption[]) =>
            setTags(_.map(newTags, 'value'))
          }
        />
      </div>
      <LocationTreeView
        className={styles.locationTreeView}
        key={isOpen + '' + reloadTreeView}
        allowSelectingRootNodes
        searchFilter={searchFilter}
        nodeTree={nodeTree}
        nodeMap={nodeMap}
        sidePadding={0}
        focusedId={_.head(selectedIds)}
        selectedIds={selectedIds}
        selectEquipment={selectEquipment}
        onChangeSelectedState={updateLocation}
        equipmentMap={equipmentMap}
      />
    </div>
  );
};

PageTreeView.defaultProps = {
  disablePageChange: false
};

export default PageTreeView;
