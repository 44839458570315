import { createReducer } from 'ecto-common/lib/utils/reducerUtils';
import {
  initialReqState,
  mapReqStateToProp,
  RequestStatusProp
} from 'ecto-common/lib/utils/requestStatus';
import _ from 'lodash';
import API from 'ecto-common/lib/API/API';
import RequestModule from 'ecto-common/lib/RequestModule/RequestModule';
import { downloadBlobFromText } from 'ecto-common/lib/utils/downloadBlob';
import { BuildingStatusTimestampComparisonType } from 'ecto-common/lib/utils/buildingStatusUtil';
import {
  BuildingListResponseModel,
  BuildingStatus,
  SimpleNodeResponseModel
} from 'ecto-common/lib/API/APIGen';
import { AdminDispatch, AdminGetState } from 'js/reducers/storeAdmin';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';

const GET_BUILDINGS = 'BUILDINGS_GET_BUILDINGS';
const SET_BUILDINGS_QUERY = 'BUILDINGS_SET_BUILDINGS_QUERY';
const EXPORT_BUILDINGS = 'BUILDINGS_EXPORT_BUILDINGS';

const requests = Object.freeze({
  [GET_BUILDINGS]: 'getBuildingsReqState',
  [EXPORT_BUILDINGS]: 'exportBuildingsReqState'
});

const module = new RequestModule(requests);

export type BuildingsSearchQuery = {
  buildingStatusTimestamp: string;
  buildingStatusTimestampComparisonType: BuildingStatusTimestampComparisonType;
  buildingStatuses: BuildingStatus[];
  page: number;
  pageSize: number;
  searchPhrase: string;
  sortColumn: string;
  sortOrder: string;
  tags: string[];
};

type BuildingsReducerProps = {
  getBuildingsReqState: RequestStatusProp<BuildingListResponseModel>;
  exportBuildingsReqState: RequestStatusProp<File>;
  buildings: SimpleNodeResponseModel[];
  searchQuery: BuildingsSearchQuery;
};

const initialState: BuildingsReducerProps = {
  ...module.initialState,
  getBuildingsReqState: mapReqStateToProp(initialReqState),
  exportBuildingsReqState: mapReqStateToProp(initialReqState),
  buildings: [],
  searchQuery: {
    buildingStatusTimestamp: null,
    buildingStatusTimestampComparisonType:
      BuildingStatusTimestampComparisonType.BEFORE,
    buildingStatuses: [],
    page: 0,
    pageSize: 100,
    searchPhrase: '',
    sortColumn: null,
    sortOrder: null,
    tags: []
  }
};

export default createReducer(initialState, {
  ...module.handlers,
  [GET_BUILDINGS]: (state, action) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getBuildingsReqState = mapReqStateToProp<any>(action);
    const buildings = getBuildingsReqState.isSuccessful
      ? getBuildingsReqState.data.buildings
      : state.buildings;

    return { ...state, getBuildingsReqState, buildings };
  },
  [SET_BUILDINGS_QUERY]: (state, { newQuery }) => ({
    ...state,
    searchQuery: { ...state.searchQuery, ...newQuery }
  }),
  [EXPORT_BUILDINGS]: (state, action) => ({
    ...state,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    exportBuildingsReqState: mapReqStateToProp<any>(action)
  })
});

export const BuildingActions = {
  getBuildings:
    (
      contextSettings: ApiContextSettings,
      newQuery: Partial<BuildingsSearchQuery> = undefined
    ) =>
    (dispatch: AdminDispatch, getState: AdminGetState) => {
      const { buildings } = getState();
      const { searchQuery } = buildings;

      if (!_.isEqual(newQuery, searchQuery)) {
        dispatch({ type: SET_BUILDINGS_QUERY, payload: { newQuery } });

        module.doRequest(
          contextSettings,
          GET_BUILDINGS,
          API.Admin.Nodes.getBuildings,
          getState().buildings.searchQuery
        )(dispatch);
      }
    },
  cancelGetBuildings: () => module.cancelRequest(GET_BUILDINGS),
  exportBuildings:
    (contextSettings: ApiContextSettings) =>
    async (dispatch: AdminDispatch, getState: AdminGetState) => {
      const { buildings } = getState();
      const { searchQuery } = buildings;

      await module.doRequest(
        contextSettings,
        EXPORT_BUILDINGS,
        API.Admin.Nodes.exportBuildings,
        searchQuery
      )(dispatch);

      const state = getState();
      const { exportBuildingsReqState } = state.buildings;

      downloadBlobFromText(exportBuildingsReqState.data);
    },
  cancelExportBuildings: () => module.cancelRequest(EXPORT_BUILDINGS)
};
