import React, { useCallback, useState, useEffect } from 'react';

import AddButton from 'ecto-common/lib/Button/AddButton';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import T from 'ecto-common/lib/lang/Language';
import useReloadTrigger from 'ecto-common/lib/hooks/useReloadTrigger';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';

import DashboardsCollectionsPageList from 'js/components/DashboardCollections/DashboardCollectionsPageList';
import DashboardCollectionFormModal from 'js/components/DashboardCollections/DashboardCollectionFormModal';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import Button from 'ecto-common/lib/Button/Button';
import Icons from 'ecto-common/lib/Icons/Icons';
import SelectDefaultDashboardCollectionDialog from 'js/components/DashboardCollections/RelationModals/SelectDefaultDashboardCollectionDialog';
import { DashboardCollectionResponseModel } from 'ecto-common/lib/API/APIGen';

interface DashboardsCollectionsPageProps {
  segmentControl?: React.ReactNode;
}

const DashboardsCollectionsPage = ({
  segmentControl
}: DashboardsCollectionsPageProps) => {
  const [isOpen, showDialog, hideDialog] = useDialogState(false);
  const [defaultDialogIsOpen, showDefaultDialog, hideDefaultDialog] =
    useDialogState(false);
  const [currentItem, setCurrentItem] =
    useState<DashboardCollectionResponseModel>(null);
  const [reloadTrigger, triggerReload] = useReloadTrigger();

  useEffect(() => {
    if (currentItem != null) {
      showDialog();
    } else {
      hideDialog();
    }
  }, [currentItem, showDialog, hideDialog]);

  const _hideDialog = useCallback(() => {
    setCurrentItem(null);
    hideDialog();
  }, [hideDialog]);

  const toolbarItems = (
    <>
      {segmentControl}
      <ToolbarFlexibleSpace />
      <ToolbarItem>
        <Button onClick={showDefaultDialog}>
          <Icons.Folder /> {T.admin.dashboardcollection.setdefault}
        </Button>
      </ToolbarItem>
      <ToolbarItem>
        <AddButton onClick={showDialog}>
          {T.admin.dashboardcollection.add}
        </AddButton>
      </ToolbarItem>
    </>
  );

  return (
    <ToolbarContentPage
      wrapContent={false}
      title={T.admin.dashboards.title}
      showLocationPicker={false}
      toolbarItems={toolbarItems}
    >
      <DashboardsCollectionsPageList
        onClickRow={setCurrentItem}
        reloadTrigger={reloadTrigger}
      />

      <SelectDefaultDashboardCollectionDialog
        isOpen={defaultDialogIsOpen}
        onModalClose={hideDefaultDialog}
      />

      <DashboardCollectionFormModal
        currentItem={currentItem}
        onModalClose={_hideDialog}
        isOpen={isOpen}
        onSuccess={triggerReload}
      />
    </ToolbarContentPage>
  );
};

export default DashboardsCollectionsPage;
