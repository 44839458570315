import React from 'react';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalSpace from 'ecto-common/lib/Modal/ModalSpace';
import Button from 'ecto-common/lib/Button/Button';
import GreyButton from 'ecto-common/lib/Button/GreyButton';
import T from '../lang/Language';

interface ModalActionFooterProps {
  /**
   *  If set to true buttons will be disabled. Useful for REST operations that should not be aborted.
   */
  isLoading?: boolean;
  /**
   * This allows you to insert additional content to the left of the two buttons. TODO: Shouldn't you be using a regular ModalFooter then?
   */
  leftSide?: object;
  /**
   * Action that is called when the confirm button is pressed.
   */
  onConfirm?(): void;
  /**
   * Action that is called when the modal is closed.
   */
  onModalClose: () => void;
  /**
   * Text for the confirm button.
   */
  confirmText?: React.ReactNode;
  /**
   * Text for the cancel button.
   */
  cancelText?: string;
  /**
   * If set to true the confirm button is disabled.
   */
  confirmDisabled?: boolean;
}

/**
 *  This is a convenience footer that can be used instead of a ModalFooter in the common use case of one confirm and one cancel button.
 */
const ModalActionFooter = ({
  leftSide,
  onConfirm,
  onModalClose,
  confirmText,
  cancelText,
  confirmDisabled,
  isLoading
}: ModalActionFooterProps) => {
  return (
    <ModalFooter>
      {leftSide}
      <ModalSpace />
      <Button onClick={onConfirm} disabled={isLoading || confirmDisabled}>
        {confirmText}
      </Button>
      <GreyButton onClick={onModalClose} disabled={isLoading}>
        {cancelText || T.common.actionmodal.cancel}
      </GreyButton>
    </ModalFooter>
  );
};

export default ModalActionFooter;
