import React, { useCallback } from 'react';

import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import styles from 'ecto-common/lib/ModelForm/ModelEditor.module.css';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import SelectUsersDialog from 'js/components/SelectUsersDialog/SelectUsersDialog';
import { KeyValueFixedSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueFixedSelectableInput';
import _ from 'lodash';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import { CustomModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';

type AdminModelEditorUsersFromNodeProps = Omit<ModelEditorProps, 'rawValue'> & {
  nodeId?: string;
  rawValue: string[];
  selectLabel: React.ReactNode;
  model: CustomModelDefinition<object, object, unknown>;
};

const AdminModelEditorUsersFromNode = ({
  nodeId,
  model,
  updateItem,
  disabled,
  rawValue,
  selectLabel
}: AdminModelEditorUsersFromNodeProps) => {
  const [dialogIsOpen, showDialog, hideDialog] = useDialogState(false);

  const value = (
    <div>
      <Icons.User className={styles.icon} />
      {_.isEmpty(rawValue)
        ? T.admin.modeltypes.users.zeroselected
        : T.format(T.admin.modeltypes.users.numberselected, rawValue.length)}
    </div>
  );

  const onAddUsers = useCallback(
    (_userIds: string[]) => {
      updateItem(_userIds);
      hideDialog();
    },
    [updateItem, hideDialog]
  );

  return (
    <>
      <KeyValueFixedSelectableInput
        disabled={disabled}
        keyText={model.label}
        value={value}
        placeholder={_.defaultTo(selectLabel, model.label)}
        onClick={showDialog}
      />
      <SelectUsersDialog
        isOpen={dialogIsOpen}
        nodeId={nodeId}
        selectedUserIds={rawValue}
        onModalClose={hideDialog}
        addUsers={onAddUsers}
      />
    </>
  );
};

export default React.memo(AdminModelEditorUsersFromNode);
