// Auto generated file! Do not edit!
import { APIGenType } from './APIGenType';
import { getApiEnvironment } from 'ecto-common/lib/utils/apiEnvironment';
import { BackendSetting } from 'ecto-common/lib/API/APIUtils';
import { getExternalAPIFetch } from 'ecto-common/lib/utils/APIFetchInstance';
export const getBackendSettings = (): Partial<
  Record<APIGenType, BackendSetting>
> => {
  const apiEnvironment = getApiEnvironment();
  return {
    [APIGenType.EctotableClientAPIGen]: {
      apiFetch: getExternalAPIFetch(),
      fetchOptions: {
        scopes: [apiEnvironment.scopes.ectoplanner],
        domain: apiEnvironment.urls.ectotableClientUrl
      }
    },
    [APIGenType.EctotableAPIGen]: {
      fetchOptions: {
        scopes: [apiEnvironment.scopes.ectoplanner],
        domain: apiEnvironment.urls.ectotableUrl
      }
    },
    [APIGenType.APIGen]: {
      fetchOptions: {
        scopes: [apiEnvironment.scopes.gateway],
        domain: apiEnvironment.urls.url
      }
    },
    [APIGenType.IntegrationAPIGen]: {
      fetchOptions: {
        domain: apiEnvironment.urls.integrationUrl
      }
    },
    [APIGenType.IntegrationAdminAPIGen]: {
      fetchOptions: {
        domain: apiEnvironment.urls.integrationAdminUrl
      }
    },
    [APIGenType.IdentityServiceAPIGen]: {
      fetchOptions: {
        scopes: [apiEnvironment.scopes.identity],
        domain: apiEnvironment.urls.identityServiceUrl
      }
    },
    [APIGenType.IdentityServiceAPIGenV2]: {
      fetchOptions: {
        scopes: [apiEnvironment.scopes.identity],
        domain: apiEnvironment.urls.identityServiceV2Url
      }
    },
    [APIGenType.NetmoreAPIGen]: {
      fetchOptions: {
        domain: apiEnvironment.urls.netmoreUrl
      }
    },
    [APIGenType.EctoplannerAPIGen]: {
      fetchOptions: {
        scopes: [apiEnvironment.scopes.ectoplanner],
        domain: apiEnvironment.urls.ectoplannerUrl
      }
    },
    [APIGenType.JobsAPIGenV2]: {
      fetchOptions: {
        scopes: [apiEnvironment.scopes.jobs],
        domain: apiEnvironment.urls.jobsV2Url
      }
    },
    [APIGenType.DeviceAPIGen]: {
      fetchOptions: {
        scopes: [apiEnvironment.scopes.device],
        domain: apiEnvironment.urls.deviceUrl
      }
    },
    [APIGenType.AlarmsAPIGen]: {
      fetchOptions: {
        scopes: [apiEnvironment.scopes.alarms],
        domain: apiEnvironment.urls.alarmsUrl
      }
    },
    [APIGenType.PresentationAPIGen]: {
      fetchOptions: {
        scopes: [apiEnvironment.scopes.presentation],
        domain: apiEnvironment.urls.presentationUrl
      }
    },
    [APIGenType.ManualAlarmsAPIGen]: {
      fetchOptions: {
        scopes: [apiEnvironment.scopes.manualalarms],
        domain: apiEnvironment.urls.manualAlarmsUrl
      }
    }
  };
};
