import { findDOMNode } from 'react-dom';
import _ from 'lodash';

/**
 * Traverse up the DOM tree and reset the first node that has scrolled.
 */
export const resetScroll = (parentElement: Element) => {
  if (parentElement == null) {
    return;
  }

  let parent = findDOMNode(parentElement);
  let scrollerElement = parent;

  while (scrollerElement && (scrollerElement as Element).scrollTop === 0) {
    scrollerElement = scrollerElement.parentElement;
  }

  parent = scrollerElement ?? parent;
  (parent as Element).scrollTo(0, 0);
};

export const centerListEntry = (entryId: string, parentElement: Element) => {
  let parent = findDOMNode(parentElement) as Element;
  const container = parent.childNodes[0];
  let modalScrollerElement = parent;

  // Determine if there is a modal or docked toolbar content page
  // body which handles the scrolling.  If so, it overrides the parent
  // element scroll and we need to adjust the scroll offset for that
  // element instead.
  while (
    modalScrollerElement &&
    !modalScrollerElement.hasAttribute('data-parent-scroller')
  ) {
    modalScrollerElement = modalScrollerElement.parentElement;
  }

  parent = modalScrollerElement ?? parent;

  const childNode = _.find(Array.from(container.childNodes), { id: entryId });

  if (childNode == null) {
    return;
  }

  const nodeToCenter = findDOMNode(childNode as Element) as Element;
  const { height: scrollRectHeight, y: parentPosition } =
    parent.getBoundingClientRect();
  const { height: childHeight, y: childPosition } =
    nodeToCenter.getBoundingClientRect();
  // Adding scrollTop is necessary to get real relative position as its value is baked
  // into childPosition
  const childPositionInScrollParent =
    childPosition + parent.scrollTop - parentPosition;

  let childPositionInScrollRect =
    childPositionInScrollParent - parent.scrollTop;
  const nodeIsAlreadyVisible =
    childPositionInScrollRect > 0 &&
    childPositionInScrollRect + childHeight < scrollRectHeight;

  if (!nodeIsAlreadyVisible) {
    const centeredChildPositionInScrollParent =
      childPositionInScrollParent - scrollRectHeight * 0.5 + childHeight * 0.5;
    parent.scrollTop = Math.max(0, centeredChildPositionInScrollParent);
  }
};
