import APIGen from 'ecto-common/lib/API/APIGen';
import { useProcessMapSignals } from 'ecto-common/lib/ProcessMap/ProcessMapHooks';
import ProcessMapView from 'ecto-common/lib/ProcessMap/ProcessMapView';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import React, { useRef, useState } from 'react';
import _ from 'lodash';
import { SignalProviderSignalResponseModel } from 'ecto-common/lib/API/APIGen';
import { SignalProviderSignalWithProviderResponseModel } from 'ecto-common/lib/types/EctoCommonTypes';

type ProcessMapPreviewOldProps = {
  previewNodeId: string;
};

const ProcessMapPreviewOld = ({ previewNodeId }: ProcessMapPreviewOldProps) => {
  const signalProvidersQuery = APIGen.Signals.getSignalsByNode.useQuery(
    { nodesIds: [previewNodeId] },
    { enabled: previewNodeId != null }
  );

  const dragState = useRef<{ x: number; y: number; dragging: boolean }>({
    x: 300,
    y: window.innerHeight - 500,
    dragging: false
  });
  const ref = useRef<HTMLDivElement>(null);

  const signalTypesNameMap = useCommonSelector(
    (state) => state.general.signalTypesNameMap
  );
  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );
  const signalUnitTypesMap = useCommonSelector(
    (state) => state.general.signalUnitTypesMap
  );

  const [currentSignal, setCurrentSignal] =
    useState<SignalProviderSignalResponseModel>(null);
  const [_currentEditSignal, setCurrentEditSignal] =
    useState<SignalProviderSignalWithProviderResponseModel>(null);

  const signalProviders = signalProvidersQuery.data ?? [];
  const { image, signalData } = useProcessMapSignals(
    previewNodeId,
    signalProviders,
    false,
    signalTypesNameMap,
    null,
    true
  );

  return (
    <div
      ref={ref}
      onMouseMove={(e) => {
        if (e.buttons === 1) {
          dragState.current.x += e.movementX;
          dragState.current.y += e.movementY;
          dragState.current.dragging = true;
          ref.current.style.left = dragState.current.x + 'px';
          ref.current.style.top = dragState.current.y + 'px';
        }
      }}
      style={{
        border: '1px solid silver',
        width: 800,
        height: 400,
        position: 'absolute',
        left: dragState.current.x,
        top: dragState.current.y,
        background: 'white',
        cursor: 'pointer',
        userSelect: 'none'
      }}
    >
      <ProcessMapView
        image={image}
        signalProviders={signalProviders}
        signalData={signalData}
        setCurrentSignal={setCurrentSignal}
        setCurrentEditSignal={setCurrentEditSignal}
        currentSignal={currentSignal}
        signalTypesMap={signalTypesMap}
        signalUnitTypesMap={signalUnitTypesMap}
      />
    </div>
  );
};

export default React.memo(ProcessMapPreviewOld);
