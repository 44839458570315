import React, { useEffect, useCallback, useState, useRef } from 'react';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { calculateDataTableMinHeight } from 'ecto-common/lib/utils/dataTableUtils';
import T from 'ecto-common/lib/lang/Language';
import PagedDataTable, {
  getTotalPagesFromPagedResult,
  PagedDataTableDataType,
  PagedDataTableErrorResult
} from 'ecto-common/lib/PagedDataTable/PagedDataTable';
import { resetScroll } from 'ecto-common/lib/utils/scrollUtils';
import {
  ASC,
  SortDirectionType
} from 'ecto-common/lib/DataTable/SortDirection';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import { typedMemo } from 'ecto-common/lib/utils/typescriptUtils';
import { CancellablePromise } from 'ecto-common/lib/API/API';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';

type IntegrationAccountPaging = {
  sortColumn: string;
  sortOrder: string;
  page: number;
  pageSize: number;
};

const INITIAL_PAGING: IntegrationAccountPaging = {
  sortColumn: null,
  sortOrder: ASC,
  page: 0,
  pageSize: 10
};

interface IntegrationAccountListProps<AccountType> {
  onSelectAccount?(account: AccountType): void;
  reloadAccounts?: number;
  pageSize?: number;
  isOpen?: boolean;
  promise?(
    contextSettings: ApiContextSettings,
    pagingParams: IntegrationAccountPaging
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): CancellablePromise<any>;
  columns?: DataTableColumnProps<AccountType>[];
  sortColumn?: string;
}

function IntegrationAccountList<AccountType extends object>({
  onSelectAccount,
  reloadAccounts,
  pageSize,
  isOpen,
  promise,
  columns,
  sortColumn
}: IntegrationAccountListProps<AccountType>) {
  const [paging, setPaging] = useState<IntegrationAccountPaging>({
    ...INITIAL_PAGING,
    pageSize: pageSize,
    sortColumn
  });
  const [accounts, setAccounts] = useState<PagedDataTableDataType<AccountType>>(
    { result: [], totalPages: 0, hasError: false }
  );
  const scrollNode = useRef(null);

  // GET ACCOUNTS
  const [getAccountsIsLoading, _getAccounts, cancelGetAccounts] =
    usePromiseCall({
      promise,
      onSuccess: (data) => {
        setAccounts({
          result: data?.items ?? data,
          totalPages: getTotalPagesFromPagedResult(data, pageSize),
          hasError: false
        });
      },
      onError: () => {
        setAccounts(PagedDataTableErrorResult);
        toastStore.addErrorToast(
          T.admin.integration.generic.get.accounts.error
        );
      },
      initiallyLoading: true
    });

  const getAccounts = useCallback(
    () => _getAccounts(paging),
    [_getAccounts, paging]
  );

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  useEffect(() => {
    if (!isOpen) {
      cancelGetAccounts();
    }
  }, [isOpen, cancelGetAccounts]);

  const onSortClick = useCallback((_sortColumn: string, sortOrder: string) => {
    setPaging((oldPaging) => ({
      ...oldPaging,
      sortColumn: _sortColumn,
      sortOrder
    }));
  }, []);

  // This is an ugly workaround to force account reload. Will disappear when we move to react-query.
  const lastReload = useRef(reloadAccounts);

  useEffect(() => {
    resetScroll(scrollNode.current);
    setPaging((oldPaging) => {
      if (oldPaging.page !== 0 || lastReload.current !== reloadAccounts) {
        lastReload.current = reloadAccounts;
        return { ...oldPaging, page: 0 };
      }

      return oldPaging;
    });
  }, [reloadAccounts]);

  const onPageChange = useCallback((newPage: number) => {
    resetScroll(scrollNode.current);
    setPaging((oldPaging) => ({ ...oldPaging, page: newPage }));
  }, []);

  return (
    <PagedDataTable<AccountType>
      innerRef={scrollNode}
      data={accounts}
      page={paging.page}
      columns={columns}
      isLoading={getAccountsIsLoading}
      onSortChange={onSortClick}
      onPageChange={onPageChange}
      pageSize={pageSize}
      sortBy={paging.sortColumn}
      sortDirection={paging.sortOrder as SortDirectionType}
      onClickRow={onSelectAccount}
      noDataText={T.admin.integration.generic.get.accounts.empty}
      useAllAvailableHeight
      minHeight={calculateDataTableMinHeight({ pageSize })}
    />
  );
}

IntegrationAccountList.defaultProps = {
  pageSize: 20,
  isOpen: true
};

export default typedMemo(IntegrationAccountList);
