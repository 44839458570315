import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import NavLinkFix from 'ecto-common/lib/NavLinkFix/NavLinkFix';
import DeleteButton from 'ecto-common/lib/Button/DeleteButton';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import Button from 'ecto-common/lib/Button/Button';
import SaveButton from 'ecto-common/lib/Button/SaveButton';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import Icons from 'ecto-common/lib/Icons/Icons';
import SelectDashboardCollectionForBuildingTemplateModal from 'js/components/DashboardCollections/RelationModals/SelectDashboardCollectionForBuildingTemplateModal';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';

import API from 'ecto-common/lib/API/API';
import useReloadTrigger from 'ecto-common/lib/hooks/useReloadTrigger';
import NoDataMessage from 'ecto-common/lib/NoDataMessage/NoDataMessage';
import HelpPaths from 'ecto-common/help/tocKeys';

import { patchEquipmentGroupTemplates } from 'js/actions/getEquipmentGroupTemplates';
import { setEquipmentGroupTemplates } from 'js/actions/getEquipmentGroupTemplates';
import EditEquipmentGroup from 'js/components/EditBuildingData/EditEquipmentGroup';
import { transformModbusDataTypes } from 'js/components/ModbusLayout/ModbusEditUtils';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import { TemplateManagementParams } from 'js/utils/routeConstants';
import {
  AddOrUpdateBuildingTemplateRequestModel,
  BuildingTemplateResponseModel
} from 'ecto-common/lib/API/APIGen';

const EditEquipmentGroupTemplate = () => {
  const equipmentGroupTemplates = useAdminSelector(
    (state) => state.admin.equipmentGroupTemplates
  );
  const [templateData, setTemplateData] =
    useState<BuildingTemplateResponseModel>(null);

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [deleteDialogOpen, showDeleteDialog, hideDeleteDialog] =
    useDialogState(false);
  const [
    editDashboardCollectionOpen,
    showEditDashboardCollection,
    hideEditDashboardCollection
  ] = useDialogState(false);
  const { tenantId } = useContext(TenantContext);

  // We use this reload trigger to forcefully recreate the EditEquipmentGroup component
  // after a successful save so we are sure that the data from the server matches 1:1 with
  // our internal edit state.
  const [reloadTriggerForTemplateData, triggerReloadForTemplateData] =
    useReloadTrigger();
  const params = useParams<TemplateManagementParams>();
  const dispatch = useAdminDispatch();
  const history = useHistory();

  const [isLoadingEquipmentGroupTemplates, getEquipmentGroupTemplates] =
    usePromiseCall({
      promise: API.Admin.Templates.getBuildings,
      onSuccess: (templates) => {
        dispatch(setEquipmentGroupTemplates(templates));
      },
      onError: () => {
        // Do nothing, just try to refresh the equipment templates
      }
    });

  useEffect(() => {
    getEquipmentGroupTemplates();
  }, [getEquipmentGroupTemplates]);

  const [isUpdatingEquipmentGroupTemplate, updateEquipmentGroupTemplate] =
    usePromiseCall({
      promise: API.Admin.Templates.updateBuilding,
      onSuccess: (result) => {
        toastStore.addSuccessToast(T.admin.requests.updatetemplate.success);
        dispatch(patchEquipmentGroupTemplates(result));
        setHasUnsavedChanges(false);
        triggerReloadForTemplateData();
      },
      onError: () => {
        toastStore.addErrorToast(T.admin.requests.updatetemplate.failure);
      }
    });

  const save = useCallback(() => {
    let _templateData = _.cloneDeep(
      templateData ||
        _.find(equipmentGroupTemplates, {
          equipmentTemplateGroupId: params.itemId
        })
    );

    if (_.isEmpty(_.trim(_templateData.name))) {
      toastStore.addErrorToast(
        T.admin.equipmentgrouptemplates.error.invalidname
      );
      return;
    }

    _templateData.equipmentTemplates.forEach((equipmentTemplate) => {
      equipmentTemplate.signalTemplateOverrides = transformModbusDataTypes(
        equipmentTemplate.signalTemplateOverrides
      );
    });

    // Cast due to BuildingTemplateResponseModel containing optional values. Asserted to be valid by form.
    updateEquipmentGroupTemplate(
      _templateData as AddOrUpdateBuildingTemplateRequestModel
    );
  }, [
    templateData,
    equipmentGroupTemplates,
    params.itemId,
    updateEquipmentGroupTemplate
  ]);

  const [isDeleting, deleteEquipmentGroupTemplate] = usePromiseCall({
    promise: API.Admin.Templates.deleteBuilding,
    onSuccess: (unused, itemIds) => {
      toastStore.addSuccessToast(T.admin.equipmentgrouptemplates.deletesuccess);
      const idToDelete = _.head(itemIds);
      const newEquipmentGroupTemplates = _.reject(
        _.cloneDeep(equipmentGroupTemplates),
        { equipmentTemplateGroupId: idToDelete }
      );
      dispatch(setEquipmentGroupTemplates(newEquipmentGroupTemplates));
      hideDeleteDialog();
      setHasUnsavedChanges(false);
      _.defer(() => {
        history.push(`/${tenantId}/templateManagement/buildings`);
      });
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.equipmentgrouptemplates.deletefailed);
    }
  });

  const deleteTemplate = useCallback(() => {
    if (params.itemId) {
      deleteEquipmentGroupTemplate([params.itemId]);
    }
  }, [params, deleteEquipmentGroupTemplate]);

  const onEquipmentGroupDataChanged = useCallback(
    (buildingData: BuildingTemplateResponseModel) => {
      setTemplateData((oldData) => {
        setHasUnsavedChanges(buildingData !== oldData);
        return buildingData;
      });
    },
    []
  );

  const renderTemplateData = _.find(equipmentGroupTemplates, {
    equipmentTemplateGroupId: params.itemId
  });

  const hasNoData = renderTemplateData == null;

  const headingBody = useMemo(() => {
    return (
      <span>
        <NavLinkFix to={`/${tenantId}/templateManagement/buildings`}>
          {T.admin.equipmentgrouptemplates.title}
        </NavLinkFix>{' '}
        &gt;&nbsp;
        {renderTemplateData && renderTemplateData.name}
      </span>
    );
  }, [renderTemplateData, tenantId]);

  const isLoading =
    isLoadingEquipmentGroupTemplates || isUpdatingEquipmentGroupTemplate;

  const toolbarItems = (
    <>
      <ToolbarFlexibleSpace />
      <ToolbarItem>
        <Button
          onClick={showEditDashboardCollection}
          disabled={isLoading || hasNoData}
        >
          <Icons.Dashboard /> {T.admin.dashboards.configuredashboardcollection}
        </Button>
      </ToolbarItem>
      <ToolbarItem>
        <DeleteButton
          disabled={isLoading || hasNoData}
          onClick={showDeleteDialog}
        >
          {T.admin.equipmentgrouptemplates.delete}
        </DeleteButton>
      </ToolbarItem>
      <ToolbarItem>
        <SaveButton
          disabled={!hasUnsavedChanges || isLoading || hasNoData}
          onClick={save}
        >
          {T.admin.equipmentgrouptemplates.save}
        </SaveButton>
      </ToolbarItem>
    </>
  );

  return (
    <ToolbarContentPage
      showLocationPicker={false}
      title={headingBody}
      wrapContent={false}
      toolbarItems={toolbarItems}
      helpPath={HelpPaths.docs.admin.templates.building_templates}
    >
      <LoadingContainer isLoading={isLoading}>
        {renderTemplateData ? (
          <EditEquipmentGroup
            hasUnsavedChanges={hasUnsavedChanges}
            editDescriptionInModbusDialog={false}
            editingTemplate
            key={reloadTriggerForTemplateData}
            onEquipmentGroupDataChanged={onEquipmentGroupDataChanged}
            initialEquipmentGroup={renderTemplateData}
          />
        ) : (
          <NoDataMessage />
        )}
      </LoadingContainer>

      <SelectDashboardCollectionForBuildingTemplateModal
        isOpen={editDashboardCollectionOpen}
        onModalClose={hideEditDashboardCollection}
        buildingTemplateId={params.itemId}
      />

      <ConfirmDeleteDialog
        isLoading={isDeleting}
        onModalClose={hideDeleteDialog}
        onDelete={deleteTemplate}
        isOpen={deleteDialogOpen}
      >
        {T.admin.equipmentgrouptemplates.confirmdelete.text}
      </ConfirmDeleteDialog>
    </ToolbarContentPage>
  );
};

export default React.memo(EditEquipmentGroupTemplate);
