import { createReducer } from 'ecto-common/lib/utils/reducerUtils';
import { createAction } from 'ecto-common/lib/utils/actionUtils';
import API, {
  performAsyncRequestWithStateChanges
} from 'ecto-common/lib/API/API';
import {
  initialReqState,
  REQ_STATE_ERROR,
  REQ_STATE_SUCCESS,
  RequestStatusRawProp,
  updateReqState
} from 'ecto-common/lib/utils/requestStatus';
import {
  ProvisioningActions,
  SET_PROVISIONING_DEVICE_DATA_CHANGED
} from 'js/modules/provisioningCommon/provisioningCommon';
import {
  DeviceInfoResponseModel,
  IoTDeviceResponseModel,
  IoTDeviceViewResponseModel
} from 'ecto-common/lib/API/APIGen';
import { AdminDispatch, AdminGetState } from 'js/reducers/storeAdmin';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';

const MANAGE_ENERGY_MANAGER_DEVICE_SET_EM_EQ_ID =
  'MANAGE_ENERGY_MANAGER_DEVICE_SET_EM_EQ_ID';
const MANAGE_ENERGY_MANAGER_DEVICE_SET_NEW_IOT_DEVICE =
  'MANAGE_ENERGY_MANAGER_DEVICE_SET_NEW_IOT_DEVICE';
const MANAGE_ENERGY_MANAGER_DEVICE_SET_SHOW_DIALOG =
  'MANAGE_ENERGY_MANAGER_DEVICE_SET_SHOW_DIALOG';
const MANAGE_ENERGY_MANAGER_DEVICE_UPDATE_GET_REQUEST =
  'MANAGE_ENERGY_MANAGER_DEVICE_UPDATE_GET_REQUEST';
const MANAGE_ENERGY_MANAGER_DEVICE_SHOULD_CONFIRM_UNLINK =
  'MANAGE_ENERGY_MANAGER_DEVICE_SHOULD_CONFIRM_UNLINK';
const MANAGE_ENERGY_MANAGER_DEVICE_UPDATE_PAIR_REQUEST =
  'MANAGE_ENERGY_MANAGER_DEVICE_UPDATE_PAIR_REQUEST';
const MANAGE_ENERGY_MANAGER_DEVICE_UPDATE_UNPAIR_REQUEST =
  'MANAGE_ENERGY_MANAGER_DEVICE_UPDATE_UNPAIR_REQUEST';

type ProvisionDeviceGetRequest = {
  deviceInfo: DeviceInfoResponseModel;
  device: IoTDeviceViewResponseModel;
};

type ProvisionDeviceReducerProps = {
  energyManagerEquipmentId: string;
  iotDevice: IoTDeviceViewResponseModel;
  pendingNewIotDevice: IoTDeviceResponseModel;
  getRequest: RequestStatusRawProp<ProvisionDeviceGetRequest>;
  pairRequest: RequestStatusRawProp<void>;
  unpairRequest: RequestStatusRawProp<void>;
  showEnergyManagerDialog: boolean;
  shouldConfirmUnlink: boolean;
};

const initialState: ProvisionDeviceReducerProps = {
  energyManagerEquipmentId: null,
  iotDevice: null,
  pendingNewIotDevice: null,
  getRequest: initialReqState,
  pairRequest: initialReqState,
  unpairRequest: initialReqState,
  showEnergyManagerDialog: false,
  shouldConfirmUnlink: false
};

export default createReducer(initialState, {
  [SET_PROVISIONING_DEVICE_DATA_CHANGED]: (state) => {
    return {
      ...state,
      showEnergyManagerDialog: false,
      pendingNewIotDevice: null,
      shouldConfirmUnlink: false
    };
  },
  [MANAGE_ENERGY_MANAGER_DEVICE_SET_EM_EQ_ID]: (
    state,
    { energyManagerEquipmentId }
  ) => {
    return {
      ...state,
      energyManagerEquipmentId,
      pairRequest: initialReqState,
      unpairRequest: initialReqState,
      getRequest: initialReqState
    };
  },
  [MANAGE_ENERGY_MANAGER_DEVICE_SHOULD_CONFIRM_UNLINK]: (
    state,
    { shouldConfirmUnlink }
  ) => {
    return { ...state, shouldConfirmUnlink };
  },
  [MANAGE_ENERGY_MANAGER_DEVICE_SET_NEW_IOT_DEVICE]: (
    state,
    { pendingNewIotDevice }
  ) => {
    return { ...state, pendingNewIotDevice };
  },
  [MANAGE_ENERGY_MANAGER_DEVICE_UPDATE_PAIR_REQUEST]: (state, action) => {
    return { ...state, pairRequest: updateReqState(state.pairRequest, action) };
  },
  [MANAGE_ENERGY_MANAGER_DEVICE_UPDATE_UNPAIR_REQUEST]: (state, action) => {
    return {
      ...state,
      unpairRequest: updateReqState(state.unpairRequest, action)
    };
  },
  [MANAGE_ENERGY_MANAGER_DEVICE_UPDATE_GET_REQUEST]: (state, action) => {
    let iotDevice = state.iotDevice;

    if (action.state === REQ_STATE_SUCCESS) {
      iotDevice = action.payload.device;
    } else if (action.state === REQ_STATE_ERROR) {
      iotDevice = null;
    }

    return {
      ...state,
      iotDevice,
      getRequest: updateReqState(state.getRequest, action)
    };
  },
  [MANAGE_ENERGY_MANAGER_DEVICE_SET_SHOW_DIALOG]: (
    state,
    { showEnergyManagerDialog }
  ) => {
    return { ...state, showEnergyManagerDialog };
  }
});

const _updateIdAction = createAction(
  MANAGE_ENERGY_MANAGER_DEVICE_SET_EM_EQ_ID,
  'energyManagerEquipmentId'
);
const _updateNewDeviceAction = createAction(
  MANAGE_ENERGY_MANAGER_DEVICE_SET_NEW_IOT_DEVICE,
  'pendingNewIotDevice'
);
const _setConfirmUnlinkAction = createAction(
  MANAGE_ENERGY_MANAGER_DEVICE_SHOULD_CONFIRM_UNLINK,
  'shouldConfirmUnlink'
);

const _handlePairStateChange = (
  contextSettings: ApiContextSettings,
  dispatch: AdminDispatch,
  getState: AdminGetState
) => {
  const provisionDevice = getState().provisionDevice;
  dispatch(ProvisioningActions.notifyDeviceDataChanged());
  // Force reload of data
  dispatch(
    ProvisionDeviceActions.setEnergyManagerEquipmentId(
      contextSettings,
      provisionDevice.energyManagerEquipmentId,
      true
    )
  );
};

export const ProvisionDeviceActions = {
  showEnergyManagerDialog: createAction(
    MANAGE_ENERGY_MANAGER_DEVICE_SET_SHOW_DIALOG,
    'showEnergyManagerDialog'
  ),
  setShouldConfirmUnlink: (shouldConfirmUnlink: boolean) => {
    return (dispatch: AdminDispatch) => {
      dispatch(_setConfirmUnlinkAction(shouldConfirmUnlink));
    };
  },
  confirmUnlink: (contextSettings: ApiContextSettings) => {
    return async (dispatch: AdminDispatch, getState: AdminGetState) => {
      let { provisionDevice } = getState();

      let newDevice = { ...provisionDevice.iotDevice.ioTDevice };
      newDevice.deviceId = null;

      await performAsyncRequestWithStateChanges(
        dispatch,
        API.Admin.IoTDevices.updateDevice(contextSettings, newDevice),
        MANAGE_ENERGY_MANAGER_DEVICE_UPDATE_UNPAIR_REQUEST
      );

      provisionDevice = getState().provisionDevice;

      if (provisionDevice.unpairRequest.state === REQ_STATE_SUCCESS) {
        _handlePairStateChange(contextSettings, dispatch, getState);
      }
    };
  },
  confirmNewDevice: (
    contextSettings: ApiContextSettings,
    copySettings: boolean
  ) => {
    return async (dispatch: AdminDispatch, getState: AdminGetState) => {
      let { provisionDevice } = getState();
      const { iotDevice, energyManagerEquipmentId } = provisionDevice;

      await performAsyncRequestWithStateChanges(
        dispatch,
        async () => {
          const deviceInfo = await API.Admin.Devices.getDeviceInfo(
            contextSettings,
            energyManagerEquipmentId
          );

          const newDevice = {
            ...provisionDevice.pendingNewIotDevice,
            deviceId: deviceInfo.deviceId
          };

          if (iotDevice != null && copySettings) {
            return await API.Admin.IoTDevices.switchDevices(
              contextSettings,
              iotDevice.ioTDevice.id,
              provisionDevice.pendingNewIotDevice.id
            );
          } else if (iotDevice != null) {
            let oldDevice = { ...iotDevice.ioTDevice };
            oldDevice.deviceId = null;
            await API.Admin.IoTDevices.updateDevice(contextSettings, oldDevice);
            return await API.Admin.IoTDevices.updateDevice(
              contextSettings,
              newDevice
            );
          }

          return await API.Admin.IoTDevices.updateDevice(
            contextSettings,
            newDevice
          );
        },
        MANAGE_ENERGY_MANAGER_DEVICE_UPDATE_PAIR_REQUEST
      );

      provisionDevice = getState().provisionDevice;

      if (provisionDevice.pairRequest.state === REQ_STATE_SUCCESS) {
        _handlePairStateChange(contextSettings, dispatch, getState);
      }
    };
  },
  setNewDeviceToConfirm: (pendingNewIotDevice: IoTDeviceResponseModel) =>
    _updateNewDeviceAction(pendingNewIotDevice),
  setEnergyManagerEquipmentId: (
    contextSettings: ApiContextSettings,
    energyManagerEquipmentId: string,
    forceReload = false
  ) => {
    return async (dispatch: AdminDispatch, getState: AdminGetState) => {
      const state = getState().provisionDevice;

      if (
        state.energyManagerEquipmentId === energyManagerEquipmentId &&
        !forceReload
      ) {
        return;
      }

      dispatch(_updateIdAction(energyManagerEquipmentId));

      if (energyManagerEquipmentId == null) {
        return;
      }

      await performAsyncRequestWithStateChanges(
        dispatch,
        async () => {
          const deviceInfo = await API.Admin.Devices.getDeviceInfo(
            contextSettings,
            energyManagerEquipmentId
          );
          const devices = await API.Admin.IoTDevices.getIoTDeviceByDeviceIds(
            contextSettings,
            [deviceInfo.deviceId]
          );
          return {
            deviceInfo,
            device: devices.length === 0 ? null : devices[0]
          };
        },
        MANAGE_ENERGY_MANAGER_DEVICE_UPDATE_GET_REQUEST
      );
    };
  }
};
