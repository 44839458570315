import React from 'react';
import { GroupBase, StylesConfig } from 'react-select';
import { AsyncPaginate, AsyncPaginateProps } from 'react-select-async-paginate';
import zindex from '../styles/variables/zindex';
import { typedMemo } from '../utils/typescriptUtils';
import { GenericSelectOption } from './Select';

type AsyncSelectProps<
  ValueType = GenericSelectOption,
  IsMulti extends boolean = false
> = AsyncPaginateProps<ValueType, GroupBase<ValueType>, object, IsMulti> & {
  menuPortalTarget?: HTMLElement;
  /**
   * Default placement of the menu in relation to the control. 'auto' will flip when there isn't enough space below the control.
   */
  disabled?: boolean;
  isDisabled?: boolean;
  additionalStyles?: StylesConfig<ValueType, IsMulti, GroupBase<ValueType>>;
};

function AsyncSelect<
  ValueType = GenericSelectOption,
  IsMulti extends boolean = false
>({
  menuPortalTarget,
  disabled,
  isDisabled,
  additionalStyles = null,
  ...otherProps
}: AsyncSelectProps<ValueType, IsMulti>) {
  let _disabled = isDisabled == null ? disabled : isDisabled;
  const _menuPortalTarget =
    menuPortalTarget !== undefined ? menuPortalTarget : document.body;

  return (
    <AsyncPaginate
      menuPortalTarget={_menuPortalTarget}
      tabSelectsValue={false}
      classNamePrefix="Select"
      menuShouldScrollIntoView={false}
      isDisabled={_disabled}
      styles={{
        ...additionalStyles,
        menuPortal: (base) => ({ ...base, zIndex: zindex.modalOverlayZIndex })
      }}
      {...otherProps}
      defaultOptions
    />
  );
}

export default typedMemo(AsyncSelect);
