/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ListTenantUsersResponse {
  /** Gets the tenant users. */
  tenantUsers?: TenantUserModel[] | null;
  /**
   * Gets the total size of all the tenant users.
   * @format int32
   */
  totalSize?: number;
}

/** This class is a custom "view model" for the TenantUser entity. */
export interface TenantUserModel {
  /** The tenant identifier. This is a valid Guid. */
  tenantId?: string | null;
  /** The user identifier. This is a valid Guid. It will not be set when FE adds a new user to a tenant. */
  userId?: string | null;
  /** The email address and user name. This can't be modified! */
  email?: string | null;
  /** Output only. The display name of a user. */
  displayName?: string | null;
  /** An optional string describing the tenant user. */
  description?: string | null;
  /** The roles that the user has been assigned in this tenant. */
  roles?: IdNamePairModel[] | null;
  /** If the login is federated to another AD. */
  isFederated?: boolean;
}

/** A generic class that holds an identifier and a name for some resource. */
export interface IdNamePairModel {
  /** The item identifier. This is a valid Guid. */
  id?: string | null;
  /** The name of the item. */
  name?: string | null;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface CreateOrUpdateTenantUserRequest {
  tenantUser?: TenantUserModel | null;
}

export interface ListTenantsResponse {
  /**
   * The total number of items that exists.
   * @format int32
   */
  totalSize?: number;
  /** The tenants for the requested page. */
  tenants?: TenantModel[] | null;
}

/** This class is a custom "view model" for the Tenant entity. */
export interface TenantModel {
  /** The tenant identifier. This is a valid Guid. */
  id?: string | null;
  /** The name of the tenant. */
  name?: string | null;
  /** An optional string describing the tenant. */
  description?: string | null;
  /**
   * The latitude of a tenant.
   * @format double
   */
  latitude?: number | null;
  /**
   * The longitude of a tenant.
   * @format double
   */
  longitude?: number | null;
  /** The IANA time zone of where the tenant is located. */
  timeZone?: string | null;
  /** Environment this tenant belongs to. Naming will be derived from Microsoft Azure region short name (i.e. WEU, which stands for West Europe). */
  environment?: string | null;
  /** Indicates if the current tenant is deactivated or not. */
  isDeactivated?: boolean;
  /** The identifiers and names of the resources that the tenant has been assigned. */
  resources?: IdNamePairModel[] | null;
  /** The name of the IoT hub host. */
  iotHubHostName?: string | null;
}

export interface CreateTenantRequest {
  tenant?: TenantModel | null;
}

export interface UpdateTenantRequest {
  tenant?: TenantModel | null;
}

export interface TenantListTenantUsersParams {
  /** The filter to apply when getting the users for the tenant. Leave it empty if no filter is wanted. */
  filter?: string | null;
  /**
   * The page number to retrieve. The value is 0-based so send 0 for the first page.
   *             If no value is passed then the first page is returned.
   * @format int32
   */
  pageNumber?: number | null;
  /**
   * Size of the page. If no value is passed then all items are returned.
   * @format int32
   */
  pageSize?: number | null;
  /** The sort order of the result. If no value is supplied then the result will be sorted on "Email ascending". */
  orderBy?: string | null;
}

export interface TenantsListTenantsParams {
  /** The filter to apply when getting the tenants. Leave it empty if no filter is wanted. */
  Filter?: string | null;
  /**
   * The page number to retrieve. The value is 0-based so send 0 for the first page.
   * If no value is passed then the first page is returned.
   * @format int32
   */
  PageNumber?: number | null;
  /**
   * The maximum number of tenants to return in the response. If no value is passed then all items are returned.
   * @format int32
   */
  PageSize?: number | null;
  /**
   * The sort order of the result. If no value is supplied then the result will be sorted on "name ascending".
   * The string value should follow SQL syntax: i.e. a field name and a direction.
   * For example: "foo". The default sorting order is ascending. To specify descending order for a
   * field, a suffix " desc" should be appended to the field name. For example: "foo desc".
   */
  OrderBy?: string | null;
}

export interface TenantUsersListTenantUsersParams {
  /** The filter to apply when getting the users for the tenant. Leave it empty if no filter is wanted. */
  filter?: string | null;
  /**
   * The page number to retrieve. The value is 0-based so send 0 for the first page.
   *             If no value is passed then the first page is returned.
   * @format int32
   */
  pageNumber?: number | null;
  /**
   * Size of the page. If no value is passed then all items are returned.
   * @format int32
   */
  pageSize?: number | null;
  /** The sort order of the result. If no value is supplied then the result will be sorted on "Email ascending". */
  orderBy?: string | null;
  /** The tenant identifier. */
  tenantId: string | null;
}

/**
 * @title Identity API
 * @version v1
 * @baseUrl https://app-ec-identity-test-weu-001-hfkk.azurewebsites.net
 */

import { APIGenType } from './APIGenType';
import {
  ContentType,
  Method,
  apiEndpoint,
  apiEndpointEmptyUsingQueryParams,
  apiEndpointEmptyWithPath,
  apiEndpointEmptyWithPathUsingQueryParams,
  apiEndpointWithPath
} from './APIUtils';

const apiTitle = APIGenType.IdentityServiceAPIGen;

export interface TenantGetTenantUserPath {
  /** The user identifier. */
  userId: string | null;
}

export interface TenantDeleteTenantUserPath {
  /** The user identifier. */
  userId: string | null;
}

export interface TenantsGetTenantPath {
  /** The tenant identifier. */
  tenantId: string | null;
}

export interface TenantsDeleteTenantPath {
  /** The tenant identifier. */
  tenantId: string | null;
}

export interface TenantUsersListTenantUsersPath {
  /** The tenant identifier. */
  tenantId: string | null;
}

export interface TenantUsersCreateOrUpdateTenantUserPath {
  /** The tenant identifier. */
  tenantId: string | null;
}

export interface TenantUsersGetTenantUserPath {
  /** The tenant identifier. */
  tenantId: string | null;
  /** The user identifier. */
  userId: string | null;
}

export interface TenantUsersDeleteTenantUserPath {
  /** The tenant identifier. */
  tenantId: string | null;
  /** The user identifier. */
  userId: string | null;
}

const IdentityServiceAPIGen = {
  Tenant: {
    /**
     * @summary Gets all the users that belongs to the current tenant in a paged manner.
     */
    listTenantUsers: apiEndpointEmptyUsingQueryParams<
      TenantListTenantUsersParams,
      ListTenantUsersResponse,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/tenant/users`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Updates an existing tenant user or creates a new if it doesn't exist.
If it doesn't exist in B2C then a new user will be created there also.
*/
    createOrUpdateTenantUser: apiEndpoint<
      CreateOrUpdateTenantUserRequest,
      TenantUserModel,
      ProblemDetails
    >(
      Method.PUT,
      `/api/v1/tenant/users`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the tenant user with the supplied user identifier for the current tenant.
     */
    getTenantUser: apiEndpointEmptyWithPath<
      TenantUserModel,
      ProblemDetails,
      TenantGetTenantUserPath
    >(
      Method.GET,
      ({ userId }: TenantGetTenantUserPath) => `/api/v1/tenant/users/${userId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Deletes the tenant user with the supplied user identifier for the current tenant.
This does NOT remove the user from Azure B2C!
*/
    deleteTenantUser: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      TenantDeleteTenantUserPath
    >(
      Method.DELETE,
      ({ userId }: TenantDeleteTenantUserPath) =>
        `/api/v1/tenant/users/${userId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Tenants: {
    /**
 * @summary Gets all the existing tenants in a paged manner.
Leave all the parameters empty if you want a single page with all the
existing data. A simple filter can also be applied to limit the returned
result.
*/
    listTenants: apiEndpointEmptyUsingQueryParams<
      TenantsListTenantsParams,
      ListTenantsResponse,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/tenants`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Creates a new tenant.
     */
    createTenant: apiEndpoint<CreateTenantRequest, TenantModel, ProblemDetails>(
      Method.POST,
      `/api/v1/tenants`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Updates an existing tenant.
     */
    updateTenant: apiEndpoint<UpdateTenantRequest, TenantModel, ProblemDetails>(
      Method.PUT,
      `/api/v1/tenants`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the tenant with the supplied identifier.
     */
    getTenant: apiEndpointEmptyWithPath<
      TenantModel,
      ProblemDetails,
      TenantsGetTenantPath
    >(
      Method.GET,
      ({ tenantId }: TenantsGetTenantPath) => `/api/v1/tenants/${tenantId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Deletes the tenant with the supplied identifier including all the users.
     */
    deleteTenant: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      TenantsDeleteTenantPath
    >(
      Method.DELETE,
      ({ tenantId }: TenantsDeleteTenantPath) => `/api/v1/tenants/${tenantId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  TenantUsers: {
    /**
     * @summary Gets all the users that belongs to the supplied tenant in a paged manner.
     */
    listTenantUsers: apiEndpointEmptyWithPathUsingQueryParams<
      Omit<
        TenantUsersListTenantUsersParams,
        keyof TenantUsersListTenantUsersPath
      >,
      ListTenantUsersResponse,
      ProblemDetails,
      TenantUsersListTenantUsersPath
    >(
      Method.GET,
      ({ tenantId }: TenantUsersListTenantUsersPath) =>
        `/api/v1/tenants/${tenantId}/users`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Updates an existing tenant user or creates a new if it doesn't exist.
If it doesn't exist in B2C then a new user will be created there also.
*/
    createOrUpdateTenantUser: apiEndpointWithPath<
      CreateOrUpdateTenantUserRequest,
      TenantUserModel,
      ProblemDetails,
      TenantUsersCreateOrUpdateTenantUserPath
    >(
      Method.PUT,
      ({ tenantId }: TenantUsersCreateOrUpdateTenantUserPath) =>
        `/api/v1/tenants/${tenantId}/users`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the tenant user with the supplied identifiers.
     */
    getTenantUser: apiEndpointEmptyWithPath<
      TenantUserModel,
      ProblemDetails,
      TenantUsersGetTenantUserPath
    >(
      Method.GET,
      ({ tenantId, userId }: TenantUsersGetTenantUserPath) =>
        `/api/v1/tenants/${tenantId}/users/${userId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Deletes the tenant user matching the supplied identifiers.
This does NOT remove the user from Azure B2C!
*/
    deleteTenantUser: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      TenantUsersDeleteTenantUserPath
    >(
      Method.DELETE,
      ({ tenantId, userId }: TenantUsersDeleteTenantUserPath) =>
        `/api/v1/tenants/${tenantId}/users/${userId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  }
};

export default IdentityServiceAPIGen;
