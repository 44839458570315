import { LOCATION_CHANGE } from 'ecto-common/lib/actions/actionTypes';
import { useLocation } from 'react-router';

export default function locationChange(
  location: ReturnType<typeof useLocation>
) {
  return {
    type: LOCATION_CHANGE,
    payload: location.pathname
  };
}
