import React, { memo } from 'react';
import Icons from 'ecto-common/lib/Icons/Icons';
import NetmoreAPI from 'ecto-common/lib/utils/NetmoreAPI';
import IntegrationAccountList from 'js/components/IntegrationAccounts/Generic/IntegrationAccountList';
import T from 'ecto-common/lib/lang/Language';
import { AccountResponse } from 'ecto-common/lib/API/NetmoreAPIGen';

const columns = [
  {
    label: T.admin.integration.netmore.form.username,
    dataKey: 'username',
    linkColumn: true,
    minWidth: 120,
    dataFormatter: (value: string) => (
      <>
        <Icons.User /> {value}{' '}
      </>
    )
  },
  {
    label: T.admin.integration.netmore.form.description,
    dataKey: 'description',
    minWidth: 110
  }
];

interface NetmoreAccountsListProps {
  onSelectAccount?(account: AccountResponse): void;
  reloadAccounts?: number;
  pageSize?: number;
  isOpen?: boolean;
}

const NetmoreAccountsList = ({
  onSelectAccount,
  reloadAccounts,
  pageSize,
  isOpen
}: NetmoreAccountsListProps) => {
  return (
    <IntegrationAccountList<AccountResponse>
      onSelectAccount={onSelectAccount}
      reloadAccounts={reloadAccounts}
      pageSize={pageSize}
      isOpen={isOpen}
      columns={columns}
      promise={NetmoreAPI.getAccountsAll}
      sortColumn="username"
    />
  );
};

NetmoreAccountsList.defaultProps = {
  pageSize: 20,
  isOpen: true
};

export default memo(NetmoreAccountsList);
