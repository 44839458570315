import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useContext,
  Dispatch,
  SetStateAction
} from 'react';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import AddUserDialog from 'js/components/ManageUsers/AddUserDialog';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import IdentityServiceAPI from 'ecto-common/lib/utils/IdentityServiceAPI';
import { RoleOptions } from 'ecto-common/lib/constants';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import PagedDataTable, {
  PagedDataTableDataType,
  PagedDataTableErrorResult,
  totalSizeToTotalPages
} from 'ecto-common/lib/PagedDataTable/PagedDataTable';
import {
  calculateDataTableMinHeight,
  standardColumns
} from 'ecto-common/lib/utils/dataTableUtils';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import {
  IdNamePairModel,
  TenantModel,
  TenantUserModel
} from 'ecto-common/lib/API/IdentityServiceAPIGen';

export const useManageUsersPaging = (pageSize: number) => {
  const [paging, setPaging] = useState(() => ({
    sortColumn: null,
    sortOrder: null,
    pageNumber: 0,
    pageSize,
    filter: null
  }));

  const setPageNumber = useCallback((pageNumber: number) => {
    setPaging((oldPaging) => ({ ...oldPaging, pageNumber }));
  }, []);

  const setSearchFilter = useCallback((filter: string) => {
    setPaging((oldPaging) => ({ ...oldPaging, pageNumber: 0, filter }));
  }, []);

  return { paging, setSearchFilter, setPageNumber };
};

interface ManageUsersProps {
  newUser?: object;
  setNewUser: Dispatch<SetStateAction<TenantUserModel>>;
  tenantId?: string;
  paging?: ManageUsersPagingData;
  setPageNumber: (newPage: number) => void;
}

type ManageUsersPagingData = {
  pageSize: number;
  pageNumber: number;
};

/**
 * Renders a list view of all users.
 * In this view you can create and edit users
 */
const ManageUsers = ({
  newUser,
  setNewUser,
  paging,
  tenantId,
  setPageNumber
}: ManageUsersProps) => {
  const [users, setUsers] = useState<PagedDataTableDataType<TenantUserModel>>({
    totalPages: 0,
    hasError: false,
    result: []
  });

  const [userToDelete, setUserToDelete] = useState<TenantUserModel>(null);
  const { availableTenantRoles } = useContext(TenantContext);

  const [isLoadingResources, loadResources] = usePromiseCall({
    promise: IdentityServiceAPI.TenantUsers.getTenantUsers,
    onSuccess: (_users) => {
      setUsers({
        totalPages: totalSizeToTotalPages(_users.totalSize, paging.pageSize),
        hasError: false,
        result: _users.tenantUsers
      });
    },
    onError: () => {
      setUsers(PagedDataTableErrorResult);
    },
    initiallyLoading: true
  });

  const _loadResources = useCallback(() => {
    loadResources(tenantId, { ...paging, tenantId });
  }, [loadResources, tenantId, paging]);

  const [isUpdatingUser, updateUser] = usePromiseCall({
    promise: IdentityServiceAPI.TenantUsers.addOrUpdateTenantUser,
    onSuccess: () => {
      _loadResources();
    },
    onError: (_err, _tenantId, user) => {
      toastStore.addErrorToastForUpdatedItem(user.email, false);
    }
  });

  const [isDeletingUser, deleteUser] = usePromiseCall({
    promise: IdentityServiceAPI.TenantUsers.deleteTenantUser,
    onSuccess: () => {
      setUserToDelete(null);
      toastStore.addSuccessToast(T.admin.users.request.deleteduser);
      _loadResources();
    },
    onError: (_err, _tenantId, _tenantUser) => {
      toastStore.addErrorToastForDeletedItem(_tenantUser.email);
    }
  });

  const cancelDeleteUser = useCallback(() => {
    setUserToDelete(null);
  }, []);

  const confirmDeleteUser = useCallback(() => {
    deleteUser(tenantId, userToDelete);
  }, [tenantId, userToDelete, deleteUser]);

  useEffect(() => {
    _loadResources();
  }, [_loadResources]);

  const onDelete = useCallback((user: TenantModel) => {
    setUserToDelete(user);
  }, []);

  const updateRole = useCallback(
    (user: TenantUserModel, newRole: GenericSelectOption<string>) => {
      const fullRoleObject = _.find(availableTenantRoles, [
        'name',
        newRole.value
      ]);
      updateUser(tenantId, { ...user, roles: [fullRoleObject] });
    },
    [tenantId, updateUser, availableTenantRoles]
  );

  const columns: DataTableColumnProps<TenantUserModel>[] = useMemo(
    () => [
      {
        label: T.admin.users.name,
        dataKey: 'displayName',
        width: 1,
        dataFormatter: (value: string) => (
          <>
            <Icons.User />
            {value}
          </>
        )
      },
      {
        label: T.admin.users.username,
        dataKey: 'email',
        width: 1,
        flexGrow: 1
      },
      {
        label: T.admin.users.role,
        dataKey: 'roles',
        width: 170,
        flexGrow: 0,
        flexShrink: 0,
        dataFormatter: (
          userRoles: IdNamePairModel[],
          user: TenantUserModel
        ) => {
          const relevantRole = _.head(userRoles);
          const selectedOption =
            _.find(RoleOptions, ['value', relevantRole?.name]) ?? null;
          return (
            <Select<GenericSelectOption<string>, false>
              options={RoleOptions}
              value={selectedOption}
              onChange={(newValue: GenericSelectOption<string>) => {
                updateRole(user, newValue);
              }}
            />
          );
        }
      },
      ...standardColumns({ onDelete })
    ],
    [onDelete, updateRole]
  );

  const minHeight = calculateDataTableMinHeight({
    pageSize: paging.pageSize,
    withSelect: true
  });

  return (
    <>
      <PagedDataTable<TenantUserModel>
        pageSize={paging.pageSize}
        page={paging.pageNumber}
        onPageChange={setPageNumber}
        isLoading={isLoadingResources || isUpdatingUser || isDeletingUser}
        data={users}
        columns={columns}
        minHeight={minHeight}
        errorText={T.admin.users.error.loadfailed}
        useAllAvailableHeight
      />
      <AddUserDialog
        onUserChanged={_loadResources}
        setEditUser={setNewUser}
        editUser={newUser}
        isLoadingResources={isLoadingResources}
        tenantId={tenantId}
      />
      <ConfirmDeleteDialog
        isLoading={isDeletingUser}
        isOpen={userToDelete != null}
        onModalClose={cancelDeleteUser}
        onDelete={confirmDeleteUser}
        itemName={userToDelete?.displayName}
      />
    </>
  );
};

export default React.memo(ManageUsers);
