import React, { useCallback, useMemo } from 'react';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import classNames from 'classnames';
import ColorPickerDialog from 'ecto-common/lib/ColorPickerDialog/ColorPickerDialog';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import styles from './ModelEditorColor.module.css';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import { RemoveIcon } from 'ecto-common/lib/Icon/index';
import T from 'ecto-common/lib/lang/Language';
import { ColorResult } from 'react-color';
import { ModelDefinitionInternal } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';

export type ColorModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object
> = {
  modelType: typeof ModelType.COLOR;
  noTitle?: boolean;
  maxWidth?: number;
  minWidth?: number;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, string>;

type ModelEditorColorProps = ModelEditorProps & {
  model: ColorModelDefinition<object, object>;
};

const ModelEditorColor = ({
  model,
  updateItem,
  rawValue
}: ModelEditorColorProps) => {
  const [pickerVisible, showPicker, hidePicker] = useDialogState(false);
  const color = rawValue || model.placeholder;
  const onChange = useCallback(
    (value: ColorResult, isCustomColor: boolean) => {
      updateItem(value.hex);

      if (!isCustomColor) {
        hidePicker();
      }
    },
    [updateItem, hidePicker]
  );

  const removeColor: React.MouseEventHandler<SVGSVGElement> = useCallback(
    (e) => {
      e.stopPropagation();
      updateItem(null);
    },
    [updateItem]
  );

  const content = useMemo(() => {
    return (
      <>
        <div
          onClick={showPicker}
          className={classNames(
            styles.colorBar,
            color == null && styles.noColor
          )}
          style={{
            background: color,
            minWidth: model.minWidth,
            maxWidth: model.maxWidth
          }}
        >
          {color == null && (
            <div className={styles.infoText}> {T.modeleditor.selectcolor}</div>
          )}

          <div className={styles.flex} />
          {color != null && (
            <RemoveIcon className={styles.removeIcon} onClick={removeColor} />
          )}
        </div>
      </>
    );
  }, [showPicker, removeColor, color, model.maxWidth, model.minWidth]);

  return (
    <>
      <ColorPickerDialog
        isOpen={pickerVisible}
        onClose={hidePicker}
        onChange={onChange}
        color={color}
      />
      <div>
        {model.noTitle ? (
          content
        ) : (
          <KeyValueGeneric keyText={model.label}>{content}</KeyValueGeneric>
        )}
      </div>
    </>
  );
};

export default React.memo(ModelEditorColor);
