import React, { CSSProperties, PureComponent } from 'react';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';
import { autobind } from 'core-decorators';
import Tooltip from '../Tooltip/Tooltip';
import styles from './RowCell.module.css';

interface RowCellProps {
  children?: React.ReactNode;
  className?: string;
  tooltipEnabled: boolean;
  selected?: boolean;
  truncateText?: boolean;
  style?: CSSProperties;
}

class RowCell extends PureComponent<RowCellProps> {
  //
  // Local variables
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  _ref: any = null;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  _domNode: any = null;

  //
  // Lifecycle

  componentDidMount() {
    if (this._ref) {
      this._domNode = findDOMNode(this._ref);
    }
  }

  //
  // Control

  @autobind
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  _setRef(ref: any) {
    this._ref = ref;
  }

  @autobind
  _tooltipVisibility() {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    const findOverflowingElement = (domNode: any): Element => {
      const overflowing = domNode.offsetWidth < domNode.scrollWidth;

      if (overflowing) {
        return domNode;
      }

      for (let childNode of domNode.childNodes) {
        const childOverflowing = findOverflowingElement(childNode);
        if (childOverflowing != null) {
          return childOverflowing;
        }
      }

      return null;
    };

    const elem = findOverflowingElement(this._domNode);

    return this.props.tooltipEnabled && this._domNode && elem != null;
  }

  //
  // Render function

  @autobind
  getText() {
    return this._domNode?.innerText ?? '';
  }

  render() {
    const { className, children, selected, truncateText, style } = this.props;

    const cssStyle = classNames(
      styles.rowCell,
      selected && styles.selected,
      className
    );

    if (!truncateText) {
      return (
        <div className={cssStyle} ref={this._setRef} style={style}>
          {children}
        </div>
      );
    }

    return (
      <Tooltip
        ref={this._setRef}
        className={classNames(cssStyle, styles.truncated)}
        text={this.getText}
        visibilityFn={this._tooltipVisibility}
        multiline
      >
        {children}
      </Tooltip>
    );
  }
}

export default RowCell;
