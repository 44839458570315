import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import { useUpdateModelFormInput } from 'ecto-common/lib/ModelForm/formUtils';
import { KeyValueFixedSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueFixedSelectableInput';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import Grid from 'ecto-common/lib/Grid/Grid';
import NetmoreAccountsList from 'js/components/IntegrationAccounts/NetmoreIntegration/NetmoreAccountsList';
import styles from './EditNetmoreTool.module.css';
import Toolbar from 'ecto-common/lib/Toolbar/Toolbar';
import ToolbarHeading from 'ecto-common/lib/Toolbar/ToolbarHeading';
import Icons from 'ecto-common/lib/Icons/Icons';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import NetmoreAPI from 'ecto-common/lib/utils/NetmoreAPI';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { buttonListColumn } from 'ecto-common/lib/utils/dataTableUtils';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import _ from 'lodash';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { DEFAULT_TABLE_PAGE_SIZE_MODAL } from 'ecto-common/lib/utils/constants';
import T from 'ecto-common/lib/lang/Language';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import ToolbarSearch from 'ecto-common/lib/Toolbar/ToolbarSearch';
import HelpPaths from 'ecto-common/help/tocKeys';
import { modelFormIsValid } from 'ecto-common/lib/ModelForm/validateForm';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import { CustomModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import {
  AccountResponse,
  AccountSensorResponse,
  ProviderResponse,
  SignalResponse
} from 'ecto-common/lib/API/NetmoreAPIGen';

const EMPTY_TABLE: SignalResponse[] = [];

const AccountModelEditor = ({
  rawValue,
  model,
  disabled,
  hasError,
  updateItem
}: CustomModelEditorProps) => {
  const [showingAccountModal, showAccountModal, hideAccountModal] =
    useDialogState(false);
  const [accountLookup, setAccountLookup] = useState<
    Record<string, AccountResponse>
  >({});

  const [isLoadingAccountByIds, loadAccountByIds] = usePromiseCall({
    promise: NetmoreAPI.getAccountsByIds,
    onSuccess: (accounts) => {
      setAccountLookup((oldAccountLookup) => ({
        ...oldAccountLookup,
        ..._.keyBy(accounts, 'id')
      }));
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.integration.generic.get.accounts.error);
    }
  });

  useEffect(() => {
    if (rawValue != null && accountLookup[rawValue] == null) {
      loadAccountByIds([rawValue]);
    }
  }, [rawValue, accountLookup, loadAccountByIds]);

  const onSelectAccount = useCallback(
    (account: AccountResponse) => {
      setAccountLookup((oldAccountLookup) => ({
        ...oldAccountLookup,
        [account.id]: account
      }));

      updateItem(account.id);
      hideAccountModal();
    },
    [updateItem, hideAccountModal]
  );

  return (
    <>
      <ActionModal
        disableCancel
        actionText={T.common.done}
        isOpen={showingAccountModal}
        onConfirmClick={hideAccountModal}
        onModalClose={hideAccountModal}
        title={T.admin.integration.netmore.account}
        headerIcon={Icons.User}
      >
        <NetmoreAccountsList
          isOpen={showingAccountModal}
          onSelectAccount={onSelectAccount}
          pageSize={DEFAULT_TABLE_PAGE_SIZE_MODAL}
        />
      </ActionModal>

      <KeyValueFixedSelectableInput
        isLoading={isLoadingAccountByIds}
        disabled={disabled}
        keyText={model.label}
        value={accountLookup[rawValue]?.username ?? ''}
        placeholder={model.placeholder}
        onClick={showAccountModal}
        hasError={hasError}
      />
    </>
  );
};

const models: ModelDefinition<ProviderResponse>[] = [
  {
    modelType: ModelType.TEXT,
    key: (input) => input.name,
    hasError: isNullOrWhitespace,
    label: T.admin.integration.netmore.form.name
  },
  {
    modelType: ModelType.TEXT,
    key: (input) => input.description,
    label: T.admin.integration.netmore.form.description
  },
  {
    modelType: ModelType.CUSTOM,
    render: (props, model) => <AccountModelEditor {...props} model={model} />,
    key: (input) => input.accountId,
    label: T.admin.integration.netmore.form.account,
    hasError: isNullOrWhitespace
  }
];

const columns = [
  {
    label: T.admin.integration.netmore.headers.euiserialnumber,
    dataKey: 'devEUI'
  },
  {
    label: T.admin.integration.netmore.headers.description,
    dataKey: 'description'
  }
];

interface EditNetmoreToolProps {
  item?: ProviderResponse;
  setItem: Dispatch<SetStateAction<ProviderResponse>>;
  isLoading?: boolean;
  editVisible?: boolean;
  hideEdit?(): void;
  save?(): void;
}

const EditNetmoreTool = ({
  item,
  setItem,
  isLoading,
  editVisible,
  hideEdit,
  save
}: EditNetmoreToolProps) => {
  const updater = useUpdateModelFormInput(setItem);
  const [availableSignals, setAvailableSignals] = useState<
    AccountSensorResponse[]
  >([]);
  const [searchText, setSearchText] = useState<string>(null);
  const lastAccountId = useRef(null);

  useEffect(() => {
    setSearchText(null);
    if (!editVisible) {
      setAvailableSignals([]);
    }
  }, [editVisible]);

  useEffect(() => {
    if (lastAccountId.current != null) {
      setItem((oldInput) => ({ ...oldInput, signals: [] }));
    }

    lastAccountId.current = item?.accountId;
  }, [item?.accountId, setItem]);

  const addSignal = useCallback(
    (signal: SignalResponse) => {
      setItem((oldInput) => ({
        ...oldInput,
        signals: (oldInput.signals ?? []).concat(signal)
      }));
    },
    [setItem]
  );

  const availableColumns = useMemo(() => {
    return [
      ...columns,
      buttonListColumn([
        {
          action: addSignal,
          icon: <Icons.Add />,
          enabled: (sensor) =>
            _.find(item?.signals, ['devEUI', sensor.devEUI]) == null
        }
      ])
    ];
  }, [addSignal, item?.signals]);

  const selectedColumns: DataTableColumnProps<SignalResponse>[] =
    useMemo(() => {
      return [
        ...columns,
        buttonListColumn([
          {
            action: (sensor) => {
              setItem((oldInput) => ({
                ...oldInput,
                signals: _.reject(oldInput.signals, ['devEUI', sensor.devEUI])
              }));
            },
            icon: <Icons.Delete />
          }
        ])
      ];
    }, [setItem]);

  const [isLoadingSensors, loadSensors] = usePromiseCall({
    promise: NetmoreAPI.getSensors,
    onSuccess: setAvailableSignals,
    onError: () => {
      toastStore.addErrorToast(
        T.admin.integration.netmore.error.failedtoloadsignals
      );
    }
  });

  useEffect(() => {
    if (item?.accountId != null) {
      // If we change account id, clear signals.
      setAvailableSignals([]);
      if (searchText?.length > 6) {
        loadSensors(item.accountId, searchText);
      }
    }
  }, [item?.accountId, loadSensors, searchText]);

  const isEditing = item?.id != null;
  const title = isEditing
    ? T.admin.integration.netmore.edit.tool
    : T.admin.integration.netmore.add.tool;
  const hasError =
    !modelFormIsValid(models, item, null) || _.isEmpty(item?.signals);

  return (
    <ActionModal
      isLoading={isLoading}
      onConfirmClick={save}
      headerIcon={isEditing ? Icons.Edit : Icons.Add}
      onModalClose={hideEdit}
      isOpen={editVisible}
      disableActionButton={hasError}
      large
      title={title}
      helpPath={HelpPaths.docs.admin.manage.equipment.tools.netmore}
    >
      <ModelForm models={models} input={item} onUpdateInput={updater}>
        {(renderChildModel) => {
          return (
            <div className={styles.body}>
              <Grid container justify="space-between" spacing={3}>
                <Grid item xs={6}>
                  {renderChildModel((input) => input.accountId)}
                </Grid>
                <Grid item xs={3} className={styles.borderLeft}>
                  {renderChildModel((input) => input.name)}
                </Grid>
                <Grid item xs={3}>
                  {renderChildModel((input) => input.description)}
                </Grid>
              </Grid>
              <Grid
                container
                justify="space-between"
                spacing={3}
                className={styles.signalsSection}
              >
                <Grid item xs={6}>
                  <Toolbar>
                    <ToolbarHeading
                      title={T.admin.integration.netmore.availablesignals}
                    />
                    <ToolbarFlexibleSpace />
                    <ToolbarSearch
                      value={searchText}
                      onChange={setSearchText}
                      disabled={item?.accountId == null}
                    />
                  </Toolbar>
                  <DataTable
                    isLoading={isLoadingSensors}
                    data={availableSignals}
                    columns={availableColumns}
                    noDataText={T.admin.integration.netmore.nodatatext}
                  />
                </Grid>
                <Grid item xs={6} className={styles.borderLeft}>
                  <Toolbar>
                    <ToolbarHeading
                      title={T.admin.integration.netmore.selectedsignals}
                    />
                  </Toolbar>
                  <DataTable
                    data={item?.signals ?? EMPTY_TABLE}
                    columns={selectedColumns}
                    noDataText={T.admin.integration.netmore.nosignalsadded}
                  />
                </Grid>
              </Grid>
            </div>
          );
        }}
      </ModelForm>
    </ActionModal>
  );
};

export default React.memo(EditNetmoreTool);
