import React, { useState, useCallback, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Prompt } from 'react-router';
import deepmerge from 'deepmerge';

import {
  getEnergyManagerEquipmentTypeId,
  getSignalsForEquipmentType
} from 'ecto-common/lib/utils/equipmentTypeUtils';
import T from 'ecto-common/lib/lang/Language';
import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';

import ModbusSettingDialog from 'js/components/ManageTemplates/ModbusTemplates/ModbusSettingDialog';
import {
  handleModbusConnectionChange,
  handleModbusPropertyChange,
  modbusConfigTypeToMode
} from 'js/components/ModbusLayout/ModbusEditUtils';
import EquipmentSignalsEditor from 'js/components/EditBuildingData/EquipmentSignalsEditor';
import AddEquipmentTemplate from 'js/components/EditBuildingData/AddEquipmentTemplate';
import EditEnergyManagerSettings from 'js/components/EditBuildingData/EditEnergyManagerSettings';

import ModbusConnectionDialog from 'js/components/EditBuildingData/ModbusConnectionDialog';
import styles from './EditEquipmentGroup.module.css';
import staticDataTableStyles from 'ecto-common/lib/StaticDataTable/StaticDataTable.module.css';
import UUID from 'uuidjs';
import { AlarmSignalGroupTemplateIds } from 'ecto-common/lib/utils/constants';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import {
  BuildingTemplateResponseModel,
  ConnectionModbusConfigTemplateResponseModel,
  EquipmentTypeResponseModel,
  SignalSettingsTemplateResponseModel,
  ToolType
} from 'ecto-common/lib/API/APIGen';
import {
  EquipmentTemplateResponseModel,
  EquipmentSignalTemplateOverrideResponseModel
} from 'ecto-common/lib/API/APIGen';

interface EditEquipmentGroupProps {
  initialEquipmentGroup?: BuildingTemplateResponseModel;
  onEquipmentGroupDataChanged?(template: BuildingTemplateResponseModel): void;
  editingTemplate?: boolean;
  editDescriptionInModbusDialog?: boolean;
  hasUnsavedChanges: boolean;
}

const EditEquipmentGroup = ({
  initialEquipmentGroup,
  onEquipmentGroupDataChanged,
  editingTemplate,
  editDescriptionInModbusDialog = true,
  hasUnsavedChanges
}: EditEquipmentGroupProps) => {
  const equipmentTypes = useAdminSelector(
    (state) => state.general.equipmentTypes
  );
  const signalTemplates = useAdminSelector(
    (state) => state.admin.signalTemplates
  );
  const toolTemplates = useAdminSelector(
    (state) => state.general.enums.toolTypes
  );
  const connectionModbusConfigDefaults = useAdminSelector(
    (state) => state.general.enums.connectionModbusConfigDefaults
  );
  const [dialogIsOpen, showDialog, hideDialog] = useDialogState(false);

  // Ensure that an Energy Manager is always at index 0
  const copyEquipmentGroup = useCallback(
    (
      _initialEquipmentGroup: BuildingTemplateResponseModel,
      _equipmentTypes: EquipmentTypeResponseModel[],
      _editingTemplate: boolean
    ) => {
      const _equipmentGroup = _.cloneDeep(_initialEquipmentGroup);

      const emEquipmentTypeId =
        getEnergyManagerEquipmentTypeId(_equipmentTypes);

      if (
        _editingTemplate &&
        (_equipmentGroup.equipmentTemplates.length === 0 ||
          _equipmentGroup.equipmentTemplates[0].equipmentTypeId !==
            emEquipmentTypeId)
      ) {
        _equipmentGroup.equipmentTemplates.unshift({
          name: 'Energy Manager',
          description: '',
          toolTypes: [],
          id: UUID.generate(),
          alarmSignalGroupTemplateId:
            AlarmSignalGroupTemplateIds.ENERGY_MANAGER_ALARMS,
          equipmentTypeId: emEquipmentTypeId,
          signalTemplateOverrides: []
        });
      }

      if (_equipmentGroup.connectionModbusConfig == null) {
        _equipmentGroup.connectionModbusConfig = {
          id: UUID.generate()
        };
      }

      return _equipmentGroup;
    },
    []
  );

  const [modbusEditEquipmentIndex, setModbusEditEquipmentIndex] = useState(-1);
  const [equipmentGroup, setEquipmentGroup] =
    useState<BuildingTemplateResponseModel>(() =>
      copyEquipmentGroup(initialEquipmentGroup, equipmentTypes, editingTemplate)
    );
  const [hasChanges, setHasChanges] = useState(false);
  const [modbusEditIndex, setModbusEditIndex] = useState<number>(null);

  const updateEquipmentGroup = useCallback(
    (callback: (group: BuildingTemplateResponseModel) => void) => {
      setEquipmentGroup((oldEquipmentGroup) => {
        const ret = { ...oldEquipmentGroup };
        callback(ret);
        return ret;
      });
      setHasChanges(true);
    },
    []
  );

  useEffect(() => {
    if (hasChanges) {
      onEquipmentGroupDataChanged(equipmentGroup);
    }
  }, [onEquipmentGroupDataChanged, equipmentGroup, hasChanges]);

  useEffect(() => {
    setEquipmentGroup((oldEquipmentGroup) => {
      if (
        oldEquipmentGroup?.equipmentTemplateGroupId !==
        initialEquipmentGroup?.equipmentTemplateGroupId
      ) {
        return copyEquipmentGroup(
          initialEquipmentGroup,
          equipmentTypes,
          editingTemplate
        );
      }

      return oldEquipmentGroup;
    });
  }, [
    initialEquipmentGroup,
    equipmentTypes,
    editingTemplate,
    copyEquipmentGroup
  ]);

  const onChangeConnectionProperty = useCallback(
    (key: string, value: unknown) => {
      updateEquipmentGroup((_equipmentGroup: BuildingTemplateResponseModel) => {
        const connection: ConnectionModbusConfigTemplateResponseModel = {
          ..._equipmentGroup.connectionModbusConfig
        };
        handleModbusConnectionChange(connection, key, value);
        _equipmentGroup.connectionModbusConfig = connection;
      });
    },
    [updateEquipmentGroup]
  );

  const navigateSignal = useCallback(
    (offset: number) => {
      const equipmentTemplate =
        equipmentGroup.equipmentTemplates[modbusEditEquipmentIndex];
      const signals = getSignalsForEquipmentType(
        equipmentTemplate.equipmentTypeId,
        equipmentTemplate.alarmSignalGroupTemplateId,
        signalTemplates
      );
      let index = modbusEditIndex;
      index = (index + offset) % signals.length;

      if (index < 0) {
        index = signals.length - 1;
      }

      setModbusEditIndex(index);
    },
    [
      equipmentGroup.equipmentTemplates,
      modbusEditEquipmentIndex,
      modbusEditIndex,
      signalTemplates
    ]
  );

  const onChangeSignalProperty = useCallback(
    (key: string, val: unknown) => {
      updateEquipmentGroup((_equipmentGroup: BuildingTemplateResponseModel) => {
        const equipmentTemplate = _.cloneDeep(
          _equipmentGroup.equipmentTemplates[modbusEditEquipmentIndex]
        );
        _equipmentGroup.equipmentTemplates[modbusEditEquipmentIndex] =
          equipmentTemplate;
        _equipmentGroup.equipmentTemplates =
          _equipmentGroup.equipmentTemplates.slice();

        // The signal we should update the template override object for
        const selectedSignal = getSignalsForEquipmentType(
          equipmentTemplate.equipmentTypeId,
          equipmentTemplate.alarmSignalGroupTemplateId,
          signalTemplates
        )[modbusEditIndex];

        // This is the object that has { signalTemplateOverride } property
        // for the selectedSignal
        let signalTemplateOverrideObject = _.find(
          equipmentTemplate.signalTemplateOverrides,
          (x) => x.signalTemplateId === selectedSignal.id
        );

        // Make sure we have a valid signal settings (...if we found a signalTemplateOverrideObject)
        _.defaults(signalTemplateOverrideObject, {
          signalTemplateOverride: { signalSettings: {} }
        });

        // Get modbus mode, or fallback to connection default
        const { connectionModbusConfig } = _equipmentGroup;

        const configType = modbusConfigTypeToMode(
          _.get(
            connectionModbusConfig,
            'modbusMode',
            connectionModbusConfigDefaults.modbusMode
          )
        );

        // Get current signalSettings, or fallback to empty
        const signalSettings = _.get(
          signalTemplateOverrideObject,
          ['signalTemplateOverride', 'signalSettings', configType],
          {}
        );

        // merge selected signals modbus with template override modbus
        const modbus = handleModbusPropertyChange(signalSettings, key, val);

        if (signalTemplateOverrideObject) {
          signalTemplateOverrideObject.signalTemplateOverride.signalSettings = {
            ...signalTemplateOverrideObject.signalTemplateOverride
              .signalSettings,
            [configType]: modbus
          };
        } else {
          equipmentTemplate.signalTemplateOverrides.push({
            signalTemplateId: selectedSignal.id,
            id: UUID.generate(),
            signalTemplateOverride: {
              id: UUID.generate(),
              signalSettings: {
                [configType]: modbus
              }
            }
          });
        }

        equipmentTemplate.signalTemplateOverrides =
          equipmentTemplate.signalTemplateOverrides.slice();
      });
    },
    [
      connectionModbusConfigDefaults.modbusMode,
      modbusEditEquipmentIndex,
      modbusEditIndex,
      signalTemplates,
      updateEquipmentGroup
    ]
  );

  const addEquipment = useCallback(
    (
      selectedEquipmentTypeId: string,
      selectedAlarmSignalGroupTemplateId: string,
      selectedToolTypes: ToolType[]
    ) => {
      updateEquipmentGroup((_equipmentGroup: BuildingTemplateResponseModel) => {
        _equipmentGroup.equipmentTemplates.push({
          signalTemplateOverrides: [],
          equipmentTypeId: selectedEquipmentTypeId,
          id: UUID.generate(),
          alarmSignalGroupTemplateId: selectedAlarmSignalGroupTemplateId,
          toolTypes: selectedToolTypes
        });
      });
    },
    [updateEquipmentGroup]
  );

  const removeEquipment = useCallback(
    (equipmentIdx: number) => {
      updateEquipmentGroup((_equipmentGroup: BuildingTemplateResponseModel) => {
        _equipmentGroup.equipmentTemplates.splice(equipmentIdx, 1);
      });
    },
    [updateEquipmentGroup]
  );

  const editModbus = useCallback((signalIdx: number, equipmentIdx: number) => {
    setModbusEditIndex(signalIdx);
    setModbusEditEquipmentIndex(equipmentIdx);
  }, []);

  const stopEditing = useCallback(() => {
    setModbusEditIndex(-1);
    setModbusEditEquipmentIndex(-1);
  }, []);

  const modbusMode = useMemo(() => {
    const configType = _.get(
      equipmentGroup.connectionModbusConfig,
      'modbusMode',
      connectionModbusConfigDefaults.modbusMode
    );
    return modbusConfigTypeToMode(configType);
  }, [
    connectionModbusConfigDefaults.modbusMode,
    equipmentGroup.connectionModbusConfig
  ]);

  const setNumericModbusValue = useCallback(
    (
      propertyName: 'modbusAddress' | 'defaultValue',
      equipmentIdx: number,
      signalTemplateId: string,
      value: string | number
    ) => {
      updateEquipmentGroup((_equipmentGroup: BuildingTemplateResponseModel) => {
        let convertedNumber = parseInt(value as string, 10);

        if (isNaN(convertedNumber)) {
          convertedNumber = null;
        }

        let equipment = Object.assign(
          {},
          _equipmentGroup.equipmentTemplates[equipmentIdx]
        );
        const settingIdx = _.findIndex(equipment.signalTemplateOverrides, {
          signalTemplateId
        });

        if (settingIdx === -1 && convertedNumber) {
          equipment.signalTemplateOverrides.push({
            signalTemplateId: signalTemplateId,
            id: UUID.generate(),
            signalTemplateOverride: {
              id: UUID.generate(),
              signalSettings: {
                [propertyName]: convertedNumber
              }
            }
          });
        } else if (settingIdx !== -1) {
          const signalTemplateOverride = _.cloneDeep(
            _.defaultTo(
              equipment.signalTemplateOverrides[settingIdx]
                .signalTemplateOverride,
              {
                id: UUID.generate()
              }
            )
          );

          const signalSettings: SignalSettingsTemplateResponseModel =
            _.defaultTo(signalTemplateOverride.signalSettings, {});

          if (convertedNumber) {
            signalSettings[propertyName] = convertedNumber;
          } else {
            delete signalSettings[propertyName];
          }
          signalTemplateOverride.signalSettings = signalSettings;
          equipment.signalTemplateOverrides[settingIdx].signalTemplateOverride =
            signalTemplateOverride;
        }

        _equipmentGroup.equipmentTemplates[equipmentIdx] = equipment;
        equipment.signalTemplateOverrides =
          equipment.signalTemplateOverrides.slice();
        _equipmentGroup.equipmentTemplates =
          _equipmentGroup.equipmentTemplates.slice();
      });
    },
    [updateEquipmentGroup]
  );

  const setAddressValue = useCallback(
    (equipmentIdx: number, signalTemplateId: string, value: string) => {
      setNumericModbusValue(
        'modbusAddress',
        equipmentIdx,
        signalTemplateId,
        value
      );
    },
    [setNumericModbusValue]
  );

  const setAlarmTemplateValue = useCallback(
    (equipmentIdx: number, value: string) => {
      updateEquipmentGroup((_equipmentGroup: BuildingTemplateResponseModel) => {
        let equipment = Object.assign(
          {},
          _equipmentGroup.equipmentTemplates[equipmentIdx]
        );
        const oldAlarmSignalTemplate =
          signalTemplates.alarmSignalTemplates.find(
            (e) =>
              e.alarmSignalGroupTemplateId ===
              equipment.alarmSignalGroupTemplateId
          );
        const oldAlarmTemplateIds = _.map(
          oldAlarmSignalTemplate?.alarmSignalTemplates,
          'id'
        );

        if (value !== equipment.alarmSignalGroupTemplateId) {
          equipment.signalTemplateOverrides = _.filter(
            equipment.signalTemplateOverrides,
            (x) => !oldAlarmTemplateIds.includes(x.signalTemplateId)
          );
          equipment.alarmSignalGroupTemplateId = value;
        }

        _equipmentGroup.equipmentTemplates[equipmentIdx] = equipment;
      });
    },
    [updateEquipmentGroup, signalTemplates]
  );

  const updateSettingProperty = useCallback(
    (
      equipmentIdx: number,
      signalTemplateId: string,
      property: keyof EquipmentSignalTemplateOverrideResponseModel,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any
    ) => {
      updateEquipmentGroup((_equipmentGroup: BuildingTemplateResponseModel) => {
        let equipment = Object.assign(
          {},
          _equipmentGroup.equipmentTemplates[equipmentIdx]
        );
        const settingIdx = _.findIndex(equipment.signalTemplateOverrides, {
          signalTemplateId
        });

        let setting = null;

        if (settingIdx !== -1) {
          setting = Object.assign(
            {},
            equipment.signalTemplateOverrides[settingIdx]
          );
        }

        if (!setting) {
          equipment.signalTemplateOverrides.push({
            signalTemplateId: signalTemplateId,
            id: UUID.generate(),
            [property]: value
          });
        } else if (setting) {
          setting[property] = value;
          equipment.signalTemplateOverrides[settingIdx] = setting;
        }

        _equipmentGroup.equipmentTemplates[equipmentIdx] = equipment;
        equipment.signalTemplateOverrides =
          equipment.signalTemplateOverrides.slice();
        _equipmentGroup.equipmentTemplates =
          _equipmentGroup.equipmentTemplates.slice();
      });
    },
    [updateEquipmentGroup]
  );

  const setDescriptionValue = useCallback(
    (equipmentIdx: number, signalTemplateId: string, value: string) => {
      updateSettingProperty(
        equipmentIdx,
        signalTemplateId,
        'description',
        value
      );
    },
    [updateSettingProperty]
  );

  const setNameValue = useCallback(
    (equipmentIdx: number, signalTemplateId: string, value: string) => {
      updateSettingProperty(equipmentIdx, signalTemplateId, 'name', value);
    },
    [updateSettingProperty]
  );

  const setEquipmentProperty = useCallback(
    (
      equipmentIdx: number,
      propertyName: keyof EquipmentTemplateResponseModel,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any
    ) => {
      updateEquipmentGroup((_equipmentGroup: BuildingTemplateResponseModel) => {
        let equipment = Object.assign(
          {},
          _equipmentGroup.equipmentTemplates[equipmentIdx]
        );
        _equipmentGroup.equipmentTemplates[equipmentIdx] = equipment;
        equipment[propertyName] = value;
      });
    },
    [updateEquipmentGroup]
  );

  const setToolTemplateValue = useCallback(
    (equipmentIdx: number, value: string[]) => {
      updateEquipmentGroup((_equipmentGroup: BuildingTemplateResponseModel) => {
        let equipment = Object.assign(
          {},
          _equipmentGroup.equipmentTemplates[equipmentIdx]
        );
        _equipmentGroup.equipmentTemplates[equipmentIdx] = equipment;
        equipment.toolTypes = value;
      });
    },
    [updateEquipmentGroup]
  );

  const selectedSignal = useMemo(() => {
    if (modbusEditEquipmentIndex !== -1) {
      const equipmentTemplate =
        equipmentGroup.equipmentTemplates[modbusEditEquipmentIndex];

      const _signals = getSignalsForEquipmentType(
        equipmentTemplate.equipmentTypeId,
        equipmentTemplate.alarmSignalGroupTemplateId,
        signalTemplates
      );
      const _selectedSignal = _.cloneDeep(_signals[modbusEditIndex]);

      const signalSettings = _.get(
        equipmentTemplate.signalTemplateOverrides.find(
          ({ signalTemplateId }) => signalTemplateId === _selectedSignal.id
        ),
        'signalTemplateOverride.signalSettings'
      );

      if (signalSettings) {
        _selectedSignal.signalSettings = deepmerge(
          _.defaultTo(_selectedSignal.signalSettings, {}),
          signalSettings
        );
      }

      return _selectedSignal;
    }

    return null;
  }, [
    equipmentGroup.equipmentTemplates,
    modbusEditEquipmentIndex,
    modbusEditIndex,
    signalTemplates
  ]);

  const { connectionModbusConfig } = equipmentGroup;
  const updateName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      updateEquipmentGroup((_equipmentGroup: BuildingTemplateResponseModel) => {
        _equipmentGroup.name = e.target.value;
      });
    },
    [updateEquipmentGroup]
  );

  return (
    <div className={styles.mainSection}>
      <Prompt when={hasUnsavedChanges} message={T.admin.form.unsavedstate} />

      <div className={styles.splitSections}>
        {editingTemplate && (
          <div className={styles.splitSection}>
            <KeyValueInput
              value={equipmentGroup.name}
              onChange={updateName}
              keyText={T.admin.equipmentgrouptemplates.name}
            />
          </div>
        )}

        <div className={classNames(styles.splitSection)}>
          <KeyValueGeneric keyText={T.admin.equipmentgroup.connections}>
            {equipmentGroup && (
              <EditEnergyManagerSettings
                connectionData={{ connectionModbusConfig }}
                onEditConnection={showDialog}
              />
            )}
          </KeyValueGeneric>
        </div>
      </div>

      <div>
        {_.map(equipmentGroup?.equipmentTemplates, (equipment, idx: number) => {
          return (
            <table
              key={idx}
              className={classNames(
                styles.equipmentTable,
                staticDataTableStyles.staticDataTable
              )}
            >
              <tbody>
                <EquipmentSignalsEditor
                  equipment={equipment}
                  index={idx}
                  equipmentIndex={_.findIndex(
                    _.filter(equipmentGroup?.equipmentTemplates, [
                      'equipmentTypeId',
                      equipment.equipmentTypeId
                    ]),
                    equipment
                  )}
                  modbusMode={modbusMode}
                  onSetNameValue={setNameValue}
                  onSetDescriptionValue={setDescriptionValue}
                  onSetAddressValue={setAddressValue}
                  onEditModbus={editModbus}
                  onSetEquipmentProperty={setEquipmentProperty}
                  onRemoveEquipment={removeEquipment}
                  onSetAlarmTemplateValue={setAlarmTemplateValue}
                  onSetToolTemplateValue={setToolTemplateValue}
                />
              </tbody>
            </table>
          );
        })}
      </div>

      {editingTemplate && (
        <AddEquipmentTemplate
          onAddEquipment={addEquipment}
          equipmentTypes={equipmentTypes}
          signalTemplates={signalTemplates}
          toolTemplates={toolTemplates}
        />
      )}

      <ModbusSettingDialog
        editDescriptionInModbusDialog={editDescriptionInModbusDialog}
        disableConfigTypeSelector
        onChangeConfigType={_.noop}
        modbusMode={modbusMode}
        selectedSignal={selectedSignal}
        isEditingSignal
        onPrevClicked={() => navigateSignal(-1)}
        onNextClicked={() => navigateSignal(1)}
        onChangeModbusProperty={onChangeSignalProperty}
        onModalClose={stopEditing}
        isOpen={selectedSignal != null}
        onChangeSignalProperty={() => {
          throw Error('Should not be used in this context!');
        }}
      />

      <ModbusConnectionDialog
        selectedConnection={connectionModbusConfig}
        onChangeProperty={onChangeConnectionProperty}
        onModalClose={hideDialog}
        isOpen={dialogIsOpen}
      />
    </div>
  );
};

export default EditEquipmentGroup;
