import React, {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
  useMemo,
  useContext,
  useRef
} from 'react';
import { applyToPoint, toCSS } from 'transformation-matrix';
import styles from './ProcessMapEditor.module.css';

import md5 from 'md5';
import { SymbolModelOrSystemItem } from 'ecto-common/lib/ProcessMaps/ProcessMapLibraryList';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import T from 'ecto-common/lib/lang/Language';
import DropdownMenu, {
  DropdownOpenFileFooter
} from 'ecto-common/lib/DropdownButton/DropdownMenu';
import Icons from 'ecto-common/lib/Icons/Icons';
import ToolbarMenuButton from 'ecto-common/lib/Toolbar/ToolbarMenuButton';
import ToolbarMenuDropdownButton from 'ecto-common/lib/Toolbar/ToolbarMenuDropdownButton';

import Toolbar from 'ecto-common/lib/Toolbar/Toolbar';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import ToolbarMenu from 'ecto-common/lib/Toolbar/ToolbarMenu';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import _ from 'lodash';
import ProcessMapObjectEditor from 'ecto-common/lib/ProcessMaps/ProcessMapObjectEditor';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import APIGen, {
  ProcessMapResponseModel,
  SignalProviderByNodeResponseModel
} from 'ecto-common/lib/API/APIGen';
import { useMutation } from '@tanstack/react-query';
import { Base64 } from 'js-base64';
import {
  ProcessMapDocument,
  ProcessMapObjectTypes,
  ProcessMapRect,
  ProcessMapRectResizeArea,
  processMapSvgDataUrlPrefix
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import ProcessMapViewV2, {
  ProcessMapViewV2Props
} from 'ecto-common/lib/ProcessMap/ProcessMapViewV2';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { ProcessMapViewProps } from 'ecto-common/lib/ProcessMap/ProcessMapView';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { useProcessMapSignals } from 'ecto-common/lib/ProcessMap/ProcessMapHooks';
import { Updater } from 'use-immer';
import {
  ProcessMapEditorActions,
  ProcessMapEditorStateHelpers
} from 'ecto-common/lib/ProcessMaps/ProcessMapEditorActions';
import { ProcessMapState } from 'ecto-common/lib/ProcessMaps/ProcessMapEditorTypes';
import { DropdownButtonOptionType } from 'ecto-common/lib/DropdownButton/DropdownButton';
import ToolbarFixedSpace from 'ecto-common/lib/Toolbar/ToolbarFixedSpace';
import dimensions from 'ecto-common/lib/styles/dimensions';
import classNames from 'classnames';
import SelectProcessMapDialog from 'ecto-common/lib/ProcessMaps/SelectProcessMapDialog';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import ProcessMapLibraryMenu from 'ecto-common/lib/ProcessMaps/ProcessMapLibraryMenu';
import ProcessMapPreviewOld from 'ecto-common/lib/ProcessMaps/ProcessMapPreviewOld';
import DOMPurify from 'dompurify';
import ProcessMapZoomSelector from 'ecto-common/lib/ProcessMaps/ProcessMapZoomSelector';
import { downloadBlobFromText } from 'ecto-common/lib/utils/downloadBlob';
import { MatrixPair } from './ProcessMapEditorTypes';
import useReloadTrigger from 'ecto-common/lib/hooks/useReloadTrigger';
import { processMapNewObjectApproxHeight } from '../ProcessMap/ProcessMapViewConstants';
import { ProcessMapObjectFactory } from 'ecto-common/lib/ProcessMaps/ProcessMapObjectFactory';
import { prettifyXml } from 'ecto-common/lib/ProcessMap/ProcessMapViewUtils';
import PreviewNodeToolbarItems, {
  useNodeTreeSet
} from 'ecto-common/lib/PreviewNodeToolbarItems/PreviewNodeToolbarItems';
import { toastStore } from '../Toast/ToastContainer';
import { SymbolModel } from 'ecto-common/lib/API/PresentationAPIGen';

type ProcessMapEditorViewWrapperProps = Omit<
  ProcessMapViewV2Props,
  keyof ProcessMapViewProps | 'isLoading'
> & {
  previewNodeId: string;
  children: React.ReactNode;
};

const documentModels: ModelDefinition<ProcessMapDocument>[] = [
  {
    modelType: ModelType.NUMBER,
    key: (input) => input.width,
    label: T.admin.processmaps.mapwidth,
    hasError: (value) => value == null || value <= 0,
    unit: 'px'
  },
  {
    modelType: ModelType.NUMBER,
    key: (input) => input.height,
    label: T.admin.processmaps.mapheight,
    hasError: (value) => value == null || value <= 0,
    unit: 'px'
  }
];

const emptySignalProviders: SignalProviderByNodeResponseModel[] = [];

const ProcessMapEditorViewWrapper = ({
  children,
  previewNodeId,
  ...props
}: ProcessMapEditorViewWrapperProps) => {
  const signalProvidersQuery = APIGen.Signals.getSignalsByNode.useQuery(
    { nodesIds: [previewNodeId] },
    { enabled: previewNodeId != null }
  );

  const signalTypesNameMap = useCommonSelector(
    (state) => state.general.signalTypesNameMap
  );
  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );
  const signalUnitTypesMap = useCommonSelector(
    (state) => state.general.signalUnitTypesMap
  );

  const signalProviders = signalProvidersQuery.data ?? emptySignalProviders;
  const { signalData, isLoading } = useProcessMapSignals(
    previewNodeId,
    signalProviders,
    false,
    signalTypesNameMap,
    null,
    true
  );

  return (
    <ProcessMapViewV2
      {...props}
      isLoading={
        previewNodeId != null && (isLoading || signalProvidersQuery.isLoading)
      }
      signalData={signalData}
      signalProviders={signalProviders}
      signalTypesMap={signalTypesMap}
      signalUnitTypesMap={signalUnitTypesMap}
      showSignalLabelsWhenNotFound={previewNodeId == null}
    >
      {children}
    </ProcessMapViewV2>
  );
};
type ProcessMapEditorProps = {
  processMapState: ProcessMapState;
  setProcessMapState: Updater<ProcessMapState>;
  onSave: (newDataBase64: string, comment: string) => void;
  library: SymbolModel[];
  previewTenantId?: string;
  setPreviewTenantId?: (tenantId: string) => void;
  previewNodeId?: string;
  setPreviewNodeId?: (nodeId: string) => void;
  name: string;
};

type ContextMenuLocation = {
  x: number;
  y: number;
  documentX: number;
  documentY: number;
};

function readSvgStringDimensions({
  svgStringBase64,
  centerX,
  centerY
}: {
  svgStringBase64: string;
  centerX: number;
  centerY: number;
}) {
  return new Promise<{
    svgStringDecoded: string;
    width: number;
    height: number;
    centerX: number;
    centerY: number;
  }>((resolve, reject) => {
    const image = new Image();
    const decoded = Base64.decode(svgStringBase64);
    if (!decoded.startsWith('<')) {
      reject(T.admin.processmaps.notsvgerror);
    }

    const url = URL.createObjectURL(
      new Blob([decoded], { type: 'image/svg+xml' })
    );
    image.src = url;
    image.onerror = reject;
    image.onload = function () {
      resolve({
        svgStringDecoded: decoded,
        width: image.width,
        height: image.height,
        centerX,
        centerY
      });
    };
  });
}

function readSvgFileAndDimensions({
  file,
  dropX,
  dropY
}: {
  file: File;
  dropX: number;
  dropY: number;
}) {
  return new Promise<{
    svg: string;
    width: number;
    height: number;
    dropX: number;
    dropY: number;
  }>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onerror = reject;
    reader.onloadend = function () {
      const image = new Image();
      const url = reader.result as string;
      if (!url.startsWith(processMapSvgDataUrlPrefix)) {
        reject(T.admin.processmaps.notsvgerror);
        return;
      }

      image.src = url;
      image.onerror = reject;
      const rawData = Base64.decode(
        url.substring(processMapSvgDataUrlPrefix.length)
      );

      image.onload = function () {
        resolve({
          svg: DOMPurify.sanitize(rawData),
          width: image.width,
          height: image.height,
          dropX,
          dropY
        });
      };
    };
  });
}

const ProcessMapEditor = ({
  processMapState,
  setProcessMapState,
  library,
  previewTenantId,
  setPreviewTenantId,
  previewNodeId,
  setPreviewNodeId,
  onSave,
  name
}: ProcessMapEditorProps) => {
  const [objectContextMenuLocation, setObjectContextMenuLocation] =
    useState<ContextMenuLocation>(null);
  const [newObjectContextMenuLocation, setNewObjectContextMenuLocation] =
    useState<ContextMenuLocation>(null);
  const [showingImportProcessMap, showImportProcessMap, hideImportProcessMap] =
    useDialogState(false);
  const [showingOldPreview, setShowOldPreview] = useDialogState(false);

  const svgRef = React.useRef<SVGSVGElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const signalTypesNameMap = useCommonSelector(
    (state) => state.general.signalTypesNameMap
  );
  const { contextSettings } = useContext(TenantContext);
  const [nodeTreeSet, isLoadingNodeTreeSet] = useNodeTreeSet();

  const readRevisionMutation = useMutation(
    (itemId: string) => {
      return APIGen.AdminProcessMaps.getProcessMapRevisionsByProcessMapId
        .promise(contextSettings, { processMapIds: [itemId] }, null)
        .then((results) => {
          const lastRevision = _.head(results);
          return APIGen.AdminProcessMaps.getProcessMapRevisionsById.promise(
            contextSettings,
            { ids: [lastRevision.id] },
            null
          );
        });
    },
    {
      onSuccess: (data) => {
        try {
          const processMap = JSON.parse(
            Base64.decode(data[0].map)
          ) as ProcessMapDocument;
          setProcessMapState((draft) =>
            ProcessMapEditorActions.importFromMap(draft, processMap)
          );
        } catch (_e) {
          console.error(T.admin.processmaps.failedtoparse);
        }
      }
    }
  );

  const readSvgStringMutation = useMutation(readSvgStringDimensions, {
    onSuccess: ({ svgStringDecoded, centerX, centerY, width, height }) => {
      const svgBaseEncoded = Base64.encode(
        DOMPurify.sanitize(svgStringDecoded)
      );
      setProcessMapState((draft) => {
        ProcessMapEditorActions.addExternalSvgAsImage(
          draft,
          svgBaseEncoded,
          centerX,
          centerY,
          width,
          height
        );
      });
    }
  });

  const readSvgFileMutation = useMutation(readSvgFileAndDimensions, {
    onSuccess: ({ svg, dropX, dropY, width, height }) => {
      const svgBaseEncoded = Base64.encode(DOMPurify.sanitize(svg));
      setProcessMapState((draft) => {
        ProcessMapEditorActions.addExternalSvgAsImage(
          draft,
          svgBaseEncoded,
          dropX,
          dropY,
          width,
          height
        );
      });
      setObjectContextMenuLocation(null);
      setNewObjectContextMenuLocation(null);
      triggerForceCloseDropdowns();
    },
    onError: (err) => {
      if (_.isString(err)) {
        toastStore.addErrorToast(err);
      } else {
        toastStore.addErrorToast(T.common.unknownerror);
      }
    }
  });

  const {
    processMap,
    transform,
    defineAreaState,
    selectedRectHandles,
    pendingConnectionLine,
    pendingConnectionSymbol,
    hoverRectHandles,
    isMouseDown,
    showDeleteConnections,
    connectionCircleRadius
  } = processMapState;

  const draggingSingleLinePoint =
    selectedRectHandles.length === 1 &&
    processMap.objects[selectedRectHandles[0].objectIndex].type ===
      ProcessMapObjectTypes.Line;

  useLayoutEffect(() => {
    const rect = containerRef.current.getBoundingClientRect();
    setProcessMapState((draft) =>
      ProcessMapEditorActions.updateTransform(draft, rect)
    );
  }, [setProcessMapState]);

  const onMouseDown: React.MouseEventHandler<SVGElement> = (mouseEvent) => {
    if (
      newObjectContextMenuLocation != null ||
      objectContextMenuLocation != null
    ) {
      setObjectContextMenuLocation(null);
      setNewObjectContextMenuLocation(null);
    }

    setProcessMapState((draft) =>
      ProcessMapEditorActions.onMouseDown(
        draft,
        mouseEvent,
        svgRef.current.getBoundingClientRect()
      )
    );
  };

  const onMouseMove: React.MouseEventHandler<SVGElement> = (mouseEvent) => {
    setProcessMapState((draft) =>
      ProcessMapEditorActions.onMouseMove(
        draft,
        mouseEvent,
        svgRef.current.getBoundingClientRect()
      )
    );
  };

  // Store these as refs so we do not trigger event registration effect for every render
  const lastTransform = useRef<MatrixPair>(processMapState.transform);
  lastTransform.current = processMapState.transform;
  const hasSelectedRectHandles = useRef<boolean>(
    selectedRectHandles.length > 0
  );
  hasSelectedRectHandles.current = selectedRectHandles.length > 0;

  useEffect(() => {
    const ref = svgRef.current;

    const onMouseWheel = (wheelEvent: WheelEvent) => {
      wheelEvent.preventDefault();
      wheelEvent.stopPropagation();
      setProcessMapState((draft) =>
        ProcessMapEditorActions.onMouseWheel(draft, wheelEvent)
      );
    };

    const onCopy = (clipboardEvent: ClipboardEvent) => {
      if (clipboardEvent.target instanceof HTMLInputElement) {
        return;
      }

      clipboardEvent.preventDefault();
      setProcessMapState((draft) => ProcessMapEditorActions.onCopy(draft));
    };

    const onCut = (clipboardEvent: ClipboardEvent) => {
      if (clipboardEvent.target instanceof HTMLInputElement) {
        return;
      }

      clipboardEvent.preventDefault();
      setProcessMapState((draft) => ProcessMapEditorActions.onCut(draft));
    };

    const onPaste = (clipboardEvent: ClipboardEvent) => {
      if (clipboardEvent.target instanceof HTMLInputElement) {
        return;
      }

      clipboardEvent.preventDefault();
      setProcessMapState((draft) => ProcessMapEditorActions.onPaste(draft));
    };

    const onKeyDown = (keyEvent: KeyboardEvent) => {
      if (keyEvent.target instanceof HTMLInputElement) {
        return;
      }

      setProcessMapState((draft) =>
        ProcessMapEditorActions.onKeyDown(draft, keyEvent)
      );
    };

    const onKeyUp = (keyEvent: KeyboardEvent) => {
      if (keyEvent.target instanceof HTMLInputElement) {
        return;
      }

      setProcessMapState((draft) =>
        ProcessMapEditorActions.onKeyUp(draft, keyEvent)
      );
    };

    const onContextMenu = (contextEvent: MouseEvent) => {
      const rect = containerRef.current.getBoundingClientRect();

      if (containerRef.current.contains(contextEvent.target as Node)) {
        contextEvent.preventDefault();
        contextEvent.stopPropagation();
      }

      if (hasSelectedRectHandles.current) {
        setProcessMapState((draft) =>
          ProcessMapEditorActions.onShowContextMenu(draft)
        );
        const svgRect = svgRef.current.getBoundingClientRect();
        const mouseDocumentPosition = applyToPoint(
          lastTransform.current.inverse,
          {
            x: contextEvent.clientX - svgRect.x,
            y: contextEvent.clientY - svgRect.y
          }
        );

        setObjectContextMenuLocation({
          x: contextEvent.clientX - rect.x,
          y: contextEvent.clientY - rect.y,
          documentX: mouseDocumentPosition.x,
          documentY: mouseDocumentPosition.y
        });
        setNewObjectContextMenuLocation(null);
      } else {
        setObjectContextMenuLocation(null);
      }
    };

    ref?.addEventListener('wheel', onMouseWheel);
    document.addEventListener('copy', onCopy);
    document.addEventListener('cut', onCut);
    document.addEventListener('paste', onPaste);
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);
    document.addEventListener('contextmenu', onContextMenu);

    return () => {
      ref?.removeEventListener('wheel', onMouseWheel);
      document.removeEventListener('copy', onCopy);
      document.removeEventListener('cut', onCut);
      document.removeEventListener('paste', onPaste);
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
      document.removeEventListener('contextmenu', onContextMenu);
    };
  }, [setProcessMapState]);

  const onMouseUp: React.MouseEventHandler<SVGElement> = (mouseEvent) => {
    setProcessMapState((draft) => ProcessMapEditorActions.onMouseUp(draft));
    if (
      !processMapState.mouseState.didMove &&
      objectContextMenuLocation == null &&
      mouseEvent.button === 2
    ) {
      const rect = containerRef.current.getBoundingClientRect();
      const svgRect = svgRef.current.getBoundingClientRect();
      const mouseDocumentPosition = applyToPoint(
        processMapState.transform.inverse,
        { x: mouseEvent.clientX - svgRect.x, y: mouseEvent.clientY - svgRect.y }
      );

      // Use bounded y position to prevent context menu from going off screen,
      // should use poppers builtin logic instead.
      setNewObjectContextMenuLocation({
        x: mouseEvent.clientX - rect.x,
        y: Math.min(
          mouseEvent.clientY - rect.y,
          rect.y + rect.height - processMapNewObjectApproxHeight
        ),
        documentX: mouseDocumentPosition.x,
        documentY: mouseDocumentPosition.y
      });
    }
  };

  const matrixTransform = toCSS(transform.current);

  const getDragPosition = useCallback(
    (
      event: React.DragEvent<HTMLImageElement> | React.DragEvent<SVGSVGElement>,
      offsetX: number,
      offsetY: number
    ) => {
      const rect = svgRef.current.getBoundingClientRect();
      const x = event.clientX - rect.x - offsetX;
      const y = event.clientY - rect.y - offsetY;
      return applyToPoint(transform.inverse, { x, y });
    },
    [transform.inverse]
  );

  const [forceCloseDropdowns, triggerForceCloseDropdowns] = useReloadTrigger(
    0,
    -1
  );

  const onClickLibraryItem = useCallback(
    (
      libraryItem: SymbolModelOrSystemItem,
      centerX = processMap.width / 2.0,
      centerY = processMap.height / 2.0
    ) => {
      const object = ProcessMapObjectFactory.createObjectFromLibraryItem(
        libraryItem,
        centerX,
        centerY
      );
      if (libraryItem.type === ProcessMapObjectTypes.Symbol) {
        const item = libraryItem.item;
        const itemMd5 = md5(item.data);
        setProcessMapState((draft) => {
          ProcessMapEditorActions.addSvg(draft, item.data, itemMd5);
          ProcessMapEditorActions.addObject(draft, object);
        });
      } else {
        setProcessMapState((draft) =>
          ProcessMapEditorActions.addObject(draft, object)
        );
      }
      setNewObjectContextMenuLocation(null);
      setObjectContextMenuLocation(null);
    },
    [processMap.height, processMap.width, setProcessMapState]
  );

  const objectContextMenuOptions: DropdownButtonOptionType[] = useMemo(() => {
    return _.compact([
      {
        icon: <Icons.NavigationArrowUp />,
        label: T.admin.processmaps.contextmenu.sendforward,
        action: () => {
          setProcessMapState((draft) =>
            ProcessMapEditorActions.sendSelectedForward(draft)
          );
          setObjectContextMenuLocation(null);
        }
      },
      {
        icon: <Icons.NavigationArrowUp />,
        label: T.admin.processmaps.contextmenu.sendtofront,
        action: () => {
          setProcessMapState((draft) =>
            ProcessMapEditorActions.sendSelectedToFront(draft)
          );
          setObjectContextMenuLocation(null);
        }
      },
      {
        icon: <Icons.NavigationArrowDown />,
        label: T.admin.processmaps.contextmenu.sendback,
        action: () => {
          setProcessMapState((draft) =>
            ProcessMapEditorActions.sendSelectedBack(draft)
          );
          setObjectContextMenuLocation(null);
        }
      },
      {
        icon: <Icons.NavigationArrowDown />,
        label: T.admin.processmaps.contextmenu.sendtoback,
        action: () => {
          setProcessMapState((draft) =>
            ProcessMapEditorActions.sendSelectedToBack(draft)
          );
          setObjectContextMenuLocation(null);
        }
      },
      {
        icon: <Icons.Delete />,
        label: T.admin.processmaps.contextmenu.deleteobject,
        action: () => {
          setProcessMapState((draft) =>
            ProcessMapEditorActions.clearSelectedItems(draft)
          );
          setObjectContextMenuLocation(null);
        }
      },
      {
        icon: <Icons.Delete />,
        label: T.admin.processmaps.contextmenu.deleteconnections,
        action: () => {
          setProcessMapState((draft) =>
            ProcessMapEditorActions.setShowDeleteConnections(draft)
          );
          setObjectContextMenuLocation(null);
        }
      },
      draggingSingleLinePoint &&
        processMap.objects[selectedRectHandles[0].objectIndex].rects.length >
          2 && {
          icon: <Icons.Delete />,
          label: T.admin.processmaps.contextmenu.deletepoint,
          action: () => {
            setProcessMapState((draft) =>
              ProcessMapEditorActions.deleteSelectedLineRects(draft)
            );
            setObjectContextMenuLocation(null);
          }
        }
    ]);
  }, [
    draggingSingleLinePoint,
    processMap.objects,
    selectedRectHandles,
    setProcessMapState
  ]);

  const onDragItemEnd = useCallback(
    (
      libraryItem: SymbolModelOrSystemItem,
      event: React.DragEvent<HTMLImageElement>,
      offsetX: number,
      offsetY: number
    ) => {
      event.preventDefault();
      const relativePos = getDragPosition(event, offsetX, offsetY);
      onClickLibraryItem(libraryItem, relativePos.x, relativePos.y);
    },
    [getDragPosition, onClickLibraryItem]
  );

  const addSvg = useCallback(
    (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
      const file = _.head(changeEvent.target.files);
      readSvgFileMutation.mutate({
        file,
        dropX: processMap.width / 2.0,
        dropY: processMap.height / 2.0
      });
      changeEvent.target.value = null;
    },
    [processMap.height, processMap.width, readSvgFileMutation]
  );

  const updateSymbols = useCallback(() => {
    setProcessMapState((draft) =>
      ProcessMapEditorActions.updateSymbols(draft, library)
    );
    toastStore.addSuccessToast(
      T.admin.processmaps.projectsettings.updatesymbolsversionstoast
    );
  }, [library, setProcessMapState]);

  const onDropSvg: React.DragEventHandler<SVGSVGElement> = (e) => {
    e.preventDefault();
    if (e.dataTransfer.items) {
      [...e.dataTransfer.items].forEach((item) => {
        if (item.kind === 'file') {
          const file = item.getAsFile();
          if (file.type === 'image/svg+xml') {
            const dragPos = getDragPosition(e, 0, 0);
            readSvgFileMutation.mutate({
              file,
              dropX: dragPos.x,
              dropY: dragPos.y
            });
          }
        }
      });
    }
  };

  const importFromOtherProcessMap = useCallback(
    (processMapToImport: ProcessMapResponseModel) => {
      readRevisionMutation.mutate(processMapToImport.id);
      hideImportProcessMap();
    },
    [hideImportProcessMap, readRevisionMutation]
  );

  const updateObject = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (objectIndex: number, key: string[], value: any) => {
      setProcessMapState((draft) =>
        ProcessMapEditorActions.updateObject(
          draft,
          objectIndex,
          key,
          value,
          library
        )
      );
    },
    [library, setProcessMapState]
  );

  const updateTextSize = useCallback(
    (objectIndex: number, rectIndex: number, width: number, height: number) => {
      _.defer(() => {
        setProcessMapState((draft) =>
          ProcessMapEditorActions.updateTextSize(
            draft.processMap,
            objectIndex,
            rectIndex,
            width,
            height
          )
        );
      });
    },
    [setProcessMapState]
  );

  const setRectResizeElement = useCallback(
    (
      objectIndex: number,
      rectIndex: number,
      resizeElement: ProcessMapRectResizeArea
    ) => {
      _.defer(() => {
        setProcessMapState((draft) =>
          ProcessMapEditorActions.setRectResizeElement(
            draft,
            objectIndex,
            rectIndex,
            resizeElement
          )
        );
      });
    },
    [setProcessMapState]
  );

  const updateRect = useCallback(
    (
      objectIndex: number,
      rectIndex: number,
      update: Partial<ProcessMapRect>
    ) => {
      setProcessMapState((draft) =>
        ProcessMapEditorActions.updateRect(
          draft,
          objectIndex,
          rectIndex,
          update
        )
      );
    },
    [setProcessMapState]
  );

  const projectSettings = useMemo(() => {
    return (
      <div className={styles.dropdownContent}>
        <div
          className={classNames(styles.previewSettings, styles.editSettings)}
        >
          <ModelForm
            input={processMap}
            onUpdateInput={(key, value) => {
              setProcessMapState((draft) =>
                ProcessMapEditorActions.updateDocument(draft, key, value)
              );
            }}
            models={documentModels}
            horizontalModels
          />
        </div>
      </div>
    );
  }, [processMap, setProcessMapState]);

  const onClickLibraryMenuItem = useCallback(
    (item: SymbolModelOrSystemItem) => {
      onClickLibraryItem(
        item,
        newObjectContextMenuLocation?.documentX ?? processMap.width / 2.0,
        newObjectContextMenuLocation?.documentY ?? processMap.height / 2.0
      );
      triggerForceCloseDropdowns();
    },
    [
      newObjectContextMenuLocation?.documentX,
      newObjectContextMenuLocation?.documentY,
      onClickLibraryItem,
      processMap.height,
      processMap.width,
      triggerForceCloseDropdowns
    ]
  );

  const newObjectContextMenuFooter = (
    <DropdownOpenFileFooter
      icon={<Icons.Add />}
      label={T.admin.processmaps.addsvgimage}
      onChange={addSvg}
    />
  );

  const projectOptions: DropdownButtonOptionType[] = useMemo(() => {
    return [
      {
        label: T.admin.processmaps.projectsettings.updatesymbolsversions,
        isEnabled: processMap.objects.length > 0,
        icon: <Icons.Graph />,
        action: updateSymbols
      },
      {
        icon: <Icons.MinMax />,
        label: T.admin.processmaps.projectsettings.trimmap,
        isEnabled: processMap.objects.length > 0,
        action: () =>
          setProcessMapState((draft) =>
            ProcessMapEditorActions.trimDocumentSize(draft)
          )
      }
    ];
  }, [processMap.objects.length, setProcessMapState, updateSymbols]);

  const newObjectContextMenuOptions: DropdownButtonOptionType[] =
    useMemo(() => {
      const addItemWithEvent = (
        item: SymbolModelOrSystemItem,
        e: React.MouseEvent<HTMLElement>
      ) => {
        const svgRect = svgRef.current.getBoundingClientRect();
        const mouseDocumentPosition = applyToPoint(
          processMapState.transform.inverse,
          { x: e.clientX - svgRect.x, y: e.clientY - svgRect.y }
        );
        let x = mouseDocumentPosition.x;
        let y = mouseDocumentPosition.y;

        // If adding via toolbar, just add it to the center of the document
        if (newObjectContextMenuLocation == null) {
          x = processMap.width / 2.0;
          y = processMap.height / 2.0;
        }

        onClickLibraryItem(item, x, y);
      };
      const libraryMenu = (
        <ProcessMapLibraryMenu
          library={library}
          onClickLibraryMenuItem={onClickLibraryMenuItem}
          onDragItemEnd={onDragItemEnd}
        />
      );

      return [
        {
          label: T.admin.processmaps.contextmenu.addline,
          icon: <Icons.Add />,
          action: (event) => {
            addItemWithEvent({ type: ProcessMapObjectTypes.Line }, event);
          }
        },
        {
          label: T.admin.processmaps.contextmenu.addrect,
          icon: <Icons.Add />,
          action: (event) => {
            addItemWithEvent({ type: ProcessMapObjectTypes.Rect }, event);
          }
        },
        {
          label: T.admin.processmaps.contextmenu.addsignal,
          icon: <Icons.Add />,
          action: (event) => {
            addItemWithEvent({ type: ProcessMapObjectTypes.Signal }, event);
          }
        },
        {
          label: T.admin.processmaps.contextmenu.addtext,
          icon: <Icons.Add />,
          action: (event) => {
            addItemWithEvent({ type: ProcessMapObjectTypes.Text }, event);
          }
        },
        {
          label: T.admin.processmaps.contextmenu.addsymbol,
          icon: <Icons.Add />,
          rightSideIcon: <Icons.NavigationArrowRight />,
          nestedHeader: libraryMenu
        }
      ];
    }, [
      library,
      newObjectContextMenuLocation,
      onClickLibraryItem,
      onClickLibraryMenuItem,
      onDragItemEnd,
      processMap.height,
      processMap.width,
      processMapState.transform.inverse
    ]);

  const oldSvgDropdownOptions: DropdownButtonOptionType[] = useMemo(() => {
    return _.compact([
      {
        label: T.admin.processmaps.svgmenu.copyobjects,
        icon: <Icons.Copy />,
        action: () => {
          showImportProcessMap();
        }
      },
      processMap.oldSvgData && {
        label: T.admin.processmaps.svgmenu.importoldsvgdata,
        icon: <Icons.File />,
        action: () => {
          setProcessMapState((draft) =>
            ProcessMapEditorActions.importOldSvg(
              draft,
              signalTypesNameMap,
              library
            )
          );
        }
      },
      processMap.oldSvgData && {
        label: T.admin.processmaps.svgmenu.addoldsvgasimage,
        icon: <Icons.Add />,
        action: () => {
          readSvgStringMutation.mutate({
            svgStringBase64: processMap.oldSvgData,
            centerX: processMap.width / 2.0,
            centerY: processMap.height / 2.0
          });
        }
      },
      processMap.oldSvgData && {
        label: T.admin.processmaps.svgmenu.copyoldsvgtoclipboard,
        icon: <Icons.Copy />,
        action: () => {
          toastStore.addSuccessToast(T.common.copytoclipboard.success);
          navigator.clipboard.writeText(
            prettifyXml(
              DOMPurify.sanitize(Base64.decode(processMap.oldSvgData))
            )
          );
        }
      },
      processMap.oldSvgData && {
        label: T.admin.processmaps.svgmenu.downloadoldsvg,
        icon: <Icons.Download />,
        action: () => {
          downloadBlobFromText(
            prettifyXml(
              DOMPurify.sanitize(Base64.decode(processMap.oldSvgData))
            ),
            (name ?? 'processmap') + '.svg'
          );
        }
      },
      processMap.oldSvgData &&
        previewNodeId && {
          label: T.admin.processmaps.svgmenu.showpreview,
          icon: <Icons.Zoom />,
          action: setShowOldPreview
        }
    ]);
  }, [
    library,
    name,
    previewNodeId,
    processMap.height,
    processMap.oldSvgData,
    processMap.width,
    readSvgStringMutation,
    setProcessMapState,
    setShowOldPreview,
    showImportProcessMap,
    signalTypesNameMap
  ]);

  const onAddNewLinePoint = useCallback(
    (objectIndex: number, index: number, centerX: number, centerY: number) => {
      setProcessMapState((draft) =>
        ProcessMapEditorActions.addNewLinePoint(
          draft,
          objectIndex,
          index,
          centerX,
          centerY
        )
      );
    },
    [setProcessMapState]
  );

  const setZoom = useCallback(
    (zoom: number) => {
      setProcessMapState((draft) =>
        ProcessMapEditorActions.setZoom(
          draft,
          zoom,
          draft.processMap.width / 2.0,
          draft.processMap.height / 2.0
        )
      );
    },
    [setProcessMapState]
  );

  return (
    <div className={styles.container}>
      <div
        className={styles.canvasContainer}
        ref={containerRef}
        onDragOver={(e) => e.preventDefault()}
      >
        <Toolbar isPageHeadingToolbar>
          <ToolbarFixedSpace width={dimensions.standardMargin} />
          {setPreviewTenantId && setPreviewNodeId && (
            <PreviewNodeToolbarItems
              isLoadingNodeTreeSet={isLoadingNodeTreeSet}
              nodeTreeSet={nodeTreeSet}
              previewNodeId={previewNodeId}
              setPreviewNodeId={setPreviewNodeId}
              previewTenantId={previewTenantId}
              setPreviewTenantId={setPreviewTenantId}
            />
          )}

          <ToolbarItem>
            <ToolbarMenu>
              <ToolbarMenuButton
                tooltipText={T.common.save}
                icon={<Icons.Save />}
                disabled={!processMapState.hasChanges}
                onClick={() => {
                  onSave(
                    Base64.encode(JSON.stringify(processMap)),
                    T.admin.processmaps.newversion
                  );
                }}
              />
              <ToolbarMenuButton
                tooltipText={T.common.undo}
                icon={<Icons.Undo />}
                disabled={
                  !ProcessMapEditorStateHelpers.undoAvailable(processMapState)
                }
                onClick={() => {
                  setProcessMapState((draft) =>
                    ProcessMapEditorActions.onUndo(draft)
                  );
                }}
              />
              <ToolbarMenuButton
                tooltipText={T.common.redo}
                icon={<Icons.Redo />}
                disabled={
                  !ProcessMapEditorStateHelpers.redoAvailable(processMapState)
                }
                onClick={() => {
                  setProcessMapState((draft) =>
                    ProcessMapEditorActions.onRedo(draft)
                  );
                }}
              />
              <ToolbarMenuDropdownButton
                tooltipText={T.common.add}
                options={newObjectContextMenuOptions}
                footer={newObjectContextMenuFooter}
                isIconButton
                forceClose={forceCloseDropdowns}
              >
                <Icons.Add />
              </ToolbarMenuDropdownButton>

              <ToolbarMenuDropdownButton
                tooltipText={T.admin.processmaps.projectsettings.title}
                header={projectSettings}
                options={projectOptions}
                isIconButton
                forceClose={forceCloseDropdowns}
              >
                <Icons.Settings />
              </ToolbarMenuDropdownButton>
              <ToolbarMenuDropdownButton
                tooltipText={T.admin.processmaps.symbolactions}
                options={oldSvgDropdownOptions}
                isIconButton
                forceClose={forceCloseDropdowns}
              >
                <Icons.Graph />
              </ToolbarMenuDropdownButton>
            </ToolbarMenu>
          </ToolbarItem>
          <ProcessMapZoomSelector
            value={transform.current.a}
            setZoom={setZoom}
          />
          <ToolbarItem>
            {readRevisionMutation.isLoading && (
              <Spinner size={SpinnerSize.SMALL} />
            )}
          </ToolbarItem>
          <ToolbarFlexibleSpace />
        </Toolbar>

        <div className={styles.svgContainer} tabIndex={0}>
          <svg
            width="100%"
            height="100%"
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            ref={svgRef}
            onDragOver={(e) => {
              e.preventDefault();
            }}
            onDrop={onDropSvg}
          >
            <ProcessMapEditorViewWrapper
              connectionCircleRadius={connectionCircleRadius}
              previewNodeId={previewNodeId}
              processMap={processMap}
              matrixTransform={matrixTransform}
              updateTextSize={updateTextSize}
              mouseActions={null}
              setRectResizeElement={setRectResizeElement}
              selectedRectHandles={selectedRectHandles}
              hoverRectHandles={hoverRectHandles}
              pendingConnectionLine={pendingConnectionLine}
              pendingConnectionSymbol={pendingConnectionSymbol}
              draggingSingleLinePoint={draggingSingleLinePoint}
              isMouseDown={isMouseDown}
              showDeleteConnections={showDeleteConnections}
              onAddNewLinePoint={onAddNewLinePoint}
              editMode
              zoom={transform.current.a}
            >
              {defineAreaState && (
                <rect
                  x={defineAreaState.startX}
                  y={defineAreaState.startY}
                  width={defineAreaState.endX - defineAreaState.startX}
                  height={defineAreaState.endY - defineAreaState.startY}
                  style={{ fill: 'rgba(0, 0, 255, 0.3)' }}
                />
              )}
            </ProcessMapEditorViewWrapper>
          </svg>
        </div>
        <DropdownMenu
          options={objectContextMenuOptions}
          isShowing={objectContextMenuLocation != null}
          outerContainerClassName={styles.contextMenu}
          outerStyle={{
            left: objectContextMenuLocation?.x,
            top: objectContextMenuLocation?.y
          }}
        />
        <DropdownMenu
          options={newObjectContextMenuOptions}
          footer={newObjectContextMenuFooter}
          isShowing={newObjectContextMenuLocation != null}
          outerContainerClassName={styles.contextMenu}
          outerStyle={{
            left: newObjectContextMenuLocation?.x,
            top: newObjectContextMenuLocation?.y
          }}
        />
      </div>
      <div className={styles.objectEditor}>
        <ProcessMapObjectEditor
          selectedRectHandles={selectedRectHandles}
          processMap={processMap}
          updateObject={updateObject}
          nodeTreeSet={nodeTreeSet}
          previewNodeId={previewNodeId}
          updateRect={updateRect}
          svgImages={processMapState.processMap.svgImages}
          library={library}
        />
      </div>
      <SelectProcessMapDialog
        onModalClose={hideImportProcessMap}
        isOpen={showingImportProcessMap}
        nodeId={null}
        actionText={T.common.done}
        onConfirm={importFromOtherProcessMap}
      />

      {showingOldPreview && processMap.oldSvgData != null && (
        <ProcessMapPreviewOld previewNodeId={previewNodeId} />
      )}
    </div>
  );
};

export default React.memo(ProcessMapEditor);
