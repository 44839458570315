export const getEquipmentPageUrl = (
  tenantId: string,
  nodeId: string,
  itemId: string,
  subPage: string
) => {
  let url = `/${tenantId}/home/${nodeId}/equipment/`;

  if (itemId != null) {
    url += itemId;
  }

  if (subPage) {
    url = url + '/' + subPage;
  }

  return url;
};
