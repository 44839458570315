import classNames from 'classnames';
import styles from 'ecto-common/lib/KeyValueInput/KeyValueInput.module.css';
import { KeyValueLabel } from 'ecto-common/lib/KeyValueInput/KeyValueLabel';
import _ from 'lodash';
import KeyValueInternalSelectableInput from 'ecto-common/lib/KeyValueInput/Internal/KeyValueInternalSelectableInput';
import Button from 'ecto-common/lib/Button/Button';
import React from 'react';
import {
  GenericSelectOption,
  SelectProps
} from 'ecto-common/lib/Select/Select';
import {
  DefaultHorizontalWeights,
  HorizontalLabelValueWeights
} from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import { PropsValue } from 'react-select';

type KeyValueSelectableInputProps<
  ValueType,
  IsMulti extends boolean
> = SelectProps<ValueType, IsMulti> & {
  /**
   * Text describing the select control.
   */
  keyText: React.ReactNode;
  /**
   * Placeholder value which is shown when no value has been selected.
   */
  placeholder?: React.ReactNode;

  /**
   * Used to override click handler for select control. Can be used to show a modal instead of the select control list.
   * Typically you should use KeyValueFixedSelectableInput in this case.
   */
  onClick?(): void;
  /**
   * If set to true, show a small spinner in the select control.
   */
  isLoading?: boolean;
  /**
   * If set to true, add a placeholder option for creating a new element.
   */
  withCreatableOption?: boolean;

  /**
   * Whether or not the list control is disabled.
   */
  disabled?: boolean;
  /**
   * If true, show red border around control indicating error.
   */
  hasError?: boolean;
  /**
   * If set then a button will appear to the right of the select control. Once clicked this callback will be invoked.
   */
  onAction?(value: PropsValue<ValueType>): void;
  /**
   * Whether or not the button is disabled (onAction needs to be set for button to be shown)
   */
  buttonDisabled?: boolean;
  /**
   * Used to override the appearance of the control. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * Used to add a help text underneath the control.
   */
  helpText?: React.ReactNode;

  /**
   * If true, the help text will be shown as a tooltip instead of a label.
   * @default false
   */
  useTooltipHelpTexts?: boolean;
  /**
   * Set this to load options asynchronously. See documentation for https://www.npmjs.com/package/react-select-async-paginate for more details.
   */
  loadOptions?: (
    search: string,
    loadedOptions: ValueType[],
    additional: unknown
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Promise<any>;

  /**
   * If using horizontal layout, specify the layout weights of the value and label. For instance, to have the label take 40% and the value take 60% of the available width,
   * you could pass a value of [4, 6].
   */
  horizontalWeights?: HorizontalLabelValueWeights;

  /**
   * Whether or not to lay out this control in a horizontal layout
   */
  isHorizontal?: boolean;

  reduceOptions?: (
    prevOptions: ValueType[],
    loadedOptions: ValueType[]
  ) => ValueType[];

  cacheUniqs?: unknown[];

  showOptionWhenEmpty?: boolean;
};

/**
 *  This component provides a select input with an annotated key title.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function KeyValueSelectableInput<
  ValueType = GenericSelectOption,
  IsMulti extends boolean = false
>({
  className = null,
  keyText = null,
  value = null,
  onClick = null,
  placeholder = null,
  isLoading = false,
  options,
  disabled = false,
  buttonDisabled = false,
  onAction = null,
  hasError = false,
  helpText = null,
  useTooltipHelpTexts = false,
  loadOptions = null,
  horizontalWeights = DefaultHorizontalWeights,
  withCreatableOption = false,
  isHorizontal = false,
  ...props
}: KeyValueSelectableInputProps<ValueType, IsMulti>) {
  return (
    <div
      className={classNames(
        styles.keyValueContainer,
        isHorizontal && styles.keyValueContainerHorizontal,
        className
      )}
    >
      <KeyValueLabel
        className={isHorizontal ? styles.keyValueLabelHorizontal : null}
        style={{ flexGrow: isHorizontal ? horizontalWeights[0] : 1 }}
        tooltipText={useTooltipHelpTexts && helpText}
      >
        {keyText}
      </KeyValueLabel>
      <div
        className={styles.selectableContent}
        onClick={disabled ? _.noop : onClick}
        style={{ flexGrow: isHorizontal ? horizontalWeights[1] : 1 }}
      >
        <KeyValueInternalSelectableInput<ValueType, IsMulti>
          isLoading={isLoading}
          options={options}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          hasError={hasError}
          withCreatableOption={withCreatableOption}
          loadOptions={loadOptions}
          {...props}
        />
        {onAction && (
          <Button
            disabled={buttonDisabled || disabled}
            onClick={() => onAction(value)}
          >
            {keyText}
          </Button>
        )}
      </div>
      {helpText && !useTooltipHelpTexts && (
        <div className={styles.helpText}> {helpText} </div>
      )}
    </div>
  );
}
