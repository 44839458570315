import React, { useContext } from 'react';

import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';
import styles from './GaugePanel.module.css';
import GaugeGraph from 'ecto-common/lib/Graph/GaugeGraph';
import BarGaugeGraph from 'ecto-common/lib/Graph/BarGaugeGraph';
import MeterGraph from 'ecto-common/lib/Graph/MeterGraph';
import colors from 'ecto-common/lib/styles/variables/colors';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import { getGaugeValueText } from 'ecto-common/lib/Graph/gaugeUtil';
import { migrateSignalSettingSystemNamesToSignalTypes } from 'ecto-common/lib/Dashboard/migrations/datasourceUtil';
import { getSignalTypeUnit } from 'ecto-common/lib/SignalSelector/SignalUtils';
import HelpPaths from 'ecto-common/help/tocKeys';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import { SignalValueType } from 'ecto-common/lib/hooks/useLatestSignalValues';
import {
  LastSignalValuesDataSourceResult,
  SignalInputType
} from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';
import {
  CustomPanelProps,
  DashboardPanel
} from 'ecto-common/lib/Dashboard/Panel';
import {
  GetEnumsAndFixedConfigurationsResponseModel,
  SignalTypeResponseModel
} from 'ecto-common/lib/API/APIGen';
import DataSourceTypes from 'ecto-common/lib/Dashboard/datasources/DataSourceTypes';
import {
  ModelDefinition,
  ModelFormSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';

const DEFAULT_FONT_SIZE = 28;

type GaugePanelConfig = {
  minValue?: number;
  maxValue?: number;
  type?: string;
  fontSize?: number;
  hideUnit?: boolean;
};

type GaugeValueProps = {
  value: number;
  unit: string;
  isLoading: boolean;
  hasError: boolean;
  color: string;
  parentSize: {
    width: number;
    height: number;
  };
  fontSize?: number;
  hideUnit?: boolean;
};

const GaugeValue = ({
  value,
  unit,
  isLoading,
  hasError,
  color,
  parentSize,
  fontSize,
  hideUnit
}: GaugeValueProps) => {
  const circleSize = 0.9 * Math.min(parentSize.width, parentSize.height);
  const text = getGaugeValueText(value, isLoading, hideUnit, unit);

  return (
    <>
      <div
        className={styles.mainSignal}
        style={{
          backgroundColor: color,
          width: circleSize,
          height: circleSize,
          fontSize
        }}
      >
        <div className={styles.value}>
          {isLoading && (
            <div className={styles.loading}> {T.common.loading}</div>
          )}
          {!isLoading && text}
        </div>
      </div>
      {hasError && <ErrorNotice> {T.common.unknownerror}</ErrorNotice>}
    </>
  );
};

const GaugeType = Object.freeze({
  VALUE: 'value',
  GAUGE: 'solidgauge',
  METER: 'meter',
  BAR: 'bar'
});

const DEFAULT_GAUGE_TYPE = GaugeType.VALUE;

const allTypes = {
  [GaugeType.VALUE]: {
    component: GaugeValue,
    title: T.admin.dashboards.panels.types.gauge.types.value
  },
  [GaugeType.GAUGE]: {
    component: GaugeGraph,
    title: T.admin.dashboards.panels.types.gauge.types.solidgauge
  },
  [GaugeType.METER]: {
    component: MeterGraph,
    title: T.admin.dashboards.panels.types.gauge.types.meter
  },
  [GaugeType.BAR]: {
    component: BarGaugeGraph,
    title: T.admin.dashboards.panels.types.gauge.types.bar
  }
};

const GaugeTypeComponents = _.mapValues(allTypes, 'component');
const GaugeTypeOptionsText = _.mapValues(allTypes, 'title');

type GaugePanelDataProps = {
  signal: LastSignalValuesDataSourceResult;
} & GaugePanelConfig;

type GaugePanelProps = GaugePanelDataProps &
  CustomPanelProps & {
    data: GaugePanelDataProps;
  };

const GaugePanel = ({ panelApi, data }: GaugePanelProps) => {
  const { size } = panelApi;
  let {
    signal,
    minValue = 0,
    maxValue = 0,
    type = DEFAULT_GAUGE_TYPE,
    fontSize = DEFAULT_FONT_SIZE,
    hideUnit
  } = data;

  // Workaround for null being accepted as valid value
  if (fontSize == null) {
    fontSize = DEFAULT_FONT_SIZE;
  }

  const { signalTypesMap, signalUnitTypesMap } =
    useContext(DashboardDataContext);

  const latestSignalValue: SignalValueType = _.head(signal?.signalValues);
  const signalId = latestSignalValue?.signalId;
  const unit = getSignalTypeUnit(
    signal?.signalInfo.signals?.[signalId]?.signalTypeId,
    signalTypesMap,
    signalUnitTypesMap
  );

  // We're only working with one signal, use that to get the color. Need to use signalInputs
  // since the other objects will not be populated until we get values.
  const signalInfo = _.head(signal?.signalInfo.signalInputs);
  const color = signalInfo?.color ?? colors.accent1Color;

  const isLoading = signal?.isLoading;
  const hasError = signal?.hasError;
  const signalValue = _.head(signal?.signalValues)?.value;

  const Component = GaugeTypeComponents[type];

  return (
    <div className={styles.main}>
      {(hasError || Component == null) && T.common.unknownerror}
      {!hasError && Component && (
        <Component
          value={signalValue}
          min={minValue}
          max={maxValue}
          unit={unit}
          parentSize={size}
          color={color}
          hideUnit={hideUnit}
          isLoading={isLoading}
          fontSize={fontSize}
          hasError={hasError}
        />
      )}
    </div>
  );
};

const sections: ModelFormSectionType<GaugePanelConfig>[] = [
  {
    label: T.admin.dashboards.sections.gauge,
    lines: [
      {
        models: [
          {
            key: (input) => input.type,
            modelType: ModelType.OPTIONS,
            label: T.admin.dashboards.panels.types.gauge.type,
            placeholder: GaugeTypeOptionsText[DEFAULT_GAUGE_TYPE],
            options: _.map(GaugeTypeOptionsText, (label, value) => ({
              label,
              value
            }))
          },
          {
            key: (input) => input.hideUnit,
            modelType: ModelType.BOOL,
            label: T.admin.dashboards.panels.types.gauge.hideunit,
            isHorizontal: false
          }
        ]
      },
      {
        models: [
          {
            key: (input) => input.fontSize,
            modelType: ModelType.NUMBER,
            label: T.admin.dashboards.panels.types.gauge.fontsize,
            placeholder: '' + DEFAULT_FONT_SIZE
          },
          {
            key: (input) => input.minValue,
            modelType: ModelType.NUMBER,
            label: T.admin.dashboards.panels.types.gauge.minvalue,
            placeholder: '0'
          },
          {
            key: (input) => input.maxValue,
            modelType: ModelType.NUMBER,
            label: T.admin.dashboards.panels.types.gauge.maxvalue,
            placeholder: '0'
          }
        ]
      }
    ]
  }
];

export const GaugePanelData = {
  dataSourceSectionsConfig: {
    [DataSourceTypes.SIGNALS_LAST_VALUE]: {
      minItems: 1,
      optionalSignalModels: [] as ModelDefinition<SignalInputType>[]
    }
  },
  emptyTargets: {
    signal: {
      sourceType: DataSourceTypes.SIGNALS_LAST_VALUE
    }
  },
  sections,
  fixedSizeInRelayout: true,
  migrations: [
    {
      version: 2,
      migration: (
        panel: DashboardPanel,
        enums: GetEnumsAndFixedConfigurationsResponseModel,
        signalTypesMap: Record<string, SignalTypeResponseModel>
      ) => {
        panel.targets.signal = migrateSignalSettingSystemNamesToSignalTypes(
          panel.targets.signal,
          enums,
          signalTypesMap
        );
      }
    }
  ],
  helpPath: HelpPaths.docs.dashboard.dashboards.gauge
};

export default React.memo(GaugePanel);
