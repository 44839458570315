/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface ListPicturesResponse {
  pictures?: PictureModel[] | null;
}

export interface PictureModel {
  id?: string | null;
  tenant?: string | null;
  header?: string | null;
  data?: string | null;
  /** @format int32 */
  defaultPriority?: number;
  allowedNodeTypes?: string[] | null;
}

export interface CreatePictureRequest {
  picture?: PictureModel | null;
}

export interface UpdatePictureRequest {
  picture?: PictureModel | null;
}

export interface ListAssignedPicturesResponse {
  assignedPictures?: AssignedItemModel[] | null;
}

export interface AssignedItemModel {
  id?: string | null;
  /** @format int32 */
  priority?: number;
}

export interface ListNodeTypesUsingPicturesResponse {
  nodeTypeIds?: string[] | null;
}

export interface PictureNodeTypeModel {
  id?: string | null;
  assignedPictures?: AssignedItemModel[] | null;
}

export interface AssignPictureToNodeTypeRequest {
  nodeTypeId?: string | null;
  assignedPicture?: AssignedItemModel | null;
}

export interface ListSymbolResponse {
  continuationToken?: string | null;
  symbols?: SymbolModel[] | null;
}

export interface SymbolModel {
  id?: string | null;
  name?: string | null;
  description?: string | null;
  data?: string | null;
  /** @format int32 */
  width?: number;
  /** @format int32 */
  height?: number;
  states?: string[] | null;
  connections?: ConnectionModel[] | null;
}

export interface ConnectionModel {
  id?: string | null;
  /** @format float */
  x?: number;
  /** @format float */
  y?: number;
}

export interface CreateSymbolRequest {
  symbol?: SymbolModel | null;
}

export interface UpdateSymbolRequest {
  symbol?: SymbolModel | null;
}

export interface ListTenantDashboardResponse {
  dashboards?: TenantDashboardModel[] | null;
}

export interface TenantDashboardModel {
  id?: string | null;
  tenant?: string | null;
  header?: string | null;
  data?: string | null;
  /** @format int32 */
  defaultPriority?: number;
  allowedNodeTypes?: string[] | null;
}

export interface CreateTenantDashboardRequest {
  dashboard?: TenantDashboardModel | null;
}

export interface UpdateTenantDashboardRequest {
  dashboard?: TenantDashboardModel | null;
}

export interface DatapointDeleteDatapointSamplesParams {
  /** @format date-time */
  startTime?: string;
  /** @format date-time */
  endTime?: string;
  id: string;
}

export interface SymbolsListSymbolsParams {
  continuationToken?: string | null;
  /**
   *  Odata query filter
   * @example "id eq 'abc123'"
   */
  $filter?: string;
  /**
   * OData search query string
   * @example 50
   */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface VersionVersionParams {
  'api-version'?: string | null;
}

/**
 * @title Presentation API
 * @version v1
 * @baseUrl https://app-ec-presentation-test-weu-001-hfkk.azurewebsites.net
 */

import { APIGenType } from './APIGenType';
import {
  ContentType,
  Method,
  apiEndpoint,
  apiEndpointEmpty,
  apiEndpointEmptyUsingQueryParams,
  apiEndpointEmptyWithPath,
  apiEndpointEmptyWithPathUsingQueryParams,
  apiEndpointWithPath
} from './APIUtils';

const apiTitle = APIGenType.PresentationAPIGen;

export interface DatapointDeleteDatapointSamplesPath {
  id: string;
}

export interface DatapointDeleteDatapointWithSamplesPath {
  id: string;
}

export interface PicturesGetPicturePath {
  id: string;
}

export interface PicturesUpdatePicturePath {
  id: string;
}

export interface PicturesDeletePicturePath {
  id: string;
}

export interface PicturesListPicturesByNodeTypeIdPath {
  nodeTypeId: string;
}

export interface PicturesListNodeTypesByPictureIdPath {
  id: string;
}

export interface PicturesAssignPictureToNodeTypePath {
  id: string;
  nodeTypeId: string;
}

export interface PicturesRemovePictureFromNodeTypePath {
  id: string;
  nodeTypeId: string;
}

export interface SymbolsGetSymbolPath {
  id: string;
}

export interface SymbolsUpdateSymbolPath {
  id: string;
}

export interface SymbolsDeleteSymbolPath {
  id: string;
}

export interface TenantDashboardsGetTenantDashboardPath {
  id: string;
}

export interface TenantDashboardsUpdateTenantDashboardPath {
  id: string;
}

export interface TenantDashboardsDeleteTenantDashboardPath {
  id: string;
}

const PresentationAPIGen = {
  Datapoint: {
    deleteDatapointSamples: apiEndpointEmptyWithPathUsingQueryParams<
      Omit<
        DatapointDeleteDatapointSamplesParams,
        keyof DatapointDeleteDatapointSamplesPath
      >,
      void,
      ProblemDetails,
      DatapointDeleteDatapointSamplesPath
    >(
      Method.DELETE,
      ({ id }: DatapointDeleteDatapointSamplesPath) =>
        `/api/v1/datapoints/${id}/samples`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    deleteDatapointWithSamples: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      DatapointDeleteDatapointWithSamplesPath
    >(
      Method.DELETE,
      ({ id }: DatapointDeleteDatapointWithSamplesPath) =>
        `/api/v1/datapoints/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Pictures: {
    listPicturess: apiEndpointEmpty<ListPicturesResponse, any>(
      Method.GET,
      `/api/v1/pictures`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    createPicture: apiEndpoint<
      CreatePictureRequest,
      PictureModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/v1/pictures`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getPicture: apiEndpointEmptyWithPath<
      PictureModel,
      ProblemDetails,
      PicturesGetPicturePath
    >(
      Method.GET,
      ({ id }: PicturesGetPicturePath) => `/api/v1/pictures/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    updatePicture: apiEndpointWithPath<
      UpdatePictureRequest,
      PictureModel,
      ProblemDetails,
      PicturesUpdatePicturePath
    >(
      Method.PUT,
      ({ id }: PicturesUpdatePicturePath) => `/api/v1/pictures/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    deletePicture: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      PicturesDeletePicturePath
    >(
      Method.DELETE,
      ({ id }: PicturesDeletePicturePath) => `/api/v1/pictures/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    listPicturesByNodeTypeId: apiEndpointEmptyWithPath<
      ListAssignedPicturesResponse,
      ProblemDetails,
      PicturesListPicturesByNodeTypeIdPath
    >(
      Method.GET,
      ({ nodeTypeId }: PicturesListPicturesByNodeTypeIdPath) =>
        `/api/v1/pictures/nodetypes/${nodeTypeId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    listNodeTypesByPictureId: apiEndpointEmptyWithPath<
      ListNodeTypesUsingPicturesResponse,
      ProblemDetails,
      PicturesListNodeTypesByPictureIdPath
    >(
      Method.GET,
      ({ id }: PicturesListNodeTypesByPictureIdPath) =>
        `/api/v1/pictures/${id}/nodetypes`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    assignPictureToNodeType: apiEndpointWithPath<
      AssignPictureToNodeTypeRequest,
      PictureNodeTypeModel,
      ProblemDetails,
      PicturesAssignPictureToNodeTypePath
    >(
      Method.POST,
      ({ id, nodeTypeId }: PicturesAssignPictureToNodeTypePath) =>
        `/api/v1/pictures/${id}/nodetypes/${nodeTypeId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    removePictureFromNodeType: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      PicturesRemovePictureFromNodeTypePath
    >(
      Method.DELETE,
      ({ id, nodeTypeId }: PicturesRemovePictureFromNodeTypePath) =>
        `/api/v1/pictures/${id}/nodetypes/${nodeTypeId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Symbols: {
    listSymbols: apiEndpointEmptyUsingQueryParams<
      SymbolsListSymbolsParams,
      ListSymbolResponse,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/symbols`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    createSymbol: apiEndpoint<CreateSymbolRequest, SymbolModel, ProblemDetails>(
      Method.POST,
      `/api/v1/symbols`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getSymbol: apiEndpointEmptyWithPath<
      SymbolModel,
      ProblemDetails,
      SymbolsGetSymbolPath
    >(
      Method.GET,
      ({ id }: SymbolsGetSymbolPath) => `/api/v1/symbols/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    updateSymbol: apiEndpointWithPath<
      UpdateSymbolRequest,
      SymbolModel,
      ProblemDetails,
      SymbolsUpdateSymbolPath
    >(
      Method.PUT,
      ({ id }: SymbolsUpdateSymbolPath) => `/api/v1/symbols/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    deleteSymbol: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      SymbolsDeleteSymbolPath
    >(
      Method.DELETE,
      ({ id }: SymbolsDeleteSymbolPath) => `/api/v1/symbols/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  TenantDashboards: {
    listTenantDashboards: apiEndpointEmpty<
      ListTenantDashboardResponse,
      ProblemDetails
    >(
      Method.GET,
      `/api/v1/admin/tenant/dashboards`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    createTenantDashboard: apiEndpoint<
      CreateTenantDashboardRequest,
      TenantDashboardModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/v1/admin/tenant/dashboards`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getTenantDashboard: apiEndpointEmptyWithPath<
      TenantDashboardModel,
      ProblemDetails,
      TenantDashboardsGetTenantDashboardPath
    >(
      Method.GET,
      ({ id }: TenantDashboardsGetTenantDashboardPath) =>
        `/api/v1/admin/tenant/dashboards/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    updateTenantDashboard: apiEndpointWithPath<
      UpdateTenantDashboardRequest,
      TenantDashboardModel,
      ProblemDetails,
      TenantDashboardsUpdateTenantDashboardPath
    >(
      Method.PUT,
      ({ id }: TenantDashboardsUpdateTenantDashboardPath) =>
        `/api/v1/admin/tenant/dashboards/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    deleteTenantDashboard: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      TenantDashboardsDeleteTenantDashboardPath
    >(
      Method.DELETE,
      ({ id }: TenantDashboardsDeleteTenantDashboardPath) =>
        `/api/v1/admin/tenant/dashboards/${id}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Version: {
    version: apiEndpointEmptyUsingQueryParams<VersionVersionParams, File, any>(
      Method.GET,
      `/Version`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  }
};

export default PresentationAPIGen;
