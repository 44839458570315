// Auto generated file! Do not edit!
export type ApiConfigType = {
  ectotableClientUrl: string;
  ectotableUrl: string;
  url: string;
  integrationUrl: string;
  integrationAdminUrl: string;
  identityServiceUrl: string;
  identityServiceV2Url: string;
  netmoreUrl: string;
  ectoplannerUrl: string;
  ectoplannerHubsUrl: string;
  jobsV2Url: string;
  deviceUrl: string;
  alarmsUrl: string;
  presentationUrl: string;
  signalRUrl: string;
  smartComfortUrl: string;
  manualAlarmsUrl: string;
};
export type AppScopesType = {
  ectoplanner: string;
  gateway: string;
  identity: string;
  jobs: string;
  device: string;
  alarms: string;
  presentation: string;
  smartcomfort: string;
  manualalarms: string;
};
export type ApiEnvironmentConfig = {
  name: string;
  authority: '';
  clientId: string;
  scopes: AppScopesType;
  knownAuthorities: string[];
  urls: ApiConfigType;
};
export const emptyEnvironment: ApiEnvironmentConfig = Object.freeze({
  name: '',
  authority: '',
  clientId: '',
  scopes: {
    ectoplanner: '',
    gateway: '',
    identity: '',
    jobs: '',
    device: '',
    alarms: '',
    presentation: '',
    smartcomfort: '',
    manualalarms: ''
  },
  knownAuthorities: [],
  urls: {
    ectotableClientUrl: '',
    ectotableUrl: '',
    url: '',
    integrationUrl: '',
    integrationAdminUrl: '',
    identityServiceUrl: '',
    identityServiceV2Url: '',
    netmoreUrl: '',
    ectoplannerUrl: '',
    ectoplannerHubsUrl: '',
    jobsV2Url: '',
    deviceUrl: '',
    alarmsUrl: '',
    presentationUrl: '',
    signalRUrl: '',
    smartComfortUrl: '',
    manualAlarmsUrl: ''
  }
});
