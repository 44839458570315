import React, { MouseEventHandler, useEffect, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Prompt } from 'react-router';
import UUID from 'uuidjs';

import AddButton from 'ecto-common/lib/Button/AddButton';
import DeleteButton from 'ecto-common/lib/Button/DeleteButton';
import EditButton from 'ecto-common/lib/Button/EditButton';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import T from 'ecto-common/lib/lang/Language';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import staticDataTableStyles from 'ecto-common/lib/StaticDataTable/StaticDataTable.module.css';

import { SignalModbusConfigMode } from 'js/components/ModbusLayout/ModbusTypes';
import {
  handleModbusPropertyChange,
  translateBitmaskTypes,
  validateSignalCategoryChange
} from 'js/components/ModbusLayout/ModbusEditUtils';
import ModbusSettingDialog from 'js/components/ManageTemplates/ModbusTemplates/ModbusSettingDialog';

import styles from './EditSignalProviderType.module.css';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { getSignalTypeName } from 'ecto-common/lib/SignalSelector/SignalUtils';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import {
  GetEnumsAndFixedConfigurationsResponseModel,
  SignalProviderType,
  SignalTypeResponseModel
} from 'ecto-common/lib/API/APIGen';
import {
  AlarmOrEqTemplate,
  SignalProviderInput
} from 'js/components/ManageTemplates/manageTemplatesTypes';

type EditSignalProviderTypeSignalTemplateProps = {
  signalInputProvider: SignalProviderInput;
  signalInputsToHideForTable?: string[];
  signalProviderType: SignalProviderType;
  initialSignals?: AlarmOrEqTemplate[];
  onSignalsChanged?: (signals: AlarmOrEqTemplate[]) => void;
  allowDeleteForSignal?: (signal: AlarmOrEqTemplate) => boolean;
  hasUnsavedChanges: boolean;
  onSaveTemplates: MouseEventHandler<HTMLButtonElement>;
  isLoading: boolean;
};

type GetSignalStateResult = {
  signalTemplates: AlarmOrEqTemplate[];
};

function getSignalState(templates: AlarmOrEqTemplate[]): GetSignalStateResult {
  let signalTemplates = _.cloneDeep(templates);
  signalTemplates = translateBitmaskTypes(signalTemplates);
  return { signalTemplates };
}

type EditSignalProviderTypeSignalTemplateState = {
  signal: AlarmOrEqTemplate;
  signalTemplates: AlarmOrEqTemplate[];
  modbusEditIndex: number;
  configType: SignalModbusConfigMode;
  initialSignals: AlarmOrEqTemplate[];
  deleteDialogIsOpen: boolean;
  signalToRemove: AlarmOrEqTemplate;
};

const EditSignalProviderTypeSignalTemplate = ({
  signalInputProvider,
  initialSignals,
  onSignalsChanged,
  signalInputsToHideForTable = [],
  signalProviderType,
  hasUnsavedChanges,
  onSaveTemplates,
  isLoading,
  allowDeleteForSignal
}: EditSignalProviderTypeSignalTemplateProps) => {
  const enums: GetEnumsAndFixedConfigurationsResponseModel = useAdminSelector(
    (state) => state.general.enums
  );
  const signalTypesMap: Record<string, SignalTypeResponseModel> =
    useAdminSelector((state) => state.general.signalTypesMap);

  const signalState = getSignalState(initialSignals ?? []);
  const [state, setState] = useState<EditSignalProviderTypeSignalTemplateState>(
    {
      signal: { ...signalInputProvider.emptySignal },
      signalTemplates: [],
      modbusEditIndex: -1,
      configType: SignalModbusConfigMode.SLAVE,
      initialSignals: [],
      deleteDialogIsOpen: false,
      signalToRemove: null,
      ...signalState
    }
  );

  useEffect(() => {
    setState((oldState) => ({
      ...oldState,
      ...getSignalState(initialSignals ?? [])
    }));
  }, [initialSignals]);

  const addSignal = () => {
    const signalTemplates = _.cloneDeep(state.signalTemplates);

    if (state.signal.signalTypeId == null) {
      toastStore.addErrorToast(T.admin.equipmenttemplates.error.missingtype);
      return;
    } else if (isNullOrWhitespace(state.signal.name)) {
      toastStore.addErrorToast(T.admin.equipmenttemplates.error.missingname);
      return;
    }

    signalTemplates.push({
      ..._.cloneDeep(state.signal),
      id: UUID.generate()
    });

    setState((oldState) => ({
      ...oldState,
      signalTemplates,
      signal: {
        ..._.cloneDeep(signalInputProvider.emptySignal)
      }
    }));

    onSignalsChanged(signalTemplates);
  };

  const removeSignal = (signal: AlarmOrEqTemplate) => {
    const _signalTemplates = _.cloneDeep(signalTemplates);
    _.remove(_signalTemplates, { id: signal.id });
    setState((oldState) => ({
      ...oldState,
      signalTemplates: _signalTemplates
    }));
    onSignalsChanged(_signalTemplates);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeModbusProperty = (name: string, value: any) => {
    let signalTemplates = _.cloneDeep(state.signalTemplates);
    const idx = state.modbusEditIndex;

    if (signalTemplates[idx].signalSettings == null) {
      signalTemplates[idx].signalSettings = {};
    }

    let modbus = signalTemplates[idx].signalSettings[state.configType];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    modbus = handleModbusPropertyChange(modbus as any, name, value);
    signalTemplates[idx].signalSettings[state.configType] = modbus;
    setState((oldState) => ({ ...oldState, signalTemplates }));
    onSignalsChanged(signalTemplates);
  };

  const navigateSignal = (offset: number) => {
    const signals = state.signalTemplates;
    let index = (state.modbusEditIndex + offset) % signals.length;
    if (index < 0) {
      index = signals.length - 1;
    }
    setState((oldState) => ({ ...oldState, modbusEditIndex: index }));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setSignalProperty = (index: number, name: string, value: any) => {
    if (
      !validateSignalCategoryChange(
        state.signalTemplates[index],
        signalInputProvider.type,
        name,
        value
      )
    ) {
      return;
    }

    const templates = _.cloneDeep(state.signalTemplates);

    if (name === 'signalTypeId') {
      const item = templates[index];
      const prevSignalType = signalTypesMap[item.signalTypeId];
      const signalType = signalTypesMap[value as string];

      if (signalType != null) {
        if (
          isNullOrWhitespace(item.name) ||
          item.name === prevSignalType?.name
        ) {
          _.set(templates[index], 'name', signalType.name);
        }

        if (
          isNullOrWhitespace(item.description) ||
          item.description === prevSignalType?.description
        ) {
          _.set(templates[index], 'description', signalType.description);
        }
      }
    }

    _.set(templates[index], name, value);

    setState((oldState) => ({ ...oldState, signalTemplates: templates }));
    onSignalsChanged(templates);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChangeSignalProperty = (name: string, value: any) =>
    setSignalProperty(state.modbusEditIndex, name, value);
  const signalInputs = signalInputProvider
    .inputs(enums)
    .filter(({ type }) => !signalInputsToHideForTable.includes(type));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSetNewSignalValue = (name: string, value: any) => {
    const newSignal = _.cloneDeep(state.signal);
    _.set(newSignal, name, value);

    // Slightly ugly workaround to copy description from signal type whenever it changes.
    // Ideally we should port this component over to use ModelForm instead of this custom code.
    if (name === 'signalTypeId') {
      const signalType = signalTypesMap[value as string];
      _.set(newSignal, 'description', signalType?.description);
      _.set(newSignal, 'name', signalType?.name);
    }

    setState((oldState) => ({ ...oldState, signal: newSignal }));
  };

  const onOpenDeleteSignalDialog = (signal: AlarmOrEqTemplate) => {
    setState((oldState) => ({
      ...oldState,
      deleteDialogIsOpen: true,
      signalToRemove: signal
    }));
  };

  const onCloseDeleteSignalDialog = () => {
    setState((oldState) => ({
      ...oldState,
      deleteDialogIsOpen: false,
      signalToRemove: null
    }));
  };

  const onConfirmDeleteSignal = () => {
    removeSignal(state.signalToRemove);
    onCloseDeleteSignalDialog();
  };

  const signalTemplates: AlarmOrEqTemplate[] = _.cloneDeep(
    state.signalTemplates
  );
  const { configType } = state;

  const tableHeaders = [
    <tr key="templateHeaders">
      {signalInputs.map((input, inputIndex) => (
        <th key={inputIndex}>{input.name}</th>
      ))}
    </tr>
  ];

  const isEditingSignal = true;

  const tableRows = signalTemplates.map((signal, idx) => (
    <tr key={signal.id || idx}>
      {signalInputs.map((signalInput, signalInputIndex) => {
        return (
          <td key={signalInputIndex}>
            {signalInput.render({
              signal,
              onChange: (name: string, value) =>
                setSignalProperty(idx, name, value)
            })}
          </td>
        );
      })}
      <td
        className={classNames(
          staticDataTableStyles.minWidthColumn,
          styles.buttonCellContainer
        )}
      >
        <DeleteButton
          disabled={allowDeleteForSignal && !allowDeleteForSignal(signal)}
          isIconButton
          onClick={() => onOpenDeleteSignalDialog(signal)}
        />

        <EditButton
          isIconButton
          onClick={() =>
            setState((oldState) => ({ ...oldState, modbusEditIndex: idx }))
          }
        />
      </td>
    </tr>
  ));

  return (
    <>
      <Prompt when={hasUnsavedChanges} message={T.admin.form.unsavedstate} />

      <table className={staticDataTableStyles.staticDataTable}>
        <thead>{tableHeaders}</thead>

        <tbody>
          {tableRows}
          <tr>
            {signalInputs.map((signalInput, signalInputIndex) => {
              return (
                <td key={signalInputIndex}>
                  {signalInput.render({
                    signal: state.signal,
                    onChange: onSetNewSignalValue
                  })}
                </td>
              );
            })}
            <td
              className={staticDataTableStyles.minWidthColumn}
              style={{ textAlign: 'right' }}
            >
              <AddButton onClick={addSignal}>
                {T.admin.alarmtemplates.addsignal}
              </AddButton>
            </td>
          </tr>
        </tbody>
      </table>

      <div className={styles.buttonContainer}>
        <LocalizedButtons.Save
          disabled={!hasUnsavedChanges || isLoading}
          onClick={onSaveTemplates}
        />
      </div>

      <ModbusSettingDialog
        isEditingSignal={isEditingSignal}
        onChangeConfigType={(e: SignalModbusConfigMode) =>
          setState((oldState) => ({ ...oldState, configType: e }))
        }
        modbusMode={configType}
        selectedSignal={{
          type: signalProviderType,
          ...state.signalTemplates[state.modbusEditIndex]
        }}
        onPrevClicked={() => navigateSignal(-1)}
        onNextClicked={() => navigateSignal(1)}
        onChangeSignalProperty={onChangeSignalProperty}
        onChangeModbusProperty={onChangeModbusProperty}
        onModalClose={() =>
          setState((oldState) => ({ ...oldState, modbusEditIndex: -1 }))
        }
        isOpen={state.modbusEditIndex !== -1}
      />

      <ConfirmDeleteDialog
        isOpen={state.deleteDialogIsOpen}
        onModalClose={onCloseDeleteSignalDialog}
        onDelete={onConfirmDeleteSignal}
        itemName={
          isNullOrWhitespace(state.signalToRemove?.name)
            ? getSignalTypeName(
                state.signalToRemove?.signalTypeId,
                signalTypesMap
              )
            : state.signalToRemove?.name
        }
      />
    </>
  );
};

export default React.memo(EditSignalProviderTypeSignalTemplate);
