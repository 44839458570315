import React from 'react';
import styles from './AcknowledgeButton.module.css';
import Button from 'ecto-common/lib/Button/Button';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import { AcknowledgeAlarmModal } from './AcknowledgeAlarmModal';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { AlarmInfoResponseModel } from 'ecto-common/lib/API/APIGen';

type AcknowledgeButtonProps = {
  node: SingleGridNode;
  alarm: AlarmInfoResponseModel;
};

const AcknowledgeButton = ({ node, alarm }: AcknowledgeButtonProps) => {
  const [isAcknowledgeModalOpen, showAcknowledgeModal, hideAcknowledgeModal] =
    useDialogState(false);
  const needAcknowledgment = alarm?.needAcknowledgment;

  const { isAcknowledged, acknowledgedBy, alarmCommentMessage } = alarm;

  if (isAcknowledged) {
    return (
      <div>
        <strong>{acknowledgedBy || 'Unknown'}</strong>
        {': '}
        {alarmCommentMessage || <em>{T.alarms.acknowledgedwithoutcomment}</em>}
      </div>
    );
  }

  return (
    <div>
      <Button
        className={styles.button}
        onClick={showAcknowledgeModal}
        disabled={!needAcknowledgment}
      >
        <Icons.Checkmark />
        {T.alarms.acknowledge.action}
      </Button>

      <AcknowledgeAlarmModal
        isOpen={isAcknowledgeModalOpen}
        onModalClose={hideAcknowledgeModal}
        node={node}
        alarm={alarm}
        onSuccess={hideAcknowledgeModal}
      />
    </div>
  );
};

export default AcknowledgeButton;
