import { Dispatch, SetStateAction, useCallback, useState } from 'react';

const useDialogState = (
  defaultValue: boolean
): [boolean, () => void, () => void, Dispatch<SetStateAction<boolean>>] => {
  const [dialogVisible, setDialogVisible] = useState<boolean>(defaultValue);
  const showDialog: () => void = useCallback(() => setDialogVisible(true), []);
  const hideDialog: () => void = useCallback(() => setDialogVisible(false), []);
  return [dialogVisible, showDialog, hideDialog, setDialogVisible];
};

export default useDialogState;
