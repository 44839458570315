import React, { useContext, useMemo, useState } from 'react';
import _ from 'lodash';

import SignalsTable, {
  NonSelectableColumns,
  SIGNALS_TABLE_COLUMNS,
  SignalsTableSignal
} from 'ecto-common/lib/SignalsTable/SignalsTable';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import HelpPaths from 'ecto-common/help/tocKeys';
import T from 'ecto-common/lib/lang/Language';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import {
  LastSignalValuesDataSourceResult,
  SignalInputType
} from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';
import { LastSignalValuesDataSet } from 'ecto-common/lib/hooks/useLatestSignalValues';
import DataSourceTypes from 'ecto-common/lib/Dashboard/datasources/DataSourceTypes';
import APIGen from 'ecto-common/lib/API/APIGen';
import {
  ModelDefinition,
  ModelFormSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';
import { CustomPanelProps } from 'ecto-common/lib/Dashboard/Panel';

const COLUMN_SETTINGS = {
  includeEquipmentName: true
};

export type LastSignalValuesDataSetWithMetadata = LastSignalValuesDataSet & {
  step?: number;
  isWritable?: boolean;
};

export type LastSignalValuesResultWithMetadata = Record<
  string,
  LastSignalValuesDataSetWithMetadata
>;

type SignalListPanelConfig = {
  signalColumns: string[];
};

type SignalListPanelProps = CustomPanelProps & {
  data: {
    signal: LastSignalValuesDataSourceResult;
  } & SignalListPanelConfig;
};

// Empty function, use to get selection style cursors in table view
const onClickRow = () => {};

const SignalListPanel = ({ data }: SignalListPanelProps) => {
  const { signalTypesMap } = useContext(DashboardDataContext);
  const { nodes, isLoading, hasError, signalInfo } = data.signal;

  const equipmentIds = _.flatMap(nodes, (node) =>
    _.map(node.equipments, 'equipmentId')
  );
  const allNodeIds = _.map(nodes, 'nodeId').concat(equipmentIds);

  // TODO: We should request per signal ID here, not per node ID. Now we fetch info for all sibling signals, not just
  // the ones we are interested in.
  const { data: signalViews, isLoading: isLoadingSignalViews } =
    APIGen.SignalViews.getNodeSignalViews.useQuery(
      { nodeIds: allNodeIds },
      {
        enabled: nodes.length > 0
      }
    );

  const { signalIdToProviderId, signalProviders, matchingSignals } = signalInfo;
  const [currentEditSignal, setCurrentEditSignal] =
    useState<SignalsTableSignal>(null);
  const otherSignals = useMemo(() => {
    return _.flatMap(_.values(signalProviders), (provider) => provider.signals);
  }, [signalProviders]);

  const signalValuesById: LastSignalValuesResultWithMetadata = useMemo(() => {
    let accumulator: LastSignalValuesResultWithMetadata = {};
    const eqSignals = _.flatMap(signalViews, (view) => view.equipmentSignals);
    const eqSignalsKeyed = _.keyBy(eqSignals, (signal) => signal.signalId);

    return _.reduce(
      data.signal.signalValues,
      (cur, signal) => {
        const metadata = eqSignalsKeyed[signal.signalId];
        cur[signal.signalId] = {
          values: [signal],
          step: metadata?.step
        };

        return cur;
      },
      accumulator
    );
  }, [data.signal.signalValues, signalViews]);

  const mappedSignals: SignalsTableSignal[] = useMemo(
    () =>
      _(matchingSignals)
        .orderBy('signalInfo.matchIndex')
        .map(({ signal }) => {
          const signalType = signalTypesMap?.[signal.signalTypeId];
          const providerId = signalIdToProviderId?.[signal.signalId];
          const signalProvider = signalProviders?.[providerId];
          const signalData = signalValuesById?.[signal.signalId];
          const lastValue = _.head(signalData?.values);

          return {
            ...signal,
            ...signalData,
            signalProvider,
            signalType,
            rawSignal: signal,
            value: lastValue?.value,
            time: lastValue?.time
          };
        })
        .value(),
    [
      matchingSignals,
      signalTypesMap,
      signalIdToProviderId,
      signalProviders,
      signalValuesById
    ]
  );

  const columnKeys = data?.signalColumns || [];

  return (
    <SignalsTable
      signals={mappedSignals}
      signalValuesById={signalValuesById}
      otherSignals={otherSignals}
      setCurrentEditSignal={setCurrentEditSignal}
      currentEditSignal={currentEditSignal}
      isLoading={isLoading || isLoadingSignalViews}
      hasError={hasError}
      columnKeys={columnKeys}
      columnSettings={COLUMN_SETTINGS}
      onClickRow={onClickRow}
    />
  );
};

const AllColumns = _.map(SIGNALS_TABLE_COLUMNS, ({ dataKey, label }) => ({
  value: dataKey,
  label
}));

const SignalColumnsOptions = _.filter(
  AllColumns,
  (column) => !_.includes(NonSelectableColumns, column.value)
);

const sections: ModelFormSectionType<SignalListPanelConfig>[] = [
  {
    label: T.common.columns,
    lines: [
      {
        models: [
          {
            key: (input) => input.signalColumns,
            modelType: ModelType.CHECKBOX_OPTIONS,
            label: 'displayName',
            isMulti: true,
            options: SignalColumnsOptions
          }
        ]
      }
    ]
  }
];

export const SignalListPanelData = {
  minWidth: 550,
  helpPath: HelpPaths.docs.dashboard.dashboards.signal_list,
  sections,
  emptyTargets: {
    signal: {
      sourceType: DataSourceTypes.SIGNALS_LAST_VALUE,
      arguments: {
        minItems: 1,
        optionalSignalModels: [] as ModelDefinition<SignalInputType, object>[]
      }
    }
  }
};

export default React.memo(SignalListPanel);
