import React, { useState, useEffect, useCallback, useMemo } from 'react';
import classNames from 'classnames';

import Spinner from 'ecto-common/lib/Spinner/Spinner';
import T from 'ecto-common/lib/lang/Language';
import {
  getSpinnerSizePixels,
  SpinnerSize
} from 'ecto-common/lib/Spinner/Spinner';
import styles from 'ecto-common/lib/LoadingContainer/LoadingContainer.module.css';

const LONG_LOADING_TIME = 5000; // 5s is considered a long time
import useTimeout from 'ecto-common/lib/hooks/useTimeout';

interface LoadingContainerProps {
  /**
   *  The content to show a loading state for.
   */
  children?: React.ReactNode;
  /**
   *  Whether or not the loading effect is active.
   */
  isLoading: boolean;
  /**
   * Used to override the appearance of the loading container. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * Used to override the appearance of the loading container.
   */
  style?: object;
  /**
   * Whether or not to show a centered spinner.
   */
  showSpinner?: boolean;
  /**
   * Whether or not the component should blur its content when loading.
   */
  blur?: boolean;
  /**
   * The size of the spinner that is displayed when loading
   */
  spinnerSize?: SpinnerSize;
}

/**
 * This component can be used to show a loading state for its children. The content will be blurred with an optional spinner.
 *
 * NOTE: This component does some unexpected things such as making its children expand to take full width. Overall it might be a candidate for removal or refactoring.
 */
const LoadingContainer = ({
  children,
  isLoading,
  className,
  showSpinner,
  blur,
  spinnerSize,
  style
}: LoadingContainerProps) => {
  const [longLoad, setLongLoad] = useState<boolean>(null);
  const loadingStyle = blur ? styles.isLoading : styles.noBlurLoading;
  const spinnerStyle = useMemo(() => {
    return { marginTop: -(getSpinnerSizePixels(spinnerSize) / 2) };
  }, [spinnerSize]);

  const [startTimer, stopTimer] = useTimeout(
    useCallback(() => setLongLoad(true), []),
    LONG_LOADING_TIME
  );

  useEffect(() => {
    if (!isLoading && longLoad != null) {
      stopTimer();
      setLongLoad(false);
    }

    if (isLoading && longLoad == null) {
      startTimer();
    }

    return () => {
      stopTimer();
    };
  }, [isLoading, longLoad, stopTimer, startTimer]);

  return (
    <div
      className={classNames(
        styles.loadingContainer,
        showSpinner && isLoading && styles.loadingContainerWithSpinner,
        className
      )}
      style={style}
    >
      {isLoading && showSpinner && (
        <div className={styles.spinnerWrapper} style={spinnerStyle}>
          <Spinner className={styles.spinner} size={spinnerSize} />

          {longLoad && (
            <div className={styles.longLoad}>
              {T.loadingcontainer.longerthanexpected}
            </div>
          )}
        </div>
      )}

      <div className={classNames(styles.children, isLoading && loadingStyle)}>
        {children}
      </div>
    </div>
  );
};

LoadingContainer.defaultProps = {
  showSpinner: true,
  blur: true,
  spinnerSize: SpinnerSize.MEDIUM
};

export default LoadingContainer;
