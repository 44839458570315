import { ROOT_NODE_ID } from '../constants';
import _ from 'lodash';
import T, { UnlocalizedString } from '../lang/Language';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import {
  EquipmentResponseModel,
  GridType,
  NodeResponseModel,
  NodeType
} from 'ecto-common/lib/API/APIGen';
import {
  LocationTreeEquipmentOrNode,
  LocationTreeViewRowType
} from 'ecto-common/lib/LocationTreeView/LocationTreeViewRow';

/**
 * A key representation of node and key in nodeMap or equipmentMap
 * @see createNodeMap
 * @see createEquipmentMap
 * @param nodeId
 * @param grid
 * @returns {*}
 */
const nodeKey = (nodeId: string) => (nodeId ? nodeId : null);
export function getNodeFromMap<NodeType>(
  nodeMap: Record<string, NodeType>,
  nodeId: string
): NodeType {
  return nodeMap?.[nodeKey(nodeId)];
}

export function getFullPathFromMap(
  nodeMap: Record<string, SingleGridNode>,
  nodeId: string,
  lastParentId: string = null,
  excludeLastParent = false
) {
  let fullPath = getFullPathMap(nodeMap, nodeId);
  let lastParentIndex = fullPath.findIndex(
    (pathLocation) => pathLocation.nodeId === lastParentId
  );

  if (lastParentIndex !== -1) {
    fullPath = fullPath.slice(
      excludeLastParent ? lastParentIndex + 1 : lastParentIndex
    );
  }

  return fullPath.map((pathLocation) => pathLocation.name).join(' > ');
}

export function getFullPathMap(
  nodeMap: Record<string, SingleGridNode>,
  nodeId: string
) {
  if (nodeId == null || _.isEmpty(nodeMap)) {
    return [];
  }
  let node = getNodeFromMap(nodeMap, nodeId);
  if (node == null) {
    return [];
  }

  const pathOfInterest = [node];

  while (node && node.parentIds != null) {
    let parentNode = null;
    for (let parentId of node.parentIds) {
      parentNode = getNodeFromMap(nodeMap, parentId);
      if (parentNode != null) {
        pathOfInterest.push(parentNode);
        break;
      }
    }
    node = parentNode;
  }

  return _.reverse(pathOfInterest);
}

/**
 * Given a tree of locations and one location object, return the full path to it
 * @param nodeTree
 * @param nodeId
 * @param grid
 * @param lastParentId
 * @param excludeLastParent
 * @returns {String} returns path/to/nodeId
 */
export function getFullPath(
  nodeTree: SingleGridNode[],
  nodeId: string,
  lastParentId: string,
  excludeLastParent: boolean
) {
  let fullPath = getFullPath2(nodeTree, nodeId);

  let lastParentIndex = fullPath.findIndex(
    (pathLocation) => pathLocation.nodeId === lastParentId
  );

  if (lastParentIndex !== -1) {
    fullPath = fullPath.slice(
      excludeLastParent ? lastParentIndex + 1 : lastParentIndex
    );
  }

  return fullPath.map((pathLocation) => pathLocation.name).join(' > ');
}

export function getFullPath2(
  nodeTree: SingleGridNode[],
  itemId: string
): LocationTreeEquipmentOrNode[] {
  if (itemId == null || nodeTree.length === 0) {
    return [];
  }

  const pathOfInterest: LocationTreeEquipmentOrNode[] = [];

  findParent(nodeTree);

  function findParent(children: SingleGridNode[]) {
    for (let i = 0; i < children.length; i++) {
      const child = children[i];

      const arrayKeys = [];

      // Loop through every conceivable path in the structure
      for (const property in child) {
        if (Object.prototype.hasOwnProperty.call(child, property)) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((child as any)[property] === itemId) {
            pathOfInterest.unshift(child);

            return true;
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if (Array.isArray((child as any)[property])) {
            arrayKeys.push(property);
          }
        }
      }

      for (let j = 0; j < arrayKeys.length; j++) {
        const key = arrayKeys[j];

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (findParent((child as any)[key])) {
          pathOfInterest.unshift(child);

          return true;
        }
      }
    }

    return false;
  }

  return pathOfInterest;
}

/**
 *
 * @param dataTree
 * @param predicateFn
 */
export function findInTree(
  dataTree: SingleGridNode[],
  predicateFn: (node: SingleGridNode | EquipmentResponseModel) => boolean
): SingleGridNode | EquipmentResponseModel {
  if (predicateFn == null) {
    throw new Error('No predicate function supplied');
  }

  return findLocation(dataTree);

  function findLocation(
    tree: SingleGridNode[] = []
  ): SingleGridNode | EquipmentResponseModel {
    for (let i = 0; i < tree.length; i++) {
      const item = tree[i];

      if (predicateFn(item)) {
        return item;
      }

      for (const property in item) {
        if (
          Object.prototype.hasOwnProperty.call(item, property) &&
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Array.isArray((item as any)[property])
        ) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const child = findLocation((item as any)[property]);

          if (child) {
            return child;
          }
        }
      }
    }

    return null;
  }
}

/**
 * Creates a dictionary where each item is mapped with nodeId and grid, using nodeKey
 * @see nodeKey
 * @param nodeTree
 * @returns {*}
 */
export const createNodeMap = (nodeTree: SingleGridNode[]) => {
  return reduceTree<SingleGridNode>(
    nodeTree,
    (acc, item) => {
      acc[nodeKey(item.nodeId)] = item;
      return acc;
    },
    {}
  );
};

/**
 * Creates a dictionary where each item is mapped with equipmentId and grid, nodeKey
 * @see nodeKey
 * @param nodeTree
 * @returns {*}
 */
export const createEquipmentMap = (nodeTree: SingleGridNode[]) => {
  return reduceTree<EquipmentResponseModel>(
    nodeTree,
    (acc, item) => {
      _.forEach(item.equipments, (equipment) => {
        acc[nodeKey(equipment.equipmentId)] = equipment;
      });
      return acc;
    },
    {}
  );
};

/**
 * Reduces dataTree (depth-first) to a value which is the accumulated result of running each element in collection thru iteratee,
 * where each successive invocation is supplied the return value of the previous. If accumulator is not given, the
 * first element of dataTree is used as the initial value. The iteratee is invoked with two arguments:
 * (accumulator, value).
 * @param dataTree
 * @param iteratee
 * @param accumulator
 */
export function reduceTree<ReturnType>(
  dataTree: SingleGridNode[],
  iteratee: (
    acc: Record<string, ReturnType>,
    item: SingleGridNode
  ) => Record<string, ReturnType>,
  accumulator: Record<string, ReturnType>
) {
  for (let i = 0; i < dataTree.length; i++) {
    const item = dataTree[i];
    accumulator = iteratee(accumulator, item);
    if (item.children) {
      accumulator = reduceTree(item.children, iteratee, accumulator);
    }
  }
  return accumulator;
}

export function searchResultsNodeTree(
  parentNode: SingleGridNode,
  list: SingleGridNode[],
  selectEquipment: boolean,
  searchPath: string[] = [],
  nodePath = ''
): LocationTreeViewRowType[] {
  let results: LocationTreeViewRowType[] = [];

  for (let location of list) {
    if (location.children != null) {
      results = [
        ...results,
        ...searchResultsNodeTree(
          location,
          location.children,
          selectEquipment,
          searchPath.concat(location.name),
          nodePath + '.' + location.nodeId
        )
      ];
    }

    if (selectEquipment && location.equipments != null) {
      results = [
        ...results,
        ...location.equipments.map((equipment) => ({
          node: { ...equipment, nodeId: equipment.equipmentId },
          parentNode: location,
          searchPath: searchPath.join(' > ') + ' > ' + location.name,
          prefix: '',
          parentPath: '',
          path: nodePath + '.' + equipment.equipmentId
        }))
      ];
    }

    if (!isRootNodeId(location.nodeId)) {
      results.push({
        node: location,
        searchPath: searchPath.join(' > '),
        parentNode,
        prefix: '',
        parentPath: '',
        path: nodePath + '.' + location.nodeId
      });
    }
  }

  return results;
}

export function filteredNodeTree(
  list: SingleGridNode[],
  searchFilter: (node: SingleGridNode) => boolean
): SingleGridNode[] {
  let tree = [];

  for (let location of list) {
    const childLocations = filteredNodeTree(location.children, searchFilter);

    if (searchFilter(location) || childLocations.length > 0) {
      tree.push(Object.assign({}, location, { children: childLocations }));
    }
  }
  return tree;
}

type NodeResponseModelWithParentId = NodeResponseModel & {
  parentId?: string;
};

export function createFlatNodeTree(
  gridTypes: GridType[],
  nodeListArg: NodeResponseModel[]
): {
  gridTypes: GridType[];
  nodeTree: SingleGridNode[];
} {
  const nodeList: NodeResponseModelWithParentId[] = _.cloneDeep(nodeListArg);

  // Only used for equipment types
  gridTypes = gridTypes.filter((x) => x !== 'Inherited');

  nodeList.forEach((n) => {
    // TODO: Add proper unpacking support for multiple parent ID:s
    if (n.parentIds.length > 0) {
      n.parentId = n.parentIds[0];
    } else {
      n.parentId = null;
    }
  });

  let singleGridNodes: SingleGridNode[] = nodeList.map((node) => ({
    ...node,
    grid: node.grids[0],
    children: [],
    parentId: node.parentIds[0]
  }));

  const nodeLookup: Record<string, SingleGridNode> = _.keyBy(
    singleGridNodes,
    'nodeId'
  );
  let rootLevelNodes: SingleGridNode[] = [];

  singleGridNodes.forEach((node) => {
    const parent = nodeLookup[node.parentId];
    if (parent != null) {
      parent.children.push(node);
    } else {
      rootLevelNodes.push(node);
    }
  });

  rootLevelNodes.forEach(sortNode);

  return { gridTypes, nodeTree: rootLevelNodes };
}

function sortNode(node: SingleGridNode) {
  node.children = _.sortBy(node.children, 'name');
  node.children.forEach(sortNode);
}

export function localizeLocationType(type: NodeType) {
  const property = type.toLowerCase() as keyof typeof T.nodetypes;
  return T.nodetypes[property] === UnlocalizedString
    ? type
    : T.nodetypes[property];
}

export function localizeGridName(grid: GridType): string {
  const property = grid.toLowerCase() as keyof typeof T.grids;
  return T.grids[property] === UnlocalizedString ? grid : T.grids[property];
}

/**
 * Returns the node that the equipment id exists in.
 * @param equipmentId
 * @param grid
 * @param nodeMap
 * @param equipmentMap
 * @returns {*}
 */
export const getEquipmentNode = (
  equipmentId: string,
  nodeMap: Record<string, SingleGridNode>,
  equipmentMap: Record<string, EquipmentResponseModel>
) => {
  const equipmentNodeId = _.get(
    getNodeFromMap(equipmentMap, equipmentId),
    'nodeId'
  );
  return getNodeFromMap(nodeMap, equipmentNodeId);
};

export const isRootNodeId = (nodeId: string) =>
  _.startsWith(nodeId, ROOT_NODE_ID);

export const isRootNode = (node: SingleGridNode) => isRootNodeId(node.nodeId);
