import React, { useCallback } from 'react';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import API from 'ecto-common/lib/API/API';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import DeleteModal from 'ecto-common/lib/ConfirmDeleteDialog/DeleteModal';
import { MappingResponseModel } from 'ecto-common/lib/API/APIGen';

interface DeleteSignalMappingModalProps {
  deleteItem?: MappingResponseModel;
  onModalClose: () => void;
  onSuccess?(): void;
}

const DeleteSignalMappingModal = ({
  deleteItem,
  onModalClose,
  onSuccess
}: DeleteSignalMappingModalProps) => {
  const [isLoading, deleteMaps] = usePromiseCall({
    promise: API.Admin.Maps.deleteMaps,
    onSuccess: () => {
      toastStore.addSuccessToast(T.admin.signalmapping.request.delete.success);
      onModalClose();
      onSuccess?.();
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.signalmapping.request.delete.failed);
    }
  });

  const _deleteMaps = useCallback(
    () => deleteMaps(deleteItem),
    [deleteMaps, deleteItem]
  );

  return (
    <DeleteModal
      isLoading={isLoading}
      deleteItem={deleteItem}
      onConfirmDelete={_deleteMaps}
      onModalClose={onModalClose}
      itemName={deleteItem?.name}
    />
  );
};

export default React.memo(DeleteSignalMappingModal);
