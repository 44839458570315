import React from 'react';
import Select, {
  GenericSelectOption,
  SelectProps
} from 'ecto-common/lib/Select/Select';
import T from 'ecto-common/lib/lang/Language';
import classNames from 'classnames';
import styles from 'ecto-common/lib/KeyValueInput/KeyValueInput.module.css';
import CreatableSelect from 'ecto-common/lib/Select/CreatableSelect';
import AsyncSelect from 'ecto-common/lib/Select/AsyncSelect';

type KeyValueInternalSelectableInputProps<
  ValueType,
  IsMulti extends boolean
> = SelectProps<ValueType, IsMulti> & {
  hasError?: boolean;
  withCreatableOption?: boolean;

  loadOptions?: (
    search: string,
    loadedOptions: ValueType[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    additional: any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Promise<any>;
};

function KeyValueInternalSelectableInput<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ValueType = GenericSelectOption<any>,
  IsMulti extends boolean = false
>({
  hasError = false,
  placeholder = null,
  className = null,
  withCreatableOption = false,
  loadOptions = null,
  ...otherProps
}: KeyValueInternalSelectableInputProps<ValueType, IsMulti>) {
  const allProps = {
    ...otherProps,
    placeholder:
      placeholder == null ? T.creatableselect.placeholder : placeholder,
    className: classNames(
      styles.selectableInput,
      hasError && styles.errorSelect,
      className
    ),
    loadOptions: loadOptions
  };

  if (loadOptions != null) {
    return <AsyncSelect<ValueType, IsMulti> {...allProps} />;
  } else if (withCreatableOption) {
    // TODO: Silencing this error is not good at all, perhaps we should rethink wrapping
    // CreatableSelect in the same component as other ones
    // @ts-ignore-next-line
    return <CreatableSelect<ValueType, IsMulti> {...allProps} />;
  }

  return <Select<ValueType, IsMulti> {...allProps} />;
}

KeyValueInternalSelectableInput.defaultProps = {
  hasError: false,
  withCreatableOption: false
};

export default KeyValueInternalSelectableInput;
