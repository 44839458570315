import React from 'react';
import Toast from 'ecto-common/lib/Toast/Toast';
import { usePopper } from 'react-popper';
import dimensions from 'ecto-common/lib/styles/dimensions';
import { VirtualElement } from '@popperjs/core';

interface InlineToastProps {
  sourceElement?: Element | VirtualElement;
}

const InlineToast = ({ sourceElement, ...otherProps }: InlineToastProps) => {
  const [popperElement, setPopperElement] = React.useState<HTMLElement>(null);

  const { styles, attributes } = usePopper(sourceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, dimensions.smallMargin]
        }
      }
    ]
  });

  return (
    <Toast
      ref={setPopperElement}
      isInline
      style={styles.popper}
      {...attributes.popper}
      {...otherProps}
    />
  );
};

export default React.memo(InlineToast);
