import React, { MouseEventHandler, useCallback, useRef, useState } from 'react';
import Icons from 'ecto-common/lib/Icons/Icons';
import styles from './CopyToClipboardTooltip.module.css';
import { Modifier, usePopper } from 'react-popper';
import classNames from 'classnames';
import { isTestingWithJest } from 'ecto-common/lib/utils/testEnvironmentUtil';
import * as PopperJS from '@popperjs/core';
import T from 'ecto-common/lib/lang/Language';
import Portal from 'ecto-common/lib/Portal/Portal';
import Transition from 'ecto-common/lib/Transition/Transition';
import cssDurationToMilliseconds from 'ecto-common/lib/utils/cssDurationToMilliseconds';
import animations from 'ecto-common/lib/styles/variables/animations';
import useTimeout from 'ecto-common/lib/hooks/useTimeout';
const tooltipSpeed = cssDurationToMilliseconds(animations.toastSpeed);

type CopyToClipboardTooltipProps = {
  valueToCopy: string;
  additionalText?: React.ReactNode;
  children: React.ReactNode;
};

const transitionStyles = {
  appear: styles.enter,
  appearActive: styles.enterActive,
  enter: styles.enter,
  enterActive: styles.enterActive,
  exit: styles.exit,
  exitActive: styles.exitActive
};

function _mockUsePopper<Modifiers>(
  _referenceElement?: Element | PopperJS.VirtualElement | null,
  _popperElement?: HTMLElement | null,
  _options?: Omit<Partial<PopperJS.Options>, 'modifiers'> & {
    createPopper?: typeof PopperJS.createPopper;
    modifiers?: ReadonlyArray<Modifier<Modifiers>>;
  }
): {
  styles: { [key: string]: React.CSSProperties };
  attributes: { [key: string]: { [key: string]: string } | undefined };
  state: PopperJS.State | null;
  update: PopperJS.Instance['update'] | null;
  forceUpdate: PopperJS.Instance['forceUpdate'] | null;
} {
  return {
    styles: {},
    attributes: {},
    state: null,
    update: null,
    forceUpdate: null
  };
}
const popperModifiers = [
  {
    name: 'preventOverflow',
    options: {
      mainAxis: false
    }
  }
];

const CopyToClipboardTooltip = ({
  children,
  valueToCopy,
  additionalText
}: CopyToClipboardTooltipProps) => {
  const [referenceElement, setReferenceElement] = React.useState<Element>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLElement>(null);

  // TODO: This should be mocked in test file instead.
  // Unfortunately we have to do this ugly workaround since popper modifies state
  // that triggers warnings in jest tests. If running in test, use mock implementation
  // that does nothing.
  const usePopperImpl = isTestingWithJest() ? _mockUsePopper : usePopper;

  const {
    styles: popperStyles,
    attributes,
    update
  } = usePopperImpl(referenceElement, popperElement, {
    placement: 'top',
    modifiers: popperModifiers
  });

  const [isVisible, setIsVisible] = useState(false);
  const [copiedText, setCopiedText] = useState<boolean>(false);

  const [startTimer, stopTimer] = useTimeout(
    useCallback(() => {
      setIsVisible(true);
      update?.().then();
    }, [update]),
    tooltipSpeed
  );

  const onMouseOver = () => {
    startTimer();
  };

  const onMouseLeave = () => {
    stopTimer();
    setIsVisible(false);
    setCopiedText(false);
  };

  const innerRef = useRef(null);

  const onCopyClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      event.stopPropagation();

      navigator.clipboard.writeText(valueToCopy).then(() => {
        setCopiedText(true);
      });
    },
    [valueToCopy]
  );

  return (
    <span
      ref={setReferenceElement}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      style={{ cursor: 'pointer' }}
    >
      {children}
      <Portal isOpen={isVisible} closeTimeout={tooltipSpeed}>
        <Transition
          classNames={transitionStyles}
          timeout={{
            appear: tooltipSpeed,
            enter: tooltipSpeed,
            exit: tooltipSpeed
          }}
        >
          {isVisible && (
            <div
              className={classNames(styles.window, styles.zIndex)}
              ref={setPopperElement}
              style={popperStyles.popper}
              {...attributes.popper}
            >
              <div
                className={styles.windowInnerContainer}
                onClick={onCopyClick}
                ref={innerRef}
              >
                <div className={styles.windowBody}>
                  {additionalText && (
                    <>
                      <div className={styles.additionalText}>
                        {additionalText}
                      </div>
                      <div className={styles.divider} />
                    </>
                  )}
                  <div className={styles.windowButtonArea}>
                    {copiedText ? <Icons.Checkmark /> : <Icons.Copy />}{' '}
                    {T.common.copytoclipboard.tooltip}
                  </div>
                </div>
                <div className={styles.windowArrowContainer}>
                  <div className={styles.windowArrow} />
                </div>
              </div>
            </div>
          )}
        </Transition>
      </Portal>
    </span>
  );
};

export default React.memo(CopyToClipboardTooltip);
