import React, { useEffect, useState } from 'react';
import Button from 'ecto-common/lib/Button/Button';
import styles from './ModbusLayout.module.css';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import SegmentControl from 'ecto-common/lib/SegmentControl/SegmentControl';
import { SegmentControlItem } from 'ecto-common/lib/SegmentControl/SegmentControlItem';
import API, { cancellablePromiseList } from 'ecto-common/lib/API/API';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
// @ts-ignore-next-line
import { Base64 } from 'js-base64';
import { SimplifiedEquipmentResponseModel } from 'ecto-common/lib/API/APIGen';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';

const _renderText = (text: string) => {
  return <div className={styles.monoText}>{text}</div>;
};

const _renderJSON = (object: object) => {
  return (
    <div className={styles.monoText}>{JSON.stringify(object, null, 3)}</div>
  );
};

const loadDataPromise = (
  contextSettings: ApiContextSettings,
  deviceId: string
) => {
  return cancellablePromiseList([
    API.Admin.DeviceFiles.getSignalsConfigFile(
      contextSettings,
      deviceId,
      false
    ),
    API.Admin.DeviceFiles.getEquipmentConfigFile(contextSettings, deviceId),
    API.Admin.DeviceFiles.getToolsConfigFile(contextSettings, deviceId),
    API.Admin.DeviceFiles.getDeviceConfigFile(contextSettings, deviceId)
  ] as const);
};

const prettyJSONEncode = (data: object) =>
  Base64.encode(JSON.stringify(data, null, 4));

const sections = [
  {
    label: T.admin.modbuslayout.config,
    contentType: 'application/json',
    fileName: 'modbus_interface.json',
    icon: <Icons.FileTypes.Text />,
    contentData: (contentData: ModbusLayoutDataType) =>
      prettyJSONEncode(contentData.deviceConfigFile),
    renderContent: (contentData: ModbusLayoutDataType) =>
      _renderJSON(contentData.deviceConfigFile)
  },
  {
    label: T.admin.modbuslayout.signals,
    contentType: 'application/json',
    fileName: 'signals.json',
    icon: <Icons.FileTypes.Text />,
    contentData: (contentData: ModbusLayoutDataType) =>
      prettyJSONEncode(contentData.signalsConfigFile),
    renderContent: (contentData: ModbusLayoutDataType) =>
      _renderJSON(contentData.signalsConfigFile)
  },
  {
    label: T.admin.modbuslayout.tools,
    contentType: 'application/json',
    fileName: 'control.json',
    icon: <Icons.FileTypes.Text />,
    contentData: (contentData: ModbusLayoutDataType) =>
      prettyJSONEncode(contentData.toolsConfigFile),
    renderContent: (contentData: ModbusLayoutDataType) =>
      _renderJSON(contentData.toolsConfigFile)
  }
];

interface ModbusLayoutProps {
  deviceId: string;
}

type ModbusLayoutDataType = {
  signalsConfigFile: object[];
  equipmentConfigFile: SimplifiedEquipmentResponseModel[];
  toolsConfigFile: object;
  deviceConfigFile: object;
};

const ModbusLayout = ({ deviceId }: ModbusLayoutProps) => {
  const [sectionIndex, setSectionIndex] = useState(0);
  const [data, setData] = useState<ModbusLayoutDataType>(null);
  const [hasError, setHasError] = useState(false);

  const [getDataIsLoading, getData, cancelGetData] = usePromiseCall({
    promise: loadDataPromise,
    onSuccess: ([
      signalsConfigFile,
      equipmentConfigFile,
      toolsConfigFile,
      deviceConfigFile
    ]) => {
      setData({
        signalsConfigFile,
        equipmentConfigFile,
        toolsConfigFile,
        deviceConfigFile
      });
    },
    onError: () => {
      setHasError(true);
    }
  });

  useEffect(() => {
    if (deviceId) {
      getData(deviceId);
    }

    return () => {
      cancelGetData();
    };
  }, [deviceId, getData, cancelGetData]);

  const section = sections[sectionIndex];
  const contentUrl =
    data &&
    'data:' + section.contentType + ';base64,' + section.contentData(data);
  const downloadName = section.fileName;

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <SegmentControl>
          {sections.map((curSection, index) => {
            return (
              <SegmentControlItem
                key={index}
                onClick={() => setSectionIndex(index)}
                active={index === sectionIndex}
              >
                {curSection.icon}
                {curSection.label}
              </SegmentControlItem>
            );
          })}
        </SegmentControl>
        <a download={downloadName} href={contentUrl}>
          <Button disabled={getDataIsLoading || hasError}>
            <Icons.Download />
            {T.admin.modbuslayout.downloadfile}
          </Button>
        </a>
      </div>
      <div className={styles.content}>
        {getDataIsLoading && _renderText(T.common.loading)}
        {!getDataIsLoading && data && section.renderContent(data)}
        {!getDataIsLoading && hasError && _renderText(T.common.unknownerror)}
      </div>
    </div>
  );
};

export default ModbusLayout;
