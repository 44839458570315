import React, { useState } from 'react';

import T from 'ecto-common/lib/lang/Language';
import SegmentControl from 'ecto-common/lib/SegmentControl/SegmentControl';
import { SegmentControlItem } from 'ecto-common/lib/SegmentControl/SegmentControlItem';
import Icons from 'ecto-common/lib/Icons/Icons';

import DashboardCollectionsPage from 'js/components/DashboardCollections/DashboardCollectionsPage';
import { useParams } from 'react-router';
import DashboardsPage from 'js/components/Dashboards/DashboardsPage';
import EditDashboardPage from 'js/components/Dashboards/EditDashboardPage';
import { TemplateManagementParams } from 'js/utils/routeConstants';

const sections = [
  {
    title: T.admin.dashboards.title,
    key: 'dashboards',
    view: DashboardsPage,
    icon: <Icons.Dashboard />
  },
  {
    title: T.admin.dashboardcollection.title,
    key: 'collections',
    view: DashboardCollectionsPage,
    icon: <Icons.Folder />
  }
];

const Dashboards = () => {
  const params = useParams<TemplateManagementParams>();
  let dashboardId = params.itemId;
  const [curSection, setCurSection] = useState(sections[0]);

  if (dashboardId != null) {
    return <EditDashboardPage dashboardId={dashboardId} />;
  }

  const segmentControl = (
    <SegmentControl>
      {sections.map((section) => (
        <SegmentControlItem
          key={section.key}
          active={curSection === section}
          onClick={() => setCurSection(section)}
        >
          {section.icon}
          {section.title}
        </SegmentControlItem>
      ))}
    </SegmentControl>
  );

  return (
    <>
      {React.createElement(curSection.view, {
        segmentControl
      })}
    </>
  );
};

export default Dashboards;
