import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import Checkbox from '../Checkbox/Checkbox';
import RadioButton from '../RadioButton/RadioButton';
import styles from './Options.module.css';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { typedMemo } from 'ecto-common/lib/utils/typescriptUtils';

type SingleValue<Option> = GenericSelectOption<Option> | null;
type MultiValue<Option> = readonly GenericSelectOption<Option>[];

const isSelectedValue = <ValueType,>(
  value: ValueType,
  selectedOption: MultiValue<ValueType> | SingleValue<ValueType>
): boolean => {
  if (_.isArray(selectedOption)) {
    return _.some(selectedOption, { value });
  } else if (selectedOption) {
    return (selectedOption as SingleValue<ValueType>)?.value === value;
  }

  return false;
};

interface OptionsProps<ValueType, IsMulti extends boolean> {
  options?: MultiValue<ValueType>;
  value?: IsMulti extends true ? MultiValue<ValueType> : SingleValue<ValueType>;
  selectAllLabel?: string;
  onChange?: IsMulti extends true
    ? (options: MultiValue<ValueType>) => void
    : (option: SingleValue<ValueType>) => void;
  isMulti?: boolean;
  disabled?: boolean;
}

/**
 * Options is similar to Select, but instead of rendering the options in a dropdown
 * menu it renders them as checkboxes or radio buttons, depending on if IsMulti is set.
 *
 * Migrating to typescript has revealed some issues with how IsMulti is handled. We should
 * find a more elegant solution that avoids casting (look into how react-select does it).
 */
const Options = <ValueType, IsMulti extends boolean>({
  options,
  value,
  selectAllLabel,
  onChange,
  isMulti,
  disabled
}: OptionsProps<ValueType, IsMulti>) => {
  const emptySelect = useCallback(() => onChange(null), [onChange]);

  // TODO: Refactor this out so that we can avoid casts somehow
  const toggleMultiValue = useCallback(
    (selectedValue: GenericSelectOption<ValueType>) => {
      const listValue = value as GenericSelectOption<ValueType>[];
      const toggleOption = _.find(listValue, { value: selectedValue.value });
      const multiOnChange = onChange as (
        options: MultiValue<ValueType>
      ) => void;
      if (toggleOption) {
        // remove value from selection
        multiOnChange(_.reject(listValue, ['value', selectedValue.value]));
      } else {
        // add value to new values
        multiOnChange([...listValue, selectedValue]);
      }
    },
    [onChange, value]
  );

  const optionComponents = useMemo(
    () =>
      options?.map((option, index) => {
        if (isMulti) {
          return (
            <Checkbox
              key={index}
              disabled={disabled}
              checked={isSelectedValue(option.value, value)}
              onChange={() => toggleMultiValue(option)}
            >
              <label>{option.label}</label>
            </Checkbox>
          );
        }

        const singleOnChange = onChange as (
          options: SingleValue<ValueType>
        ) => void;

        return (
          <RadioButton
            key={index}
            disabled={disabled}
            checked={isSelectedValue(option.value, value)}
            onChange={() => singleOnChange(option)}
          >
            <label>{option.label}</label>
          </RadioButton>
        );
      }),
    [options, isMulti, onChange, value, disabled, toggleMultiValue]
  );

  const hasSelection = useMemo(
    () => _.some(options, (option) => isSelectedValue(option.value, value)),
    [options, value]
  );

  return (
    <div className={styles.checkboxList}>
      {optionComponents}
      {selectAllLabel && (
        <Checkbox
          disabled={disabled}
          checked={!hasSelection}
          onChange={emptySelect}
        >
          <label>{selectAllLabel}</label>
        </Checkbox>
      )}
    </div>
  );
};

Options.defaultProps = {
  isMulti: false
};

export default typedMemo(Options);
