import { getApiEnvironment } from 'ecto-common/lib/utils/apiEnvironment';
import {
  JSONDelete,
  JSONFetch,
  JSONPost,
  JSONPut
} from 'ecto-common/lib/API/API';
import queryString from 'query-string';
import {
  ListTenantsResponse,
  ListTenantUsersResponse,
  TenantModel,
  TenantsListTenantsParams,
  TenantUserModel,
  TenantUsersListTenantUsersParams
} from 'ecto-common/lib/API/IdentityServiceAPIGen';
import {
  ListResourcesResponse,
  ListRolesResponse,
  TenantUserSettingsModel,
  UserModel
} from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';

const fetchOptions = {
  domain: () => getApiEnvironment().urls.identityServiceUrl,
  scopes: () => [getApiEnvironment().scopes.identity]
};

const IdentityServiceAPI = {
  Tenant: {
    getUsers: (
      contextSettings: ApiContextSettings,
      paging?: TenantUsersListTenantUsersParams
    ) =>
      JSONFetch<ListTenantUsersResponse>(
        contextSettings,
        '/v1/tenant/users?' + queryString.stringify(paging),
        {},
        fetchOptions
      )
  },
  Tenants: {
    getTenants: (
      contextSettings: ApiContextSettings,
      paging: TenantsListTenantsParams
    ) =>
      JSONFetch<ListTenantsResponse>(
        contextSettings,
        '/v1/tenants?' + queryString.stringify(paging),
        {},
        fetchOptions
      ),
    deleteTenant: (contextSettings: ApiContextSettings, tenantId: string) =>
      JSONDelete<void>(
        contextSettings,
        `/v1/tenants/${tenantId}`,
        null,
        {},
        fetchOptions
      ),
    updateTenant: (contextSettings: ApiContextSettings, tenant: TenantModel) =>
      JSONPut<TenantModel>(
        contextSettings,
        '/v1/tenants',
        { tenant },
        {},
        fetchOptions
      ),
    createTenant: (contextSettings: ApiContextSettings, tenant: TenantModel) =>
      JSONPost<TenantModel>(
        contextSettings,
        '/v1/tenants',
        { tenant },
        {},
        fetchOptions
      )
  },
  TenantUser: {
    getSettings: (contextSettings: ApiContextSettings) =>
      JSONFetch<TenantUserSettingsModel>(
        contextSettings,
        '/v2/tenantUser/settings',
        {},
        fetchOptions
      ),
    updateSettings: (contextSettings: ApiContextSettings, settings: string) =>
      JSONPut<TenantUserSettingsModel>(
        contextSettings,
        '/v2/tenantUser/settings',
        { settings },
        {},
        fetchOptions
      )
  },
  TenantUsers: {
    getTenantUsers: (
      contextSettings: ApiContextSettings,
      tenantId: string,
      paging?: TenantUsersListTenantUsersParams
    ) => {
      if (tenantId == null) {
        return JSONFetch<ListTenantUsersResponse>(
          contextSettings,
          '/v1/tenant/users?' + queryString.stringify(paging),
          {},
          fetchOptions
        );
      }

      return JSONFetch<ListTenantUsersResponse>(
        contextSettings,
        `/v1/tenants/${tenantId}/users?` + queryString.stringify(paging),
        {},
        fetchOptions
      );
    },
    addOrUpdateTenantUser: (
      contextSettings: ApiContextSettings,
      tenantId: string,
      tenantUser: TenantUserModel
    ) => {
      if (tenantId == null) {
        return JSONPut<TenantUserModel>(
          contextSettings,
          '/v1/tenant/users',
          { tenantUser },
          {},
          fetchOptions
        );
      }

      return JSONPut<TenantUserModel>(
        contextSettings,
        `/v1/tenants/${tenantId}/users`,
        { tenantUser },
        {},
        fetchOptions
      );
    },
    deleteTenantUser: (
      contextSettings: ApiContextSettings,
      tenantId: string,
      tenantUser: TenantUserModel
    ) => {
      if (tenantId == null) {
        return JSONDelete<void>(
          contextSettings,
          `/v1/tenant/users/${tenantUser.userId}`,
          { tenantUser },
          {},
          fetchOptions
        );
      }

      return JSONDelete<void>(
        contextSettings,
        `/v1/tenants/${tenantId}/users/${tenantUser.userId}`,
        { tenantUser },
        {},
        fetchOptions
      );
    }
  },
  Resources: {
    getResources: (contextSettings: ApiContextSettings) =>
      JSONFetch<ListResourcesResponse>(
        contextSettings,
        '/v2/resources',
        {},
        fetchOptions
      )
  },
  Roles: {
    getRoles: (contextSettings: ApiContextSettings) =>
      JSONFetch<ListRolesResponse>(
        contextSettings,
        '/v2/roles',
        {},
        fetchOptions
      )
  },
  User: {
    getWhoAmI: (contextSettings: ApiContextSettings) =>
      JSONFetch<UserModel>(contextSettings, '/v2/user', {}, fetchOptions),
    getTenantUsers: (contextSettings: ApiContextSettings) =>
      JSONFetch<TenantUserModel[]>(
        contextSettings,
        '/v2/user/tenantUsers',
        {},
        fetchOptions
      ),
    getTenants: (contextSettings: ApiContextSettings) =>
      JSONFetch<TenantModel[]>(
        contextSettings,
        '/v2/user/tenants',
        {},
        fetchOptions
      ),
    updateUser: (contextSettings: ApiContextSettings, user: UserModel) =>
      JSONPut<UserModel>(
        contextSettings,
        '/v2/user',
        { user },
        {},
        fetchOptions
      )
  }
};

export default IdentityServiceAPI;
