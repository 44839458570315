import React, { useCallback, useContext, useEffect, useState } from 'react';
import pageStyles from '../../PageStyle.module.css';
import { setEquipmentGroupTemplates } from 'js/actions/getEquipmentGroupTemplates';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import styles from './EditEquipmentGroupTemplate.module.css';
import T from 'ecto-common/lib/lang/Language';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { getEnergyManagerEquipmentTypeId } from 'ecto-common/lib/utils/equipmentTypeUtils';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import Icons from 'ecto-common/lib/Icons/Icons';
import { useHistory } from 'react-router-dom';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';

import API from 'ecto-common/lib/API/API';
import _ from 'lodash';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import UUID from 'uuidjs';
import { AlarmSignalGroupTemplateIds } from 'ecto-common/lib/utils/constants';
import HelpPaths from 'ecto-common/help/tocKeys';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import {
  AddOrUpdateBuildingTemplateRequestModel,
  BuildingTemplateResponseModel
} from 'ecto-common/lib/API/APIGen';

const _columns: DataTableColumnProps<BuildingTemplateResponseModel>[] = [
  {
    label: null,
    dataKey: 'name',
    flexGrow: 1,
    width: 100,
    linkColumn: true,
    dataFormatter: (name: string) => (
      <>
        <Icons.File /> {name}
      </>
    )
  }
];

const EquipmentGroupTemplates = () => {
  const dispatch = useAdminDispatch();
  const equipmentGroupTemplates = useAdminSelector(
    (state) => state.admin.equipmentGroupTemplates
  );
  const equipmentTypes = useAdminSelector(
    (state) => state.general.equipmentTypes
  );
  const [addDialogVisible, showAddDialog, hideAddDialog] =
    useDialogState(false);
  const [templateName, setTemplateName] = useState('');
  const { tenantId } = useContext(TenantContext);

  useEffect(() => {
    if (!addDialogVisible) {
      setTemplateName('');
    }
  }, [addDialogVisible]);

  const [isLoading, _createNewTemplate] = usePromiseCall({
    promise: API.Admin.Templates.updateBuilding,
    onSuccess: (body) => {
      hideAddDialog();
      toastStore.addSuccessToast(T.admin.requests.createtemplate.success);
      if (body.length === 1) {
        const groupTemplate = body[0];
        // Update global equipment group templates
        const newEquipmentGroupTemplates = _.cloneDeep(equipmentGroupTemplates);
        newEquipmentGroupTemplates.push(groupTemplate);
        dispatch(setEquipmentGroupTemplates(newEquipmentGroupTemplates));
      }
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.requests.createtemplate.failure);
    }
  });

  const createNewTemplate = useCallback(() => {
    if (templateName === '') {
      return;
    }

    const emEquipmentTypeId = getEnergyManagerEquipmentTypeId(equipmentTypes);

    const body: AddOrUpdateBuildingTemplateRequestModel = {
      name: templateName,
      description: '',
      connectionModbusConfig: {
        id: UUID.generate()
      },
      equipmentTemplateGroupId: UUID.generate(),
      equipmentTemplates: [
        {
          name: 'Energy Manager',
          description: '',
          id: UUID.generate(),
          signalTemplateOverrides: [],
          alarmSignalGroupTemplateId:
            AlarmSignalGroupTemplateIds.ENERGY_MANAGER_ALARMS,
          toolTypes: [],
          equipmentTypeId: emEquipmentTypeId
        }
      ]
    };

    _createNewTemplate(body);
  }, [_createNewTemplate, equipmentTypes, templateName]);

  const history = useHistory();

  const onRowClick = useCallback(
    (template: BuildingTemplateResponseModel) => {
      history.push(
        `/${tenantId}/templateManagement/buildings/${template.equipmentTemplateGroupId}`
      );
    },
    [history, tenantId]
  );

  const _onCloseAddNew = useCallback(() => {
    hideAddDialog();
    setTemplateName('');
  }, [hideAddDialog]);

  const onAddNameChanged: React.ChangeEventHandler<HTMLInputElement> =
    useCallback((e) => {
      setTemplateName(e.target.value);
    }, []);

  return (
    <ToolbarContentPage
      wrapContent={false}
      title={T.admin.equipmentgrouptemplates.title}
      showLocationPicker={false}
      addActionTitle={T.admin.equipmentgrouptemplates.addnew}
      addAction={showAddDialog}
      helpPath={HelpPaths.docs.admin.templates.building_templates}
    >
      {equipmentGroupTemplates && (
        <DataTable<BuildingTemplateResponseModel>
          isLoading={false}
          disableHeader
          onClickRow={onRowClick}
          data={equipmentGroupTemplates}
          columns={_columns}
        />
      )}

      <ActionModal
        className={styles.modal}
        onModalClose={_onCloseAddNew}
        isOpen={addDialogVisible}
        title={T.admin.equipmentgrouptemplates.addnew}
        actionText={T.common.iconbutton.add.title}
        onConfirmClick={createNewTemplate}
        isLoading={isLoading}
        headerIcon={Icons.Add}
      >
        <TextInput
          autoFocus
          value={templateName}
          placeholder={T.admin.equipmentgrouptemplates.templatename}
          wrapperClassName={pageStyles.wideInputField}
          onEnterKeyPressed={createNewTemplate}
          onChange={onAddNameChanged}
        />
      </ActionModal>
    </ToolbarContentPage>
  );
};

export default EquipmentGroupTemplates;
