import React, { useCallback } from 'react';
import _ from 'lodash';
import {
  ProcessMapObjectsProps,
  ProcessMapOverlayObjectProps
} from '../ProcessMapObjectProps';
import { ProcessMapViewSignal } from '../ProcessMapView';
import {
  ProcessMapRectObject,
  processMapColorRules
} from '../ProcessMapViewConstants';
import { evaluateProcessMapColorRule } from '../ProcessMapViewUtils';
import {
  ProcessMapRectView,
  ProcessMapRectViewOverlay
} from '../View/ProcessMapRectView';
import { CustomNodeTreeSet } from 'ecto-common/lib/SelectNodeDialog/SelectNode';
import {
  ModelFormLineType,
  ModelFormSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';
import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';

export const RectView = ({
  allSignalsBySignalTypeId,
  node: nodeIn,
  signalData,
  objectIndex,
  selectedRectHandles,
  isHovering,
  onMouseOut,
  onMouseOver,
  onClick
}: ProcessMapObjectsProps) => {
  const node = nodeIn as ProcessMapRectObject;
  const amongSelected = selectedRectHandles.some(
    (handle) => handle.objectIndex === objectIndex && handle.rectIndex === 0
  );
  let fillColorFromRule: string = null;
  let signal: ProcessMapViewSignal = null;
  let isWritableSignal = false;

  if (node.fillColorRuleName != null) {
    fillColorFromRule = evaluateProcessMapColorRule(
      node.fillColorRuleName,
      node.fillColorRuleSignalTypeIds,
      allSignalsBySignalTypeId,
      signalData
    );

    let signalTypeId = _.find(
      node.fillColorRuleSignalTypeIds,
      (id) => allSignalsBySignalTypeId[id] != null
    );

    if (signalTypeId != null) {
      signal = allSignalsBySignalTypeId[signalTypeId];
      isWritableSignal = signalData[signal?.signalId]?.isWritable;
    }
  }

  const _onClick = useCallback(
    (event: MouseEvent) => {
      onClick?.(event, node, signal, isWritableSignal);
    },
    [onClick, node, signal, isWritableSignal]
  );

  const _onMouseOver = useCallback(
    (event: MouseEvent) => {
      onMouseOver?.(event, node, signal, isWritableSignal);
    },
    [onMouseOver, node, signal, isWritableSignal]
  );

  const _onMouseOut = useCallback(
    (event: MouseEvent) => {
      onMouseOut?.(event, node, signal, isWritableSignal);
    },
    [onMouseOut, node, signal, isWritableSignal]
  );

  return (
    <ProcessMapRectView
      key={node.id}
      isDragging={amongSelected}
      node={node}
      isHovering={isHovering}
      fillColorFromRule={fillColorFromRule}
      onMouseOver={_onMouseOver}
      onMouseOut={_onMouseOut}
      onClick={_onClick}
    />
  );
};

export const RectOverlay = ({
  node,
  selectedRectHandles,
  objectIndex,
  zoom,
  setRectResizeElement
}: ProcessMapOverlayObjectProps) => {
  const amongSelected = selectedRectHandles.some(
    (handle) => handle.objectId === node.id
  );
  return amongSelected ? (
    <ProcessMapRectViewOverlay
      objectIndex={objectIndex}
      setRectResizeElement={setRectResizeElement}
      key={node.id}
      node={node as ProcessMapRectObject}
      zoom={zoom}
    />
  ) : null;
};

const processMapColorRuleOptions = Object.keys(processMapColorRules).map(
  (key) => ({
    label: key,
    value: key
  })
);

const colorRuleArgumentOptionLines = (
  argLabel: string,
  ruleName: string,
  previewNodeId: string,
  nodeTreeSet: CustomNodeTreeSet
): ModelFormLineType<ProcessMapRectObject>[] => {
  if (ruleName == null) {
    return [];
  }

  const rule = processMapColorRules[ruleName];
  const ruleLines: ModelFormLineType<ProcessMapRectObject>[] = [];

  for (let i = 0; i < rule.numSignalValues; i++) {
    ruleLines.push({
      models: [
        {
          key: (input) => input.fillColorRuleSignalTypeIds[i],
          label:
            argLabel +
            ' ' +
            T.format(
              T.admin.processmaps.objecteditor.signaltypeindexformat,
              i + 1
            )
              .join('')
              .toLowerCase(),
          modelType: ModelType.SIGNAL_TYPE,
          customNodeTreeSet: nodeTreeSet,
          nodeId: previewNodeId
        }
      ]
    });
  }

  return ruleLines;
};

export const rectSections = (
  previewNodeId: string,
  nodeTreeSet: CustomNodeTreeSet,
  fillColorRule: string
): ModelFormSectionType<ProcessMapRectObject>[] => {
  return [
    {
      label: T.admin.processmaps.objecteditor.rectobject,
      lines: [
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.strokewidth,
              key: (input) => input.strokeWidth,
              modelType: ModelType.NUMBER
            },
            {
              label: T.admin.processmaps.objecteditor.cornerradius,
              key: (input) => input.cornerRadius,
              modelType: ModelType.NUMBER
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.strokecolor,
              key: (input) => input.strokeColor,
              modelType: ModelType.COLOR
            },
            {
              label: T.admin.processmaps.objecteditor.fillcolor,
              key: (input) => input.fillColor,
              modelType: ModelType.COLOR
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.fillcolorrule,
              key: (input) => input.fillColorRuleName,
              modelType: ModelType.OPTIONS,
              options: processMapColorRuleOptions,
              isClearable: true
            }
          ]
        },
        ...colorRuleArgumentOptionLines(
          T.admin.processmaps.objecteditor.fillcolor,
          fillColorRule,
          previewNodeId,
          nodeTreeSet
        )
      ]
    }
  ];
};
