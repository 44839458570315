import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  useContext
} from 'react';

import Icons from 'ecto-common/lib/Icons/Icons';
import Modal from 'ecto-common/lib/Modal/Modal';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';
import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import ActionFooter from 'ecto-common/lib/Modal/ModalActionFooter';
import T from 'ecto-common/lib/lang/Language';

import styles from './ManageUsers.module.css';
import UserDetailsView from 'js/components/ManageUsers/UserDetailsView';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import {
  isEONKID,
  isEONKIDEMail,
  isNullOrWhitespace,
  isValidEmail
} from 'ecto-common/lib/utils/stringUtils';
import IdentityServiceAPIGenV2, {
  TenantUserModel
} from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import IdentityServiceAPIGen from 'ecto-common/lib/API/IdentityServiceAPIGen';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import {
  EonKidDomainSuffix,
  UserAccountType,
  UserAccountTypes
} from './userUtils';

interface AddUserDialogProps {
  editUser?: TenantUserModel;
  setEditUser?: Dispatch<SetStateAction<TenantUserModel>>;
  tenantId?: string;
  onUserChanged?(): void;
  isLoadingResources?: boolean;
}

const transformEmail = (email: string, userAccountType: UserAccountType) => {
  if (userAccountType === UserAccountTypes.KID) {
    return email + EonKidDomainSuffix;
  }

  return email;
};

/**
 * Shows add user dialog
 */
const AddUserDialog = ({
  setEditUser,
  editUser,
  tenantId,
  onUserChanged,
  isLoadingResources
}: AddUserDialogProps) => {
  const [hasChanges, setHasChanges] = useState(false);
  const { tenantId: currentTenantId } = useContext(TenantContext);
  const [promptForDisplayName, setPromptForDisplayName] = useState(false);
  const [displayName, setDisplayName] = useState(null);
  const [userAccountType, setUserAccountType] = useState<UserAccountType>(
    UserAccountTypes.KID
  );
  const [confirmExternalUser, setConfirmExternalUser] = useState(false);

  useEffect(() => {
    setHasChanges(false);
  }, [editUser?.userId]);

  const onModalClose = useCallback(() => {
    setEditUser(null);
    setDisplayName(null);
    setPromptForDisplayName(false);
    setUserAccountType(UserAccountTypes.KID);
  }, [setEditUser]);

  const userAdded = () => {
    toastStore.addSuccessToast(T.admin.users.request.createduser);
    onUserChanged();
    onModalClose();
  };

  const userFailedToAdd = () => {
    toastStore.addErrorToastForUpdatedItem(editUser.email, true);
  };

  const updateTenantUserMutation =
    IdentityServiceAPIGen.TenantUsers.createOrUpdateTenantUser.useMutation(
      { tenantId },
      {
        onSuccess: userAdded,
        onError: userFailedToAdd
      }
    );

  const updateCurrentTenantUserMutation =
    IdentityServiceAPIGen.Tenant.createOrUpdateTenantUser.useMutation({
      onSuccess: userAdded,
      onError: userFailedToAdd
    });

  const confirmDisplayName = useCallback(
    (userDisplayName: string) => {
      const userToAdd: TenantUserModel = {
        ...editUser,
        email: transformEmail(editUser.email, userAccountType),
        tenantId: tenantId == null ? currentTenantId : tenantId,
        isFederated: userAccountType === UserAccountTypes.KID,
        displayName: userDisplayName
      };

      if (tenantId != null) {
        updateTenantUserMutation.mutate({
          tenantUser: userToAdd
        });
      } else {
        updateCurrentTenantUserMutation.mutate({
          tenantUser: userToAdd
        });
      }
    },
    [
      currentTenantId,
      editUser,
      tenantId,
      updateCurrentTenantUserMutation,
      updateTenantUserMutation,
      userAccountType
    ]
  );

  const getUserInfoMutation =
    IdentityServiceAPIGenV2.Users.getUserByEmail.useMutation({
      onSuccess: (user) => {
        if (user.user != null && user.user.displayName != null) {
          // Go ahead and update the user
          confirmDisplayName(user.user.displayName);
        } else {
          setPromptForDisplayName(true);
        }
      },
      onError: userFailedToAdd
    });

  const addUserAction = useCallback(() => {
    getUserInfoMutation.mutate({
      email: transformEmail(editUser.email, userAccountType)
    });
  }, [editUser?.email, getUserInfoMutation, userAccountType]);

  const isLoadingUpdateUser =
    updateTenantUserMutation.isLoading ||
    updateCurrentTenantUserMutation.isLoading;

  const _changeAccountType = (newAccountType: UserAccountType) => {
    if (newAccountType === UserAccountTypes.KID) {
      setUserAccountType(UserAccountTypes.KID);
    } else {
      setConfirmExternalUser(true);
    }
  };

  const isLoading =
    isLoadingResources || isLoadingUpdateUser || getUserInfoMutation.isLoading;
  let errorText: string = null;
  const hasInvalidEmail = !isValidEmail(editUser?.email);

  if (hasInvalidEmail && userAccountType === UserAccountTypes.External) {
    errorText = T.admin.tenants.user.errors.invalidemail;
  } else if (
    !isEONKID(editUser?.email) &&
    userAccountType === UserAccountTypes.KID
  ) {
    errorText = T.admin.tenants.user.errors.invalidkid;
  } else if (
    isEONKIDEMail(editUser?.email) &&
    userAccountType === UserAccountTypes.External
  ) {
    errorText = T.admin.tenants.user.errors.invalidkidemail;
  }
  const isValid = editUser && errorText == null && editUser?.roles?.length > 0;

  return (
    <>
      <Modal
        className={styles.dialog}
        onModalClose={onModalClose}
        isOpen={editUser != null}
      >
        <ModalHeader titleIcon={Icons.AddUser}>
          {T.admin.users.adduser}
        </ModalHeader>

        <ModalBody loading={isLoading}>
          <UserDetailsView
            setEditUser={setEditUser}
            editUser={editUser}
            setHasChanges={setHasChanges}
            userAccountType={userAccountType}
            errorText={errorText}
            setAccountType={_changeAccountType}
          />
        </ModalBody>
        <ActionModal
          isOpen={confirmExternalUser}
          onModalClose={() => setConfirmExternalUser(false)}
          title={T.admin.users.adduser}
          actionText={T.common.yes}
          onConfirmClick={() => {
            setUserAccountType(UserAccountTypes.External);
            setConfirmExternalUser(false);
          }}
        >
          {T.admin.tenants.user.confirmexternal}
        </ActionModal>

        <ActionModal
          isOpen={promptForDisplayName}
          onModalClose={() => setPromptForDisplayName(false)}
          title={T.admin.users.adduser}
          actionText={T.common.add}
          onConfirmClick={() => confirmDisplayName(displayName)}
          disableActionButton={isNullOrWhitespace(displayName)}
          isLoading={isLoading}
          headerIcon={Icons.AddUser}
        >
          {T.admin.tenants.user.nodisplayname}
          <KeyValueLine>
            <KeyValueInput
              onChange={(e) => setDisplayName(e.target.value)}
              value={displayName}
              keyText={T.admin.tenants.user.fields.name}
              placeholder={T.admin.tenants.user.fields.name}
              autoComplete="off"
              hasError={isNullOrWhitespace(displayName)}
            />
          </KeyValueLine>
        </ActionModal>
        <ActionFooter
          onModalClose={onModalClose}
          onConfirm={addUserAction}
          confirmDisabled={!hasChanges || !isValid}
          cancelText={T.common.actionmodal.cancel}
          confirmText={T.admin.users.adduser}
          isLoading={isLoading}
        />
      </Modal>
    </>
  );
};

export default AddUserDialog;
