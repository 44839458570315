import { useCallback, useContext, useEffect, useState } from 'react';
import API from 'ecto-common/lib/API/API';
import _ from 'lodash';
import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import { ResourceType } from 'ecto-common/lib/constants';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { ComfortHeatingProviderResponseModel } from 'ecto-common/lib/API/APIGen';

export const EMPTY_INPUT = Object.freeze({
  displayName: null,
  meanTemperatureSignalId: null,
  outTemperatureSignalId: null,
  tapWaterEquipmentId: null,
  windSpeedSignalId: null
});

/**
 * Loads comfort tool for current equipment and node
 * @param nodeId
 * @param equipmentId
 * @returns {[currentTool, isLoadingTool, reloadToolFunc]}
 */
const useComfortTools = (
  nodeId: string,
  equipmentId: string
): [
  currentTool: ComfortHeatingProviderResponseModel,
  isLoadingTool: boolean,
  reloadTools: () => void
] => {
  const { tenantResources } = useContext(TenantContext);
  const canUseTool = hasAccessToResource(
    ResourceType.SMART_COMFORT,
    tenantResources
  );

  const [currentTool, setCurrentTool] =
    useState<ComfortHeatingProviderResponseModel>(null);
  const { contextSettings } = useContext(TenantContext);

  const [triggerReloadTool, setTriggerReloadTool] = useState(false);

  const reloadTools = useCallback(() => {
    setTriggerReloadTool((s) => !s);
  }, [setTriggerReloadTool]);

  const [isLoadingTool, setLoadingTool] = useState(false);
  useEffect(() => {
    if (!canUseTool) {
      return;
    }
    setLoadingTool(true);
    let loadToolPromise = API.Admin.Comfort.getTool(contextSettings, nodeId);

    loadToolPromise
      .then((result) => {
        setCurrentTool(_.find(result, ['equipmentId', equipmentId]));
      })
      .catch((e) => console.error(e))
      .finally(() => {
        if (loadToolPromise) {
          setLoadingTool(false);
        }
        loadToolPromise = null;
      });
    return () => {
      loadToolPromise?.cancel?.();
      loadToolPromise = null;
    };
  }, [nodeId, equipmentId, triggerReloadTool, canUseTool, contextSettings]);

  return [currentTool, isLoadingTool, reloadTools];
};

export default useComfortTools;
