import {
  SET_EQUIPMENT_GROUP_TEMPLATES,
  SET_SIGNAL_TEMPLATES,
  PATCH_EQUIPMENT_GROUP_TEMPLATES,
  PATCH_SIGNAL_TEMPLATES
} from 'js/actions/actionTypes';

import _ from 'lodash';
import { translateBitmaskTypes } from 'js/components/ModbusLayout/ModbusEditUtils';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import { AdminSignalTemplates } from 'ecto-common/lib/types/EctoCommonTypes';
import {
  AdminAlarmSignalGroupTemplateResponseModel,
  BuildingTemplateResponseModel,
  EquipmentSignalProviderTemplateResponseModel
} from 'ecto-common/lib/API/APIGen';

type AdminReducerState = {
  equipmentGroupTemplates: BuildingTemplateResponseModel[];
  signalTemplates: AdminSignalTemplates;
};

const initialState: AdminReducerState = {
  equipmentGroupTemplates: [],
  signalTemplates: {
    equipmentSignalTemplates: {},
    alarmSignalTemplates: []
  }
};

export type AdminAction =
  | {
      type: typeof PATCH_EQUIPMENT_GROUP_TEMPLATES;
      payload: BuildingTemplateResponseModel[];
    }
  | {
      type: typeof SET_EQUIPMENT_GROUP_TEMPLATES;
      payload: BuildingTemplateResponseModel[];
    }
  | {
      type: typeof PATCH_SIGNAL_TEMPLATES;
      payload:
        | EquipmentSignalProviderTemplateResponseModel[]
        | AdminAlarmSignalGroupTemplateResponseModel[];
    }
  | {
      type: typeof SET_SIGNAL_TEMPLATES;
      payload: {
        alarmSignalTemplates: AdminAlarmSignalGroupTemplateResponseModel[];
        equipmentSignalTemplates: EquipmentSignalProviderTemplateResponseModel[];
      };
    };

function admin(state = initialState, action: AdminAction): AdminReducerState {
  switch (action.type) {
    case PATCH_EQUIPMENT_GROUP_TEMPLATES: {
      const templatesToPatch = action.payload;
      const equipmentGroupTemplates = sortByLocaleCompare(
        state.equipmentGroupTemplates.slice(),
        'name'
      );

      templatesToPatch.forEach((template) => {
        const index = equipmentGroupTemplates.findIndex(
          (eqt) =>
            eqt.equipmentTemplateGroupId === template.equipmentTemplateGroupId
        );
        if (index !== -1) {
          equipmentGroupTemplates[index] = template;
        } else {
          equipmentGroupTemplates.push(template);
        }
      });

      return Object.assign({}, state, {
        equipmentGroupTemplates
      });
    }
    case SET_EQUIPMENT_GROUP_TEMPLATES: {
      let templates = _.cloneDeep(action.payload);
      templates.sort((a, b) => a.name.localeCompare(b.name));

      for (let i = 0; i < templates.length; i++) {
        let template = templates[i];
        template.equipmentTemplates.forEach((equipmentTemplate) => {
          equipmentTemplate.signalTemplateOverrides = translateBitmaskTypes(
            equipmentTemplate.signalTemplateOverrides
          );
        });
      }

      return Object.assign({}, state, {
        equipmentGroupTemplates: templates
      });
    }
    case PATCH_SIGNAL_TEMPLATES: {
      const signalTemplates = Object.assign({}, state.signalTemplates);
      const signalTemplatesToPatch = action.payload;

      signalTemplatesToPatch.forEach((template) => {
        // Merge equipment and alarm templates into one object so we can deduce which one it is
        let merged = template as Partial<
          EquipmentSignalProviderTemplateResponseModel &
            AdminAlarmSignalGroupTemplateResponseModel
        >;

        if (merged.equipmentTypeId && !merged.alarmSignalGroupTemplateId) {
          let eqTemplate =
            template as EquipmentSignalProviderTemplateResponseModel;
          signalTemplates.equipmentSignalTemplates[eqTemplate.equipmentTypeId] =
            eqTemplate;
        } else if (merged.alarmSignalGroupTemplateId) {
          let alarmTemplate =
            template as AdminAlarmSignalGroupTemplateResponseModel;

          const index = signalTemplates.alarmSignalTemplates.findIndex(
            (ast) =>
              ast.alarmSignalGroupTemplateId ===
              alarmTemplate.alarmSignalGroupTemplateId
          );

          if (index !== -1) {
            signalTemplates.alarmSignalTemplates[index] = alarmTemplate;
          } else {
            signalTemplates.alarmSignalTemplates.push(alarmTemplate);
          }
        }
      });

      return Object.assign({}, state, {
        signalTemplates
      });
    }
    case SET_SIGNAL_TEMPLATES: {
      let equipmentSignalDict: Record<
        string,
        EquipmentSignalProviderTemplateResponseModel
      > = {};
      action.payload.equipmentSignalTemplates.forEach(
        (est: EquipmentSignalProviderTemplateResponseModel) => {
          equipmentSignalDict[est.equipmentTypeId] = est;
        }
      );

      const data = {
        alarmSignalTemplates: action.payload.alarmSignalTemplates,
        equipmentSignalTemplates: equipmentSignalDict
      };

      return Object.assign({}, state, {
        signalTemplates: data
      });
    }
    // Run any matching handle in module or default to state
    default:
      return state;
  }
}

export default admin;
