import React, { useCallback, useMemo, useState } from 'react';
import DropdownMenu from '../DropdownButton/DropdownMenu';
import styles from './ProcessMapDropdown.module.css';
import { DropdownButtonOptionType } from '../DropdownButton/DropdownButton';

export type ProcessMapDropdownShowMenuCallback = (
  options: DropdownButtonOptionType[],
  location: { x: number; y: number }
) => void;

const useProcessMapDropdown = (): {
  showMenu: ProcessMapDropdownShowMenuCallback;
  hideMenu: () => void;
  ProcessMapDropdown: () => JSX.Element;
} => {
  const [actionOptions, setActionOptions] = useState([]);
  const [showActionOptions, setShowActionOptions] = useState(false);
  const [objectContextMenuLocation, setObjectContextMenuLocation] = useState<{
    x: number;
    y: number;
  }>(null);

  const ProcessMapDropdown = useMemo(() => {
    return () => (
      <DropdownMenu
        options={actionOptions}
        isShowing={showActionOptions}
        outerContainerClassName={styles.contextMenu}
        outerStyle={{
          left: objectContextMenuLocation?.x,
          top: objectContextMenuLocation?.y
        }}
      />
    );
  }, [
    actionOptions,
    objectContextMenuLocation?.x,
    objectContextMenuLocation?.y,
    showActionOptions
  ]);

  const showMenu = useCallback(
    (
      options: DropdownButtonOptionType[],
      location: { x: number; y: number }
    ) => {
      setActionOptions(options);
      setObjectContextMenuLocation(location);
      setShowActionOptions(true);
    },
    []
  );
  const hideMenu = useCallback(() => setShowActionOptions(false), []);
  return { ProcessMapDropdown, showMenu, hideMenu };
};

export default useProcessMapDropdown;
