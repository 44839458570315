import React, { useMemo, useState, useEffect, useContext } from 'react';
import T from 'ecto-common/lib/lang/Language';

import {
  calculateDataTableMinHeight,
  checkboxColumn,
  standardColumns,
  truncateColumns
} from 'ecto-common/lib/utils/dataTableUtils';

import { DashboardCollectionFormColumns } from 'js/components/DashboardCollections/DashboardCollectionForm';
import DashboardCollectionsDeleteModal from 'js/components/DashboardCollections/DashboardCollectionsDeleteModal';

import _ from 'lodash';
import PagedDataTable, {
  PagedDataTableErrorResult
} from 'ecto-common/lib/PagedDataTable/PagedDataTable';
import APIGen, {
  DashboardCollectionResponseModel
} from 'ecto-common/lib/API/APIGen';
import { useQueryClient } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

interface DashboardsCollectionsPageListProps {
  onClickRow(
    dashboard: DashboardCollectionResponseModel,
    row: number,
    column: number
  ): void;
  reloadTrigger?: number;
  pageSize?: number;
  useAsSelector?: boolean;
  selectedCollectionId?: string;
}

const DashboardsCollectionsPageList = ({
  onClickRow,
  useAsSelector = false,
  selectedCollectionId,
  reloadTrigger: externalReloadTrigger,
  pageSize = 15
}: DashboardsCollectionsPageListProps) => {
  const [page, setPage] = useState(0);
  const [deleteItem, setDeleteItem] =
    useState<DashboardCollectionResponseModel>(null);
  const { contextSettings } = useContext(TenantContext);

  const columns = useMemo(() => {
    return _.compact([
      useAsSelector &&
        checkboxColumn({
          rowIsChecked: (item: DashboardCollectionResponseModel) =>
            item.dashboardCollectionId === selectedCollectionId
        }),
      ...truncateColumns(DashboardCollectionFormColumns, useAsSelector),
      ...standardColumns({
        onDelete: useAsSelector ? null : setDeleteItem
      })
    ]);
  }, [selectedCollectionId, useAsSelector]);

  const collectionsQuery =
    APIGen.AdminDashboard.getPagedDashboardCollections.useQuery({
      page,
      pageSize
    });

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.invalidateQueries(
      APIGen.AdminDashboard.getPagedDashboardCollections.path(contextSettings)
    );
  }, [contextSettings, externalReloadTrigger, queryClient]);

  const results = useMemo(() => {
    if (collectionsQuery.error != null) {
      return PagedDataTableErrorResult;
    }

    return {
      hasError: false,
      result: collectionsQuery.data?.dashboardCollections ?? [],
      totalPages: collectionsQuery.data?.totalPages ?? 0,
      totalRecords: collectionsQuery.data?.totalRecords ?? 0
    };
  }, [collectionsQuery.data, collectionsQuery.error]);

  return (
    <>
      <PagedDataTable<DashboardCollectionResponseModel>
        columns={columns}
        data={results}
        pageSize={pageSize}
        page={page}
        onPageChange={setPage}
        noDataText={T.admin.dashboardcollection.nodata}
        onClickRow={onClickRow}
        isLoading={collectionsQuery.isLoading}
        useAllAvailableHeight={!useAsSelector}
        minHeight={
          useAsSelector
            ? calculateDataTableMinHeight({ pageSize, withCheckbox: true })
            : undefined
        }
      />

      <DashboardCollectionsDeleteModal
        deleteItem={deleteItem}
        onSuccess={() => {
          queryClient.invalidateQueries(
            APIGen.AdminDashboard.getPagedDashboardCollections.path(
              contextSettings
            )
          );
        }}
        onModalClose={() => setDeleteItem(null)}
      />
    </>
  );
};

export default DashboardsCollectionsPageList;
