import {
  ToolSignalProviders,
  ToolSignalProviderTranslations
} from 'js/components/ManageEquipment/EditEquipment/toolTypes';
import HelpPaths from 'ecto-common/help/tocKeys';
import NetmoreAPI from 'ecto-common/lib/utils/NetmoreAPI';
import EditNetmoreTool from 'js/components/ManageEquipment/EditEquipment/Netmore/EditNetmoreTool';
import { AdminToolImplementationType } from '../useEquipmentTools';

const NetmoreTool: AdminToolImplementationType<object> = {
  type: ToolSignalProviders.NETMORE,
  name: ToolSignalProviderTranslations[ToolSignalProviders.NETMORE],
  accessRights: [],
  allowDelete: true,
  allowDeploy: false,
  model: [],
  emptyInput: {},
  dialog: EditNetmoreTool,
  save: NetmoreAPI.addsOrUpdatesProviders,
  delete: NetmoreAPI.removeProviders,
  load: NetmoreAPI.getProvidersByNodeIds,
  helpPath: HelpPaths.docs.admin.manage.equipment.tools.netmore,
  idKey: 'id',
  includeDeviceId: false
};

export default NetmoreTool;
