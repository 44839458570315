import React, { useCallback, useMemo } from 'react';
import T from 'ecto-common/lib/lang/Language';
import SearchableSelectableTable from 'ecto-common/lib/SearchableTable/SearchableSelectableTable';
import { filterUsers } from 'js/components/ManageUsers/userUtils';
import _ from 'lodash';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import { TenantUserModel } from 'ecto-common/lib/API/IdentityServiceAPIGenV2';

const originalColumns: DataTableColumnProps<TenantUserModel>[] = [
  {
    label: T.admin.editlocation.users.username,
    dataKey: 'email',
    width: '50%',
    dataFormatter: (value) => {
      return <span>{value}</span>;
    }
  },
  {
    label: T.admin.editlocation.users.name,
    dataKey: 'displayName',
    width: '50%',
    dataFormatter: (value) => {
      return <span>{value}</span>;
    }
  }
];

interface SelectUsersProps {
  isLoading: boolean;
  data: TenantUserModel[];
  selectedData: string[];
  onMarkedData(userIds: string[]): void;
  extraColumns?: DataTableColumnProps<TenantUserModel>[];
  // func (row, extraColumnsStartIndex)
  shouldIgnoreOnClickRow?(
    row: TenantUserModel,
    rowIndex: number,
    columnIndex: number,
    extraColumnStartIndex: number
  ): boolean;
}

type UsersSelectedType = { userId: string };

const SelectUsers = ({
  isLoading,
  data,
  selectedData,
  onMarkedData,
  extraColumns,
  shouldIgnoreOnClickRow
}: SelectUsersProps) => {
  const columns = useMemo(
    () => [...originalColumns, ...(extraColumns || [])],
    [extraColumns]
  );

  // Create a thin wrappers around SearchableSelectableTable format so user can pass and receive raw
  // lists of user id:s
  const _onMarkedData = useCallback(
    (markedData: UsersSelectedType[]) => {
      onMarkedData?.(_.map(markedData, 'userId'));
    },
    [onMarkedData]
  );

  const _selectedData: UsersSelectedType[] = useMemo(
    () => selectedData.map((userId) => ({ userId })),
    [selectedData]
  );

  return (
    <SearchableSelectableTable
      dataKey={'userId'}
      noDataString={T.admin.editlocation.users.nousersfound}
      searchPlaceholder={T.admin.editlocation.users.searchplaceholder}
      isLoading={isLoading}
      data={data}
      columns={columns}
      selectedData={_selectedData}
      onMarkedData={_onMarkedData}
      shouldIgnoreOnClickRow={(row, rowIndex, columnIndex) => {
        if (columnIndex <= originalColumns.length) {
          return false;
        }
        // + 1 offset for the original checkbox
        return (
          shouldIgnoreOnClickRow &&
          shouldIgnoreOnClickRow(
            row,
            rowIndex,
            columnIndex,
            originalColumns.length + 1
          )
        );
      }}
      filterPredicate={filterUsers}
    />
  );
};

export default React.memo(SelectUsers);
