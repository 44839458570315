import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import BatchEditComfortToolsStep1 from 'js/components/EditLocation/Tools/Comfort/BatchEditComfortToolsStep1';
import Icons from 'ecto-common/lib/Icons/Icons';
import ModalActionFooter from 'ecto-common/lib/Modal/ModalActionFooter';
import T from 'ecto-common/lib/lang/Language';
import styles from 'js/components/ManageEquipment/EditEquipment/Comfort/EditComfortDialog.module.css';
import Button from 'ecto-common/lib/Button/Button';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import API from 'ecto-common/lib/API/API';
import _ from 'lodash';
import {
  saveAndFetchComfortTools,
  sortComfortParameterArrays
} from 'js/components/ManageEquipment/EditEquipment/EditToolComponents/EditComfortTool';
import EditComfortParameters from 'js/components/ManageEquipment/EditEquipment/Comfort/EditComfortParameters';
import {
  createInputForEquipments,
  createMergedComfortHeatingParameters,
  getBatchEditedComfortParametersToSave
} from 'js/components/EditLocation/Tools/Comfort/batchEditComfortUtils';
import { useUpdateModelFormInput } from 'ecto-common/lib/ModelForm/formUtils';
import UUID from 'uuidjs';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import { ComfortEquipmentWithToolData } from 'js/components/EditLocation/Tools/ManageComfortTools';
import {
  AddOrUpdateComfortToolSetResponseModel,
  ComfortHeatingProviderResponseModel,
  CreateComfortHeatingByTemplateResponseModel
} from 'ecto-common/lib/API/APIGen';

interface BatchEditComfortToolsProps {
  equipments?: ComfortEquipmentWithToolData[];
  isOpen?: boolean;
  onModalClose: () => void;
  onToolsSaved?(providers: ComfortHeatingProviderResponseModel[]): void;
}

const BatchEditComfortTools = ({
  equipments,
  isOpen,
  onModalClose,
  onToolsSaved
}: BatchEditComfortToolsProps) => {
  const [editParameters, setEditParameters] = useState<
    CreateComfortHeatingByTemplateResponseModel[]
  >([]);
  const [mergedComfortHeatingParameters, setMergedComfortHeatingParameters] =
    useState<AddOrUpdateComfortToolSetResponseModel>({
      id: null
    });
  const [editedComfortHeatingParameters, setEditedComfortHeatingParameters] =
    useState<AddOrUpdateComfortToolSetResponseModel>(null);
  const isEditingParameters = editParameters.length > 0;
  const nodeId = useAdminSelector((state) => state.general.nodeId);
  const [editToolConfigIsValid, setEditToolConfigIsValid] = useState(false);
  const [editParametersConfigIsValid, setEditParametersConfigIsValid] =
    useState<boolean>(false);
  const [input, setInput] = useState(createInputForEquipments(equipments));

  const closeDialog = useCallback(() => {
    onModalClose();
    setEditedComfortHeatingParameters(null);
    setMergedComfortHeatingParameters({ id: null });
    setEditParameters([]);
  }, [onModalClose]);

  const [isLoadingGetParameters, getParameters] = usePromiseCall({
    promise: API.Admin.Comfort.getParameters,
    onSuccess: (parameters) => {
      const sortedParameters = parameters.map(sortComfortParameterArrays);
      setEditParameters(sortedParameters);
      const mergedParams =
        createMergedComfortHeatingParameters(sortedParameters);
      setEditedComfortHeatingParameters(null);
      setMergedComfortHeatingParameters(mergedParams);
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.equipment.request.updatetoolfailed);
    }
  });

  const [isLoadingSaveTools, saveTools] = usePromiseCall({
    promise: saveAndFetchComfortTools,
    onSuccess: (newProviders) => {
      onToolsSaved(newProviders);
      closeDialog();
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.equipment.request.updatetoolfailed);
    }
  });

  const signalData = useMemo(() => {
    const alarmSignals = _(editParameters)
      .map('addOrUpdateAlarmSignalProvider')
      .map((provider) => {
        return _.map(provider.signals, (signal) => ({
          ...signal,
          signalProviderName: provider.name ?? ''
        }));
      })
      .flatMap()
      .value();

    const eqSignals = _.flatMap(
      editParameters,
      'addOrUpdateComfortHeatingProvider.addOrUpdateComfortToolSet.addOrUpdateEquipmentSignals'
    ).map((signal) => {
      const equipment = _.find(equipments, ['equipmentId', signal.equipmentId]);
      return {
        ...signal,
        signalProviderName: equipment?.name ?? ''
      };
    });

    return _.keyBy([...alarmSignals, ...eqSignals], 'id');
  }, [editParameters, equipments]);

  const cancelEditParameters = useCallback(() => {
    setEditParameters([]);
  }, []);

  const onUpdateInput = useUpdateModelFormInput(setInput);

  useEffect(() => {
    setInput(createInputForEquipments(equipments));
  }, [equipments]);

  const save = useCallback(() => {
    if (!isEditingParameters) {
      const tools = _.map(equipments, (equipment) => {
        const newSettings = _.find(input.equipmentSettings, [
          'equipmentId',
          equipment.equipmentId
        ]);

        return {
          ...(equipment.tool ?? {
            equipmentId: equipment.equipmentId,
            nodeId
          }),
          ...newSettings,
          outTemperatureSignalId: input.outTemperatureSignalId,
          windSpeedSignalId: input.windSpeedSignalId,
          providerId: newSettings.providerId ?? UUID.generate()
        };
      });

      getParameters(tools);
    } else {
      const toSave = getBatchEditedComfortParametersToSave(
        equipments,
        editParameters,
        editedComfortHeatingParameters
      );
      saveTools(toSave);
    }
  }, [
    nodeId,
    input,
    equipments,
    getParameters,
    isEditingParameters,
    editedComfortHeatingParameters,
    editParameters,
    saveTools
  ]);

  const isLoading = isLoadingGetParameters || isLoadingSaveTools;
  const footer = (
    <ModalActionFooter
      onModalClose={closeDialog}
      isLoading={isLoading}
      onConfirm={save}
      confirmDisabled={
        isEditingParameters
          ? !editParametersConfigIsValid
          : !editToolConfigIsValid
      }
      confirmText={
        isEditingParameters ? (
          <>
            <Icons.Save /> {T.common.save}
          </>
        ) : (
          <>
            {T.common.next}{' '}
            <Icons.NavigationArrowRight className={styles.leftArrow} />
          </>
        )
      }
      leftSide={
        <Button
          disabled={!isEditingParameters || isLoading}
          onClick={cancelEditParameters}
        >
          <Icons.NavigationArrowLeft />
          {T.common.back}
        </Button>
      }
    />
  );

  return (
    <ActionModal
      isOpen={isOpen}
      onModalClose={closeDialog}
      title={T.admin.editlocation.tools.comfort.batchtitle}
      onConfirmClick={closeDialog}
      headerIcon={Icons.Edit}
      footer={footer}
      isLoading={isLoading}
    >
      {!isEditingParameters && (
        <BatchEditComfortToolsStep1
          equipments={equipments}
          input={input}
          onUpdateInput={onUpdateInput}
          setConfigIsValid={setEditToolConfigIsValid}
        />
      )}
      {isEditingParameters && (
        <EditComfortParameters
          comfortToolsetParameters={mergedComfortHeatingParameters}
          onComfortToolsetParametersChanged={setEditedComfortHeatingParameters}
          signalData={signalData}
          setConfigIsValid={setEditParametersConfigIsValid}
        />
      )}
    </ActionModal>
  );
};

export default BatchEditComfortTools;
