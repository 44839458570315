import _ from 'lodash';
import API, {
  CancellablePromiseCallback,
  cancellablePromiseSequence,
  cancellablePromiseSequenceWithNamedResult
} from 'ecto-common/lib/API/API';
import waitUntil from 'ecto-common/lib/utils/waitUntil';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { IoTDeviceViewResponseModel } from 'ecto-common/lib/API/APIGen';

const getUptime = (results: IoTDeviceViewResponseModel[]) =>
  _.get(results, '[0].reportedSystem.uptime');

const isResultUpdated = (result: DeviceSequenceResult) => {
  return getUptime(result.firstUptime) > getUptime(result.secondUptime);
};

type DeviceSequenceResult = {
  firstUptime: IoTDeviceViewResponseModel[];
  secondUptime: IoTDeviceViewResponseModel[];
};

const checkIfRebootUpdatedPromise = (
  contextSettings: ApiContextSettings,
  ioTDeviceId: string
) => {
  const then = new Date().getTime() + 60 * 1000;

  return cancellablePromiseSequenceWithNamedResult({
    firstSyncDeviceTwin: () =>
      API.Admin.Devices.syncDeviceTwin(contextSettings, ioTDeviceId),
    firstUptime: () =>
      API.Admin.IoTDevices.getIoTDeviceByIoTDeviceId(
        contextSettings,
        ioTDeviceId
      ),
    waitUntil: () => waitUntil(then),
    secondSyncDeviceTwin: () =>
      API.Admin.Devices.syncDeviceTwin(contextSettings, ioTDeviceId),
    secondUptime: () =>
      API.Admin.IoTDevices.getIoTDeviceByIoTDeviceId(
        contextSettings,
        ioTDeviceId
      )
  });
};

const checkRebootStatusTwicePromise = (
  contextSettings: ApiContextSettings,
  ioTDeviceId: string
) => {
  return cancellablePromiseSequence(
    (withNextPromise: CancellablePromiseCallback) => {
      return withNextPromise(
        checkIfRebootUpdatedPromise(contextSettings, ioTDeviceId)
      ).then((result: DeviceSequenceResult) => {
        if (isResultUpdated(result)) {
          return Promise.resolve();
        }

        return withNextPromise(
          checkIfRebootUpdatedPromise(contextSettings, ioTDeviceId)
        ).then((secondResult: DeviceSequenceResult) => {
          if (isResultUpdated(secondResult)) {
            return Promise.resolve();
          }

          // Device was not confirmed online after two tries, throw error
          throw Error('error');
        });
      });
    }
  );
};

const rebootDevice = (
  contextSettings: ApiContextSettings,
  ioTDeviceId: string
) => {
  return cancellablePromiseSequence(
    (withNextPromise: CancellablePromiseCallback) => {
      return withNextPromise(
        API.Admin.IoTDevices.rebootDevice(contextSettings, ioTDeviceId)
      ).then(() => {
        return withNextPromise(
          checkRebootStatusTwicePromise(contextSettings, ioTDeviceId)
        );
      });
    }
  );
};

export default rebootDevice;
