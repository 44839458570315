import React, { useContext } from 'react';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import { ResourceType, ROOT_NODE_ID } from 'ecto-common/lib/constants';
import { Redirect } from 'react-router-dom';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import T from 'ecto-common/lib/lang/Language';
import Page from 'ecto-common/lib/Page/Page';
import { generatePath } from 'react-router';
import _ from 'lodash';
import { getLastNodeId } from 'ecto-common/lib/utils/cacheKeys';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { RouteItem } from 'ecto-common/lib/BaseContainer/BaseContainer';
import { enumValues } from 'ecto-common/lib/utils/typescriptUtils';
import { GridType } from 'ecto-common/lib/API/APIGen';
import { Location } from 'history';

const NoValidResourcesPage = () => {
  const content = (
    <ToolbarContentPage title={T.common.error} showLocationPicker={false}>
      {T.tenants.noresources}
    </ToolbarContentPage>
  );

  return <Page content={content} />;
};

interface BaseLocationProps {
  routes?: RouteItem[];
  location?: Location<unknown>;
  allowNoLocation?: boolean;
}

const legacyPathRegexp = new RegExp(
  '/grid/(' + enumValues(GridType).join('|') + ')',
  'm'
);

/**
 * Loaded when we are logged in but do not have an exact route. It will try to find a node to send the user to.
 * @constructor
 */
const BaseLocation = ({
  location,
  routes,
  allowNoLocation = false
}: BaseLocationProps) => {
  const nodeTree = useCommonSelector((state) => state.general.nodeTree);
  const nodeMap = useCommonSelector((state) => state.general.nodeMap);
  const { tenantId, tenantResources } = useContext(TenantContext);

  // Handle the old style paths that had the grid as part of the URL.
  if (legacyPathRegexp.test(location.pathname)) {
    const newLocation = Object.assign({}, location, {
      pathname: location.pathname.replace(legacyPathRegexp, '/home')
    });

    return <Redirect to={newLocation} />;
  }

  if (
    hasAccessToResource(ResourceType.CORE, tenantResources) &&
    !hasAccessToResource(ResourceType.TEMPLATE_MANAGEMENT, tenantResources)
  ) {
    let newLocation;

    if (nodeTree.length === 0) {
      if (allowNoLocation) {
        newLocation = Object.assign({}, location, {
          pathname: `/${tenantId}/home/${ROOT_NODE_ID}/dashboard`
        });
      } else {
        newLocation = Object.assign({}, location, {
          pathname: `/${tenantId}/noLocations`
        });
      }
    } else if (nodeTree && nodeTree.length > 0) {
      let defaultLocation =
        nodeTree[0].children.length > 0 ? nodeTree[0].children[0] : nodeTree[0];
      const { nodeId } = getLastNodeId(tenantId);
      const prevLocation = getNodeFromMap(nodeMap, nodeId);

      if (prevLocation) {
        defaultLocation = prevLocation;
      }

      newLocation = Object.assign({}, location, {
        pathname: `/${tenantId}/home/${defaultLocation.nodeId}/dashboard`
      });
    }

    return <Redirect to={newLocation} />;
  }

  const validPages = _.filter(
    routes,
    (page) => !page.path.endsWith('noLocations')
  );

  const firstValidPage = _.head(validPages);

  if (firstValidPage == null) {
    return <NoValidResourcesPage />;
  }

  let newLocation = generatePath(firstValidPage.path, { tenantId });

  if (newLocation != null) {
    if (!newLocation.endsWith('/')) {
      newLocation += '/';
    }

    return <Redirect to={newLocation} />;
  }

  return <></>;
};

export default BaseLocation;
