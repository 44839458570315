import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  Dispatch,
  SetStateAction
} from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import styles from './Panel.module.css';
import panels from './panels';
import { replaceLanguageVariables } from 'ecto-common/lib/lang/localizationUtils';
import useReloadTrigger from 'ecto-common/lib/hooks/useReloadTrigger';
import dimensions from 'ecto-common/lib/styles/dimensions';

import { AllDataSources } from 'ecto-common/lib/Dashboard/datasources';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import T from 'ecto-common/lib/lang/Language';
import ErrorBoundary from 'ecto-common/lib/utils/ErrorBoundary';
import classNames from 'classnames';
import PanelDropDownMenu, {
  PanelDropDownOption
} from 'ecto-common/lib/Dashboard/PanelDropDownMenu';
import Icons from 'ecto-common/lib/Icons/Icons';
import { withSize } from 'react-sizeme';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import MarkdownModal from 'ecto-common/lib/Markdown/MarkdownModal';
import Button from 'ecto-common/lib/Button/Button';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';
import { ESC } from 'ecto-common/lib/utils/KeyboardShortcuts';
import { DashboardViewResponseModel } from 'ecto-common/lib/API/APIGen';
import DataSourceTypes from 'ecto-common/lib/Dashboard/datasources/DataSourceTypes';
import { PromiseCacheContext } from 'ecto-common/lib/Dashboard/datasources/signalUtils';

export type DashboardCopyPanelToPersonalFormState = {
  title?: string;
  dashboard?: DashboardViewResponseModel;
  useFixedLocation: boolean;
};

type PanelSize = {
  width: number;
  height: number;
};

export type PanelApi = {
  size: PanelSize;
  title: string;
  setTitle: Dispatch<SetStateAction<string>>;
  cacheContext: PromiseCacheContext;
  // Triggers the data sources to refresh (re-fetch data if needed)
  reload: () => void;
};

export type PanelSizeType = {
  width: number;
  height: number;
};

export type PanelTarget = {
  sourceType: DataSourceTypes;
  nodeId?: string;
  nodeIds?: string[];
  useSiblings?: boolean;
};

export type DashboardPanel = {
  id: string;
  title?: string;
  targets: Record<string, PanelTarget>;
  type: string;
  gridPos: {
    x: number;
    w: number;
    y: number;
    h: number;
  };
  startFullscreen?: boolean;
  description?: string;
  version?: number;
};

export type CustomPanelProps = {
  panelApi: PanelApi;
  panel: DashboardPanel;
};

const PanelTitlebarHeight =
  34 + dimensions.smallMargin * 2 + dimensions.borderWidth;

// We use size me one time for the entire panel instead of different panel types doing it themselves.
// But the outer panel size is larger than the size that is available to the panels, so we need to adjust
// for that.
const innerPanelContentSize = (panelSize: PanelSize): PanelSize => {
  return {
    width:
      panelSize.width != null
        ? panelSize.width -
          dimensions.standardMargin * 2 -
          dimensions.borderWidth * 2
        : panelSize.width,
    height:
      panelSize.height != null
        ? panelSize.height - PanelTitlebarHeight - dimensions.standardMargin * 2
        : panelSize.height
  };
};

interface PanelProps {
  panel?: DashboardPanel;
  menuOptions?: PanelDropDownOption[];
  titleClassName?: string;
  contentClassName?: string;
  showOnlyContent?: boolean;
  size?: PanelSizeType;
  cacheContext?: PromiseCacheContext;
  reloadTrigger?: number;
}

const Panel = ({
  panel,
  menuOptions,
  size: outerSize,
  cacheContext,
  contentClassName = null,
  titleClassName = null,
  showOnlyContent = false,
  reloadTrigger: externalReloadTrigger = 0
}: PanelProps) => {
  const [title, setTitle] = useState(() =>
    replaceLanguageVariables(panel.title)
  );

  useEffect(() => {
    setTitle(replaceLanguageVariables(panel.title));
  }, [panel.title]);

  let size = innerPanelContentSize(outerSize);

  const [reloadTrigger, triggerReload] = useReloadTrigger(
    externalReloadTrigger
  );
  const [descriptionDialogOpen, showDescriptionDialog, hideDescriptionDialog] =
    useDialogState(false);
  const [renderFullscreen, setRenderFullscreen] = useState(
    panel?.startFullscreen === true
  );
  const toggleFullscreen = useCallback(() => {
    setRenderFullscreen((r) => !r);
  }, []);

  const panelApi: PanelApi = useMemo(() => {
    return {
      size,
      title,
      setTitle,
      cacheContext,
      // Triggers the data sources to refresh (re-fetch data if needed)
      reload: triggerReload
    };
  }, [title, setTitle, triggerReload, size, cacheContext]);

  const componentData = panels[panel.type];
  const ContentComponent = componentData?.component;
  const componentEmptyTargets = componentData.data.emptyTargets;

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const data: Record<string, any> = _.mapValues(
    panel.targets,
    (target: PanelTarget, targetName) => {
      if (_.isObject(target) && target.sourceType != null) {
        const { sourceType, ...inputs } = target;
        const dataSourceType = AllDataSources[sourceType];

        // also merge non user data constants from panel target definition
        // remove special names that we use for this source type and the models ('arguments')
        const constantValuesFromComponent = _.omit(
          componentEmptyTargets[targetName],
          ['arguments', 'sourceType']
        );

        return (
          dataSourceType?.implementation({
            ...constantValuesFromComponent,
            ...inputs,
            reloadTrigger,
            size,
            cacheContext
          }) ?? null
        );
      }
      return target;
    }
  );

  const optionsWithFullscreen: PanelDropDownOption[] = useMemo(
    () =>
      (menuOptions ?? []).concat({
        icon: renderFullscreen ? <Icons.Contract /> : <Icons.Expand />,
        label: renderFullscreen
          ? T.dashboard.exitfullscreen
          : T.dashboard.enterfullscreen,
        action: toggleFullscreen
      }),
    [menuOptions, renderFullscreen, toggleFullscreen]
  );

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.keyCode === ESC.keyCode) {
        setRenderFullscreen(false);
      }
    };

    if (renderFullscreen) {
      window.addEventListener('keydown', handleEsc);
    }

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [renderFullscreen]);

  const closeFullScreen = useCallback(() => setRenderFullscreen(false), []);

  const content = (
    <>
      <div
        className={classNames(
          styles.panelContainer,
          renderFullscreen && styles.fullscreen,
          showOnlyContent && styles.showOnlyContent
        )}
      >
        {!showOnlyContent && (
          <div className={classNames(styles.panelTitle, titleClassName)}>
            <div className={styles.titleContent}>{title}</div>

            <div className={styles.flexibleSpace} />

            {panel.description && (
              <Tooltip text={T.common.description}>
                <Button isIconButton onClick={showDescriptionDialog}>
                  <Icons.Question size={'xs'} />
                </Button>
              </Tooltip>
            )}

            <div className={styles.buttonContainer}>
              <PanelDropDownMenu
                panel={panel}
                panelData={data}
                menuOptions={optionsWithFullscreen}
              />
              {renderFullscreen && (
                <Button isIconButton onClick={closeFullScreen}>
                  <Icons.Close />
                </Button>
              )}
            </div>
          </div>
        )}
        <div className={classNames(styles.panelContent, contentClassName)}>
          {ContentComponent && panelApi.size.width && panelApi.size.height && (
            <ErrorBoundary>
              <ContentComponent
                panelApi={panelApi}
                // @ts-ignore-next-line
                panel={panel}
                // @ts-ignore-next-line
                data={data}
              />
            </ErrorBoundary>
          )}
          {!ContentComponent && (
            <ErrorNotice> {T.dashboard.error.unsupportedpaneltype}</ErrorNotice>
          )}
        </div>
      </div>

      <MarkdownModal
        title={T.common.description}
        onConfirmClick={hideDescriptionDialog}
        isOpen={descriptionDialogOpen}
        content={panel.description}
      />
    </>
  );

  return renderFullscreen
    ? ReactDOM.createPortal(content, document.body)
    : content;
};

export default React.memo(
  withSize({
    monitorWidth: true,
    monitorHeight: true,
    refreshMode: 'debounce',
    refreshRate: 200,
    noPlaceholder: true
  })(Panel)
);
