import { DELETE_NODES_WITH_IDS, PATCH_NODES, SET_NODES } from './actionTypes';
import { createFlatNodeTree } from 'ecto-common/lib/utils/locationUtils';
import { CACHE_KEY_NODES } from 'ecto-common/lib/utils/cacheKeys';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { useContext, useEffect } from 'react';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import localStore from 'store';
import { GridType, NodeResponseModel } from 'ecto-common/lib/API/APIGen';
import _ from 'lodash';

export const deleteNodesWithIds = (nodeIds: string[]) => {
  return {
    type: DELETE_NODES_WITH_IDS,
    payload: nodeIds
  };
};

export const patchNodes = (nodes: NodeResponseModel[]) => {
  return {
    type: PATCH_NODES,
    payload: nodes
  };
};

export function setNodes(nodeResults: [NodeResponseModel[], GridType[]]) {
  let gridTypes;

  if (nodeResults[0] == null) {
    return {
      type: 'NOOP'
    };
  }

  let nodeList = _.cloneDeep(nodeResults[0]);
  gridTypes = nodeResults[1];

  const gridTree = createFlatNodeTree(gridTypes ?? [], nodeList ?? []);

  return {
    type: SET_NODES,
    payload: {
      nodeTree: gridTree.nodeTree,
      nodeList,
      gridTypes: gridTree.gridTypes
    }
  };
}

// Whenever node list changes due to incremental patches to the node tree, update the usePromiseCall
// response cache to include the patched data. Slightly hacky, can probably be removed once we have
// proper isolated tenant data.
export const useNodeCacheUpdate = () => {
  const { tenantId } = useContext(TenantContext);
  const nodeList = useCommonSelector((state) => state.general.nodeList);
  const gridTypes = useCommonSelector((state) => state.general.gridTypes);

  useEffect(() => {
    const data = [nodeList, gridTypes];
    const fullCacheKey = CACHE_KEY_NODES + '-' + tenantId;

    if (nodeList?.length > 0) {
      try {
        localStore.set(fullCacheKey, JSON.stringify(data ?? {}));
      } catch (e) {
        // Silently fail. Can be because of local storage limits etc.
        console.error(e);
      }
    }
  }, [tenantId, nodeList, gridTypes]);
};
