import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import { getTemplateManagementRoute } from 'js/utils/routeConstants';
import T from 'ecto-common/lib/lang/Language';
import NavLinkFix from 'ecto-common/lib/NavLinkFix/NavLinkFix';
import DashboardEditor from 'ecto-common/lib/DashboardEditor/DashboardEditor';
import { Prompt, useHistory } from 'react-router';
import _ from 'lodash';
import {
  DashboardData,
  DashboardFile,
  InitialDashboardFileData
} from 'ecto-common/lib/DashboardEditor/DashboardConstants';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import useReloadTrigger from 'ecto-common/lib/hooks/useReloadTrigger';
import DashboardMenu from 'ecto-common/lib/DashboardEditor/DashboardMenu';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import TimeRangeContext from 'ecto-common/lib/Dashboard/context/TimeRangeContext';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import styles from './EditDashboardPage.module.css';
import useTimeRangeSelector from 'ecto-common/lib/Dashboard/context/useTimeRangeSelector';
import { applyDashboardMigrations } from 'ecto-common/lib/Dashboard/migrations/migrations';
import DashboardDataContext, {
  useDashboardDataFromRedux
} from 'ecto-common/lib/hooks/DashboardDataContext';
import { SET_CURRENT_NODE } from 'ecto-common/lib/actions/actionTypes';
import HelpPaths from 'ecto-common/help/tocKeys';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import APIGen, { DashboardResponseModel } from 'ecto-common/lib/API/APIGen';
import useUndoHistory from 'ecto-common/lib/hooks/useUndoHistory';
import { DashboardPanel } from 'ecto-common/lib/Dashboard/Panel';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { useMutation, useQuery } from '@tanstack/react-query';

function partialSetter<SourceObject>(
  fun: Dispatch<SetStateAction<DashboardData>>,
  name: keyof DashboardData
) {
  return (newData: SourceObject) => {
    fun((oldData: DashboardData) => {
      const oldPartialData = _.get(oldData, name);
      if (_.isFunction(newData)) {
        const createdData = newData(oldPartialData);
        if (oldPartialData === createdData) {
          return oldData;
        }
        return _.set(
          oldData ? { ...oldData } : {},
          name,
          createdData
        ) as DashboardData;
      } else if (newData !== oldPartialData) {
        return _.set(
          oldData ? { ...oldData } : {},
          name,
          newData
        ) as DashboardData;
      }
      return oldData;
    });
  };
}

const saveDashboardDataPromise = (
  contextSettings: ApiContextSettings,
  dashboardData: DashboardData
) => {
  return Promise.all([
    APIGen.AdminDashboard.addOrUpdateDashboards.promise(
      contextSettings,
      [{ ...dashboardData.info, name: dashboardData.info.name ?? '' }],
      null
    ),
    APIGen.AdminDashboard.addOrUpdateDashboardFiles.promise(
      contextSettings,
      [
        {
          dashboardId: dashboardData.info.dashboardId,
          jsonData: dashboardData.jsonData
        }
      ],
      null
    )
  ] as const);
};

const createDashboardData = (
  info: DashboardResponseModel,
  jsonData: DashboardFile
): DashboardData => ({ info, jsonData });

interface EditDashboardPageProps {
  dashboardId?: string;
}

const EditDashboardPage = ({ dashboardId }: EditDashboardPageProps) => {
  const history = useHistory();
  const [panelEditItem, setPanelEditItem] = useState<DashboardPanel>(null);
  const [dashboardData, setDashboardData] = useState<DashboardData>(null);

  const setDashboardFile = useCallback((newJsonData: DashboardFile) => {
    partialSetter<DashboardFile>(setDashboardData, 'jsonData')(newJsonData);
  }, []);

  const setDashboard = useCallback((newInfo: DashboardResponseModel) => {
    partialSetter<DashboardResponseModel>(setDashboardData, 'info')(newInfo);
  }, []);

  const dashboard = dashboardData?.info;
  const dashboardFile = dashboardData?.jsonData;
  const [initialDashboards, setInitialDashboards] =
    useState<DashboardData[]>(null);
  const onUpdateFromUndo = useCallback((newDashboardData: DashboardData) => {
    setDashboardData(newDashboardData);
    setHasChanges(true);
  }, []);

  const [
    hasUndo,
    hasRedo,
    pushDashboardHistory,
    undoDashboardHistory,
    redoDashboardHistory
  ] = useUndoHistory(initialDashboards, onUpdateFromUndo);

  const { contextSettings } = useContext(TenantContext);
  const [hasChanges, setHasChanges] = useState(false);
  const [triggerAddPanel, setTriggerAddPanel] = useReloadTrigger();
  const enums = useAdminSelector((state) => state.general.enums);
  const signalTypesNameMap = useAdminSelector(
    (state) => state.general.signalTypesNameMap
  );
  const { tenantId } = useContext(TenantContext);

  const onDelete = useCallback(() => {
    setHasChanges(false);

    _.defer(() => {
      history.push(getTemplateManagementRoute(tenantId, 'dashboards'));
    });
  }, [tenantId, history]);

  const onEditedDashboard = useCallback(
    (editedDashboard: DashboardResponseModel) => {
      pushDashboardHistory(createDashboardData(editedDashboard, dashboardFile));
      setDashboard(editedDashboard);
      setHasChanges(true);
    },
    [dashboardFile, pushDashboardHistory, setDashboard]
  );

  const getDashboardQuery = useQuery(
    ['getDashboard', dashboardId],
    ({ signal }) => {
      const promises = Promise.all([
        APIGen.AdminDashboard.getDashboardsById.promise(
          contextSettings,
          { dashboardIds: [dashboardId] },
          signal
        ),
        APIGen.AdminDashboard.getDashboardFiles.promise(
          contextSettings,
          [{ dashboardId }],
          signal
        )
      ]);

      return promises.then(([newDashboards, data]) => {
        if (_.head(newDashboards) == null) {
          throw T.admin.dashboards.loadobject.failure;
        } else {
          try {
            const newDashboard = applyDashboardMigrations(
              (_.head(data)?.jsonData as DashboardFile) ?? {
                ...InitialDashboardFileData
              },
              enums,
              signalTypesNameMap
            );
            const loadedData = createDashboardData(
              _.head(newDashboards),
              newDashboard
            );
            return loadedData;
          } catch (e) {
            console.error(e);
            throw T.admin.dashboards.loadfile.failure;
          }
        }
      });
    }
  );

  useEffect(() => {
    if (getDashboardQuery.data != null) {
      setInitialDashboards([_.cloneDeep(getDashboardQuery.data)]);
      setDashboardData(getDashboardQuery.data);
    }
  }, [getDashboardQuery.data]);

  let error: string = null;
  if (getDashboardQuery.error != null) {
    error = _.isString(getDashboardQuery.error)
      ? getDashboardQuery.error
      : T.admin.dashboards.loadobject.failure;
  }

  const saveDashboardDataMutation = useMutation({
    mutationFn: (data: DashboardData) => {
      return saveDashboardDataPromise(contextSettings, data);
    },
    onSuccess: () => {
      setHasChanges(false);
      toastStore.addSuccessToast(T.admin.dashboards.change.success);
    },
    onError: () => toastStore.addErrorToast(T.admin.dashboards.save.failure)
  });

  const onSaveDashboardFile = useCallback(() => {
    saveDashboardDataMutation.mutate(dashboardData);
  }, [dashboardData, saveDashboardDataMutation]);

  const [timeRange, timeRangeComponent] = useTimeRangeSelector();
  const showingDialog = panelEditItem != null;

  const menu = (
    <>
      <ToolbarItem>
        <DashboardMenu
          enableSelect={false}
          isLoadingDashboards={getDashboardQuery.isLoading}
          dashboard={dashboard}
          showAddNewDashboard={false}
          onAddPanel={setTriggerAddPanel}
          onDashboardUpdated={onEditedDashboard}
          onDeleteDashboard={onDelete}
          onSave={onSaveDashboardFile}
          hasChanges={hasChanges}
          undo={undoDashboardHistory}
          enableUndo={hasUndo && !showingDialog}
          redo={redoDashboardHistory}
          enableRedo={hasRedo && !showingDialog}
          disableEditing={dashboard == null || dashboardFile == null}
        />
      </ToolbarItem>
      <ToolbarFlexibleSpace />
      <ToolbarItem className={styles.timerange}>
        {timeRangeComponent}
      </ToolbarItem>
    </>
  );

  const onDashboardFileChanged = useCallback(
    (newDashboardFile: DashboardFile) => {
      pushDashboardHistory(createDashboardData(dashboard, newDashboardFile));
      setHasChanges(true);
    },
    [dashboard, pushDashboardHistory]
  );

  const title = (
    <>
      <NavLinkFix to={getTemplateManagementRoute(tenantId, 'dashboards')}>
        {T.admin.dashboards.title}
      </NavLinkFix>
      &nbsp;&gt;&nbsp;{dashboard?.name}
    </>
  );

  const [locationPickerOpen, setLocationPickerOpen] = useState(false);

  useEffect(() => {
    _.defer(() => {
      window.dispatchEvent(new Event('resize'));
    });
  }, [locationPickerOpen]);

  const dispatch = useAdminDispatch();

  const setNode = useCallback(
    (nodeId: string) => {
      dispatch({ type: SET_CURRENT_NODE, payload: { nodeId } });
    },
    [dispatch]
  );

  const nodeId = useAdminSelector((state) => state.general.nodeId);
  const reduxDashboardData = useDashboardDataFromRedux();
  const dashboardDataValue = useMemo(
    () => ({ nodeId, setNode, ...reduxDashboardData }),
    [nodeId, setNode, reduxDashboardData]
  );

  return (
    <ToolbarContentPage
      title={title}
      wrapContent={false}
      toolbarItems={menu}
      showLocationPicker
      locationPickerOpen={locationPickerOpen}
      setLocationPickerOpen={setLocationPickerOpen}
      disablePageChange
      helpPath={HelpPaths.docs.admin.manage.dashboards}
    >
      <Prompt when={hasChanges} message={T.admin.form.unsavedstate} />
      <DashboardDataContext.Provider value={dashboardDataValue}>
        <TimeRangeContext.Provider value={timeRange}>
          <DashboardEditor
            panelEditItem={panelEditItem}
            setPanelEditItem={setPanelEditItem}
            dashboardFile={dashboardFile}
            setDashboardFile={setDashboardFile}
            isLoading={
              getDashboardQuery.isLoading || saveDashboardDataMutation.isLoading
            }
            triggerAddPanel={triggerAddPanel}
            error={error}
            onDashboardFileChanged={onDashboardFileChanged}
          />
        </TimeRangeContext.Provider>
      </DashboardDataContext.Provider>
    </ToolbarContentPage>
  );
};

export default React.memo(EditDashboardPage);
