import { useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import useReloadTrigger from 'ecto-common/lib/hooks/useReloadTrigger';
import T from 'ecto-common/lib/lang/Language';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import {
  AdminToolImplementationType,
  ToolProvider
} from 'js/components/ManageEquipment/EditEquipment/useEquipmentTools';

/**
 * Loads tool for current equipment and node
 * @param tool tool definition
 * @param nodeId node id to get the tool for
 * @param equipmentId equipment id to load the node for
 * @returns {[currentTool, isLoadingTool, triggerReload]}
 */
const useToolLoader = <ObjectType extends object>(
  tool: AdminToolImplementationType<ObjectType>,
  nodeId: string,
  equipmentId: string
): [
  currentTool: ToolProvider,
  getProvidersIsLoading: boolean,
  triggerReload: () => void
] => {
  const { tenantResources } = useContext(TenantContext);
  let canUseTool = _.every(tool.accessRights, (accessRight) =>
    hasAccessToResource(accessRight, tenantResources)
  );
  canUseTool =
    canUseTool &&
    (tool.available ? tool.available(tool, nodeId, equipmentId) : true);

  const [currentTool, setCurrentTool] = useState<ToolProvider>(null);
  const [reloadTrigger, triggerReload] = useReloadTrigger();
  const [error, setError] = useState(false);

  const [getProvidersIsLoading, getProvidersByNodeId] = usePromiseCall({
    promise: tool.load,
    onSuccess: (result) =>
      setCurrentTool(_.find(result, ['nodeId', equipmentId])),
    onError: () => setError(true)
  });

  useEffect(() => {
    if (error) {
      toastStore.addErrorToast(
        T.format(T.admin.equipmenttools.error.signalfetchfailed, tool.name)
      );
    }
  }, [error, tool.name]);

  useEffect(() => {
    if (canUseTool) {
      getProvidersByNodeId([equipmentId]);
    }
  }, [equipmentId, reloadTrigger, getProvidersByNodeId, canUseTool]);

  return [currentTool, getProvidersIsLoading, triggerReload];
};

export default useToolLoader;
