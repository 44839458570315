import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useMemo
} from 'react';
import _ from 'lodash';

import {
  ModelDefinition,
  ModelFormSectionStyle,
  ModelFormSectionType,
  ModelMapItem
} from './ModelPropType';
import ModelEditor, { evaluateModelFormRootProperty } from './ModelEditor';
import ModelFormSection from 'ecto-common/lib/ModelForm/ModelFormSection';
import ModelFormSegmentControl from 'ecto-common/lib/ModelForm/ModelFormSegmentControl';
import {
  getPathFromModelKeyFunc,
  getPathStringFromModelKeyFunc,
  modelFormSectionsToModels
} from 'ecto-common/lib/ModelForm/formUtils';
import ModelFormSelectControl from 'ecto-common/lib/ModelForm/ModelFormSelectControl';
import { modelFormSectionsAreValid } from 'ecto-common/lib/ModelForm/validateForm';
import { typedMemo } from 'ecto-common/lib/utils/typescriptUtils';

function renderModel<ObjectType extends object, EnvironmentType extends object>(
  model: ModelMapItem<ObjectType>,
  input: ObjectType,
  environment: EnvironmentType,
  disableErrors: boolean,
  useTooltipHelpTexts: boolean,
  disabled: boolean
) {
  return (
    <ModelEditor
      {...model}
      input={input}
      environment={environment}
      disableErrors={disableErrors}
      useTooltipHelpTexts={useTooltipHelpTexts}
      disabled={disabled}
    />
  );
}

function renderModelsMap<
  ObjectType extends object,
  EnvironmentType extends object
>(
  modelsMap: Record<string, ModelMapItem<ObjectType>>,
  input: ObjectType,
  environment: EnvironmentType,
  disableErrors: boolean,
  useTooltipHelpTexts: boolean,
  disabled: boolean
) {
  return Object.values(modelsMap).map((model) =>
    renderModel(
      model,
      input,
      environment,
      disableErrors,
      useTooltipHelpTexts,
      disabled
    )
  );
}

function validateSections<ObjectType extends object>(
  sections: ModelFormSectionType<ObjectType>[],
  modelsMap: Record<string, ModelMapItem<ObjectType>>
) {
  const allModels = Object.values(modelsMap);
  const allModelsInSections = modelFormSectionsToModels(sections).map((model) =>
    getPathStringFromModelKeyFunc(model.key)
  );
  const remainingModels = allModels.filter(
    (model) =>
      !allModelsInSections.includes(
        getPathStringFromModelKeyFunc(model.model.key)
      )
  );

  if (remainingModels.length > 0) {
    console.error(
      'Not all models are referenced in sections!',
      remainingModels.map((m) => m.model.key)
    );
  }
}

function renderSectionList<ObjectType extends object>(
  sections: ModelFormSectionType<ObjectType>[],
  renderChildModel: ModelRenderCallbackFunction<ObjectType>,
  sectionClassName: string,
  input: ObjectType
) {
  return (
    <>
      {sections.map((section, idx) => (
        <ModelFormSection
          key={idx}
          section={section}
          renderChildModel={renderChildModel}
          className={sectionClassName}
          input={input}
        />
      ))}
    </>
  );
}

function renderSectionSegmentControl<ObjectType extends object>(
  sections: ModelFormSectionType<ObjectType>[],
  renderChildModel: ModelRenderCallbackFunction<ObjectType>,
  sectionClassName: string,
  input: ObjectType
) {
  return (
    <ModelFormSegmentControl
      sections={sections}
      renderChildModel={renderChildModel}
      sectionClassName={sectionClassName}
      input={input}
    />
  );
}

function renderSectionSelectControl<ObjectType extends object>(
  sections: ModelFormSectionType<ObjectType>[],
  renderChildModel: ModelRenderCallbackFunction<ObjectType>,
  sectionsTitle: React.ReactNode,
  sectionClassName: string,
  input: ObjectType
) {
  return (
    <ModelFormSelectControl
      sections={sections}
      renderChildModel={renderChildModel}
      sectionsTitle={sectionsTitle}
      sectionClassName={sectionClassName}
      input={input}
    />
  );
}

export type ModelRenderCallbackFunction<ObjectType extends object> = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderChildModel: (input: ObjectType) => any
) => JSX.Element;
export type ModelRenderChildModelFunction<ObjectType extends object> = (
  func: ModelRenderCallbackFunction<ObjectType>
) => JSX.Element;

interface ModelFormProps<ObjectType extends object> {
  isLoading?: boolean;
  horizontalModels?: boolean;
  models?: ModelDefinition<ObjectType>[];
  onUpdateInput?: (key: string[], value: unknown) => void;
  setInput?: Dispatch<SetStateAction<ObjectType>>;
  input: ObjectType;
  environment?: object;
  sections?: ModelFormSectionType<ObjectType>[];
  disableErrors?: boolean;
  children?: ModelRenderChildModelFunction<ObjectType>;
  sectionStyle?: ModelFormSectionStyle;
  sectionClassName?: string;
  sectionsTitle?: React.ReactNode;
  useTooltipHelpTexts?: boolean;
  disabled?: boolean;
}

/**
 * ModelForm renders data driven form components. The input fields are defined in the models object,
 * and the current values are specified in input. For a list of the different model types available,
 * @see ModelType. For a specification of the model format @see ModelPropType.
 *
 * ModelForm calls onUpdateInput for each key.
 *
 * @param models models to generate form input ui.
 * @param input Input data for forms
 * @param onUpdateInput Called with (key, value) when input for 'key' changes to new value 'value'.
 * @param setInput Can be used to update input directly. Expects a setter from React.
 * useState.
 * @param horizontalModels Use horizontal rendering
 * @param isLoading {Boolean} Whether this form is currently loading values
 * @param children node or render function
 * @param environment {{}} set this object to add extra parameters that can be used by the model functions. For instance request status etc
 * @param disableErrors {Boolean} set this to disable error state rendering. Useful when state is not fully loaded.
 * @param disabled {Boolean} set this to disable all input fields
 * @param sections A section is a grouping of models under a label (optional). The section can be collapsed. It renders a list of lines with support for multiple editors per line. Basically this property gives you a way to create a more structured layout of the form, but it is completely optional. Any model not found in the sections is rendered after the sections. See @ModelFormSectionPropType for more information regarding the format.
 * @param sectionStyle If using sections, how should they be rendered? Either as a long list of sections or as a segment control.
 * @param sectionClassName If using sections, apply this class name to the sections.
 * @param sectionsTitle {string} Title of the section
 * @param useTooltipHelpTexts {boolean} If true, the help texts will be shown as tooltips instead of labels.
 * @returns {*}
 * @constructor
 */
const ModelForm = <
  ObjectType extends object,
  EnvironmentType extends object = object
>({
  models,
  input,
  onUpdateInput,
  setInput,
  horizontalModels,
  isLoading,
  children,
  environment,
  disableErrors,
  sections,
  sectionStyle = ModelFormSectionStyle.SECTION_LIST,
  sectionClassName,
  sectionsTitle,
  disabled = false,
  useTooltipHelpTexts = false
}: ModelFormProps<ObjectType>) => {
  if (sections?.length > 0 && models?.length > 0) {
    console.error('Specify either a sections layout or a simple models array');
  }

  const allModels = useMemo(() => {
    if (sections?.length > 0) {
      return modelFormSectionsToModels(sections);
    }

    return models ?? [];
  }, [sections, models]);

  const _onUpdateInput = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (name: string[], value: any) => {
      onUpdateInput?.(name, value);

      setInput?.((oldItem) => {
        const newItem = { ...oldItem };
        // TODO: This modifies the potentially nested object - which is not optimal.
        // We should create new sub objects for each nested object instead.
        _.set(newItem, name, value);
        return newItem;
      });
    },
    [onUpdateInput, setInput]
  );

  const lastInputRef = React.useRef(input);
  lastInputRef.current = input;

  const updateInput = useCallback(
    (
      model: ModelDefinition<ObjectType>,
      name: string[],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      updatedModels: Set<ModelDefinition<ObjectType>> = new Set()
    ) => {
      _onUpdateInput(name, value);

      if (model.onDidUpdate != null) {
        updatedModels.add(model);
        let otherValues = model.onDidUpdate(
          name,
          value as never,
          lastInputRef.current
        );

        for (let [key, otherValue] of otherValues ?? []) {
          const keyPath = getPathFromModelKeyFunc(key);
          const keyPathString = keyPath.join('.');
          const otherModel = _.find(
            allModels,
            (potentialModel) =>
              getPathStringFromModelKeyFunc(potentialModel.key) ===
              keyPathString
          );
          if (otherModel != null && !updatedModels.has(otherModel)) {
            if (model.preventRecursiveOnDidUpdate) {
              _onUpdateInput(keyPath, otherValue);
            } else {
              updateInput(otherModel, keyPath, otherValue, updatedModels);
            }
          }
        }
      }
    },
    [_onUpdateInput, allModels]
  );

  const updateModelInput = useCallback(
    (model: ModelDefinition<ObjectType>) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (...data: any[]) => {
        // TODO: Investigate if we can avoid spread operator
        // @ts-ignore-next-line
        updateInput(model, ...data);
      },
    [updateInput]
  );

  const modelsMap: Record<
    string,
    ModelMapItem<ObjectType, EnvironmentType>
  > = useMemo(() => {
    return _.reduce(
      allModels,
      (modelMap, model, index) => {
        modelMap[getPathStringFromModelKeyFunc(model.key)] = {
          key: index + '',
          model,
          updateItem: updateModelInput(model),
          isLoading,
          isHorizontal: horizontalModels
        };

        return modelMap;
      },
      {} as Record<string, ModelMapItem<ObjectType>>
    );
  }, [updateModelInput, horizontalModels, isLoading, allModels]);

  const renderChildModel = useCallback(
    (keyPath: (input: ObjectType) => React.ReactNode) => {
      // Some duplication of code in ModelEditor, but we want to avoid even instantiating the ModelEditor
      // component if visible is set to false
      const keyPathString = getPathStringFromModelKeyFunc(keyPath);
      const modelProps: ModelMapItem<ObjectType> = _.get(
        modelsMap,
        keyPathString
      );

      if (modelProps?.model == null) {
        console.error('Could not find model for key path', keyPathString);
        return null;
      }

      const model = modelProps.model;

      if (
        !evaluateModelFormRootProperty(
          model.visible,
          input,
          environment,
          true,
          model
        )
      ) {
        return null;
      }

      return (
        modelProps && (
          <ModelEditor
            {...modelProps}
            input={input}
            environment={environment}
            disableErrors={disableErrors}
            useTooltipHelpTexts={useTooltipHelpTexts}
            disabled={disabled}
          />
        )
      );
    },
    [
      modelsMap,
      input,
      environment,
      disableErrors,
      useTooltipHelpTexts,
      disabled
    ]
  );

  const orderedSections = useMemo(() => {
    return _(sections)
      .orderBy((section) => section.listPriority ?? 0, ['desc'])
      .map((section) => ({
        ...section,
        hasError: !modelFormSectionsAreValid([section], input, environment)
      }))
      .filter((section) => section.visible == null || section.visible(input))
      .value();
  }, [sections, input, environment]);

  let content;

  if (_.isFunction(children)) {
    content = children(renderChildModel);
  } else if (children != null) {
    content = children;
  } else if (sections?.length > 0) {
    validateSections(sections, modelsMap);

    switch (sectionStyle) {
      case ModelFormSectionStyle.SEGMENT_CONTROL:
        content = renderSectionSegmentControl(
          orderedSections,
          renderChildModel,
          sectionClassName,
          input
        );
        break;
      case ModelFormSectionStyle.SELECT_CONTROL:
        content = renderSectionSelectControl(
          orderedSections,
          renderChildModel,
          sectionsTitle,
          sectionClassName,
          input
        );
        break;
      case ModelFormSectionStyle.SECTION_LIST:
      default:
        content = renderSectionList(
          orderedSections,
          renderChildModel,
          sectionClassName,
          input
        );
        break;
    }
  } else {
    content = renderModelsMap(
      modelsMap,
      input,
      environment,
      disableErrors,
      useTooltipHelpTexts,
      disabled
    );
  }

  return <Fragment>{content}</Fragment>;
};

ModelForm.defaultProps = {
  environment: {}
};

export default typedMemo(ModelForm);
