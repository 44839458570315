import React from 'react';
import SelectDashboardCollectionRelationModal, {
  SelectDashboardCollectionRelationModalProps
} from './SelectDashboardCollectionRelationModal';
import T from 'ecto-common/lib/lang/Language';
import { DashboardCollectionResponseModel } from 'ecto-common/lib/API/APIGen';

type SelectDashboardCollectionForCreateNodeDialogProps =
  SelectDashboardCollectionRelationModalProps & {
    dashboardCollection: DashboardCollectionResponseModel;
    onModalClose: () => void;
    dashboardCollectionId?: string;
    onSelectedCollection?(collection: DashboardCollectionResponseModel): void;
  };

const SelectDashboardCollectionForCreateNodeDialog = ({
  dashboardCollection,
  onSelectedCollection,
  onModalClose,
  ...otherProps
}: SelectDashboardCollectionForCreateNodeDialogProps) => {
  return (
    <SelectDashboardCollectionRelationModal
      getRelationsArgs={null}
      collection={dashboardCollection}
      onSelectDone={onSelectedCollection}
      onModalClose={onModalClose}
      isLoading={false}
      actionText={T.common.done}
      {...otherProps}
    />
  );
};

export default React.memo(SelectDashboardCollectionForCreateNodeDialog);
