import React, { useState } from 'react';
import T from 'ecto-common/lib/lang/Language';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import NotificationTable from './NotificationTable';
import HelpPaths from 'ecto-common/help/tocKeys';

const Notifications = () => {
  const [searchString, setSearchString] = useState<string>(null);
  const [dialogVisible, showDialog, , setDialogVisible] = useDialogState(false);

  return (
    <ToolbarContentPage
      showLocationPicker={false}
      wrapContent={false}
      title={T.admin.notifications.title}
      onSearchInput={setSearchString}
      searchString={searchString}
      addAction={showDialog}
      addActionTitle={T.admin.notifications.addnotification}
      helpPath={HelpPaths.docs.admin.manage.notifications}
    >
      <NotificationTable
        onShowAddNotification={setDialogVisible}
        shouldShowAddNotification={dialogVisible}
        searchString={searchString}
      />
    </ToolbarContentPage>
  );
};

export default Notifications;
