import React, { useCallback } from 'react';
import _ from 'lodash';
import {
  CustomModelDefinition,
  ModelDefinition
} from 'ecto-common/lib/ModelForm/ModelPropType';
import { modelRawDataValue } from 'ecto-common/lib/ModelForm/modelValueUtil';
import styles from './ModelEditor.module.css';
import { typedMemo } from 'ecto-common/lib/utils/typescriptUtils';
import { getPathFromModelKeyFunc } from './formUtils';
import KeyValueErrorNotice from 'ecto-common/lib/KeyValueInput/KeyValueErrorNotice';
import { renderModelFormEditor } from 'ecto-common/lib/ModelForm/Plugins';

export function evaluateModelFormProperty<
  ObjectType extends object,
  EnvironmentType extends object = object
>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parameter: any,
  value: unknown,
  input: ObjectType,
  environment: EnvironmentType,
  defaultValue: unknown,
  model: ModelDefinition<ObjectType, EnvironmentType>
) {
  if (parameter == null) {
    return defaultValue;
  }

  if (_.isFunction(parameter)) {
    return parameter(value, input, environment, model);
  }

  return parameter;
}

export function evaluateModelFormRootProperty<
  ObjectType extends object,
  EnvironmentType extends object = object
>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  parameter: any,
  input: ObjectType,
  environment: EnvironmentType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue: any,
  model: ModelDefinition<ObjectType, EnvironmentType>
) {
  if (parameter == null) {
    return defaultValue;
  }

  if (_.isFunction(parameter)) {
    return parameter(input, environment, model);
  }

  return parameter;
}
interface InternalModelEditorProps<
  ObjectType extends object,
  EnvironmentType extends object
> {
  model: ModelDefinition<ObjectType, EnvironmentType>;
  isLoading?: boolean;
  updateItem?(key: string[], value: unknown): void;
  isHorizontal?: boolean;
  input: ObjectType;
  environment?: EnvironmentType;
  disableErrors?: boolean;
  useTooltipHelpTexts: boolean;
  disabled: boolean;
}

const ModelEditor = <
  ObjectType extends object,
  EnvironmentType extends object
>({
  model,
  isLoading,
  updateItem,
  isHorizontal,
  input,
  disableErrors,
  environment,
  useTooltipHelpTexts,
  disabled: disabledEditor
}: InternalModelEditorProps<ObjectType, EnvironmentType>) => {
  const rawValue = modelRawDataValue(model, input);

  const _updateItem = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any) => updateItem(getPathFromModelKeyFunc(model.key), value),
    [model.key, updateItem]
  );

  if (
    !evaluateModelFormRootProperty(
      model.visible,
      input,
      environment,
      true,
      model
    )
  ) {
    return null;
  }

  const errorText = evaluateModelFormProperty(
    model.errorText,
    rawValue,
    input,
    environment,
    null,
    model
  );
  const helpText = evaluateModelFormProperty(
    model.helpText,
    rawValue,
    input,
    environment,
    null,
    model
  );
  const shouldBeVisible = evaluateModelFormRootProperty(
    model.visible,
    input,
    environment,
    true,
    model
  );
  const shouldBeEnabled = evaluateModelFormRootProperty(
    model.enabled,
    input,
    environment,
    true,
    model
  );
  const modelIsLoading = evaluateModelFormProperty(
    model.isLoading,
    rawValue,
    input,
    environment,
    false,
    model
  );
  const hasError =
    !disableErrors &&
    (evaluateModelFormProperty(
      model.hasError,
      rawValue,
      input,
      environment,
      false,
      model
    ) ||
      errorText != null);

  const disabled = !shouldBeEnabled || isLoading || disabledEditor;

  if (!shouldBeVisible) {
    return null;
  }

  let defaultProps: ModelEditorProps<EnvironmentType> = {
    isLoading,
    updateItem: _updateItem,
    isHorizontal: isHorizontal ?? model.isHorizontal,
    disabled,
    helpText,
    useTooltipHelpTexts:
      model.useTooltipHelpTexts === undefined
        ? useTooltipHelpTexts
        : model.useTooltipHelpTexts,
    modelIsLoading,
    hasError,
    rawValue,
    environment
  };

  let editor: React.ReactNode = renderModelFormEditor<
    ObjectType,
    EnvironmentType
  >(model, defaultProps, rawValue, input, environment);

  return (
    <div className={styles.editorContainer}>
      {editor}
      {errorText && <KeyValueErrorNotice>{errorText}</KeyValueErrorNotice>}
    </div>
  );
};

export type ModelEditorProps<
  EnvironmentType extends object = object,
  ValueType = unknown
> = {
  isLoading: boolean;
  disabled: boolean;
  helpText: string;
  useTooltipHelpTexts: boolean;
  modelIsLoading: boolean;
  hasError: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rawValue: any;
  updateItem: (item: ValueType) => void;
  environment: EnvironmentType;
  isHorizontal: boolean;
};

export type CustomModelEditorProps<
  ObjectType extends object = object,
  EnvironmentType extends object = object,
  ValueType = unknown
> = ModelEditorProps<EnvironmentType, ValueType> & {
  model: CustomModelDefinition<ObjectType, EnvironmentType, ValueType>;
};

export default typedMemo(ModelEditor);
