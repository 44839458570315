import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import Icons from 'ecto-common/lib/Icons/Icons';

import Modal from 'ecto-common/lib/Modal/Modal';
import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';
import T from 'ecto-common/lib/lang/Language';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';

import SelectSignalTypesTable from 'js/components/SelectSignalsFromNodeTableDialog/SelectSignalTypesTable';
import styles from './SelectSignalsDialog.module.css';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import API from 'ecto-common/lib/API/API';

export const SelectSignalsFromNodeSignalType = {
  NOTIFICATION_ALARM_SIGNALS: 'NotificationAlarmSignals'
} as const;

const getSignalsPromise = (type: string) => {
  switch (type) {
    case SelectSignalsFromNodeSignalType.NOTIFICATION_ALARM_SIGNALS:
    default:
      return API.Admin.Notifications.getSignalTypeIds;
  }
};

export interface ItemWithSignalTypeId {
  signalTypeId: string;
}

interface SelectSignalTypesFromNodeTableDialogProps {
  nodeId?: string;
  isOpen?: boolean;
  onModalClose: () => void;
  onSignalsSelected?(signalTypeIds: string[]): void;
  selectedSignals?: ItemWithSignalTypeId[];
  type: string;
}

type ObjectWithSignalTypeId = {
  signalTypeId: string;
};

const SelectSignalTypesFromNodeTableDialog = ({
  nodeId,
  type,
  onModalClose,
  isOpen,
  onSignalsSelected,
  selectedSignals
}: SelectSignalTypesFromNodeTableDialogProps) => {
  const [tempSelectedSignalTypeIds, setTempSelectedSignalTypeIds] =
    useState<ObjectWithSignalTypeId[]>(selectedSignals);

  useEffect(() => {
    setTempSelectedSignalTypeIds(selectedSignals);
  }, [selectedSignals, isOpen]);

  const [signalTypeIds, setSignalTypeIds] = useState<ItemWithSignalTypeId[]>(
    []
  );

  const [isLoadingSignals, loadSignals] = usePromiseCall({
    promise: getSignalsPromise(type),
    onSuccess: (_signals) => {
      setSignalTypeIds(
        _.map(_signals.signalTypeIds, (signalTypeId) => ({ signalTypeId }))
      );
    },
    onError: () => {}
  });

  useEffect(() => {
    loadSignals(nodeId);
  }, [nodeId, loadSignals]);

  const selectDone = useCallback(() => {
    onSignalsSelected(
      tempSelectedSignalTypeIds.map((signal) => signal.signalTypeId)
    );
    onModalClose();
  }, [onModalClose, onSignalsSelected, tempSelectedSignalTypeIds]);

  return (
    <Modal
      onModalClose={onModalClose}
      isOpen={isOpen}
      className={styles.selectSignalsDialog}
    >
      <ModalHeader titleIcon={Icons.Signal}>
        {T.admin.selectsignalsdialog.title}
      </ModalHeader>
      <ModalBody withWhiteBackground withoutPadding>
        <div>
          <SelectSignalTypesTable
            isLoading={isLoadingSignals}
            data={signalTypeIds}
            selectedData={tempSelectedSignalTypeIds}
            onMarkedData={setTempSelectedSignalTypeIds}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <LocalizedButtons.Ok onClick={selectDone} />
        <LocalizedButtons.Cancel onClick={onModalClose} />
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(SelectSignalTypesFromNodeTableDialog);
