import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import T from 'ecto-common/lib/lang/Language';
import {
  ConfigurationFormat,
  ConfigurationValue,
  IntegrationProxyResponse,
  IntegrationProxyResponseConfiguration
} from 'ecto-common/lib/API/IntegrationAPIGen';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import ModelFormDialog from 'ecto-common/lib/ModelForm/ModelFormDialog';
import IntegrationAdminAPIGen, {
  IntegrationPointCreateRequest,
  IntegrationPointResponse
} from 'ecto-common/lib/API/IntegrationAdminAPIGen';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import { IntegrationPointUpdateRequest } from 'ecto-common/lib/API/IntegrationAdminAPIGen';

const getIntegrationModels = (
  nodeIdsEnabled: boolean
): ModelDefinition<IntegrationPointCreateRequest>[] => [
  {
    modelType: ModelType.TEXT,
    key: (input) => input.name,
    label: T.admin.integrations.proxy.form.name,
    autoFocus: true,
    hasError: isNullOrWhitespace
  },
  {
    key: (input) => input.nodeIds,
    enabled: nodeIdsEnabled,
    label: T.admin.integrations.proxy.form.nodeids,
    modelType: ModelType.NODE_LIST
  }
];

// Create placeholder objects that hold one of float/int/stringValue
export const configurationObjectWithConfiguration = (
  configuration?: IntegrationProxyResponseConfiguration[]
): Record<string, ConfigurationValue> => {
  return _.reduce(
    configuration,
    (collection, value) => {
      return {
        ...collection,
        [value.code]: {}
      };
    },
    {}
  );
};

// We have placeholder objects for all parameters, but some are optional and thus do not set any values. Remove these optional
// empty objects from the final create request
interface ObjectWithConfiguration {
  configuration?: Record<string, ConfigurationValue>;
}

function trimConfigurationObjects<T extends ObjectWithConfiguration>(
  data: T
): T {
  const ret = _.cloneDeep(data);

  return {
    ...ret,
    configuration: _.pickBy(ret.configuration, (value) => {
      return !_.isEmpty(value);
    })
  };
}

export const integrationModelsWithConfiguration = (
  configuration?: IntegrationProxyResponseConfiguration[],
  nodeIdsEnabled = true
): ModelDefinition<IntegrationPointCreateRequest>[] => {
  let ret = [...getIntegrationModels(nodeIdsEnabled)];

  if (configuration != null) {
    ret = [
      ...ret,
      ...configuration.map((config) => {
        const sharedProps = {
          label: config.name,
          errorText: (value: string) => {
            if (value == null && config.required) {
              return T.admin.integrations.proxy.form.error.missing;
            }

            if (
              config.format === ConfigurationFormat.String &&
              isNullOrWhitespace(value) &&
              config.required
            ) {
              return T.admin.integrations.proxy.form.error.missing;
            }

            if (
              config.regexFormat != null &&
              value != null &&
              config.format === ConfigurationFormat.String
            ) {
              const regex = new RegExp(config.regexFormat, 'g');
              const stringValue = value as string;
              if (stringValue.match(regex) == null) {
                return T.format(
                  T.admin.integrations.proxy.form.error.invalidformat,
                  config.regexFormat
                );
              }
            }

            return null;
          }
        };

        switch (config.format) {
          case ConfigurationFormat.String:
            return {
              modelType: ModelType.TEXT,
              key: (input: IntegrationPointCreateRequest) =>
                input.configuration[config.code].stringValue,
              ...sharedProps
            };
          case ConfigurationFormat.Integer:
            return {
              modelType: ModelType.NUMBER,
              key: (input: IntegrationPointCreateRequest) =>
                input.configuration[config.code].integerValue,
              ...sharedProps
            };
          default:
          case ConfigurationFormat.Float:
            return {
              modelType: ModelType.NUMBER,
              key: (input: IntegrationPointCreateRequest) =>
                input.configuration[config.code].floatValue,
              ...sharedProps
            };
        }
      })
    ];
  }

  return ret;
};

export const useAddOrEditIntegrationPointModal = (
  integrationProxy: IntegrationProxyResponse,
  nodeId: string = null,
  editPoint: IntegrationPointResponse = null,
  onEditCancelled: () => void = null
) => {
  const [currentPoint, setCurrentPoint] =
    useState<IntegrationPointCreateRequest>(null);
  const queryClient = useQueryClient();

  const [prevEditPoint, setPrevEditPoint] = useState(editPoint);

  if (prevEditPoint !== editPoint) {
    setPrevEditPoint(editPoint);
    setCurrentPoint(editPoint);
  }

  const models = useMemo(() => {
    return integrationModelsWithConfiguration(
      integrationProxy?.configuration,
      nodeId == null
    );
  }, [integrationProxy, nodeId]);

  const onClearPoint = useCallback(() => {
    onEditCancelled?.();
    setCurrentPoint(null);
  }, [onEditCancelled]);

  const api = IntegrationAdminAPIGen.IntegrationPoints.addIntegrationPoint;
  const onPointChanged = (wasAdded: boolean, name: string) => {
    toastStore.addSuccessToastForUpdatedItem(name, wasAdded);
    onClearPoint();
    queryClient.invalidateQueries();
  };

  const { mutate: addIntegrationPoint, isLoading: isAddingIntegrationPoint } =
    api.useMutation(
      {
        await: true
      },
      {
        onError: (_unused, req) => {
          toastStore.addErrorToastForUpdatedItem(req.name, true);
        },
        onSuccess: (_unused, req) => {
          onPointChanged(true, req.name);
        }
      }
    );

  const { mutate: editIntegrationPoint, isLoading: isEditingIntegrationPoint } =
    IntegrationAdminAPIGen.IntegrationPoints.putIntegrationPoint.useMutation(
      {
        pointId: currentPoint?.id
      },
      {
        await: true
      },
      {
        onError: (_unused, req) => {
          toastStore.addErrorToastForUpdatedItem(req.name, false);
        },
        onSuccess: (_unused, req) => {
          onPointChanged(true, req.name);
        }
      }
    );

  const onAddIntegrationPoint = useCallback(() => {
    setCurrentPoint({
      integrationProxyId: integrationProxy?.id,
      name: '',
      nodeIds: nodeId != null ? [nodeId] : [],
      configuration: configurationObjectWithConfiguration(
        integrationProxy?.configuration
      ),
      id: null
    });
  }, [integrationProxy, nodeId]);

  const _addIntegrationPoint = (inputToSave: IntegrationPointCreateRequest) => {
    addIntegrationPoint(
      trimConfigurationObjects<IntegrationPointCreateRequest>(inputToSave)
    );
  };
  const _editIntegrationPoint = (
    inputToSave: IntegrationPointUpdateRequest
  ) => {
    editIntegrationPoint(
      trimConfigurationObjects<IntegrationPointUpdateRequest>(inputToSave)
    );
  };

  const isEditing = currentPoint?.id != null;

  return {
    onAddIntegrationPoint,
    addIntegrationModelComponent: (
      <ModelFormDialog
        saveAsArray={false}
        editTitle={T.format(
          T.admin.integrations.editpointmodalformat,
          integrationProxy?.name
        )}
        addTitle={T.format(
          T.admin.integrations.addpointmodalformat,
          integrationProxy?.name
        )}
        isSavingInput={isAddingIntegrationPoint}
        onModalClose={onClearPoint}
        input={currentPoint}
        isLoading={isAddingIntegrationPoint || isEditingIntegrationPoint}
        models={models}
        saveInput={isEditing ? _editIntegrationPoint : _addIntegrationPoint}
      />
    )
  };
};
