import { useCallback, useEffect, useState } from 'react';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import IdentityServiceAPI from 'ecto-common/lib/utils/IdentityServiceAPI';
import { TenantUserModel } from 'ecto-common/lib/API/IdentityServiceAPIGen';

export const useAdminUsers = ({ initiallyLoading = true } = {}): [
  TenantUserModel[],
  boolean,
  () => void,
  () => void
] => {
  const [users, setUsers] = useState<TenantUserModel[]>([]);

  const [isLoadingUsers, loadUsers, cancelLoadUsers] = usePromiseCall({
    promise: IdentityServiceAPI.TenantUsers.getTenantUsers,
    onSuccess: (_users) => {
      setUsers(_users.tenantUsers);
    },
    onError: () => {
      toastStore.addErrorToast(T.common.baserequesterror);
    }
  });

  /**
   * Currently all the tenants use the default tenant database for nodes and so forth.
   * In the future we will have per-tenant databases, but for now, do all node editing as
   * if the admin was in the default tenant regardless of the actual tenant it is in.
   */
  useEffect(() => {
    if (initiallyLoading) {
      loadUsers(null);
    }
  }, [loadUsers, initiallyLoading]);

  const _loadUsers = useCallback(() => {
    loadUsers(null);
  }, [loadUsers]);

  return [users, isLoadingUsers, _loadUsers, cancelLoadUsers];
};
