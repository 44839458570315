import React, {
  memo,
  useMemo,
  useCallback,
  useEffect,
  useState,
  useRef
} from 'react';
import _ from 'lodash';
import UUID from 'uuidjs';

import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import Icons from 'ecto-common/lib/Icons/Icons';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import T from 'ecto-common/lib/lang/Language';
import DeleteButton from 'ecto-common/lib/Button/DeleteButton';
import useFormState from 'ecto-common/lib/hooks/useFormState';
import {
  calculateDataTableMinHeight,
  checkboxColumn,
  standardColumns,
  truncateColumns
} from 'ecto-common/lib/utils/dataTableUtils';

import {
  AddOrUpdateDashboardCollectionWithDashboardsRequestModel,
  DashboardCollectionForm,
  DashboardFormAndTableEntry,
  initialFormState
} from 'js/components/DashboardCollections/DashboardCollectionForm';
import DashboardCollectionsDeleteModal from 'js/components/DashboardCollections/DashboardCollectionsDeleteModal';
import DashboardCollectionSelectionListModal from 'js/components/DashboardCollections/DashboardCollectionSelectionListModal';

import { DashboardFormColumns } from 'js/components/Dashboards/DashboardColumns';
import DataTable from 'ecto-common/lib/DataTable/DataTable';
import {
  DashboardCollectionResponseModel,
  DashboardResponseModel
} from 'ecto-common/lib/API/APIGen';
import { evaluateModelFormProperty } from 'ecto-common/lib/ModelForm/ModelEditor';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import APIGen from 'ecto-common/lib/API/APIGen';

interface DashboardCollectionFormModalProps {
  currentItem?: DashboardCollectionResponseModel;
  onModalClose: () => void;
  isOpen?: boolean;
  onSuccess?(): void;
}

// TODO: Refactor to use generic form validation that doesn't rely on our ugly DashboardFormAndTableEntry type
const hasValidDashboardCollectionFormValues = (
  input: AddOrUpdateDashboardCollectionWithDashboardsRequestModel,
  models: DashboardFormAndTableEntry[],
  environment = {}
) => {
  for (const model of models) {
    if (
      evaluateModelFormProperty(
        model.hasError,
        _.get(input, model.dataKey),
        input,
        environment,
        false,
        // TODO: Remove ugly cast
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        { ...model, key: model.key } as ModelDefinition<any>
      )
    ) {
      return false;
    }
  }
  return true;
};

const DashboardCollectionFormModal = ({
  currentItem,
  onModalClose,
  isOpen,
  onSuccess
}: DashboardCollectionFormModalProps) => {
  // TODO: Type mismatch with dashboardIds here:
  const [formState, onChange, resetForm] =
    useFormState<AddOrUpdateDashboardCollectionWithDashboardsRequestModel>(
      currentItem as AddOrUpdateDashboardCollectionWithDashboardsRequestModel,
      initialFormState
    );
  const [deleteItem, setDeleteItem] =
    useState<DashboardCollectionResponseModel>(null);
  const isValid =
    hasValidDashboardCollectionFormValues(formState, DashboardCollectionForm) &&
    !_.isEqual(currentItem, formState);
  const isEditing = formState?.dashboardCollectionId != null;
  const title = isEditing
    ? T.admin.dashboardcollection.edit
    : T.admin.dashboardcollection.add;
  const actionText = isEditing ? T.common.save : T.common.add;
  const dashboardCollectionId = useRef(UUID.generate());

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen, resetForm]);

  useEffect(() => {
    if (!isOpen) {
      dashboardCollectionId.current = null;
    }
  }, [isOpen]);

  const _onSuccess = useCallback(() => {
    onModalClose();
    onSuccess?.();
  }, [onModalClose, onSuccess]);

  const addOrUpdateCollectionMutation =
    APIGen.AdminDashboard.addOrUpdateDashboardCollections.useMutation({
      onSuccess: () => {
        _onSuccess();
        toastStore.addSuccessToast(
          isEditing
            ? T.admin.dashboardcollection.editsuccess
            : T.admin.dashboardcollection.addsuccess
        );
      },
      onError: () => {
        toastStore.addErrorToast(
          isEditing
            ? T.admin.dashboardcollection.editfailure
            : T.admin.dashboardcollection.addfailure
        );
      }
    });

  const onConfirmClick = useCallback(() => {
    if (!isEditing && !dashboardCollectionId.current) {
      dashboardCollectionId.current = UUID.generate();
    }

    const _formState: AddOrUpdateDashboardCollectionWithDashboardsRequestModel =
      { ...formState };
    _formState.dashboardCollectionId =
      _formState.dashboardCollectionId ?? dashboardCollectionId.current;
    _formState.dashboardIds = _.map(formState.dashboards, 'dashboardId');

    if (_formState.dashboardIds.length <= 0 && _formState.defaultDashboardId) {
      _formState.defaultDashboardId = null;
    }

    addOrUpdateCollectionMutation.mutate([_formState]);
  }, [formState, addOrUpdateCollectionMutation, isEditing]);

  const showConfirmDelete = useCallback(
    () => setDeleteItem(currentItem),
    [currentItem]
  );

  const addDashboards = useCallback(
    (dashboards: DashboardResponseModel[]) => {
      onChange({ dataKey: ['dashboards'], value: dashboards });
    },
    [onChange]
  );

  const toggleDefaultDashboard = useCallback(
    (item: DashboardResponseModel) => {
      const _dashboardId = item?.dashboardId;
      const value =
        formState.defaultDashboardId !== _dashboardId ? _dashboardId : null;
      onChange({ dataKey: ['defaultDashboardId'], value });
    },
    [formState.defaultDashboardId, onChange]
  );

  const removeSelectedDashboard = useCallback(
    (item: DashboardResponseModel) => {
      const newDashboards = _.filter(formState.dashboards, (dashboard) => {
        return dashboard.dashboardId !== item.dashboardId;
      });

      onChange({ dataKey: ['dashboards'], value: newDashboards });

      if (
        !_.find(
          newDashboards,
          (newDashboard) =>
            newDashboard.dashboardId === formState.defaultDashboardId
        )
      ) {
        onChange({ dataKey: ['defaultDashboardId'], value: null });
      }
    },
    [formState, onChange]
  );

  const columns = useMemo(
    () => [
      checkboxColumn({
        label: T.admin.dashboardcollection.defaultdashboard,
        rowOnChange: toggleDefaultDashboard,
        rowIsChecked: (item: DashboardResponseModel) =>
          formState.defaultDashboardId === item?.dashboardId,
        width: 72
      }),
      ...truncateColumns(DashboardFormColumns),
      ...standardColumns({
        onDelete: removeSelectedDashboard
      })
    ],
    [
      formState.defaultDashboardId,
      toggleDefaultDashboard,
      removeSelectedDashboard
    ]
  );

  return (
    <>
      <ActionModal
        title={title}
        actionText={actionText}
        onModalClose={onModalClose}
        disableActionButton={!isValid}
        isOpen={isOpen}
        onConfirmClick={onConfirmClick}
        isLoading={addOrUpdateCollectionMutation.isLoading}
        headerIcon={isEditing ? Icons.Edit : Icons.Add}
        leftSideButton={
          isEditing && (
            <DeleteButton
              disabled={addOrUpdateCollectionMutation.isLoading}
              onClick={showConfirmDelete}
            >
              {T.common.delete}
            </DeleteButton>
          )
        }
      >
        <>
          {_.map(DashboardCollectionForm, (input, key) => {
            if (input.label) {
              return (
                input.label && (
                  <KeyValueInput
                    key={key}
                    keyText={input.label}
                    value={_.get(formState, input.dataKey)}
                    onChange={(event) =>
                      onChange({ event, dataKey: [input.dataKey] })
                    }
                  />
                )
              );
            }
          })}

          <DataTable<DashboardResponseModel>
            columns={columns}
            data={formState.dashboards}
            noDataText={T.admin.dashboardcollection.noselecteddashboards}
            minHeight={calculateDataTableMinHeight({
              pageSize: 4,
              withButtons: true
            })}
          />

          <DashboardCollectionSelectionListModal
            onConfirm={addDashboards}
            dashboards={formState.dashboards}
          />
        </>
      </ActionModal>

      <DashboardCollectionsDeleteModal
        deleteItem={deleteItem}
        onModalClose={() => setDeleteItem(null)}
        onSuccess={_onSuccess}
      />
    </>
  );
};

export default memo(DashboardCollectionFormModal);
