import React, { useMemo } from 'react';
import { render } from 'react-dom';
import localStore from 'store';
import {
  IPublicClientApplication,
  PublicClientApplication
} from '@azure/msal-browser';

import {
  NODE_IDS_STORAGE_ADMIN,
  NODE_IDS_STORAGE_OPERATOR,
  NODES_STORAGE_ADMIN,
  NODES_STORAGE_OPERATOR
} from 'ecto-common/lib/utils/persistentNodeState';
import Root from 'ecto-common/lib/Root/Root';
import UserContainer from 'ecto-common/lib/Application/UserContainer';
import _ from 'lodash';
import * as moment from 'moment';
import * as momentTimezone from 'moment-timezone';
import UrlContext, {
  UrlContextInformation
} from 'ecto-common/lib/hooks/UrlContext';
import { useQuery } from '@tanstack/react-query';
import {
  hasSelectedAPIEnvironment,
  setApiEnvironment
} from 'ecto-common/lib/utils/apiEnvironment';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import createAuthenticationConfiguration from 'ecto-common/lib/utils/createAuthenticationConfiguration';
import DebugLogin from 'ecto-common/lib/DebugLogin/DebugLogin';
import { getCurrentApiEnvironmentDefinition } from '../utils/apiEnvironment';
import T from 'ecto-common/lib/lang/Language';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { ApiEnvironmentConfig } from 'ecto-common/lib/API/ApiConfigType';

// Import and inject moment timezone into moment object.
_.noop(moment);
_.noop(momentTimezone);

type AppContainerProps = {
  store: ToolkitStore;
  urlData: UrlContextInformation;
  MainContainer: React.FC;
};

const queryClient = new QueryClient();

const EnvironmentPickerWrapper = ({
  store,
  urlData,
  MainContainer
}: AppContainerProps) => {
  if (process.env.DEV_BUILD && !hasSelectedAPIEnvironment()) {
    return <DebugLogin />;
  }

  return (
    <AppContainer
      store={store}
      MainContainer={MainContainer}
      urlData={urlData}
    />
  );
};

const AppContainer = ({ store, urlData, MainContainer }: AppContainerProps) => {
  const definition = getCurrentApiEnvironmentDefinition();

  const envQuery = useQuery(
    ['environment', definition.filename],
    async () => {
      return fetch('/' + definition.filename).then((r) => {
        if (r.status !== 200) {
          throw new Error();
        }
        return r.json() as unknown as ApiEnvironmentConfig;
      });
    },
    { retry: 1, refetchOnWindowFocus: false }
  );

  const msalConfiguration: IPublicClientApplication = useMemo(() => {
    if (envQuery.data == null) {
      return null;
    }

    const configuration = createAuthenticationConfiguration(
      envQuery.data.authority,
      envQuery.data.clientId,
      envQuery.data.knownAuthorities
    );
    return new PublicClientApplication(configuration);
  }, [envQuery.data]);

  if (!envQuery.data) {
    if (envQuery.error) {
      return (
        <div>
          <h1>{T.common.environmenterror} </h1>
        </div>
      );
    }
    return null;
  }

  setApiEnvironment(envQuery.data);

  return (
    <Root store={store} configuration={msalConfiguration}>
      <UrlContext.Provider value={urlData}>
        <UserContainer msalConfiguration={msalConfiguration}>
          <MainContainer />
        </UserContainer>
      </UrlContext.Provider>
    </Root>
  );
};

export default (
  store: ToolkitStore,
  MainContainer: React.FC,
  urlData: UrlContextInformation
) => {
  // Clear out old local store keys. Non-prefixed variants of these are deprecated, they were
  // used when we did not have tenant support.
  localStore.remove(NODES_STORAGE_OPERATOR);
  localStore.remove(NODE_IDS_STORAGE_OPERATOR);
  localStore.remove(NODES_STORAGE_ADMIN);
  localStore.remove(NODE_IDS_STORAGE_ADMIN);

  function handleFirstTab(e: KeyboardEvent) {
    if (e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing');

      window.removeEventListener('keydown', handleFirstTab);
      window.addEventListener('mousedown', handleMouseDownOnce);
    }
  }

  function handleMouseDownOnce() {
    document.body.classList.remove('user-is-tabbing');

    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
  }

  window.addEventListener('keydown', handleFirstTab);

  window.onload = () => {
    render(
      <QueryClientProvider client={queryClient}>
        <EnvironmentPickerWrapper
          store={store}
          MainContainer={MainContainer}
          urlData={urlData}
        />
      </QueryClientProvider>,
      document.getElementById('main')
    );
  };
};
