import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';

import cssDurationToMilliseconds from 'ecto-common/lib/utils/cssDurationToMilliseconds';
import animations from 'ecto-common/lib/styles/variables/animations';
import Portal from 'ecto-common/lib/Portal/Portal';
import Transition from 'ecto-common/lib/Transition/Transition';
import { RemoveIcon } from 'ecto-common/lib/Icon/index';
import styles from 'ecto-common/lib/Modal/Modal.module.css';

const modalSpeed = cssDurationToMilliseconds(animations.modalSpeed);

const transitionStyles = {
  appear: styles.enter,
  appearActive: styles.enterActive,
  enter: styles.enter,
  enterActive: styles.enterActive,
  exit: styles.exit,
  exitActive: styles.exitActive
};

interface ModalProps {
  /**
   * The content of the Modal. Should be Modal content classes (ModalHeader, ModalBody, ModalFooter)
   */
  children?: React.ReactNode;
  /**
   * Whether the dialog is open. Use this instead of { isOpen && <Modal ...> } to get correct animations.
   */
  isOpen: boolean;
  /**
   * Called whenever the modal closes.
   */
  onModalClose(): void;
  /**
   * Used to override the appearance of the modal. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * Disables close button.
   */
  disableClose?: boolean;

  preventCloseDropdownMenu?: boolean;

  style?: React.CSSProperties;
}

/**
 * A Modal component renders its content in a window dialog that is centered on the screen.
 */
const Modal = ({
  children,
  isOpen,
  onModalClose,
  className,
  disableClose,
  preventCloseDropdownMenu,
  style = null
}: ModalProps) => {
  let modalRef = useRef(null);

  const onCloseClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (event) {
        event.stopPropagation();
      }

      if (!disableClose) {
        onModalClose();
      }
    },
    [disableClose, onModalClose]
  );

  return (
    <Portal isOpen={isOpen} closeTimeout={modalSpeed}>
      <Transition
        className={styles.modalContainer}
        classNames={transitionStyles}
        timeout={{
          appear: modalSpeed,
          enter: modalSpeed,
          exit: modalSpeed
        }}
      >
        {isOpen && (
          <div className={styles.modalBackdrop}>
            <div
              ref={modalRef}
              style={style}
              className={classNames(styles.modal, className)}
              data-disableclosedropdownmenuonclick={
                preventCloseDropdownMenu ? true : undefined
              }
            >
              <div className={styles.modalBackground} />

              <div
                className={classNames(
                  styles.closeButton,
                  disableClose && styles.disabled
                )}
                onClick={disableClose ? undefined : onCloseClick}
              >
                <RemoveIcon
                  className={styles.closeIcon}
                  height={24}
                  width={24}
                />
              </div>

              {children}
            </div>
          </div>
        )}
      </Transition>
    </Portal>
  );
};

export default Modal;
