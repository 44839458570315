import React, {
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import ErrorNotice from '../Notice/ErrorNotice';
import NoDataMessage from 'ecto-common/lib/NoDataMessage/NoDataMessage';
import LoadingContainer from '../LoadingContainer/LoadingContainer';
import { ASC, DESC, SortDirectionType } from './SortDirection';
import styles from './DataTable.module.css';
import T from '../lang/Language';
import {
  getSpinnerSizePixels,
  SpinnerSize
} from 'ecto-common/lib/Spinner/Spinner';
import DataTableRow from 'ecto-common/lib/DataTable/DataTableRow';
import DataTableHeaderRow from 'ecto-common/lib/DataTable/DataTableHeaderRow';
import { typedMemo } from '../utils/typescriptUtils';
import './DataTableAnimation.css';
import { Property } from 'csstype';
import DataTableSectionHeaderView from 'ecto-common/lib/DataTable/DataTableSectionHeaderView';

interface DataTableLoadMoreMarkerProps {
  onLoadMoreItems?(): void;
}

const DataTableLoadMoreMarker = ({
  onLoadMoreItems
}: DataTableLoadMoreMarkerProps) => {
  const loadMoreRef = useRef<HTMLDivElement>(null);

  const callback = useCallback(
    (e: IntersectionObserverEntry[]) => {
      if (_.head(e)?.isIntersecting) {
        onLoadMoreItems();
      }
    },
    [onLoadMoreItems]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(callback);
    const curRef = loadMoreRef.current;

    if (curRef != null) {
      observer.observe(curRef);
    }

    return () => {
      if (curRef != null) {
        observer.unobserve(curRef);
      }
    };
  }, [callback, loadMoreRef]);

  return <div ref={loadMoreRef} />;
};

const EmptyData: unknown = [];
export interface DataTableColumnProps<ObjectType> {
  width?: number | string; // CSS width
  label?: React.ReactNode; // Title of column
  tooltip?: React.ReactNode; // Additional info for the column, shown as a tooltip when hovering the table header
  dataKey: string; // JSON path of value, i.e dataKey = 'prop' retrieves value from object.prop
  maxWidth?: number | string; // Optional maximum width of the column
  minWidth?: number | string; // Optional minimum width of the column
  align?: Property.TextAlign; // Alignment within cell: left, center, right
  flexGrow?: number; // flexGrow CSS property of column
  flexShrink?: number; // flexShrink CSS property of column
  truncateText?: boolean; // If set to true then truncate text with ellipsis on overflow and show tooltip when hovering.
  canSort?: boolean; // Whether you can click on the header to change sort order
  linkColumn?: boolean | ((obj: ObjectType) => boolean); // If set to true, style the text and icons like a link. Can also be a function with the row data as first argument if you need to decide dynamically.
  dataFormatter?: (
    value: unknown,
    object: ObjectType,
    rowIndex: number,
    isHovering: boolean,
    rowColumn: DataTableColumnProps<ObjectType>
  ) => React.ReactNode; // Optional utility property. If set, apply dataFormatter on each cell value to get visual component.
}

export interface BaseDataTableProps<ObjectType> {
  /**
   * The columns to be used in the table.
   *
   * An array of objects with these properties:
   * {
   *   width,            // CSS width
   *   label,            // Title of column
   *   dataKey,          // JSON path of value, i.e dataKey = 'prop' retrieves value from object.prop
   *   maxWidth          // Optional maximum width of the column
   *   minWidth          // Optional minimum width of the column
   *   align             // Alignment within cell: left, center, right
   *   flexGrow,         // flexGrow CSS property of column
   *   flexShrink,       // flexShrink CSS property of column
   *   truncateText      // If set to true then truncate text with ellipsis on overflow and show tooltip when hovering.
   *   canSort,          // Whether or not you can click on the header to change sort order
   *   linkColumn,       // If set to true, style the text and icons like a link. Can also be a function with the row data as first argument if you need to decide dynamically.
   *   dataFormatter     // Optional utility property. If set, apply dataFormatter on each cell value to get visual component.
   * }
   */
  columns: DataTableColumnProps<ObjectType>[];
  /**
   * If set to true the table is blurred out and a spinner is shown.
   */
  isLoading?: boolean;
  /**
   * If set to true an error notice will be shown. Set errorText to customize text.
   */
  hasError?: boolean;
  /**
   * Called whenever the sorting order is changed. Called with (orderBy, sortDirection) arguments (based on which column is clicked)
   */
  onSortChange?: (orderBy: string, sortDirection: string) => void;
  /**
   * Used to override the appearance of the table. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * Used to display the DataTable in an inline format, suitable for multi-column layouts in dialogs etc (see signal picker). Removes rounded
   * corners, left/right borders and uses a slightly smaller font.
   */
  inline?: boolean;
  /**
   * Whether to hide the header.
   */
  disableHeader?: boolean;
  /**
   * Which column data key the data should be sorted on.
   */
  sortBy?: string;
  /**
   * Which sort direction the data should be sorted by.
   */
  sortDirection?: SortDirectionType;
  /**
   * Custom callback that handles row clicks.
   */
  onClickRow?: (object: ObjectType, row: number, column: number) => void;
  /**
   * Text to display if the data is empty.
   */
  noDataText?: React.ReactNode;
  /**
   * Custom error text to show if hasError is set to true.
   */
  errorText?: React.ReactNode;
  /**
   * If you want to highlight a specific row with a selected background color, set this index to the corresponding row index.
   */
  selectedIndex?: number;
  /**
   * Used to override the appearance of a selected row. Should be a valid CSS class name.
   */
  selectedIndexStyle?: string;
  /**
   * Minimum height for the table. The actual minimum height might be larger since we need to accommodate for various info
   * elements.
   */
  minHeight?: number;
  /**
   * Sets the vertical padding of the table.
   */
  verticalPadding?: number;
  /**
   * Whether to show a notice header above noDataText etc.
   */
  showNoticeHeaders?: boolean;
  /**
   * The size of the spinner that is displayed when loading
   */
  spinnerSize?: SpinnerSize; // TODO: Fix proper type
  /**
   * Whether the DataTable should try and expand to take as much height as is available.
   * Suitable for full screen DataTables.
   */
  useAllAvailableHeight?: boolean;

  /**
   * Set this to something else than -1 to limit the number of rows to draw. More rows will be
   * added incrementally when you scroll to the end of the table (in chunks of numRowsToShowPerScroll).
   * Useful when you have large tables with complex data that is expensive to draw.
   */
  numRowsToShowPerScroll?: number;

  /**
   * Since we use Typescript generics we cannot use forwardRef, unfortunately. Use this prop
   * to access the inner div element instead.
   */
  innerRef?: Ref<HTMLDivElement>;

  /**
   * If you want to automatically load more items once the user has scrolled to the end of the table,
   * you can do so by setting this callback. It will be called once the user has scrolled to the end.
   */
  onUserScrolledToEndOfTable?: () => void;
}

const _dataTableSectionId = 'dataTableSectionId';

export function DataTableSectionHeader(
  sectionTableHeader: React.ReactNode
): DataTableSectionHeaderType {
  const ret = {
    sectionTableHeader: sectionTableHeader,
    _dataTableSectionId
  } as const;

  return ret;
}

export type DataTableSectionHeaderType = {
  sectionTableHeader: React.ReactNode;
  _dataTableSectionId: typeof _dataTableSectionId;
};

export interface DataTableProps<ObjectType>
  extends BaseDataTableProps<ObjectType> {
  /**
   * The data for the rows in the table. Expects an array of objects with entries that match the dataKeys defined in columns.
   */
  data: Array<ObjectType | DataTableSectionHeaderType>;
}

/**
 * DataTable is our standard table component. Instead of using traditional table elements this classes uses divs with flexbox instead. This gives us greater control over the layout.
 *
 * The layout for each column is specified explicitly in the columns property. See the columns prop type comment for information regarding the column format.
 */
const DataTable = <ObjectType extends object>({
  className = null,
  inline = false,
  columns,
  data: inputData,
  disableHeader = false,
  errorText = null,
  hasError = false,
  isLoading = false,
  minHeight = 0,
  noDataText = null,
  onClickRow = null,
  onSortChange: _onSortChange,
  showNoticeHeaders = true,
  selectedIndex = null,
  selectedIndexStyle = null,
  sortBy = null,
  sortDirection = null,
  spinnerSize = SpinnerSize.MEDIUM,
  useAllAvailableHeight,
  verticalPadding = 0,
  numRowsToShowPerScroll = -1,
  innerRef = null,
  onUserScrolledToEndOfTable = null
}: DataTableProps<ObjectType>) => {
  const data = inputData ?? (EmptyData as Array<ObjectType>);
  const [numItemsToShow, setNumItemsToShow] = useState(numRowsToShowPerScroll);

  useEffect(() => {
    if (numRowsToShowPerScroll !== -1) {
      setNumItemsToShow(numRowsToShowPerScroll);
    }
  }, [data, numRowsToShowPerScroll]);

  const _data = useMemo(() => {
    if (numRowsToShowPerScroll !== -1) {
      return data.slice(0, numItemsToShow);
    }

    return data;
  }, [data, numItemsToShow, numRowsToShowPerScroll]);

  const loadMoreItems = useCallback(() => {
    onUserScrolledToEndOfTable?.();
    setNumItemsToShow((oldNumItemsToShow) => {
      return Math.min(oldNumItemsToShow + numRowsToShowPerScroll, data.length);
    });
  }, [numRowsToShowPerScroll, data, onUserScrolledToEndOfTable]);

  const showNoData = _.isEmpty(_data) && !isLoading && !hasError;

  const tableMinHeight = Math.max(minHeight, getSpinnerSizePixels(spinnerSize));
  const _noDataText: React.ReactNode = noDataText || T.common.datatable.nodata;
  const renderTable = !hasError && !_.isEmpty(_data);

  const onSortChange = useCallback(
    ({ sortBy: newSortBy }: { sortBy: string }) => {
      if (_onSortChange) {
        let newSortDirection;

        // Only change ASC/DESC
        if (newSortBy === sortBy) {
          newSortDirection = sortDirection === DESC ? ASC : DESC;
        } else {
          // Column changed, default to DESC
          newSortDirection = DESC;
        }

        _onSortChange(newSortBy, newSortDirection);
      }
    },
    [_onSortChange, sortBy, sortDirection]
  );

  return (
    <div
      className={classNames(
        styles.container,
        className,
        useAllAvailableHeight && styles.useAllAvailableHeight
      )}
      ref={innerRef}
    >
      <LoadingContainer
        isLoading={isLoading}
        style={{
          minHeight: tableMinHeight,
          flexGrow: useAllAvailableHeight ? 1 : null
        }}
      >
        {hasError && (
          <ErrorNotice showHeader={showNoticeHeaders}>
            {errorText || T.common.datatable.error}
          </ErrorNotice>
        )}
        {showNoData && (
          <NoDataMessage textOnly={!showNoticeHeaders} message={_noDataText} />
        )}
        {renderTable && (
          <div
            className={styles.flexTable}
            style={{ paddingTop: verticalPadding }}
          >
            {!disableHeader && (
              <DataTableHeaderRow<ObjectType>
                columns={columns}
                onSortChange={onSortChange}
                sortBy={sortBy}
                sortDirection={sortDirection}
              />
            )}
            <div className={styles.flexTableContent}>
              {_data.map((row, index) => {
                if (
                  (row as DataTableSectionHeaderType)?._dataTableSectionId ===
                  _dataTableSectionId
                ) {
                  return (
                    <DataTableSectionHeaderView
                      key={index}
                      section={row as DataTableSectionHeaderType}
                    />
                  );
                }

                return (
                  <DataTableRow
                    inline={inline}
                    columns={columns}
                    key={index}
                    onClickRow={onClickRow}
                    rowData={row as ObjectType}
                    rowIndex={index}
                    selectedIndex={selectedIndex}
                    selectedIndexStyle={selectedIndexStyle}
                  />
                );
              })}
            </div>
            {(numRowsToShowPerScroll !== -1 || onUserScrolledToEndOfTable) && (
              <DataTableLoadMoreMarker
                key={_data.length}
                onLoadMoreItems={loadMoreItems}
              />
            )}
          </div>
        )}
      </LoadingContainer>
    </div>
  );
};

export default typedMemo(DataTable);
