import APIGen from 'ecto-common/lib/API/APIGen';
import Icons from 'ecto-common/lib/Icons/Icons';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import HighchartsReact, {
  HighchartsReactRefObject
} from 'highcharts-react-official';
import { colors } from 'ecto-common/lib/styles/variables';
import { AdminEquipmentSignalWithConfigType } from 'js/components/ManageEquipment/EditEquipment/Util/editEquipmentUtil';
import { standardStockChartOptions } from 'ecto-common/lib/SignalSelector/ChartUtils';
import { Highcharts } from 'ecto-common/lib/Highcharts/Highcharts';
import { getSignalTypeUnit } from 'ecto-common/lib/SignalSelector/SignalUtils';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { showChartStatus } from 'ecto-common/lib/Charts/ChartUtil';
import T from 'ecto-common/lib/lang/Language';
import dimensions from 'ecto-common/lib/styles/dimensions';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import PresentationAPIGen from 'ecto-common/lib/API/PresentationAPIGen';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { useCallbackOnChange } from 'ecto-common/lib/hooks/useOpenStateTrigger';

const containerProps = {
  style: {
    marginTop: dimensions.largeMargin
  }
};

type DataPointModel = {
  startDate: string;
  endDate: string;
};

const models: ModelDefinition<DataPointModel>[] = [
  {
    modelType: ModelType.DATE,
    key: (input) => input.startDate,
    label: T.admin.equipment.deletedatapoint.startdate,
    withTime: true,
    isHorizontal: true,
    hasError: (date, input) => {
      return (
        isNullOrWhitespace(date) ||
        (!isNullOrWhitespace(input.endDate) && date > input.endDate)
      );
    }
  },
  {
    modelType: ModelType.DATE,
    key: (input) => input.endDate,
    label: T.admin.equipment.deletedatapoint.enddate,
    isHorizontal: true,
    withTime: true,
    hasError: isNullOrWhitespace
  }
];

const DeleteDataPointsModal = ({
  signal,
  onModalClose
}: {
  signal: AdminEquipmentSignalWithConfigType;
  onModalClose: () => void;
}) => {
  const [input, setInput] = useState<DataPointModel>({
    startDate: '',
    endDate: ''
  });

  const isOpen = signal != null;
  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );
  const signalUnitTypesMap = useCommonSelector(
    (state) => state.general.signalUnitTypesMap
  );

  const unit =
    signal?.signalTypeId != null &&
    getSignalTypeUnit(signal?.signalTypeId, signalTypesMap, signalUnitTypesMap);

  useCallbackOnChange(isOpen, () => {
    setInput({
      startDate: '',
      endDate: ''
    });
  });

  const datesAreValid =
    !isNullOrWhitespace(input.startDate) &&
    !isNullOrWhitespace(input.endDate) &&
    input.startDate < input.endDate;

  const enableSignalValuesQuery = isOpen && datesAreValid;

  const deleteMutation =
    PresentationAPIGen.Datapoint.deleteDatapointSamples.useMutation(
      {
        id: signal?.signalId
      },
      {
        endTime: input.endDate,
        startTime: input.startDate
      },
      {
        onSuccess: () => {
          toastStore.addSuccessToast(T.admin.equipment.deletedatapoint.success);
          onModalClose();
          hideConfirmDialog();
        },
        onError: () => {
          toastStore.addErrorToast(T.common.unknownerror);
          hideConfirmDialog();
        }
      }
    );

  const signalValuesQuery = APIGen.EIoTSignals.getSignalValues.useQuery(
    {
      SignalIds: [signal?.signalId],
      StartDate: input.startDate,
      EndDate: input.endDate,
      Points: 1000
    },
    {
      enabled: enableSignalValuesQuery
    }
  );

  const ref = useRef<HighchartsReactRefObject>();

  const options: Highcharts.Options = useMemo(() => {
    return {
      yAxis: [
        {
          id: unit,
          title: {
            text: unit
          },
          opposite: false
        }
      ],
      ...standardStockChartOptions(true),
      series: [
        {
          name: signal?.name,
          color: colors.errorColor,
          step: 'left',
          type: 'line',
          yAxis: unit,
          data:
            signalValuesQuery.data?.[0]?.signals?.map((value) => [
              new Date(value.time).getTime(),
              value.value
            ]) ?? []
        }
      ]
    } as const;
  }, [signal?.name, signalValuesQuery.data, unit]);

  const updateChartStatus = useCallback(() => {
    if (ref.current) {
      const chart = ref.current.chart;

      showChartStatus({
        hasError: false,
        chart,
        hasPointsOverflow: false,
        isLoading: enableSignalValuesQuery && signalValuesQuery.isLoading,
        config: options,
        noSeriesText: T.graphs.nosignalsfound,
        noDataText: T.admin.equipment.deletedatapoint.nodata
      });
    }
  }, [enableSignalValuesQuery, options, signalValuesQuery.isLoading]);

  // We use the loading text as a generic label mechanism for displaying information.
  useEffect(() => {
    updateChartStatus();
  }, [
    ref,
    enableSignalValuesQuery,
    signalValuesQuery.isLoading,
    options,
    updateChartStatus
  ]);

  const assignRef = useCallback(
    (value: HighchartsReactRefObject) => {
      ref.current = value;
      updateChartStatus();
    },
    [updateChartStatus]
  );

  const onDelete = useCallback(() => {
    deleteMutation.mutate();
  }, [deleteMutation]);

  const [showingConfirmDialog, showConfirmDialog, hideConfirmDialog] =
    useDialogState(false);

  return (
    <>
      <ActionModal
        isOpen={isOpen}
        onModalClose={onModalClose}
        title={T.admin.equipment.deletedatapoint.title}
        headerIcon={Icons.Eraser}
        enablePopoutChildren
        actionText={T.common.delete}
        onConfirmClick={showConfirmDialog}
        disableActionButton={!datesAreValid}
      >
        <ModelForm models={models} input={input} setInput={setInput} />
        <HighchartsReact
          options={options}
          highcharts={Highcharts}
          constructorType={'stockChart'}
          ref={assignRef}
          containerProps={containerProps}
        />
      </ActionModal>
      <ConfirmDeleteDialog
        isOpen={showingConfirmDialog}
        onModalClose={hideConfirmDialog}
        isLoading={deleteMutation.isLoading}
        onDelete={onDelete}
      >
        {T.format(T.admin.equipment.deletedatapoint.deleteformat, signal?.name)}
      </ConfirmDeleteDialog>
    </>
  );
};

export default React.memo(DeleteDataPointsModal);
