import { createContext, useMemo } from 'react';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import {
  EquipmentResponseModel,
  EquipmentTypeResponseModel,
  GridType,
  SignalProviderType,
  SignalTypeResponseModel,
  UnitResponseModel
} from 'ecto-common/lib/API/APIGen';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { PromiseCacheContext } from 'ecto-common/lib/Dashboard/datasources/signalUtils';

export type DashboardDataContextType = {
  equipmentTypesMap: Record<string, EquipmentTypeResponseModel>;
  signalTypesMap: Record<string, SignalTypeResponseModel>;
  signalTypesNameMap: Record<string, SignalTypeResponseModel>;
  signalUnitTypesMap: Record<string, UnitResponseModel>;
  signalProviderTypes: SignalProviderType[];
  cacheContext: PromiseCacheContext;
  gridTypes: GridType[];
  isAdmin: boolean;
  equipmentTypes: EquipmentTypeResponseModel[];
  nodeMap: Record<string, SingleGridNode>;
  equipmentMap: Record<string, EquipmentResponseModel>;
  nodeId: string;
  setNode: (nodeId: string) => void;

  // These are extensions used only by Ectotable
  isLoadingResources: boolean;
  hasError: boolean;
};

export const useDashboardDataFromRedux = () => {
  const nodeMap = useCommonSelector((state) => state.general.nodeMap);
  const gridTypes = useCommonSelector((state) => state.general.gridTypes);
  const isAdmin = useCommonSelector((state) => state.general.isAdmin);
  const equipmentMap = useCommonSelector((state) => state.general.equipmentMap);
  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );
  const signalTypesNameMap = useCommonSelector(
    (state) => state.general.signalTypesNameMap
  );
  const signalUnitTypesMap = useCommonSelector(
    (state) => state.general.signalUnitTypesMap
  );
  const equipmentTypes = useCommonSelector(
    (state) => state.general.equipmentTypes
  );
  const signalProviderTypes = useCommonSelector(
    (state) => state.general.enums?.signalProviderTypes
  );
  const equipmentTypesMap = useCommonSelector(
    (state) => state.general.equipmentTypesMap
  );
  const cacheContext = useMemo(() => new Map(), []);

  return useMemo(
    () => ({
      nodeMap,
      gridTypes,
      isAdmin,
      equipmentMap,
      signalTypesMap,
      signalTypesNameMap,
      signalUnitTypesMap,
      equipmentTypes,
      signalProviderTypes,
      equipmentTypesMap,
      cacheContext,
      isLoadingResources: false,
      hasError: false
    }),
    [
      nodeMap,
      gridTypes,
      isAdmin,
      equipmentMap,
      signalTypesMap,
      signalTypesNameMap,
      signalUnitTypesMap,
      equipmentTypes,
      signalProviderTypes,
      equipmentTypesMap,
      cacheContext
    ]
  );
};

const DashboardDataContext = createContext<DashboardDataContextType>({
  nodeMap: {},
  gridTypes: [],
  isAdmin: false,
  equipmentMap: {},
  signalTypesMap: {},
  signalUnitTypesMap: {},
  signalTypesNameMap: {},
  equipmentTypes: [],
  signalProviderTypes: [],
  equipmentTypesMap: {},
  cacheContext: new Map(),
  nodeId: null,
  setNode: null,
  isLoadingResources: false,
  hasError: false
});

export default DashboardDataContext;
