import React from 'react';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import T from 'ecto-common/lib/lang/Language';

const NoResourcesInfo = () => {
  return (
    <ToolbarContentPage
      title={T.tenants.noresources}
      wrapContent={false}
      showLocationPicker={false}
    >
      {T.tenants.noresourcestext}
    </ToolbarContentPage>
  );
};

export default React.memo(NoResourcesInfo);
