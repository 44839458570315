import React from 'react';
import styles from '../ModelEditor.module.css';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import Button from 'ecto-common/lib/Button/Button';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { ModelDefinitionInternal } from 'ecto-common/lib/ModelForm/ModelPropType';

export type ButtonModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object
> = {
  modelType: typeof ModelType.BUTTON;
  icon?: React.ReactNode;
  onAction?: React.MouseEventHandler<HTMLButtonElement>;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, unknown>;

type ModelEditorButtonProps = ModelEditorProps & {
  model: ButtonModelDefinition<object>;
};

const ModelEditorButton = ({
  model,
  isHorizontal,
  disabled
}: ModelEditorButtonProps) => {
  return (
    <KeyValueGeneric isHorizontal={isHorizontal} keyText={model.label}>
      <Button
        className={styles.button}
        onClick={model.onAction}
        disabled={disabled}
      >
        {model.icon} {model.label}
      </Button>
    </KeyValueGeneric>
  );
};

export default React.memo(ModelEditorButton);
