import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState
} from 'react';
import API, { cancellablePromiseList } from 'ecto-common/lib/API/API';
import {
  AddOrUpdateTemperatureImpactProviderRequestModel,
  SignalProviderType,
  TemperatureImpactProviderSignalsResponseModel
} from 'ecto-common/lib/API/APIGen';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import T from 'ecto-common/lib/lang/Language';
import HorizontalAlignments from 'ecto-common/lib/types/HorizontalAlign';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { calculateDataTableMinHeight } from 'ecto-common/lib/utils/dataTableUtils';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import _ from 'lodash';
import UUID from 'uuidjs';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';

type TempImpactTableEntry = {
  tool: string;
  status: string;
  action: MouseEventHandler<HTMLButtonElement>;
};

const getToolState = (contextSettings: ApiContextSettings, nodeId: string) => {
  const promises = [
    API.Admin.Signals.getProvidersForNodeIds(
      contextSettings,
      [nodeId],
      [SignalProviderType.TemperatureImpact]
    ),
    API.Admin.TemperatureImpact.getProviderSignals(contextSettings, nodeId)
  ] as const;

  return cancellablePromiseList(promises);
};

const columns: DataTableColumnProps<TempImpactTableEntry>[] = [
  {
    label: T.admin.editlocation.tools.status,
    dataKey: 'status'
  },
  {
    label: T.admin.editlocation.tools.manage,
    dataKey: 'action',
    width: 100,
    flexGrow: 0,
    align: HorizontalAlignments.CENTER,
    dataFormatter: (onClick: MouseEventHandler<HTMLButtonElement>) => {
      return (
        <LocalizedButtons.Add onClick={onClick} disabled={onClick === null} />
      );
    }
  }
];

interface ManageTemperatureImpactProps {
  nodeId?: string;
}

const ManageTemperatureImpact = ({ nodeId }: ManageTemperatureImpactProps) => {
  const [hasTemperatureImpact, setHasTemperatureImpact] = useState(false);
  const [temperatureImpactParams, setTemperatureImpactParams] =
    useState<TemperatureImpactProviderSignalsResponseModel>(null);

  const [getToolsIsLoading, getTools, cancelGetTools] = usePromiseCall({
    promise: getToolState,
    onSuccess: ([providers, temperatureImpactParamsList]) => {
      setHasTemperatureImpact(providers.length > 0);
      const newTemperatureImpactParams = _.head(temperatureImpactParamsList);
      setTemperatureImpactParams(newTemperatureImpactParams);
    },
    onError: () => {}
  });

  const [addToolIsLoading, addTool] = usePromiseCall({
    promise: API.Admin.TemperatureImpact.addTemperatureImpact,
    onSuccess: () => {
      getTools(nodeId);
    },
    onError: () => {
      toastStore.addErrorToast(T.common.unknownerror);
    }
  });

  const supportsAddingTemperatureImpact =
    temperatureImpactParams?.powerControlGlobalAmplitudeDesiredSignalId !=
      null && temperatureImpactParams?.meteorologyTSignalId != null;

  let temperatureImpactStatus = '';
  let temperatureImpactAction = null;

  const addTemperatureImpact = useCallback(() => {
    // Cast due to response model having optional fields - verified to be set by form.
    addTool({
      nodeId,
      providerId: UUID.generate(),
      ...temperatureImpactParams
    } as AddOrUpdateTemperatureImpactProviderRequestModel);
  }, [addTool, nodeId, temperatureImpactParams]);

  if (getToolsIsLoading || addToolIsLoading) {
    temperatureImpactStatus = T.common.loading;
  } else if (hasTemperatureImpact) {
    temperatureImpactStatus = T.admin.editlocation.tools.configured;
  } else if (supportsAddingTemperatureImpact) {
    temperatureImpactStatus = T.admin.editlocation.tools.readytoadd;
    temperatureImpactAction = addTemperatureImpact;
  } else {
    temperatureImpactStatus = T.admin.editlocation.tools.unsupported;
  }

  useEffect(() => {
    if (nodeId !== null) {
      getTools(nodeId);
    }

    return () => {
      cancelGetTools();
    };
  }, [nodeId, cancelGetTools, getTools]);

  const data: TempImpactTableEntry[] = [
    {
      tool: T.admin.editlocation.tools.temperatureimpact,
      status: temperatureImpactStatus,
      action: temperatureImpactAction
    }
  ];

  return (
    <div>
      <DataTable
        isLoading={getToolsIsLoading || addToolIsLoading}
        data={data}
        columns={columns}
        minHeight={calculateDataTableMinHeight({
          pageSize: 1,
          showNoticeHeaders: false
        })}
      />
    </div>
  );
};

export default ManageTemperatureImpact;
