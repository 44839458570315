import React, { useCallback, useState } from 'react';
import T from 'ecto-common/lib/lang/Language';

import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';

import Button from 'ecto-common/lib/Button/Button';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import DeleteButton from 'ecto-common/lib/Button/DeleteButton';
import { SignalProviderType } from 'ecto-common/lib/API/APIGen';
import styles from './EditSignalProviderType.module.css';
import { Prompt } from 'react-router';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import Icons from 'ecto-common/lib/Icons/Icons';
import SelectProcessMapDialog from 'ecto-common/lib/ProcessMaps/SelectProcessMapDialog';
import { useParams } from 'react-router-dom';
import { TemplateManagementParams } from 'js/utils/routeConstants';

interface EditSignalProviderTypeDetailsProps {
  onTemplateNameChanged(newName: string): void;
  templateName: string;
  hasUnsavedChanges: boolean;
  onDeleteTemplate(): void;
  onSaveDetails(): void;
  signalProviderType: string;
  isLoading: boolean;
}

const EditSignalProviderTypeDetails = ({
  onTemplateNameChanged,
  templateName,
  hasUnsavedChanges,
  onDeleteTemplate,
  onSaveDetails,
  signalProviderType,
  isLoading
}: EditSignalProviderTypeDetailsProps) => {
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [showProcessMapDialog, onShowProcessMapDialog, hideProcessMapDialog] =
    useDialogState(false);
  const { itemId } = useParams<TemplateManagementParams>();

  const deleteEquipment = useCallback(() => {
    setShowConfirmDeleteDialog(false);
    onDeleteTemplate();
  }, [setShowConfirmDeleteDialog, onDeleteTemplate]);

  const onModalClose = useCallback(
    () => setShowConfirmDeleteDialog(false),
    [setShowConfirmDeleteDialog]
  );

  return (
    <>
      <Prompt when={hasUnsavedChanges} message={T.admin.form.unsavedstate} />

      <KeyValueLine>
        <KeyValueInput
          className={styles.nameInput}
          onChange={(e) => onTemplateNameChanged(e.target.value)}
          value={templateName}
          keyText={T.admin.alarmtemplates.displayname}
          placeholder={T.admin.alarmtemplates.displayname}
        />
      </KeyValueLine>

      <div className={styles.buttonContainer}>
        {signalProviderType === SignalProviderType.Equipment && (
          <Button onClick={onShowProcessMapDialog}>
            <Icons.Settings />
            {T.admin.editlocation.editprocessmap}
          </Button>
        )}
        {signalProviderType === SignalProviderType.Alarm && (
          <DeleteButton onClick={() => setShowConfirmDeleteDialog(true)}>
            {T.admin.alarmtemplates.delete}
          </DeleteButton>
        )}

        <LocalizedButtons.Save
          disabled={!hasUnsavedChanges || isLoading}
          onClick={onSaveDetails}
        />

        {signalProviderType === SignalProviderType.Equipment && (
          <SelectProcessMapDialog
            onModalClose={hideProcessMapDialog}
            isOpen={showProcessMapDialog}
            equipmentTypeId={itemId}
          />
        )}

        <ConfirmDeleteDialog
          isOpen={showConfirmDeleteDialog}
          onDelete={deleteEquipment}
          onModalClose={onModalClose}
          itemName={templateName}
        />
      </div>
    </>
  );
};

export default EditSignalProviderTypeDetails;
