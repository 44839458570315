import React from 'react';
import styles from './Page.module.css';
import LocationSidebar from 'ecto-common/lib/LocationSidebar/LocationSidebar';
import classNames from 'classnames';
import { toggleSideBarExpanded } from 'ecto-common/lib/actions/toggleSidebarExpanded';
import { ResourceType } from 'ecto-common/lib/constants';
import { useCommonDispatch } from 'ecto-common/lib/reducers/storeCommon';
import { FeatureFlag } from '../FeatureFlags/FeatureFlags';

export type PageNavLinkSubLink = {
  route?: string;
  path: (tenantId: string, nodeId: string) => string;
  name: string;
  icon: React.ReactNode;
  resourceTypes?: ResourceType[];
  hideIfHasResourceTypes?: ResourceType[];
  featureFlag?: FeatureFlag;
};

export type PageNavLink = {
  title: string;
  links: PageNavLinkSubLink[];
};

export interface PageProps {
  content?: React.ReactNode;
  navLinks?: PageNavLink[];
  isLoading?: boolean;
}

const Page = ({ content, navLinks = [], isLoading = false }: PageProps) => {
  const dispatch = useCommonDispatch();

  return (
    <div className={styles.main}>
      <div className={styles.outerContent}>
        <LocationSidebar
          signedIn
          onToggleSidebar={() => dispatch(toggleSideBarExpanded())}
          navLinks={navLinks}
          isLoading={isLoading}
        />
        <div className={classNames(styles.locationContainer)}>{content}</div>
      </div>
    </div>
  );
};

export default Page;
