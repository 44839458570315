import React, { useCallback, useContext, useEffect, useState } from 'react';
import T from 'ecto-common/lib/lang/Language';
import API, { CancellablePromise } from 'ecto-common/lib/API/API';
import queryString from 'query-string';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import {
  getNodeFromMap,
  getFullPathFromMap,
  getEquipmentNode
} from 'ecto-common/lib/utils/locationUtils';
import { useHistory, useLocation } from 'react-router';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import AlarmViewSection from './AlarmViewSection';
import AlarmHistoryModal from './AlarmHistoryModal';
import {
  ASC,
  DESC,
  SortDirectionType
} from 'ecto-common/lib/DataTable/SortDirection';
import { requestWasCancelled } from 'ecto-common/lib/utils/reqState';
import _ from 'lodash';
import PagingFooter from 'ecto-common/lib/PagingFooter/PagingFooter';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import {
  AlarmInfoResponseModel,
  AlarmsListResponseModel,
  BuildingStatus
} from 'ecto-common/lib/API/APIGen';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

const PAGE_LINK_QUERY_PARAM = 'page';

const initialAlarmState: AlarmsListResponseModel = {
  totalPages: 0,
  alarms: []
};

const translateSortProperties: Record<string, string> = {
  severity: 'severity',
  name: 'signal',
  isActive: 'status',
  alarmDate: 'date',
  isActiveDate: 'date'
};

interface AlarmTableViewProps {
  nodeId: string;
  reloadTrigger?: number;
  listHistory?: boolean;
  search?: string;
  compact?: boolean;
  filterParams?: {
    buildingStatuses: BuildingStatus[];
  };
  pageSize?: number;
}

export type AlarmTableViewNode = AlarmInfoResponseModel & {
  node: SingleGridNode;
  onClickAlarmDate: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    alarm: AlarmTableViewNode
  ) => void;
  isHistoryItem: boolean;
  fullPath: string;
};

const AlarmTableView = ({
  nodeId,
  reloadTrigger,
  search,
  listHistory,
  compact,
  filterParams,
  pageSize = 15
}: AlarmTableViewProps) => {
  const nodeMap = useCommonSelector((state) => state.general.nodeMap);
  const equipmentMap = useCommonSelector((state) => state.general.equipmentMap);

  const [historyAlarm, setHistoryAlarm] = useState<AlarmTableViewNode>(null);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alarmRows, setAlarmRows] = useState<AlarmTableViewNode[]>([]);
  const [totalPages, setTotalPages] = useState(0);

  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const page = _.parseInt((queryParams.page as string) ?? '0');

  const history = useHistory();

  const defaultSort = listHistory ? 'alarmDate' : 'severity';
  const defaultSortDirection: SortDirectionType = listHistory ? DESC : ASC;

  const sortBy = (queryParams.sortBy as string) || defaultSort;
  const sortDirection =
    (queryParams.sortDirection as SortDirectionType) || defaultSortDirection;

  const onSortChange = useCallback(
    (newSortBy: string, newSortDirection: string) => {
      history.replace({
        search:
          '?' +
          queryString.stringify({
            sortBy: newSortBy,
            sortDirection: newSortDirection
          })
      });
    },
    [history]
  );

  const onClickAlarmDate = useCallback(
    (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
      alarm: AlarmTableViewNode
    ) => {
      event.stopPropagation();
      setHistoryAlarm(alarm);
    },
    []
  );

  // Not ideal to pass these state variables in but we run the risk of race conditions otherwise
  const updateAlarmRows = useCallback(
    (
      alarms: AlarmsListResponseModel,
      curNodeId: string,
      curListHistory: boolean,
      curOnClickAlarmDate: (
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        alarm: AlarmTableViewNode
      ) => void
    ) => {
      setTotalPages(alarms.totalPages);
      setAlarmRows(
        alarms.alarms.map((alarm) => {
          const node =
            getNodeFromMap(nodeMap, alarm.nodeId) ||
            getEquipmentNode(alarm.nodeId, nodeMap, equipmentMap);

          const fullPath = getFullPathFromMap(
            nodeMap,
            // TODO: Why signalGroupId? Seems to be no relation at all?
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            node.nodeId || (node as any).signalGroupId,
            curNodeId,
            true
          );

          return {
            ...alarm,
            node,
            onClickAlarmDate: curOnClickAlarmDate,
            isHistoryItem: curListHistory,
            fullPath
          };
        })
      );
    },
    [setAlarmRows, equipmentMap, nodeMap]
  );

  useEffect(() => {
    updateAlarmRows(initialAlarmState, nodeId, listHistory, onClickAlarmDate);
  }, [nodeId, updateAlarmRows, listHistory, onClickAlarmDate]);

  // Send in a simpler item (string) instead of object to the useEffect so it's dependencies can be shallow compared
  const _filterParams = JSON.stringify(filterParams);
  const { contextSettings } = useContext(TenantContext);

  useEffect(() => {
    setLoading(true);
    setHasError(false);

    const node = getNodeFromMap(nodeMap, nodeId);
    let promise: CancellablePromise<AlarmsListResponseModel> = null;

    if (listHistory) {
      promise = API.Alarms.getHistory(contextSettings, {
        nodeId: nodeId.startsWith(ROOT_NODE_ID) ? null : nodeId,
        grid: node.grid,
        searchString: search,
        page: page + 1,
        pageSize,
        sortDirection,
        ...JSON.parse(_filterParams),
        orderBy: translateSortProperties[sortBy]
      });
    } else {
      promise = API.Alarms.getAlarms(contextSettings, {
        nodeId: nodeId.startsWith(ROOT_NODE_ID) ? null : nodeId,
        grid: node.grid,
        searchString: search,
        page: page + 1,
        pageSize,
        sortDirection,
        orderBy: translateSortProperties[sortBy],
        buildingStatuses: filterParams?.buildingStatuses
      });
    }

    promise
      .then((alarmsResult) => {
        if (page > 0 && page >= alarmsResult.totalPages) {
          let newSearch = queryString.parse(window.location.search);
          newSearch.page = '' + Math.max(0, alarmsResult.totalPages - 1);

          history.replace({
            search: '?' + queryString.stringify(newSearch)
          });
        } else {
          setLoading(false);
          updateAlarmRows(alarmsResult, nodeId, listHistory, onClickAlarmDate);
        }
      })
      .catch((e: unknown) => {
        if (!requestWasCancelled(e)) {
          setLoading(false);
          setHasError(true);
        }
      });

    return () => {
      promise.cancel();
    };
  }, [
    contextSettings,
    nodeId,
    reloadTrigger,
    search,
    page,
    pageSize,
    listHistory,
    sortBy,
    sortDirection,
    updateAlarmRows,
    _filterParams,
    onClickAlarmDate,
    filterParams?.buildingStatuses,
    history,
    nodeMap
  ]);

  const onAlarmHistoryModalClose = useCallback(() => {
    setHistoryAlarm(null);

    const newParams = { ...queryParams };
    delete newParams.historyPage;

    history.replace({
      search: '?' + queryString.stringify(newParams)
    });
  }, [setHistoryAlarm, history, queryParams]);

  return (
    <>
      {!hasError && (
        <AlarmViewSection
          nodeId={nodeId}
          key={listHistory + ''}
          loading={loading}
          alarmList={alarmRows}
          compact={compact}
          sortDirection={sortDirection}
          sortBy={sortBy}
          onSortChange={onSortChange}
        />
      )}

      {hasError && (
        <ErrorNotice showHeader>{T.common.datatable.error}</ErrorNotice>
      )}

      <AlarmHistoryModal
        nodeId={nodeId}
        alarm={historyAlarm}
        onModalClose={onAlarmHistoryModalClose}
        showingHistoryItem={listHistory}
      />
      {!compact && (
        <PagingFooter
          totalPages={totalPages}
          page={page}
          pageLinkQueryParameter={PAGE_LINK_QUERY_PARAM}
        />
      )}
    </>
  );
};

export default AlarmTableView;
