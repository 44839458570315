import {
  createDefaultRequestReducers,
  createInitialReqStates
} from 'ecto-common/lib/utils/requestStatus';
import _ from 'lodash';
import { performAsyncRequestWithStateChanges } from 'ecto-common/lib/API/API';
import { CommonDispatch } from 'ecto-common/lib/reducers/storeCommon';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';

export default class RequestModule {
  requestPromises = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialState: any = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handlers: Record<string, (state: any, action: any) => any> = {};

  /*
   * @param Requests - Request to map initialState and to create default reducer
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(requests: any) {
    this.requestPromises = {};
    // Assign initialReqState to all requests
    this.initialState = { ...createInitialReqStates(requests) };
    this.handlers = createDefaultRequestReducers(requests);
  }

  cancelRequest = (name: string) => {
    return () => {
      _.invoke(_.get(this.requestPromises, name), 'cancel');
      _.set(this.requestPromises, name, null);
    };
  };

  doRequest = (
    contextSettings: ApiContextSettings,
    name: string,
    apiCall: (
      contextSettings: ApiContextSettings,
      ...rest: unknown[]
    ) => Promise<unknown>,
    ...apiArgs: unknown[]
  ) => {
    if (apiCall == null) {
      throw Error(
        `Missing apiCall argument in RequestModule for name: ${name}!`
      );
    }
    return async (dispatch: CommonDispatch) => {
      dispatch(this.cancelRequest(name));
      const request = apiCall(contextSettings, ...apiArgs);
      _.set(this.requestPromises, name, request);
      return await performAsyncRequestWithStateChanges(
        dispatch,
        request,
        name,
        null,
        {},
        false
      );
    };
  };
}
