import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import NavLinkFix from 'ecto-common/lib/NavLinkFix/NavLinkFix';
import React from 'react';
import { getNodePage } from 'ecto-common/lib/utils/commonLinkUtil';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import { getPathFromModelKeyFunc } from 'ecto-common/lib/ModelForm/formUtils';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';

/**
 * Returns a string value to be shown in the UI for the model.
 */
export function modelFormattedValue<
  ObjectType extends object,
  EnvironmentType extends object
>(
  model: ModelDefinition<ObjectType, EnvironmentType>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rawValue: any,
  input: ObjectType
) {
  if (
    (model.modelType === ModelType.LABEL ||
      model.modelType === ModelType.DISABLED_TEXT) &&
    model.dataFormatter != null
  ) {
    return model.dataFormatter(rawValue, input);
  }

  return rawValue;
}

// Get the 'raw' data no transform or value change
export function modelRawDataValue<
  ObjectType extends object,
  EnvironmentType extends object
>(model: ModelDefinition<ObjectType, EnvironmentType>, input: ObjectType) {
  return _.get(input, getPathFromModelKeyFunc(model.key));
}

export const modelFormattedNodeValue = (
  nodeId: string,
  nodeMap: Record<string, SingleGridNode>
) => {
  return getNodeFromMap(nodeMap, nodeId)?.name ?? '';
};

export const modelFormattedNodeValues = (
  nodeIds: string[],
  nodeMap: Record<string, SingleGridNode>
) => {
  if (nodeIds == null || nodeIds?.length === 0) {
    return null;
  } else if (nodeIds.length === 1) {
    return modelFormattedNodeValue(_.head(nodeIds), nodeMap);
  }

  return T.format(T.modeleditor.locationformat, nodeIds.length);
};

export const modelFormattedNodeLinks = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  model: any,
  nodeIds: string[],
  nodeMap: Record<string, SingleGridNode>,
  tenantId: string
) => {
  const nodeObjects = nodeIds
    .map((node) => getNodeFromMap(nodeMap, node))
    .filter((node) => node != null);

  const formatter = model.dataFormatter ?? _.identity;

  return nodeObjects.map((node, idx) => (
    <NavLinkFix to={getNodePage(tenantId, node)} key={node.nodeId + '-' + idx}>
      {formatter(node.name)}
      {idx < nodeIds.length - 1 && <span>,&nbsp;</span>}
    </NavLinkFix>
  ));
};
