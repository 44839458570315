import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';

import Checkbox from 'ecto-common/lib/Checkbox/Checkbox';

import styles from './SearchableSelectableTable.module.css';
import SearchableTable from './SearchableTable';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import { typedMemo } from 'ecto-common/lib/utils/typescriptUtils';

/**
 * Creates dictionary marked with values true/false where key is the dataKey
 * Ex: dataKey = 'id' and data = [ { id: 'name' }, { id: 'other' }] then result becomes  { name: true, other: true }
 * @param data
 * @param dataKey
 * @param flag
 * @returns {*} dictionary that contains key: 'name': <bool> where name
 */
function createMarkedData<SelectedType extends object>(
  data: SelectedType[],
  dataKey: string,
  flag: boolean
): Record<string, boolean> {
  return _.reduce(
    data,
    (dict, item) => ({ ...dict, [_.get(item, dataKey) as string]: flag }),
    {} as Record<string, boolean>
  );
}

interface SearchableSelectableTableProps<ObjectType, SelectedType> {
  isLoading: boolean;
  data: ObjectType[];
  columns: DataTableColumnProps<ObjectType>[];
  selectedData: SelectedType[];
  onMarkedData(data: SelectedType[]): void;
  dataKey: keyof ObjectType;
  noDataString?: string;
  searchPlaceholder?: string;
  filterPredicate(query: string): (data: ObjectType) => boolean;
  shouldIgnoreOnClickRow?(data: ObjectType, row: number, col: number): boolean;
}

// TODO: SelectedType seems a bit redundant, perhaps we should just keep a collection of ObjectType[] instead.
function SearchableSelectableTable<
  ObjectType extends object,
  SelectedType extends object
>({
  isLoading,
  data,
  columns,
  selectedData,
  onMarkedData,
  dataKey,
  noDataString,
  searchPlaceholder,
  filterPredicate,
  shouldIgnoreOnClickRow
}: SearchableSelectableTableProps<ObjectType, SelectedType>) {
  const selectedLookupTable: Record<string, boolean> = useMemo(
    () => createMarkedData(selectedData, dataKey as string, true),
    [selectedData, dataKey]
  );
  // Returns only data that is currently selected
  const onlyMarkedData: boolean[] = useMemo(
    () => _.filter(selectedLookupTable, _.identity),
    [selectedLookupTable]
  );
  const [searchString, setSearchString] = useState('');

  const markData = useCallback(
    (id: string, isEnabled: boolean) => {
      if (isEnabled) {
        onMarkedData(selectedData.concat({ [dataKey]: id } as SelectedType));
      } else {
        onMarkedData(_.reject(selectedData, [dataKey, id]));
      }
    },
    [onMarkedData, dataKey, selectedData]
  );

  const onClickRow = useCallback(
    (rowData: ObjectType, rowIndex: number, columnIndex: number) => {
      if (
        shouldIgnoreOnClickRow &&
        shouldIgnoreOnClickRow(rowData, rowIndex, columnIndex)
      ) {
        return;
      }
      const value = rowData[dataKey] as string;
      markData(value, !selectedLookupTable[value]);
    },
    [shouldIgnoreOnClickRow, selectedLookupTable, dataKey, markData]
  );

  const filteredResult = useMemo(() => {
    return _.filter(data, filterPredicate(searchString));
  }, [searchString, data, filterPredicate]);

  const toggleAll = useCallback(() => {
    // if number of marked data are less than filtered data, then assign true to all, else false
    const toggleAllToEnabled = onlyMarkedData.length < data.length;

    if (toggleAllToEnabled) {
      onMarkedData(
        data.map(
          (item) =>
            ({
              [dataKey]: item[dataKey]
            }) as SelectedType
        )
      );
    } else {
      onMarkedData([]);
    }
  }, [onlyMarkedData, data, dataKey, onMarkedData]);

  const partialCheckedAddAll =
    onlyMarkedData.length > 0 && onlyMarkedData.length < filteredResult.length;
  const addAllChecked = onlyMarkedData.length === filteredResult.length;

  // Merge checkbox column with user specified colums
  let _columns: DataTableColumnProps<ObjectType>[] = useMemo(
    () => [
      {
        label: (
          <Checkbox
            className={styles.addAllCheckbox}
            partiallyChecked={partialCheckedAddAll}
            onChange={toggleAll}
            checked={addAllChecked}
          />
        ),
        dataKey: dataKey as string,
        width: 44,
        flexGrow: 0,
        dataFormatter: (key: string) => {
          return <Checkbox checked={selectedLookupTable[key] === true} />;
        }
      },
      ...columns
    ],
    [
      columns,
      selectedLookupTable,
      toggleAll,
      partialCheckedAddAll,
      addAllChecked,
      dataKey
    ]
  );

  return (
    <SearchableTable<ObjectType>
      isLoading={isLoading}
      searchString={searchString}
      searchPlaceholder={searchPlaceholder}
      noDataText={noDataString}
      data={filteredResult}
      columns={_columns}
      onClickRow={onClickRow}
      onSearch={setSearchString}
    />
  );
}

export default typedMemo(SearchableSelectableTable);
