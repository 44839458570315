import React, { Component } from 'react';
import { autobind } from 'core-decorators';
import ToastStore from './ToastStore';
import Transition from '../Transition/Transition';
import cssDurationToMilliseconds from '../utils/cssDurationToMilliseconds';
import animations from '../styles/variables/animations';
import styles from './ToastContainer.module.css';
import InlineToast from 'ecto-common/lib/Toast/InlineToast';
import Toast from 'ecto-common/lib/Toast/Toast';

const toastSpeed = cssDurationToMilliseconds(animations.toastSpeed);

const transitionStyles = {
  appear: styles.enter,
  appearActive: styles.enterActive,
  enter: styles.enter,
  enterActive: styles.enterActive,
  exit: styles.exit,
  exitActive: styles.exitActive
};

export type ToastType = {
  type: string;
  message: React.ReactNode;
  id: number;
  sourceElement: Element;
};

class ToastContainer extends Component {
  //
  // Local variables

  state = {
    toasts: [] as ToastType[]
  };

  //
  // Lifecycle

  componentDidMount() {
    toastStore.addListener(this.onToastChange);
  }

  componentWillUnmount() {
    toastStore.removeListener(this.onToastChange);
  }

  shouldComponentUpdate(_: unknown, nextState: typeof this.state) {
    return this.state.toasts.length !== nextState.toasts.length;
  }

  //
  // Listeners

  @autobind
  onToastChange(toasts: ToastType[]) {
    this.setState({ toasts });
  }

  //
  // Render function

  render() {
    return (
      <div className={styles.toastOuterContainer}>
        <Transition
          className={styles.toastContainer}
          classNames={transitionStyles}
          timeout={{
            appear: toastSpeed,
            enter: toastSpeed,
            exit: toastSpeed
          }}
        >
          {this.state.toasts.map(this._renderToast)}
        </Transition>
        <div className={styles.toastPadElement} />
      </div>
    );
  }

  _renderToast({ type, message, id, sourceElement }: ToastType) {
    let GenericToast;
    let toastProps;

    if (sourceElement) {
      GenericToast = InlineToast;
      toastProps = { sourceElement };
    } else {
      GenericToast = Toast;
      toastProps = {};
    }

    return (
      <GenericToast
        type={type}
        onRemove={() => toastStore.removeToast(id)}
        key={`toast-${id}`}
        {...toastProps}
      >
        {message}
      </GenericToast>
    );
  }
}

export default ToastContainer;

export const toastStore = new ToastStore();
