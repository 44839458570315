import React, { useCallback, useEffect, useRef, useState } from 'react';
import T from 'ecto-common/lib/lang/Language';
import { showChartStatus } from 'ecto-common/lib/Charts/ChartUtil';
import HighchartsReact, {
  HighchartsReactRefObject
} from 'highcharts-react-official';
import _ from 'lodash';
import {
  arrayMergerHighcharts,
  standardStockChartOptions
} from 'ecto-common/lib/SignalSelector/ChartUtils';
import { Highcharts } from 'ecto-common/lib/Highcharts/Highcharts';

// Some series have no unit since they are in the "general"-measurement in influx.
// We call those 'n/a' to actually have something to show.

type SeriesVisibilityChangeFunction = (
  series: Highcharts.Series,
  visible: boolean
) => void;

const generateConfig = (
  extraConfig: Highcharts.Options,
  onAfterSetExtremes: Highcharts.AxisSetExtremesEventCallbackFunction,
  onVisibilityChange: SeriesVisibilityChangeFunction,
  seriesIds: string[],
  visibilityState: Record<string, boolean>,
  enableAnimation: boolean
) => {
  const _onShow: Highcharts.SeriesShowCallbackFunction = function () {
    onVisibilityChange(this, true);
  };

  const _onHide: Highcharts.SeriesHideCallbackFunction = function () {
    onVisibilityChange(this, false);
  };

  const enableBoost =
    extraConfig?.boost?.enabled !== undefined
      ? extraConfig?.boost?.enabled
      : true;

  const standardOptions = standardStockChartOptions(enableAnimation);

  return _.mergeWith(
    standardOptions,
    {
      xAxis: {
        events: {
          afterSetExtremes: onAfterSetExtremes
        }
      }
    },
    extraConfig,
    {
      series: extraConfig.series.map((series, index) => {
        return {
          ...series,
          boostThreshold: enableBoost ? 1 : undefined,
          turboThreshold: enableBoost ? 1 : undefined,
          visible:
            seriesIds == null || (visibilityState[seriesIds[index]] ?? true),
          events: {
            ...series.events,
            show: _onShow,
            hide: _onHide
          }
        };
      })
    },
    arrayMergerHighcharts
  );
};

const STOCKCHART_CONTAINER_PROPS = {
  style: {
    height: '100%',
    width: '100%'
  }
};

type StockChartProps = {
  onExtremesChange?: (min: number, max: number, userDidZoom: boolean) => void;
  isLoading?: boolean;
  dateFrom?: number;
  dateTo?: number;
  config: Highcharts.Options;
  /**
   * To keep a consistent visibility state of series when zooming, we need to have a
   * unique ID of every series. This should be the same length as series in config,
   * with a unique ID for every series. This makes it possible to have a stable identity
   * for every series, even when the series object change but the underlying signal does not.
   *
   * It is optional because some graphs do not have the ability to enable/disable visibility
   * (i.e. no legend - gauges etc). For those, we do not need to keep track of the visibility
   * state.
   *
   * For some it might be enough to pass the ID of the signal, but other more advanced use
   * cases allow for signals to be added multiple times, but with different aggregations.
   * In those cases it might be better to use the chartSignalId instead.
   */
  seriesIds?: string[];
  hasError?: boolean;
  containerWidth?: number;
  hasPointsOverflow?: boolean;
  noSeriesText?: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  containerProps?: { [key: string]: any };
  enableAnimation?: boolean;
};

/**
 * This class wraps a Highcharts stock chart component. It provides properties for controlling
 * some additional features that are commonly used in our app. The config object format is the
 * same as Highcharts uses. We do provide some config defaults that are more suitable for our
 * application. For more information, see: https://www.highcharts.com/blog/highstock/
 */
const StockChart = React.forwardRef<HighchartsReactRefObject, StockChartProps>(
  (
    {
      onExtremesChange,
      isLoading = false,
      dateFrom = -1,
      dateTo = -1,
      config,
      seriesIds,
      hasError = false,
      containerWidth = 0,
      hasPointsOverflow = false,
      enableAnimation = false,
      noSeriesText = T.graphs.nosignalsfound,
      containerProps = STOCKCHART_CONTAINER_PROPS
    },
    forwardRef
  ) => {
    const ref = useRef<HighchartsReactRefObject>();

    if (
      config != null &&
      config.series != null &&
      seriesIds != null &&
      config.series.length !== seriesIds.length
    ) {
      console.error('Series IDs length does not match series length');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onAfterSetExtremes = useCallback(
      _.debounce((event) => {
        const { min, max, trigger } = event;

        if (!min || !max || trigger == null) {
          return;
        }

        onExtremesChange(
          min,
          max,
          trigger === 'zoom' || trigger === 'navigator'
        );
      }, 1),
      [onExtremesChange, ref]
    );

    useEffect(() => {
      if (ref.current && dateFrom !== -1 && dateTo !== -1) {
        ref.current.chart.xAxis[0].setExtremes(dateFrom, dateTo);
      }
    }, [dateFrom, dateTo]);

    // Highcharts does not retain the series visibility state when the chart is zoomed.
    // To keep track of it, we store the visibility state in a ref so that series with
    // the same ID retain their visibility state throughout zoom changes. We use a ref
    // instead of state since we don't want to trigger an unnecessary re-render when
    // Highcharts updates the visibility state internally - we only want to keep the
    // previous visibility state when the view re-renders.
    const chartVisibilityState = useRef<Record<string, boolean>>({});

    const onVisibilityChange: SeriesVisibilityChangeFunction = useCallback(
      (a, show) => {
        if (seriesIds != null) {
          chartVisibilityState.current[seriesIds[a.index]] = show;
        } else {
          console.error(
            'Got visibility change but no series IDs were provided'
          );
        }
      },
      [seriesIds]
    );

    const [internalConfig, setInternalConfig] = useState(() =>
      generateConfig(
        config,
        onAfterSetExtremes,
        onVisibilityChange,
        seriesIds,
        chartVisibilityState.current,
        enableAnimation
      )
    );

    useEffect(() => {
      setInternalConfig(
        generateConfig(
          config,
          onAfterSetExtremes,
          onVisibilityChange,
          seriesIds,
          chartVisibilityState.current,
          enableAnimation
        )
      );
    }, [
      onAfterSetExtremes,
      config,
      onVisibilityChange,
      chartVisibilityState,
      seriesIds,
      enableAnimation
    ]);

    useEffect(() => {
      if (ref.current) {
        const chart = ref.current.chart;
        chart.reflow();
      }
    }, [containerWidth]);

    // We use the loading text as a generic label mechanism for displaying information.
    useEffect(() => {
      if (ref.current) {
        const chart = ref.current.chart;

        showChartStatus({
          hasError,
          chart,
          hasPointsOverflow,
          isLoading,
          config,
          noSeriesText
        });
      }
    }, [ref, isLoading, hasError, config, hasPointsOverflow, noSeriesText]);

    const assignRef = useCallback(
      (value: HighchartsReactRefObject) => {
        if (forwardRef) {
          if (typeof forwardRef === 'function') {
            forwardRef(value);
          } else if (forwardRef) {
            forwardRef.current = value;
          }
        }
        ref.current = value;
      },
      [forwardRef]
    );

    return (
      <HighchartsReact
        options={internalConfig}
        highcharts={Highcharts}
        ref={assignRef}
        constructorType={'stockChart'}
        containerProps={containerProps}
      />
    );
  }
);

export default React.memo(StockChart);
