import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';

import Modal from 'ecto-common/lib/Modal/Modal';
import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';
import styles from 'js/components/EditLocation/CreateLocationDialog.module.css';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import LocationTreeView from 'ecto-common/lib/LocationTreeView/LocationTreeView';
import { isEquipmentOfType } from 'ecto-common/lib/utils/equipmentTypeUtils';
import { getNodeFromMap } from 'ecto-common/lib/utils/locationUtils';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';
import { useAdminSelector } from 'js/reducers/storeAdmin';
import { SingleGridNode } from 'ecto-common/lib/types/EctoCommonTypes';
import { EquipmentResponseModel } from 'ecto-common/lib/API/APIGen';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';

const searchFilter = { searchTerm: '', tags: [] as string[] };

interface SelectDeviceDialogProps {
  onModalClose: () => void;
  isOpen: boolean;
  onChangedSelectedEnergyManager(em: EquipmentResponseModel): void;
  selectedEnergyManagerEquipmentId?: string;
  onConfirmClick(): void;
  initialNode?: SingleGridNode;
}

const SelectDeviceDialog = ({
  onModalClose,
  isOpen,
  onConfirmClick,
  initialNode,
  onChangedSelectedEnergyManager,
  selectedEnergyManagerEquipmentId
}: SelectDeviceDialogProps) => {
  const params = useParams<NodeParams>();
  const nodeMap = useAdminSelector((state) => state.general.nodeMap);
  const nodeTree = useAdminSelector((state) => state.general.nodeTree);
  const equipmentMap = useAdminSelector((state) => state.general.equipmentMap);
  const equipmentTypes = useAdminSelector(
    (state) => state.general.equipmentTypes
  );

  const nodeId = useAdminSelector((state) => state.general.nodeId);
  let focusedId = null;

  if (initialNode) {
    focusedId = selectedEnergyManagerEquipmentId || initialNode.nodeId;
  }

  const parent = useMemo(() => {
    if (selectedEnergyManagerEquipmentId) {
      const equipment = getNodeFromMap(
        equipmentMap,
        selectedEnergyManagerEquipmentId
      );
      return getNodeFromMap(nodeMap, equipment?.nodeId);
    }

    return null;
  }, [nodeMap, equipmentMap, selectedEnergyManagerEquipmentId]);

  const selectedIds = useMemo(() => {
    if (parent != null) {
      return [selectedEnergyManagerEquipmentId];
    }

    return _.compact([selectedEnergyManagerEquipmentId || nodeId]);
  }, [nodeId, parent, selectedEnergyManagerEquipmentId]);

  const _onSelectedChanged = useCallback(
    (selectedId: string) => {
      const em = getNodeFromMap(equipmentMap, selectedId);
      if (em) {
        if (em.equipmentId === selectedEnergyManagerEquipmentId) {
          onChangedSelectedEnergyManager(null);
        } else {
          onChangedSelectedEnergyManager(em);
        }
      }
    },
    [
      equipmentMap,
      onChangedSelectedEnergyManager,
      selectedEnergyManagerEquipmentId
    ]
  );

  return (
    <Modal
      className={styles.deviceDialog}
      onModalClose={onModalClose}
      isOpen={isOpen}
    >
      <ModalHeader titleIcon={Icons.Connect}>
        {T.admin.selectdevice.title}
      </ModalHeader>

      <ModalBody className={styles.deviceSettingBody}>
        <LocationTreeView
          selectEquipment
          equipmentFilter={(e) =>
            isEquipmentOfType(
              e.equipmentTypeId,
              'EnergyManager',
              equipmentTypes
            )
          }
          selectedIds={selectedIds}
          focusedId={focusedId ?? params?.nodeId}
          sidePadding={10}
          className={styles.treeView}
          searchFilter={searchFilter}
          onChangeSelectedState={_onSelectedChanged}
          nodeTree={nodeTree}
          nodeMap={nodeMap}
          equipmentMap={equipmentMap}
        />
      </ModalBody>
      <ModalFooter>
        <LocalizedButtons.Done onClick={onConfirmClick} />
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(SelectDeviceDialog);
