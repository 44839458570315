import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import _ from 'lodash';
import { adminRoutes } from 'js/containers/AdminRoutes';

import Layout from 'ecto-common/lib/Layout/Layout';
import ContentArea from 'ecto-common/lib/Layout/ContentArea/ContentArea';
import ToastContainer from 'ecto-common/lib/Toast/ToastContainer';
import BaseContainer from 'ecto-common/lib/BaseContainer/BaseContainer';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import {
  REQ_STATE_ERROR,
  REQ_STATE_PENDING,
  REQ_STATE_SUCCESS,
  RequestStatusRawProp
} from 'ecto-common/lib/utils/requestStatus';
import { useDispatchResultRequest } from 'ecto-common/lib/hooks/useDispatchPromise';
import API from 'ecto-common/lib/API/API';
import { cancellablePromiseList } from 'ecto-common/lib/API/API';
import T from 'ecto-common/lib/lang/Language';
import { AuthenticationErrorComponent } from 'ecto-common/lib/AuthenticationWrapper/AuthenticationWrapper';

import { setEquipmentGroupTemplates } from 'js/actions/getEquipmentGroupTemplates';

import { setEnums } from 'ecto-common/lib/actions/getEnums';

import styles from './MainContainer.module.css';
import useAuthentication, {
  AuthenticatedArea
} from 'ecto-common/lib/hooks/useAuthentication';
import { setSignalTypes } from 'ecto-common/lib/actions/setSignalTypes';
import { setSignalTypeFolders } from 'ecto-common/lib/actions/setSignalTypeFolders';
import LoadingScreenWithMenu from 'ecto-common/lib/LoadingScreen/LoadingScreenWithMenu';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import TenantContainer from 'ecto-common/lib/Application/TenantContainer';
import UserContext from 'ecto-common/lib/hooks/UserContext';
import { setEquipmentTypes } from 'ecto-common/lib/actions/getEquipmentTypes';
import { setNodes, useNodeCacheUpdate } from 'ecto-common/lib/actions/getNodes';
import { setNodeTags } from 'ecto-common/lib/actions/getNodeTags';
import { setSignalTemplates } from 'js/actions/getSignalTemplates';
import { useAdminDispatch, useAdminSelector } from 'js/reducers/storeAdmin';
import locationChange from 'ecto-common/lib/actions/locationChange';
import { useLocation } from 'react-router';
import { CACHE_KEY_NODES } from 'ecto-common/lib/utils/cacheKeys';
import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import { ResourceType } from 'ecto-common/lib/constants/index';
import { getApiEnvironment } from 'ecto-common/lib/utils/apiEnvironment';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { AuthError } from '@azure/msal-browser';

const getSignalTemplatesPromise = (contextSettings: ApiContextSettings) => {
  return cancellablePromiseList([
    API.Admin.Alarms.getAlarmSignalTemplates(contextSettings),
    API.Admin.Equipments.getEquipmentTemplates(contextSettings)
  ] as const);
};

export function getNodesPromise(
  contextSettings: ApiContextSettings,
  _tenantId: string
) {
  return cancellablePromiseList([
    API.Admin.Nodes.getAllNodes(contextSettings),
    API.Nodes.getGrids(contextSettings)
  ] as const);
}

const CoreResourceWrapper = React.memo(() => {
  const apiEnvironment = getApiEnvironment();

  const nodeTree = useAdminSelector((state) => state.general.nodeTree);
  const { userId } = useContext(UserContext);
  const { isAuthenticated, instance, currentAccount, isLoggingOut } =
    useAuthentication(apiEnvironment.scopes.gateway);
  const { tenantId } = useContext(TenantContext);

  const [hasError, setHasError] = useState(false);

  useNodeCacheUpdate();

  const [isLoadingEnums, loadEnums] = useDispatchResultRequest(
    API.Enums.getEnums,
    setEnums,
    setHasError
  );
  const [, loadSignalTemplates] = useDispatchResultRequest(
    getSignalTemplatesPromise,
    setSignalTemplates,
    setHasError
  );
  const [isLoadingNode, loadNodes] = useDispatchResultRequest(
    getNodesPromise,
    setNodes,
    setHasError,
    CACHE_KEY_NODES
  );
  const [isLoadingNodeTags, loadNodeTags] = useDispatchResultRequest(
    API.Admin.Nodes.getNodeTags,
    setNodeTags,
    setHasError
  );
  const [, loadEquipmentGroupTemplates] = useDispatchResultRequest(
    API.Admin.Templates.getBuildings,
    setEquipmentGroupTemplates,
    setHasError
  );
  const [isLoadingEquipmentTypes, loadEquipmentTypes] =
    useDispatchResultRequest(
      API.Admin.Equipments.getEquipmentTypes,
      setEquipmentTypes,
      setHasError
    );
  const [isLoadingSignalTypes, loadSignalTypes] = useDispatchResultRequest(
    API.Admin.SignalTypes.getAllSignalTypes,
    setSignalTypes,
    setHasError
  );
  const [isLoadingSignalTypeFolders, loadSignalTypeFolders] =
    useDispatchResultRequest(
      API.Admin.SignalTypeFolders.getAllSignalTypeFolders,
      setSignalTypeFolders,
      setHasError
    );

  useEffect(() => {
    /**
     * Make sure that account is logged in
     */
    if (userId && tenantId) {
      loadEnums();
      // Add tenant ID as explicit argument so that it can easily be used as cache key. It is
      // used as implicit parameter in the headers. Use the same tenant ID for all fetches
      // since BE does not implement per-tenant node access. Speeds things up while
      // using a single storage for all nodes.
      loadNodes(tenantId);
      loadNodeTags();
      loadEquipmentTypes();
      loadSignalTemplates();
      loadEquipmentGroupTemplates();
      loadSignalTypes();
      loadSignalTypeFolders();
    }
  }, [
    tenantId,
    loadEquipmentTypes,
    loadNodeTags,
    loadNodes,
    loadEnums,
    loadSignalTemplates,
    loadEquipmentGroupTemplates,
    loadSignalTypes,
    userId,
    loadSignalTypeFolders
  ]);

  useEffect(() => {
    if (hasError && isAuthenticated) {
      toastStore.addErrorToast(T.common.baserequesterror);
    } else if (!isAuthenticated) {
      setHasError(false);
    }
  }, [hasError, isAuthenticated, setHasError]);

  const isLoading =
    isLoadingNodeTags ||
    isLoadingEquipmentTypes ||
    (isLoadingNode && nodeTree.length === 0) ||
    isLoadingEnums ||
    isLoadingSignalTypes ||
    isLoadingSignalTypeFolders;

  if (isLoading) {
    return <LoadingScreenWithMenu isLoading />;
  } else if (currentAccount && !isLoggingOut) {
    return (
      <BaseContainer
        routes={adminRoutes}
        msalConfiguration={instance}
        currentAccount={currentAccount}
        allowNoLocation
      />
    );
  }

  return null;
});

const Container = React.memo(() => {
  const apiEnvironment = getApiEnvironment();
  const {
    isLoading: authenticationIsLoading,
    errorMessage,
    instance,
    currentAccount,
    isLoggingOut
  } = useAuthentication(apiEnvironment.scopes.gateway);
  const { isLoadingTenants, tenantsFailedToLoad, tenantResources } =
    useContext(TenantContext);
  const isLoading = authenticationIsLoading || isLoadingTenants;

  let _errorMessage = errorMessage;

  if (tenantsFailedToLoad) {
    _errorMessage = AuthError.createUnexpectedError(
      T.tenants.error.failedtoload
    );
  }

  let content: React.ReactNode = null;

  if (isLoading) {
    content = <LoadingScreenWithMenu isLoading />;
  } else if (hasAccessToResource(ResourceType.CORE, tenantResources)) {
    content = <CoreResourceWrapper />;
  } else if (currentAccount && !isLoggingOut) {
    content = (
      <BaseContainer
        routes={adminRoutes}
        msalConfiguration={instance}
        currentAccount={currentAccount}
      />
    );
  }

  return (
    <>
      {!_errorMessage ? (
        <div className={styles.baseContainer}>{content}</div>
      ) : (
        <AuthenticationErrorComponent error={_errorMessage} />
      )}
    </>
  );
});

const MainContentArea = React.memo(() => {
  const { tenantId } = useContext(TenantContext);

  return (
    <ContentArea>
      <Switch>
        <Route path="*">
          <Container key={tenantId} />
        </Route>
      </Switch>

      <ToastContainer />
    </ContentArea>
  );
});

const useReqStateToast = () => {
  const reqState = useAdminSelector((state) => state.reqState);
  const prevReqState = useRef(_.cloneDeep(reqState));

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const nextReqState: any = reqState;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _prevReqState: any = prevReqState.current;

    Object.keys(nextReqState).forEach((key) => {
      if (
        nextReqState[key].statusText &&
        nextReqState[key].statusText !== _prevReqState[key].statusText
      ) {
        if (nextReqState[key].state === REQ_STATE_SUCCESS) {
          toastStore.addSuccessToast(nextReqState[key].statusText);
        } else if (nextReqState[key].state === REQ_STATE_ERROR) {
          toastStore.addErrorToast(nextReqState[key].statusText);
        }
      }
    });
    prevReqState.current = _.cloneDeep(reqState);
  }, [reqState]);

  return useMemo(() => {
    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.values(reqState as unknown as RequestStatusRawProp<any>[]).filter(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (request: RequestStatusRawProp<any>) =>
          request.state === REQ_STATE_PENDING && request.blocking
      ).length !== 0
    );
  }, [reqState]);
};

const MainContainer = () => {
  const isBlocking = useReqStateToast();
  const dispatch = useAdminDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(locationChange(location));
  }, [dispatch, location]);

  return (
    <AuthenticatedArea>
      <TenantContainer>
        <Layout disabled={isBlocking} contentArea={<MainContentArea />} />
      </TenantContainer>
    </AuthenticatedArea>
  );
};

export default React.memo(MainContainer);
