import { useHistory } from 'react-router';
import { getSignalProvidersPage } from './commonLinkUtil';
import { useCallback, useContext } from 'react';
import TenantContext from '../hooks/TenantContext';
import _ from 'lodash';
import { NodeResponseModel } from '../API/APIGen';
import { ProcessMapDropdownShowMenuCallback } from '../ProcessMap/useProcessMapDropdown';
import sortByLocaleCompare from './sortByLocaleCompare';

const useEquipmentLinkAction = ({
  node,
  showMenu,
  hideMenu
}: {
  node: NodeResponseModel;
  showMenu: ProcessMapDropdownShowMenuCallback;
  hideMenu: () => void;
}) => {
  const history = useHistory();
  const { contextSettings } = useContext(TenantContext);

  return useCallback(
    (event: MouseEvent, equipmentTypeId: string) => {
      const equipments = _.filter(node.equipments, {
        equipmentTypeId: equipmentTypeId
      });

      if (equipments.length === 1) {
        // open equipment page directly if only one equipment matches
        history.push(
          getSignalProvidersPage(
            contextSettings.tenantId,
            node.nodeId,
            equipments[0].equipmentId
          )
        );
      } else {
        const sortedEquipment = sortByLocaleCompare(equipments, 'name');
        showMenu(
          sortedEquipment.map((equipment) => ({
            label: equipment.name,
            action: () => {
              hideMenu();
              history.push(
                getSignalProvidersPage(
                  contextSettings.tenantId,
                  node.nodeId,
                  equipment.equipmentId
                )
              );
            }
          })),
          {
            x: event.clientX,
            y: event.clientY
          }
        );
      }
    },
    [
      node.equipments,
      node.nodeId,
      history,
      contextSettings.tenantId,
      showMenu,
      hideMenu
    ]
  );
};

export default useEquipmentLinkAction;
