import React, { useCallback, useEffect, useState } from 'react';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import API from 'ecto-common/lib/API/API';
import { KeyValueFixedSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueFixedSelectableInput';
import _ from 'lodash';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import Icons from 'ecto-common/lib/Icons/Icons';
import styles from './ModelEditorSignal.module.css';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import T from 'ecto-common/lib/lang/Language';
import SelectSignalsDialog from 'ecto-common/lib/SelectSignalsDialog/SelectSignalsDialog';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { FullSignalProviderResponseModel } from 'ecto-common/lib/API/APIGen';
import { ChartSignal } from 'ecto-common/lib/SignalSelector/ChartUtils';
import UUID from 'uuidjs';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { ModelDefinitionInternal } from 'ecto-common/lib/ModelForm/ModelPropType';

export const formattedSignalNameWithProvider = (signal: ChartSignal) => {
  if (signal != null) {
    return signal.group.signalProviderName + ' - ' + signal.item.name;
  }
  return '';
};

export type SignalModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object
> = {
  modelType: typeof ModelType.SIGNAL;
  validSignalTypeIds?: string[];
  selectFromCurrentNodeOnly?: boolean;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, string>;

type ModelEditorSignalProps = ModelEditorProps & {
  model: SignalModelDefinition<object, object>;
};

const ModelEditorSignal = ({
  model,
  disabled,
  rawValue,
  hasError,
  updateItem
}: ModelEditorSignalProps) => {
  const [dialogIsOpen, showDialog, hideDialog] = useDialogState(false);
  const [selectedSignal, setSelectedSignal] = useState<ChartSignal>(null);
  const [hasLoadError, setHasLoadError] = useState(false);
  const [signalCache, setSignalCache] = useState<Record<string, ChartSignal>>(
    {}
  );
  const isAdmin = useCommonSelector((state) => state.general.isAdmin);
  const nodeId = useCommonSelector((state) => state.general.nodeId);

  const [getSignalDataIsLoading, getSignalData, cancelGetSignalData] =
    usePromiseCall({
      promise: isAdmin
        ? API.Admin.Signals.getProvidersBySignalIds
        : API.Signals.getProvidersBySignalIds,
      onSuccess: (data: FullSignalProviderResponseModel[]) => {
        const provider = _.head(data);
        setSelectedSignal({
          item: _.head(provider.signals),
          chartSignalId: UUID.generate(),
          group: _.omit(provider, 'signals'),
          parent: null
        });
      },
      onError: () => {
        setHasLoadError(true);
      }
    });

  useEffect(() => {
    if (rawValue != null) {
      setHasLoadError(false);

      if (signalCache[rawValue] != null) {
        setSelectedSignal(signalCache[rawValue]);
      } else {
        setSelectedSignal(null);
        getSignalData([rawValue]);

        return () => {
          cancelGetSignalData();
        };
      }
    } else {
      setSelectedSignal(null);
    }
  }, [rawValue, signalCache, cancelGetSignalData, getSignalData]);

  let valueLabel;
  if (hasLoadError) {
    valueLabel = T.modeleditor.failedtoloadsignaldata;
  } else {
    valueLabel = formattedSignalNameWithProvider(selectedSignal);
  }

  const content = rawValue && !getSignalDataIsLoading && (
    <div className={styles.valueContainer}>
      <Icons.Signal /> <div>{valueLabel}</div>
    </div>
  );

  const placeholderContent = (
    <div className={styles.valueContainer}>
      <Icons.Signal />{' '}
      {getSignalDataIsLoading ? T.common.loading : model.placeholder}
    </div>
  );
  const updateValue = useCallback(
    (selectedSignals: ChartSignal[]) => {
      const signal = _.head(selectedSignals);
      const signalId = signal?.item?.signalId;

      if (signalId != null) {
        setSignalCache((oldCache) => ({
          ...oldCache,
          [signalId]: signal
        }));
      }

      updateItem(signalId);
      hideDialog();
    },
    [hideDialog, updateItem]
  );

  return (
    <>
      <KeyValueFixedSelectableInput
        disabled={disabled}
        keyText={model.label}
        value={content}
        isLoading={getSignalDataIsLoading}
        placeholder={placeholderContent}
        onClick={showDialog}
        hasError={hasError}
        isDisabled={getSignalDataIsLoading}
      />
      <SelectSignalsDialog
        isOpen={dialogIsOpen}
        nodeId={nodeId}
        onModalClose={hideDialog}
        onSignalsSelected={updateValue}
        isSingleSelect
        validSignalTypeIds={model.validSignalTypeIds}
        selectedSignals={_.compact([selectedSignal])}
        selectFromCurrentNodeOnly={model.selectFromCurrentNodeOnly}
      />
    </>
  );
};

export default React.memo(ModelEditorSignal);
