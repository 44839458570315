import React, { Dispatch, SetStateAction } from 'react';
import { UnitResponseModel } from 'ecto-common/lib/API/APIGen';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import T from 'ecto-common/lib/lang/Language';
import { GraphMinMaxSettings } from 'ecto-common/lib/types/EctoCommonTypes';
import {
  parseLocaleNumber,
  numberLocaleFormatter
} from 'ecto-common/lib/utils/stringUtils';

type GraphMinMaxTableProps = {
  units: UnitResponseModel[];
  minMaxSettings: Record<string, GraphMinMaxSettings>;
  onMinMaxSettingsChanged: (
    newSettings: Record<string, GraphMinMaxSettings>
  ) => void;
  onConfirmMinMaxSettings: (
    newSettings: Record<string, GraphMinMaxSettings>
  ) => void;
  setHasChanges: Dispatch<SetStateAction<boolean>>;
};

export const GraphMinMaxSettingsTable = React.memo(
  ({
    units,
    minMaxSettings,
    onMinMaxSettingsChanged,
    onConfirmMinMaxSettings,
    setHasChanges
  }: GraphMinMaxTableProps) => {
    const minMaxDataFormatter = (propertyName: 'min' | 'max') => {
      return (_unused: unknown, unit: UnitResponseModel) => {
        let value = minMaxSettings?.[unit.id]?.[propertyName];
        const commitValue = (newValue: string) => {
          let newNum = parseLocaleNumber(newValue);
          if (isNaN(newNum)) {
            newNum = null;
          }

          const newMinMaxSettings = {
            ...minMaxSettings,
            [unit.id]: {
              ...minMaxSettings?.[unit.id],
              [propertyName]: newNum
            }
          };

          onMinMaxSettingsChanged(newMinMaxSettings);
          return newMinMaxSettings;
        };

        return (
          <TextInput
            clearButton
            onChange={() => {
              setHasChanges(true);
            }}
            onClear={() => {
              commitValue('');
              setHasChanges(true);
            }}
            onEnterKeyPressed={(e) => {
              const newSettings = commitValue(
                (e.target as HTMLInputElement).value
              );
              onConfirmMinMaxSettings(newSettings);
            }}
            onBlur={(e) => {
              commitValue(e.target.value);
            }}
            textFormatter={numberLocaleFormatter}
            placeholder={T.graphs.minmax.table.placeholder}
            value={value?.toString()}
          />
        );
      };
    };

    const columns: DataTableColumnProps<UnitResponseModel>[] = [
      {
        label: T.graphs.minmax.table.unit,
        dataKey: 'unit'
      },
      {
        label: T.graphs.minmax.table.min,
        dataKey: 'min',
        dataFormatter: minMaxDataFormatter('min')
      },
      {
        label: T.graphs.minmax.table.max,
        dataKey: 'max',
        dataFormatter: minMaxDataFormatter('max')
      }
    ];

    return <DataTable<UnitResponseModel> columns={columns} data={units} />;
  }
);
