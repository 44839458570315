/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ByteReadOnlyMemory {
  /** @format int32 */
  length?: number;
  isEmpty?: boolean;
  span?: ByteReadOnlySpan;
}

export interface ByteReadOnlySpan {
  /** @format int32 */
  length?: number;
  isEmpty?: boolean;
}

export enum ConfigurationFormat {
  String = 'String',
  Integer = 'Integer',
  Float = 'Float'
}

export interface ConfigurationValue {
  stringValue?: string | null;
  /** @format int64 */
  integerValue?: number | null;
  /** @format double */
  floatValue?: number | null;
}

export enum GraphicalRepresentation {
  Unknown = 'Unknown',
  Event = 'Event',
  Step = 'Step',
  Linear = 'Linear',
  Spline = 'Spline'
}

export interface IntegrationPointResponse {
  /**
   * Integration Point Identifier
   * @minLength 1
   */
  id: string;
  /** @minLength 1 */
  integrationProxyId: string;
  /** @minLength 1 */
  name: string;
  /**
   * Tenant identifier
   * @minLength 1
   */
  tenantId: string;
  nodeIds?: string[] | null;
  configuration?: Record<string, ConfigurationValue>;
  dataPoints?: Record<string, IntegrationPointResponseDataPoint>;
  /** Some proxies load some intital data at first execution. This can be like historical data. If this is true, initial data has been loaded. */
  initialDataLoaded?: boolean;
}

export interface IntegrationPointResponseDataPoint {
  /** @format uuid */
  signalId?: string;
  name?: string | null;
  description?: string | null;
  /** @format uuid */
  signalTypeId?: string;
  graphicalRepresentation?: GraphicalRepresentation;
}

export interface IntegrationPointResponseListResponse {
  items?: IntegrationPointResponse[] | null;
  continuationToken?: string | null;
  links?: Record<string, string>;
  /** @format int32 */
  total?: number | null;
}

export interface IntegrationProxyResponse {
  id?: string | null;
  name?: string | null;
  configuration?: IntegrationProxyResponseConfiguration[] | null;
  dataPoints?: IntegrationProxyResponseDataPoint[] | null;
}

export interface IntegrationProxyResponseConfiguration {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  name: string;
  format?: ConfigurationFormat;
  required?: boolean;
  /** @minLength 1 */
  regexFormat: string;
}

export interface IntegrationProxyResponseDataPoint {
  /** @minLength 1 */
  code: string;
  /** @minLength 1 */
  defaultName: string;
  /** @format uuid */
  signalTypeId?: string;
  required?: boolean;
  /** @minLength 1 */
  defaultDescription: string;
  graphicalRepresentation?: GraphicalRepresentation;
}

export interface IntegrationProxyResponseListResponse {
  items?: IntegrationProxyResponse[] | null;
  continuationToken?: string | null;
  links?: Record<string, string>;
  /** @format int32 */
  total?: number | null;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface VersionResponse {
  netVersion?: string | null;
  application?: string | null;
  commonWebLib?: string | null;
  /** @format date-time */
  applicationStarted?: string | null;
}

export interface ListIntegrationPointsParams {
  /** Continuation Token for pagination, leave empty for inital request. */
  continuationToken?: string;
  /**  Odata query filter */
  $filter?: string;
  /** @example 50 */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface ListIntegrationProxyiesParams {
  /** Continuation Token for pagination, leave empty for inital request. */
  continuationToken?: string;
  /**  Odata query filter */
  $filter?: string;
  /** @example 50 */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
}

export interface ListIntegrationPointsForIntegrationProxyParams {
  /** Continuation Token for pagination, leave empty for inital request. */
  continuationToken?: string;
  /**  Odata query filter */
  $filter?: string;
  /** @example 50 */
  $top?: number;
  /**
   * Order by
   * @example "name asc"
   */
  $orderby?: string;
  proxyId: string;
}

/**
 * @title General Integration API
 * @version general
 */

import { APIGenType } from './APIGenType';
import {
  ContentType,
  Method,
  apiEndpointEmpty,
  apiEndpointEmptyUsingQueryParams,
  apiEndpointEmptyWithPath,
  apiEndpointEmptyWithPathUsingQueryParams
} from './APIUtils';

const apiTitle = APIGenType.IntegrationAPIGen;

export interface GetIntegrationPointPath {
  pointId: string;
}

export interface GetIntegrationProxyPath {
  proxyId: string;
}

export interface ListIntegrationPointsForIntegrationProxyPath {
  proxyId: string;
}

const IntegrationAPIGen = {
  IntegrationPoints: {
    getIntegrationPoint: apiEndpointEmptyWithPath<
      IntegrationPointResponse,
      ProblemDetails,
      GetIntegrationPointPath
    >(
      Method.GET,
      ({ pointId }: GetIntegrationPointPath) => `/IntegrationPoints/${pointId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    listIntegrationPoints: apiEndpointEmptyUsingQueryParams<
      ListIntegrationPointsParams,
      IntegrationPointResponseListResponse,
      ProblemDetails
    >(
      Method.GET,
      `/IntegrationPoints`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  IntegrationProxies: {
    getIntegrationProxy: apiEndpointEmptyWithPath<
      IntegrationProxyResponse,
      ProblemDetails,
      GetIntegrationProxyPath
    >(
      Method.GET,
      ({ proxyId }: GetIntegrationProxyPath) =>
        `/IntegrationProxies/${proxyId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    listIntegrationProxyies: apiEndpointEmptyUsingQueryParams<
      ListIntegrationProxyiesParams,
      IntegrationProxyResponseListResponse,
      ProblemDetails
    >(
      Method.GET,
      `/IntegrationProxies`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    listIntegrationPointsForIntegrationProxy:
      apiEndpointEmptyWithPathUsingQueryParams<
        Omit<
          ListIntegrationPointsForIntegrationProxyParams,
          keyof ListIntegrationPointsForIntegrationProxyPath
        >,
        IntegrationPointResponseListResponse,
        ProblemDetails,
        ListIntegrationPointsForIntegrationProxyPath
      >(
        Method.GET,
        ({ proxyId }: ListIntegrationPointsForIntegrationProxyPath) =>
          `/IntegrationProxies/${proxyId}/integrationpoints`,
        apiTitle,
        ContentType.Json,
        ContentType.Json,
        ContentType.Json
      )
  },
  Version: {
    version: apiEndpointEmpty<VersionResponse, any>(
      Method.GET,
      `/Version`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  }
};

export default IntegrationAPIGen;
