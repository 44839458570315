import React, { useCallback, useEffect, useState } from 'react';
import Icons from 'ecto-common/lib/Icons/Icons';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import styles from 'ecto-common/lib/SignalTypePicker/SelectSignalTypeDialog.module.css';
import _ from 'lodash';
import SignalTypeTreeView from 'ecto-common/lib/SignalTypePicker/SignalTypeTreeView';
import T from 'ecto-common/lib/lang/Language';
import Button from 'ecto-common/lib/Button/Button';
import { CustomNodeTreeSet } from '../SelectNodeDialog/SelectNode';
import SelectSignalsDialog from 'ecto-common/lib/SelectSignalsDialog/SelectSignalsDialog';
import { ChartSignal } from 'ecto-common/lib/SignalSelector/ChartUtils';
import { SignalProviderSignalResponseModel } from 'ecto-common/lib/API/APIGen';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';

interface SelectSignalTypeDialogProps {
  isOpen?: boolean;
  onModalClose: () => void;
  onChangeSignalTypeId?(signalTypeId: string): void;
  signalTypeId?: string;
  selectFolder?: boolean;
  selectOnlyFolders?: boolean;
  customNodeTreeSet?: CustomNodeTreeSet;
  nodeId?: string;
}

const SelectSignalTypeDialog = ({
  isOpen,
  onModalClose,
  signalTypeId,
  onChangeSignalTypeId,
  selectFolder,
  selectOnlyFolders,
  nodeId,
  customNodeTreeSet = null
}: SelectSignalTypeDialogProps) => {
  const [selectedNodes, setSelectedNodes] = useState<Record<string, boolean>>(
    {}
  );
  const [isShowingSignalSelector, showSignalSelector, hideSignalSelector] =
    useDialogState(false);
  const [searchFilter, setSearchFilter] = useState('');

  useEffect(() => {
    if (isOpen) {
      const newSelectedNodes =
        signalTypeId != null ? { [signalTypeId]: true } : {};
      setSelectedNodes(newSelectedNodes);
    } else {
      setSearchFilter('');
    }
  }, [isOpen, signalTypeId]);

  const onConfirm = useCallback(() => {
    onChangeSignalTypeId(_.head(_.keys(selectedNodes)));
    onModalClose();
  }, [onChangeSignalTypeId, selectedNodes, onModalClose]);

  const hasSignal = useCallback(
    (array: ChartSignal[], signal: SignalProviderSignalResponseModel) => {
      const inCurrentSelection = _.some(
        array,
        (otherSignal) => otherSignal.item.signalTypeId === signal.signalTypeId
      );
      const inCurrentSignals = signal.signalTypeId === signalTypeId;

      return inCurrentSelection || inCurrentSignals;
    },
    [signalTypeId]
  );

  const onSignalsSelected = useCallback(
    (selectedSignals: ChartSignal[]) => {
      if (selectedSignals.length > 0) {
        const newSelectedNodes = {
          [selectedSignals[0].item.signalTypeId]: true
        };
        setSelectedNodes(newSelectedNodes);
      } else {
        setSelectedNodes({});
      }

      hideSignalSelector();
    },
    [hideSignalSelector]
  );

  const title = selectOnlyFolders
    ? T.admin.signaltypes.selectfolder
    : T.admin.signaltypes.selecttypedialogtitle;

  return (
    <ActionModal
      title={title}
      headerIcon={Icons.Edit}
      isOpen={isOpen}
      onModalClose={onModalClose}
      onConfirmClick={onConfirm}
      messageBodyClassName={styles.body}
      withSearchField
      searchText={searchFilter}
      onSearchTextChanged={setSearchFilter}
      leftSideButton={
        <Button onClick={showSignalSelector}>
          <Icons.Signal /> {T.admin.signaltypes.addfromexisting}
        </Button>
      }
    >
      <SignalTypeTreeView
        key={isOpen.toString()}
        selectedNodes={selectedNodes}
        setSelectedNodes={setSelectedNodes}
        searchFilter={searchFilter}
        selectFolder={selectFolder}
        selectOnlyFolders={selectOnlyFolders}
      />
      <SelectSignalsDialog
        isOpen={isShowingSignalSelector}
        onModalClose={hideSignalSelector}
        onSignalsSelected={onSignalsSelected}
        selectedSignals={[]}
        nodeId={nodeId}
        hasSignal={hasSignal}
        title={T.admin.dashboards.forms.signalnames.modaltitle}
        showSignalType
        customNodeTreeSet={customNodeTreeSet}
      />
    </ActionModal>
  );
};

export default React.memo(SelectSignalTypeDialog);
