import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useContext
} from 'react';
import { useParams } from 'react-router';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

import { getRawEquipmentName } from 'ecto-common/lib/utils/equipmentTypeUtils';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import NavLinkFix from 'ecto-common/lib/NavLinkFix/NavLinkFix';
import T from 'ecto-common/lib/lang/Language';
import {
  AddOrUpdateEquipmentSignalProviderTemplateRequestModel,
  AddOrUpdateEquipmentTypeRequestModel,
  SignalProviderType
} from 'ecto-common/lib/API/APIGen';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';
import HelpPaths from 'ecto-common/help/tocKeys';
import API from 'ecto-common/lib/API/API';

import { patchSignalTemplates } from 'js/actions/getSignalTemplates';
import { transformModbusDataTypes } from 'js/components/ModbusLayout/ModbusEditUtils';
import { signalProviderInputs } from 'js/components/ManageTemplates/signalInputs';
import EditSignalProviderType from 'js/components/ManageTemplates/EditSignalProviderType/EditSignalProviderType';
import {
  TemplateManagementParams,
  getTemplateManagementRoute
} from 'js/utils/routeConstants';
import { hasFalsyProperty } from 'ecto-common/lib/utils/functional';
import HttpStatus from 'ecto-common/lib/utils/HttpStatus';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import {
  patchEquipmentTypes,
  setEquipmentTypes
} from 'ecto-common/lib/actions/getEquipmentTypes';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import { AlarmOrEqTemplate } from 'js/components/ManageTemplates/manageTemplatesTypes';
import {
  EquipmentSignalProviderTemplateResponseModel,
  EquipmentTypeResponseModel
} from 'ecto-common/lib/API/APIGen';

const signalInputProvider = signalProviderInputs[SignalProviderType.Equipment];

const hideSignalProperties = [
  'signalSettings.signalFunction',
  'signalSettings.defaultValue',
  'dataFormat',
  'signalDirection',
  'graphicalRepresentation',
  'signalCategoryIds'
];

const EditEquipmentSignalTemplate = () => {
  const params = useParams<TemplateManagementParams>();
  const dispatch = useAdminDispatch();
  const equipmentTypes = useAdminSelector(
    (state) => state.general.equipmentTypes
  );

  const [editedSignalTemplates, setEditedSignalTemplates] =
    useState<AlarmOrEqTemplate[]>(null);
  const [templateName, setTemplateName] = useState(
    getRawEquipmentName(params?.itemId, equipmentTypes)
  );
  const [originalTemplateName, setOriginalTemplateName] = useState(
    getRawEquipmentName(params?.itemId, equipmentTypes)
  );
  const [selectedTemplate, setSelectedTemplate] =
    useState<EquipmentSignalProviderTemplateResponseModel>(null);
  const history = useHistory();
  const { tenantId } = useContext(TenantContext);

  const [getTemplateIsLoading, getTemplates] = usePromiseCall({
    promise: API.Admin.Templates.getEquipmentByEquipmentTypeId,
    initiallyLoading: true,
    onSuccess: (response) => {
      const existingTemplate = response?.[0];
      setSelectedTemplate(
        existingTemplate ?? {
          equipmentTypeId: params.itemId,
          signalTemplates: []
        }
      );
    },
    onError: () => {
      toastStore.addErrorToast(
        T.admin.equipmenttypes.failed.to.fetch.templates
      );
    }
  });

  useEffect(() => {
    getTemplates(params.itemId);
  }, [getTemplates, params?.itemId]);

  const prevSubPageRef = useRef(undefined);
  useEffect(() => {
    if (params.subPage !== prevSubPageRef.current) {
      setTemplateName(originalTemplateName);
      setEditedSignalTemplates(null);

      prevSubPageRef.current = params.subPage;
    }
  }, [originalTemplateName, params?.subPage]);

  const getOutput = useCallback(() => {
    if (editedSignalTemplates == null) {
      return { ...selectedTemplate };
    }

    const signalTemplates = _.cloneDeep(editedSignalTemplates);
    transformModbusDataTypes(signalTemplates);
    const equipmentTypeId = params.itemId;

    if (selectedTemplate) {
      return { ...selectedTemplate, signalTemplates, equipmentTypeId };
    }

    return { signalTemplates, equipmentTypeId };
  }, [editedSignalTemplates, params.itemId, selectedTemplate]);

  // TODO: Should we block the entire UI (with new blocking mechanism, e.g not using the req blocking) until we have
  //  patched the equipment templates?
  const [isSavingTemplate, saveTemplateCall] = usePromiseCall({
    promise: API.Admin.Templates.updateEquipment,
    onSuccess: (result) => {
      const savedEquipmentTemplate = _.head(result);
      setSelectedTemplate({ ...savedEquipmentTemplate });
      setEditedSignalTemplates(null);
      dispatch(patchSignalTemplates([savedEquipmentTemplate]));
      toastStore.addSuccessToast(T.admin.equipmenttypes.updated);
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.equipmenttemplates.error.updatetemplate);
    }
  });

  const saveTemplate = useCallback(() => {
    const output = getOutput();
    const invalidTemplates = _.filter(
      output.signalTemplates,
      hasFalsyProperty('signalTypeId', 'name')
    );

    if (invalidTemplates.length > 0) {
      toastStore.addErrorToast(T.admin.equipmenttemplates.error.missingfields);
    } else {
      // Cast due to optional fields in response model
      saveTemplateCall(
        output as AddOrUpdateEquipmentSignalProviderTemplateRequestModel
      );
    }
  }, [saveTemplateCall, getOutput]);

  const [isSavingDetails, saveDetailsCall] = usePromiseCall({
    promise: API.Admin.Templates.updateEquipmentType,
    onSuccess: (result: EquipmentTypeResponseModel[]) => {
      const savedEquipmentType = _.head(result);
      dispatch(patchEquipmentTypes([savedEquipmentType]));
      toastStore.addSuccessToast(T.admin.equipmenttypes.updated);
      setOriginalTemplateName(savedEquipmentType.name);
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.equipmenttemplates.error.updatetemplate);
    }
  });

  const saveDetails = useCallback(() => {
    const equipmentType = _.cloneDeep(
      _.find(equipmentTypes, ['equipmentTypeId', params.itemId])
    );

    _.merge(equipmentType, { name: templateName });
    // Cast due to EquipmentResponseType having optional fields
    saveDetailsCall(equipmentType as AddOrUpdateEquipmentTypeRequestModel);
  }, [saveDetailsCall, equipmentTypes, params.itemId, templateName]);

  const [isDeletingTemplate, deleteEquipmentTemplate] = usePromiseCall({
    promise: API.Admin.Templates.deleteEquipment,
    onSuccess: (unused, args) => {
      const { equipmentTypeIds } = args;
      toastStore.addSuccessToast(T.admin.templates.removed);
      const filteredEquipmentTypes = _.reject(equipmentTypes, {
        equipmentTypeId: equipmentTypeIds[0]
      });

      dispatch(setEquipmentTypes(filteredEquipmentTypes));
      history.replace(getTemplateManagementRoute(tenantId, 'equipments'));
    },
    onError: (e) => {
      if (e?.response?.status === HttpStatus.FORBIDDEN) {
        toastStore.addErrorToast(
          T.admin.templates.error.couldnotremoveconflict
        );
      } else {
        toastStore.addErrorToast(T.admin.templates.error.couldnotremove);
      }
    }
  });

  const deleteSignalTemplate = useCallback(async () => {
    deleteEquipmentTemplate({ equipmentTypeIds: [params.itemId] });
  }, [deleteEquipmentTemplate, params?.itemId]);

  const originalEquipmentName = useMemo(
    () => getRawEquipmentName(params.itemId, equipmentTypes),
    [equipmentTypes, params.itemId]
  );
  const isLoading =
    getTemplateIsLoading ||
    isDeletingTemplate ||
    isSavingDetails ||
    isSavingTemplate;

  const title = useMemo(
    () => (
      <span>
        <NavLinkFix to={`/${tenantId}/templateManagement/equipments`}>
          {T.admin.equipmentsignaltemplates.header}
        </NavLinkFix>{' '}
        &gt; {templateName}
      </span>
    ),
    [templateName, tenantId]
  );

  const onSignalsChanged = useCallback(
    (signalTemplates: AlarmOrEqTemplate[]) =>
      setEditedSignalTemplates(signalTemplates),
    []
  );

  const hasNoData = selectedTemplate == null;

  return (
    <EditSignalProviderType
      editedSignalTemplates={editedSignalTemplates}
      initialSignals={selectedTemplate?.signalTemplates}
      isLoading={isLoading}
      onDeleteTemplate={deleteSignalTemplate}
      onSaveDetails={saveDetails}
      onSaveTemplates={saveTemplate}
      onSignalsChanged={onSignalsChanged}
      onTemplateNameChanged={setTemplateName}
      originalName={originalEquipmentName}
      signalInputProvider={signalInputProvider}
      signalInputsToHideForTable={hideSignalProperties}
      signalProviderType={SignalProviderType.Equipment}
      templateName={templateName}
      title={title}
      hasNoData={hasNoData}
      helpPath={HelpPaths.docs.admin.templates.equipment_types}
    />
  );
};

export default EditEquipmentSignalTemplate;
