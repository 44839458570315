import React, { useCallback, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import { getEquipmentName } from 'ecto-common/lib/utils/equipmentTypeUtils';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';

import API from 'ecto-common/lib/API/API';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import HelpPaths from 'ecto-common/help/tocKeys';

import AddEquipmentTypeDialog from 'js/components/ManageTemplates/EquipmentSignalTemplates/AddEquipmentTypeDialog';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { setEquipmentTypes } from 'ecto-common/lib/actions/getEquipmentTypes';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import { EquipmentTypeResponseModel } from 'ecto-common/lib/API/APIGen';

interface EquipmentTypeListItemProps {
  equipmentTypeId?: string;
}

const EquipmentTypeListItem = ({
  equipmentTypeId
}: EquipmentTypeListItemProps) => {
  const equipmentTypes = useAdminSelector(
    (state) => state.general.equipmentTypes
  );

  return (
    <>
      <Icons.Equipment /> {getEquipmentName(equipmentTypeId, equipmentTypes)}{' '}
    </>
  );
};

const columns: DataTableColumnProps<EquipmentTypeResponseModel>[] = [
  {
    label: null,
    dataKey: 'equipmentTypeId',
    flexGrow: 1,
    width: 100,
    linkColumn: true,
    dataFormatter: (value: string) => (
      <EquipmentTypeListItem equipmentTypeId={value} />
    )
  }
];

const EquipmentTypes = () => {
  const [showingDialog, showDialog, hideDialog] = useDialogState(false);
  const dispatch = useAdminDispatch();
  const equipmentTypes = useAdminSelector(
    (state) => state.general.equipmentTypes
  );
  const history = useHistory();
  const { tenantId } = useContext(TenantContext);

  const [isLoading, addEquipmentType] = usePromiseCall({
    promise: API.Admin.Templates.updateEquipmentType,
    onSuccess: (updatedEquipmentTypes) => {
      hideDialog();
      dispatch(
        setEquipmentTypes([...equipmentTypes, ...updatedEquipmentTypes])
      );
      history.push(
        `/${tenantId}/templateManagement/equipments/${updatedEquipmentTypes[0].equipmentTypeId}`
      );
      toastStore.addSuccessToast(T.admin.requests.addequipmenttype.success);
    },
    onError: () => {
      toastStore.addErrorToast(T.admin.requests.addequipmenttype.failure);
    }
  });

  const onClickRow = useCallback(
    (equipment: EquipmentTypeResponseModel) => {
      history.push(
        `/${tenantId}/templateManagement/equipments/${equipment.equipmentTypeId}`
      );
    },
    [history, tenantId]
  );

  return (
    <ToolbarContentPage
      showLocationPicker={false}
      wrapContent={false}
      title={T.admin.equipmenttypes.title}
      addActionTitle={T.admin.equipmenttypes.add}
      addAction={showDialog}
      helpPath={HelpPaths.docs.admin.templates.equipment_types}
    >
      <AddEquipmentTypeDialog
        isLoading={isLoading}
        isOpen={showingDialog}
        onAddEquipmentType={addEquipmentType}
        onModalClose={hideDialog}
      />
      <DataTable<EquipmentTypeResponseModel>
        isLoading={false}
        disableHeader
        onClickRow={onClickRow}
        data={equipmentTypes}
        columns={columns}
      />
    </ToolbarContentPage>
  );
};

export default React.memo(EquipmentTypes);
