import React, { memo, useMemo, useCallback } from 'react';

import Modal from 'ecto-common/lib/Modal/Modal';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';

import ProcessMapsEditModalContent from './ProcessMapsEditModalContent';
import styles from './ProcessMapsEditModal.module.css';
import { ProcessMapResponseModel } from 'ecto-common/lib/API/APIGen';

interface ProcessMapsEditModalProps {
  isOpen: boolean;
  onModalClose: () => void;
  isEditing: boolean;
  selectedProcessMapId?: string;
  items?: Record<string, ProcessMapResponseModel>;
  isLoading?: boolean;
  setSelectedProcessMapId?(processMapId: string): void;
  reloadProcessMaps(newProcessMapId?: string): void;
}

const ProcessMapsEditModal = ({
  isOpen,
  onModalClose,
  isEditing,
  selectedProcessMapId,
  items,
  isLoading,
  setSelectedProcessMapId,
  reloadProcessMaps
}: ProcessMapsEditModalProps) => {
  const processMap = useMemo(
    () => items?.[selectedProcessMapId],
    [items, selectedProcessMapId]
  );
  const header = processMap
    ? T.admin.processmaps.edit
    : T.admin.processmaps.addmap;

  const resetSelectedProcessMap = useCallback(
    () => setSelectedProcessMapId(null),
    [setSelectedProcessMapId]
  );

  const _onModalClose = useCallback(() => {
    onModalClose();
    resetSelectedProcessMap();
  }, [onModalClose, resetSelectedProcessMap]);

  return (
    <Modal
      isOpen={isOpen}
      onModalClose={_onModalClose}
      className={styles.modal}
      disableClose={isLoading}
    >
      <ModalHeader titleIcon={isEditing ? Icons.Edit : Icons.Add}>
        {header}
      </ModalHeader>

      <ProcessMapsEditModalContent
        onModalClose={_onModalClose}
        isEditing={isEditing}
        selectedProcessMapId={selectedProcessMapId}
        items={items}
        reloadProcessMaps={reloadProcessMaps}
      />
    </Modal>
  );
};

export default memo(ProcessMapsEditModal);
