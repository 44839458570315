import { CancellablePromise } from 'ecto-common/lib/API/API';

const waitUntil = (then: number) => {
  let interval: ReturnType<typeof setInterval>;

  const promise = new Promise((resolve) => {
    interval = setInterval(() => {
      const now = new Date().getTime();

      if (now >= then) {
        clearInterval(interval);
        resolve();
      }
    }, 1000);
  }) as CancellablePromise<void>;

  promise.cancel = () => {
    clearInterval(interval);
  };

  return promise;
};

export default waitUntil;
