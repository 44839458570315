import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import IntegrationAPIGen, {
  IntegrationPointResponse
} from 'ecto-common/lib/API/IntegrationAPIGen';
import { useParams } from 'react-router';
import DataTableLoadMoreFooter from 'ecto-common/lib/DataTable/DataTableLoadMoreFooter';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import _ from 'lodash';
import DataTable from 'ecto-common/lib/DataTable/DataTable';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import AddNodeToIntegrationPointModal from 'js/components/Integrations/AddNodeToIntegrationPointModal';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { standardColumns } from 'ecto-common/lib/utils/dataTableUtils';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import IntegrationAdminAPIGen from 'ecto-common/lib/API/IntegrationAdminAPIGen';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { useQueryClient } from '@tanstack/react-query';

const columns: DataTableColumnProps<IntegrationPointResponse>[] = [
  {
    dataKey: 'name',
    label: T.admin.integrations.integrationpointname
  }
];

type EditIntegrationPointsForNodeProps = {
  isOpen: boolean;
  nodeId: string;
  onModalClose: () => void;
};

const EditIntegrationPointsForNode = ({
  isOpen,
  onModalClose,
  nodeId
}: EditIntegrationPointsForNodeProps) => {
  const [
    isAddNodeToPointDialogOpen,
    showAddNodeToPointDialog,
    hideAddNodeToPointDialog
  ] = useDialogState(false);
  const [pointToDelete, setPointToDelete] =
    useState<IntegrationPointResponse>(null);
  const params = useParams<NodeParams>();
  const queryClient = useQueryClient();

  const { mutate: deletePointFromNode, isLoading: isDeletingPointFromNode } =
    IntegrationAdminAPIGen.IntegrationPoints.deleteNodeFromIntegrationPoint.useMutation(
      {
        nodeId,
        pointId: pointToDelete?.id
      },
      {
        await: true
      },
      {
        onSuccess: () => {
          setPointToDelete(null);
          queryClient.invalidateQueries();
        },
        onError: () => {
          toastStore.addErrorToast(T.common.unknownerror);
        }
      }
    );

  const {
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    error
  } =
    IntegrationAPIGen.IntegrationPoints.listIntegrationPoints.useInfiniteQuery(
      {
        $filter: "'" + params.nodeId + "' in nodeIds"
      },
      {
        enabled: isOpen,
        retry: false
      }
    );

  const allColumns = useMemo(() => {
    return [
      ...columns,
      ...standardColumns({
        onDelete: (item: IntegrationPointResponse) => setPointToDelete(item)
      })
    ];
  }, []);

  const allItems = useMemo(() => {
    return _.flatMap(data?.pages, (page) => page.items);
  }, [data]);

  return (
    <>
      <ActionModal
        headerIcon={Icons.Data}
        title={T.admin.integrations.managepoints}
        isOpen={isOpen}
        actionText={
          <>
            {' '}
            <Icons.Add /> {T.admin.integrations.addtopoint}{' '}
          </>
        }
        cancelText={T.common.done}
        onConfirmClick={showAddNodeToPointDialog}
        onModalClose={onModalClose}
      >
        {error != null && <ErrorNotice> {T.common.unknownerror} </ErrorNotice>}
        <DataTable<IntegrationPointResponse>
          columns={allColumns}
          data={allItems}
          isLoading={isLoading}
        />
        <DataTableLoadMoreFooter
          isFetchingNextPage={isFetchingNextPage}
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
        />
      </ActionModal>
      <AddNodeToIntegrationPointModal
        nodeId={params.nodeId}
        isOpen={isAddNodeToPointDialogOpen}
        onModalClose={hideAddNodeToPointDialog}
      />
      <ConfirmDeleteDialog
        isOpen={pointToDelete != null}
        onModalClose={() => setPointToDelete(null)}
        itemName={pointToDelete?.name}
        isLoading={isDeletingPointFromNode}
        onDelete={deletePointFromNode}
      />
    </>
  );
};

EditIntegrationPointsForNode.propTypes = {
  isOpen: PropTypes.bool,
  onModalClose: PropTypes.func
};

export default React.memo(EditIntegrationPointsForNode);
