import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import ProcessMapLibraryList, {
  SymbolModelOrSystemItem
} from 'ecto-common/lib/ProcessMaps/ProcessMapLibraryList';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import styles from './ProcessMapEditor.module.css';
import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import Icons from 'ecto-common/lib/Icons/Icons';
import Button from 'ecto-common/lib/Button/Button';
import FileButton from 'ecto-common/lib/Button/FileButton';
import T from 'ecto-common/lib/lang/Language';
import removeIcon from 'ecto-common/lib/ProcessMap/ProcessMapDeleteIcon.svg';
import _ from 'lodash';
import { Base64 } from 'js-base64';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import { useMutation, useQuery } from '@tanstack/react-query';
import UUID from 'uuidjs';
import {
  ProcessMapObjectTypes,
  connectionCircleRadiusPixels,
  processMapSvgDataUrlPrefix
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import { extractSignalStateNames } from 'ecto-common/lib/ProcessMap/ProcessMapHooks';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { downloadBlobFromText } from 'ecto-common/lib/utils/downloadBlob';
import DOMPurify from 'dompurify';
import { prettifyXml } from 'ecto-common/lib/ProcessMap/ProcessMapViewUtils';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import UnsavedChangesDialog from 'ecto-common/lib/UnsavedChangesDialog/UnsavedChangesDialog';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import PresentationAPIGen, {
  SymbolModel
} from 'ecto-common/lib/API/PresentationAPIGen';
import { useImmer } from 'use-immer';
import SaveButton from 'ecto-common/lib/Button/SaveButton';
import { useProcessMapLibrary } from 'ecto-common/lib/ProcessMaps/ProcessMapEditorHooks';

type ProcessMapEditLibraryModalProps = {
  isOpen: boolean;
  onModalClose: () => void;
};

const loadImageDataPromise = (src: string) => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

function readSvgPromise({ file }: { file: File }) {
  return new Promise<[string, number, number]>((resolve, reject) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      const dataUrlContent = reader.result as string;
      if (!dataUrlContent.startsWith(processMapSvgDataUrlPrefix)) {
        reject(T.admin.processmaps.notsvgerror);
        return;
      }

      const rawContent = Base64.decode(
        dataUrlContent.replace(processMapSvgDataUrlPrefix, '')
      );

      loadImageDataPromise(URL.createObjectURL(file))
        .then((img) => {
          resolve([
            Base64.encode(DOMPurify.sanitize(rawContent)),
            img.width,
            img.height
          ]);
        })
        .catch(reject);
    };

    reader.onerror = reject;
  });
}

const ProcessMapEditLibraryModal = ({
  isOpen,
  onModalClose
}: ProcessMapEditLibraryModalProps) => {
  const [confirmEditIndex, setConfirmEditIndex] = useState(-1);

  const { libraryRequest, invalidateLibraryRequests, libraryItems } =
    useProcessMapLibrary();

  const [confirmDeleteItem, setConfirmDeleteItem] = useState(false);
  const [formData, setFormData] = useImmer<SymbolModel>(null);
  const [initialFormData, setInitialFormData] = useState<SymbolModel>(null);

  if (
    libraryItems.length > 0 &&
    formData == null &&
    !libraryRequest.isFetching
  ) {
    setFormData(libraryItems[0]);
    setInitialFormData(libraryItems[0]);
  }

  const [deletingConnection, setDeleteConnection] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);
  const [confirmSaveBeforeCreate, setConfirmSaveBeforeCreate] = useState(false);

  const deleteItemRequest = PresentationAPIGen.Symbols.deleteSymbol.useMutation(
    {
      id: formData?.id
    },
    {
      onSuccess: () => {
        invalidateLibraryRequests(null);
        const oldIndex = libraryItems.findIndex(
          (predicate) => predicate.id === formData.id
        );
        const newItem = libraryItems[Math.max(0, oldIndex - 1)];
        setFormData(newItem);
        setInitialFormData(newItem);
      }
    }
  );

  const saveItemRequest = PresentationAPIGen.Symbols.updateSymbol.useMutation(
    {
      id: formData?.id
    },
    {
      onSuccess: () => {
        invalidateLibraryRequests(formData?.id);
        setInitialFormData(formData);
        setConfirmSaveBeforeCreate(false);
        if (confirmEditIndex !== -1) {
          setFormData(libraryItems[confirmEditIndex]);
          setInitialFormData(libraryItems[confirmEditIndex]);
          setConfirmEditIndex(-1);
        }
      },
      onError: (e) => {
        if (_.isString(e)) {
          toastStore.addErrorToast(e);
        } else {
          toastStore.addErrorToast(T.common.unknownerror);
        }
      }
    }
  );

  const createSymbolMutation =
    PresentationAPIGen.Symbols.createSymbol.useMutation({
      onSuccess: (res) => {
        invalidateLibraryRequests(null);
        setInitialFormData(res);
        setFormData(res);
      },
      onError: (e) => {
        if (_.isString(e)) {
          toastStore.addErrorToast(e);
        } else {
          toastStore.addErrorToast(T.common.unknownerror);
        }
      }
    });

  const addSvgMutation = useMutation(readSvgPromise, {
    onSuccess: ([svgContent, width, height]) => {
      createSymbolMutation.mutate({
        symbol: {
          connections: [],
          width,
          height,
          name: T.admin.processmaps.newsymbol,
          data: svgContent,
          states: extractSignalStateNames(Base64.decode(svgContent))
        }
      });
    },
    onError: (e) => {
      if (_.isString(e)) {
        toastStore.addErrorToast(e);
      } else {
        toastStore.addErrorToast(T.common.unknownerror);
      }
    }
  });

  const editSvgMutation = useMutation(readSvgPromise, {
    onSuccess: ([svgContent, width, height]) => {
      setFormData((draft) => {
        draft.data = svgContent;
        draft.width = width;
        draft.height = height;
        draft.states = extractSignalStateNames(Base64.decode(svgContent));
      });
    },
    onError: (e) => {
      if (_.isString(e)) {
        toastStore.addErrorToast(e);
      } else {
        toastStore.addErrorToast(T.common.unknownerror);
      }
    }
  });

  const onClickItem = (item: SymbolModelOrSystemItem) => {
    if (item.type === ProcessMapObjectTypes.Symbol) {
      setDeleteConnection(false);
      let newIndex = libraryItems.indexOf(item.item);

      if (hasChanges) {
        setConfirmEditIndex(newIndex);
      } else {
        setFormData(item.item);
        setInitialFormData(item.item);
      }
    }
  };

  const itemUrl = useMemo(() => {
    if (formData?.data == null) {
      return null;
    }

    return URL.createObjectURL(
      new Blob([Base64.decode(formData.data)], { type: 'image/svg+xml' })
    );
  }, [formData?.data]);

  const imageQuery = useQuery(
    ['processMapEditImageSize', itemUrl],
    () => loadImageDataPromise(itemUrl),
    {
      enabled: itemUrl != null
    }
  );

  const imgRef = useRef<HTMLDivElement>(null);
  const dragOffset = useRef({ x: 0, y: 0 });
  const dragContainer = useRef<HTMLDivElement>(null);

  const saveItem = () => {
    saveItemRequest.mutate({
      symbol: formData
    });
  };

  const deleteItem = () => {
    setConfirmDeleteItem(false);
    deleteItemRequest.mutate();
  };

  const addConnection = () => {
    setDeleteConnection(false);
    setFormData((draft) => {
      draft.connections.push({ x: 0.5, y: 0.5, id: UUID.generate() });
    });
  };

  const editSvgFile = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    const file = _.head(changeEvent.target.files);
    editSvgMutation.mutate({ file });
    changeEvent.target.value = null;
  };

  const addSvgFile = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    if (hasChanges) {
      setConfirmSaveBeforeCreate(true);
    } else {
      const file = _.head(changeEvent.target.files);
      addSvgMutation.mutate({ file });
      changeEvent.target.value = null;
    }
  };

  const isLoading =
    saveItemRequest.isLoading ||
    libraryRequest.isFetching ||
    deleteItemRequest.isLoading ||
    editSvgMutation.isLoading ||
    addSvgMutation.isLoading;
  const footer = (
    <FileButton disabled={isLoading} onChange={addSvgFile}>
      <Icons.File /> {T.admin.processmaps.addsvgsymbol}
    </FileButton>
  );

  const aspectRatio =
    (imageQuery.data?.width ?? 1) / (imageQuery.data?.height ?? 1);
  let width = 465;
  let height = width / aspectRatio;

  if (height > 600) {
    height = 600;
    width = height * aspectRatio;
  }

  const setImageRef = useCallback(
    (node: HTMLDivElement) => {
      imgRef.current = node;
      let svgElement = imgRef.current?.getElementsByTagName('svg')[0];

      if (svgElement == null) {
        return;
      }

      svgElement.setAttribute('width', width + 'px');
      svgElement.setAttribute('height', height + 'px');
    },
    [height, width]
  );

  useEffect(() => {
    setImageRef(imgRef.current);
  }, [width, height, formData?.data, setImageRef]);

  const createContent = useCallback(() => {
    return { __html: Base64.decode(formData?.data) };
  }, [formData?.data]);

  const signalStateOptions = useMemo(() => {
    return (formData?.states ?? []).map((state) => ({
      label: state,
      value: state
    }));
  }, [formData?.states]);

  const close = () => {
    invalidateLibraryRequests(null);
    setFormData(null);
    setInitialFormData(null);
    onModalClose();
  };
  const hasChanges = formData !== initialFormData;

  const done = () => {
    if (hasChanges) {
      setConfirmClose(true);
    } else {
      close();
    }
  };

  const editIndex = libraryItems.findIndex(
    (predicate) => predicate.id === formData?.id
  );

  return (
    <>
      <ConfirmDeleteDialog
        isOpen={confirmDeleteItem}
        isLoading={false}
        onDelete={deleteItem}
        onModalClose={() => setConfirmDeleteItem(false)}
        itemName={formData?.name ?? ''}
      />
      <UnsavedChangesDialog
        isLoading={saveItemRequest.isLoading}
        isOpen={confirmSaveBeforeCreate}
        onCancel={() => {
          setConfirmSaveBeforeCreate(false);
        }}
        onSave={function (): void {
          saveItem();
        }}
        onDiscardChanges={function (): void {
          setConfirmSaveBeforeCreate(false);
          setFormData(initialFormData);
        }}
      />
      <UnsavedChangesDialog
        isLoading={saveItemRequest.isLoading}
        isOpen={confirmEditIndex !== -1}
        onCancel={() => {
          setConfirmEditIndex(-1);
        }}
        onSave={function (): void {
          saveItem();
        }}
        onDiscardChanges={function (): void {
          setConfirmEditIndex(-1);
          setFormData(libraryItems[confirmEditIndex]);
          setInitialFormData(libraryItems[confirmEditIndex]);
        }}
      />
      <UnsavedChangesDialog
        isOpen={confirmClose}
        onCancel={() => {
          setConfirmClose(false);
        }}
        onSave={function (): void {
          setConfirmClose(false);
          saveItem();
        }}
        onDiscardChanges={function (): void {
          setFormData(initialFormData);
          setConfirmClose(false);
          close();
        }}
      />
      <ActionModal
        title={T.admin.processmaps.editsymbollibrary}
        isOpen={isOpen}
        onModalClose={done}
        onConfirmClick={done}
        disableCancel
        large
        headerIcon={Icons.Edit}
        actionText={T.common.done}
        isLoading={isLoading}
        preventCloseDropdownMenu
        leftSideButton={footer}
      >
        <div className={styles.editContainer}>
          <ProcessMapLibraryList
            onClickLibraryItem={onClickItem}
            selectedIndex={editIndex}
            library={libraryItems}
            withBorder
          />
          {formData && (
            <div
              className={styles.editArea}
              onDragOver={(e) => {
                e.preventDefault();
              }}
            >
              <KeyValueInput
                value={formData.name}
                onChange={(event) => {
                  setFormData((draft) => {
                    draft.name = event.target.value;
                  });
                }}
              />
              <div className={styles.imageContainer} ref={dragContainer}>
                <div
                  ref={setImageRef}
                  style={{ width: width + 'px', height: height + 'px' }}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={createContent()}
                  draggable={false}
                />
                {formData.connections.map((connection, index) => (
                  <div
                    className={styles.connection}
                    style={{
                      left:
                        'calc(' +
                        connection.x * 100 +
                        '% - ' +
                        connectionCircleRadiusPixels +
                        'px)',
                      top:
                        'calc(' +
                        connection.y * 100 +
                        '% - ' +
                        connectionCircleRadiusPixels +
                        'px)',
                      backgroundColor: deletingConnection
                        ? 'transparent'
                        : 'red',
                      border: deletingConnection ? null : '1px solid black'
                    }}
                    draggable
                    key={index}
                    onDragEnd={(dragEvent) => {
                      dragEvent.preventDefault();
                      const rect =
                        dragContainer.current.getBoundingClientRect();
                      dragEvent.currentTarget.style.opacity = '1.0';
                      const x =
                        dragEvent.clientX -
                        rect.x -
                        dragOffset.current.x +
                        connectionCircleRadiusPixels;
                      const y =
                        dragEvent.clientY -
                        rect.y -
                        dragOffset.current.y +
                        connectionCircleRadiusPixels;
                      const relativeX = Math.max(
                        -0.05,
                        Math.min(1.05, x / rect.width)
                      );
                      const relativeY = Math.max(
                        -0.05,
                        Math.min(1.05, y / rect.height)
                      );
                      setFormData((draft) => {
                        draft.connections[index] = {
                          x: relativeX,
                          y: relativeY,
                          id: draft.connections[index].id
                        };
                      });
                    }}
                    onMouseDown={() => {
                      if (deletingConnection) {
                        setDeleteConnection(false);
                        setFormData((draft) => {
                          draft.connections.splice(index, 1);
                        });
                      }
                    }}
                    onDragStart={(dragEvent) => {
                      const rect =
                        dragEvent.currentTarget.getBoundingClientRect();
                      dragEvent.currentTarget.style.opacity = '0.2';
                      dragEvent.dataTransfer.effectAllowed = 'move';
                      dragEvent.dataTransfer.dropEffect = 'none';
                      dragOffset.current.x = dragEvent.clientX - rect.x;
                      dragOffset.current.y = dragEvent.clientY - rect.y;
                    }}
                  >
                    {deletingConnection && <img src={removeIcon} />}
                  </div>
                ))}
              </div>
              <div className={styles.buttonGrid}>
                <Button onClick={addConnection}>
                  <Icons.Add /> {T.admin.processmaps.addconnection}
                </Button>
                <Button
                  onClick={() =>
                    setDeleteConnection(
                      (oldDeleteConnection) => !oldDeleteConnection
                    )
                  }
                >
                  <Icons.Delete /> {T.admin.processmaps.deleteconnection}
                </Button>
                <Button onClick={() => setConfirmDeleteItem(true)}>
                  <Icons.Delete /> {T.admin.processmaps.deleteitem}
                </Button>
                <Button
                  onClick={() => {
                    toastStore.addSuccessToast(
                      T.common.copytoclipboard.success
                    );
                    navigator.clipboard.writeText(
                      prettifyXml(Base64.decode(formData.data))
                    );
                  }}
                >
                  <Icons.Copy /> {T.admin.processmaps.copysource}{' '}
                </Button>
                <Button
                  onClick={() => {
                    downloadBlobFromText(
                      prettifyXml(Base64.decode(formData.data)),
                      formData.name + '.svg'
                    );
                  }}
                >
                  <Icons.Download /> {T.admin.processmaps.downloadsvg}{' '}
                </Button>
                <FileButton onChange={editSvgFile}>
                  <Icons.File /> {T.admin.processmaps.updatesvgsymbol}
                </FileButton>
                <SaveButton onClick={saveItem} disabled={!hasChanges}>
                  {T.common.save}
                </SaveButton>
                {signalStateOptions.length > 0 && (
                  <>
                    <div />
                    <div />
                    <KeyValueGeneric
                      keyText={T.admin.processmaps.previewsymbolstates}
                    >
                      <Select<GenericSelectOption<string>, true>
                        isMulti
                        options={signalStateOptions}
                        onChange={(changeEvent) => {
                          const elements = imgRef.current.querySelectorAll(
                            '[data-symbol-state]'
                          );
                          const activeElements =
                            changeEvent.length === 0
                              ? ['default']
                              : [...changeEvent.map((option) => option.value)];

                          for (let element of elements) {
                            const state =
                              element.getAttribute('data-symbol-state');
                            if (activeElements.includes(state)) {
                              element.setAttribute('display', 'auto');
                            } else {
                              element.setAttribute('display', 'none');
                            }
                          }
                        }}
                      />
                    </KeyValueGeneric>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </ActionModal>
    </>
  );
};

export default React.memo(ProcessMapEditLibraryModal);
