import React from 'react';
import Page, { PageNavLink } from 'ecto-common/lib/Page/Page';

const emptyArray: PageNavLink[] = [];

interface LoadingScreenWithMenuProps {
  isLoading?: boolean;
}

const LoadingScreenWithMenu = ({ isLoading }: LoadingScreenWithMenuProps) => {
  if (!isLoading) {
    return null;
  }

  const content = <></>;

  return <Page isLoading content={content} navLinks={emptyArray} />;
};

export default React.memo(LoadingScreenWithMenu);
