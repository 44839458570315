import _ from 'lodash';

type RequestItem = {
  cancel?: () => void;
};

export default class RequestSet {
  _requests: Record<string, RequestItem[]> = {};

  trackRequestList(id: string, requests: RequestItem[]) {
    this._requests[id] = requests;
  }

  trackRequest(id: string, request: RequestItem) {
    this.trackRequestList(id, [request]);
  }

  isTrackingRequests(id: string, requests: RequestItem[]) {
    return _.isEqual(this._requests[id], requests);
  }

  cancelRequests() {
    Object.values(this._requests).forEach((requestList) => {
      requestList.forEach((request) => {
        if (request != null && request.cancel != null) {
          request.cancel();
        }
      });
    });

    this._requests = {};
  }

  async cancelRequestsBlocking() {
    let cancelledPromises: RequestItem[] = [];
    Object.values(this._requests).forEach((requestList) => {
      requestList.forEach((request) => {
        if (request != null && request.cancel != null) {
          request.cancel();
          cancelledPromises.push(request);
        }
      });
    });

    this._requests = {};

    try {
      await Promise.all(cancelledPromises);
    } catch (e) {
      // Ignore exception, only used to cancel actual HTTP request
    }
  }
}
