import React, { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import T from 'ecto-common/lib/lang/Language';
import styles from 'js/components/EnergyManagers/EditIoTDevice.module.css';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import Icons from 'ecto-common/lib/Icons/Icons';
import SegmentedContentView from 'ecto-common/lib/SegmentControl/SegmentedContentView';
import API, { cancellablePromiseList } from 'ecto-common/lib/API/API';
import usePromiseCall from 'ecto-common/lib/hooks/usePromiseCall';

import IoTHardwareActions from 'js/components/EnergyManagers/IoTHardwareActions';
import IoTDeviceInfo from 'js/components/EnergyManagers/IoTDeviceInfo';
import DeviceDetails from 'js/components/EnergyManagers/DeviceDetails';
import {
  IoTDeviceViewResponseModel,
  ModuleResponseModel
} from 'ecto-common/lib/API/APIGen';
import { UseEditIoTDeviceLoadingInfo } from 'js/components/EnergyManagers/useEditIoTDevice';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';

const getDeviceInfoRequest = (
  contextSettings: ApiContextSettings,
  iotDeviceId: string
) => {
  // Just fire this call off without waiting for the results. Can't do anything if it fails anyway.
  // If successful, it will trigger the device to upload new data to the backend.
  API.Admin.Devices.syncDeviceTwin(contextSettings, iotDeviceId).catch(_.noop);

  return cancellablePromiseList([
    API.Admin.IoTDevices.getCurrentModuleInfo(contextSettings),
    API.Admin.IoTDevices.getIoTDeviceByIoTDeviceId(contextSettings, iotDeviceId)
  ] as const);
};

interface EditIoTDeviceProps {
  editIoTDevice?: IoTDeviceViewResponseModel;
  unlinkButton?: React.ReactNode;
  actionButtons?: React.ReactNode;
  useMinimumHeight?: boolean;
  loadingInfo?: UseEditIoTDeviceLoadingInfo;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDeviceDataChanged?(key: string[], value: any): void;
}

export type EditIoTDeviceDataType = IoTDeviceViewResponseModel & {
  moduleInfo?: ModuleResponseModel[];
};

const EditIoTDevice = ({
  editIoTDevice,
  useMinimumHeight,
  actionButtons,
  unlinkButton,
  loadingInfo,
  onDeviceDataChanged
}: EditIoTDeviceProps) => {
  const [data, setData] = useState<EditIoTDeviceDataType>(null);

  const [getDataIsLoading, getData, cancelGetData] = usePromiseCall({
    promise: getDeviceInfoRequest,
    onSuccess: (response) => {
      const moduleInfo = response[0] || [];
      const iotDevices = response[1];
      const iotDevice = _.head(iotDevices);
      setData({ ...iotDevice, moduleInfo });
    },
    onError: () => {
      toastStore.addErrorToast(
        T.admin.iotdevicedetails.error.failedtofetchdeviceinfo
      );
    }
  });

  const iotDeviceId = editIoTDevice?.ioTDevice?.id;

  const _getData = useCallback(() => {
    if (iotDeviceId) {
      getData(iotDeviceId);
    }
  }, [getData, iotDeviceId]);

  useEffect(() => {
    return () => {};
  }, [getData, iotDeviceId, cancelGetData, _getData]);

  const sections = useMemo(
    () => [
      {
        icon: <Icons.Settings />,
        title: T.admin.iotdevicedetails.tab.details,
        view: (
          <DeviceDetails
            device={editIoTDevice}
            onDeviceDataChanged={onDeviceDataChanged}
            actionButtons={actionButtons}
            loadingInfo={loadingInfo}
            unlinkButton={unlinkButton}
          />
        )
      },
      {
        icon: <Icons.Hardware />,
        title: T.admin.iotdevicedetails.tab.hardware,
        view: (
          <IoTDeviceInfo
            isLoading={getDataIsLoading}
            data={data}
            getData={_getData}
          />
        )
      },
      {
        icon: <Icons.Actions />,
        title: T.admin.iotdevicedetails.tab.hardwareactions,
        view: <IoTHardwareActions device={editIoTDevice} />
      }
    ],
    [
      editIoTDevice,
      data,
      onDeviceDataChanged,
      _getData,
      actionButtons,
      loadingInfo,
      unlinkButton,
      getDataIsLoading
    ]
  );

  return (
    <div className={classNames(useMinimumHeight && styles.useMinimumHeight)}>
      <LoadingContainer isLoading={loadingInfo.isSaving}>
        <SegmentedContentView sections={sections} expanding />
      </LoadingContainer>
    </div>
  );
};

export default EditIoTDevice;
